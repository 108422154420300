export default {
  'error.lend.exceedBalance': '歸還數量超出借出數量',
  'lend.title.list': '借貨目錄',
  'lend.title.add': '新增借貨單',
  'lend.title.edit': '修改借貨單',
  'lend.title.view': '借貨單詳情',
  'lend.title.addReturn': '新增借貨歸還',
  'lend.title.viewReturn': '借貨歸還紀錄',
  'lend.title.deleteReturn': '刪除借貨歸還',
  'lend.title.print': '借貨單 - {ticketId}',
  'lend.btn.addReturn': '歸還',
  'lend.btn.addSell': '銷貨',
  'lend.message.deleteReturn': '確認刪除借貨歸還?',
  'lend.section.product': '商品',
  'lend.section.return': '歸還紀錄',
  'lend.section.dispatch': '出貨紀錄',
  'lend.section.sell': '銷貨紀錄',
  'lend.field.id': '單號',
  'lend.field.ticketNo': '廠商單號',
  'lend.field.transDate': '借貨日期',
  'lend.field.salesId': '負責人員',
  'lend.field.memo': '業務備註',
  'lend.field.warehouseId': '倉庫',
  'lend.field.dealerId': '經銷層',
  'lend.field.dealerEIN': '統一編號',
  'lend.field.dealerAddress': '公司地址',
  'lend.field.shippingId': '物流',
  'lend.field.recipientId': '收貨處',
  'lend.field.recipientContact': '聯絡人',
  'lend.field.recipientAddress': '收件地址',
  'lend.field.recipientEmail': 'Email',
  'lend.field.recipientPhone': '電話',
  'lend.field.recipientCellphone': '手機',
  'lend.field.spu': '商品名稱',
  'lend.field.sku': '品號',
  'lend.field.barcode': '產品條碼',
  'lend.field.isGift': '贈品',
  'lend.field.batchNo': '批號',
  'lend.field.balance': '可用數量',
  'lend.field.quantity': '數量',
  'lend.field.returnQuantity': '歸還量',
  'lend.field.sellQuantity': '銷貨量',
  'lend.field.remainQuantity': '剩餘量',
  'lend.field.price': '金額',
  'lend.field.status': '狀態',
  'lend.type.RETURN_WAREHOUSE': '轉倉',
  'lend.type.DISCARD': '瑕疵報廢',
  'lend.status.ACTIVE': '已完成',
  'lend.status.INACTIVE': '已刪除',
  'lend.status.ACTION_PENDING': '借出中',
}
