import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { ALERT_ADD, STAFF_SET, TOKEN_SET } from 'constants/actionType'
import { TextInput } from 'components/form'

export const initialState = () =>
  initializeState({
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  })

const validation = {
  password: [{ type: 'required', message: 'error.required' }],
  newPassword: [
    { type: 'required', message: 'error.required' },
    {
      type: 'minLength',
      val: 6,
      message: { id: 'error.minLength', values: { val: 6 } },
    },
  ],
  newPasswordConfirm: [
    { type: 'required', message: 'error.required' },
    {
      type: 'minLength',
      val: 6,
      message: { id: 'error.minLength', values: { val: 6 } },
    },
    {
      type: 'fieldEqual',
      name: 'newPassword',
      message: 'error.passNotMatch',
    },
  ],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    password: (
      <TextInput
        type="password"
        placeholder="account.field.password"
        value={state.password}
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    newPassword: (
      <TextInput
        type="password"
        placeholder="account.field.newPassword"
        value={state.newPassword}
        onChange={onTextChange('newPassword')}
        errMsg={state.__error__.newPassword}
      />
    ),
    newPasswordConfirm: (
      <TextInput
        type="password"
        placeholder="account.field.newPasswordConfirm"
        value={state.newPasswordConfirm}
        onChange={onTextChange('newPasswordConfirm')}
        errMsg={state.__error__.newPasswordConfirm}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { password, newPassword } = state
    const variables = { password, newPassword }
    const query = `
      mutation($password: String!, $newPassword: String!) {
        editStaffPass(password: $password, newPassword: $newPassword) {
          accessToken
          refreshToken
          claim
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session, app })
    if (!ok) return

    const { accessToken, refreshToken, claim } = data.editStaffPass
    app.dispatch({ type: STAFF_SET, staff: claim })
    app.dispatch({ type: TOKEN_SET, token: { accessToken, refreshToken } })
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'account.success.passwordEdit' },
    })
    action.setOpen(false)
  },
})
