import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const DraggableBox = ({ as, className, style, children, index, onDrag }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'BOX',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const { top, bottom, left, right } = ref.current.getBoundingClientRect()
      const hoverMiddleY = (bottom - top) / 2
      const hoverMiddleX = (right - left) / 2

      const mouseOffset = monitor.getClientOffset()
      const mouseY = mouseOffset.y - top
      const mouseX = mouseOffset.x - left

      if (
        dragIndex < hoverIndex &&
        mouseY > hoverMiddleY &&
        mouseX > hoverMiddleX
      ) {
        return
      }

      if (
        dragIndex > hoverIndex &&
        mouseY < hoverMiddleY &&
        mouseX < hoverMiddleX
      ) {
        return
      }

      onDrag(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'BOX', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const Tag = as || 'div'
  return (
    <Tag
      ref={ref}
      className={className}
      style={{ ...style, opacity }}
      data-handler-id={handlerId}
    >
      {children}
    </Tag>
  )
}

export default DraggableBox
