import React from 'react'
import { request } from 'utilities/request'
import { getSelectOptions } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'
import { filterLocations } from 'utilities/permission'

export const filters = ({ data }) => [
  {
    id: 'dealer',
    label: 'field.dealer',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.dealers),
      }),
  },
  {
    id: 'org',
    label: 'field.org',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.orgs),
      }),
  },
  {
    id: 'dept',
    label: 'field.dept',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.depts),
      }),
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
]

export const columns = ({ filterValues, action }) => [
  {
    id: 'locationName',
    label: 'field.dealer',
    format: ['html', 'excel'],
  },
  {
    id: 'locationCode',
    label: 'field.dealerCode',
    format: ['excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
  },
  {
    id: 'sellQuantity',
    label: 'field.sellQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          // action.handleOpen({
          //   ticketType: ['SELL', 'DEFER'],
          //   transDate: transDate?.value,
          //   locationId: row.locationId,
          //   productVariantId: row.productVariantId,
          //   isGift: false,
          // })
          action.handleSalesOpen({
            showQuantity: true,
            distinct: true,
            transDate: transDate?.value,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {round(row.sellQuantity, 1)}
      </Link>
    ),
    render: ({ row }) => round(row.sellQuantity, 1),
  },
  {
    id: 'sellGiftQuantity',
    label: 'field.sellGiftQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          // action.handleOpen({
          //   ticketType: ['SELL', 'DEFER'],
          //   transDate: transDate?.value,
          //   locationId: row.locationId,
          //   productVariantId: row.productVariantId,
          //   isGift: true,
          // })
          action.handleSalesOpen({
            showQuantity: true,
            distinct: true,
            transDate: transDate?.value,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
            isGift: true,
          })
        }}
      >
        {round(row.sellGiftQuantity, 1)}
      </Link>
    ),
    render: ({ row }) => round(row.sellGiftQuantity, 1),
  },
  {
    id: 'sellAmount',
    label: 'field.sellAmount',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleSalesOpen({
            distinct: true,
            showAmount: true,
            transDate: transDate?.value,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {round(row.sellAmount)}
      </Link>
    ),
    render: ({ row }) => round(row.sellAmount),
  },
  {
    id: 'refundQuantity',
    label: 'field.refundQuantity',
    format: ['html', 'excel'],
    align: 'right',
    render: ({ row }) => row.refundQuantity,
  },
]

function round(val, decimal = 100) {
  if (!val) return 0
  return Math.round(val * decimal) / decimal
}

export const handlers = ({
  app,
  message,
  setState,
  session,
  setDepts,
  setOrgs,
  setDealers,
  setTotal,
  setFilterValues,
  setPagination,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setDepts(resp.depts)
    setOrgs(resp.orgs)
    setDealers(resp.dealers)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setTotal(resp.total)
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.dealerSales'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getInit({ session, app }) {
  const variables = { input: { type: ['DEPT', 'DEALER_ORG', 'DEALER'] } }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        name
        type
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const depts = []
  const orgs = []
  const dealers = []

  locations.forEach((item) => {
    switch (item.type) {
      case 'DEPT':
        depts.push(item)
        break
      case 'DEALER_ORG':
        orgs.push(item)
        break
      case 'DEALER':
        dealers.push(item)
        break
      default:
    }
  })

  return { depts, orgs, dealers }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const input = getDataInput({ filterValues, pagination })
  const variables = { input }
  const query = `
    query($input: LocationSalesReportInput) {
      locationSalesReportCount(input: $input)
      locationSalesReports(input: $input) {
        locationId
        locationName
        productVariantId
        productVariantName
        sku
        sellQuantity
        sellGiftQuantity
        sellAmount
        refundQuantity
      }
      locationSalesTotal(input: $input) {
        sellQuantity
        sellGiftQuantity        
        sellAmount
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { sellQuantity, sellGiftQuantity, sellAmount } = data.locationSalesTotal
  const { page, countPerPage } = pagination
  return {
    data: data.locationSalesReports,
    total: { sellQuantity, sellGiftQuantity, sellAmount: round(sellAmount) },
    pagination: getPagination(
      page,
      countPerPage,
      data.locationSalesReportCount,
    ),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: LocationSalesReportInput) {
      locationSalesReports(input: $input) {
        locationName
        locationCode
        productVariantName
        sku
        sellQuantity
        sellGiftQuantity
        sellAmount
        refundQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.locationSalesReports
}

function getDataInput({ filterValues, pagination }) {
  const dept = filterValues.find(({ id }) => id === 'dept')
  const org = filterValues.find(({ id }) => id === 'org')
  const dealer = filterValues.find(({ id }) => id === 'dealer')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const input = {
    deptId: dept?.value.value,
    orgId: org?.value.value,
    locationId: dealer?.value.value,
    transDate: transDate?.value,
    productVariantName: productName?.value,
    orderBy: [
      { key: 'locationName' },
      { key: 'productVariantName' },
      { key: 'sku' },
    ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
