import React from 'react'
import {
  Select,
  TextInput,
  NumberInput,
  TextArea,
  DateInput,
} from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
} from 'utilities/form'

export const initialState = (value = {}, data) => ({
  index: value.index,
  ...initializeState({
    ticketNo: value.ticketNo || '',
    transDate: value.transDate || '',
    srcCompanyId: value.srcCompanyId || '',
    billCompanyId: value.billCompanyId || '',
    memo: value.memo || '',
    productVariantId: value.productVariantId || {},
    toLocationId: value.toLocationId || '',
    invoicedQuantity: value.invoicedQuantity || 0,
    purchasedQuantity: value.purchasedQuantity || 0,
    expireAt: value.expireAt || '',
    batchNo: value.batchNo || '',
  }),
})

const validation = {
  ticketNo: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
  toLocationId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="purchase.field.transDate"
        placeholder="purchase.field.transDate"
        value={state.transDate}
        role="lockPurchaseTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="purchase.field.ticketNo"
        placeholder="purchase.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
        errMsg={state.__error__.ticketNo}
      />
    ),
    srcCompanyId: (
      <Select
        label="purchase.field.srcCompany"
        isClearable={false}
        options={data.srcCompanies}
        value={state.srcCompanyId}
        onChange={onSelectChange('srcCompanyId')}
        errMsg={state.__error__.srcCompanyId}
      />
    ),
    billCompanyId: (
      <Select
        label="purchase.field.billCompany"
        isClearable={false}
        options={data.billCompanies}
        value={state.billCompanyId}
        onChange={onSelectChange('billCompanyId')}
        errMsg={state.__error__.billCompanyId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="purchase.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    productVariantId: (
      <Select
        id="productVariantId"
        label="product.field.sku"
        placeholder="product.field.sku"
        isClearable={false}
        options={data.products}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    toLocationId: (
      <Select
        id="toLocationId"
        label="field.warehouse"
        placeholder="field.warehouse"
        isClearable={false}
        options={data.warehouses}
        value={state.toLocationId}
        onChange={onSelectChange('toLocationId')}
        errMsg={state.__error__.toLocationId}
      />
    ),
    invoicedQuantity: (
      <NumberInput
        id="invoicedQuantity"
        min="0"
        label="purchase.field.invoicedQuantity"
        placeholder="purchase.field.invoicedQuantity"
        value={state.invoicedQuantity}
        onChange={onTextChange('invoicedQuantity')}
        errMsg={state.__error__.invoicedQuantity}
      />
    ),
    purchasedQuantity: (
      <NumberInput
        id="purchasedQuantity"
        min="0"
        label="purchase.field.purchasedQuantity"
        placeholder="purchase.field.purchasedQuantity"
        value={state.purchasedQuantity}
        onChange={onTextChange('purchasedQuantity')}
        errMsg={state.__error__.purchasedQuantity}
      />
    ),
    expireAt: (
      <DateInput
        id="expireAt"
        label="purchase.field.expireAt"
        placeholder="purchase.field.expireAt"
        value={state.expireAt}
        onChange={onTextChange('expireAt')}
        errMsg={state.__error__.expireAt}
      />
    ),
    batchNo: (
      <TextInput
        id="batchNo"
        label="purchase.field.batchNo"
        placeholder="purchase.field.batchNo"
        value={state.batchNo}
        onChange={onTextChange('batchNo')}
        errMsg={state.__error__.batchNo}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.editTicketItem(state)
    action.handleClose()
  },
})
