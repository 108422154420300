import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { AppContext } from 'contexts'
import { fields } from 'models/accountPage'
import { Button, Grid, Section, Toolbar } from 'components/core'
import Page from 'components/Page'
import PasswordModal from 'views/PasswordModal'
import { MdEdit } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const app = useContext(AppContext)
  const [state, setState] = useState({ staff: app.state.staff })
  const [open, setOpen] = useState(false)
  const form = fields({ state, setState, message })

  return (
    <>
      <Page title="account.title.view">
        <Grid cols={['1fr', '1fr 2fr']} gap={3}>
          <Section>
            <Toolbar>
              <Button
                variant="default"
                icon={<MdEdit />}
                text="account.btn.editPassword"
                onClick={() => setOpen(true)}
              />
            </Toolbar>
            {form.name}
            {form.type}
            {/* {form.role} */}
          </Section>
        </Grid>
      </Page>
      <PasswordModal open={open} action={{ setOpen }} />
    </>
  )
}
