import React, { Suspense, lazy } from 'react'
import { convertDate, createExcel, readExcel } from 'utilities/excel'
import {
  getSelectOptions,
  handleTextChange,
  initializeState,
  parseAddress,
  validateForm,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Center, LoadingIcon } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'

const FileInput = lazy(() => import('components/file/FileInput'))

export const initialState = (value = {}) => {
  const dealerMap = value.dealers?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const recipientMap = value.recipients?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const billingMap = value.billings?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const productMap = value.products?.reduce((result, item) => {
    result[item.sku] = item.id
    return result
  }, {})

  const salesMap = value.sales?.reduce((result, item) => {
    result[item.name] = item.id
    return result
  }, {})

  return {
    dealers: value.dealers || [],
    dealerLocations: value.dealerLocations || [],
    dealerMap,
    recipients: value.recipients || [],
    recipientLocations: value.recipientLocations || [],
    recipientMap,
    billings: value.billings || [],
    billingMap,
    products: getSelectOptions(value.products, 'id', 'sku'),
    productMap,
    sales: getSelectOptions(value.sales),
    salesMap,
    ticketItems: value.ticketItems || [],
    ...initializeState({
      file: '',
    }),
  }
}

const columns = [
  { id: 'transDate', label: 'refund.field.transDate' },
  { id: 'ticketNo', label: 'refund.field.ticketNo' },
  { id: 'dealerId', label: 'refund.field.dealerId' },
  { id: 'recipientId', label: 'refund.field.recipientId' },
  { id: 'recipientContact', label: 'refund.field.recipientContact' },
  { id: 'recipientAddress', label: 'refund.field.recipientAddress' },
  { id: 'recipientPhone', label: 'refund.field.recipientPhone' },
  { id: 'recipientCellphone', label: 'refund.field.recipientCellphone' },
  { id: 'billingId', label: 'refund.field.billingId' },
  { id: 'salesId', label: 'refund.field.salesId' },
  { id: 'memo', label: 'refund.field.memo' },
  { id: 'productVariantId', label: 'product.field.sku' },
  { id: 'quantity', label: 'field.quantity' },
]

const validation = {
  file: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    file: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <FileInput
          id="file"
          label="refund.field.importFile"
          accept=".xlsx"
          value={state.file}
          onUpload={onTextChange('file')}
          onDelete={onTextChange('file')}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
          errMsg={state.__error__.file}
        />
      </Suspense>
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  message,
  data: { cityMap, districtMap },
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, message })
    setState(initialState(data))
  },
  downloadTemplate: () => {
    const title = 'refund.message.template'
    createExcel({ message, title, timestamp: false, cols: columns, rows: [] })
  },
  loadExcel: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const cities = Object.keys(cityMap)
    const districts = Object.keys(districtMap)
    const excelFile = await readFile(state.file)
    const data = await readExcel(excelFile)

    const ticketItems = data.reduce((result, item) => {
      const ticketItem = {}
      columns.forEach(({ id, label }) => {
        let value = item[message({ id: label })]
        if (typeof value === 'string') value = value.trim()

        const dealerId = state.dealerMap[value]
        const recipientId = state.recipientMap[value]
        const billingId = state.billingMap[value]
        const productId = state.productMap[value]

        switch (id) {
          case 'dealerId':
            value = dealerId
              ? { value: dealerId, label: value }
              : state.dealerId
            break
          case 'recipientId':
            value = recipientId ? { value: recipientId, label: value } : null
            break
          case 'recipientAddress':
            value = parseAddress(cityMap, cities, districtMap, districts, value)
            break
          case 'billingId':
            value = billingId ? { value: billingId, label: value } : null
            break
          case 'salesId':
            value = value?.split(',').reduce((r, item) => {
              const salesId = state.salesMap[item]
              if (!salesId) return r
              r.push({ value: salesId, label: item })
              return r
            }, [])
            if (!value || value.length === 0) value = state.salesId
            break
          case 'productVariantId':
            value = productId ? { value: productId, label: value } : null
            break
          case 'transDate':
            value = convertDate(value)
            break
          default:
        }
        ticketItem[id] = value
      })

      const matched = result.find(
        (i) =>
          i.ticketNo === ticketItem.ticketNo &&
          i.productVariantId?.value === ticketItem.productVariantId?.value,
      )

      if (matched) {
        matched.quantity =
          parseInt(matched.quantity) + parseInt(ticketItem.quantity)
      } else {
        result.push(ticketItem)
      }

      return result
    }, [])

    setState({ ...state, ticketItems })
    action.setOpen(true)
  },
})

async function getData({ app, session, message }) {
  const staffInput = { type: ['SALES'] }
  const locationInput = { type: ['DEALER', 'STORAGE', 'COMPANY'] }
  const variables = { locationInput, staffInput }
  const query = `
    query($locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
      }
      productVariants {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { locations, productVariants, staffs } = data
  const dealers = []
  const dealerLocations = []
  const recipients = [
    {
      value: '__INDIVIDUAL__',
      label: message({ id: 'dispatch.recipient.individual' }),
    },
  ]
  const recipientLocations = []
  const billings = []

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'DEALER':
        dealers.push(result)
        dealerLocations.push(item)
        break
      case 'STORAGE':
        recipients.push(result)
        recipientLocations.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'BILLING') billings.push(result)
        break
      default:
        break
    }
  })

  return {
    dealers,
    dealerLocations,
    recipients,
    recipientLocations,
    billings,
    sales: staffs,
    products: productVariants,
  }
}

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}
