import React from 'react'
import { Box } from 'reflexbox'
import { Button, Heading, Divider } from 'components/core'
import { MdClose } from 'react-icons/md'
import { renderMessage } from 'utilities/app'

export default ({
  width = [1, '360px'],
  open,
  onClose = () => {},
  title,
  children,
  titleProps,
  ...props
}) => (
  <>
    <Box
      // onClick={onClose}
      width={1}
      height="100%"
      bg="dark.3"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        transform: open ? 'scale(1)' : 'scale(0)',
        opacity: open ? 0.6 : 0,
        transition: 'opacity 0.3s ease-out',
      }}
    />
    <Box
      width={open ? width : 0}
      height="100%"
      bg="light.0"
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 1001,
        overflowX: 'hidden',
        transition: 'width 0.2s',
      }}
      {...props}
    >
      <Box width={width} p={3}>
        <Heading
          p={0}
          mb={3}
          height="28px"
          justifyContent="space-between"
          {...titleProps}
        >
          {renderMessage(title)}
          <Button variant="icon" icon={<MdClose />} onClick={onClose} />
        </Heading>
        <Divider bg="light.2" />
        {children}
      </Box>
    </Box>
  </>
)
