import React from 'react'
import { TextInput, NumberInput, Select } from 'components/form'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
} from 'utilities/form'

export const initialState = (value = {}, message) => ({
  index: value.index,
  ...initializeState({
    processName: value.processName || '',
    code: value.code || '',
    memo: value.memo || '',
    quantity: value.quantity || 1,
    unit: getUnit(value.unit, message),
    price: value.price || 0,
  }),
})

function getUnit(item, message) {
  let { value } = item || {}
  if (!value) value = 'PCS'
  return { value, label: message({ id: `processConfig.unit.${value}` }) }
}

const validation = {
  processName: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id) =>
    handleSelectChange(id, state, setState, validation)
  return {
    processName: (
      <TextInput
        id="processName"
        label="processConfig.field.processName"
        placeholder="processConfig.field.processName"
        value={state.processName}
        onChange={onTextChange('processName')}
        errMsg={state.__error__.processName}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="processConfig.field.code"
        placeholder="processConfig.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    memo: (
      <TextInput
        id="memo"
        label="processConfig.field.memo"
        placeholder="processConfig.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
        errMsg={state.__error__.memo}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="1"
        label="processConfig.field.quantity"
        placeholder="processConfig.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    unit: (
      <Select
        label="processConfig.field.unit"
        isSearchable={false}
        isClearable={false}
        options={[
          { value: 'PCS', label: message({ id: `processConfig.unit.PCS` }) },
          {
            value: 'INNER_BOX',
            label: message({ id: `processConfig.unit.INNER_BOX` }),
          },
          {
            value: 'OUTER_BOX',
            label: message({ id: `processConfig.unit.OUTER_BOX` }),
          },
        ]}
        value={state.unit}
        onChange={onSelectChange('unit')}
      />
    ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="processConfig.field.price"
        placeholder="processConfig.field.price"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.addConfigItem(state)
    action.handleClose()
  },
})
