import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
} from 'utilities/form'
import { Select } from 'components/form'

export const initialState = () =>
  initializeState({
    techId: {},
  })

const validation = {
  techId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    techId: (
      <Select
        isClearable={false}
        label="sy.field.techId"
        options={data.techs}
        value={state.techId}
        onChange={onSelectChange('techId')}
        errMsg={state.__error__.techId}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.addTech(state.techId)
    action.handleClose()
    setState(initialState())
  },
})
