import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import {
  initialState,
  fields,
  handlers,
  getPaymentBalance,
  getPaymentPaid,
  getPaymentInvoiced,
  getPaymentReverted,
  getTotal,
  renderSendLink,
} from 'models/deferPage'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Toolbar,
  Text,
} from 'components/core'
import Page from 'components/Page'
import DeferDrawer from 'views/DeferDrawer'
import {
  MdAdd,
  MdArrowBack,
  MdEdit,
  MdFileDownload,
  MdLock,
  MdLockOpen,
  MdPrint,
  MdPictureAsPdf,
} from 'react-icons/md'
import DeferPaymentDrawer from './DeferPaymentDrawer'
import { Box, Flex } from 'reflexbox'
import { renderDeleteLink, renderLockLink, renderMessage } from 'utilities/app'
import DeferRevertDrawer from './DeferRevertDrawer'
import { handleLock } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { hasAccess } from 'utilities/permission'

export default ({ match, profile }) => {
  const { id } = match.params
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [paymentOpen, setPaymentOpen] = useState(false)
  const [paymentValue, setPaymentValue] = useState({})
  const [revertOpen, setRevertOpen] = useState(false)
  const [revertValue, setRevertValue] = useState({})
  const activeTicketItems = state.ticketItems?.filter(
    (item) => item.status === 'ACTIVE',
  )

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handlePaymentOpen = (item) => {
    setPaymentValue(item)
    setPaymentOpen(true)
  }

  const handlePaymentClose = () => {
    setPaymentOpen(false)
    setPaymentValue({})
  }

  const handleRevertOpen = (item) => {
    setRevertValue(item)
    setRevertOpen(true)
  }

  const handleRevertClose = () => {
    setRevertOpen(false)
    setRevertValue({})
  }

  const readonly = profile === 'view'
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    history,
    id,
    profile,
  })
  const form = fields({
    profile,
    app,
    session,
    state,
    setState,
    message,
    action: { handleOpen, handlePaymentOpen },
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  const renderTableFooter = (label, value, showBorder = true) => (
    <Flex
      pt={showBorder ? 3 : 2}
      justifyContent="flex-end"
      sx={{
        borderTopWidth: showBorder ? '1px' : 0,
        borderTopStyle: 'solid',
        borderTopColor: 'light.3',
      }}
    >
      {renderMessage(label, { color: 'dark.1' })}
      <Text mx={2} minWidth="4em" sx={{ textAlign: 'right' }}>
        {value}
      </Text>
    </Flex>
  )

  return (
    <>
      <Page
        profile={profile}
        navId="deferList"
        title={`defer.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`defer.status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/defer/${id}/view` : '/defer'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            <Link
              ml={3}
              show={readonly}
              disabled={!state.pdfUrl}
              variant="link"
              icon={<MdPictureAsPdf />}
              tooltip="btn.pdf"
              href={state.pdfUrl}
              target="_blank"
            />
            {renderDeleteLink({
              module: 'defer',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            {renderSendLink({
              session,
              readonly,
              status: state.status,
              email: state.recipientEmail,
              onSubmit: handler.handleEmailSend,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.locked || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/defer/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: getLockStatus(state),
              onSubmit: handleLock({
                module: 'defer',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.ticketNo}
            {form.transDate}
            {form.dealer}
            {form.recipient}
            {form.dealerCode}
            {form.billing}
          </Section>
          <Section>
            {form.recipientContact}
            {form.recipientAddress}
            {form.recipientPhone}
            {form.recipientCellphone}
            {form.recipientEmail}
            {form.sales}
            {form.memo}
          </Section>
          <Section>
            {form.contractFromDate}
            {form.contractToDate}
            {form.contractSignDate}
            {form.contractAmount}
            {form.issueReceipt}
            {form.backup}
          </Section>
        </Grid>
        <Section mt={5} title="defer.section.product">
          <Toolbar show={readonly}>
            <Button
              disabled={state.itemLocked}
              variant="default"
              icon={<MdLock />}
              text="defer.btn.lockItem"
              onClick={handler.handleItemLockToggle}
            />
            <Button
              disabled={!state.itemLocked}
              variant="default"
              icon={<MdLockOpen />}
              text="defer.btn.unlockItem"
              onClick={handler.handleItemLockToggle}
            />
          </Toolbar>
          <Toolbar show={!readonly}>
            <Button
              disabled={state.itemLocked}
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
          {form.product}
          {renderTableFooter(
            'defer.field.orderAmount',
            getPaymentInvoiced(state),
          )}
          {renderTableFooter(
            'defer.field.revertAmount',
            getPaymentReverted(state),
            false,
          )}
          {renderTableFooter('field.total', getTotal(state), false)}
        </Section>
        <Section mt={5} title="defer.section.image">
          {form.images}
        </Section>
        <Section show={readonly} mt={5} title="defer.section.payment">
          <Toolbar show={readonly}>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handlePaymentOpen({ index: -1 })}
            />
          </Toolbar>
          {form.payments}
          {renderTableFooter('defer.field.paymentPaid', getPaymentPaid(state))}
          {renderTableFooter(
            'defer.field.paymentBalance',
            getPaymentBalance(state),
            false,
          )}
        </Section>
        <Section show={readonly} mt={5} title="defer.section.revert">
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleRevertOpen({ index: -1 })}
            />
          </Toolbar>
          {form.reverts}
        </Section>
        <Grid mt={5} cols={['1fr', '1fr 1fr']} gap={3}>
          <Box>
            <Section show={readonly} title="defer.section.dispatch">
              <Toolbar show={readonly}>
                <Button
                  disabled={
                    !state.itemLocked ||
                    !hasAccess(app.state.staff, 'addDeferDispatchTicket')
                  }
                  variant="default"
                  icon={<MdAdd />}
                  text="btn.add"
                  onClick={() =>
                    history.push(
                      `/dispatch/add?parentId=${id}&parentType=DEFER`,
                    )
                  }
                />
                <Button
                  disabled={
                    !state.itemLocked ||
                    !hasAccess(app.state.staff, 'addDeferDispatchTicket')
                  }
                  variant="default"
                  icon={<MdFileDownload />}
                  text="btn.import"
                  onClick={() => history.push(`/dispatch/import?deferId=${id}`)}
                />
              </Toolbar>
              {form.dispatchTickets}
            </Section>
          </Box>
          <Box>
            <Section show={readonly} title="defer.section.refund">
              <Toolbar show={readonly}>
                <Button
                  variant="default"
                  icon={<MdAdd />}
                  text="btn.add"
                  onClick={() => history.push(`/refund/add?parentId=${id}`)}
                />
              </Toolbar>
              {form.refundTickets}
            </Section>
          </Box>
        </Grid>
      </Page>
      <DeferDrawer
        open={open}
        value={value}
        data={{ productOptions: state.productOptions }}
        action={{ handleClose, addItem: handler.addItem }}
      />
      <DeferPaymentDrawer
        open={paymentOpen}
        value={paymentValue}
        data={{
          id,
          hash: state.hash,
          ticketItems: activeTicketItems,
          payments: state.payments,
        }}
        action={{
          handleLoad: handler.handleLoad,
          handleClose: handlePaymentClose,
        }}
      />
      <DeferRevertDrawer
        open={revertOpen}
        value={revertValue}
        data={{
          id,
          hash: state.hash,
          ticketItems: activeTicketItems,
          reverts: state.reverts,
        }}
        action={{
          handleLoad: handler.handleLoad,
          handleClose: handleRevertClose,
        }}
      />
    </>
  )
}

function getLockStatus(state) {
  if (state.status === 'REVERTED') return 'ACTIVE'
  if (state.status === 'PENDING' && state.locked) return 'ACTIVE'
  return state.status
}
