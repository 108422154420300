import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import { Button, Center, Text, Icon } from 'components/core'
import { MdDelete, MdEdit, MdErrorOutline } from 'react-icons/md'
import { request } from 'utilities/request'
import { showAddress, validateRows } from 'utilities/form'

export const initialState = (value) => ({
  ticketItems: value.ticketItems || [],
  errorItems: [],
})

const validation = {
  dealerId: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.dealer' },
      },
    },
  ],
  recipientId: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.storage' },
      },
    },
  ],
  recipientContact: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.contact' },
      },
    },
  ],
  recipientAddress: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.contactAddress' },
      },
    },
    { type: 'address', message: 'error.invalidAddress' },
  ],
  productVariantId: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.productName' },
      },
    },
  ],
  quantity: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'refund.field.quantity' },
      },
    },
  ],
}

export const columns = ({ state, setState, message, action }) => {
  const renderText = (id, label, align = 'left') => ({
    id,
    label,
    align,
    cellProps: { py: 2 },
    render: ({ row }) => <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]}</Text>,
  })

  const renderSelect = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]?.label}</Text>
    ),
  })

  const renderMultiSelect = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => {
      if (!row[id]) return null
      return (
        <Text sx={{ whiteSpace: 'nowrap' }}>
          {row[id].map(({ label }) => label).join(', ')}
        </Text>
      )
    },
  })

  const renderAddress = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{showAddress(row[id], message)}</Text>
    ),
  })

  return [
    renderText('transDate', 'refund.field.transDate'),
    renderText('ticketNo', 'refund.field.ticketNo'),
    renderSelect('dealerId', 'refund.field.dealerId'),
    renderSelect('recipientId', 'refund.field.recipientId'),
    renderText('recipientContact', 'refund.field.recipientContact'),
    renderAddress('recipientAddress', 'refund.field.recipientAddress'),
    renderText('recipientPhone', 'refund.field.recipientPhone'),
    renderText('recipientCellphone', 'refund.field.recipientCellphone'),
    renderSelect('billingId', 'refund.field.billingId'),
    renderMultiSelect('salesId', 'refund.field.salesId'),
    renderText('memo', 'refund.field.memo'),
    renderSelect('productVariantId', 'product.field.sku'),
    renderText('quantity', 'field.quantity', 'right'),
    {
      id: 'actions',
      align: 'right',
      headProps: {
        width: '100px',
        height: '56px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          top: 0,
          right: 0,
          zIndex: 1,
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      cellProps: {
        py: 2,
        width: '100px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          right: 0,
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'light.3',
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      render: ({ row, index }) => {
        const err = state.errorItems.find(
          ({ ticketNo }) => ticketNo === row.ticketNo,
        )
        return (
          <Center>
            <Icon
              color={err ? 'error.1' : 'light.3'}
              px={1}
              tooltip={err?.message}
              tooltipProps={{ place: 'bottom', type: 'error' }}
              value={<MdErrorOutline />}
            />
            <Button
              px={1}
              variant="icon"
              icon={<MdEdit />}
              onClick={() => action.handleOpen(row)}
            />
            <Button
              px={1}
              variant="icon"
              icon={<MdDelete />}
              onClick={() => {
                const ticketItems = [...state.ticketItems]
                ticketItems.splice(index, 1)
                setState({ ...state, ticketItems })
              }}
            />
          </Center>
        )
      },
    },
  ]
}

export const handlers = ({ app, session, state, setState, history }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()

    const { ticketItems } = state
    const inputErrors = validateRows(ticketItems, validation)
    if (inputErrors.length > 0) {
      setState({ ...state, errorItems: inputErrors })
      return
    }

    const result = await addRefundTickets(app, session, state)
    if (!result) return

    const errorItems = result.filter(({ ok }) => !ok)
    const successCount = result.reduce((count, { ok }) => {
      if (ok) count++
      return count
    }, 0)

    if (successCount > 0) {
      session.dispatch({
        type: ALERT_ADD,
        item: {
          type: 'success',
          message: {
            id: 'submit.batch.success',
            values: { count: successCount },
            texts: { name: 'module.refund' },
          },
        },
      })
    }

    if (errorItems.length === 0) {
      history.push('/refund')
      return
    }

    const idSet = new Set(errorItems.map(({ ticketNo }) => ticketNo))
    setState({
      ticketItems: ticketItems.filter(({ ticketNo }) => idSet.has(ticketNo)),
      errorItems,
    })
  },
  editTicketItem: ({ index, ...value }) => {
    const ticketItems = [...state.ticketItems]
    ticketItems.splice(index, 1, value)

    const errorItems = state.errorItems.filter(
      ({ ticketNo }) => ticketNo !== value.ticketNo,
    )

    setState({ ...state, ticketItems, errorItems })
  },
})

async function addRefundTickets(app, session, state) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: [TicketInput]!) {
      addRefundTickets(input: $input) {
        ok
        message
        ticketNo
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  return data.addRefundTickets
}

function getSubmitInput(state) {
  const { ticketItems } = state
  return ticketItems.reduce((result, item) => {
    const ticketNoStr = `${item.ticketNo || ''}`
    let ticket = result.find(({ ticketNo }) => ticketNo === ticketNoStr)
    if (!ticket) {
      const sales = item.salesId || []

      ticket = {
        ticketNo: ticketNoStr,
        transDate: item.transDate,
        fromLocationId: item.dealerId?.value,
        extra: {
          recipientId: item.recipientId.value,
          recipientName: item.recipientId.label,
          recipientContact: item.recipientContact,
          recipientAddress: item.recipientAddress,
          recipientPhone: item.recipientPhone,
          recipientCellphone: item.recipientCellphone,
          billingId: item.billingId?.value,
          billingName: item.billingId?.label,
          salesId: sales.map((item) => item.value),
          salesName: sales.map((item) => item.label),
          memo: item.memo,
          itemsToAdd: [],
        },
      }
      result.push(ticket)
    }
    addTicketItem(ticket, item)
    return result
  }, [])
}

function addTicketItem(ticket, item) {
  const ticketItem = ticket.extra.itemsToAdd.find(
    (i) => i.productVariantId === item.productVariantId?.value,
  )

  if (!ticketItem) {
    ticket.extra.itemsToAdd.push(getTicketItem(item))
    return
  }
}

function getTicketItem(item) {
  return {
    productVariantId: item.productVariantId?.value,
    quantity: parseInt(item.quantity || 0),
    extra: { isReceived: true },
  }
}
