import React from 'react'
import { request } from 'utilities/request'
import { showAddress, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link, LinkButton, StatusChip } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { FILTER_SET } from 'constants/actionType'
import { printHtml } from 'utilities/print'
import { Flex } from 'reflexbox'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { getLockStatusOptions, getStatusColor } from 'constants/status'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'transport.field.id',
    input: textFilter,
  },
  {
    id: 'parentId',
    label: 'field.parentId',
    input: textFilter,
  },
  {
    id: 'ticketDate',
    label: 'transport.field.ticketDate',
    input: dateFilter,
  },
  {
    id: 'transDate',
    label: 'transport.field.transDate',
    input: dateFilter,
  },
  {
    id: 'deliverDate',
    label: 'transport.field.deliverDate',
    input: dateFilter,
  },
  {
    id: 'shippingId',
    label: 'transport.field.shippingId',
    input: (props) => selectFilter({ ...props, options: data.shippings }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'pickupContact',
    label: 'transport.field.pickupContact',
    input: textFilter,
  },
  {
    id: 'deliverContact',
    label: 'transport.field.deliverContact',
    input: textFilter,
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
]

function getStatusOptions(message) {
  return [
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
    { value: 'PENDING', label: message({ id: 'status.PENDING' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'transport.field.id',
    format: ['html', 'excel', 'print'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/transport/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'parentId',
    label: 'transport.field.parentId',
    format: ['html', 'excel', 'print'],
    noWrap: true,
    render: ({ row }) => row.parentId,
  },
  {
    id: 'shipping',
    label: 'transport.field.shippingId',
    format: ['html', 'excel', 'print'],
    noWrap: true,
    render: ({ row }) => row.extra?.shippingName,
  },
  {
    id: 'fee',
    label: 'transport.field.fee',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => row.extra?.fee,
  },
  {
    id: 'ticketDate',
    label: 'transport.field.ticketDate',
    format: ['html', 'excel', 'print'],
    noWrap: true,
    render: ({ row }) => showDate(row.extra?.ticketDate),
  },
  {
    id: 'transDate',
    label: 'transport.field.transDate',
    format: ['html', 'excel', 'print'],
    noWrap: true,
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'pickupContact',
    label: 'transport.field.pickupContact',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => row.extra?.pickupContact,
  },
  {
    id: 'pickupAddress',
    label: 'transport.field.pickupAddress',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => showAddress(row.extra?.pickupAddress),
  },
  {
    id: 'pickupPhone',
    label: 'transport.field.pickupPhone',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => showAddress(row.extra?.pickupPhone),
  },
  {
    id: 'pickupCellphone',
    label: 'transport.field.pickupCellphone',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => showAddress(row.extra?.pickupCellphone),
  },
  {
    id: 'pickupMemo',
    label: 'transport.field.pickupMemo',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => showAddress(row.extra?.pickupMemo),
  },
  {
    id: 'deliverDate',
    label: 'transport.field.deliverDate',
    format: ['html', 'excel', 'print'],
    noWrap: true,
    render: ({ row }) => showDate(row.extra?.deliverDate),
  },
  {
    id: 'deliverContact',
    label: 'transport.field.deliverContact',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => row.extra?.deliverContact,
  },
  {
    id: 'deliverAddress',
    label: 'transport.field.deliverAddress',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => showAddress(row.extra?.deliverAddress),
  },
  {
    id: 'deliverPhone',
    label: 'transport.field.deliverPhone',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => row.extra?.deliverPhone,
  },
  {
    id: 'deliverMemo',
    label: 'transport.field.deliverMemo',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => row.extra?.deliverMemo,
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'itemProductVariantName',
    label: 'field.productName',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['excel'],
  },
  {
    id: 'productCode',
    label: 'field.productCode',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    format: ['excel'],
  },
  {
    id: 'itemMemo',
    label: 'transport.field.itemMemo',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.memo,
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    format: ['html'],
    renderHtml: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/transport/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'transport',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('transport', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setData,
  setFilterValues,
  setPagination,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ app, session })
    setData(resp)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'transportList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handlePrint: async ({ filterValues }) => {
    if (filterValues.length === 0) return

    const rows = await getExportData({ session, app, filterValues, message })
    const title = 'transport.title.list'
    const list = { columns: columns({ message }), rows }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    if (filterValues.length === 0) return

    const title = 'transport.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues, message })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getInit({ app, session }) {
  const variables = {
    input: { type: ['COMPANY'] },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        type
        name
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const shippings = []

  data.locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'COMPANY':
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  return { shippings }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      transportTicketCount(input: $input)
      transportTickets(input: $input) {
        id
        parentId
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.transportTickets,
    pagination: getPagination(page, countPerPage, data.transportTicketCount),
  }
}

async function getExportData({ session, app, filterValues, message }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      transportTickets(input: $input) {
        id
        parentId
        ticketNo
        fromLocationName
        itemProductVariantName
        productCode
        sku
        quantity
        transDate
        extra
        itemExtra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.transportTickets
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const parentId = filterValues.find(({ id }) => id === 'parentId')
  const ticketDate = filterValues.find(({ id }) => id === 'ticketDate')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const deliverDate = filterValues.find(({ id }) => id === 'deliverDate')
  const shippingId = filterValues.find(({ id }) => id === 'shippingId')
  const status = filterValues.find(({ id }) => id === 'status')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const pickupContact = filterValues.find(({ id }) => id === 'pickupContact')
  const deliverContact = filterValues.find(({ id }) => id === 'deliverContact')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const input = {
    joinItem,
    showProductCode: true,
    id: id?.value,
    parentId: parentId?.value,
    transDate: transDate?.value,
    status: status?.value.value,
    productVariantName: productName?.value,
    extra: {
      ticketDate: ticketDate?.value,
      deliverDate: deliverDate?.value,
      shippingId: shippingId?.value.value,
      pickupContact: pickupContact?.value,
      deliverContact: deliverContact?.value,
      locked: lockStatus?.value.value,
    },
    orderBy: [
      // { key: 'transDate', sort: 'DESC' },
      { key: 'id', sort: 'DESC' },
    ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
