import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { Address, PhoneInput, TextInput } from 'components/form'

export const initialState = () =>
  initializeState({
    name: '',
    phone: '',
    cellphone: '',
    address: {},
  })

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
  address: [{ type: 'address', message: 'error.invalidAddress' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    name: (
      <TextInput
        id="name"
        label="customer.field.name"
        placeholder="customer.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    phone: (
      <PhoneInput
        id="phone"
        label="customer.field.phone"
        value={state.phone}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    cellphone: (
      <PhoneInput
        id="cellphone"
        type="cellphone"
        label="customer.field.cellphone"
        value={state.cellphone}
        onChange={onTextChange('cellphone')}
        errMsg={state.__error__.cellphone}
      />
    ),
    address: (
      <Address
        id="address"
        label="field.address"
        placeholder="field.address"
        value={state.address}
        onChange={onTextChange('address')}
        errMsg={state.__error__.address}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, history }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const customerId = await addCustomer(state, app, session)
    if (!customerId) return

    history.push(`/customer/${customerId}/view`)
  },
  // handleDelete: (id) => async () => {
  //   const variables = { id }
  //   const query = `
  //     mutation($id: ID!) {
  //       deleteCustomer(id: $id)
  //     }
  //   `
  //   const [ok] = await request({ query, variables }, { session, app })
  //   return ok
  // },
})

async function addCustomer(state, app, session) {
  const variables = {
    input: {
      name: state.name,
      phones: state.phone ? [state.phone] : [],
      cellphones: state.cellphone ? [state.cellphone] : [],
      addresses: state.address ? [state.address] : [],
    },
  }
  const query = `
    mutation($input: CustomerInput!) {
      addCustomer(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.addCustomer
}
