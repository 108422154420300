export default {
  'staff.title.list': '員工目錄',
  'staff.title.add': '新增員工',
  'staff.title.edit': '更改員工',
  'staff.title.editRole': '更改員工權限',
  'staff.title.delete': '刪除員工',
  'staff.title.passwordReset': '重置密碼',
  'staff.btn.resetPassword': '重置密碼',
  'staff.message.delete': '確認刪除員工?',
  'staff.success.passwordReset': '成功重置密碼',
  'staff.section.basic': '員工信息',
  'staff.section.role': '管理角色',
  'staff.section.permission': '管理權限',
  'staff.section.warehouse': '管理倉庫',
  'staff.section.dept': '管理業務',
  'staff.section.dealer': '管理經銷商',
  'staff.field.name': '員工姓名',
  'staff.field.username': '帳戶',
  'staff.field.password': '密碼',
  'staff.field.newPassword': '新密碼',
  'staff.field.newPasswordConfirm': '確認新密碼',
  'staff.field.type': '部門',
  'staff.field.email': 'Email',
  'staff.field.role': '權限角色',
  'staff.field.status': '狀態',
  'staff.field.isActive': '在職中',
  'staff.type.OWNER': '擁有人',
  'staff.type.ADMIN': '管理員',
  'staff.type.TECHNICIAN': '技師',
  'staff.type.CUSTOMER_SUPPORT': '客服',
  'staff.type.SALES': '業務',
  'staff.type.WAREHOUSE': '倉管',
  'staff.type.DEALER': '廠商',
  'staff.status.ACTIVE': '在職',
  'staff.status.INACTIVE': '離職',
}
