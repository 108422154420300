export default {
  'error.role.notAllowed': '登錄用戶無執行權限',
  'role.title.list': '權限目錄',
  'role.title.add': '新增權限',
  'role.title.edit': '更改權限',
  'role.title.delete': '刪除權限',
  'role.message.delete': '確認刪除權限?',
  'role.section.role': '角色',
  'role.section.location': '管理門店',
  'role.section.permission': '管理權限',
  'role.field.name': '角色名稱',
  'role.field.module': '模塊',
  'role.field.action': '功能',
  'role.field.status': '狀態',
  'module.category': '商品類別管理',
  'category.add': '新增商品類別',
  'category.edit': '修改商品類別',
  'category.delete': '刪除商品類別',
  'category.read': '讀取商品類別',
  'module.customer': '客戶管理',
  'customer.add': '新增客戶',
  'customer.edit': '修改客戶',
  'customer.delete': '刪除客戶',
  'customer.read': '讀取客戶',
  'customer.addProduct': '新增客戶商品',
  'customer.editProduct': '修改客戶商品',
  'customer.deleteProduct': '刪除客戶商品',
  'customer.addMemo': '新增客服內容',
  'customer.editMemo': '修改客服內容',
  'customer.deleteMemo': '刪除客服內容',
  'customer.mergeCustomers': '合併客戶',
  'customer.switchCustomer': '轉換客戶',
  'customer.repairReport': '滿意度報告',
  'module.inventory': '庫存管理',
  'inventory.readAll': '讀取總倉庫存',
  'inventory.readDept': '讀取業務庫存',
  'inventory.readWarehouse': '讀取倉庫庫存',
  'inventory.readOrg': '讀取經銷群庫存',
  'inventory.readDealer': '讀取經銷商庫存',
  'inventory.readPart': '讀取零件庫存',
  'inventory.readLink': '讀取庫存歷史紀錄',
  'module.location': '系統管理',
  'location.addWarehouseGroup': '新增倉庫群',
  'location.editWarehouseGroup': '修改倉庫群',
  'location.deleteWarehouseGroup': '刪除倉庫群',
  'location.addWarehouse': '新增倉庫',
  'location.editWarehouse': '修改倉庫',
  'location.deleteWarehouse': '刪除倉庫',
  'location.addDept': '新增業務層',
  'location.editDept': '修改業務層',
  'location.deleteDept': '刪除業務層',
  'location.addOrg': '新增經銷群',
  'location.editOrg': '修改經銷群',
  'location.deleteOrg': '刪除經銷群',
  'location.addDealer': '新增經銷商',
  'location.editDealer': '修改經銷商',
  'location.deleteDealer': '刪除經銷商',
  'location.addCompany': '新增廠商',
  'location.editCompany': '修改廠商',
  'location.deleteCompany': '刪除廠商',
  'location.addPartWarehouse': '新增零件倉庫',
  'location.editPartWarehouse': '修改零件倉庫',
  'location.deletePartWarehouse': '刪除零件倉庫',
  'module.merchant': '商戶管理',
  'merchant.add': '新增商戶',
  'merchant.edit': '修改商戶',
  'merchant.delete': '刪除商戶',
  'merchant.read': '讀取商戶',
  'module.product': '商品管理',
  'product.add': '新增商品',
  'product.edit': '修改商品',
  'product.delete': '刪除商品',
  'product.read': '讀取商品',
  'product.addCombo': '新增組合商品',
  'product.editCombo': '修改組合商品',
  'product.deleteCombo': '刪除組合商品',
  'product.readCombo': '讀取組合商品',
  'product.addComboCode': '新增虛擬組合',
  'product.deleteComboCode': '刪除虛擬組合',
  'product.readComboCode': '讀取虛擬組合',
  'module.staff': '員工管理',
  'staff.add': '新增員工',
  'staff.edit': '修改員工',
  'staff.editRole': '修改員工權限',
  'staff.delete': '刪除員工',
  'staff.read': '讀取員工',
  'staff.addRole': '新增員工權限',
  'staff.deleteRole': '刪除員工權限',
  'staff.readRole': '讀取員工權限',
  'module.ticket': '訂單管理',
  'ticket.sync': '同步至三方系統',
  'ticket.addPurchase': '新增進倉單',
  'ticket.editPurchase': '修改進倉單',
  'ticket.confirmPurchase': '確認進倉單',
  'ticket.cancelPurchase': '取消進倉單',
  'ticket.lockPurchase': '鎖定進倉單',
  'ticket.readPurchase': '讀取進倉單',
  'ticket.addLock': '新增鎖貨單',
  'ticket.editLock': '修改鎖貨單',
  'ticket.confirmLock': '確認鎖貨單',
  'ticket.cancelLock': '取消鎖貨單',
  'ticket.lockLock': '鎖定鎖貨單',
  'ticket.readLock': '讀取鎖貨單',
  'ticket.addReverse': '新增歸還單',
  'ticket.editReverse': '修改歸還單',
  'ticket.confirmReverse': '確認歸還單',
  'ticket.cancelReverse': '取消歸還單',
  'ticket.lockReverse': '鎖定歸還單',
  'ticket.readReverse': '讀取歸還單',
  'ticket.addDispatch': '新增出貨單',
  'ticket.addDeferDispatch': '延出單轉出貨單',
  'ticket.editDispatch': '修改出貨單',
  'ticket.confirmDispatch': '確認出貨單',
  'ticket.cancelDispatch': '取消出貨單',
  'ticket.lockDispatch': '鎖定出貨單',
  'ticket.readDispatch': '讀取出貨單',
  'ticket.addSell': '新增銷貨單',
  'ticket.editSell': '修改銷貨單',
  'ticket.confirmSell': '確認銷貨單',
  'ticket.cancelSell': '取消銷貨單',
  'ticket.lockSell': '鎖定銷貨單',
  'ticket.readSell': '讀取銷貨單',
  'ticket.addRefund': '新增退貨單',
  'ticket.addRefundResult': '新增退處理項目',
  'ticket.editRefund': '修改退貨單',
  'ticket.addRefundAdjust': '新增退貨溢收',
  'ticket.editRefundAdjust': '修改退貨溢收',
  'ticket.delRefundAdjust': '刪除退貨溢收',
  'ticket.confirmRefund': '確認退貨單',
  'ticket.completeRefund': '完成退貨單',
  'ticket.cancelRefund': '取消退貨單',
  'ticket.lockRefund': '鎖定退貨單',
  'ticket.readRefund': '讀取退貨單',
  'ticket.addProcess': '新增加工單',
  'ticket.editProcess': '修改加工單',
  'ticket.confirmProcess': '確認加工單',
  'ticket.completeProcess': '強制完成加工單',
  'ticket.cancelProcess': '取消加工單',
  'ticket.lockProcess': '鎖定加工單',
  'ticket.readProcess': '讀取加工單',
  'ticket.addAdjust': '新增調整單',
  'ticket.editAdjust': '修改調整單',
  'ticket.confirmAdjust': '確認調整單',
  'ticket.cancelAdjust': '取消調整單',
  'ticket.lockAdjust': '鎖定調整單',
  'ticket.readAdjust': '讀取調整單',
  'ticket.addWarehouseMove': '新增調撥單（倉庫互調）',
  'ticket.addDeptMove': '新增調撥單（業務互調）',
  'ticket.addOrgMove': '新增調撥單（經銷群互調）',
  'ticket.addDealerMove': '新增調撥單（經銷商互調）',
  'ticket.editMove': '修改調撥單',
  'ticket.confirmMove': '確認調撥單',
  'ticket.cancelMove': '取消調撥單',
  'ticket.lockMove': '鎖定調撥單',
  'ticket.readMove': '讀取調撥單',
  'ticket.addTransferOut': '新增跨系統調出單',
  'ticket.editTransferOut': '修改跨系統調出單',
  'ticket.confirmTransferOut': '確認跨系統調出單',
  'ticket.cancelTransferOut': '取消跨系統調出單',
  'ticket.lockTransferOut': '鎖定跨系統調出單',
  'ticket.readTransferOut': '讀取跨系統調出單',
  'ticket.addTransferIn': '新增跨系統調入單',
  'ticket.editTransferIn': '修改跨系統調入單',
  'ticket.confirmTransferIn': '確認跨系統調入單',
  'ticket.cancelTransferIn': '取消跨系統調入單',
  'ticket.lockTransferIn': '鎖定跨系統調入單',
  'ticket.readTransferIn': '讀取跨系統調入單',
  'ticket.addDefer': '新增延出單',
  'ticket.editDefer': '修改延出單',
  'ticket.cancelDefer': '取消延出單',
  'ticket.lockDefer': '鎖定延出單',
  'ticket.lockDeferItem': '鎖定延出項目',
  'ticket.readDefer': '讀取延出單',
  'ticket.sendDeferEmail': '發送延出單通知',
  'ticket.addLend': '新增借貨單',
  'ticket.editLend': '修改借貨單',
  'ticket.cancelLend': '取消借貨單',
  'ticket.lockLend': '鎖定借貨單',
  'ticket.readLend': '讀取借貨單',
  'ticket.addRepair': '新增維修單',
  'ticket.editRepair': '修改維修單',
  'ticket.cancelRepair': '取消維修單',
  'ticket.lockRepair': '鎖定維修單',
  'ticket.readRepair': '讀取維修單',
  'ticket.addTransport': '新增派工單',
  'ticket.editTransport': '修改派工單',
  'ticket.confirmTransport': '確認派工單',
  'ticket.cancelTransport': '取消派工單',
  'ticket.lockTransport': '鎖定派工單',
  'ticket.readTransport': '讀取派工單',
  'ticket.readOutbox': '讀取待出貨訂單',
  'module.part': '零件管理',
  'part.addPart': '新增零件',
  'part.editPart': '修改零件',
  'part.readPart': '讀取零件',
  'part.deletePart': '刪除零件',
  'part.readInventory': '讀取庫存',
  'part.readReport': '讀取報表',
  'part.addTech': '新增維修人員',
  'part.editTech': '修改維修人員',
  'part.addBuy': '新增零件進貨單',
  'part.editBuy': '修改零件進貨單',
  'part.cancelBuy': '取消零件進貨單',
  'part.readBuy': '讀取零件進貨單',
  'part.addCheckout': '新增零件攜出單',
  'part.editCheckout': '修改零件攜出單',
  'part.cancelCheckout': '取消零件攜出單',
  'part.readCheckout': '讀取零件攜出單',
  'part.addCheckin': '新增零件歸還單',
  'part.editCheckin': '修改零件歸還單',
  'part.cancelCheckin': '取消零件歸還單',
  'part.readCheckin': '讀取零件歸還單',
  'part.addSell': '新增零件銷貨單',
  'part.editSell': '修改零件銷貨單',
  'part.cancelSell': '取消零件銷貨單',
  'part.readSell': '讀取零件銷貨單',
  'part.addAdjust': '新增零件調整單',
  'part.editAdjust': '修改零件調整單',
  'part.cancelAdjust': '取消零件調整單',
  'part.readAdjust': '讀取零件調整單',
  'part.addMove': '新增零件調撥單',
  'part.editMove': '修改零件調撥單',
  'part.confirmMove': '確認零件調撥單',
  'part.cancelMove': '取消零件調撥單',
  'part.readMove': '讀取零件調撥單',
  'module.role': '權限管理',
  'role.add': '新增權限',
  'role.edit': '修改權限',
  'role.delete': '刪除權限',
  'role.read': '讀取權限',
  'module.processConfig': '加工設定管理',
  'processConfig.add': '新增加工設定',
  'processConfig.edit': '修改加工設定',
  'processConfig.delete': '刪除加工設定',
  'processConfig.read': '讀取加工設定',
  'module.repair': '維修設定管理',
  'repair.addRepairType': '新增維修類型設定',
  'repair.editRepairType': '修改維修類型設定',
  'repair.cancelRepairType': '取消維修類型設定',
  'repair.readRepairType': '讀取維修類型設定',
  'repair.addRepairTag': '新增維修註記設定',
  'repair.cancelRepairTag': '取消維修註記設定',
  'repair.readRepairTag': '讀取維修註記設定',
  'module.plugin': '插件管理',
  'plugin.syConfig': '修改生洋設定',
  'plugin.sySync': '同步生洋庫存',
  'plugin.sdjConfig': '修改翔得捷設定',
  'plugin.sdjSync': '同步翔得捷庫存',
  'plugin.sdjInventoryDiff': '讀取翔得捷庫存差異',
  'plugin.sdjSendOutboxTicket': '翔得捷待出貨單立即出貨',
  'plugin.pxmartConfig': '修改全聯設定',
  'plugin.pxmartSync': '同步全聯訂單',
  'plugin.pxmartSyncErrors': '讀取全聯同步錯誤列表',
  'plugin.deletePxmartSyncErrors': '刪除全聯同步錯誤信息',
  'plugin.carrefourConfig': '修改家樂福設定',
  'plugin.carrefourSync': '同步家樂福訂單',
  'plugin.carrefourSyncErrors': '讀取家樂福同步錯誤列表',
  'plugin.deleteCarrefourSyncErrors': '刪除家樂福同步錯誤信息',
  'plugin.mrWarehouseConfig': '修改新慎康倉管設定',
  'plugin.vicInvoiceSync': '同步慎康訂單系統',
  'plugin.vicInvoiceLogin': '登錄慎康訂單系統',
  'plugin.bestConfig': '修改百事達設定',
  'plugin.bestSync': '同步百事達訂單',
  'module.report': '報表匯出',
  'report.readWarehouseStock': '讀取庫存存量表',
  'report.readLocationStock': '讀取通路進銷表',
  'report.readLocationSales': '讀取通路業績',
  'report.readStaffSales': '讀取業務業績',
  'report.readRepairReport': '讀取維修紀錄表',
  'report.readRefund': '讀取退貨統計表',
  'report.updateReport': '更新報表',
  'report.readRestock': '讀取商品補貨清單',
  'report.readCollect': '讀取商品允收檢核表',
}
