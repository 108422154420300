import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  getSelectOption,
  getMultiSelectOption,
  getSelectOptions,
  getDate,
  validateForm,
  showAddress,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getLocation, parseRecipient, fetchLocations } from 'actions/location'
import { fetchBalances } from 'actions/inventory'
import { getRecipients, handleDelete, setBalances } from 'actions/ticket'
import {
  Definition,
  Button,
  Table,
  Link,
  Icon,
  StatusChip,
} from 'components/core'
import {
  TextInput,
  TextArea,
  Select,
  Address,
  DateInput,
  PhoneInput,
} from 'components/form'
import { MdCheck, MdDelete, MdEdit } from 'react-icons/md'
import { filterLocations, hasAccess } from 'utilities/permission'
import { getStatusColor } from 'constants/status'

export const initialState = (value = {}, message) => {
  const recipients = getRecipients(value.recipients, message)
  const sales = getSelectOptions(value.sales)
  const dealerId = value.fromLocationId ||  value.toLocationId
  const dealerName = value.fromLocationName || value.toLocationName 
  return {
    isReady: value.isReady || false,
    id: value.id || '',
    locked: value.locked || false,
    parentId: value.parentId || '',
    parentType: value.parentType || '',
    status: value.status || 'PENDING',
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems,
    dealers: value.dealers,
    dealerCode: value.extra?.dealerCode || '',
    recipients,
    billings: value.billings,
    sales,
    salesName: value.extra?.salesName || [],
    backupName: value.extra?.backupName || [],
    products: value.products || [],
    ...initializeState({
      transDate: getDate(value.transDate),
      ticketNo: value.ticketNo || '',
      dealerId: getSelectOption(value.dealers, dealerId, 'id', 'name'),
      dealerName: dealerName || '',
      recipientId: getSelectOption(
        recipients,
        value.extra?.recipientId,
        'id',
        'name',
        false,
      ),
      recipientName: value.extra?.recipientName || '',
      recipientContact: value.extra?.recipientContact || '',
      recipientAddress: value.extra?.recipientAddress || {},
      recipientPhone: value.extra?.recipientPhone || '',
      recipientCellphone: value.extra?.recipientCellphone || '',
      billingId: getSelectOption(value.billings, value.extra?.billingId),
      billingName: value.extra?.billingName || '',
      salesId: getMultiSelectOption(sales, value.extra?.salesId),
      backupId: getMultiSelectOption(sales, value.extra?.backupId),
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  dealerId: [{ type: 'required', message: 'error.required' }],
  recipientContact: [{ type: 'required', message: 'error.required' }],
  recipientAddress: [
    { type: 'required', message: 'error.required' },
    { type: 'address', message: 'error.invalidAddress' },
  ],
  salesId: [{ type: 'required', message: 'error.required' }],
  backupId: [{ type: 'required', message: 'error.required' }],
}

const defs = (state, message) => ({
  id: state.id,
  parentId: state.parentId,
  transDate: state.transDate,
  ticketNo: state.ticketNo,
  dealer: state.dealerName,
  dealerCode: state.dealerCode,
  recipient: state.recipientName,
  recipientContact: state.recipientContact,
  recipientAddress: showAddress(state.recipientAddress, message),
  recipientPhone: state.recipientPhone,
  recipientCellphone: state.recipientCellphone,
  sales: state.salesName,
  backup: state.backupName,
  memo: state.memo,
  billing: state.billingName,
})

const columns = ({ message }) => [
  {
    id: 'productVariantName',
    label: 'sell.field.spu',
  },
  {
    id: 'isDemo',
    label: 'dispatch.field.isDemo',
    renderHtml: ({ row }) => (
      <Icon
        show={row.extra?.isDemo || false}
        color="success.1"
        value={<MdCheck />}
      />
    ),
    render: ({ row }) => (row.extra?.isDemo ? '&check;' : ''),
  },
  {
    id: 'isGift',
    label: 'dispatch.field.isGift',
    renderHtml: ({ row }) => (
      <Icon show={row.isGift || false} color="success.1" value={<MdCheck />} />
    ),
    render: ({ row }) => (row.isGift ? '&check;' : ''),
  },
  {
    id: 'memo',
    label: 'field.memo',
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'quantity',
    label: 'sell.field.quantity',
    align: 'right',
  },
  {
    id: 'unitPrice',
    label: 'sell.field.unitPrice',
    align: 'right',
    render: ({ row }) => row.extra?.unitPrice,
  },
  {
    id: 'price',
    label: 'sell.field.price',
    align: 'right',
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`itemStatus.${row.status || 'ACTIVE'}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) =>
      message({ id: `defer.itemStatus.${row.status || 'ACTIVE'}` }),
  },
]

export const labels = ({ state, message }) => {
  const parentModule = getParentModule(state.parentType)
  const defItems = Object.entries(defs(state, message))
  const content = defItems.reduce((result, [id, value]) => {
    const label = `sell.field.${id}`

    switch (id) {
      case 'parentId':
        result[id] = (
          <Definition
            show={!!state.parentId}
            label="field.parentId"
            value={state.parentId}
          >
            <Link
              mt={1}
              variant="primaryLink"
              href={`/${parentModule}/${state.parentId}/view`}
            >
              {state.parentId}
            </Link>
          </Definition>
        )
        break
      case 'sales':
      case 'backup':
        result[id] = (
          <Definition
            label={label}
            value={value.map((item) => item).join(', ')}
          />
        )
        break
      default:
        result[id] = <Definition label={label} value={value} />
    }

    return result
  }, {})

  content.product = (
    <Table showSeq columns={columns({ message })} rows={state.ticketItems} />
  )
  return content
}

function getParentModule(ticketType) {
  if (!ticketType) return 'dispatch'
  return ticketType.toLowerCase()
}

export const fields = ({ app, session, state, setState, message, action }) => {
  const parentModule = getParentModule(state.parentType)
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    id: (
      <Definition show={!!state.id} label="field.ticketId" value={state.id} />
    ),
    parentId: (
      <Definition
        show={!!state.parentId}
        label="field.parentId"
        value={state.parentId}
      >
        <Link
          mt={1}
          variant="primaryLink"
          href={`/${parentModule}/${state.parentId}/view`}
        >
          {state.parentId}
        </Link>
      </Definition>
    ),
    transDate: (
      <DateInput
        id="transDate"
        label="sell.field.transDate"
        value={state.transDate}
        role="lockSellTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="sell.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
        errMsg={state.__error__.ticketNo}
      />
    ),
    billing: (
      <Select
        label="sell.field.billing"
        isClearable={false}
        options={state.billings}
        value={state.billingId}
        onChange={onSelectChange('billingId')}
        errMsg={state.__error__.billingId}
      />
    ),
    dealer: (
      <Select
        label="sell.field.dealer"
        isClearable={false}
        options={getSelectOptions(state.dealers)}
        value={state.dealerId}
        onChange={onSelectChange('dealerId', ({ value }) =>
          onDealerChange(state, app, session, message, value),
        )}
        errMsg={state.__error__.dealerId}
      />
    ),
    dealerCode: (
      <TextInput
        id="dealerCode"
        label="sell.field.dealerCode"
        value={state.dealerCode}
        onChange={onTextChange('dealerCode')}
        errMsg={state.__error__.dealerCode}
      />
    ),
    recipient: (
      <Select
        isClearable={false}
        label="sell.field.recipient"
        options={getSelectOptions(state.recipients)}
        value={state.recipientId}
        onChange={onSelectChange('recipientId', (item) => {
          const recipient = getLocation(state.recipients, item?.value)
          return parseRecipient(recipient, state)
        })}
        errMsg={state.__error__.recipientId}
      />
    ),
    recipientContact: (
      <TextInput
        id="recipientContact"
        label="sell.field.recipientContact"
        value={state.recipientContact}
        onChange={onTextChange('recipientContact')}
        errMsg={state.__error__.recipientContact}
      />
    ),
    recipientAddress: (
      <Address
        id="recipientAddress"
        label="sell.field.recipientAddress"
        placeholder="sell.field.recipientAddress"
        value={state.recipientAddress}
        onChange={onTextChange('recipientAddress')}
        errMsg={state.__error__.recipientAddress}
      />
    ),
    recipientPhone: (
      <PhoneInput
        id="recipientPhone"
        label="sell.field.recipientPhone"
        value={state.recipientPhone}
        onChange={onTextChange('recipientPhone')}
        errMsg={state.__error__.recipientPhone}
      />
    ),
    recipientCellphone: (
      <PhoneInput
        id="recipientCellphone"
        label="sell.field.recipientCellphone"
        type="cellphone"
        value={state.recipientCellphone}
        onChange={onTextChange('recipientCellphone')}
        errMsg={state.__error__.recipientCellphone}
      />
    ),
    sales: (
      <Select
        label="sell.field.sales"
        isClearable={false}
        isMulti
        options={state.sales}
        value={state.salesId}
        onChange={onSelectChange('salesId')}
        errMsg={state.__error__.salesId}
      />
    ),
    backup: (
      <Select
        disabled={!hasAccess(app.state.staff, 'lockSellTicket')}
        label="defer.field.backupId"
        isClearable={false}
        isMulti
        options={state.sales}
        value={state.backupId}
        onChange={onSelectChange('backupId')}
        errMsg={state.__error__.backupId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="sell.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    product: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'sell.field.spu',
          },
          {
            id: 'isDemo',
            label: 'dispatch.field.isDemo',
            render: ({ row }) => (
              <Icon
                show={row.extra?.isDemo || false}
                color="success.1"
                value={<MdCheck />}
              />
            ),
          },
          {
            id: 'isGift',
            label: 'dispatch.field.isGift',
            render: ({ row }) => (
              <Icon
                show={row.isGift || false}
                color="success.1"
                value={<MdCheck />}
              />
            ),
          },
          {
            id: 'memo',
            label: 'field.memo',
            render: ({ row }) => row.extra?.memo,
          },
          {
            id: 'balance',
            label: 'sell.field.balance',
            align: 'right',
          },
          {
            id: 'quantity',
            label: 'sell.field.quantity',
            align: 'right',
          },
          {
            id: 'unitPrice',
            label: 'sell.field.unitPrice',
            align: 'right',
            render: ({ row }) => row.extra?.unitPrice,
          },
          {
            id: 'price',
            label: 'sell.field.price',
            align: 'right',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <>
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() =>
                    action.handleOpen({
                      ...row,
                      oldTicketItem: state.oldTicketItems[index],
                      index,
                    })
                  }
                />
                <Button
                  icon={<MdDelete />}
                  variant="icon"
                  onClick={() => {
                    const ticketItems = cloneDeep(state.ticketItems)
                    ticketItems.splice(index, 1)
                    setState({ ...state, ticketItems })
                  }}
                />
              </>
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  message,
  id,
  parentId,
  parentType,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({
      app,
      session,
      id,
      parentId,
      parentType,
      profile,
    })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editSell(state, app, session)
      : await addSell(state, app, session)
    if (!ok) return

    if (!id) {
      id = data.addSellTicket
    }

    history.push(`/sell/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  addItem: (value) => {
    const ticketItems = [...state.ticketItems]
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('sell', { session, app, id, hash })
    if (!ok) return false

    history.push('/sell')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state, message })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ state, message }) {
  const title = { id: 'sell.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const field = Object.entries(defs(state, message)).map(([id, value]) => {
    const label = `sell.field.${id}`
    switch (id) {
      case 'sales':
      case 'backup':
        return { label, value: value.map((item) => item).join(', ') }
      default:
        return { label, value }
    }
  })
  const list = {
    columns: columns({ message }),
    rows: state.ticketItems.filter((item) => item.status !== 'INACTIVE'),
    showSeq: true,
  }
  const content = [
    { type: 'field', display: 'inline', value: field },
    { type: 'list', value: list },
    {
      type: 'text',
      value: `${message({ id: 'field.total' })}: ${getTotalAmount(state)}`,
      className: 'right',
    },
  ]
  return { title, header, content }
}

export async function getData({
  app,
  session,
  id,
  parentId,
  parentType,
  profile,
}) {
  const staffInput = { type: ['SALES'] }
  const locationInput = { type: ['DEALER', 'COMPANY'] }
  const variables = { id: parentId || id, locationInput, staffInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
      }
      productVariants {
        id
        name
        sku
        barcode
      }
      sellTicket(id: $id) {
        parentId
        ticketNo
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      sellTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
        price
        balance
        isGift
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { staffs, productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.sellTicket || {}
  const ticketItems = data.sellTicketItems
    .filter((item) => {
      let isValid = !parentId || (!item.extra?.isReturn && item.balance > 0)
      if (profile === 'edit' && isValid) isValid = item.status !== 'INACTIVE'
      return isValid
    })
    .map((item) => {
      if (item.balance > 0) item.quantity = item.balance
      return item
    })
  const extra = ticket.extra || {}
  const dealers = []
  const billings = []
  let recipients = []
  let locationId = ticket.fromLocationId
  // let locationName = ticket.fromLocationName

  if (parentId) {
    locationId = ticket.toLocationId
    // locationName = ticket.toLocationName
  }

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'DEALER':
        dealers.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'BILLING') billings.push(result)
        break
      default:
        break
    }
  })

  if ((id || parentId) && locationId) {
    const locationInput = { parentId: locationId, type: ['STORAGE'] }
    recipients = await fetchLocations(app, session, locationInput)
  }

  if ((id || parentId) && ticketItems.length > 0) {
    const deptBalances = await getBalances(
      app,
      session,
      ticketItems,
      ticketItems,
      locationId,
    )
    setBalances(ticketItems, deptBalances)
  }

  return {
    isReady: profile === 'edit',
    id,
    extra: ticket.extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    locked: ticket.extra?.locked,
    parentId: parentId || ticket.parentId,
    parentType: parentType || extra.parentType,
    transDate: ticket.transDate,
    ticketNo: ticket.ticketNo,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    // dealerCode: extra.dealerCode,
    // recipientId: extra.recipientId,
    // recipientName: extra.recipientName,
    // recipientContact: extra.recipientContact,
    // recipientAddress: extra.recipientAddress,
    // recipientPhone: extra.recipientPhone,
    // recipientCellphone: extra.recipientCellphone,
    // billingId: extra.billingId,
    // billingName: extra.billingName,
    // salesId: extra.salesId,
    // salesName: extra.salesName,
    // backupId: extra.backupId,
    // backupName: extra.backupName,
    // memo: extra.memo,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems,
    oldTicketItems: profile === 'add' ? [] : cloneDeep(ticketItems),
    dealers,
    recipients,
    billings,
    sales: staffs,
    products: productVariants,
  }
}

async function onDealerChange(state, app, session, message, dealerId) {
  const { oldTicketItems, dealers, billings } = state
  const ticketItems = [...state.ticketItems]
  const dealer = getLocation(dealers, dealerId)
  const extra = dealer.extra || {}
  const balances = await getBalances(
    app,
    session,
    ticketItems,
    oldTicketItems,
    dealerId,
  )
  setBalances(ticketItems, balances)

  const locationInput = { parentId: dealerId, type: ['STORAGE'] }
  const recipients = await fetchLocations(app, session, locationInput)

  const oldBackupId = state.backupId || []
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))

  return {
    dealerCode: dealer?.extra.code || '',
    backupId: backupId || oldBackupId,
    billingId: getSelectOption(billings, dealer?.extra.billingId),
    recipients: getRecipients(recipients, message),
    ticketItems,
  }
}

async function getBalances(
  app,
  session,
  ticketItems,
  oldTicketItems,
  dealerId,
) {
  if (ticketItems.length === 0) return []

  const productVariantId = ticketItems.map((item) => item.productVariantId)
  return fetchBalances({
    app,
    session,
    locationType: 'DEALER',
    locationId: dealerId,
    productVariantId,
    oldTicketItems,
  })
}

async function addSell(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addSellTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editSell(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editSellTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, parentId, parentType, hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => {
    const { extra } = item
    if (parentId && parentType === 'LEND' && !extra.parentItemId) {
      extra.parentItemId = item.id
    }
    return {
      id: item.id,
      productVariantId: item.productVariantId,
      quantity: parseInt(item.quantity),
      price: parseFloat(item.price),
      isGift: item.isGift,
      extra,
    }
  })
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (item.price !== newItem.price) return false
    if (item.isGift !== newItem.isGift) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)
  const backupId = state.backupId || []

  return {
    id,
    parentId,
    hash,
    ticketNo: state.ticketNo,
    transDate: state.transDate,
    fromLocationId: state.dealerId.value,
    extra: {
      parentType,
      recipientId: state.recipientId.value,
      recipientName: state.recipientId.label,
      recipientContact: state.recipientContact,
      recipientAddress: state.recipientAddress,
      recipientPhone: state.recipientPhone,
      recipientCellphone: state.recipientCellphone,
      dealerCode: state.dealerCode,
      billingId: state.billingId.value,
      billingName: state.billingId.label,
      salesId: state.salesId.map((item) => item.value),
      salesName: state.salesId.map((item) => item.label),
      backupId: backupId.map((item) => item.value),
      backupName: backupId.map((item) => item.label),
      memo: state.memo,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

export function getTotalAmount(state) {
  const { ticketItems = [] } = state
  const total = ticketItems.reduce((result, item) => {
    if (item.status === 'INACTIVE') return result
    result += parseFloat(item.price || 0)
    return result
  }, 0)
  return Math.round(total * 100) / 100
}
