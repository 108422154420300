import React from 'react'
import {
  Select,
  TextInput,
  NumberInput,
  TextArea,
  DateInput,
} from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
} from 'utilities/form'
import { getLocationOptions } from './adjustPage'

export const initialState = (value = {}, data) => ({
  index: value.index,
  ...initializeState({
    ticketNo: value.ticketNo || '',
    transDate: value.transDate || '',
    toLocationId: value.toLocationId || '',
    memo: value.memo || '',
    productVariantId: value.productVariantId || {},
    quantity: value.quantity || 0,
  }),
})

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  toLocationId: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="adjust.field.transDate"
        placeholder="adjust.field.transDate"
        value={state.transDate}
        role="lockAdjustTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="adjust.field.ticketNo"
        placeholder="adjust.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
        errMsg={state.__error__.ticketNo}
      />
    ),
    toLocationId: (
      <Select
        label="adjust.field.toLocation"
        isClearable={false}
        options={getLocationOptions(data.locations, message)}
        value={state.toLocationId}
        onChange={onSelectChange('toLocationId')}
        errMsg={state.__error__.toLocationId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="adjust.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    productVariantId: (
      <Select
        id="productVariantId"
        label="product.field.sku"
        placeholder="product.field.sku"
        isClearable={false}
        options={data.products}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        label="field.quantity"
        placeholder="field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.editTicketItem(state)
    action.handleClose()
  },
})
