import React from 'react'
import { CONFIRM_SET, FILTER_SET } from 'constants/actionType'
import { Box } from 'reflexbox'
import { LinkButton, Button, StatusChip } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete, MdEdit } from 'react-icons/md'
import { getLimit, getPagination } from 'utilities/pagination'
import { selectFilter, textFilter } from 'utilities/filter'
import { getStatusColor } from 'constants/status'
import { showAddress } from 'utilities/form'

export const filters = ({ data }) => [
  {
    id: 'name',
    label: 'dealer.field.dealer',
    input: textFilter,
  },
  {
    id: 'org',
    label: 'dealer.field.org',
    input: (props) => selectFilter({ ...props, options: data.orgs }),
  },
  // {
  //   id: 'billing',
  //   label: 'field.billing',
  //   input: selectFilter({
  //     filterInput,
  //     setFilterInput,
  //     options: data.billings,
  //   }),
  // },
]

export const columns = ({ session, app, data, message, action }) => [
  {
    id: 'name',
    label: 'dealer.field.dealer',
    format: ['html', 'excel'],
  },
  {
    id: 'org',
    label: 'dealer.field.org',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.orgMap) return ''
      return data.orgMap[row.parentId]
    },
  },
  {
    id: 'type',
    label: 'dealer.field.type',
    format: ['html', 'excel'],
    render: ({ row }) => message({ id: `dealer.type.${row.extra.type}` }),
  },
  {
    id: 'billing',
    label: 'dealer.field.billing',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.billingMap) return ''
      return data.billingMap[row.extra.billing]
    },
  },
  {
    id: 'contact',
    label: 'dealer.field.contact',
    format: ['html'],
    renderHtml: ({ row }) => (
      <>
        <Box>{row.extra.phone}</Box>
        <Box>{row.extra.address}</Box>
      </>
    ),
  },
  {
    id: 'phone',
    label: 'dealer.field.phone',
    format: ['excel'],
    render: ({ row }) => row.extra.phone,
  },
  {
    id: 'contractFromDate',
    label: 'dealer.field.contractFromDate',
    format: ['excel'],
    render: ({ row }) => row.extra.contractFromDate,
  },
  {
    id: 'contractToDate',
    label: 'dealer.field.contractToDate',
    format: ['excel'],
    render: ({ row }) => row.extra.contractToDate,
  },
  {
    id: 'contractSignDate',
    label: 'dealer.field.contractSignDate',
    format: ['excel'],
    render: ({ row }) => row.extra.contractSignDate,
  },
  {
    id: 'address',
    label: 'dealer.field.address',
    format: ['excel'],
    render: ({ row }) => row.extra.address,
  },
  {
    id: 'storageName',
    label: 'dealerStorage.field.name',
    format: ['excel'],
    render: ({ row }) => row.childName,
  },
  {
    id: 'storageCode',
    label: 'dealerStorage.field.code',
    format: ['excel'],
    render: ({ row }) => row.childExtra.code,
  },
  {
    id: 'storageContact',
    label: 'dealerStorage.field.contactName',
    format: ['excel'],
    render: ({ row }) => row.childContact,
  },
  {
    id: 'storagePhone',
    label: 'dealerStorage.field.phone',
    format: ['excel'],
    render: ({ row }) => row.childExtra.phone,
  },
  {
    id: 'storageCellphone',
    label: 'dealerStorage.field.cellphone',
    format: ['excel'],
    render: ({ row }) => row.childExtra.cellphone,
  },
  {
    id: 'storageAddress',
    label: 'dealerStorage.field.address',
    format: ['excel'],
    render: ({ row }) => showAddress(row.childExtra.address, message),
  },
  {
    id: 'storageShipping',
    label: 'dealer.field.shipping',
    format: ['excel'],
    render: ({ row }) => row.childExtra.shippingName,
  },
  {
    id: 'storageSales',
    label: 'dealer.field.sales',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.childExtra.salesName) return null
      return row.childExtra.salesName.join(', ')
    },
  },
  {
    id: 'status',
    label: 'dealer.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`dealer.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `dealer.status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    format: ['html'],
    renderHtml: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          href={`/dealer/${row.id}/edit`}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            const item = {
              open: true,
              title: 'dealer.title.delete',
              text: 'dealer.message.delete',
              onSubmit: handleDelete({ session, app, action, id: row.id }),
            }
            session.dispatch({ type: CONFIRM_SET, item })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  data,
  message,
  setState,
  setFilterValues,
  setPagination,
  setData,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'dealerList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const data = await getOptions({ session, app, pagination, filterValues })
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    setData(data)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'dealer.title.list'
    const cols = columns({ data, message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getOptions({ session, app }) {
  const variables = { input: { type: ['DEALER_ORG', 'COMPANY'] } }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        type
        name
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  const orgs = []
  const orgMap = {}
  const billings = []
  const billingMap = {}
  data.locations.forEach((item) => {
    const { type, extra } = item
    const option = { value: item.id, label: item.name }
    switch (type) {
      case 'DEALER_ORG':
        orgs.push(option)
        orgMap[item.id] = item.name
        break
      default:
        if (extra.type === 'BILLING') {
          billings.push(option)
          billingMap[item.id] = item.name
        }
        break
    }
  })

  return { orgs, orgMap, billings, billingMap }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: LocationQueryInput) {
      locationCount(input: $input)
      locations(input: $input) {
        id
        parentId
        type
        name
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  const { page, countPerPage } = pagination
  return {
    data: data.locations,
    pagination: getPagination(page, countPerPage, data.locationCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinChild: true }) }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        parentId
        type
        name
        extra
        status
        childName
        childContact
        childExtra
        childStatus
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.locations
}

function getDataInput({ filterValues, pagination, joinChild = false }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const org = filterValues.find(({ id }) => id === 'org')
  // const billing = filterValues.find(({ id }) => id === 'billing')
  const input = {
    joinChild,
    type: ['DEALER'],
    name: name?.value,
    parentId: org?.value.value,
    status: ['ACTIVE', 'INACTIVE'],
    // billing: billing?.value.value,
    orderBy: [{ key: 'name' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

const handleDelete =
  ({ session, app, action, id }) =>
  async () => {
    const variables = { id }
    const query = `
    mutation($id: ID!) {
      deleteDealer(id: $id)
    }
  `
    const [ok] = await request({ query, variables }, { session, app })
    if (ok) {
      action.handleLoad()
    }

    return ok
  }
