import React from 'react'
import { Definition } from 'components/core'
import { Select, NumberInput, TextArea, Switch } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOptions,
  getSelectOption,
} from 'utilities/form'
import { fetchBalance } from 'actions/inventory'

export const initialState = (value = {}, data) => {
  const productOptions = getSelectOptions(data.products)
  return {
    index: value.index,
    id: value.id || '',
    parentId: value.parentId,
    parentItemId: value.extra?.parentItemId,
    productVariantName: value.productVariantName || '',
    balance: value.balance || 0,
    products: data.products,
    productOptions,
    ...initializeState({
      productVariantId: getSelectOption(productOptions, value.productVariantId),
      quantity: value.quantity || 0,
      unitPrice: value.extra?.unitPrice || 0,
      price: value.price || 0,
      isDemo: value.extra?.isDemo || false,
      isGift: value.isGift || false,
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const labels = ({ state }) => {
  return {
    memo: <Definition label="field.memo" value={state.memo} />,
  }
}

export const fields = ({ app, session, state, setState, data }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    productVariantId:
      (data.profile === 'edit' && !!state.id) || state.parentId ? (
        <Definition
          direction="row"
          label="product.field.spu"
          value={state.productVariantName}
        />
      ) : (
        <Select
          id="productVariantId"
          label="product.field.spu"
          placeholder="product.field.spu"
          isClearable={false}
          options={state.productOptions}
          value={state.productVariantId}
          onChange={onSelectChange('productVariantId', async ({ value }) => {
            const balance = await fetchBalance({
              app,
              session,
              locationType: 'DEALER',
              locationId: data.dealerId,
              productVariantId: value,
            })
            return { balance }
          })}
          errMsg={state.__error__.productVariantId}
        />
      ),
    isDemo: (
      <Definition
        direction="row"
        label="dispatch.field.isDemo"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isDemo}
          onClick={() => {
            setState({ ...state, isDemo: !state.isDemo })
          }}
        />
      </Definition>
    ),
    isGift: (
      <Definition
        direction="row"
        label="dispatch.field.isGift"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isGift}
          onClick={() => {
            setState({ ...state, isGift: !state.isGift })
          }}
        />
      </Definition>
    ),
    balance: (
      <Definition
        show={data.profile !== 'view'}
        direction="row"
        label="inventory.field.balance"
        value={state.balance}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        // min="0"
        // max={state.balance}
        label="sell.field.quantity"
        placeholder="sell.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity', (value) => {
          const price = computePrice(value, state.unitPrice)
          return { price }
        })}
        errMsg={state.__error__.quantity}
      />
    ),
    unitPrice: (
      <NumberInput
        type="decimal"
        id="unitPrice"
        label="sell.field.unitPrice"
        placeholder="sell.field.unitPrice"
        value={state.unitPrice}
        onChange={onTextChange('unitPrice', (value) => {
          const price = computePrice(state.quantity, value)
          return { price }
        })}
        errMsg={state.__error__.unitPrice}
      />
    ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="sell.field.price"
        placeholder="sell.field.price"
        autoComplete="off"
        value={state.price}
        onChange={onTextChange('price', (value) => {
          const quantity = parseInt(state.quantity || 0)
          if (quantity === 0) return

          const price = parseFloat(value || 0)
          const unitPrice = Math.round((price / quantity) * 100) / 100
          return { unitPrice }
        })}
        errMsg={state.__error__.price}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

function computePrice(quantity, unitPrice) {
  const q = parseInt(quantity || 0)
  const unit = parseFloat(unitPrice || 0)
  return Math.round(q * unit * 100) / 100
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { productVariantId, quantity, price, isGift, memo } = state
    action.addItem({
      index: state.index,
      id: state.id,
      productVariantId: productVariantId.value,
      productVariantName: productVariantId.label,
      balance: state.balance,
      quantity,
      price: parseFloat(price || 0),
      isGift,
      extra: {
        parentItemId: state.parentItemId,
        unitPrice: parseFloat(state.unitPrice || 0),
        memo,
        isDemo: state.isDemo,
      },
    })
    action.handleClose()
  },
})
