import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { renderDeleteLink, renderLockLink } from 'utilities/app'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { initialState, fields, handlers } from 'models/lendPage'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Toolbar,
} from 'components/core'
import Page from 'components/Page'
import LendReturnDrawer from 'views/LendReturnDrawer'
import { MdAdd, MdArrowBack, MdEdit, MdPrint } from 'react-icons/md'
import { handleLock } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { hasAccess } from 'utilities/permission'

export default ({ match, profile }) => {
  const { id } = match.params
  const url = new URL(window.location.href)
  const bypass = url.searchParams.get('bypass')
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState()

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const readonly = profile === 'view'
  const form = fields({
    profile,
    app,
    session,
    state,
    setState,
    message,
    bypass,
  })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    history,
    id,
    profile,
    bypass,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <>
      <Page
        profile={profile}
        navId="lendList"
        title={`lend.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`lend.status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/lend/${id}/view` : '/lend'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'lend',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.locked || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/lend/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handleLock({
                module: 'lend',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.transDate}
            {form.dealer}
            {form.recipient}
          </Section>
          <Section>
            {form.warehouse}
            {form.shipping}
            {form.sales}
            {form.memo}
          </Section>
          <Section>
            {form.recipientContact}
            {form.recipientAddress}
            {form.recipientPhone}
            {form.recipientCellphone}
          </Section>
        </Grid>
        <Section mt={5} title="lend.section.product">
          {form.product}
        </Section>
        <Grid mt={5} show={readonly} cols={['1fr', '1fr 1fr']} gap={3}>
          <Section title="lend.section.dispatch">
            <Toolbar>
              <Button
                // disabled={!hasAccess(app.state.staff, 'confirmDispatchTicket')}
                disabled
                variant="default"
                icon={<MdAdd />}
                text="btn.add"
                onClick={() =>
                  history.push(`/dispatch/add?parentId=${id}&parentType=LEND`)
                }
              />
            </Toolbar>
            {form.dispatchTickets}
          </Section>

          <Section title="lend.section.sell">
            <Toolbar>
              <Button
                disabled={!hasAccess(app.state.staff, 'confirmDispatchTicket')}
                variant="default"
                icon={<MdAdd />}
                text="btn.add"
                onClick={() =>
                  history.push(`/sell/add?parentId=${id}&parentType=LEND`)
                }
              />
            </Toolbar>
            {form.sellTickets}
          </Section>
        </Grid>
        <Section mt={5} show={readonly} title="lend.section.return">
          <Toolbar show={readonly}>
            <Button
              disabled={!hasAccess(app.state.staff, 'editLendTicket')}
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
          {form.returnItems}
        </Section>
      </Page>
      <LendReturnDrawer
        open={open}
        value={value}
        data={{
          id: state.id,
          hash: state.hash,
          products: state.allProducts,
          warehouses: state.warehouses,
          ticketItems: state.ticketItems,
        }}
        readonly={readonly}
        action={{ handleLoad: handler.handleLoad, handleClose }}
      />
    </>
  )
}
