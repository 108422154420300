import React from 'react'
import { validateForm, showDate } from 'utilities/form'
import { request } from 'utilities/request'
import { printHtml } from 'utilities/print'
import { handleDelete } from 'actions/ticket'
import { Definition } from 'components/core'
import Rating from 'react-rating'
import { FormField } from 'components/form'
import { MdStar, MdStarBorder } from 'react-icons/md'

export const initialState = (value = {}, message) => ({
  status: value.status || 'PENDING',
  ticketId: value.ticketId,
  transDate: showDate(value.transDate),
  staffName: value.staffName,
  customerName: value.customerName,
  customerPhone: value.extra?.recipientPhone,
  customerCellphone: value.extra?.recipientCellphone,
  ticketItems: value.ticketItems,
  scoreOverall: value.scoreOverall || 0,
  scoreAttitude: value.scoreAttitude || 0,
  scoreTechnical: value.scoreTechnical || 0,
})

const validation = {
  scoreOverall: [{ type: 'required', message: 'error.required' }],
  scoreAttitude: [{ type: 'required', message: 'error.required' }],
  scoreTechnical: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  { id: 'customerName', label: 'customer.field.name' },
  { id: 'customerPhone', label: 'customer.field.phone' },
  { id: 'customerCellphone', label: 'customer.field.cellphone' },
  {
    id: 'transDate',
    label: 'repair.field.transDate',
    render: (state) => showDate(state.transDate),
  },
  {
    id: 'techId',
    label: 'field.tech',
    render: getTech,
  },
  {
    id: 'product',
    label: 'field.productName',
    render: getProduct,
  },
  {
    id: 'scoreOverall',
    label: 'repairFeedback.field.scoreOverall',
    renderHtml: (state) => (
      <Rating
        readonly
        initialRating={state.scoreOverall}
        stop={10}
        emptySymbol={<MdStarBorder color="#ff9800" />}
        fullSymbol={<MdStar color="#ff9800" />}
      />
    ),
    render: (state) => getScoreText(state.scoreOverall),
  },
  {
    id: 'scoreAttitude',
    label: 'repairFeedback.field.scoreAttitude',
    renderHtml: (state) => (
      <Rating
        readonly
        initialRating={state.scoreAttitude}
        stop={10}
        emptySymbol={<MdStarBorder color="#ff9800" />}
        fullSymbol={<MdStar color="#ff9800" />}
      />
    ),
    render: (state) => getScoreText(state.scoreAttitude),
  },
  {
    id: 'scoreTechnical',
    label: 'repairFeedback.field.scoreTechnical',
    renderHtml: (state) => (
      <Rating
        readonly
        initialRating={state.scoreTechnical}
        stop={10}
        emptySymbol={<MdStarBorder color="#ff9800" />}
        fullSymbol={<MdStar color="#ff9800" />}
      />
    ),
    render: (state) => getScoreText(state.scoreTechnical),
  },
]

function getTech(state) {
  return state.staffName?.join(', ')
}

function getProduct(state) {
  return state.ticketItems?.map((item) => item.productVariantName).join('\n')
}

function getScoreText(value) {
  return `${value} / 10`
}

export const labels = ({ state }) => {
  const content = defs.reduce((result, { id, label, render }) => {
    const value = render ? render(state) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})
  return content
}

export const fields = ({ state, setState }) => {
  const content = defs.reduce((result, { id, label, render }) => {
    const value = render ? render(state) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  return {
    ...content,
    scoreOverall: (
      <FormField id="scoreOverall" label="repairFeedback.field.scoreOverall">
        <Rating
          stop={10}
          initialRating={state.scoreOverall}
          emptySymbol={<MdStarBorder color="#ff9800" />}
          fullSymbol={<MdStar color="#ff9800" />}
          onChange={(value) => setState({ ...state, scoreOverall: value })}
        />
      </FormField>
    ),
    scoreAttitude: (
      <FormField id="scoreAttitude" label="repairFeedback.field.scoreAttitude">
        <Rating
          stop={10}
          initialRating={state.scoreAttitude}
          emptySymbol={<MdStarBorder color="#ff9800" />}
          fullSymbol={<MdStar color="#ff9800" />}
          onChange={(value) => setState({ ...state, scoreAttitude: value })}
        />
      </FormField>
    ),
    scoreTechnical: (
      <FormField
        id="scoreTechnical"
        label="repairFeedback.field.scoreTechnical"
      >
        <Rating
          stop={10}
          initialRating={state.scoreTechnical}
          emptySymbol={<MdStarBorder color="#ff9800" />}
          fullSymbol={<MdStar color="#ff9800" />}
          onChange={(value) => setState({ ...state, scoreTechnical: value })}
        />
      </FormField>
    ),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  message,
  id,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await editRepairFeedback(state, app, session)
    if (!ok) return

    history.push(`/repair/feedback/${id}/view`)
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('repairFeedback', { session, app, id, hash })
    if (!ok) return false

    history.push('/repair/feedback')
    return true
  },
  handlePrint: () => {
    const title = 'repairFeedback.title.view'
    const header = {
      title,
      address: 'ticket.address',
      phone: 'ticket.phone',
    }
    const field = defs.map(({ id, label, render }) => {
      const value = render ? render(state) : state[id]
      return { label, value }
    })
    const content = [{ type: 'field', value: field }]
    printHtml({ title, header, content, message })
  },
})

async function getData({ app, session, id }) {
  const variables = { ticketId: id }
  const query = `
    query($ticketId: ID) {
      repairTicket(id: $ticketId) {
        transDate
        extra
      }
      repairTicketItems(ticketId: $ticketId) {
        productVariantName
      }
      feedback(ticketId: $ticketId) {
        ticketId
        staffName
        customerName
        scoreOverall
        scoreAttitude
        scoreTechnical
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { feedback, repairTicket, repairTicketItems } = data
  return {
    ...feedback,
    ...repairTicket,
    ticketItems: repairTicketItems,
  }
}

async function editRepairFeedback(state, app, session) {
  const input = {
    scoreOverall: state.scoreOverall,
    scoreAttitude: state.scoreAttitude,
    scoreTechnical: state.scoreTechnical,
  }
  const variables = { ticketId: state.ticketId, input }
  const query = `
    mutation($ticketId: ID!, $input: FeedbackInput!) {
      editFeedback(ticketId: $ticketId, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
