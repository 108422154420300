import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/repairReportPage'
import { Link, Center, Table, Section } from 'components/core'
import Page from 'components/Page'
import { initialPage } from 'utilities/pagination'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const { id } = match.params
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [pagination, setPagination] = useState(initialPage())

  const handler = handlers({
    app,
    session,
    setState,
    id,
    setPagination,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Page
      navId="repairReportList"
      title={`repairReport.title.view`}
      action={
        <Center>
          <Link
            variant="link"
            icon={<MdArrowBack />}
            text="btn.back"
            href="/repair/report"
          />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns}
          rows={state}
          pagination={pagination}
          onChange={handler.handleLoad}
        />
      </Section>
    </Page>
  )
}
