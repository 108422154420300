import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { initialState, fields, handlers } from 'models/dispatchImportSerialPage'
import { SessionContext, AppContext } from 'contexts'
import { Button, Center, Grid, Link, Message, Section } from 'components/core'
import Page from 'components/Page'
import DispatchImportSerialModal from 'views/DispatchImportSerialModal'
import { MdArrowBack } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const [open, setOpen] = useState(false)
  const form = fields({ app, session, state, setState })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    action: { setOpen },
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page
        navId="dispatchList"
        title="dispatch.title.importSerial"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/dispatch"
            />
            <Button ml={3} variant="primary" text="btn.submit" type="submit" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={handler.loadExcel}
      >
        <Center justifyContent="flex-start" px={3}>
          <Message id="dispatch.message.serialTemplate" />
          <Link
            variant="primaryLink"
            text="btn.download"
            onClick={handler.downloadTemplate}
          />
        </Center>
        <Grid mt={3} cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section mt={3}>{form.file}</Section>
        </Grid>
      </Page>
      <DispatchImportSerialModal
        open={open}
        value={{ ticketItems: state.ticketItems }}
        data={{ products: state.products }}
        action={{ handleClose: () => setOpen(false) }}
      />
    </>
  )
}
