export default {
  'error.lock.insufficientQuantity': '庫存數量不足',
  'error.lock.noPermission': '用戶需核准鎖貨單權限',
  'lock.title.list': '鎖貨目錄',
  'lock.title.add': '新增鎖貨單',
  'lock.title.edit': '修改鎖貨單',
  'lock.title.view': '鎖貨單詳情',
  'lock.title.print': '鎖貨單 - {ticketId}',
  'lock.section.product': '商品',
  'lock.section.toLocation': '目的地',
  'lock.section.basic': '基礎信息',
  'lock.field.id': '單號',
  'lock.field.toLocationId': '目的地',
  'lock.field.transDate': '日期',
  'lock.field.spu': '商品名稱',
  'lock.field.sku': '品號',
  'lock.field.balance': '現有庫存',
  'lock.field.quantity': '數量',
  'lock.field.memo': '備註',
  'lock.field.status': '狀態',
}
