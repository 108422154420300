export default {
  'vendor.pxmart': '全聯',
  'error.pxmart.invalidLocation': '[全聯] 選取倉別有誤！',
  'error.pxmart.notEnabled': '請先儲存設定',
  'error.pxmart.codeMissing': '收貨處無代碼設定',
  'error.pxmart.codeExist': '收貨處代碼已存在',
  'pxmart.title.edit': '全聯設定',
  'pxmart.title.addCode': '新增商品代碼',
  'pxmart.title.addStorage': '新增退貨收貨處',
  'pxmart.title.syncPxmartOrders': '同步出貨單',
  'pxmart.title.downloadPxmartOrders': '下載出貨單',
  'pxmart.title.syncPxmartRefunds': '同步退貨單',
  'pxmart.title.downloadPxmartRefunds': '下載退貨單',
  'pxmart.title.upload': '上傳出貨單',
  'pxmart.title.errorList': '全聯同步錯誤列表',
  'pxmart.section.basic': '基礎信息',
  'pxmart.section.code': '商品代碼',
  'pxmart.section.refundStorage': '退貨收貨處',
  'pxmart.section.autoSync': '自動同步',
  'pxmart.section.manualSync': '手動同步',
  'pxmart.section.parseError': '檔案格式錯誤',
  'pxmart.section.importError': '資料匯入錯誤',
  'pxmart.btn.syncDispatch': '同步出貨單 EXCEL',
  'pxmart.btn.uploadDispatch': '上傳出貨單 EXCEL',
  'pxmart.btn.downloadDispatch': '下載出貨單 PDF',
  'pxmart.btn.syncRefund': '同步退貨單 EXCEL',
  'pxmart.btn.downloadRefund': '下載退貨單 PDF',
  'pxmart.field.username': 'FTP帳號',
  'pxmart.field.password': 'FTP密碼',
  'pxmart.field.syncTime': '定時同步時間',
  'pxmart.field.merchantNo': '商戶代碼',
  'pxmart.field.code': '商品代碼',
  'pxmart.field.importStatus': '匯入接收',
  'pxmart.field.exportStatus': '匯出代碼轉換',
  'pxmart.field.locationCode': '代碼',
  'pxmart.field.syncDate': '同步日期',
  'pxmart.field.filename': '檔案名',
  'pxmart.field.filepath': '檔案路徑',
  'pxmart.field.message': '錯誤信息',
  'pxmart.field.warehouseGroup': '出貨倉庫群',
  'pxmart.field.dispatchWarehouse': '出貨倉別',
  'pxmart.field.refundWarehouse': '退貨倉別',
  'pxmart.field.errorKey': '檔案名 / 單號',
  'pxmart.message.syncSuccess': '同步成功',
  'pxmart.message.uploadSuccess': '上傳成功',
  'pxmart.status.ACTIVE': '啟用',
  'pxmart.status.INACTIVE': '停用',
}
