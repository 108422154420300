import { Center } from 'components/core'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Flex } from 'reflexbox'

export default ({
  placeholder,
  onUpload,
  onDelete,
  onError,
  accept,
  maxSize = 1024000,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      await onUpload(acceptedFiles)
    },
    onDropRejected: (files) => {
      if (onError) {
        const errMsg = files.map(({ file, errors }) => {
          console.log(errors)
          const { code } = errors[0]
          const values = {
            filename: file.name,
            maxSize: Math.round(maxSize / 1000000),
          }
          return { id: `error.${code}`, values }
        })
        onError(errMsg)
      }
    },
    accept,
    maxSize,
  })

  return (
    <Flex
      minHeight="8rem"
      alignContent="center"
      sx={{
        borderColor: isDragActive ? 'accent.1' : 'light.3',
        borderWidth: '2px',
        borderRadius: '3px',
        borderStyle: 'dashed',
        cursor: 'pointer',
        outline: 'none',
        transition: 'border .24s ease-in-out',
      }}
      {...getRootProps()}
      {...props}
    >
      <input {...getInputProps()} />
      <Center width={1} color="dark.0">
        {placeholder}
      </Center>
    </Flex>
  )
}
