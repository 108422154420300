import React from 'react'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
  getSelectOptions,
} from 'utilities/form'
import { Definition } from 'components/core'
import {
  TextInput,
  NumberInput,
  Switch,
  Select,
  TextArea,
} from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'
import ImageInput from 'components/file/ImageInput'
import { addImage, deleteImage } from 'utilities/file'

const url = process.env.REACT_APP_STATIC_URL

export const initialState = (value = {}, data = {}, message) => {
  const categories = getSelectOptions(data.categories)
  return {
    readonly: value.readonly || false,
    categories,
    id: value.id || '',
    categoryName: value.categoryName,
    hasBalance: false,
    image: getImage(value.id, value.extra?.image),
    imageAdded: false,
    imageDeleted: false,
    ...initializeState({
      categoryId: getSelectOption(categories, value.categoryId),
      name: value.name || '',
      sku: value.sku || '',
      barcode: value.barcode || '',
      price: value.price || 0,
      postedPrice: value.postedPrice || 0,
      code: value.extra?.code || '',
      boxUnit: value.extra?.boxUnit || 0,
      boxLength: value.extra?.boxLength || 0,
      boxWidth: value.extra?.boxWidth || 0,
      boxHeight: value.extra?.boxHeight || 0,
      boxWeight: value.extra?.boxWeight || 0,
      expireDate: value.extra?.expireDate || '',
      memo: value.extra?.memo || '',
      vendor: getVendors(value.extra?.vendor, message),
      isActive: value?.status !== 'INACTIVE',
    }),
  }
}

function getImage(productId, imageId) {
  if (!productId || !imageId) return ''

  return {
    id: imageId,
    filename: imageId,
    path: `${url}/1/${productId}/${imageId}`,
  }
}

function getVendors(value, message) {
  if (!value) return null

  if (Array.isArray(value)) {
    return value.map((item) => ({
      value: item,
      label: message({ id: `product.vendor.${item}` }),
    }))
  }

  return [{ value, label: message({ id: `product.vendor.${value}` }) }]
}

const validation = {
  categoryId: [{ type: 'required', message: 'error.required' }],
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 128, message: ['error.maxLength', { val: 128 }] },
  ],
  sku: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 32, message: ['error.maxLength', { val: 32 }] },
  ],
}

const defs = [
  {
    id: 'categoryId',
    label: 'product.field.category',
    render: (state) => state.categoryName,
  },
  { id: 'name', label: 'product.field.spu' },
  { id: 'sku', label: 'product.field.sku' },
  { id: 'barcode', label: 'product.field.barcode' },
  { id: 'code', label: 'product.field.code' },
  { id: 'price', label: 'product.field.price' },
  { id: 'postedPrice', label: 'product.field.postedPrice' },
  { id: 'boxUnit', label: 'product.field.boxUnit' },
  { id: 'boxLength', label: 'product.field.boxLength' },
  { id: 'boxWidth', label: 'product.field.boxWidth' },
  { id: 'boxHeight', label: 'product.field.boxHeight' },
  { id: 'boxWeight', label: 'product.field.boxWeight' },
  { id: 'expireDate', label: 'product.field.expireDate' },
  { id: 'memo', label: 'field.memo' },
  {
    id: 'vendor',
    label: 'product.field.vendor',
    render: (state) => {
      if (!state.vendor) return null
      return state.vendor.map(({ label }) => label).join(', ')
    },
  },
  // {
  //   id: 'isActive',
  //   label: 'product.field.isActive',
  //   render: (state, message) => {
  //     const status = state.isActive ? 'ACTIVE' : 'INACTIVE'
  //     return message({ id: `product.status.${status}` })
  //   },
  // },
]

export const labels = ({ state, message }) => {
  return defs.reduce((result, item) => {
    const { id, label, render } = item
    const value = render ? render(state, message) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})
}

export const fields = ({ app, session, state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id) =>
    handleSelectChange(id, state, setState, validation)

  return {
    categoryId: (
      <Select
        id="categoryId"
        label="product.field.category"
        placeholder="product.field.category"
        options={state.categories}
        value={state.categoryId}
        onChange={onSelectChange('categoryId')}
        errMsg={state.__error__.categoryId}
      />
    ),
    name: (
      <TextInput
        id="name"
        label="product.field.spu"
        placeholder="product.field.spu"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    sku: (
      <TextInput
        id="sku"
        label="product.field.sku"
        value={state.sku}
        onChange={onTextChange('sku')}
        errMsg={state.__error__.sku}
      />
    ),
    barcode: (
      <TextInput
        id="barcode"
        label="product.field.barcode"
        value={state.barcode}
        onChange={onTextChange('barcode')}
        errMsg={state.__error__.barcode}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="product.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="product.field.price"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    postedPrice: (
      <NumberInput
        type="decimal"
        id="postedPrice"
        label="product.field.postedPrice"
        value={state.postedPrice}
        onChange={onTextChange('postedPrice')}
        errMsg={state.__error__.postedPrice}
      />
    ),
    boxUnit: (
      <NumberInput
        id="boxUnit"
        label="product.field.boxUnit"
        value={state.boxUnit}
        onChange={onTextChange('boxUnit')}
        errMsg={state.__error__.boxUnit}
      />
    ),
    boxLength: (
      <TextInput
        id="boxLength"
        label="product.field.boxLength"
        value={state.boxLength}
        onChange={onTextChange('boxLength')}
        errMsg={state.__error__.boxLength}
      />
    ),
    boxWidth: (
      <TextInput
        id="boxWidth"
        label="product.field.boxWidth"
        value={state.boxWidth}
        onChange={onTextChange('boxWidth')}
        errMsg={state.__error__.boxWidth}
      />
    ),
    boxHeight: (
      <TextInput
        id="boxHeight"
        label="product.field.boxHeight"
        value={state.boxHeight}
        onChange={onTextChange('boxHeight')}
        errMsg={state.__error__.boxHeight}
      />
    ),
    boxWeight: (
      <TextInput
        id="boxWeight"
        label="product.field.boxWeight"
        value={state.boxWeight}
        onChange={onTextChange('boxWeight')}
        errMsg={state.__error__.boxWeight}
      />
    ),
    expireDate: (
      <NumberInput
        id="expireDate"
        min="0"
        label="product.field.expireDate"
        value={state.expireDate}
        onChange={onTextChange('expireDate')}
        errMsg={state.__error__.expireDate}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    vendor: (
      <Select
        label="product.field.vendor"
        isSearchable={false}
        isMulti
        options={[
          { value: 'sdj', label: message({ id: `product.vendor.sdj` }) },
          // {
          //   value: 'mrWarehouse',
          //   label: message({ id: `product.vendor.mrWarehouse` }),
          // },
        ]}
        value={state.vendor}
        onChange={onSelectChange('vendor')}
      />
    ),
    image: (
      <ImageInput
        id="image"
        label="product.field.image"
        value={state.image}
        onDrop={(image) => {
          setState({ ...state, image, imageAdded: true })
        }}
        onDelete={(file) => {
          const imageDeleted = file.preview ? '' : file.id
          setState({ ...state, image: '', imageDeleted })
        }}
        onError={(errorMessages) =>
          errorMessages.forEach((item) => {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'error', message: item },
            })
          })
        }
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="product.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            checkBalance({ app, session, state, setState })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  setData,
  setConfirmOpen,
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session })
    setData(data)
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    let { id } = state
    const [ok, data] = id
      ? await editProduct({ state, app, session })
      : await addProduct({ state, app, session })
    if (!ok) return

    if (!id) {
      id = data.addProduct
    }

    if (state.imageDeleted) {
      const imageOk = await deleteImage(app, session, id, state.image)
      if (!imageOk) return false
    }

    if (state.imageAdded) {
      const imageOk = await addImage(app, session, id, state.image)
      if (!imageOk) return false
    }

    setConfirmOpen(false)
    action.handleLoad()
    action.handleClose()
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
})

async function getData({ app, session }) {
  const query = `
    query {
      categories {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query }, { app, session })
  if (!ok) return null

  return data
}

async function checkBalance({ app, session, state, setState }) {
  const variables = { productVariantId: state.id }
  const query = `
    query($productVariantId: ID!) {
      hasInventoryBalance(productVariantId: $productVariantId)
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return

  setState({
    ...state,
    hasBalance: data.hasInventoryBalance,
    isActive: !state.isActive,
  })
}

async function addProduct({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { input }
  const query = `
    mutation($input: ProductInput!) {
      addProduct(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editProduct({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: ProductInput!) {
      editProduct(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  return {
    id: state.id,
    categoryId: state.categoryId?.value,
    categoryName: state.categoryId?.label,
    spu: state.name,
    sku: state.sku,
    barcode: state.barcode,
    price: parseFloat(state.price || 0),
    postedPrice: parseFloat(state.postedPrice || 0),
    status: state.isActive ? 'ACTIVE' : 'INACTIVE',
    extra: {
      code: state.code,
      boxUnit: state.boxUnit,
      boxLength: state.boxLength,
      boxWidth: state.boxWidth,
      boxHeight: state.boxHeight,
      boxWeight: state.boxWeight,
      expireDate: state.expireDate,
      vendor: state.vendor?.map((item) => item.value),
      memo: state.memo,
      image: state.image.id || state.image.name,
    },
  }
}
