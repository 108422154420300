import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/productComboCodeDrawer'
import { Button, Drawer, Center, Toolbar } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { MdAdd } from 'react-icons/md'

export default ({ data, open, onClose, onSubmit }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const form = fields({ state, setState, data })
  const handler = handlers({ state, setState, app, session, data, onSubmit })

  useEffect(() => {
    if (open) setState(initialState())
  }, [open])

  return (
    <Drawer
      width={[1, '100%']}
      title="productComboCode.title.add"
      open={open}
      onClose={onClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.dealer}
      {form.code}
      <Toolbar>
        <Button
          variant="default"
          icon={<MdAdd />}
          text="productComboCode.btn.addCombo"
          onClick={handler.addCombo}
        />
      </Toolbar>
      {form.products}
      <Center mt={3}>
        <Button variant="secondary" mr={2} text="btn.close" onClick={onClose} />
        <Button type="submit" variant="primary" text="btn.submit" />
      </Center>
    </Drawer>
  )
}
