import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/processConfigList'
import { Button, Section, Table } from 'components/core'
import Page from 'components/Page'

export default ({ match }) => {
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const { handleLoad } = handlers({ setState, session, app, match })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const action = { handleLoad }

  return (
    <Page
      title="processConfig.title.list"
      navId="processConfigList"
      action={
        <Button
          variant="secondary"
          text="btn.add"
          onClick={() => history.push('/processConfig/add')}
        />
      }
    >
      <Section>
        <Table
          columns={columns({ setState, session, app, action })}
          rows={state}
        />
      </Section>
    </Page>
  )
}
