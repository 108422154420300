import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { useIntl } from 'react-intl'
import { filters, columns, handlers } from 'models/customerList'
import { Table, Section, Center, Button } from 'components/core'
import Page from 'components/Page'
import CustomerDrawer from 'views/CustomerDrawer'
// import { MdFileDownload } from 'react-icons/md'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { formatMessage: message } = useIntl()
  const [selected, setSelected] = useState([])
  const [state, setState] = useState()
  const [filterValues, setFilterValues] = useState([])
  const [open, setOpen] = useState(false)
  const handler = handlers({
    session,
    app,
    setState,
    setSelected,
    setFilterValues,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="customer.title.list"
      navId="customerList"
      action={
        <Center>
          <Button
            mr={2}
            disabled={selected.length <= 1}
            variant="secondary"
            text="customer.btn.merge"
            onClick={() => handler.handleMerge(selected, filterValues)}
          />
          <Button
            variant="secondary"
            text="btn.add"
            onClick={() => setOpen(true)}
          />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({
            app,
            session,
            message,
            selected,
            setSelected,
            action: { handleLoad: handler.handleLoad },
          })}
          rows={state}
          filters={filters}
          filterValues={filterValues}
          onChange={handler.handleLoad}
        />
      </Section>
      <CustomerDrawer
        open={open}
        action={{ handleClose: () => setOpen(false) }}
      />
    </Page>
  )
}
