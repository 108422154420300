export default {
  'refund.receivedItemExists': '訂單已有對應處理品項，請先刪除處理品項',
  'refund.childProduct.quantityInvalid': '組合商品數量有誤',
  'refund.childProduct.typeRequired': '類別為必填欄位',
  'refund.childProduct.locationRequired': '目的地為必填欄位',
  'refund.title.list': '退貨目錄',
  'refund.title.add': '新增退貨單',
  'refund.title.addItem': '新增退貨商品',
  'refund.title.edit': '修改退貨單',
  'refund.title.view': '退貨單詳情',
  'refund.title.import': '匯入退貨單',
  'refund.title.editItem': '修改退貨商品',
  'refund.title.deleteAdjust': '刪除溢收調整',
  'refund.title.print': '退貨單 - {ticketId}',
  'refund.btn.import': '帶入退貨商品',
  'refund.btn.add': '新增退貨處理',
  'refund.btn.addChildProduct': '新增商品',
  'refund.section.product': '退貨項目',
  'refund.section.resultProduct': '處理退貨',
  'refund.section.image': '商品圖示',
  'refund.section.adjustProduct': '溢收調整',
  'refund.message.template': '退貨單匯入範本',
  'refund.message.deleteAdjust': '確認刪除溢收調整？',
  'refund.message.typeRequired': '類別為必填欄位',
  'refund.message.warehouseRequired': '轉倉商品之倉庫為必填欄位',
  'refund.message.quantityInvalid': '處理商品數量須與退貨商品數量一致',
  'refund.field.id': '單號',
  'refund.field.parentId': '關聯單號',
  'refund.field.type': '類別',
  'refund.field.warehouseId': '倉庫',
  'refund.field.ticketNo': '廠商單號',
  'refund.field.transDate': '退貨日期',
  'refund.field.salesId': '業務人員',
  'refund.field.staffId': '處理人員',
  'refund.field.memo': '業務備註',
  'refund.field.warehouseGroupId': '倉庫群',
  'refund.field.dealerId': '經銷商',
  'refund.field.dealerAddress': '公司地址',
  'refund.field.billingId': '歸帳',
  'refund.field.recipientId': '收貨處',
  'refund.field.recipientContact': '聯絡人',
  'refund.field.recipientAddress': '收件地址',
  'refund.field.recipientEmail': 'Email',
  'refund.field.recipientPhone': '電話',
  'refund.field.recipientCellphone': '手機',
  'refund.field.productVariant': '商品',
  'refund.field.location': '目的地',
  'refund.field.spu': '商品名稱',
  'refund.field.barcode': '產品條碼',
  'refund.field.isGift': '贈品',
  'refund.field.batchNo': '批號',
  'refund.field.balance': '可用數量',
  'refund.field.quantity': '數量',
  'refund.field.comboQuantity': '組合數量',
  'refund.field.maxSingleQuantity': '可收單品數量',
  'refund.field.price': '金額',
  'refund.field.status': '狀態',
  'refund.field.importFile': '匯入檔案',
  'refund.field.showCombo': '拆解組合',
  'refund.field.appliedQuantity': '應收數量',
  'refund.field.trackingNo': '物流編號',
  'refund.type.RETURN_WAREHOUSE': '轉倉',
  'refund.type.RETURN_DEALER': '歸還至經銷群組',
  'refund.type.DISCARD': '破包報廢',
  'refund.type.DISCARD_EXPIRED': '過期報廢',
  'refund.type.DISCARD_OPENED': '業務轉贈',
  'refund.type.DISCARD_NO_RECYCLE': '報廢不收回',
  'refund.type.RECEIVE_LESS': '數量短收',
  'refund.type.LOST': '倉管遺失',
  'refund.status.ACTIVE': '已完成',
  'refund.status.INACTIVE': '已刪除',
  'refund.status.PENDING': '待處理',
  'refund.status.CALLBACK_PENDING': '待三方確認',
  'refund.status.ACTION_PENDING': '待確認',
}
