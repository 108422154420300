import React, { useState } from 'react'
import { Flex } from 'reflexbox'
import { initialState, fields, handlers } from 'models/carrefourStorageModal'
import { Modal, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ data, open, action }) => {
  const [state, setState] = useState(initialState())
  const form = fields({ state, setState, data })
  const handler = handlers({ state, setState, data, action })

  return (
    <Modal
      title="carrefour.title.addStorage"
      open={open}
      onClose={action.handleClose}
      width={300}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.locationId}
      {form.code}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button type="submit" variant="primary" text="btn.confirm" />
      </Flex>
    </Modal>
  )
}
