import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { initialState, fields, handlers } from 'models/dispatchPage'
import { Box } from 'reflexbox'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Toolbar,
} from 'components/core'
import Page from 'components/Page'
import DispatchDrawer from 'views/DispatchDrawer'
import { MdArrowBack, MdAdd, MdPrint, MdEdit } from 'react-icons/md'
import {
  renderConfirmButton,
  renderDeleteLink,
  renderLockLink,
} from 'utilities/app'
import { handleLock, renderSyncLink } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { FaExchangeAlt } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import CustomerSwitchModal from './CustomerSwitchModal'

export default ({ match, profile }) => {
  const { id } = match.params
  const url = new URL(window.location.href)
  const parentId = url.searchParams.get('parentId')
  const parentType = url.searchParams.get('parentType')
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState(
    initialState({ parentId, parentType }, data, message, profile),
  )
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState()
  const [switchOpen, setSwitchOpen] = useState(false)

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const readonly = profile === 'view'
  const form = fields({
    app,
    session,
    parentId,
    state,
    setState,
    message,
    profile,
    action: { handleOpen },
  })
  const handler = handlers({
    state,
    setState,
    data,
    setData,
    app,
    session,
    message,
    history,
    id,
    parentId,
    parentType,
    profile,
  })

  useEffect(() => {
    handler.handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile, data])

  return (
    <>
      <Page
        profile={profile}
        navId="dispatchList"
        title={`dispatch.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/dispatch/${id}/view` : '/dispatch'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'dispatch',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdContentCopy />}
              tooltip="dispatch.btn.copyRefund"
              href={`/refund/add?dispatchId=${state.id}`}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<FaExchangeAlt />}
              tooltip="dispatch.btn.switchUser"
              onClick={() => setSwitchOpen(true)}
            />
            <Link
              ml={3}
              show={readonly}
              disabled={state.locked || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/dispatch/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handleLock({
                module: 'dispatch',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            {renderSyncLink({
              readonly,
              sync: state.sync,
              app,
              session,
              id: state.id,
              onSubmit: (value) => setState({ ...state, sync: value }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
            {renderConfirmButton({
              module: 'dispatch',
              session,
              profile,
              status: state.status,
              onSubmit: handler.handleConfirm,
            })}
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.parentId}
            {form.ticketNo}
            {form.transDate}
            {form.arriveDate}
            {form.arrivePeriod}
            {form.dealer}
            {form.recipient}
            {form.sales}
            {form.backup}
            {form.tech}
            {form.memo}
          </Section>
          <Section>
            {form.recipientContact}
            {form.recipientAddress}
            {form.recipientPhone}
            {form.recipientCellphone}
            {form.recipientEmail}
            {form.shipping}
            {/* {form.warehouse} */}
            {form.warehouseGroup}
            {form.warehouseMemo}
          </Section>
          <Box>
            <Section>
              {form.isUrgent}
              {form.isCollect}
              {form.isDirect}
              {form.isSell}
            </Section>
            <Section mt={3}>
              {form.dealerCode}
              {form.dealerEIN}
              {form.dealerAddress}
              {form.billing}
              {form.trackingNo}
              {form.receiptNo}
              {form.handleType}
              {form.handleNo}
              {form.attach}
            </Section>
          </Box>
        </Grid>
        <Section mt={5} title="dispatch.section.product">
          <Toolbar show={!readonly}>
            <Button
              disabled={!!state.parentId}
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
          {form.product}
          <Toolbar show={!readonly} bottom>
            <Button
              disabled={!!state.parentId}
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
        </Section>
        <Section mt={5} title="dispatch.section.image">
          {form.images}
        </Section>
      </Page>
      <DispatchDrawer
        readonly={readonly}
        open={open}
        value={value}
        data={{
          profile,
          parentType: state.parentType,
          isDirect: state.isDirect,
          dealers: state.dealers,
          products: state.products,
          // warehouseId: state.warehouseId.value,
          warehouseGroupId: state.warehouseGroupId.value,
          warehouses: state.warehouses,
          dealerId: state.dealerId.value,
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
        }}
        action={{
          handleOpen,
          handleClose,
          addItem: handler.addItem,
        }}
      />
      <CustomerSwitchModal
        value={{ id: state.id }}
        open={switchOpen}
        onClose={() => setSwitchOpen(false)}
        onSubmit={() => {
          handler.handleLoad()
          setSwitchOpen(false)
        }}
      />
    </>
  )
}
