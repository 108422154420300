import React, { useEffect, useState, cloneElement } from 'react'
import { useIntl } from 'react-intl'
import { Center, Drawer, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ open, data, action }) => {
  const { filters, filterValues } = data
  // const { setFilterOps, setFilterInputs } = action
  if (!filters || filters.length === 0) return null

  const { formatMessage: message } = useIntl()
  // const [filterOps, setFilterOps] = useState(data.filterOps)
  const [filterInputs, setFilterInputs] = useState(
    getFilterValMap(filterValues),
  )
  const [disabled, setDisabled] = useState(false)
  const configs = getFilterConfigs({
    filters,
    message,
    filterInputs,
    setFilterInputs,
  })

  useEffect(() => {
    setFilterInputs(getFilterValMap(filterValues))
  }, [filterValues])

  return (
    <Drawer
      title="filter.title"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(
        handleSubmit(configs, filterInputs, action, setDisabled),
      )}
    >
      {configs.map((filter) =>
        cloneElement(filter.input, {
          key: filter.id,
          label: filter.label,
          fieldProps: { mt: 3 },
        }),
      )}
      <Center mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          disabled={disabled}
          type="submit"
          variant="primary"
          text="btn.search"
        />
      </Center>
    </Drawer>
  )
}

function getFilterValMap(filterValues = []) {
  return filterValues.reduce((result, item) => {
    result[item.id] = item.value || ''
    return result
  }, {})
}

function handleSubmit(configs, filterInputs, action, setDisabled) {
  return async (event) => {
    event.preventDefault()
    setDisabled(true)

    const filterVals = Object.entries(filterInputs).map(([key, value]) => {
      const filter = configs.find(({ id }) => id === key)
      return { id: filter.id, label: filter.label, value }
    })

    try {
      return await action.handleSubmit(filterVals)
    } finally {
      setDisabled(false)
    }
  }
}

function getFilterConfigs({ filters, message, filterInputs, setFilterInputs }) {
  if (!filters) return []

  return filters.map((filter) => ({
    ...filter,
    input: filter.input({
      message,
      // filterOp: filterOps[filter.id],
      // setFilterOp: (val) => setFilterOps({ ...filterOps, [filter.id]: val }),
      filterInput: filterInputs[filter.id],
      setFilterInput: (val) =>
        setFilterInputs({ ...filterInputs, [filter.id]: val }),
    }),
  }))
}
