export default {
  'vendor.carrefour': '家樂福',
  'error.carrefour.invalidLocation': '[家樂福] 選取倉別有誤！',
  'carrefour.title.edit': '家樂福設定',
  'carrefour.title.syncOrders': '同步訂單',
  'carrefour.title.addStorage': '新增收貨處',
  'carrefour.title.errorList': '家樂福同步錯誤列表',
  'carrefour.section.basic': '基礎信息',
  'carrefour.section.autoSync': '自動同步',
  'carrefour.section.manualSync': '手動同步',
  'carrefour.section.storage': '收貨處',
  'carrefour.btn.syncDispatch': '同步訂單',
  'carrefour.message.syncSuccess': '同步成功',
  'carrefour.field.username': '帳戶',
  'carrefour.field.password': '密碼',
  'carrefour.field.syncTime1': '定時同步時間1',
  'carrefour.field.syncTime2': '定時同步時間2',
  'carrefour.field.dispatchWarehouse': '出貨倉別',
  'carrefour.field.storageName': '名稱',
  'carrefour.field.storageCode': '編碼',
  'carrefour.field.syncDate': '同步日期',
  'carrefour.field.errorKey': '檔案名 / 單號',
  'carrefour.field.message': '錯誤信息',
}
