import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
} from 'utilities/form'
import { Select } from 'components/form'

export const initialState = () =>
  initializeState({
    sdjShipping: {},
    shipping: {},
  })

const validation = {
  sdjShipping: [{ type: 'required', message: 'error.required' }],
  shipping: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    sdjShipping: (
      <Select
        isClearable={false}
        label="plugin.field.sdjShipping"
        options={data.sdjShippings}
        value={state.sdjShipping}
        onChange={onSelectChange('sdjShipping')}
        errMsg={state.__error__.sdjShipping}
      />
    ),
    shipping: (
      <Select
        isClearable={false}
        label="plugin.field.shipping"
        options={data.shippings}
        value={state.shipping}
        onChange={onSelectChange('shipping')}
        errMsg={state.__error__.shipping}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { shipping, sdjShipping } = state
    action.addShipping({
      locationId: shipping.value,
      locationName: shipping.label,
      sdjLocationId: sdjShipping.value,
      sdjLocationName: sdjShipping.label,
    })
    action.handleClose()
    setState(initialState())
  },
})
