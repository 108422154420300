import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { getSelectOptions, showAddress, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { LinkButton, StatusChip, Link } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { filterLocations } from 'utilities/permission'
import { FILTER_SET } from 'constants/actionType'
import { Flex } from 'reflexbox'
import { getStatus } from './deferPage'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'defer.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'revertDate',
    label: 'defer.field.revertDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'paymentDate',
    label: 'defer.field.paymentDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    input: textFilter,
  },
  {
    id: 'org',
    label: 'field.org',
    input: (props) => selectFilter({ ...props, options: data.orgs }),
  },
  {
    id: 'toLocation',
    label: 'field.dealer',
    input: (props) =>
      selectFilter({ ...props, isSearchable: true, options: data.dealers }),
  },
  {
    id: 'recipientName',
    label: 'defer.field.recipientId',
    input: textFilter,
  },
  {
    id: 'recipientContact',
    label: 'defer.field.recipientContact',
    input: textFilter,
  },
  {
    id: 'sales',
    label: 'defer.field.salesId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.sales),
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({ ...props, options: getStatusOptions(props.message) }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'lockItemStatus',
    label: 'defer.field.lockItem',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
  {
    id: 'isContract',
    label: 'defer.field.contractProduct',
    input: (props) =>
      selectFilter({
        ...props,
        options: getIsContractOptions(props.message),
      }),
  },
  {
    id: 'source',
    label: 'defer.field.source',
    input: (props) =>
      selectFilter({
        ...props,
        options: getSource(props.message),
      }),
  },
]

export function getSource(message) {
  return [
    { value: 'erp-invoice', label: message({ id: 'defer.source.vicInvoice' }) },
  ]
}

function getIsContractOptions(message) {
  return [
    { value: true, label: message({ id: 'type.true' }) },
    { value: false, label: message({ id: 'type.false' }) },
  ]
}

function getStatusOptions(message) {
  return [
    { value: 'ACTIVE', label: message({ id: 'defer.status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'defer.status.INACTIVE' }) },
    { value: 'PENDING', label: message({ id: 'defer.status.PENDING' }) },
    { value: 'REVERTED', label: message({ id: 'defer.status.REVERTED' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'defer.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/defer/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'transDate',
    label: 'defer.field.transDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'billingName',
    label: 'defer.field.billingId',
    format: ['excel'],
    render: ({ row }) => row.extra.billingName,
  },
  {
    id: 'ticketNo',
    label: 'defer.field.ticketNo',
    format: ['html', 'excel'],
  },
  {
    id: 'toLocationName',
    label: 'defer.field.dealerId',
    format: ['html', 'excel'],
  },
  {
    id: 'recipientName',
    label: 'field.storage',
    format: ['excel'],
    render: ({ row }) => row.extra.recipientName,
  },
  {
    id: 'dealerCode',
    label: 'defer.field.dealerCode',
    format: ['excel'],
    render: ({ row }) => row.extra.dealerCode,
  },
  {
    id: 'recipientContact',
    label: 'defer.field.recipientContact',
    format: ['excel'],
    render: ({ row }) => row.extra.recipientContact,
  },
  {
    id: 'recipientPhone',
    label: 'defer.field.recipientPhone',
    format: ['excel'],
    render: ({ row }) => row.extra.recipientPhone,
  },
  {
    id: 'recipientCellphone',
    label: 'defer.field.recipientCellphone',
    format: ['excel'],
    render: ({ row }) => row.extra.recipientCellphone,
  },
  {
    id: 'recipientAddress',
    label: 'defer.field.recipientAddress',
    format: ['excel'],
    render: ({ row }) => showAddress(row.extra.recipientAddress, message),
  },
  {
    id: 'salesName',
    label: 'field.sales',
    format: ['excel'],
    render: ({ row }) => row.extra.salesName?.join(', '),
  },
  {
    id: 'backupName',
    label: 'defer.field.backupId',
    format: ['excel'],
    render: ({ row }) => row.extra.backupName?.join(', '),
  },
  {
    id: 'memo',
    label: 'defer.field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra.memo,
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['excel'],
  },
  {
    id: 'productCode',
    label: 'field.productCode',
    format: ['excel'],
    render: ({ row }) => row.productCode,
  },
  {
    id: 'isGift',
    label: 'defer.field.isGift',
    format: ['excel'],
    render: ({ row }) => (row.isGift ? 'Y' : 'N'),
  },
  {
    id: 'isDemo',
    label: 'defer.field.isDemo',
    format: ['excel'],
    render: ({ row }) => (row.itemExtra?.isDemo ? 'Y' : 'N'),
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
    render: ({ row }) => row.itemExtra.memo,
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    format: ['excel'],
  },
  {
    id: 'revertQuantity',
    label: 'defer.field.revertQuantity',
    format: ['excel'],
  },
  {
    id: 'balance',
    label: 'defer.field.balance',
    format: ['excel'],
    render: ({ row }) => row.balance || 0,
  },
  {
    id: 'price',
    label: 'field.total',
    format: ['excel'],
  },
  {
    id: 'paymentPaid',
    label: 'defer.field.paymentPaid',
    format: ['excel'],
    // render: ({ row }) => row.itemExtra?.paidAmount || 0,
  },
  {
    id: 'paymentReverted',
    label: 'defer.field.revertAmount',
    format: ['excel'],
    // render: ({ row }) => row.itemExtra?.paidAmount || 0,
  },
  {
    id: 'paymentBalance',
    label: 'defer.field.paymentBalance',
    format: ['excel'],
    // render: ({ row }) => {
    //   const paid = row.itemExtra?.paidAmount || 0
    //   return parseFloat(row.price) - paid
    // },
  },
  {
    id: 'itemTransDate',
    label: 'defer.field.itemTransDate',
    format: ['excel'],
    render: ({ row }) => showDate(row.itemTransDate),
  },
  {
    id: 'lastDispatchDate',
    label: 'defer.field.lastDispatchDate',
    format: ['excel'],
  },
  {
    id: 'lastRefundDate',
    label: 'defer.field.lastRefundDate',
    format: ['excel'],
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`defer.status.${getStatus(row.status, row.extra)}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `defer.status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/defer/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'defer',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('defer', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setOrgs,
  setDealers,
  setSales,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'deferList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    setDealers(resp.dealers)
    setOrgs(resp.orgs)
    setSales(resp.sales)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'defer.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    staffInput: { type: ['SALES'] },
    locationInput: { type: ['DEALER', 'DEALER_ORG'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      staffs(input: $staffInput) {
        id
        name
      }
      deferTicketCount(input: $input)
      deferTickets(input: $input) {
        id
        ticketNo
        toLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const orgs = []
  const dealers = []
  const locations = filterLocations(app.state.staff, data.locations)
  locations.forEach((item) => {
    const result = { value: item.id, label: item.name }
    switch (item.type) {
      case 'DEALER_ORG':
        orgs.push(result)
        break
      default:
        dealers.push(result)
    }
  })
  const { page, countPerPage } = pagination
  return {
    data: data.deferTickets,
    pagination: getPagination(page, countPerPage, data.deferTicketCount),
    orgs,
    dealers,
    sales: data.staffs,
  }
}

export async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      deferTickets(input: $input) {
        id
        itemId
        ticketNo
        toLocationName
        productVariantName
        sku
        quantity
        balance
        price
        isGift
        transDate
        itemTransDate
        extra
        itemExtra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const paymentMap = {}
  const revertMap = {}
  const revertQuantityMap = {}
  const result = []

  data.deferTickets.forEach((item) => {
    const { isPayment, paymentType, isRevert, parentItemId } = item.itemExtra
    const lastDispatchDate = item.extra.lastDispatchDate || {}
    const lastRefundDate = item.extra.lastRefundDate || {}

    if (isPayment) {
      if (paymentType === 'REVERT') {
        const revert = revertMap[parentItemId] || 0
        revertMap[parentItemId] = revert + item.price
      } else {
        const payment = paymentMap[parentItemId] || 0
        paymentMap[parentItemId] = payment + item.price
      }
      return
    }
    if (isRevert) {
      const revert = revertQuantityMap[parentItemId] || 0
      revertQuantityMap[parentItemId] = revert + item.quantity
      return
    }
    item.revertQuantity = 0
    item.paymentReverted = 0
    item.lastDispatchDate = lastDispatchDate[item.itemId]
    item.lastRefundDate = lastRefundDate[item.itemId]
    result.push(item)
  })
  // console.log(paymentMap)
  // console.log(revertMap)
  // console.log(revertQuantityMap)
  // console.log(result)
  return result.map((item) => {
    const payment = paymentMap[item.itemId] || 0
    const revert = revertMap[item.itemId] || 0
    item.revertQuantity += Math.abs(revertQuantityMap[item.itemId] || 0)
    item.paymentReverted += Math.abs(revert)
    item.paymentPaid = payment
    item.paymentBalance = item.price - payment
    return item
  })
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const revertDate = filterValues.find(({ id }) => id === 'revertDate')
  const paymentDate = filterValues.find(({ id }) => id === 'paymentDate')
  const ticketNo = filterValues.find(({ id }) => id === 'ticketNo')
  const toLocation = filterValues.find(({ id }) => id === 'toLocation')
  const org = filterValues.find(({ id }) => id === 'org')
  const recipientName = filterValues.find(({ id }) => id === 'recipientName')
  const recipientContact = filterValues.find(
    ({ id }) => id === 'recipientContact',
  )
  const sales = filterValues.find(({ id }) => id === 'sales')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const lockItemStatus = filterValues.find(({ id }) => id === 'lockItemStatus')
  const isContract = filterValues.find(({ id }) => id === 'isContract')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const productVariantId = filterValues.find(
    ({ id }) => id === 'productVariantId',
  )
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const source = filterValues.find(({ id }) => id === 'source')
  const extra = {
    recipientName: recipientName?.value,
    recipientContact: recipientContact?.value,
    salesId: sales?.value.value,
    locked: lockStatus?.value.value,
    itemLocked: lockItemStatus?.value.value,
    source: source?.value.value,
  }
  let deferStatus = status?.value.value

  if (deferStatus === 'REVERTED') {
    deferStatus = 'ACTIVE'
    extra.isRevert = true
  }

  const input = {
    joinItem,
    id: id?.value,
    transDate: transDate?.value,
    ticketNo: ticketNo?.value,
    toLocationId: toLocation?.value.value,
    toLocationParentId: org?.value.value,
    itemDeferRevertDate: revertDate?.value,
    itemDeferPaymentDate: paymentDate?.value,
    itemIsContract: !!isContract?.value,
    extra,
    status: deferStatus,
    productVariantName: productName?.value,
    productVariantId: productVariantId?.value,
    createdBy: createdBy?.value,
    orderBy: [
      { key: 'transDate', sort: 'DESC' },
      { key: 'id', sort: 'DESC' },
    ],
  }
  // if (revertDate) {
  //   input.joinMeta = true
  //   input.meta = [
  //     { type: 'SELL_REVERT_DATE', op: 'BETWEEN', value: revertDate?.value },
  //   ]
  // }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
