import { request } from 'utilities/request'

export async function getTag({ session, app }) {
  const query = `
    query {
      tag
    }
  `
  const [ok, resp] = await request({ query }, { app, session })
  if (!ok) return null

  return resp.tag
}
