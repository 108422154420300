import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { initialState, fields, labels, handlers } from 'models/transferInPage'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Toolbar,
} from 'components/core'
import Page from 'components/Page'
import TransferInDrawer from 'views/TransferInDrawer'
import { MdArrowBack, MdAdd, MdPrint, MdEdit } from 'react-icons/md'
import {
  renderConfirmButton,
  renderDeleteLink,
  renderLockLink,
} from 'utilities/app'
import { handleLock } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match, profile }) => {
  const { id } = match.params
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState()

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const readonly = profile === 'view'
  const form = readonly
    ? labels({ state, message, action: { handleOpen } })
    : fields({
        app,
        session,
        state,
        setState,
        message,
        profile,
        action: { handleOpen },
      })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    history,
    id,
    profile,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <>
      <Page
        profile={profile}
        navId="moveList"
        title={`transferIn.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`transferIn.status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/transfer-in/${id}/view` : '/move'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'transferIn',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.locked || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/transfer-in/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handleLock({
                module: 'transfer_in',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
            {renderConfirmButton({
              hideStatusList: [
                'INACTIVE',
                'ACTIVE',
                'ACTION_PENDING',
                'CALLBACK_PENDING',
              ],
              module: 'transferIn',
              session,
              profile,
              status: state.status,
              onSubmit: handler.handleConfirm('ACTION_PENDING'),
            })}
            {renderConfirmButton({
              label: 'move.btn.complete',
              titleId: 'move.title.confirmArrive',
              textId: 'move.message.confirmArrive',
              hideStatusList: [
                'PENDING',
                'INACTIVE',
                'ACTIVE',
                'CALLBACK_PENDING',
              ],
              disabledStatusList: [],
              module: 'transferIn',
              session,
              profile,
              status: state.status,
              onSubmit: handler.hanldeComplete,
            })}
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.ticketNo}
            {form.estimateDate}
            {form.transDate}
            {form.arrivePeriod}
          </Section>
          <Section>
            {form.fromLocation}
            {form.recipientContact}
            {form.recipientAddress}
            {form.recipientPhone}
            {form.recipientCellphone}
          </Section>
          <Section>
            {form.toLocation}
            {form.shipping}
            {form.trackingNo}
            {form.memo}
          </Section>
        </Grid>
        <Section mt={5} title="transferIn.section.product">
          <Toolbar show={!readonly}>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
          {form.product}
          <Toolbar show={!readonly} bottom>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
        </Section>
      </Page>
      <TransferInDrawer
        readonly={readonly}
        open={open}
        value={value}
        data={{
          profile,
          products: state.products,
          warehouses: state.warehouses,
          fromLocationId: state.fromLocationId.value,
          toLocationId: state.toLocationId.value,
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
        }}
        action={{
          handleOpen,
          handleClose,
          addItem: handler.addItem,
        }}
      />
    </>
  )
}
