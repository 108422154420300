import React, { useState } from 'react'
import { Button, DataPlaceholder, Divider, Image, Modal } from 'components/core'
import { Flex } from 'reflexbox'
import ImageThumb from './ImageThumb'
import { urlencode } from 'utilities/app'

const ImageGallery = ({ url, imagePath, images }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)

  return (
    <>
      <Flex flexWrap="wrap" alignContent="center">
        {images.length === 0 ? (
          <DataPlaceholder />
        ) : (
          images
            .filter((item) => typeof item === 'string')
            .map((item) => {
              const src = `${url}/${imagePath}/${urlencode(item)}`
              return (
                <ImageThumb
                  key={item}
                  src={src}
                  onView={() => {
                    setPreviewOpen(true)
                    setPreviewImage({ src, alt: item })
                  }}
                />
              )
            })
        )}
      </Flex>
      <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <Image {...previewImage} maxHeight="calc(100vh - 128px)" />
        <Divider bg="light.3" />
        <Flex justifyContent="flex-end" alignItems="center" mt={3}>
          <Button
            variant="secondary"
            text="btn.close"
            onClick={() => setPreviewOpen(false)}
          />
        </Flex>
      </Modal>
    </>
  )
}

export default ImageGallery
