import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { filters, columns, handlers } from 'models/productComboCodeList'
import { Center, Section, Table, Button } from 'components/core'
import Page from 'components/Page'
import ProductCodeDrawer from 'views/ProductComboCodeDrawer'
import { initialPage } from 'utilities/pagination'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleInit, handleLoad } = handlers({
    session,
    app,
    setState,
    setData,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handleInit()
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const action = { handleLoad }

  return (
    <Page
      title="productComboCode.title"
      navId="productComboCode"
      action={
        <Center>
          <Button
            ml={3}
            variant="secondary"
            text="btn.add"
            onClick={() => setOpen(true)}
          />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({ session, app, action, data, message })}
          rows={state}
          filters={filters}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <ProductCodeDrawer
        open={open}
        data={data}
        onClose={() => setOpen(false)}
        onSubmit={() => {
          handleLoad()
          setOpen(false)
        }}
      />
    </Page>
  )
}
