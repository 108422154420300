import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  handleTextChange,
} from 'utilities/form'
import { Select, TextInput } from 'components/form'

export const initialState = () =>
  initializeState({
    sdjWarehouse: '',
    warehouse: {},
  })

const validation = {
  sdjWarehouse: [{ type: 'required', message: 'error.required' }],
  warehouse: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    sdjWarehouse: (
      <TextInput
        id="sdjWarehouse"
        label="plugin.field.sdjWarehouse"
        placeholder="plugin.field.sdjWarehouse"
        value={state.sdjWarehouse}
        onChange={onTextChange('sdjWarehouse')}
        errMsg={state.__error__.sdjWarehouse}
      />
    ),
    warehouse: (
      <Select
        isClearable={false}
        label="plugin.field.warehouse"
        options={data.warehouses}
        value={state.warehouse}
        onChange={onSelectChange('warehouse')}
        errMsg={state.__error__.warehouse}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { warehouse, sdjWarehouse } = state
    action.addWarehouse({
      locationId: warehouse.value,
      locationName: warehouse.label,
      sdjLocationId: sdjWarehouse,
    })
    action.handleClose()
    setState(initialState())
  },
})
