import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Text, Message } from 'components/core'

export default ({
  id,
  label,
  required = false,
  errMsg,
  children,
  ...props
}) => {
  if (errMsg && typeof errMsg === 'string') {
    errMsg = { id: errMsg }
  }

  if (errMsg && errMsg.constructor === Array) {
    errMsg = { id: errMsg[0], values: errMsg[1] }
  }

  const renderLabel = () => {
    if (!label) return null

    const result = (
      <Message
        as="label"
        htmlFor={id}
        display="block"
        color="dark.0"
        mb={1}
        id={label}
      />
    )

    if (required) {
      return (
        <Flex sx={{ alignItems: 'flex-start' }}>
          {result}
          <Text color="error.1" pl={1}>
            *
          </Text>
        </Flex>
      )
    }
    return result
  }

  return (
    <Box mt={3} {...props}>
      {renderLabel()}
      {children}
      {errMsg && (
        <Message
          display="block"
          mt={1}
          color="error.1"
          id={errMsg.id}
          values={errMsg.values}
        />
      )}
    </Box>
  )
}
