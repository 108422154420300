import React from 'react'
import {
  initializeState,
  validateForm,
  handleTextChange,
  getDate,
} from 'utilities/form'
import { DateInput } from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = () =>
  initializeState({
    syncDate: getDate(),
  })

const validation = {}

export const fields = ({ state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)

  return {
    syncDate: (
      <DateInput
        id="syncDate"
        label="field.date"
        placeholder="field.date"
        value={state.syncDate}
        onChange={onTextChange('syncDate')}
        errMsg={state.__error__.syncDate}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { syncType } = data
    const [ok, resp] = await syncOrders({ app, session, state, syncType })
    if (!ok || !resp) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'pxmart.message.syncSuccess' },
    })
    action.handleClose()
    setState(initialState())
  },
})

async function syncOrders({ session, app, state, syncType }) {
  const variables = { input: { syncDate: state.syncDate } }
  const query = `
    mutation($input: PxmartSyncInput!) {
      ${syncType}(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
