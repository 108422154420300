import React from 'react'
import { request } from 'utilities/request'
import { getSelectOptions } from 'utilities/form'
import { textFilter, selectFilter, limitDateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'
import { filterLocations } from 'utilities/permission'
import { today, yesterday } from 'utilities/date'

export const filters = ({ data }) => [
  {
    id: 'dealer',
    label: 'field.dealer',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.dealers),
      }),
  },
  {
    id: 'org',
    label: 'field.org',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.orgs),
      }),
  },
  {
    id: 'dept',
    label: 'field.dept',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.depts),
      }),
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: limitDateFilter({ max: yesterday() }),
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
]

export const columns = ({ data, filterValues, action }) => [
  {
    id: 'locationName',
    label: 'field.dealer',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.dealerMap) return ''
      return data.dealerMap[row.locationId]?.name
    },
  },
  {
    id: 'locationCode',
    label: 'field.dealerCode',
    format: ['excel'],
    render: ({ row }) => {
      if (!data.dealerMap) return ''
      return data.dealerMap[row.locationId]?.code
    },
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.name
    },
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.sku
    },
  },
  {
    id: 'sellQuantity',
    label: 'field.sellQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            isGift: false,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {round(row.sellQuantity, 1)}
      </Link>
    ),
    render: ({ row }) => round(row.sellQuantity, 1),
  },
  {
    id: 'sellGiftQuantity',
    label: 'field.sellGiftQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            isGift: true,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {round(row.sellGiftQuantity, 1)}
      </Link>
    ),
    render: ({ row }) => round(row.sellGiftQuantity, 1),
  },
  {
    id: 'sellAmount',
    label: 'field.sellAmount',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            locationId: row.locationId,
            productVariantId: row.productVariantId,
            showAmount: true,
            isGift: false,
          })
        }}
      >
        {round(row.sellAmount)}
      </Link>
    ),
    render: ({ row }) => round(row.sellAmount),
  },
  {
    id: 'refundQuantity',
    label: 'field.refundQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {round(row.refundQuantity)}
      </Link>
    ),
    render: ({ row }) => row.refundQuantity,
  },
]

function round(val, decimal = 100) {
  if (!val) return 0
  return Math.round(val * decimal) / decimal
}

export const handlers = ({
  app,
  message,
  setState,
  session,
  setData,
  setTotal,
  setFilterValues,
  setPagination,
}) => ({
  handleInit: async () => {
    const data = await getInit({ session, app })
    data.dealerMap = data.dealers.reduce((result, item) => {
      result[item.id] = item
      return result
    }, {})
    data.productMap = data.productVariants.reduce((result, item) => {
      result[item.id] = item
      return result
    }, {})

    setData(data)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setTotal(resp.total)
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ data, filterValues, createExcel }) => {
    const title = 'report.title.dealerSales'
    const cols = columns({ data, message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
  handleUpdate: async ({ pagination, filterValues }) => {
    const ok = await updateReport({ session, app })
    if (!ok) return false

    const resp = await getData({ session, app, pagination, filterValues })
    setTotal(resp.total)
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)

    return true
  },
})

async function getInit({ session, app }) {
  const variables = {
    input: { type: ['DEPT', 'DEALER_ORG', 'DEALER'] },
    productInput: { status: ['ACTIVE', 'INACTIVE'] },
    merchantId: app.state.staff.merchantId,
  }
  const query = `
    query($input: LocationQueryInput, $productInput: ProductQueryInput, $merchantId: ID) {
      locations(input: $input) {
        id
        name
        type
        extra
      }
      productVariants(input: $productInput) {
        id
        name
        sku
      }
      reportLogs(merchantId: $merchantId) {
        id
        updatedAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const depts = []
  const orgs = []
  const dealers = []

  locations.forEach((item) => {
    switch (item.type) {
      case 'DEPT':
        depts.push(item)
        break
      case 'DEALER_ORG':
        orgs.push(item)
        break
      case 'DEALER':
        item.code = item.extra?.code
        dealers.push(item)
        break
      default:
    }
  })

  const reportLog = data.reportLogs?.find((item) => item.id === 'locationSales')
  return {
    depts,
    orgs,
    dealers,
    productVariants: data.productVariants,
    updatedAt: reportLog?.updatedAt + ' UTC',
  }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const input = getDataInput({ filterValues, pagination })
  const variables = { input }
  const query = `
    query($input: LocationSalesReportInput) {
      locationSalesReportCount2(input: $input)
      locationSalesReports2(input: $input) {
        locationId
        productVariantId
        sku
        sellQuantity
        sellGiftQuantity
        sellAmount
        refundQuantity
      }
      locationSalesTotal2(input: $input) {
        sellQuantity
        sellGiftQuantity        
        sellAmount
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { sellQuantity, sellGiftQuantity, sellAmount } =
    data.locationSalesTotal2 || {}
  const { page, countPerPage } = pagination
  return {
    data: data.locationSalesReports2,
    total: { sellQuantity, sellGiftQuantity, sellAmount: round(sellAmount) },
    pagination: getPagination(
      page,
      countPerPage,
      data.locationSalesReportCount2,
    ),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: LocationSalesReportInput) {
      locationSalesReports2(input: $input) {
        locationId
        productVariantId
        sku
        sellQuantity
        sellGiftQuantity
        sellAmount
        refundQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.locationSalesReports2
}

function getDataInput({ filterValues, pagination }) {
  const dept = filterValues.find(({ id }) => id === 'dept')
  const org = filterValues.find(({ id }) => id === 'org')
  const dealer = filterValues.find(({ id }) => id === 'dealer')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const input = {
    deptId: dept?.value.value,
    orgId: org?.value.value,
    dealerId: dealer?.value.value,
    transDate: transDate?.value,
    productVariantName: productName?.value,
    // orderBy: [
    //   { key: 'locationName' },
    //   { key: 'productVariantName' },
    //   { key: 'sku' },
    // ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function updateReport({ session, app }) {
  const fromDate = today() + ' 00:00:00'
  const toDate = today() + ' 23:59:59'
  const variables = { transDate: [fromDate, toDate] }
  const query = `
    mutation($transDate: [String]) {
      addLocationSales(transDate: $transDate) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.addLocationSales
}
