import React, { Suspense, lazy } from 'react'
import { createExcel, readExcel } from 'utilities/excel'
import {
  getSelectOptions,
  handleTextChange,
  initializeState,
  validateForm,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Center, LoadingIcon } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'

const FileInput = lazy(() => import('components/file/FileInput'))

export const initialState = (value = {}) => {
  const productMap = value.products?.reduce((result, item) => {
    result[item.name] = { value: item.id, label: item.name }
    return result
  }, {})

  return {
    products: getSelectOptions(value.products),
    productMap,
    ...initializeState({
      file: '',
    }),
  }
}

const columns = [
  { id: 'ticketId', label: 'dispatch.field.ticketId' },
  { id: 'productVariantId', label: 'dispatch.field.spu' },
  { id: 'serial', label: 'dispatch.field.serial' },
]

const validation = {
  file: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    file: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <FileInput
          id="file"
          label="dispatch.field.importFile"
          accept=".xlsx"
          value={state.file}
          onUpload={onTextChange('file')}
          onDelete={onTextChange('file')}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
          errMsg={state.__error__.file}
        />
      </Suspense>
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  message,
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, message })
    setState(initialState(data))
  },
  downloadTemplate: () => {
    const title = 'dispatch.message.template'
    createExcel({ message, title, timestamp: false, cols: columns, rows: [] })
  },
  loadExcel: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const excelFile = await readFile(state.file)
    const data = await readExcel(excelFile)
    const ticketItems = data.reduce((result, item) => {
      const row = parseRow({ state, item, message })
      result.push(row)

      return result
    }, [])

    setState({ ...state, ticketItems })
    action.setOpen(true)
  },
})

async function getData({ app, session, message }) {
  const productInput = { status: ['ACTIVE', 'INACTIVE'] }
  const variables = { productInput }
  const query = `
    query($productInput: ProductQueryInput) {
      productVariants(input: $productInput) {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { productVariants } = data

  return { products: productVariants }
}

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

function parseRow({ state, item, message }) {
  const row = {}

  columns.forEach(({ id, label }) => {
    let value = item[message({ id: label })]
    if (typeof value === 'string') value = value.trim()

    const productOption = state.productMap[value]

    switch (id) {
      case 'ticketId':
        value = `${value}`
        break
      case 'productVariantId':
        row.productVariantName = value
        value = productOption || null
        break
      default:
    }
    row[id] = value
  })

  return row
}
