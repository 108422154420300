import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import AppContext from 'contexts/AppContext'
import { NAV_SET } from 'constants/actionType'
import { Box, Flex } from 'reflexbox'
import { Definition, Navbar } from 'components/core'
import { showDateTime } from 'utilities/form'

export default ({
  profile = 'view',
  navId,
  title,
  titleExtra,
  action,
  showAudit = false,
  audit,
  showFooterAction = false,
  fullWidth,
  children,
  ...props
}) => {
  const app = useContext(AppContext)
  const { formatMessage } = useIntl()

  useEffect(() => {
    document.title = formatMessage({ id: title })

    if (navId) {
      app.dispatch({ type: NAV_SET, id: navId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navId, title])

  const displayAudit = audit && (showAudit || profile === 'view')

  return (
    <Flex
      pb={7}
      width={1}
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        overflowX: 'auto',
      }}
      {...props}
    >
      <Box
        width={fullWidth ? '100%' : ['100%', '100%', '100%', '992px']}
        px={fullWidth ? 4 : 0}
        py={4}
      >
        <Navbar
          variant="toolbar"
          wrap
          px={3}
          mb={[2, 4]}
          title={title}
          titleProps={{ fontSize: 4 }}
          titleExtra={titleExtra}
          action={action}
        />
        {children}
        {(showFooterAction || displayAudit) && (
          <Flex
            mt={4}
            px={3}
            justifyContent={displayAudit ? 'space-between' : 'flex-end'}
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Audit
              show={displayAudit}
              createdBy={audit?.createdBy}
              createdAt={audit?.createdAt}
              updatedBy={audit?.updatedBy}
              updatedAt={audit?.updatedAt}
            />
            {action}
          </Flex>
        )}
      </Box>
    </Flex>
  )
}

function Audit({
  show = false,
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
  ...props
}) {
  if (!show) return null
  return (
    <Box {...props}>
      <Definition
        mt={2}
        direction="row"
        label="ticket.createdAt"
        value={showDateTime(createdAt)}
        show={!!createdAt}
      />
      <Definition
        mt={1}
        direction="row"
        label="ticket.createdBy"
        value={createdBy}
        show={!!createdBy}
      />
      <Definition
        mt={1}
        direction="row"
        label="ticket.updatedAt"
        value={showDateTime(updatedAt)}
        show={!!updatedAt}
      />
      <Definition
        mt={1}
        direction="row"
        label="ticket.updatedBy"
        value={updatedBy}
        show={!!updatedBy}
      />
    </Box>
  )
}
