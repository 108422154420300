import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/sdjPage'
import { Box } from 'reflexbox'
import { Grid, Section, Button, Link, Center, Toolbar } from 'components/core'
import Page from 'components/Page'
import SdjShippingModal from 'views/SdjShippingModal'
import { MdAdd, MdArrowBack } from 'react-icons/md'
import SdjWarehouseModal from './SdjWarehouseModal'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default () => {
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}))
  const [warehouseOpen, setWarehouseOpen] = useState(false)
  const [shippingOpen, setShippingOpen] = useState(false)

  const form = fields({ app, session, state, setState })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    history,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page
        navId="pluginList"
        title={`plugin.title.edit`}
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/plugin"
            />
            <Button ml={3} variant="primary" text="btn.save" type="submit" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
      >
        <Grid cols={['1fr', '1fr 2fr']} gap={3}>
          <Box>
            <Section title="plugin.section.basic" mb={[3, 0]}>
              {form.secret}
              {form.auth}
            </Section>
            <Section mt={3}>{form.isActive}</Section>
          </Box>
          <Box>
            <Section title="plugin.section.warehouse">
              <Toolbar>
                <Button
                  variant="default"
                  icon={<MdAdd />}
                  text="btn.add"
                  onClick={() => setWarehouseOpen(true)}
                />
              </Toolbar>
              {form.warehouseList}
            </Section>
            <Section mt={5} title="plugin.section.shipping">
              <Toolbar>
                <Button
                  variant="default"
                  icon={<MdAdd />}
                  text="btn.add"
                  onClick={() => setShippingOpen(true)}
                />
              </Toolbar>
              {form.shippingList}
            </Section>
          </Box>
        </Grid>
      </Page>
      <SdjWarehouseModal
        data={{
          warehouses: state.warehouses,
          sdjWarehouses: state.sdjWarehouses,
        }}
        open={warehouseOpen}
        action={{
          handleClose: () => setWarehouseOpen(false),
          addWarehouse: handler.addWarehouse,
        }}
      />
      <SdjShippingModal
        data={{ shippings: state.shippings, sdjShippings: state.sdjShippings }}
        open={shippingOpen}
        action={{
          handleClose: () => setShippingOpen(false),
          addShipping: handler.addShipping,
        }}
      />
    </>
  )
}
