import React from 'react'
import { DateInput, Select, TextArea } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getDate,
  getSelectOption,
} from 'utilities/form'
import { ALERT_ADD } from 'constants/actionType'
import { request } from 'utilities/request'

export const initialState = (value = {}, data) => {
  const { staffs } = data
  return {
    index: value.index,
    id: value.id,
    staffs,
    ...initializeState({
      createdAt: getDate(value.createdAt),
      staffId: getSelectOption(staffs, value.staffId),
      content: value.content || '',
    }),
  }
}

const validation = {
  createdAt: [{ type: 'required', message: 'error.required' }],
  staffId: [{ type: 'required', message: 'error.required' }],
  content: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    createdAt: (
      <DateInput
        id="createdAt"
        label="field.date"
        value={state.createdAt}
        onChange={onTextChange('createdAt')}
        errMsg={state.__error__.createdAt}
      />
    ),
    staffId: (
      <Select
        id="staffId"
        label="customer.field.staffId"
        placeholder="customer.field.staffId"
        options={state.staffs}
        value={state.staffId}
        onChange={onSelectChange('staffId')}
        errMsg={state.__error__.staffId}
      />
    ),
    content: (
      <TextArea
        id="content"
        label="customer.field.content"
        value={state.content}
        onChange={onTextChange('content')}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, id, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = state.id
      ? await editMemo({ app, session, state })
      : await addMemo({ app, session, state, id })

    if (ok) {
      action.handleLoad()
      action.handleClose()
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'save.success' },
      })
    }
  },
})

async function addMemo({ session, app, state, id }) {
  const input = getSubmitInput(state)
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: CustomerMemoInput!) {
      addCustomerMemo(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editMemo({ session, app, state }) {
  const input = getSubmitInput(state)
  const variables = { memoId: state.id, input }
  const query = `
    mutation($memoId: ID!, $input: CustomerMemoInput!) {
      editCustomerMemo(memoId: $memoId, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { staffId, content, createdAt } = state
  return {
    createdAt: createdAt,
    staffId: staffId.value,
    staffName: staffId.label,
    content,
  }
}
