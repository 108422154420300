import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/sdjStockDiffList'
import { Button, Section, Table, Toolbar } from 'components/core'
import Page from 'components/Page'
import { CONFIRM_SET } from 'constants/actionType'
import { MdPrint, MdSync } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const handler = handlers({ session, app, state, setState, message })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="inventoryDiff.title.list" navId="sdjStockDiffList">
      <Section>
        <Toolbar>
          <Button
            variant="default"
            text="dashboard.btn.sync"
            icon={<MdSync />}
            onClick={() => {
              const item = {
                open: true,
                title: 'dashboard.title.sync',
                text: 'dashboard.message.sync',
                onSubmit: handler.syncInventoryDiff,
              }
              session.dispatch({ type: CONFIRM_SET, item })
            }}
          />
          <Button
            variant="default"
            text="btn.print"
            icon={<MdPrint />}
            onClick={handler.handlePrint}
          />
        </Toolbar>
        <Table columns={columns({ message })} rows={state} />
      </Section>
    </Page>
  )
}
