import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Center, Drawer, Button, Toolbar } from 'components/core'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/refundReceivedDrawer'
import { MdAdd } from 'react-icons/md'

export default ({ open, value, data, action }) => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, message))
  const handler = handlers({ app, session, data, state, setState, action })
  const form = fields({ data, state, setState, message })

  useEffect(() => {
    if (open) setState(initialState(value, message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Drawer
      width={750}
      title="refund.title.edit"
      open={open}
      onClose={action.handleClose}
      contentProps={{ titleProps: { py: 3, flex: 1 } }}
    >
      {form.productVariant}
      {form.comboQuantity}
      {form.quantity}
      {form.maxSingleQuantity}
      <Toolbar>
        <Button
          variant="default"
          icon={<MdAdd />}
          text="btn.add"
          onClick={handler.addResult}
        />
      </Toolbar>
      {form.resultItems}
      <Center mt={3}>
        <Button
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          variant="primary"
          text="btn.confirm"
          onClick={async (event) => {
            const ok = await handler.handleConfirm(event)
            if (ok) action.handleClose()
          }}
        />
      </Center>
    </Drawer>
  )
}
