import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  validateForm,
  getSelectOption,
  getDate,
  renderDefinition,
  renderDateInput,
  renderTextAreaInput,
  renderSelectInput,
  renderTable,
  renderTextInput,
  renderImageDropzone,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getLocation } from 'actions/location'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import { handleDelete, setBalances } from 'actions/ticket'
import { request } from 'utilities/request'
import { Select, NumberInput } from 'components/form'
import { filterLocations } from 'utilities/permission'
import { getProductOptions } from 'actions/product'
import { updateImages } from 'actions/image'

const moduleName = 'adjust'

export const initialState = (value = {}, message) => {
  const types = getAdjustTypes(message)
  const extra = value.extra || {}
  return {
    isReady: value.isReady || false,
    id: value.id,
    locked: extra.locked || false,
    toLocationName: value.toLocationName || '',
    status: value.status || 'PENDING',
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    inputValues: getInitialInput(),
    editValues: {},
    locations: value.locations || [],
    locationOptions: getLocationOptions(value.locations, message),
    products: value.products || [],
    images: extra.images || [],
    imagesToAdd: [],
    imagesToDel: [],
    types,
    ...initializeState({
      ticketNo: value.ticketNo || '',
      transDate: getDate(value.transDate),
      type: getSelectOption(types, extra.type),
      toLocationId: getSelectOption(
        value.locations,
        value.toLocationId,
        'id',
        'name',
      ),
      memo: extra.memo || '',
    }),
  }
}

function getInitialInput() {
  return {
    productVariantId: '',
    productVariantName: '',
    balance: 0,
    quantity: 0,
  }
}

export function getAdjustTypes(message) {
  return [
    {
      value: 'NAME',
      label: message({ id: 'adjust.type.NAME' }),
    },
    {
      value: 'QUANTITY',
      label: message({ id: 'adjust.type.QUANTITY' }),
    },
    {
      value: 'COMBO',
      label: message({ id: 'adjust.type.COMBO' }),
    },
    {
      value: 'OTHER',
      label: message({ id: 'adjust.type.OTHER' }),
    },
  ]
}

export function getLocationOptions(locations, message) {
  if (!locations) return []
  const warehouses = []
  const depts = []
  const orgs = []
  const dealers = []
  locations.forEach(({ type, id, name }) => {
    const result = { label: name, value: id }

    switch (type) {
      case 'DEPT':
        depts.push(result)
        break
      case 'DEALER_ORG':
        orgs.push(result)
        break
      case 'DEALER':
        dealers.push(result)
        break
      default:
        warehouses.push(result)
    }
  })
  return [
    { label: message({ id: 'location.type.WAREHOUSE' }), options: warehouses },
    { label: message({ id: 'location.type.DEPT' }), options: depts },
    { label: message({ id: 'location.type.DEALER_ORG' }), options: orgs },
    { label: message({ id: 'location.type.DEALER' }), options: dealers },
  ]
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  toLocationId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({
  profile,
  format,
  state,
  setState,
  app,
  session,
  action,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    transDate: dateInput({ id: 'transDate', role: 'lockDispatchTicket' }),
    ticketNo: textInput({ id: 'ticketNo' }),
    type: selectInput({ id: 'type', options: state.types }),
    toLocation: selectInput({
      id: 'toLocationId',
      valKey: 'toLocationName',
      isClearable: false,
      options: state.locationOptions,
      callback: ({ value }) => onLocationChange(state, app, session, value),
    }),
    images: renderImageDropzone({
      format,
      profile,
      app,
      session,
      state,
      setState,
      action,
      imagePath: `${app.state.staff.merchantId}/${state.id}`,
    }),
    memo: textAreaInput({ id: 'memo' }),
    product: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'adjust.field.spu',
          renderInput: () => (
            <Select
              isClearable={false}
              options={getProductOptions(state.products)}
              onChange={async ({ value }) => {
                const balance = await fetchBalance({
                  app,
                  session,
                  locationType: 'DEFAULT',
                  locationId: state.toLocationId.value,
                  productVariantId: value,
                })
                return { balance, quantity: 1 }
              }}
            />
          ),
          getValue: (row) =>
            getSelectOption(state.products, row.productVariantId, 'id', 'name'),
        },
        {
          id: 'balance',
          label: 'adjust.field.balance',
          align: 'right',
          format: ['html'],
          show: profile !== 'view',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          width: '96px',
          align: 'right',
          renderInput: () => <NumberInput />,
        },
      ],
      showAddInput: profile !== 'view',
      showDeleteIcon: profile !== 'view',
      inputValues: state.inputValues,
      editValues: state.editValues,
      onInputChange: (value) => setState({ ...state, inputValues: value }),
      onEditChange: (value) => setState({ ...state, editValues: value }),
      onAdd: ({ row }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.push({
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        })
        const inputValues = getInitialInput()
        setState({ ...state, ticketItems, inputValues })
      },
      onEdit: ({ row, index }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const ticketItems = cloneDeep(state.ticketItems)
        const ticketItem = {
          ...ticketItems[index],
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        }
        ticketItems.splice(index, 1, ticketItem)
        setState({ ...state, ticketItems })
        return true
      },
      onDelete: ({ index }) => {
        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.splice(index, 1)
        setState({ ...state, ticketItems })
      },
    }),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  message,
  profile,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editAdjust(state, app, session)
      : await addAdjust(state, app, session)
    if (!ok) return

    if (!id) id = data.addAdjustTicket

    const imageOk = await updateImages(app, session, state, id)
    if (!imageOk) {
      if (!id) history.push(`/adjust/${id}/edit`)
      return
    }

    history.push(`/adjust/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmAdjust({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })

    return true
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('adjust', { session, app, id, hash })
    if (!ok) return false

    history.push('/adjust')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ app, state })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ app, state }) {
  const title = { id: 'adjust.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const form = fields({ profile: 'view', format: 'print', state, app })
  const field = Object.values(form).filter(
    ({ id }) => !['ticketItems', 'images'].includes(id),
  )
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: form.product },
  ]
  return { title, header, content }
}

async function getData({ app, session, id, profile }) {
  const locationInput = { type: ['WAREHOUSE', 'DEPT', 'DEALER_ORG', 'DEALER'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      productVariants {
        id
        name
        sku
      }
      adjustTicket(id: $id) {
        ticketNo
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      adjustTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.adjustTicket || {}
  const ticketItems = data.adjustTicketItems || []
  const oldTicketItems = cloneDeep(ticketItems)

  if (profile === 'edit' && ticketItems.length > 0) {
    const productVariantId = ticketItems.map((item) => item.productVariantId)
    const balances = await fetchBalances({
      app,
      session,
      locationType: 'WAREHOUSE',
      locationId: ticket.toLocationId,
      productVariantId,
    })
    setBalances(ticketItems, balances)
  }

  return {
    isReady: profile === 'edit',
    id,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    // locked: ticket.locked,
    ticketNo: ticket.ticketNo,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    transDate: ticket.transDate,
    // type: ticket.extra?.type,
    // memo: ticket.extra?.memo,
    // images: ticket.extra?.images,
    extra: ticket.extra || {},
    status: ticket.status,
    hash: ticket.hash,
    ticketItems,
    oldTicketItems,
    locations,
    products: productVariants,
  }
}

async function addAdjust(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addAdjustTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editAdjust(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editAdjustTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    return item.quantity === newItem.quantity
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)
  const images = state.images.map((item) => item.name || item)

  return {
    hash,
    ticketNo: state.ticketNo,
    transDate: state.transDate,
    toLocationId: state.toLocationId.value,
    extra: {
      type: state.type.value,
      memo: state.memo,
      images,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function onLocationChange(state, app, session, locationId) {
  const { locations } = state
  const ticketItems = [...state.ticketItems]
  if (ticketItems.length === 0) return { inputValues: getInitialInput() }

  const location = getLocation(locations, locationId)
  const productVariantId = ticketItems.map((item) => item.productVariantId)
  const balances = await fetchBalances({
    app,
    session,
    locationType: location.type,
    locationId: location.id,
    productVariantId,
  })
  setBalances(ticketItems, balances)

  return { ticketItems, inputValues: getInitialInput() }
}

async function confirmAdjust({ session, app, id, hash }) {
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmAdjustTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
