import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/transferInDrawer'
import { Toolbar, Center, Drawer, Button } from 'components/core'
import { MdAdd } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, data, readonly, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data))
  const form = fields({ app, session, state, setState, data })
  const handler = handlers({ state, setState, app, session, data, action })

  useEffect(() => {
    setState(initialState(value, data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      width={[1, '460px']}
      title={
        readonly ? 'transferIn.title.listItems' : 'transferIn.title.addItem'
      }
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.productVariantId}
      {form.balance}
      {form.quantity}
      <Toolbar show={!readonly}>
        <Button
          variant="default"
          icon={<MdAdd />}
          text="transferIn.btn.addBatch"
          onClick={handler.addBatch}
        />
      </Toolbar>
      {form.batchItems}
      <Center mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          show={!readonly}
          disabled={!handler.enableSubmit()}
          type="submit"
          variant="primary"
          text="btn.confirm"
        />
      </Center>
    </Drawer>
  )
}
