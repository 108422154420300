import React, { Suspense } from 'react'
import { initializeState, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'
import { FileInput } from 'components/file'
import { Center, LoadingIcon } from 'components/core'
import { readExcel } from 'utilities/excel'

export const initialState = () =>
  initializeState({
    file: '',
  })

const validation = {}

export const fields = ({ session, state, setState }) => {
  return {
    file: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <FileInput
          id="file"
          label="pxmart.field.filepath"
          accept=".xlsx"
          maxSize={15000000}
          value={state.file}
          onUpload={(file) => setState({ file })}
          onDelete={() => {
            setState({ file: null })
          }}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
        />
      </Suspense>
    ),
  }
}

export const handlers = ({ app, session, state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const excelFile = await readFile(state.file)
    const data = await readExcel(excelFile)

    const [ok, resp] = await uploadOrders({ app, session, data })
    if (!ok || !resp) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'pxmart.message.uploadSuccess' },
    })
    action.handleClose()
    setState(initialState())
  },
})

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

async function uploadOrders({ session, app, data }) {
  const variables = { input: { data } }
  const query = `
    mutation($input: PxmartUploadInput!) {
      uploadPxmartOrder(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
