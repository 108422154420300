import React, { useState, useContext, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/pluginList'
import { Section, Table } from 'components/core'
import Page from 'components/Page'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const { handleLoad } = handlers({ setState, session, app })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const action = { handleLoad }

  return (
    <Page title="plugin.title.list" navId="pluginList">
      <Section>
        <Table
          columns={columns({ setState, session, app, action })}
          rows={state}
        />
      </Section>
    </Page>
  )
}
