import { request } from 'utilities/request'

export const columns = [
  {
    id: 'locationName',
    label: `field.org`,
  },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right',
  },
]

export const handlers = ({ setState, session, app, value, data }) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    const { orgMap } = data
    let resp = await getData({ session, app, value, pagination })
    if (resp) {
      resp = resp.map((item) => ({
        ...item,
        locationName: orgMap[item.locationId],
      }))
    }
    setState(resp)
  },
})

async function getData({ session, app, value }) {
  const input = {
    parentLocationId: value.locationId,
    productVariantId: value.productVariantId,
  }
  const variables = { input }
  const query = `
    query($input: InventoryInput!) {
      inventoryBalances(input: $input) {
        locationId
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  return data.inventoryBalances
}
