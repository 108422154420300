import React, { Suspense, lazy } from 'react'
import { ALERT_ADD } from 'constants/actionType'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { uploadFile } from 'utilities/file'
import { Center, Table, Button, LoadingIcon } from 'components/core'
import { TextInput } from 'components/form'
import { MdDelete, MdEdit } from 'react-icons/md'

const url = process.env.REACT_APP_STATIC_URL
const ImageDropzone = lazy(() => import('components/file/ImageDropzone'))

export const initialState = (value = {}, message) => ({
  id: value.id || '',
  createdBy: value.createdBy,
  createdAt: value.createdAt,
  updatedBy: value.updatedBy,
  updatedAt: value.updatedAt,
  processItems: getProcessItems(value.processItems, message),
  images: value.images || [],
  imagesToAdd: [],
  imagesToDel: [],
  ...initializeState({
    name: value.name || '',
  }),
})

function getProcessItems(items, message) {
  if (!items || items.length === 0) return []
  return items.map((item) => ({
    ...item,
    unit: {
      value: item.unit,
      label: message({ id: `processConfig.unit.${item.unit}` }),
    },
  }))
}

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
}
export const fields = ({
  app,
  session,
  state,
  setState,
  message,
  id,
  action,
}) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="processConfig.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    processItems: (
      <Table
        columns={[
          {
            id: 'processName',
            label: 'processConfig.field.processName',
          },
          {
            id: 'code',
            label: 'processConfig.field.code',
          },
          {
            id: 'memo',
            label: 'processConfig.field.memo',
          },
          {
            id: 'unit',
            label: 'processConfig.field.unit',
            render: ({ row }) =>
              message({ id: `processConfig.unit.${row.unit.value}` }),
          },
          {
            id: 'quantity',
            label: 'processConfig.field.quantity',
          },
          {
            id: 'price',
            label: 'processConfig.field.price',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <>
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() => action.handleOpen({ ...row, index })}
                />
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdDelete />}
                  onClick={() => {
                    const processItems = [...state.processItems]
                    processItems.splice(index, 1)
                    setState({ ...state, processItems })
                  }}
                />
              </>
            ),
          },
        ]}
        rows={state.processItems}
      />
    ),
    images: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <ImageDropzone
          baseUrl={`${url}/${app.state.staff.merchantId}/${id}/`}
          value={state.images || []}
          onUpload={(files) => {
            const images = [...state.images, ...files]
            const imagesToAdd = [...state.imagesToAdd, ...files]
            setState({ ...state, images, imagesToAdd })
          }}
          onDelete={(file, index) => {
            const images = [...state.images]
            images.splice(index, 1)
            const imagesToDel = [...state.imagesToDel]
            if (!file.preview) imagesToDel.push(file)
            setState({ ...state, images, imagesToDel })
          }}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
          onDrag={(images) => {
            setState({ ...state, images })
          }}
        />
      </Suspense>
    ),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  message,
  history,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    let { id } = state
    const [ok, data] = id
      ? await editConfig(state, app, session)
      : await addConfig(state, app, session)
    if (!ok) return

    if (!state.id) id = data.addTicketConfig
    const imageOk = await updateImages(app, session, state, id)
    if (!imageOk) {
      if (!id) history.push(`/processConfig/${id}/edit`)
      return
    }

    history.push('/processConfig')
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  addConfigItem: (value) => {
    const processItems = [...state.processItems]
    const { index } = value
    if (index === -1) {
      processItems.push(value)
    } else {
      processItems.splice(index, 1, value)
    }
    setState({ ...state, processItems })
  },
  sortProducts: (ref, onClose) => () => {
    const indexList = ref.current.getRows().map((item) => item.index)
    const processItems = indexList.reduce((result, item) => {
      result.push(state.processItems[item])
      return result
    }, [])

    setState({ ...state, processItems })
    onClose()
  },
})

async function getData({ app, session, id }) {
  const input = { type: ['DEALER_ORG'] }
  const variables = { id, input }
  const query = `
    query($id: ID, $input: LocationQueryInput) {
      ticketConfig(id: $id) {
        id
        name
        content
        extra
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const ticketConfig = data.ticketConfig || {}
  const processItems = ticketConfig.content?.processItems.map((item) => ({
    processName: item.processName,
    code: item.code,
    memo: item.memo,
    unit: item.unit,
    quantity: item.quantity,
    price: item.price,
  }))
  const images = ticketConfig.extra?.images.map((item) =>
    item.substr(item.lastIndexOf('/') + 1),
  )
  return {
    id: ticketConfig.id,
    name: ticketConfig.name,
    processItems,
    images,
    createdBy: ticketConfig.createdBy,
    createdAt: ticketConfig.createdAt,
    updatedBy: ticketConfig.updatedBy,
    updatedAt: ticketConfig.updatedAt,
  }
}

async function addConfig(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketConfigInput!) {
      addTicketConfig(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editConfig(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketConfigInput!) {
      editTicketConfig(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, name } = state
  const processItems = state.processItems.map((item) => ({
    processName: item.processName,
    code: item.code,
    memo: item.memo,
    unit: item.unit.value,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
  }))
  const images = state.images.map((item) => item.name || item)

  return {
    id,
    type: 'PROCESS',
    name,
    content: {
      processItems,
    },
    extra: { images },
  }
}

async function updateImages(app, session, state, id) {
  const { imagesToAdd, imagesToDel } = state

  const result = await Promise.all([
    ...imagesToDel.map(async (image) => deleteImage(app, session, id, image)),
    ...imagesToAdd.map(async (image) => addImage(app, session, id, image)),
  ])
  return !result.some((ok) => !ok)
}

async function addImage(app, session, id, file) {
  const variables = { id, filename: file.name, contentType: file.type }
  const query = `
    mutation($id: ID!, $filename: String!, $contentType: String!) {
      addFile(id: $id, filename: $filename, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addFile
  uploadFile({ url, fields, file })
  return ok
}

async function deleteImage(app, session, id, image) {
  const variables = { id, filename: image }
  const query = `
    mutation($id: ID!, $filename: String!) {
      deleteFile(id: $id, filename: $filename) 
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
