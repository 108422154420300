import React from 'react'
import { Box } from 'reflexbox'

export default ({
  show = true,
  cols = '1fr',
  rows = '1fr',
  colGap = 0,
  rowGap = 0,
  gap = 0,
  fullWidth = true,
  children,
  ...props
}) => {
  if (!show) return ''
  return (
    <Box
      width={fullWidth ? '100%' : ['100%', '100%', '100%', '992px']}
      sx={{
        display: 'grid',
        gridTemplateColumns: cols,
        gridTemplateRows: rows,
        gridColumnGap: colGap || gap,
        gridRowGap: rowGap || gap,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
