import React from 'react'
import { request } from 'utilities/request'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { Flex } from 'reflexbox'
import { Link, Collapsible } from 'components/core'
import { Checkbox, TextInput } from 'components/form'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

export const initialState = (value = {}) => ({
  id: value.role?.id || '',
  permissions: value.permissions || [],
  ...initializeState({
    name: value.role?.name || '',
  }),
})

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="role.field.name"
        placeholder="role.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    permissions: Object.entries(state.permissions).map(([key, val]) => (
      <Flex
        key={key}
        flexDirection="column"
        py={2}
        sx={{
          borderBottomColor: 'light.2',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }}
      >
        <Link
          icon={
            <Checkbox
              checked={val.active}
              isPartial={val.isPartial}
              onChange={(event) =>
                toggleModuleActive(event, state, setState, key)
              }
            />
          }
          text={`module.${key}`}
          textProps={{ flex: 1 }}
          rightIcon={val.open ? <MdArrowDropUp /> : <MdArrowDropDown />}
          onClick={() => toggleModuleOpen(state, setState, key)}
        />
        <Collapsible open={val.open}>
          {val.items.map((item, index) => (
            <Link
              key={index}
              display="flex"
              pt={2}
              ml={4}
              icon={<Checkbox checked={item.active} />}
              text={`${key}.${item.action}`}
              textProps={{ flex: 1 }}
              onClick={(event) =>
                toggleCodeActive(event, state, setState, key, index)
              }
            />
          ))}
        </Collapsible>
      </Flex>
    )),
  }
}

function toggleModuleOpen(state, setState, key) {
  const permissions = { ...state.permissions }
  permissions[key].open = !permissions[key].open
  setState({ ...state, permissions })
}

function toggleModuleActive(event, state, setState, key) {
  event.stopPropagation()
  const permissions = { ...state.permissions }
  const permission = permissions[key]
  const items = [...permission.items]
  items.forEach((item) => {
    item.active = !permission.active
  })
  permission.active = !permission.active
  permission.isPartial = !permission.active
  permission.items = items

  setState({ ...state, permissions })
}

function toggleCodeActive(event, state, setState, key, index) {
  event.stopPropagation()
  const permissions = { ...state.permissions }
  const items = [...permissions[key].items]
  items[index].active = !items[index].active
  const count = items.filter((item) => item.active).length
  permissions[key].active = count > 0
  permissions[key].isPartial = count < items.length
  setState({ ...state, permissions })
}

export const handlers = ({
  state,
  setState,
  app,
  session,
  history,
  roleId,
}) => ({
  handleLoad: async () => {
    const resp = await getData({ app, session, roleId })
    setState(initialState(resp))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = state.id
      ? await editRole({ state, app, session })
      : await addRole({ state, app, session })

    if (!ok) return

    history.push('/role')
  },
})

async function getData({ app, session, roleId }) {
  const variables = { id: roleId || null }
  let query = `
    query($id: ID) {
      permissions
      role(id: $id) {
        id
        name
      }
      rolePermissions(roleId: $id) {
        code
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  const { role, rolePermissions, permissions } = data
  const activeCodes = rolePermissions.map(({ code }) => code)
  return {
    role,
    permissions: getPermissions(permissions, activeCodes),
  }
}

function getPermissions(permissions, activeCodes) {
  return Object.entries(permissions).reduce((result, [module, codes]) => {
    let count = 0
    const items = Object.entries(codes).map(([action]) => {
      const active = activeCodes.includes(`${module}::${action}`)
      count += active ? 1 : 0
      return { action, active }
    })
    result[module] = {
      items,
      open: false,
      active: count > 0,
      isPartial: count < Object.keys(codes).length,
    }
    return result
  }, {})
}

async function addRole({ state, app, session }) {
  const variables = {
    input: {
      name: state.name,
      codes: getActiveCodes(state.permissions),
    },
  }
  const query = `
    mutation($input: RoleInput!) {
      addRole(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editRole({ state, app, session }) {
  const variables = {
    id: state.id,
    input: {
      name: state.name,
      codes: getActiveCodes(state.permissions),
    },
  }
  const query = `
    mutation($id: ID!, $input: RoleInput!) {
      editRole(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getActiveCodes(permissions) {
  return Object.entries(permissions).reduce((result, [key, value]) => {
    value.items.forEach((item) => {
      if (item.active) {
        result.push(`${key}::${item.action}`)
      }
    })
    return result
  }, [])
}
