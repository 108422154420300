import React from 'react'
import { request } from 'utilities/request'
import { Definition } from 'components/core'
import { NumberInput, Select, TextArea } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
  getSelectOptions,
} from 'utilities/form'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = (value = {}, data, message) => {
  const products = data.ticketItems.map(({ productVariantId }) => {
    return data.products.find((item) => item.id === productVariantId)
  })
  const productOptions = getSelectOptions(products)
  return {
    id: value.id,
    index: value.index,
    productVariantName: value.productVariantName,
    balance: 0,
    warehouses: getSelectOptions(data.warehouses),
    products,
    productOptions,
    ...initializeState({
      productVariantId: getSelectOption(productOptions, value.productVariantId),
      type: getType(value.type, message),
      warehouseId: getSelectOption(data.warehouses, value.toLocationId),
      quantity: value.quantity || 0,
      memo: value.memo || '',
    }),
  }
}

function getType(value, message) {
  if (!value) value = 'RETURN_WAREHOUSE'
  return { value, label: message({ id: `lend.type.${value}` }) }
}

const validation = {}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    productVariantId: state.id ? (
      <Definition
        direction="row"
        label="product.field.spu"
        value={state.productVariantName}
      />
    ) : (
      <Select
        label="field.productName"
        placeholder="field.productName"
        isClearable={false}
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId', ({ value }) => {
          if (!value) return { balance: 0 }
          const ticketItem = data.ticketItems.find(
            (item) => item.productVariantId === value,
          )
          return { balance: ticketItem?.balance || 0 }
        })}
      />
    ),
    type: (
      <Select
        label="field.type"
        isSearchable={false}
        isClearable={false}
        options={[
          {
            value: 'RETURN_WAREHOUSE',
            label: message({ id: `lend.type.RETURN_WAREHOUSE` }),
          },
          { value: 'DISCARD', label: message({ id: `lend.type.DISCARD` }) },
        ]}
        value={state.type}
        onChange={onSelectChange('type')}
      />
    ),
    warehouseId: (
      <Select
        id="warehouseId"
        label="field.warehouse"
        placeholder="field.warehouse"
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        max={state.balance}
        label="lend.field.quantity"
        placeholder="lend.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = state.id
      ? await editReturn({ app, session, state, data })
      : await addReturn({ app, session, state, data })
    if (!ok) return

    action.handleLoad()
    action.handleClose()
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
})

async function addReturn({ session, app, state, data }) {
  const variables = { id: data.id, input: getSubmitInput(state, data) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editLendTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editReturn({ session, app, state, data }) {
  const variables = { id: data.id, input: getSubmitInput(state, data) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editLendTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state, data) {
  const { id, type, warehouseId, productVariantId, quantity } = state
  const ticketItems = [
    {
      id,
      productVariantId: productVariantId.value,
      toLocationId: warehouseId?.value || null,
      quantity: parseInt(quantity),
      extra: { type: type.value, isReturn: true },
    },
  ]
  if (id) return { itemsToEdit: ticketItems }
  return { hash: data.hash, extra: { isReturn: true }, itemsToAdd: ticketItems }
}
