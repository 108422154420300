import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { columns, filters, handlers } from 'models/repairReportList'
import { SessionContext, AppContext } from 'contexts'
import { createExcel } from 'utilities/excel'
import { initialPage } from 'utilities/pagination'
import { Center, Popover, Link, Table, Section } from 'components/core'
import Page from 'components/Page'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="repairReport.title.list"
      navId="repairReportList"
      action={
        <Center>
          <Popover>
            <Link
              py={2}
              variant="primaryLink"
              text="btn.export"
              onClick={() => handleExport({ filterValues, createExcel })}
            />
          </Popover>
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns}
          rows={state}
          filters={filters}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
