import React from 'react'
import { useIntl } from 'react-intl'
import { Flex } from 'reflexbox'
import ReactTooltip from 'react-tooltip'
import { getMessage } from 'utilities/app'

export default ({ show = true, value, tooltip, tooltipProps, ...props }) => {
  if (!show || !value) return null

  const { formatMessage: message } = useIntl()
  return (
    <>
      <Flex
        alignItems="center"
        fontSize={3}
        data-tip={tooltip && getMessage(message, tooltip)}
        {...props}
      >
        {value}
      </Flex>
      {tooltip && <ReactTooltip effect="solid" {...tooltipProps} />}
    </>
  )
}
