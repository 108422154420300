import { showDate } from 'utilities/form'
import { getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'

export const columns = [
  {
    id: 'transDate',
    label: 'repair.field.transDate',
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'customerName',
    label: 'field.customer',
    render: ({ row }) => row.customerName,
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    render: ({ row }) => '',
  },
  {
    id: 'scoreOverall',
    label: 'repairFeedback.field.scoreOverall',
    align: 'right',
  },
  {
    id: 'scoreAttitude',
    label: 'repairFeedback.field.scoreAttitude',
    align: 'right',
  },
  {
    id: 'scoreTechnical',
    label: 'repairFeedback.field.scoreTechnical',
    align: 'right',
  },
]

export const handlers = ({ session, app, setState, setPagination, id }) => ({
  handleLoad: async () => {
    const resp = await getData({ app, session, id })
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ app, session, pagination = {}, id }) {
  const input = { staffId: [id] }
  const variables = { input }
  const query = `
    query($input: FeedbackQueryInput) {
      feedbackCount(input: $input)
      feedbacks(input: $input) {
        transDate
        customerId
        customerName
        scoreOverall
        scoreAttitude
        scoreTechnical
        productVariantName
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.feedbacks,
    pagination: getPagination(page, countPerPage, data.feedbackSummaryCount),
  }
}
