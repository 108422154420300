import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { Link } from 'components/core'
import { columns as exportColumns, getExportData } from 'models/deferList'

export const columns = () => [
  {
    id: 'ticketId',
    label: `field.ticketId`,
    format: ['html', 'excel'],
    renderHtml: ({ row: { ticketId } }) => (
      <Link
        variant="primaryLink"
        href={`${process.env.REACT_APP_STOCK_URL}/defer/${ticketId}/view`}
        target="_blank"
      >
        {ticketId}
      </Link>
    ),
  },
  {
    id: 'transDate',
    label: `inventory.field.transDate`,
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
    format: ['html', 'excel'],
  },
]

export const handlers = ({
  session,
  app,
  value,
  message,
  setState,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    if (!value.productVariantId) return

    const resp = await getData({ session, app, value, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ createExcel }) => {
    const title = 'inventory.title.history'
    const cols = exportColumns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const filterValues = [
      { id: 'productVariantId', value: value.productVariantId },
    ]
    let rows = await getExportData({ session, app, filterValues })
    rows = rows.filter((item) => item.balance > 0)
    createExcel({ message, title, cols, rows })
  },
})

async function getData({ session, app, value, pagination }) {
  const variables = {
    input: getDataInput({ pagination, value }),
  }
  const query = `
    query($input: TicketQueryInput) {
      inventoryDeferLinkCount(input: $input)
      inventoryDeferLinks(input: $input) {
        ticketId
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryDeferLinks,
    pagination: getPagination(page, countPerPage, data.inventoryDeferLinkCount),
  }
}

// async function getExportData({ session, app, value }) {
//   const variables = {
//     input: getDataInput({ value }),
//   }
//   const query = `
//     query($input: TicketQueryInput) {
//       inventoryDeferLinks(input: $input) {
//         ticketId
//         transDate
//         quantity
//       }
//     }
//   `
//   const [ok, data] = await request({ query, variables }, { session, app })
//   if (!ok) return {}

//   return data.inventoryDeferLinks
// }

function getDataInput({ pagination, value }) {
  const input = {
    joinItem: true,
    productVariantId: value.productVariantId,
    orderBy: [{ key: 'transDate', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
