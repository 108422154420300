import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { formatDate } from 'utilities/date'
import { getSelectOptions } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { StatusChip, Link, LinkButton } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { filterLocations } from 'utilities/permission'
import { FILTER_SET } from 'constants/actionType'
import { Flex } from 'reflexbox'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'lend.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    input: textFilter,
  },
  {
    id: 'fromLocation',
    label: 'field.dealer',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.dealers),
      }),
  },
  {
    id: 'recipientName',
    label: 'lend.field.recipientId',
    input: textFilter,
  },
  {
    id: 'recipientContact',
    label: 'lend.field.recipientContact',
    input: textFilter,
  },
  {
    id: 'sales',
    label: 'lend.field.salesId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.sales),
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'ACTIVE', label: message({ id: 'lend.status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'lend.status.INACTIVE' }) },
    {
      value: 'ACTION_PENDING',
      label: message({ id: 'lend.status.ACTION_PENDING' }),
    },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'lend.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/lend/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'sales',
    label: 'lend.field.salesId',
    format: ['excel'],
    render: ({ row }) => row.extra?.salesName.join(', '),
  },
  {
    id: 'transDate',
    label: 'lend.field.transDate',
    format: ['html', 'excel'],
    render: ({ row }) => formatDate(new Date(row.transDate)),
  },
  {
    id: 'ticketNo',
    label: 'lend.field.ticketNo',
    format: ['html', 'excel'],
  },
  {
    id: 'toLocationName',
    label: 'lend.field.dealerId',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'product.field.spu',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'lend.field.quantity',
    format: ['excel'],
  },
  {
    id: 'returnQuantity',
    label: 'lend.field.returnQuantity',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.returnQuantity || 0,
  },
  {
    id: 'sellQuantity',
    label: 'lend.field.sellQuantity',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.sellQuantity || 0,
  },
  {
    id: 'remainQuantity',
    label: 'lend.field.remainQuantity',
    format: ['excel'],
    render: ({ row }) => {
      const quantity = row.quantity
      const returnQuantity = row.itemExtra?.returnQuantity || 0
      const sellQuantity = row.itemExtra?.sellQuantity || 0
      return quantity - returnQuantity - sellQuantity
    },
  },
  {
    id: 'recipientContact',
    label: 'lend.field.recipientContact',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientContact,
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'status',
    label: 'lend.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`lend.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `lend.status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/lend/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'lend',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('lend', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setDealers,
  setSales,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'lendList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    setDealers(resp.locations)
    setSales(resp.sales)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'lend.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    locationInput: { type: ['DEALER'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
      }
      staffs {
        id
        name
      }
      lendTicketCount(input: $input)
      lendTickets(input: $input) {
        id
        toLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const { page, countPerPage } = pagination
  return {
    data: data.lendTickets,
    pagination: getPagination(page, countPerPage, data.lendTicketCount),
    locations,
    sales: data.staffs,
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      lendTickets(input: $input) {
        id
        ticketNo
        toLocationName
        productVariantName
        sku
        quantity
        transDate
        extra
        itemExtra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  // const lendItems = []
  // const returnMap = {}

  // data.lendTickets.forEach((item) => {
  //   const { id, itemExtra } = item
  //   if (itemExtra.isReturn) {
  //     returnMap[id] = item.quantity
  //   } else {
  //     lendItems.push(item)
  //   }
  // })

  // return lendItems.map((item) => {
  //   item.returnQuantity = returnMap[item.id] || 0
  //   return item
  // })

  return data.lendTickets.filter(({ itemExtra }) => !itemExtra.isReturn)
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const ticketNo = filterValues.find(({ id }) => id === 'ticketNo')
  const fromLocation = filterValues.find(({ id }) => id === 'fromLocation')
  const recipientName = filterValues.find(({ id }) => id === 'recipientName')
  const recipientContact = filterValues.find(
    ({ id }) => id === 'recipientContact',
  )
  const sales = filterValues.find(({ id }) => id === 'sales')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    id: id?.value,
    ticketNo: ticketNo?.value,
    transDate: transDate?.value,
    toLocationId: fromLocation?.value.value,
    extra: {
      recipientName: recipientName?.value,
      recipientContact: recipientContact?.value,
      salesId: sales?.value.value,
      locked: lockStatus?.value.value,
    },
    status: status?.value.value,
    productVariantName: productName?.value,
    createdBy: createdBy?.value,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
