import React from 'react'
import {
  Select,
  TextInput,
  NumberInput,
  Switch,
  TextArea,
  Address,
  DateInput,
  PhoneInput,
} from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
} from 'utilities/form'
import { Definition } from 'components/core'
import { getLocation } from 'actions/location'

export const initialState = (value = {}, data) => ({
  index: value.index,
  backupId: value.backupId || [],
  ...initializeState({
    ticketNo: value.ticketNo || '',
    isUrgent: value.isUrgent || false,
    isDirect: value.isDirect || false,
    transDate: value.transDate || '',
    arriveDate: value.arriveDate || '',
    arrivePeriod: value.arrivePeriod || '',
    billingId: value.billingId || {},
    dealerId: value.dealerId || {},
    dealerEIN: value.dealerEIN || '',
    dealerAddress: value.dealerAddress || '',
    recipientId: value.recipientId || {},
    recipientContact: value.recipientContact || '',
    recipientAddress: value.recipientAddress || {},
    recipientPhone: value.recipientPhone || '',
    recipientCellphone: value.recipientCellphone || '',
    recipientEmail: value.recipientEmail || '',
    receiptNo: value.receiptNo || '',
    shippingId: value.shippingId || {},
    warehouseGroupId: value.warehouseGroupId || {},
    warehouseId: value.warehouseId || {},
    salesId: value.salesId || {},
    memo: value.memo || '',
    productVariantId: value.productVariantId || {},
    isGift: value.isGift || false,
    quantity: value.quantity || 0,
    price: value.price || 0,
    expireAt: value.expireAt || '',
    batchNo: value.batchNo || '',
    batchQuantity: value.batchQuantity || 0,
    itemMemo: value.itemMemo || '',
  }),
})

const validation = {
  ticketNo: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    isUrgent: (
      <Definition
        direction="row"
        label="dispatch.field.isUrgent"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isUrgent}
          onClick={() => {
            setState({ ...state, isUrgent: !state.isUrgent })
          }}
        />
      </Definition>
    ),
    isDirect: (
      <Definition
        direction="row"
        label="dispatch.field.isDirect"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isDirect}
          onClick={() => {
            setState({ ...state, isDirect: !state.isDirect })
          }}
        />
      </Definition>
    ),
    transDate: (
      <DateInput
        id="transDate"
        label="dispatch.field.transDate"
        placeholder="dispatch.field.transDate"
        value={state.transDate}
        role="lockDispatchTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    arriveDate: (
      <DateInput
        id="arriveDate"
        label="dispatch.field.arriveDate"
        value={state.arriveDate}
        role="lockDispatchTicket"
        onChange={onTextChange('arriveDate')}
        errMsg={state.__error__.arriveDate}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="dispatch.field.ticketNo"
        placeholder="dispatch.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
        errMsg={state.__error__.ticketNo}
      />
    ),
    arrivePeriod: (
      <Select
        label="dispatch.field.arrivePeriod"
        isSearchable={false}
        isClearable={false}
        options={[
          { value: 'AM', label: message({ id: `dispatch.arrivePeriod.AM` }) },
          { value: 'PM', label: message({ id: `dispatch.arrivePeriod.PM` }) },
        ]}
        value={state.arrivePeriod}
        onChange={onSelectChange('arrivePeriod')}
      />
    ),
    billingId: (
      <Select
        label="dispatch.field.billingId"
        isClearable={false}
        options={data.billings}
        value={state.billingId}
        onChange={onSelectChange('billingId')}
        errMsg={state.__error__.billingId}
      />
    ),
    dealerId: (
      <Select
        label="dispatch.field.dealerId"
        isClearable={false}
        options={data.dealers}
        value={state.dealerId}
        onChange={onSelectChange('dealerId', ({ value }) =>
          onDealerChange(data.dealerLocations, value),
        )}
        errMsg={state.__error__.dealerId}
      />
    ),
    dealerEIN: (
      <TextInput
        id="dealerEIN"
        label="dispatch.field.dealerEIN"
        value={state.dealerEIN}
        onChange={onTextChange('dealerEIN')}
        errMsg={state.__error__.dealerEIN}
      />
    ),
    dealerAddress: (
      <TextInput
        id="dealerAddress"
        label="dispatch.field.dealerAddress"
        value={state.dealerAddress}
        onChange={onTextChange('dealerAddress')}
        errMsg={state.__error__.dealerAddress}
      />
    ),
    recipientId: (
      <Select
        isClearable={false}
        label="dispatch.field.recipientId"
        options={data.recipients}
        value={state.recipientId}
        onChange={onSelectChange('recipientId', ({ value }) =>
          onRecipientChange(data.recipientLocations, value),
        )}
        errMsg={state.__error__.recipientId}
      />
    ),
    recipientContact: (
      <TextInput
        id="recipientContact"
        label="dispatch.field.recipientContact"
        value={state.recipientContact}
        onChange={onTextChange('recipientContact')}
        errMsg={state.__error__.recipientContact}
      />
    ),
    recipientAddress: (
      <Address
        id="recipientAddress"
        label="dispatch.field.recipientAddress"
        placeholder="dispatch.field.recipientAddress"
        value={state.recipientAddress}
        onChange={onTextChange('recipientAddress')}
        errMsg={state.__error__.recipientAddress}
      />
    ),
    recipientPhone: (
      <PhoneInput
        id="recipientPhone"
        label="dispatch.field.recipientPhone"
        value={state.recipientPhone}
        onChange={onTextChange('recipientPhone')}
        errMsg={state.__error__.recipientPhone}
      />
    ),
    recipientCellphone: (
      <PhoneInput
        id="recipientCellphone"
        label="dispatch.field.recipientCellphone"
        type="cellphone"
        value={state.recipientCellphone}
        onChange={onTextChange('recipientCellphone')}
        errMsg={state.__error__.recipientCellphone}
      />
    ),
    recipientEmail: (
      <TextInput
        id="recipientEmail"
        label="dispatch.field.recipientEmail"
        value={state.recipientEmail}
        onChange={onTextChange('recipientEmail')}
        errMsg={state.__error__.recipientEmail}
      />
    ),
    receiptNo: (
      <TextInput
        id="receiptNo"
        label="dispatch.field.receiptNo"
        value={state.receiptNo}
        onChange={onTextChange('receiptNo')}
        errMsg={state.__error__.receiptNo}
      />
    ),
    shippingId: (
      <Select
        label="dispatch.field.shippingId"
        isClearable={false}
        options={data.shippings}
        value={state.shippingId}
        onChange={onSelectChange('shippingId')}
        errMsg={state.__error__.shippingId}
      />
    ),
    warehouseGroupId: (
      <Select
        label="dispatch.field.warehouseGroupId"
        isClearable={false}
        options={data.warehouseGroups}
        value={state.warehouseGroupId}
        onChange={onSelectChange('warehouseGroupId')}
        errMsg={state.__error__.warehouseGroupId}
      />
    ),
    warehouseId: (
      <Select
        label="dispatch.field.warehouseId"
        isClearable={false}
        options={getWarehouses(data.allWarehouses, state.warehouseGroupId)}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    salesId: (
      <Select
        label="dispatch.field.salesId"
        isClearable={false}
        isMulti
        options={data.sales}
        value={state.salesId}
        onChange={onSelectChange('salesId')}
        errMsg={state.__error__.salesId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="dispatch.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    productVariantId: (
      <Select
        id="productVariantId"
        label="field.productName"
        placeholder="field.productName"
        isClearable={false}
        options={data.products}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    isGift: (
      <Definition
        direction="row"
        label="dispatch.field.isGift"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isGift}
          onClick={() => {
            setState({ ...state, isGift: !state.isGift })
          }}
        />
      </Definition>
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        label="dispatch.field.quantity"
        placeholder="dispatch.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="dispatch.field.price"
        placeholder="dispatch.field.price"
        autoComplete="off"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    expireAt: (
      <DateInput
        id="expireAt"
        label="dispatch.field.expireAt"
        placeholder="dispatch.field.expireAt"
        value={state.expireAt}
        onChange={onTextChange('expireAt')}
        errMsg={state.__error__.expireAt}
      />
    ),
    batchNo: (
      <TextInput
        id="batchNo"
        label="dispatch.field.batchNo"
        placeholder="dispatch.field.batchNo"
        value={state.batchNo}
        onChange={onTextChange('batchNo')}
        errMsg={state.__error__.batchNo}
      />
    ),
    batchQuantity: (
      <NumberInput
        id="batchQuantity"
        label="dispatch.field.batchQuantity"
        placeholder="dispatch.field.batchQuantity"
        value={state.batchQuantity}
        onChange={onTextChange('batchQuantity')}
        errMsg={state.__error__.batchQuantity}
      />
    ),
    itemMemo: (
      <TextArea
        id="itemMemo"
        label="dispatch.field.itemMemo"
        value={state.itemMemo}
        onChange={onTextChange('itemMemo')}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return
    action.editTicketItem(state)
    action.handleClose()
  },
})

function onDealerChange(dealers, locationId) {
  const location = getLocation(dealers, locationId)
  const extra = location.extra || {}
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))

  return { backupId, recipientId: {} }
}

function onRecipientChange(recipients, locationId) {
  const location = getLocation(recipients, locationId)
  if (!location) return {}

  const extra = location.extra || {}
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))

  return { backupId }
}

function getWarehouses(allWarehouses, warehouseGroupId) {
  if (!allWarehouses) return []
  return allWarehouses.filter(
    (item) => item.parentId === warehouseGroupId?.value,
  )
}
