import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { AppContext, SessionContext } from 'contexts'
import { initialState, fields, handlers } from 'models/login'
import { Flex, Box } from 'reflexbox'
import { Overlay, Button, Text, Section, Center } from 'components/core'
import bgImg from 'assets/img/background.jpg'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const field = fields({ app, session, state, setState })
  const handler = handlers({ state, setState, session, app, history })

  useEffect(() => {
    document.title = formatMessage({ id: 'login.title' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderFooter = () => (
    <Flex
      justifyContent="center"
      width={1}
      sx={{ position: 'fixed', bottom: 0, left: 0, zIndex: 100 }}
    >
      <Text
        py={4}
        color="light.3"
        href="https://shop.mr-14.com"
        target="_blank"
        sx={{ textDecoration: 'none' }}
      >
        &copy; 2020 藍侃科技有限公司. All rights reserved.
      </Text>
    </Flex>
  )

  return (
    <Box height="100vh">
      <Overlay bgImage={bgImg}>
        <Center height="100%">
          <Section
            headerProps={{ display: 'flex', justifyContent: 'center', py: 3 }}
            title="app.stock"
            contentProps={{ mt: 3 }}
            width="300px"
          >
            <Box as="form" onSubmit={useCallbackOnce(handler.handleSubmit)}>
              {field.username}
              {field.password}
              <Center mt={4}>
                <Button
                  px={4}
                  type="submit"
                  variant="secondary"
                  text="login.btn.login"
                />
              </Center>
            </Box>
          </Section>
        </Center>
      </Overlay>
      {renderFooter()}
    </Box>
  )
}
