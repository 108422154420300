import React from 'react'
import { Button, Definition, Table } from 'components/core'
import {
  Select,
  TextInput,
  NumberInput,
  TextArea,
  Switch,
} from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOptions,
  getSelectOption,
} from 'utilities/form'
import { getProductOption } from 'actions/product'
import { getLocation } from 'actions/location'
import { fetchBalance } from 'actions/inventory'
import { MdDelete } from 'react-icons/md'
import { getAvailableBalance } from 'actions/ticket'
import MaskInput from 'components/form/MaskInput'

export const initialState = (value = {}, data = {}) => {
  const warehouses = data.warehouses?.filter(
    (item) => item.parentId === data.warehouseGroupId,
  )
  return {
    index: value.index,
    id: value.id || '',
    parentId: value.parentId,
    parentType: value.parentType,
    parentItemId: value.extra?.parentItemId,
    productVariantName: value.productVariantName || '',
    balance: value.balance || 0,
    warehouseBalance: value.warehouseBalance || 0,
    deptBalance: value.deptBalance || 0,
    products: data.products,
    batchItems: value.extra?.batchItems || [],
    warehouses,
    ...initializeState({
      productVariantId: getProductOption(data.products, value.productVariantId),
      warehouseId: getSelectOption(warehouses, value.srcLocationId),
      isDemo: value.extra?.isDemo || false,
      isGift: value.isGift || false,
      quantity: value.quantity || 0,
      price: value.price || 0,
      serial: value.extra?.serial || '',
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
  warehouseId: [{ type: 'required', message: 'error.required' }],
}

const columns = [
  {
    id: 'batchNo',
    label: 'dispatch.field.batchNo',
  },
  {
    id: 'expireAt',
    label: 'dispatch.field.expireAt',
  },
  {
    id: 'quantity',
    label: 'dispatch.field.quantity',
    align: 'right',
  },
]

export const labels = ({ state }) => {
  const batchItems = state.batchItems
  return {
    serial: <Definition label="dispatch.field.serial" value={state.serial} />,
    memo: <Definition label="field.memo" value={state.memo} />,
    batchItems: <Table columns={columns} rows={batchItems} />,
  }
}

export const fields = ({ app, session, state, setState, data }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)
  const onBatchItemChange = (index, key) => (value) => {
    const batchItems = [...state.batchItems]
    const batchItem = batchItems[index]
    batchItem[key] = value
    batchItems.splice(index, 1, batchItem)
    setState({ ...state, batchItems })
  }

  return {
    productVariantId:
      state.id || state.parentId ? (
        <Definition
          direction="row"
          label="product.field.spu"
          value={state.productVariantName}
        />
      ) : (
        <Select
          id="productVariantId"
          label="product.field.spu"
          placeholder="product.field.spu"
          isClearable={false}
          options={getSelectOptions(data.products)}
          value={state.productVariantId}
          onChange={onSelectChange('productVariantId', ({ value }) => {
            return getBalance(
              app,
              session,
              data,
              value,
              state.warehouseId?.value,
              state.index,
            )
          })}
          errMsg={state.__error__.productVariantId}
        />
      ),
    warehouseId: (
      <Select
        id="warehouseId"
        label="dispatch.field.warehouseId"
        placeholder="dispatch.field.warehouseId"
        isClearable={false}
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId', ({ value }) => {
          return getBalance(
            app,
            session,
            data,
            state.productVariantId?.value,
            value,
            state.index,
          )
        })}
        errMsg={state.__error__.warehouseId}
      />
    ),
    isDemo: (
      <Definition
        direction="row"
        label="dispatch.field.isDemo"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isDemo}
          onClick={() => {
            setState({ ...state, isDemo: !state.isDemo })
          }}
        />
      </Definition>
    ),
    isGift: (
      <Definition
        direction="row"
        label="dispatch.field.isGift"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isGift}
          onClick={() => {
            setState({ ...state, isGift: !state.isGift })
          }}
        />
      </Definition>
    ),
    balance: (
      <Definition
        show={
          (data.profile !== 'view' && data.isDirect) ||
          data.parentType === 'LEND'
        }
        direction="row"
        label="dispatch.field.balance"
        value={state.balance}
      />
    ),
    warehouseBalance: (
      <Definition
        show={data.profile !== 'view' && data.parentType !== 'LEND'}
        direction="row"
        label="dispatch.field.warehouseBalance"
        value={state.warehouseBalance}
      />
    ),
    deptBalance: (
      <Definition
        show={
          data.profile !== 'view' &&
          !data.isDirect &&
          data.parentType !== 'LEND'
        }
        direction="row"
        label="inventory.field.deptBalance"
        value={state.deptBalance}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        max={getMaxQuantity(state, data)}
        label="dispatch.field.quantity"
        placeholder="dispatch.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="dispatch.field.price"
        placeholder="dispatch.field.price"
        autoComplete="off"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    serial: (
      <TextInput
        id="serial"
        label="dispatch.field.serial"
        value={state.serial}
        onChange={onTextChange('serial')}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    batchItems: (
      <Table
        columns={[
          {
            id: 'batchNo',
            label: 'dispatch.field.batchNo',
            render: ({ row, index }) => (
              <TextInput
                fieldProps={{ m: 0 }}
                value={row.batchNo}
                onChange={onBatchItemChange(index, 'batchNo')}
              />
            ),
          },
          {
            id: 'expireAt',
            label: 'dispatch.field.expireAt',
            render: ({ row, index }) => (
              // <DateInput
              //   fieldProps={{ m: 0 }}
              //   value={row.expireAt}
              //   min="1970-01-01"
              //   onChange={onBatchItemChange(index, 'expireAt')}
              // />
              <MaskInput
                fieldProps={{ m: 0 }}
                mask="9999/99/99"
                value={row.expireAt}
                onChange={onBatchItemChange(index, 'expireAt')}
              />
            ),
          },
          {
            id: 'quantity',
            label: 'dispatch.field.quantity',
            render: ({ row, index }) => (
              <NumberInput
                fieldProps={{ m: 0 }}
                min="1"
                value={row.quantity}
                onChange={onBatchItemChange(index, 'quantity')}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const batchItems = [...state.batchItems]
                  batchItems.splice(index, 1)
                  setState({ ...state, batchItems })
                }}
              />
            ),
          },
        ]}
        rows={state.batchItems}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleLoad: async (value) => {
    const { balance, warehouseBalance, deptBalance } = await getBalance(
      app,
      session,
      data,
      value.productVariantId,
      value.srcLocationId,
      value.index,
    )
    value.balance = balance
    value.warehouseBalance = warehouseBalance
    value.deptBalance = deptBalance
    setState(initialState(value, data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { productVariantId, warehouseId, quantity, price } = state
    action.addItem({
      index: state.index,
      id: state.id,
      productVariantId: productVariantId.value,
      productVariantName: productVariantId.label,
      srcLocationId: warehouseId.value,
      srcLocationName: warehouseId.label,
      balance: state.balance,
      deptBalance: state.deptBalance,
      warehouseBalance: state.warehouseBalance,
      quantity,
      price: parseFloat(price || 0),
      isGift: state.isGift,
      extra: {
        parentItemId: state.parentItemId,
        batchItems: state.batchItems,
        memo: state.memo,
        isDemo: state.isDemo,
        serial: state.serial,
      },
    })
    action.handleClose()
  },
  addBatch: () => {
    const batchItems = [...state.batchItems]
    batchItems.push({ batchNo: '', expireAt: '', quantity: 1 })
    setState({ ...state, batchItems })
  },
})

function getMaxQuantity(state, data) {
  const productVariantId = state.productVariantId?.value
  if (!productVariantId) return 0

  if (data.parentType === 'LEND') {
    // return getAvailableBalance({
    //   ticketItems: data.ticketItems,
    //   oldTicketItems: data.oldTicketItems,
    //   productVariantId,
    //   balance: state.balance,
    //   index: state.index,
    //   locationKey: 'srcLocationId',
    //   locationId: state.warehouseId.value,
    // })
    return state.balance
  }

  // const availableWarehouseBalance = getAvailableBalance({
  //   ticketItems: data.ticketItems,
  //   oldTicketItems: data.oldTicketItems,
  //   productVariantId,
  //   balance: state.warehouseBalance,
  //   index: state.index,
  //   locationKey: 'srcLocationId',
  //   locationId: state.warehouseId.value,
  // })

  if (data.isDirect) {
    // const availableBalance = getAvailableBalance({
    //   ticketItems: data.ticketItems,
    //   oldTicketItems: data.oldTicketItems,
    //   productVariantId,
    //   balance: state.balance,
    //   index: state.index,
    //   locationKey: 'srcLocationId',
    //   locationId: state.warehouseId.value,
    // })
    // return Math.min(availableWarehouseBalance, availableBalance)
    return Math.min(state.warehouseBalance, state.balance)
  }

  // const availableDeptBalance = getAvailableBalance({
  //   ticketItems: data.ticketItems,
  //   oldTicketItems: data.oldTicketItems,
  //   productVariantId,
  //   balance: state.deptBalance,
  //   index: state.index,
  //   locationKey: 'srcLocationId',
  //   locationId: state.warehouseId.value,
  // })

  return Math.min(state.warehouseBalance, state.deptBalance)
}

async function getBalance(
  app,
  session,
  data,
  productVariantId,
  warehouseId,
  index,
) {
  const { isDirect, dealers, dealerId } = data
  let availableBalance = 0
  let availableDeptBalance = 0

  const warehouseBalance = await fetchBalance({
    app,
    session,
    locationType: 'WAREHOUSE',
    locationId: warehouseId,
    productVariantId,
    oldTicketItems: data.oldTicketItems,
  })
  const availableWarehouseBalance = getAvailableBalance({
    ticketItems: data.ticketItems,
    oldTicketItems: data.oldTicketItems,
    productVariantId,
    balance: warehouseBalance,
    index,
    locationKey: 'srcLocationId',
    locationId: warehouseId,
  })

  if (isDirect) {
    const balance = await fetchBalance({
      app,
      session,
      locationType: 'ALL',
      productVariantId,
      oldTicketItems: data.oldTicketItems,
    })

    availableBalance = getAvailableBalance({
      ticketItems: data.ticketItems,
      oldTicketItems: data.oldTicketItems,
      productVariantId,
      balance,
      index,
      locationKey: 'srcLocationId',
      locationId: warehouseId,
    })
  } else {
    const dealer = isDirect ? null : getLocation(dealers, dealerId)
    const deptBalance = await fetchBalance({
      app,
      session,
      locationType: 'DEALER_ORG',
      locationId: dealer.parentId,
      productVariantId,
      oldTicketItems: data.oldTicketItems,
    })

    availableDeptBalance = getAvailableBalance({
      ticketItems: data.ticketItems,
      oldTicketItems: data.oldTicketItems,
      productVariantId,
      balance: deptBalance,
      index,
      locationKey: 'srcLocationId',
      locationId: warehouseId,
    })
  }

  return {
    balance: availableBalance < 0 ? 0 : availableBalance,
    warehouseBalance:
      availableWarehouseBalance < 0 ? 0 : availableWarehouseBalance,
    deptBalance: availableDeptBalance < 0 ? 0 : availableDeptBalance,
  }
}
