import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import {
  initialState,
  handlers,
  fields,
  filters,
  columns,
} from 'models/carrefourPage'
import { Box } from 'reflexbox'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  Toolbar,
  Table,
} from 'components/core'
import Page from 'components/Page'
import { MdAdd, MdArrowBack } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import CarrefourStorageModal from './CarrefourStorageModal'
import { initialPage } from 'utilities/pagination'
import CarrefourSyncModal from './CarrefourSyncModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState(initialState())
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [syncOpen, setSyncOpen] = useState(false)

  const form = fields({ app, session, state, setState })
  const handler = handlers({
    app,
    session,
    state,
    data,
    setData,
    setState,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handler.handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(data).length > 0) handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <Page
        navId="pluginList"
        title="carrefour.title.edit"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/plugin"
            />
            <Button
              ml={3}
              variant="primary"
              text="btn.save"
              onClick={useCallbackOnce(handler.handleSubmit)}
            />
          </Center>
        }
        showFooterAction
      >
        <Grid cols={['1fr', '1fr 2fr']} gap={3}>
          <Box>
            <Section title="plugin.section.basic" mb={[3, 0]}>
              {form.username}
              {form.password}
              {form.dealerId}
              {form.warehouseId}
              {form.refundWarehouseId}
              {form.shippingId}
              {form.syncTime1}
              {form.syncTime2}
            </Section>
            <Section mt={4} title="carrefour.section.autoSync">
              {form.isActive}
            </Section>
            <Section mt={4} title="carrefour.section.manualSync">
              <Box>
                <Button
                  variant="default"
                  text="carrefour.btn.syncDispatch"
                  onClick={() => setSyncOpen(true)}
                />
              </Box>
            </Section>
          </Box>
          <Box>
            <Section title="carrefour.section.storage">
              <Toolbar>
                <Button
                  disabled={!state.dealerId?.value}
                  variant="default"
                  icon={<MdAdd />}
                  text="btn.add"
                  onClick={() => setOpen(true)}
                />
              </Toolbar>
              <Table
                mt={3}
                columns={columns({ state, setState })}
                rows={state.locationCodes}
                filters={filters}
                filterValues={filterValues}
                pagination={pagination}
                onChange={handler.handleLoad}
              />
            </Section>
          </Box>
        </Grid>
      </Page>
      <CarrefourStorageModal
        data={{ storages: data.storages }}
        open={open}
        action={{
          handleClose: () => setOpen(false),
          addStorage: handler.addStorage,
        }}
      />
      <CarrefourSyncModal
        value={state}
        open={syncOpen}
        onClose={() => setSyncOpen(false)}
      />
    </>
  )
}
