import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { StatusChip, Link, LinkButton } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { filterLocations } from 'utilities/permission'
import { FILTER_SET } from 'constants/actionType'
import { getReverseTypes } from './reversePage'
import { Flex } from 'reflexbox'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'fromLocation',
    label: 'reverse.field.fromLocationId',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLocationOptions(data.locations, props.message),
      }),
  },
  {
    id: 'type',
    label: 'reverse.field.type',
    input: (props) =>
      selectFilter({
        ...props,
        options: getReverseTypes(props.message),
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
  ]
}

function getLocationOptions(locations, message) {
  if (!locations) return []
  const depts = []
  const orgs = []
  locations.forEach(({ type, id, name }) => {
    if (type === 'DEPT') depts.push({ label: name, value: id })
    if (type === 'DEALER_ORG') orgs.push({ label: name, value: id })
  })
  return [
    { label: message({ id: 'location.type.DEPT' }), options: depts },
    { label: message({ id: 'location.type.DEALER_ORG' }), options: orgs },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'reverse.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/reverse/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'transDate',
    label: 'reverse.field.transDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'reverseType',
    label: 'reverse.field.type',
    format: ['html', 'excel'],
    render: ({ row }) => message({ id: `reverse.type.${row.extra?.type}` }),
  },
  {
    id: 'fromLocationName',
    label: 'reverse.field.fromLocationId',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'purchase.field.productVariantId',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'purchase.field.sku',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'purchase.field.quantity',
    format: ['excel'],
  },
  {
    id: 'status',
    label: 'reverse.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra?.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/reverse/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'reverse',
          session,
          locked: row.extra?.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('reverse', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  message,
  setState,
  setFilterValues,
  setPagination,
  setLocations,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'reverseList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    setLocations(resp.locations)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'reverse.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    locationInput: { type: ['DEPT', 'DEALER_ORG'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      reverseTicketCount(input: $input)
      reverseTickets(input: $input) {
        id
        fromLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const { page, countPerPage } = pagination
  return {
    data: data.reverseTickets,
    pagination: getPagination(page, countPerPage, data.reverseTicketCount),
    locations,
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      reverseTickets(input: $input) {
        id
        ticketNo
        fromLocationName
        productVariantName
        sku
        quantity
        transDate
        status
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.reverseTickets
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const fromLocation = filterValues.find(({ id }) => id === 'fromLocation')
  const type = filterValues.find(({ id }) => id === 'type')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    id: id ? id.value : null,
    transDate: transDate ? transDate.value : null,
    fromLocationId: fromLocation?.value.value,
    status: status?.value.value,
    productVariantName: productName ? productName.value : null,
    extra: {
      locked: lockStatus?.value.value,
    },
    createdBy: createdBy?.value,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (type?.value.value) {
    input.extra = { type: type?.value.value }
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
