import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  getSelectOptions,
} from 'utilities/form'
import { Select } from 'components/form'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = () =>
  initializeState({
    locationId: {},
  })

const validation = {
  locationId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    locationId: (
      <Select
        isClearable={false}
        label="field.storage"
        placeholder="field.storage"
        options={getSelectOptions(data.storages)}
        value={state.locationId}
        onChange={onSelectChange('locationId')}
        errMsg={state.__error__.locationId}
      />
    ),
  }
}

export const handlers = ({ session, state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const locationId = state.locationId.value
    const location = data.storages.find((item) => item.id === locationId)
    const { code } = location.extra
    if (!code) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.pxmart.codeMissing' },
      })
      return
    }

    if (data.refundStorages.some(({ extra }) => extra.code === code)) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.pxmart.codeExist' },
      })
      return
    }

    action.addStorage(location)
    action.handleClose()
    setState(initialState())
  },
})
