import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { getSelectOptions, showAddress, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { StatusChip, Link, LinkButton } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton, renderLockLink } from 'utilities/app'
import { handleDelete, handleLock } from 'actions/ticket'
import { filterLocations } from 'utilities/permission'
import { FILTER_SET } from 'constants/actionType'
import { Flex } from 'reflexbox'
import { today } from 'utilities/date'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'dispatch.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'arriveDate',
    label: 'dispatch.field.arriveDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    input: textFilter,
  },
  {
    id: 'parentId',
    label: 'field.parentId',
    input: textFilter,
  },
  {
    id: 'org',
    label: 'field.org',
    input: (props) => selectFilter({ ...props, options: data.orgs }),
  },
  {
    id: 'toLocation',
    label: 'dispatch.field.dealerId',
    input: (props) =>
      selectFilter({ ...props, isSearchable: true, options: data.dealers }),
  },
  {
    id: 'warehouseGroup',
    label: 'dispatch.field.warehouseGroupId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.warehouseGroups,
      }),
  },
  {
    id: 'srcLocation',
    label: 'field.warehouse',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.warehouses,
      }),
  },
  {
    id: 'shippingId',
    label: 'dispatch.field.shippingId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.shippings,
      }),
  },
  {
    id: 'trackingNo',
    label: 'dispatch.field.trackingNo',
    input: textFilter,
  },
  {
    id: 'recipientName',
    label: 'dispatch.field.recipientId',
    input: textFilter,
  },
  {
    id: 'recipientContact',
    label: 'dispatch.field.recipientContact',
    input: textFilter,
  },
  {
    id: 'sales',
    label: 'dispatch.field.salesId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.sales),
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'outboxStatus',
    label: 'field.outboxStatus',
    input: (props) =>
      selectFilter({
        ...props,
        options: getOutboxStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'PENDING', label: message({ id: 'status.PENDING' }) },
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
  ]
}

function getOutboxStatusOptions(message) {
  return [
    { value: 'PENDING', label: message({ id: 'outbox.status.PENDING' }) },
    { value: 'FAILED', label: message({ id: 'outbox.status.FAILED' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'isUrgent',
    label: 'dispatch.field.isUrgent',
    format: ['excel'],
    render: ({ row }) =>
      message({ id: `type.${row.extra?.isUrgent || false}` }),
  },
  {
    id: 'isSell',
    label: 'dispatch.field.isSell',
    format: ['excel'],
    render: ({ row }) => message({ id: `type.${row.extra?.isSell || false}` }),
  },
  {
    id: 'id',
    label: 'field.ticketId',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/dispatch/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'parentId',
    label: 'field.parentId',
    noWrap: true,
    format: ['excel'],
  },
  {
    id: 'transDate',
    label: 'dispatch.field.transDate',
    noWrap: true,
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'arriveDate',
    label: 'dispatch.field.arriveDate',
    noWrap: true,
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.extra?.arriveDate),
  },
  {
    id: 'arrivePeriod',
    label: 'dispatch.field.arrivePeriod',
    format: ['excel'],
    render: ({ row }) => {
      const arrivePeriod = row.extra?.arrivePeriod
      switch (arrivePeriod) {
        case 'ALL':
        case 'AM':
        case 'PM':
          return message({
            id: `dispatch.arrivePeriod.${arrivePeriod}`,
          })
        default:
          return arrivePeriod
      }
    },
  },
  {
    id: 'sales',
    label: 'dispatch.field.salesId',
    format: ['excel'],
    render: ({ row }) => row.extra?.salesName?.join(','),
  },
  {
    id: 'backup',
    label: 'dispatch.field.backupId',
    format: ['excel'],
    render: ({ row }) => row.extra?.backupName?.join(','),
  },
  {
    id: 'tech',
    label: 'dispatch.field.techId',
    format: ['excel'],
    render: ({ row }) => row.extra?.techName,
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    format: ['html', 'excel'],
  },
  {
    id: 'toLocationName',
    label: 'field.dealer',
    format: ['html', 'excel'],
  },
  {
    id: 'dealerCode',
    label: 'dispatch.field.dealerCode',
    format: ['html', 'excel'],
    render: ({ row }) => row.extra?.dealerCode,
  },
  {
    id: 'barcode',
    label: 'product.field.barcode',
    format: ['excel'],
  },
  {
    id: 'warehouseGroupId',
    label: 'dispatch.field.warehouseGroupId',
    format: ['html', 'excel'],
    render: ({ row }) => row.extra?.warehouseGroupName,
  },
  {
    id: 'recipientName',
    label: 'field.storage',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientName,
  },
  {
    id: 'recipientContact',
    label: 'dispatch.field.recipientContact',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientContact,
  },
  {
    id: 'recipientAddress',
    label: 'dispatch.field.recipientAddress',
    format: ['excel'],
    render: ({ row }) => showAddress(row.extra?.recipientAddress, message),
  },
  {
    id: 'recipientPhone',
    label: 'dispatch.field.recipientPhone',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientPhone,
  },
  {
    id: 'recipientCellphone',
    label: 'dispatch.field.recipientCellphone',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientCellphone,
  },
  {
    id: 'receiptNo',
    label: 'dispatch.field.receiptNo',
    format: ['excel'],
    render: ({ row }) => row.extra?.receiptNo,
  },
  {
    id: 'trackingNo',
    label: 'dispatch.field.trackingNo',
    format: ['excel'],
    render: ({ row }) => row.extra?.trackingNo,
  },
  {
    id: 'memo',
    label: 'dispatch.field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'itemSrcLocationName',
    label: 'field.warehouse',
    format: ['excel'],
  },
  {
    id: 'warehouseMemo',
    label: 'dispatch.field.warehouseMemo',
    format: ['excel'],
    render: ({ row }) => row.extra?.warehouseMemo,
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['excel'],
  },
  {
    id: 'dealerProductCode',
    label: 'dispatch.field.dealerProductCode',
    format: ['excel'],
  },
  {
    id: 'productCode',
    label: 'dispatch.field.productCode',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    format: ['excel'],
  },
  {
    id: 'price',
    label: 'field.price',
    format: ['excel'],
  },
  {
    id: 'isGift',
    label: 'dispatch.field.isGift',
    format: ['excel'],
    render: ({ row }) => (row.isGift ? 'Y' : 'N'),
  },
  {
    id: 'itemMemo',
    label: 'dispatch.field.itemMemo',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.memo,
  },
  {
    id: 'batchNo',
    label: 'dispatch.field.batchNo',
    format: ['excel'],
    render: ({ row }) => {
      const batchItems = row.itemExtra?.batchItems || []
      return batchItems.map((i) => i.batchNo).join(', ')
    },
  },
  {
    id: 'expireAt',
    label: 'dispatch.field.expireAt',
    format: ['excel'],
    render: ({ row }) => {
      const batchItems = row.itemExtra?.batchItems || []
      return batchItems.map((i) => i.expireAt).join(', ')
    },
  },
  {
    id: 'billing',
    label: 'dispatch.field.billingId',
    format: ['excel'],
    render: ({ row }) => row.extra?.billingName,
  },
  {
    id: 'shipping',
    label: 'dispatch.field.shippingId',
    format: ['excel'],
    render: ({ row }) => row.extra?.shippingName,
  },
  {
    id: 'locked',
    label: 'field.locked',
    format: ['excel'],
    render: ({ row }) => (row.extra?.locked ? 'Y' : 'N'),
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <>
        <StatusChip
          label={`status.${row.status}`}
          color={getStatusColor(row.status)}
        />
        <StatusChip
          show={!!row.outboxStatus}
          label={`outbox.status.${row.outboxStatus}`}
          color={getStatusColor(row.outboxStatus)}
        />
      </>
    ),
    render: ({ row }) => {
      let status = message({ id: `status.${row.status}` })
      if (row.outboxStatus) {
        status += `/${message({ id: `outbox.status.${row.outboxStatus}` })}`
      }
      return status
    },
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        {renderLockLink({
          readonly: true,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: handleLock({
            module: 'dispatch',
            session,
            app,
            id: row.id,
            handleLoad: action.handleLoad,
          }),
        })}
        <LinkButton
          mx={2}
          disabled={row.extra.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/dispatch/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'dispatch',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('dispatch', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const txColumns = ({ message }) => [
  {
    id: 'PS_ID',
    label: 'dispatch.tx.PS_ID',
    render: () => 'SA',
  },
  {
    id: 'PS_NO',
    label: 'dispatch.tx.PS_NO',
    render: ({ row }) => row.txId,
  },
  {
    id: 'PS_DD',
    label: 'dispatch.tx.PS_DD',
    // render: ({ row }) => showDate(row.transDate),
    render: ({ row }) => today(),
  },
  {
    id: 'CUS_NOv',
    label: 'dispatch.tx.CUS_NOv',
    render: ({ row }) => (row.extra?.dealerEIN ? '' : 'CI22035'),
  },
  {
    id: 'REM_H',
    label: 'dispatch.tx.REM_H',
    render: ({ row }) => `${row.toLocationName}${row.id}`,
  },
  {
    id: 'REM',
    label: 'dispatch.tx.REM',
    render: ({ row }) => `${row.itemIsGift ? '贈品' : ''}`,
  },
  {
    id: 'ITM',
    label: 'dispatch.tx.ITM',
    render: ({ row }) => row.seq,
  },
  {
    id: 'DEP',
    label: 'dispatch.tx.DEP',
    render: () => '00000000',
  },
  {
    id: 'TAX_ID',
    label: 'dispatch.tx.TAX_ID',
    render: ({ row }) => (row.extra?.dealerEIN ? 3 : 2),
  },
  {
    id: 'ZHANG_ID',
    label: 'dispatch.tx.ZHANG_ID',
    render: () => 1,
  },
  {
    id: 'SAL_NO',
    label: 'dispatch.tx.SAL_NO',
    render: () => 402,
  },
  {
    id: 'VOH_ID',
    label: 'dispatch.tx.VOH_ID',
    render: () => '',
  },
  {
    id: 'WH',
    label: 'dispatch.tx.WH',
    render: () => '0000',
  },
  {
    id: 'PRD_NO',
    label: 'dispatch.tx.PRD_NO',
    render: ({ row }) => row.sku,
  },
  {
    id: 'UNIT',
    label: 'dispatch.tx.UNIT',
    render: () => 1,
  },
  {
    id: 'QTY',
    label: 'dispatch.tx.QTY',
    render: ({ row }) => row.quantity,
  },
  {
    id: 'UP',
    label: 'dispatch.tx.UP',
    render: ({ row }) =>
      row.extra?.dealerEIN
        ? Math.round((row.price / 1.05) * 100) / 100
        : row.price,
  },
  {
    id: 'AMT',
    label: 'dispatch.tx.AMT',
    render: ({ row }) => row.txAmt,
  },
  {
    id: 'AMTN_NET',
    label: 'dispatch.tx.AMTN_NET',
    render: ({ row }) => row.txAmtNet,
  },
  {
    id: 'TAX',
    label: 'dispatch.tx.TAX',
    render: ({ row }) => Math.round(((row.quantity * row.price) / 1.05) * 0.05),
  },
  {
    id: 'CARRIER_KND',
    label: 'dispatch.tx.CARRIER_KND',
    render: ({ row }) => getTxHandleType(row.extra?.handleType),
  },
  {
    id: 'CARRIERID1',
    label: 'dispatch.tx.CARRIERID1',
    render: ({ row }) => {
      const handleType = getTxHandleType(row.extra?.handleType)
      if (handleType === 1) return row.extra?.handleNo
      if (handleType === 2) return row.extra?.recipientEmail
      return ''
    },
  },
]

export const handlers = ({
  session,
  app,
  message,
  setState,
  setFilterValues,
  setPagination,
  setOrgs,
  setDealers,
  setWarehouses,
  setWarehouseGroups,
  setShippings,
  setSales,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setDealers(resp.dealers)
    setWarehouseGroups(resp.warehouseGroups)
    setWarehouses(resp.warehouses)
    setShippings(resp.shippings)
    setSales(resp.sales)
    setOrgs(resp.orgs)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'dispatchList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'dispatch.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
  handleTxExport: async ({ filterValues, createExcel }) => {
    const title = 'dispatch.title.txList'
    const cols = txColumns({ message })
    const rows = await getExportData({
      session,
      app,
      filterValues,
      sort: 'ASC',
    })
    const idSet = new Set(rows.map((i) => i.id))
    const txIdList = await getDispatchTxIds({ session, app, count: idSet.size })
    const idMap = Array.from(idSet).reduce((result, id, index) => {
      result[id] = txIdList[index]
      return result
    }, {})
    const seqMap = {}
    const ticketMap = {}

    rows.forEach((row) => {
      row.txId = idMap[row.id]

      const seq = seqMap[row.id] || 1
      row.seq = seq
      seqMap[row.id] = seq + 1

      const hasEIN = row.extra?.dealerEIN
      row.txAmt = row.quantity * row.price

      const txAmtNet = row.txAmt / (hasEIN ? 1.05 : 1)
      row.txAmtNet = Math.round(txAmtNet)

      let data = ticketMap[row.id]
      if (!data) data = { amount: 0, roundedAmount: 0, items: [] }

      data.amount += txAmtNet
      data.roundedAmount += row.txAmtNet
      data.items.push(row)
      ticketMap[row.id] = data
    })

    Object.values(ticketMap).forEach((ticket) => {
      const { amount, roundedAmount, items } = ticket
      const diff = amount - roundedAmount
      if (diff === 0) return

      let maxDecimal = -Infinity
      let maxIndex = -1

      items.forEach((item, index) => {
        const decimal = item.txAmt - Math.floor(item.txAmt)
        if (decimal >= 0.5) return
        if (decimal <= maxDecimal) return

        maxDecimal = decimal
        maxIndex = index
      })

      items[maxIndex].txAmtNet += diff
    })

    const newRows = Object.values(ticketMap).reduce((result, item) => {
      result = result.concat(item.items)
      return result
    }, [])

    createExcel({ message, title, cols, rows: newRows, filterValues })
  },
})

async function getInit({ session, app }) {
  const variables = {
    staffInput: { type: ['SALES'] },
    locationInput: {
      type: ['DEALER_ORG', 'DEALER', 'WAREHOUSE', 'WAREHOUSE_GROUP', 'COMPANY'],
    },
  }
  const query = `
    query($locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const orgs = []
  const dealers = []
  const warehouses = []
  const warehouseGroups = []
  const shippings = []

  data.locations.forEach((item) => {
    if (item.type === 'WAREHOUSE_GROUP') {
      warehouseGroups.push({ label: item.name, value: item.id })
    }
  })

  locations.forEach(({ id, name, type, extra }) => {
    const result = { value: id, label: name }
    switch (type) {
      case 'DEALER_ORG':
        orgs.push(result)
        break
      case 'DEALER':
        dealers.push(result)
        break
      case 'COMPANY':
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      // case 'WAREHOUSE_GROUP':
      //   warehouseGroups.push(result)
      //   break
      case 'WAREHOUSE':
        warehouses.push(result)
        break
      default:
    }
  })

  return {
    orgs,
    dealers,
    warehouses,
    warehouseGroups,
    shippings,
    sales: data.staffs,
  }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: TicketQueryInput) {
      dispatchTicketCount(input: $input)
      dispatchTickets(input: $input) {
        id
        ticketNo
        toLocationName
        srcLocationName
        transDate
        extra
        status
        outboxStatus
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.dispatchTickets,
    pagination: getPagination(page, countPerPage, data.dispatchTicketCount),
  }
}

async function getExportData({ session, app, filterValues, sort }) {
  if (!filterValues || filterValues.length === 0) return {}
  const variables = {
    input: getDataInput({ filterValues, joinItem: true, sort }),
  }
  const query = `
    query($input: TicketQueryInput) {
      dispatchTickets(input: $input) {
        id
        parentId
        ticketNo
        toLocationName
        transDate
        itemSrcLocationName
        productVariantName
        sku
        productCode
        barcode
        quantity
        price
        isGift
        extra
        itemExtra
        status
        outboxStatus
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.dispatchTickets
}

async function getDispatchTxIds({ session, app, count }) {
  const variables = { count }
  const query = `
    query($count: Int) {
      dispatchTxIds(count: $count)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.dispatchTxIds
}

function getDataInput({
  filterValues,
  pagination,
  joinItem = false,
  sort = 'DESC',
}) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const arriveDate = filterValues.find(({ id }) => id === 'arriveDate')
  const ticketNo = filterValues.find(({ id }) => id === 'ticketNo')
  const parentId = filterValues.find(({ id }) => id === 'parentId')
  const org = filterValues.find(({ id }) => id === 'org')
  const warehouseGroup = filterValues.find(({ id }) => id === 'warehouseGroup')
  const toLocation = filterValues.find(({ id }) => id === 'toLocation')
  const srcLocation = filterValues.find(({ id }) => id === 'srcLocation')
  const shippingId = filterValues.find(({ id }) => id === 'shippingId')
  const trackingNo = filterValues.find(({ id }) => id === 'trackingNo')
  const recipientName = filterValues.find(({ id }) => id === 'recipientName')
  const recipientContact = filterValues.find(
    ({ id }) => id === 'recipientContact',
  )
  const sales = filterValues.find(({ id }) => id === 'sales')
  const status = filterValues.find(({ id }) => id === 'status')
  const outboxStatus = filterValues.find(({ id }) => id === 'outboxStatus')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    joinOutbox: true,
    id: id?.value,
    parentId: parentId?.value,
    transDate: transDate?.value,
    ticketNo: ticketNo?.value,
    toLocationId: toLocation?.value.value,
    toLocationParentId: org?.value.value,
    itemSrcLocationId: srcLocation?.value.value,
    extra: {
      arriveDate: arriveDate?.value,
      recipientName: recipientName?.value,
      recipientContact: recipientContact?.value,
      salesId: sales?.value.value,
      shippingId: shippingId?.value.value,
      trackingNo: trackingNo?.value,
      locked: lockStatus?.value.value,
      warehouseGroupId: warehouseGroup?.value.value,
    },
    status: status?.value.value,
    outboxStatus: outboxStatus?.value.value,
    productVariantName: productName?.value,
    createdBy: createdBy?.value,
    orderBy: [
      // { key: 'createdAt', sort: 'DESC' },
      { key: 'id', sort },
    ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

function getTxHandleType(value) {
  switch (value) {
    case 'PHONE':
      return 1
    case 'MEMBER_ID':
      return 2
    case 'CITIZEN_ID':
      return 3
    default:
      return 0
  }
}
