import React from 'react'
import { request } from 'utilities/request'
import { Link } from 'components/core'
import { selectFilter, textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { getSelectOptions } from 'utilities/form'

export const filters = ({ data }) => [
  {
    id: 'name',
    label: 'product.field.spu',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    input: textFilter,
  },
  {
    id: 'locationId',
    label: `inventory.field.dept`,
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.depts),
      }),
  },
  {
    id: 'option',
    label: 'inventory.filter.option',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          {
            value: 'SHOW_ZERO',
            label: props.message({ id: 'inventory.option.SHOW_ZERO' }),
          },
        ],
      }),
  },
]

export const columns = ({ action } = {}) => [
  {
    id: 'locationName',
    label: `inventory.field.dept`,
    render: ({ row }) => row.locationName,
  },
  {
    id: 'productVariantName',
    label: 'inventory.field.spu',
  },
  {
    id: 'sku',
    label: 'inventory.field.sku',
  },
  {
    id: 'totalQuantity',
    label: 'inventory.field.totalQuantity',
    align: 'right',
    render: ({ row }) => row.quantity + row.lockQuantity,
  },
  {
    id: 'lockQuantity',
    label: 'inventory.field.lockQuantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleLockOpen({
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.lockQuantity}
      </Link>
    ),
    render: ({ row }) => row.lockQuantity,
  },
  {
    id: 'quantity',
    label: 'inventory.field.availableQuantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleOpen({
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.quantity}
      </Link>
    ),
    render: ({ row }) => row.quantity,
  },
]

export const handlers = ({
  setState,
  session,
  app,
  setFilterValues,
  setPagination,
  setData,
  message,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setData({ depts: resp.depts, orgMap: getOrgMap(resp.orgs) })
  },
  handleLoad: async ({ filterValues = [], pagination = {} } = {}) => {
    const resp = await getData({ session, app, filterValues, pagination })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'inventory.title.deptList'
    const cols = columns()
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

function getOrgMap(orgs) {
  return orgs.reduce((result, item) => {
    result[item.id] = item.name
    return result
  }, {})
}

async function getInit({ session, app }) {
  const variables = {
    input: { type: ['DEPT', 'DEALER_ORG'] },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        name
        type
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const depts = []
  const orgs = []

  data.locations.forEach((item) => {
    switch (item.type) {
      case 'DEPT':
        depts.push(item)
        break
      case 'DEALER_ORG':
        orgs.push(item)
        break
      default:
    }
  })

  return { depts, orgs }
}

async function getData({ session, app, filterValues, pagination }) {
  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryDeptCount(input: $input)
      inventoryDeptList(input: $input) {
        locationId
        locationName
        productVariantId
        quantity
        lockQuantity
        productVariantName
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryDeptList,
    pagination: getPagination(page, countPerPage, data.inventoryDeptCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryDeptList(input: $input) {
        locationId
        locationName
        productVariantId
        quantity
        lockQuantity
        productVariantName
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.inventoryDeptList
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const locationId = filterValues.find(({ id }) => id === 'locationId')
  const option = filterValues.find(({ id }) => id === 'option')
  const input = {
    productVariantName: name?.value,
    sku: sku?.value,
    locationId: locationId?.value.value,
    showZero: option?.value.value === 'SHOW_ZERO',
    orderBy: [{ key: 'locationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
