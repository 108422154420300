import React from 'react'
import { showDate } from 'utilities/form'
import { printHtml } from 'utilities/print'
import { request } from 'utilities/request'
import { MdDelete } from 'react-icons/md'
import { Button, Message } from 'components/core'
import { getPagination } from 'utilities/pagination'
import { Box } from 'reflexbox'

export const columns = ({ session, app, state, setState }) => [
  {
    id: 'createdAt',
    label: 'sdj.field.createdAt',
    render: ({ row }) => showDate(row.createdAt),
  },
  {
    id: 'key',
    label: 'sdj.field.errorKey',
  },
  {
    id: 'message',
    label: 'sdj.field.message',
    renderHtml: ({ row }) =>
      row.content.map((item, index) => {
        if (typeof item.message === 'object') {
          return (
            <Box key={index}>
              <Message id={item.message.id} values={item.message.values} />
            </Box>
          )
        }
        return <Box key={index}>{item.message}</Box>
      }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row, index }) => (
      <Button
        variant="icon"
        icon={<MdDelete />}
        onClick={async () => {
          const [ok] = await handleDelete({ session, app, id: row.id })
          if (!ok) return

          const importErrors = [...state]
          importErrors.splice(index, 1)
          setState(importErrors)
        }}
      />
    ),
  },
]

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  setPagination,
}) => ({
  handleLoad: async ({ pagination } = {}) => {
    const resp = await getData({ session, app, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handlePrint: () => {
    const title = 'sdj.title.purchaseDiff'
    const list = { columns: columns({ message }), rows: state }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
})

async function getData({ session, app, pagination = {} }) {
  const variables = { vendor: 'sdj', handler: 'purchaseNotify' }
  const query = `
    query($vendor: String!, $handler: String!) {
      callbackDiffCount(vendor: $vendor, handler: $handler) 
      callbackDiffs(vendor: $vendor, handler: $handler)  {
        id
        key
        content
        createdAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.callbackDiffs,
    pagination: getPagination(page, countPerPage, data.callbackDiffCount),
  }
}

async function handleDelete({ session, app, id }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteCallbackDiff(id: $id)
    }
  `
  return request({ query, variables }, { session, app })
}
