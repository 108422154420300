export default {
  'purchase.title.list': '進倉目錄',
  'purchase.title.add': '新增進倉單',
  'purchase.title.edit': '修改進倉單',
  'purchase.title.view': '進倉單詳情',
  'purchase.title.addItem': '新增進倉商品',
  'purchase.title.editItem': '修改進倉商品',
  'purchase.title.import': '匯入進倉單',
  'purchase.title.print': '進倉單 - {ticketId}',
  'purchase.btn.addBatch': '新增批號',
  'purchase.section.product': '進倉商品',
  'purchase.section.basic': '基礎信息',
  'purchase.message.template': '進倉單匯入範本',
  'purchase.field.id': '單號',
  'purchase.field.warehouseId': '入庫倉別',
  'purchase.field.ticketNo': '廠商單號',
  'purchase.field.srcCompanyId': '廠商名稱',
  'purchase.field.billCompanyId': '歸帳',
  'purchase.field.estimateDate': '預計到貨日',
  'purchase.field.transDate': '實際到貨日',
  'purchase.field.createdAt': '下單日期',
  'purchase.field.rackNo': '櫃號',
  'purchase.field.sealNo': '封條號碼',
  'purchase.field.memo': '備註',
  'purchase.field.productVariantId': '商品',
  'purchase.field.batchNo': '批號',
  'purchase.field.expireAt': '效期',
  'purchase.field.spu': '商品名稱',
  'purchase.field.sku': '品號',
  'purchase.field.invoicedQuantity': '下單數量',
  'purchase.field.purchasedQuantity': '出貨數量',
  'purchase.field.quantity': '到貨數量',
  'purchase.field.lockQuantity': '鎖定數量',
  'purchase.field.status': '狀態',
  'purchase.field.importFile': '匯入檔案',
}
