import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  getSelectOption,
  getDate,
  validateForm,
  renderDefinition,
  renderSelectInput,
  renderTable,
  renderTextAreaInput,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getLocation } from 'actions/location'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import { getAvailableBalance, handleDelete, setBalances } from 'actions/ticket'
import { Select, NumberInput } from 'components/form'
import { filterLocations } from 'utilities/permission'
import { getProductOptions } from 'actions/product'

const moduleName = 'reverse'

export const initialState = (value = {}, message) => ({
  isReady: value.isReady || false,
  id: value.id || '',
  locked: value.locked || false,
  createdBy: value.createdBy,
  createdAt: value.createdAt,
  updatedBy: value.updatedBy,
  updatedAt: value.updatedAt,
  transDate: getDate(value.transDate),
  fromLocationName: value.fromLocationName || '',
  status: value.status || 'ACTIVE',
  hash: value.hash,
  oldTicketItems: value.oldTicketItems || [],
  ticketItems: value.ticketItems || [],
  inputValues: getInitialInput(),
  editValues: {},
  locations: value.locations || [],
  products: value.products || [],
  ...initializeState({
    type: getReverseType(value.extra?.type, message),
    fromLocationId: getSelectOption(
      value.locations,
      value.fromLocationId,
      'id',
      'name',
    ),
    memo: value.extra?.memo || '',
  }),
})

function getInitialInput() {
  return {
    productVariantId: '',
    productVariantName: '',
    balance: 0,
    quantity: 0,
  }
}

function getReverseType(value, message) {
  if (!value) {
    const types = getReverseTypes(message)
    return types[0]
  }
  return { value, label: message({ id: `reverse.type.${value}` }) }
}

export function getReverseTypes(message) {
  return [
    { value: 'DEPT', label: message({ id: 'reverse.type.DEPT' }) },
    { value: 'DEALER_ORG', label: message({ id: 'reverse.type.DEALER_ORG' }) },
    { value: 'WAREHOUSE', label: message({ id: 'reverse.type.WAREHOUSE' }) },
  ]
}

function getLocationOptions(reverseType, locations) {
  if (!locations || !reverseType) return []
  if (reverseType === 'WAREHOUSE') reverseType = 'DEALER_ORG'

  const result = []
  locations.forEach(({ type, id, name }) => {
    if (type === reverseType) result.push({ label: name, value: id })
  })
  return result
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  fromLocationId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({
  profile,
  format,
  app,
  session,
  state,
  setState,
  message,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    transDate: definition({ id: 'transDate' }),
    type: selectInput({
      id: 'type',
      isSearchable: false,
      isClearable: false,
      options: getReverseTypes(message),
      callback: () => ({ fromLocationId: [], inputValues: getInitialInput() }),
    }),
    fromLocation: selectInput({
      id: 'fromLocationId',
      valKey: 'fromLocationName',
      isClearable: false,
      options: getLocationOptions(state.type.value, state.locations),
      callback: ({ value }) => onLocationChange(state, app, session, value),
    }),
    memo: textAreaInput({ id: 'memo', label: 'field.memo' }),
    product: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'reverse.field.spu',
          renderInput: () => (
            <Select
              isClearable={false}
              options={getProductOptions(state.products)}
              onChange={async ({ value }) => {
                const balance = await fetchBalance({
                  app,
                  session,
                  locationType: 'WAREHOUSE',
                  locationId: state.fromLocationId.value,
                  productVariantId: value,
                  oldTicketItems: state.oldTicketItems,
                })
                return { balance, quantity: 1 }
              }}
            />
          ),
          getValue: (row) =>
            getSelectOption(state.products, row.productVariantId, 'id', 'name'),
        },
        {
          id: 'balance',
          label: 'reverse.field.balance',
          align: 'right',
          format: ['html'],
          show: profile !== 'view',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          width: '96px',
          align: 'right',
          renderInput: ({ row, index }) => {
            const balance = getAvailableBalance({
              ticketItems: state.ticketItems,
              oldTicketItems: state.oldTicketItems,
              productVariantId: row.productVariantName.value,
              balance: row.balance,
              index,
            })
            const min = Math.min(1, balance)
            const values = index === -1 ? state.inputValues : state.editValues
            const quantity = parseInt(values.quantity)
            return (
              <NumberInput
                min={min}
                max={balance}
                hasError={quantity < min || quantity > balance}
              />
            )
          },
        },
      ],
      showAddInput: profile !== 'view',
      showDeleteIcon: profile !== 'view',
      inputValues: state.inputValues,
      editValues: state.editValues,
      onInputChange: (value) => setState({ ...state, inputValues: value }),
      onEditChange: (value) => setState({ ...state, editValues: value }),
      onAdd: ({ row }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const availableBalance = getAvailableBalance({
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
          productVariantId: productVariantName.value,
          balance: row.balance,
        })
        if (quantity > availableBalance) return

        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.push({
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        })
        const inputValues = getInitialInput()
        setState({ ...state, ticketItems, inputValues })
      },
      onEdit: ({ row, index }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const availableBalance = getAvailableBalance({
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
          productVariantId: productVariantName.value,
          balance: row.balance,
          index,
        })
        if (quantity > availableBalance) return

        const ticketItems = cloneDeep(state.ticketItems)
        const ticketItem = {
          ...ticketItems[index],
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        }
        ticketItems.splice(index, 1, ticketItem)
        setState({ ...state, ticketItems })
        return true
      },
      onDelete: ({ index }) => {
        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.splice(index, 1)
        setState({ ...state, ticketItems })
      },
    }),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  message,
  history,
  id,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editReverse(state, app, session)
      : await addReverse(state, app, session)
    if (!ok) return

    if (!id) {
      id = data.addReverseTicket
    }

    history.push(`/reverse/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('reverse', { session, app, id, hash })
    if (!ok) return false

    history.push('/reverse')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state, message })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ state, message }) {
  const title = { id: 'reverse.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const form = fields({ profile: 'view', format: 'print', state, message })
  const field = Object.values(form).filter(({ id }) => id !== 'ticketItems')
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: form.product },
  ]
  return { title, header, content }
}

async function getData({ app, session, id, profile }) {
  const locationInput = { type: ['DEPT', 'DEALER_ORG'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      productVariants {
        id
        name
        sku
      }
      reverseTicket(id: $id) {
        fromLocationId
        fromLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      reverseTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.reverseTicket || {}
  const ticketItems = data.reverseTicketItems || []
  const oldTicketItems = cloneDeep(ticketItems)

  if (profile === 'edit' && ticketItems.length > 0) {
    const { fromLocationId } = ticket
    const balances = await fetchBalances({
      app,
      session,
      locationType: 'DEFAULT',
      locationId: fromLocationId,
      productVariantId: ticketItems.map((item) => item.productVariantId),
      oldTicketItems,
    })
    setBalances(ticketItems, balances)
  }

  return {
    isReady: profile === 'edit',
    id,
    locked: ticket.extra?.locked,
    extra: ticket.extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    transDate: ticket.transDate,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems,
    oldTicketItems,
    locations,
    products: productVariants,
  }
}

async function addReverse(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addReverseTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editReverse(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editReverseTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    return item.quantity === newItem.quantity
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    fromLocationId: state.fromLocationId.value,
    extra: { type: state.type.value, memo: state.memo },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function onLocationChange(state, app, session, locationId) {
  const { locations } = state
  const ticketItems = [...state.ticketItems]
  if (ticketItems.length === 0) return { inputValues: getInitialInput() }

  const location = getLocation(locations, locationId)
  const productVariantId = ticketItems.map((item) => item.productVariantId)
  const balances = await fetchBalances({
    app,
    session,
    locationType: 'DEFAULT',
    locationId: location.id,
    productVariantId,
    oldTicketItems: state.oldTicketItems,
  })
  setBalances(ticketItems, balances)

  return { ticketItems, inputValues: getInitialInput() }
}
