import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/inventoryDeferDrawer'
import { Button, Drawer, Table, Toolbar } from 'components/core'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { useIntl } from 'react-intl'
import { MdFileUpload } from 'react-icons/md'

export default ({ value, open, action }) => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad, handleExport } = handlers({
    session,
    app,
    value,
    message,
    setState,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      width={[1, '460px']}
      title={`inventory.title.history`}
      open={open}
      onClose={action.handleClose}
    >
      <Toolbar>
        <Button
          variant="default"
          icon={<MdFileUpload />}
          text="btn.export"
          onClick={() => handleExport({ createExcel })}
        />
      </Toolbar>
      <Table
        columns={columns(message)}
        rows={state}
        pagination={pagination}
        onChange={handleLoad}
      />
    </Drawer>
  )
}
