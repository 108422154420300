import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { Box } from 'reflexbox'
import update from 'immutability-helper'
import { Button, Message } from 'components/core'
import DraggableBox from 'components/core/DraggableBox'
import { FaInbox } from 'react-icons/fa'
import { MdDelete, MdEdit } from 'react-icons/md'

const COUNT_PER_PAGE = 25

const SortableTable = (
  {
    show = true,
    className = {},
    showSeq = false,
    columns,
    primaryKey = 'id',
    rows: value,
    onSubmit,
    ...props
  },
  ref,
) => {
  const [rows, setRows] = useState(value)
  const cols = getColumns({ columns, profile: 'view', showSeq })

  useEffect(() => {
    setRows(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.length])

  useImperativeHandle(ref, () => ({
    getRows: () => rows,
  }))

  const renderCell = (column, row, index) => {
    if (column.renderHtml) {
      return column.renderHtml({ row, index })
    }
    if (column.render) {
      return column.render({ row, index })
    }
    return row[column.id]
  }

  const renderHeadRow = () => (
    <Box as="tr" {...props}>
      {cols.map(({ width, align, label, renderHead }, index) => (
        <Box
          as="th"
          key={index}
          py={3}
          px={2}
          sx={{
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'light.3',
            fontWeight: 300,
            whiteSpace: 'nowrap',
            width: width || 'auto',
            textAlign: align || 'left',
          }}
        >
          {renderHead ? renderHead() : label && <Message id={label} />}
        </Box>
      ))}
    </Box>
  )

  const onRowDrag = useCallback((dragIndex, hoverIndex) => {
    setRows((prevRows) =>
      update(prevRows, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevRows[dragIndex]],
        ],
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderRow = useCallback(
    (row, index) => (
      <DraggableBox
        as="tr"
        key={row[primaryKey]}
        index={index}
        style={{
          borderBottom: 'none',
          fontWeight: 300,
          whiteSpace: 'nowrap',
          cursor: 'grab',
        }}
        onDrag={onRowDrag}
      >
        {cols.map((column) => (
          <Box
            key={column.id}
            as="td"
            p={2}
            color="dark.1"
            width={column.width || 'auto'}
            sx={{
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: 'light.3',
              textAlign: column.align || 'left',
              whiteSpace: column.noWrap || 'initial',
            }}
          >
            {renderCell(column, row, index)}
          </Box>
        ))}
      </DraggableBox>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const renderRows = () => {
    if (!rows || rows.length === 0) return renderEmptyRow()

    return rows.map((row, index) => renderRow(row, index))
  }

  const renderEmptyRow = () => (
    <Box as="tr" bg="light.1">
      <Box
        as="td"
        colSpan={cols.length}
        p={3}
        sx={{
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'light.3',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'light.3',
        }}
      >
        <div>
          <FaInbox size="32px" />
          <Message mt={2} id="table.message.empty" />
        </div>
      </Box>
    </Box>
  )

  if (!show) return null

  return (
    <Box {...props}>
      <Box
        as="table"
        p={2}
        width={1}
        color="dark.2"
        sx={{
          borderCollapse: 'collapse',
        }}
        {...props}
      >
        <thead>{renderHeadRow()}</thead>
        <tbody>{renderRows()}</tbody>
      </Box>
    </Box>
  )
}

export default forwardRef(SortableTable)

function getColumns({
  session,
  navigate,
  columns,
  profile,
  showSeq,
  showEditButton,
  showDeleteButton,
  editValues,
  pagination,
  onEditClick,
  onEditSubmit,
  onDeleteClick,
}) {
  const cols = columns
    .filter(({ profile: colProfile = ['add', 'edit', 'view'] }) =>
      colProfile.includes(profile),
    )
    .filter(({ format = ['html'] }) => format.includes('html'))
    .filter(({ show = true }) => show)

  if (showSeq) {
    cols.unshift({
      id: '__SEQ__',
      label: 'field.seq',
      width: '1px',
      render: ({ index }) => {
        const { page = 1, countPerPage = COUNT_PER_PAGE } = pagination || {}
        const curPage = page || 1
        return (curPage - 1) * countPerPage + index + 1
      },
    })
  }

  if (showEditButton || showDeleteButton) {
    const renderEditButton = (item) => {
      if (!showEditButton) return null

      let handleEditClick = () => {}
      if (onEditClick) {
        handleEditClick = () => onEditClick({ navigate, value: item })
      }
      if (onEditSubmit) {
        handleEditClick = () => {
          cols.forEach((col) => {
            const { id, getValue } = col
            editValues[id] = !!getValue ? getValue(item.row) : item.row[id]
          })
          onEditSubmit(editValues, item.index)
        }
      }
      return <Button mr={1} icon={<MdEdit />} onClick={handleEditClick} />
    }

    const renderDeleteButton = (item) => {
      if (!showDeleteButton || !onDeleteClick) return null
      return (
        <Button
          icon={<MdDelete />}
          onClick={() => {
            return onDeleteClick({ session, value: item })
          }}
        />
      )
    }

    cols.push({
      id: '__ACTION__',
      align: 'right',
      width: '1px',
      noWrap: true,
      renderHtml: (item) => (
        <>
          {renderEditButton(item)}
          {renderDeleteButton(item)}
        </>
      ),
    })
  }

  return cols
}
