import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { useIntl } from 'react-intl'
import { Center, Button, Drawer } from 'components/core'
import { initialState, fields, labels, handlers } from 'models/comboDrawer'

export default ({ value, open, action }) => {
  const id = value?.id
  const readonly = value.readonly
  const profile = readonly ? 'view' : id ? 'edit' : 'add'
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState(initialState(value, data, message))
  const { handleInit, handleLoad, handleSubmit } = handlers({
    app,
    session,
    setState,
    setData,
    message,
    state,
    id,
    data,
    action,
  })
  const form = readonly
    ? labels({ state, message })
    : fields({ state, setState, message })

  useEffect(() => {
    handleInit(value?.categoryId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Drawer
      width={[1, '460px']}
      title={`combo.title.${profile}`}
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={handleSubmit}
    >
      {form.categoryId}
      {form.spu}
      {form.sku}
      {form.barcode}
      {form.memo}
      {form.vendor}
      {form.isActive}
      {form.variants}
      <Center mt={3}>
        <Button
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          show={!readonly}
          type="submit"
          variant="primary"
          text="btn.submit"
        />
      </Center>
    </Drawer>
  )
}
