export default {
  'processConfig.title.list': '加工模板目錄',
  'processConfig.title.add': '新增加工模板',
  'processConfig.title.edit': '修改加工模板',
  'processConfig.title.view': '加工模板詳情',
  'processConfig.title.delete': '刪除加工模板',
  'processConfig.title.sort': '更改加工項目排序',
  'processConfig.message.delete': '確認刪除加工模板?',
  'processConfig.section.product': '加工項目',
  'processConfig.section.basic': '基礎信息',
  'processConfig.section.image': '加工說明圖示',
  'processConfig.field.orgId': '經銷組群',
  'processConfig.field.name': '加工項目名稱',
  'processConfig.field.processName': '加工項目',
  'processConfig.field.code': '品號',
  'processConfig.field.memo': '註解',
  'processConfig.field.unit': '單位',
  'processConfig.field.quantity': '數量',
  'processConfig.field.price': '價格',
  'processConfig.field.status': '狀態',
  'processConfig.unit.PCS': 'PCS',
  'processConfig.unit.INNER_BOX': '內箱',
  'processConfig.unit.OUTER_BOX': '外箱',
}
