import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, handlers } from 'models/vicInvoicePage'
import { Box } from 'reflexbox'
import { Grid, Section, Button, Link, Center } from 'components/core'
import Page from 'components/Page'
import { MdArrowBack } from 'react-icons/md'
import VicInvoiceSyncModal from './VicInvoiceSyncModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}))
  const [syncOpen, setSyncOpen] = useState(false)
  const [syncType, setSyncType] = useState('syncProduct')

  const handler = handlers({
    app,
    session,
    state,
    setState,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (type) => {
    setSyncOpen(true)
    setSyncType(type)
  }

  const handleClose = () => {
    setSyncOpen(false)
    setSyncType('syncProduct')
  }

  return (
    <>
      <Page
        navId="pluginList"
        title="vicInvoice.title.edit"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/plugin"
            />
            {/* <Button
              ml={3}
              variant="primary"
              text="btn.save"
              onClick={useCallbackOnce(handler.handleSubmit)}
            /> */}
          </Center>
        }
        showFooterAction
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Box>
            <Section mt={4} title="vicInvoice.section.settingSync">
              <Box>
                <Button
                  variant="default"
                  text="vicInvoice.title.syncProduct"
                  onClick={() => handleOpen('syncProduct')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="vicInvoice.title.syncDealer"
                  onClick={() => handleOpen('syncDealer')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="vicInvoice.title.syncStorage"
                  onClick={() => handleOpen('syncStorage')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="vicInvoice.title.syncBilling"
                  onClick={() => handleOpen('syncBilling')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="vicInvoice.title.syncSales"
                  onClick={() => handleOpen('syncSales')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="vicInvoice.title.syncStaff"
                  onClick={() => handleOpen('syncStaff')}
                />
              </Box>
            </Section>
          </Box>
          {/* <Box>
            <Section mt={4} title="vicInvoice.section.orderSync">
              <Box>
                <Button
                  variant="default"
                  text="vicInvoice.title.syncPendingOrder"
                  onClick={() => handleOpen('syncPendingOrder')}
                />
              </Box>
            </Section>
          </Box> */}
        </Grid>
      </Page>
      <VicInvoiceSyncModal
        open={syncOpen}
        data={{ syncType }}
        action={{ handleClose }}
      />
    </>
  )
}
