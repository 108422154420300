import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { LinkButton, Button, Link } from 'components/core'
import { request } from 'utilities/request'
import { MdEdit, MdDelete } from 'react-icons/md'

export const columns = ({ setState, session, app }) => [
  {
    id: 'name',
    label: 'role.field.name',
    render: ({ row }) => <Link href={`/role/${row.id}/edit`}>{row.name}</Link>,
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          href={`/role/${row.id}/edit`}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'role.title.delete',
                text: 'role.message.delete',
                onSubmit: handleDelete({ setState, session, app, id: row.id }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({ setState, session, app }) => ({
  handleLoad: handleLoad({ setState, session, app }),
})

const handleLoad = ({ setState, session, app }) => async () => {
  const query = `
    query {
      roles {
        id
        merchantId
        name
        createdAt
        createdBy
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return

  setState(data.roles)
}

const handleDelete = ({ setState, session, app, id }) => async () => {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteRole(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok) {
    handleLoad({ setState, session, app })()
  }

  return ok
}
