import { showAddress } from 'utilities/form'
import { request } from 'utilities/request'

export function checkPhoneExists(phones, input) {
  if (!phones) return true

  const phone = input.replace(/^\D+/g, '')
  if (!phone) return true

  return phones.some((item) => item.replace(/^\D+/g, '') === phone)
}

export function checkAddressExists(addresses, input) {
  if (!addresses) return true

  const address = showAddress(input)
  if (!address) return true

  return addresses.some((item) => showAddress(item) === address)
}

export async function getCustomers(state, app, session) {
  const { customerId, recipientAddress } = state
  const input = []

  if (customerId) {
    input.push({ id: customerId })
  } else {
    input.push({ address: showAddress(recipientAddress) })

    const { recipientPhone, recipientCellphone } = state
    if (recipientPhone) input.push({ phone: recipientPhone })
    if (recipientCellphone) input.push({ phone: recipientCellphone })
  }

  const customers = await Promise.all(
    input.map((item) => getCustomer(app, session, item)),
  )

  return customers.reduce((result, item) => {
    result = result.concat(item)
    return result
  }, [])
}

export async function getCustomer(app, session, input) {
  const variables = { input }
  const query = `
      query($input: CustomerQueryInput) {
        customers(input: $input) {
          id
          name
          phones
          cellphones
          addresses {
            zipcode
            city
            district
            street
            hasLift
          }
        }
      }
    `
  const [ok, data] = await request({ query, variables }, { session, app })
  return ok ? data.customers : []
}
