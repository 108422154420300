import React from 'react'
import { request } from 'utilities/request'
import { getSelectOptions } from 'utilities/form'
import { textFilter, selectFilter, limitDateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'
import { filterLocations } from 'utilities/permission'
import { today, yesterday } from 'utilities/date'

export const filters = ({ data }) => [
  {
    id: 'sales',
    label: 'field.sales',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.sales),
      }),
  },
  {
    id: 'dealer',
    label: 'field.dealer',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: limitDateFilter({ max: yesterday() }),
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
]

export const columns = ({ data, filterValues, action }) => [
  {
    id: 'staffName',
    label: 'field.sales',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.staffMap) return ''
      return data.staffMap[row.staffId]?.name
    },
  },
  {
    id: 'locationName',
    label: 'field.dealer',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.dealerMap) return ''
      return data.dealerMap[row.locationId]?.name
    },
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.name
    },
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.sku
    },
  },
  {
    id: 'sellQuantity',
    label: 'field.sellQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            locationId: row.locationId,
            productVariantId: row.productVariantId,
            isGift: false,
          })
        }}
      >
        {round(row.sellQuantity)}
      </Link>
    ),
    render: ({ row }) => row.sellQuantity || 0,
  },
  {
    id: 'sellGiftQuantity',
    label: 'field.sellGiftQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            locationId: row.locationId,
            productVariantId: row.productVariantId,
            isGift: true,
          })
        }}
      >
        {round(row.sellGiftQuantity)}
      </Link>
    ),
    render: ({ row }) => row.sellGiftQuantity || 0,
  },
  {
    id: 'sellAmount',
    label: 'field.sellAmount',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            locationId: row.locationId,
            productVariantId: row.productVariantId,
            showAmount: true,
            isGift: false,
          })
        }}
      >
        {round(row.sellAmount)}
      </Link>
    ),
    render: ({ row }) => round(row.sellAmount),
  },
]

function round(val) {
  if (!val) return 0
  return Math.round(val * 100) / 100
}

export const handlers = ({
  session,
  app,
  message,
  setState,
  setTotal,
  setFilterValues,
  setPagination,
  setData,
}) => ({
  handleInit: async () => {
    const data = await getInit({ session, app })
    setDataMaps(data)

    setData(data)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setTotal(resp.total)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ data, filterValues, createExcel }) => {
    const title = 'report.title.staffSales'
    const cols = columns({ data, message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
  handleUpdate: async ({ pagination, filterValues }) => {
    const ok = await updateReport({ session, app })
    if (!ok) return false

    const resp = await getData({ session, app, pagination, filterValues })
    setTotal(resp.total)
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)

    return true
  },
})

function setDataMaps(data) {
  data.staffMap = data.sales.reduce((result, item) => {
    result[item.id] = item
    return result
  }, {})
  data.dealerMap = data.dealers.reduce((result, item) => {
    result[item.id] = item
    return result
  }, {})
  data.productMap = data.productVariants.reduce((result, item) => {
    result[item.id] = item
    return result
  }, {})
}

async function getInit({ session, app }) {
  const variables = {
    input: { type: ['SALES'] },
    locationInput: { type: ['DEALER'] },
    productInput: { status: ['ACTIVE', 'INACTIVE'] },
    merchantId: app.state.staff.merchantId,
  }
  const query = `
    query($input: StaffQueryInput, $locationInput: LocationQueryInput, $productInput: ProductQueryInput, $merchantId: ID) {
      staffs(input: $input) {
        id
        name
      }
      locations(input: $locationInput) {
        id
        name
      }
      productVariants(input: $productInput) {
        id
        name
        sku
      }
      reportLogs(merchantId: $merchantId) {
        id
        updatedAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const dealers = filterLocations(app.state.staff, data.locations)
  const reportLog = data.reportLogs?.find((item) => item.id === 'staffSales')

  return {
    sales: data.staffs,
    dealers,
    productVariants: data.productVariants,
    updatedAt: reportLog?.updatedAt + ' UTC',
  }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: StaffSalesReportInput) {
      staffSalesReportCount2(input: $input)
      staffSalesReports2(input: $input) {
        staffId
        locationId
        productVariantId
        sellQuantity
        sellGiftQuantity
        sellAmount
      }
      staffSalesTotal2(input: $input) {
        sellAmount
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    total: data.staffSalesTotal2,
    data: data.staffSalesReports2,
    pagination: getPagination(page, countPerPage, data.staffSalesReportCount2),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: StaffSalesReportInput) {
      staffSalesReports2(input: $input) {
        staffId
        locationId
        productVariantId
        sellQuantity
        sellGiftQuantity
        sellAmount
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.staffSalesReports2
}

function getDataInput({ filterValues, pagination }) {
  const sales = filterValues.find(({ id }) => id === 'sales')
  const dealer = filterValues.find(({ id }) => id === 'dealer')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const input = {
    staffId: sales?.value.value,
    locationName: dealer?.value,
    transDate: transDate?.value,
    productVariantName: productName?.value,
    // orderBy: [
    //   { key: 'staffName' },
    //   { key: 'locationName' },
    //   { key: 'productVariantName' },
    //   { key: 'sku' },
    // ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function updateReport({ session, app }) {
  const fromDate = today() + ' 00:00:00'
  const toDate = today() + ' 23:59:59'
  const variables = { transDate: [fromDate, toDate] }
  const query = `
    mutation($transDate: [String]) {
      addStaffSales(transDate: $transDate) 
    }  
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.addStaffSales
}
