import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { importColumns, handlers } from 'models/pxmartErrorList'
import { Section, Table } from 'components/core'
import Page from 'components/Page'
import { initialPage } from 'utilities/pagination'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const handler = handlers({ session, app, state, setState, setPagination })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="pxmart.title.errorList" navId="pxmartErrorList">
      <Section>
        <Table
          columns={importColumns({ app, session, state, setState, message })}
          rows={state}
          pagination={pagination}
          onChange={handler.handleLoad}
        />
      </Section>
    </Page>
  )
}
