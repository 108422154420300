import React from 'react'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getDate,
  getSelectOption,
} from 'utilities/form'
import { Select, NumberInput, TextArea, DateInput } from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = (value = {}, data) => {
  const productOptions = getProductOptions(data.ticketItems)
  return {
    productOptions,
    balance: 0,
    ...initializeState({
      transDate: getDate(value.transDate),
      productVariantId: getSelectOption(productOptions, value.productVariantId),
      quantity: value.quantity || 0,
      memo: value.memo || '',
    }),
  }
}

function getProductOptions(ticketItems) {
  if (!ticketItems) return []
  return ticketItems.map((item) => ({
    value: item.id,
    label: item.productVariantName,
  }))
}

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="defer.field.transDate"
        value={state.transDate}
        role="lockDeferTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    productVariantId: (
      <Select
        label="field.productName"
        isClearable={false}
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId', ({ value }) => {
          const ticketItem = data.ticketItems.find(({ id }) => id === value)
          return { balance: ticketItem.balance }
        })}
        errMsg={state.__error__.productVariantId}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        label="field.quantity"
        value={state.quantity}
        min={0}
        max={state.balance}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.setDisabled(true)
    const [ok] = await addRevert({ app, session, state, data })
    action.setDisabled(false)

    if (!ok) return

    action.handleClose()
    action.handleLoad()
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
})

async function addRevert({ session, app, state, data }) {
  const parentId = state.productVariantId.value
  const ticketItem = data.ticketItems.find(({ id }) => id === parentId)
  const revert = {
    parentItemId: ticketItem.id,
    transDate: state.transDate,
    productVariantId: ticketItem.productVariantId,
    productVariantName: ticketItem.productVariantName,
    quantity: parseInt(state.quantity),
    isGift: ticketItem.isGift,
    memo: state.memo,
  }
  const reverts = { itemsToAdd: [revert] }
  const input = { hash: data.hash, extra: { reverts } }
  const variables = { id: data.id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editDeferTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
