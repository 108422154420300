export default {
  'plugin.title.list': '插件設定',
  'plugin.title.add': '新增插件',
  'plugin.title.edit': '更改插件',
  'plugin.title.delete': '刪除插件',
  'plugin.title.addShipping': '新增物流',
  'plugin.title.addWarehouse': '新增倉別',
  'plugin.message.delete': '確認刪除插件?',
  'plugin.field.name': '名稱',
  'plugin.field.auth': 'API Token',
  'plugin.field.secret': 'Secret Key',
  'plugin.field.sdjShipping': '翔得捷物流',
  'plugin.field.shipping': '系統物流',
  'plugin.field.sdjWarehouse': '翔得捷倉別',
  'plugin.field.warehouse': '系統倉別',
  'plugin.field.isActive': '開啟',
}
