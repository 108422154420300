import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import { Popover } from 'components/core'
import { MdArrowDropDown } from 'react-icons/md'
import { renderMessage } from 'utilities/app'

export default ({
  disabled,
  href,
  text,
  textProps,
  children,
  popover,
  onClick,
  variant = 'secondary',
  ...props
}) => {
  const history = useHistory()
  const handleClick = disabled
    ? () => {}
    : href
    ? () => history.push(href)
    : onClick

  return (
    <Flex
      tx="buttons"
      display="inline-flex"
      alignItems="center"
      variant={disabled ? 'disabledLink' : variant}
      p={0}
      sx={{
        '&:active': {
          position: 'relative',
          top: '0px',
        },
      }}
    >
      <Box
        as="a"
        py={2}
        px={3}
        sx={{
          userSelect: 'none',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
          borderRightColor: 'light.3',
        }}
        onClick={handleClick}
        {...props}
      >
        {text ? renderMessage(text, textProps) : children}
      </Box>
      <Popover icon={<MdArrowDropDown />}>{popover}</Popover>
    </Flex>
  )
}
