import React from 'react'
import { request } from 'utilities/request'
import { Button, Link, StatusChip } from 'components/core'
import { selectFilter, textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { ALERT_ADD, FILTER_SET } from 'constants/actionType'
import { getStatusColor } from 'constants/status'
import { MdModeEdit, MdPhoto } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { Flex } from 'reflexbox'

export const filters = [
  {
    id: 'name',
    label: 'product.field.spu',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    input: textFilter,
  },
  {
    id: 'type',
    label: 'product.field.type',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getStatusOptions(props.message),
      }),
  },
]

function getStatusOptions(message) {
  return [
    { value: 'SINGLE', label: message({ id: 'product.type.SINGLE' }) },
    { value: 'COMBO', label: message({ id: 'product.type.COMBO' }) },
  ]
}

export const columns = ({ app, session, action, message }) => [
  {
    id: 'name',
    label: 'product.field.spuAndSku',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <>
        <Link
          variant="primaryLink"
          onClick={() => {
            if (row.type === 'COMBO') {
              return action.handleComboOpen({ ...row, readonly: true })
            }
            return action.handleOpen({ ...row, readonly: true })
          }}
        >
          {row.name}
        </Link>
        <Flex>{row.sku}</Flex>
      </>
    ),
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    format: ['excel'],
  },
  {
    id: 'type',
    label: 'product.field.type',
    format: ['html', 'excel'],
    render: ({ row }) => message({ id: `product.type.${row.type}` }),
  },
  {
    id: 'barcode',
    label: 'product.field.barcode',
    format: ['html', 'excel'],
  },
  {
    id: 'code',
    label: 'product.field.code',
    format: ['html', 'excel'],
    render: ({ row }) => row.extra?.code || row.code,
  },
  {
    id: 'boxUnit',
    label: 'product.field.boxUnit',
    align: 'right',
    format: ['html', 'excel'],
    render: ({ row }) => row.extra?.boxUnit || 0,
  },
  {
    id: 'postedPrice',
    label: 'product.field.postedPrice',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'price',
    label: 'product.field.price',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'boxLength',
    label: 'product.field.boxLength',
    align: 'right',
    format: ['excel'],
    render: ({ row }) => row.extra?.boxLength,
  },
  {
    id: 'boxWidth',
    label: 'product.field.boxWidth',
    align: 'right',
    format: ['excel'],
    render: ({ row }) => row.extra?.boxWidth,
  },
  {
    id: 'boxHeight',
    label: 'product.field.boxHeight',
    align: 'right',
    format: ['excel'],
    render: ({ row }) => row.extra?.boxHeight,
  },
  {
    id: 'boxWeight',
    label: 'product.field.boxWeight',
    align: 'right',
    format: ['excel'],
    render: ({ row }) => row.extra?.boxWeight,
  },
  {
    id: 'expireDate',
    label: 'product.field.expireDate',
    align: 'right',
    format: ['excel'],
    render: ({ row }) => row.extra?.expireDate,
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`product.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `product.status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    format: ['html'],
    renderHtml: ({ row }) => {
      const { image } = row.extra || {}
      const hasImage = image

      return (
        <>
          {hasImage && (
            <Button
              mr={2}
              variant="icon"
              icon={<MdPhoto />}
              onClick={() =>
                action.handleImageOpen({
                  productId: row.id,
                  images: [image],
                })
              }
            />
          )}
          <Button
            mr={1}
            icon={<MdModeEdit />}
            variant="icon"
            onClick={() => {
              if (row.type === 'COMBO') {
                return action.handleComboOpen({ ...row, readonly: false })
              }
              return action.handleOpen({ ...row, readonly: false })
            }}
          />
          {renderDeleteButton({
            module: 'variant',
            session,
            status: 'PENDING',
            onSubmit: () =>
              handleDelete({
                session,
                app,
                id: row.id,
                type: row.type,
                action,
              }),
          })}
        </>
      )
    },
  },
]

export const handlers = ({
  session,
  app,
  categoryId,
  setState,
  setFilterValues,
  setPagination,
  message,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'productList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({
      session,
      app,
      categoryId,
      pagination,
      filterValues,
    })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'product.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, categoryId, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({
  session,
  app,
  categoryId,
  pagination = {},
  filterValues = [],
}) {
  const variables = {
    input: getDataInput({ categoryId, pagination, filterValues }),
  }
  const query = `
    query($input: ProductQueryInput) {
      productVariantCount(input: $input)
      productVariants(input: $input) {
        id
        categoryId
        categoryName
        type
        name
        sku
        barcode
        postedPrice
        price
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.productVariants,
    pagination: getPagination(page, countPerPage, data.productVariantCount),
  }
}

async function getExportData({ session, app, categoryId, filterValues = [] }) {
  const variables = { input: getDataInput({ categoryId, filterValues }) }
  const query = `
    query($input: ProductQueryInput) {
      productVariants(input: $input) {
        type
        name
        sku
        barcode
        postedPrice
        price
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.productVariants
}

function getDataInput({ categoryId, filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const type = filterValues.find(({ id }) => id === 'type')
  const input = {
    categoryId,
    productVariantName: name?.value,
    sku: sku?.value,
    type: type?.value.value,
    status: ['ACTIVE', 'INACTIVE'],
    orderBy: [{ key: 'status', sort: 'DESC' }, { key: 'name' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function handleDelete({ session, app, id, type, action }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteProduct(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return false

  action.handleLoad()
  session.dispatch({
    type: ALERT_ADD,
    item: { type: 'success', message: 'delete.success' },
  })
  return true
}
