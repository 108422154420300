import React, { useContext, useEffect, useState } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { Flex } from 'reflexbox'
import { initialState, fields, handlers } from 'models/customerSwitchModal'
import { Modal, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, open, onClose, onSubmit }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const form = fields({ state, setState })
  const handler = handlers({ value, state, setState, app, session })

  useEffect(() => {
    setState(initialState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal
      title="customer.title.switch"
      open={open}
      onClose={onClose}
      width={300}
      as="form"
      onSubmit={useCallbackOnce(async (event) => {
        const ok = await handler.handleSubmit(event)
        if (ok) onSubmit()
      })}
    >
      {form.customerId}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button variant="secondary" mr={2} text="btn.close" onClick={onClose} />
        <Button type="submit" variant="primary" text="btn.submit" />
      </Flex>
    </Modal>
  )
}
