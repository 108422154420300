import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  getSelectOption,
  getMultiSelectOption,
  getSelectOptions,
  getDate,
  validateForm,
  renderDefinition,
  renderDateInput,
  renderTextInput,
  renderTextAreaInput,
  renderSelectInput,
  renderTable,
  renderSwitchInput,
  renderAddressInput,
  renderPhoneInput,
  renderAttach,
  renderCustomerLookup,
  renderImageDropzone,
  showDate,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getLocation, parseRecipient, fetchLocations } from 'actions/location'
import { getRecipients, handleDelete } from 'actions/ticket'
import { Center, Button, Icon } from 'components/core'
import { MdEdit, MdDelete, MdCheck, MdMore } from 'react-icons/md'
import { filterLocations, hasAccess } from 'utilities/permission'
import { uploadFile, urlToFile } from 'utilities/file'
import { formatDate } from 'utilities/date'
import { updateImages } from 'actions/image'
import { getTag } from 'actions/token'

const moduleName = 'dispatch'
const url = process.env.REACT_APP_STATIC_URL

export const initialState = (value = {}, data = {}, message, profile) => {
  const recipients = getRecipients(
    value.recipients?.length > 0 ? value.recipients : data.recipients,
    message,
  )
  const { sales, techs } = data
  const isRefAdd = profile === 'add' && value.parentId
  return {
    tag: value.tag,
    isReady: value.isReady || false,
    id: value.id || '',
    sync: value.sync || false,
    locked: value.locked || false,
    parentId: value.parentId || '',
    parentType: value.parentType || '',
    status: value.status || 'PENDING',
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    warehouses: data.warehouses,
    warehouseGroups: data.warehouseGroups,
    dealers: data.dealers,
    dealerCode: value.dealerCode || '',
    isSell: value.isSell || false,
    recipients,
    billings: data.billings,
    shippings: data.shippings,
    sales,
    salesName: value.salesName || [],
    backupName: value.backupName || [],
    techs,
    techName: value.techName || [],
    products: data.products || [],
    attach: value.attach,
    attachToAdd: null,
    attachToDel: null,
    customerId: '',
    images: value.images || [],
    imagesToAdd: value.imagesToAdd || [],
    imagesToDel: [],
    warehouseGroupName: value.warehouseGroupName,
    ...initializeState({
      isUrgent: value.isUrgent || false,
      isDirect: value.isDirect || false,
      isCollect: value.isCollect || false,
      transDate: isRefAdd ? '' : getDate(value.transDate),
      arriveDate: isRefAdd ? '' : getDate(value.arriveDate),
      arrivePeriod: getArrivePeriod(value.arrivePeriod, message),
      ticketNo: value.ticketNo || '',
      dealerId: getSelectOption(data.dealers, value.dealerId, 'id', 'name'),
      dealerName: value.dealerName || '',
      dealerEIN: value.dealerEIN || '',
      dealerAddress: value.dealerAddress || '',
      recipientId: isRefAdd
        ? {}
        : getSelectOption(recipients, value.recipientId, 'id', 'name'),
      recipientName: value.recipientName || '',
      recipientContact: value.recipientContact || '',
      recipientAddress: value.recipientAddress || '',
      recipientEmail: value.recipientEmail || '',
      recipientPhone: value.recipientPhone || '',
      recipientCellphone: value.recipientCellphone || '',
      receiptNo: value.receiptNo || '',
      billingId: getSelectOption(data.billings, value.billingId),
      billingName: value.billingName || '',
      shippingId: getSelectOption(data.shippings, value.shippingId),
      shippingName: value.shippingName || '',
      // warehouseId: getSelectOption(value.warehouses, value.warehouseId),
      // warehouseName: value.warehouseName || '',
      warehouseGroupId: getSelectOption(
        data.warehouseGroups,
        value.warehouseGroupId,
      ),
      salesId: getMultiSelectOption(sales, value.salesId),
      backupId: getMultiSelectOption(sales, value.backupId),
      techId: getSelectOption(techs, value.techId),
      memo: value.memo || '',
      warehouseMemo: value.warehouseMemo || '',
      trackingNo: value.trackingNo || '',
      handleType: getHandleType(value.handleType, message),
      handleNo: value.handleNo || '',
    }),
  }
}

function getHandleTypes(message) {
  return [
    { value: 'PHONE', label: message({ id: `dispatch.handleType.PHONE` }) },
    {
      value: 'MEMBER_ID',
      label: message({ id: `dispatch.handleType.MEMBER_ID` }),
    },
    {
      value: 'CITIZEN_ID',
      label: message({ id: `dispatch.handleType.CITIZEN_ID` }),
    },
  ]
}

export function getHandleType(value, message) {
  if (!value) return null
  return { value, label: message({ id: `dispatch.handleType.${value}` }) }
}

function getArrivePeriods(message) {
  return [
    { value: 'ALL', label: message({ id: `dispatch.arrivePeriod.ALL` }) },
    { value: 'AM', label: message({ id: `dispatch.arrivePeriod.AM` }) },
    { value: 'PM', label: message({ id: `dispatch.arrivePeriod.PM` }) },
  ]
}

function getArrivePeriod(value, message) {
  if (!value) value = 'ALL'
  return { value, label: message({ id: `dispatch.arrivePeriod.${value}` }) }
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  arriveDate: [{ type: 'required', message: 'error.required' }],
  dealerId: [{ type: 'required', message: 'error.required' }],
  // warehouseId: [{ type: 'required', message: 'error.required' }],
  warehouseGroupId: [{ type: 'required', message: 'error.required' }],
  recipientId: [{ type: 'required', message: 'error.required' }],
  recipientContact: [{ type: 'required', message: 'error.required' }],
  recipientAddress: [
    { type: 'required', message: 'error.required' },
    { type: 'address', message: 'error.invalidAddress' },
  ],
  salesId: [{ type: 'required', message: 'error.required' }],
  backupId: [{ type: 'required', message: 'error.required' }],
}

const crossValidation = [
  {
    fields: ['recipientPhone', 'recipientCellphone'],
    type: 'required',
    message: 'dispatch.error.phoneRequired',
  },
]

export const fields = ({
  app,
  session,
  parentId,
  state,
  setState,
  message,
  profile,
  format,
  action,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const customerLookup = (inputProps) =>
    renderCustomerLookup({ ...commonProps, ...inputProps })
  const phoneInput = (inputProps) =>
    renderPhoneInput({ ...commonProps, ...inputProps })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const switchInput = (inputProps) =>
    renderSwitchInput({ ...commonProps, ...inputProps })
  const addressInput = (inputProps) =>
    renderAddressInput({ ...commonProps, ...inputProps, message })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })

  const isOwner = app.state.staff?.staffType === 'OWNER'

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    parentId: definition({
      id: 'parentId',
      label: 'field.parentId',
      show: !!state.parentId,
      href:
        state.parentType === 'LEND'
          ? `/lend/${state.parentId}/view`
          : `/defer/${state.parentId}/view`,
    }),
    isUrgent: switchInput({ id: 'isUrgent' }),
    isDirect: switchInput({
      id: 'isDirect',
      disabled: !!state.id || state.parentType === 'LEND',
      // callback: (value) => onIsDirectChange(state, app, session, value),
    }),
    isCollect: switchInput({ id: 'isCollect' }),
    isSell: switchInput({ id: 'isSell', disabled: true }),
    transDate: dateInput({
      id: 'transDate',
      role: 'lockDispatchTicket',
      max: getMaxTransDate(app),
    }),
    arriveDate: dateInput({ id: 'arriveDate', role: 'lockDispatchTicket' }),
    arrivePeriod: selectInput({
      id: 'arrivePeriod',
      isSearchable: false,
      isClearable: false,
      options: getArrivePeriods(message),
    }),
    ticketNo: textInput({
      id: 'ticketNo',
      profile: state.status !== 'PENDING' && !isOwner ? 'view' : profile,
    }),
    billing: selectInput({
      id: 'billingId',
      valKey: 'billingName',
      isClearable: false,
      options: state.billings,
    }),
    dealer: selectInput({
      profile:
        (state.id || parentId) && app.state.staff?.staffType !== 'OWNER'
          ? 'view'
          : profile,
      id: 'dealerId',
      valKey: 'dealerName',
      isClearable: false,
      options: getSelectOptions(state.dealers),
      callback: ({ value }) =>
        onDealerChange(state, app, session, message, value),
    }),
    dealerCode: textInput({ id: 'dealerCode' }),
    dealerEIN: textInput({ id: 'dealerEIN' }),
    dealerAddress: textInput({ id: 'dealerAddress' }),
    recipient: selectInput({
      id: 'recipientId',
      valKey: 'recipientName',
      isClearable: false,
      options: getSelectOptions(state.recipients),
      callback: (item) => {
        const recipient = getLocation(state.recipients, item?.value)
        return parseRecipient(recipient, state)
      },
    }),
    recipientContact: customerLookup({
      id: 'recipientContact',
      callback: (value) => {
        setState({
          ...state,
          customerId: value.id || '',
          recipientContact: value.name || '',
          recipientPhone: value.phone || '',
          recipientCellphone: value.cellphone || '',
          recipientAddress: value.address || {},
        })
        return true
      },
    }),
    recipientAddress: addressInput({ id: 'recipientAddress' }),
    recipientEmail: textInput({ id: 'recipientEmail' }),
    recipientPhone: phoneInput({ id: 'recipientPhone' }),
    recipientCellphone: phoneInput({
      id: 'recipientCellphone',
      type: 'cellphone',
    }),
    receiptNo: textInput({ id: 'receiptNo' }),
    shipping: selectInput({
      id: 'shippingId',
      valKey: 'shippingName',
      isClearable: false,
      options: state.shippings,
    }),
    // warehouse: selectInput({
    //   id: 'warehouseId',
    //   valKey: 'warehouseName',
    //   isClearable: false,
    //   options: state.warehouses,
    //   callback: ({ value }) => onWarehouseChange(state, app, session, value),
    // }),
    warehouseGroup: selectInput({
      id: 'warehouseGroupId',
      valKey: 'warehouseGroupName',
      isClearable: false,
      options: state.warehouseGroups,
      callback: () => {
        const ticketItems = [...state.ticketItems].map((item) => {
          item.srcLocationId = null
          item.srcLocationName = null
          return item
        })
        return { ticketItems }
      },
    }),
    sales: selectInput({
      id: 'salesId',
      valKey: 'salesName',
      isClearable: false,
      isMulti: true,
      options: state.sales,
    }),
    backup: selectInput({
      disabled: !hasAccess(app.state.staff, 'lockDispatchTicket'),
      id: 'backupId',
      valKey: 'backupName',
      isClearable: false,
      isMulti: true,
      options: state.sales,
    }),
    tech: selectInput({
      id: 'techId',
      valKey: 'techName',
      options: state.techs,
    }),
    memo: textAreaInput({ id: 'memo' }),
    warehouseMemo: textAreaInput({ id: 'warehouseMemo' }),
    trackingNo: textInput({ id: 'trackingNo' }),
    handleType: selectInput({
      id: 'handleType',
      isSearchable: false,
      options: getHandleTypes(message),
    }),
    handleNo: textInput({ id: 'handleNo' }),
    attach: renderAttach({
      ...commonProps,
      session,
      id: 'attach',
      accept: '.zip',
      href: `${url}/${app.state.staff.merchantId}/${state.id}/${state.attach}`,
    }),
    product: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'dispatch.field.spu',
        },
        {
          id: 'srcLocationName',
          label: 'dispatch.field.warehouseId',
        },
        {
          id: 'isDemo',
          label: 'dispatch.field.isDemo',
          renderHtml: ({ row }) => (
            <Icon
              show={row.extra?.isDemo || false}
              color="success.1"
              value={<MdCheck />}
            />
          ),
          render: ({ row }) => (row.extra?.isDemo ? '&check;' : ''),
        },
        {
          id: 'isGift',
          label: 'dispatch.field.isGift',
          renderHtml: ({ row }) => (
            <Icon
              show={row.isGift || false}
              color="success.1"
              value={<MdCheck />}
            />
          ),
          render: ({ row }) => (row.isGift ? '&check;' : ''),
        },
        // {
        //   id: 'warehouseBalance',
        //   label: 'dispatch.field.warehouseBalance',
        //   align: 'right',
        //   profile: ['add', 'edit'],
        //   format: ['html'],
        //   // show: !state.isDirect,
        //   show: state.parentType !== 'LEND',
        // },
        // {
        //   id: 'balance',
        //   label: 'dispatch.field.balance',
        //   align: 'right',
        //   profile: ['add', 'edit'],
        //   format: ['html'],
        //   show: state.isDirect || state.parentType === 'LEND',
        // },
        // {
        //   id: 'deptBalance',
        //   label: 'dispatch.field.deptBalance',
        //   align: 'right',
        //   profile: ['add', 'edit'],
        //   format: ['html'],
        //   show: !state.isDirect && state.parentType !== 'LEND',
        // },
        {
          id: 'quantity',
          label: 'dispatch.field.quantity',
          format: ['html'],
          align: 'right',
        },
        {
          id: 'batchNo',
          label: 'dispatch.field.batchNo',
          format: ['print'],
          render: ({ row }) => {
            if (!row.extra.batchItems) return null
            return row.extra.batchItems
              .map(({ batchNo }) => batchNo || '--')
              .join('<br/>')
          },
        },
        {
          id: 'expireAt',
          label: 'dispatch.field.expireAt',
          format: ['print'],
          render: ({ row }) => {
            if (!row.extra.batchItems) return null
            return row.extra.batchItems
              .map(({ expireAt }) => expireAt || '--')
              .join('<br/>')
          },
        },
        {
          id: 'batchQuantity',
          label: 'dispatch.field.quantity',
          align: 'right',
          format: ['print'],
          render: ({ row }) => {
            if (!row.extra.batchItems) return row.quantity
            if (row.extra.batchItems.length === 0) return row.quantity
            return row.extra.batchItems
              .map(({ quantity }) => quantity || 0)
              .join('<br/>')
          },
        },
        {
          id: 'price',
          label: 'dispatch.field.price',
          align: 'right',
          format: ['html'],
          render: ({ row }) => row.price,
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          profile: ['add', 'edit'],
          render: ({ row, index }) => (
            <Center justifyContent="flex-end">
              <Button
                mr={2}
                variant="icon"
                icon={<MdEdit />}
                onClick={() =>
                  action.handleOpen({
                    ...row,
                    oldTicketItem: state.oldTicketItems[index],
                    parentId: state.parentId,
                    index,
                  })
                }
              />
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const ticketItems = cloneDeep(state.ticketItems)
                  ticketItems.splice(index, 1)
                  setState({ ...state, ticketItems })
                }}
              />
            </Center>
          ),
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          profile: ['view'],
          render: ({ row }) => (
            <Button
              variant="icon"
              icon={<MdMore />}
              onClick={() => action.handleOpen(row)}
            />
          ),
        },
      ],
    }),
    images: renderImageDropzone({
      format,
      profile,
      app,
      session,
      state,
      setState,
      action,
      imagePath: `${app.state.staff.merchantId}/${state.id || parentId}`,
    }),
  }
}

async function fetchImages(urls, merchantId, id) {
  if (!merchantId || !id) return null

  try {
    return await Promise.all(
      urls.map((item) => urlToFile(`${url}/${merchantId}/${id}/${item}`)),
    )
  } catch (e) {
    console.error(e.message)
    return []
  }
}

function getMaxTransDate(app) {
  if (app.state.staff?.staffType === 'OWNER') {
    return '9999-12-31'
  }

  if (app.state.staff?.permissions.includes('addCustomer')) {
    return '9999-12-31'
  }

  const newDate = new Date()
  newDate.setDate(newDate.getDate() + 60)
  return formatDate(newDate)
}

export const handlers = ({
  state,
  setState,
  data,
  setData,
  session,
  app,
  history,
  message,
  id,
  parentId,
  parentType = 'DEFER',
  profile,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ app, session, parentId })
    setData(resp)
  },
  handleLoad: async () => {
    if (!data || Object.keys(data).length === 0) return

    const resp = await getData({
      app,
      session,
      id,
      parentId,
      parentType,
      profile,
      data,
    })

    if (parentId && parentType === 'DEFER') {
      if (resp.itemLocked === false) {
        history.push(`/defer/${parentId}/view`)
        session.dispatch({
          type: ALERT_ADD,
          item: { type: 'error', message: 'dispatch.error.deferItemLocked' },
        })
      } else {
        resp.imagesToAdd = await fetchImages(
          resp.images,
          app.state.staff.merchantId,
          parentId,
        )
      }
    }
    setState(initialState(resp, data, message, profile))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    const isValid = validateForm({
      session,
      state,
      setState,
      validation,
      crossValidation,
    })
    if (!isValid) return

    if (state.ticketItems.length === 0) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.product.required' },
      })
      return
    }

    if (!state.ticketItems.every((i) => !!i.srcLocationId)) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.dispatch.missingWarehouseId' },
      })
      return
    }

    const [ok, data] = id
      ? await editDispatch(state, app, session)
      : await addDispatch(state, app, session)

    if (!ok) {
      const tag = await getTag({ session, app })
      setState({ ...state, tag })
      return
    }

    if (!id) {
      id = parentId ? data.addDeferDispatchTicket : data.addDispatchTicket
    }

    const uploadOk = await updateAttach(app, session, state, id)
    if (!uploadOk) {
      if (!id) history.push(`/dispatch/${id}/edit`)
      return
    }

    const imageOk = await updateImages(app, session, state, id)
    if (!imageOk) {
      if (!id) history.push(`/dispatch/${id}/edit`)
      return
    }

    history.push(`/dispatch/${id}/view`)
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('dispatch', { session, app, id, hash })
    if (!ok) return false

    history.push('/dispatch')
    return true
  },
  addItem: (value) => {
    const ticketItems = [...state.ticketItems]
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmDispatch({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id, parentId, parentType, data })
    setState(initialState(resp, data, message, profile))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })

    return true
  },
  handlePrint: () => {
    const { title, header, footer, content } = getPrintData({
      app,
      state,
      message,
    })
    printHtml({ title, header, footer, content, message })
  },
})

export function getPrintData({ app, state, message }) {
  const title = { id: 'dispatch.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const footer = [
    { label: 'ticket.manager', value: '__INPUT__' },
    { label: 'ticket.accounting', value: '__INPUT__' },
    { label: 'ticket.warehouse', value: '__INPUT__' },
    { label: 'ticket.technician', value: '__INPUT__' },
    { label: 'ticket.customer', value: '__INPUT__' },
  ]
  const form = fields({ format: 'print', app, state, message })
  const hidden = [
    'handleType',
    'handleNo',
    'trackingNo',
    'receiptNo',
    'attach',
    'ticketItems',
    'images',
  ]
  const field = Object.values(form).filter(({ id }) => !hidden.includes(id))
  const content = [
    {
      type: 'field',
      value: field,
      display: 'inline',
      template: '2fr 1fr 1fr 1fr 1fr',
      areas: `
          'id isUrgent isCollect isDirect isSell'
          'parentId transDate transDate dealerId dealerId'
          'ticketNo recipientId recipientId dealerCode dealerCode'
          'arriveDate recipientContact recipientContact dealerAddress dealerAddress'
          'arrivePeriod recipientPhone recipientPhone dealerEIN dealerEIN'
          'billingId recipientCellphone recipientCellphone shippingId shippingId'
          'salesId techId techId recipientEmail recipientEmail'
          'backupId recipientAddress recipientAddress warehouseGroupId warehouseGroupId'
          'memo recipientAddress recipientAddress warehouseMemo warehouseMemo'
        `,
    },
    { type: 'list', value: form.product },
  ]
  return { title, header, footer, content }
}

async function getInit({ app, session, parentId }) {
  const { merchantId } = app.state.staff
  const staffInput = { type: ['SALES', 'TECHNICIAN'] }
  const productInput = { status: parentId ? ['ACTIVE', 'INACTIVE'] : null }
  const locationInput = {
    type: ['WAREHOUSE_GROUP', 'WAREHOUSE', 'DEALER', 'COMPANY'],
  }
  const variables = {
    locationInput,
    staffInput,
    productInput,
    merchantId,
    vendor: 'sdj',
  }
  const query = `
    query($locationInput: LocationQueryInput, $staffInput: StaffQueryInput, $merchantId: ID!, $vendor: String!, $productInput: ProductQueryInput) {
      plugin(merchantId: $merchantId, vendor: $vendor) {
        isActive
      }
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
        type
      }
      productVariants(input: $productInput) {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { staffs, productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const warehouseGroups = []
  const warehouses = []
  const dealers = []
  const billings = []
  const shippings = []
  let recipients = []

  data.locations.forEach((item) => {
    if (item.type === 'WAREHOUSE_GROUP') {
      warehouseGroups.push({ label: item.name, value: item.id })
    }
  })

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      // case 'WAREHOUSE_GROUP':
      //   warehouseGroups.push(result)
      //   break
      case 'WAREHOUSE':
        result.parentId = item.parentId
        warehouses.push(result)
        break
      case 'DEALER':
        dealers.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'BILLING') billings.push(result)
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  const sales = []
  const techs = []

  staffs.forEach((item) => {
    const result = { label: item.name, value: item.id }
    switch (item.type) {
      case 'SALES':
        sales.push(result)
        break
      case 'TECHNICIAN':
        techs.push(result)
        break
      default:
    }
  })

  return {
    plugin: data.plugin,
    warehouses,
    warehouseGroups,
    dealers,
    recipients,
    billings,
    shippings,
    sales,
    techs,
    products: productVariants,
  }
}

async function getData({
  app,
  session,
  id,
  parentId,
  parentType,
  profile,
  data,
}) {
  const variables = { id: parentId || id }
  const query = `
    query($id: ID) {
      ${profile !== 'view' ? 'tag' : ''}
      dispatchTicket(id: $id) {
        parentId
        ticketNo
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      dispatchTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        srcLocationId
        srcLocationName
        sku
        quantity
        balance
        price
        isGift
        extra
        status
      }
    }
  `
  const [ok, resp] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = resp.dispatchTicket || {}
  if (ticket.extra?.parentType) parentType = ticket.extra?.parentType

  const ticketItems = resp.dispatchTicketItems.reduce((result, item) => {
    if (parentId) {
      if (item.status === 'INACTIVE') return result
      if (item.balance <= 0) return result
      item.quantity = item.balance

      if (parentType === 'LEND' && item.extra.isReturn) return result

      result.push(item)
      return result
    }
    result.push(item)
    return result
  }, [])
  const oldTicketItems = parentId ? [] : cloneDeep(ticketItems)
  const extra = ticket.extra || {}
  const isDirect = parentType === 'LEND' || extra.isDirect
  let recipients = []

  if ((profile === 'edit' || parentId) && ticket.toLocationId) {
    const locationInput = { parentId: ticket.toLocationId, type: ['STORAGE'] }
    recipients = await fetchLocations(app, session, locationInput)
  }

  // if ((profile === 'edit' || parentId) && ticketItems.length > 0) {
  //   const warehouseBalances = await getBalances(
  //     app,
  //     session,
  //     ticketItems,
  //     oldTicketItems,
  //     'WAREHOUSE',
  //     ticketItems.map((item) => item.srcLocationId),
  //   )
  //   setBalances(ticketItems, warehouseBalances, 'warehouseBalance')

  //   if (isDirect && parentType !== 'LEND') {
  //     const balances = await getBalances(
  //       app,
  //       session,
  //       ticketItems,
  //       oldTicketItems,
  //       'ALL',
  //       null,
  //     )
  //     setBalances(ticketItems, balances)
  //   } else {
  //     const dealer = getLocation(data.dealers, ticket.toLocationId)
  //     if (dealer) {
  //       const deptBalances = await getBalances(
  //         app,
  //         session,
  //         ticketItems,
  //         oldTicketItems,
  //         'DEALER_ORG',
  //         dealer.parentId,
  //       )
  //       setBalances(ticketItems, deptBalances, 'deptBalance')
  //     }
  //   }
  // }

  if (profile === 'add' && parentId) {
    const dealer = getLocation(data.dealers, ticket.toLocationId)
    extra.dealerEIN = dealer?.extra.ein
    extra.dealerAddress = dealer?.extra.address
    extra.isSell = dealer?.extra.type === 'DEALER_STORAGE'
  }

  // let warehouseId = ticket.srcLocationId
  // let warehouseName = ticket.srcLocationName

  // if (profile === 'add' && parentType === 'LEND') {
  //   warehouseId = ticket.fromLocationId
  //   warehouseName = ticket.fromLocationName
  // }

  return {
    tag: resp.tag,
    isReady: profile === 'edit',
    id,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    sync: extra.sync === undefined ? data.plugin.isActive : extra.sync,
    locked: ticket.extra?.locked,
    itemLocked: ticket.extra?.itemLocked,
    parentId: parentId || ticket.parentId,
    parentType: parentType || ticket.extra?.parentType,
    isUrgent: extra.isUrgent,
    isDirect,
    isCollect: extra.isCollect,
    isSell: extra.isSell,
    transDate: ticket.transDate,
    arriveDate: extra.arriveDate,
    arrivePeriod: extra.arrivePeriod,
    ticketNo: ticket.ticketNo,
    dealerId: ticket.toLocationId,
    dealerName: ticket.toLocationName,
    dealerCode: extra.dealerCode,
    dealerEIN: extra.dealerEIN,
    dealerAddress: extra.dealerAddress,
    recipientId: extra.recipientId,
    recipientName: extra.recipientName,
    recipientContact: extra.recipientContact,
    recipientAddress: extra.recipientAddress,
    recipientEmail: extra.recipientEmail,
    recipientPhone: extra.recipientPhone,
    recipientCellphone: extra.recipientCellphone,
    receiptNo: extra.receiptNo,
    billingId: extra.billingId,
    billingName: extra.billingName,
    shippingId: extra.shippingId,
    shippingName: extra.shippingName,
    trackingNo: extra.trackingNo,
    // warehouseId,
    // warehouseName,
    warehouseGroupId: extra.warehouseGroupId,
    warehouseGroupName: extra.warehouseGroupName,
    salesId: extra.salesId,
    salesName: extra.salesName,
    backupId: extra.backupId,
    backupName: extra.backupName,
    techId: extra.techId,
    techName: extra.techName,
    memo: extra.memo,
    warehouseMemo: extra.warehouseMemo,
    attach: extra.attach,
    handleType: extra.handleType,
    handleNo: extra.handleNo,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems,
    oldTicketItems,
    recipients,
    images: extra.images,
  }
}

async function onDealerChange(state, app, session, message, dealerId) {
  const { dealers, billings } = state
  // const ticketItems = cloneDeep(state.ticketItems)
  const dealer = getLocation(dealers, dealerId)
  const extra = dealer.extra || {}

  // const warehouseBalances = await getBalances(
  //   app,
  //   session,
  //   ticketItems,
  //   state.oldTicketItems,
  //   'WAREHOUSE',
  //   ticketItems.map((item) => item.srcLocationId),
  // )
  // setBalances(ticketItems, warehouseBalances, 'warehouseBalance')

  // if (isDirect) {
  //   const balances = await getBalances(
  //     app,
  //     session,
  //     ticketItems,
  //     state.oldTicketItems,
  //     'ALL',
  //     null,
  //   )
  //   setBalances(ticketItems, balances)
  // } else {
  //   const deptBalances = await getBalances(
  //     app,
  //     session,
  //     ticketItems,
  //     state.oldTicketItems,
  //     'DEALER_ORG',
  //     dealer.parentId,
  //   )
  //   setBalances(ticketItems, deptBalances, 'deptBalance')
  // }

  const locationInput = { parentId: dealerId, type: ['STORAGE'] }
  const recipients = await fetchLocations(app, session, locationInput)
  const oldBackupId = state.backupId || []
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))

  return {
    isSell: dealer?.extra.type === 'DEALER_STORAGE',
    dealerCode: dealer?.extra.code || '',
    dealerEIN: dealer?.extra.ein || '',
    dealerAddress: dealer?.extra.address || '',
    backupId: backupId || oldBackupId,
    billingId: getSelectOption(billings, dealer?.extra.billingId),
    recipients: getRecipients(recipients, message),
    // ticketItems,
  }
}

// async function onIsDirectChange(state, app, session, isDirect) {
//   const { dealers, dealerId } = state
//   const ticketItems = cloneDeep(state.ticketItems)
//   const dealer = getLocation(dealers, dealerId.value)

//   const warehouseBalances = await getBalances(
//     app,
//     session,
//     ticketItems,
//     state.oldTicketItems,
//     'WAREHOUSE',
//     ticketItems.map((item) => item.srcLocationId),
//   )
//   setBalances(ticketItems, warehouseBalances, 'warehouseBalance')

//   if (isDirect) {
//     const balances = await getBalances(
//       app,
//       session,
//       ticketItems,
//       state.oldTicketItems,
//       'ALL',
//       null,
//     )
//     setBalances(ticketItems, balances)
//   } else {
//     const deptBalances = await getBalances(
//       app,
//       session,
//       ticketItems,
//       state.oldTicketItems,
//       'DEALER_ORG',
//       dealer.parentId,
//     )
//     setBalances(ticketItems, deptBalances, 'deptBalance')
//   }

//   return { ticketItems }
// }

async function addDispatch(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const method = state.parentId ? 'addDeferDispatchTicket' : 'addDispatchTicket'
  const query = `
    mutation($input: TicketInput!) {
      ${method}(input: $input)
    }
  `
  return request({ query, variables }, { session, app, tag: state.tag })
}

async function editDispatch(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editDispatchTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app, tag: state.tag })
}

async function confirmDispatch({ session, app, id, hash }) {
  const variables = { id, input: { hash } }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmDispatchTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, parentId, hash, oldTicketItems, attach } = state
  const isFile = attach instanceof File
  const ticketItems = state.ticketItems.map((item) => {
    const { extra } = item
    if (parentId && !extra.parentItemId) extra.parentItemId = item.id
    return {
      id: item.id,
      productVariantId: item.productVariantId,
      srcLocationId: item.srcLocationId,
      quantity: parseInt(item.quantity),
      price: parseFloat(item.price),
      isGift: item.isGift,
      extra,
    }
  })
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.srcLocationId !== newItem.srcLocationId) return false
    if (item.quantity !== newItem.quantity) return false
    if (item.price !== newItem.price) return false
    if (item.isGift !== newItem.isGift) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)
  const backupId = state.backupId || []
  const images = state.images.map((item) => item.name || item)

  return {
    id,
    parentId,
    hash,
    ticketNo: state.ticketNo,
    transDate: state.transDate,
    // srcLocationId: state.warehouseId.value,
    toLocationId: state.dealerId.value,
    extra: {
      parentType: state.parentType,
      isUrgent: state.isUrgent,
      isDirect: state.isDirect,
      isCollect: state.isCollect,
      isSell: state.isSell,
      arriveDate: showDate(state.arriveDate),
      arrivePeriod: state.arrivePeriod.value,
      dealerCode: state.dealerCode,
      dealerEIN: state.dealerEIN,
      dealerAddress: state.dealerAddress,
      recipientId: state.recipientId.value,
      recipientName: state.recipientId.label,
      recipientContact: state.recipientContact,
      recipientAddress: state.recipientAddress,
      recipientEmail: state.recipientEmail,
      recipientPhone: state.recipientPhone,
      recipientCellphone: state.recipientCellphone,
      customerId: state.customerId,
      receiptNo: state.receiptNo,
      warehouseGroupId: state.warehouseGroupId.value,
      warehouseGroupName: state.warehouseGroupId.label,
      billingId: state.billingId.value,
      billingName: state.billingId.label,
      shippingId: state.shippingId?.value,
      shippingName: state.shippingId?.label,
      trackingNo: state.trackingNo,
      salesId: state.salesId.map((item) => item.value),
      salesName: state.salesId.map((item) => item.label),
      backupId: backupId.map((item) => item.value),
      backupName: backupId.map((item) => item.label),
      techId: state.techId.value,
      techName: state.techId.label,
      memo: state.memo,
      warehouseMemo: state.warehouseMemo,
      handleType: state.handleType?.value,
      handleNo: state.handleNo,
      attach: isFile ? attach.name : attach,
      sync: state.sync,
      images,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function updateAttach(app, session, state, id) {
  const { attachToAdd, attachToDel } = state

  const result = await Promise.all([
    attachToDel ? deleteFile(app, session, id, attachToDel) : true,
    attachToAdd ? addFile(app, session, id, attachToAdd) : true,
  ])
  return !result.some((ok) => !ok)
}

async function addFile(app, session, id, file) {
  const variables = { id, filename: file.name, contentType: file.type }
  const query = `
    mutation($id: ID!, $filename: String!, $contentType: String!) {
      addFile(id: $id, filename: $filename, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addFile
  uploadFile({ url, fields, file })
  return ok
}

async function deleteFile(app, session, id, file) {
  const variables = { id, filename: file }
  const query = `
    mutation($id: ID!, $filename: String!) {
      deleteFile(id: $id, filename: $filename) 
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
