import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { useIntl } from 'react-intl'
import ReactSelect from 'react-select'
import { FormField } from 'components/form'

const styles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    borderColor: isFocused ? '#9c27b0' : styles.borderColor,
    boxShadow: 'none',
    '&:hover': {
      borderColor: isFocused ? '#9c27b0' : styles.borderColor,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#9c27b0'
      : isFocused
      ? '#efefef'
      : null,
    color: isDisabled ? '#d0d0d0' : isSelected ? '#fff' : '#000',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? '#9c27b0' : '#b761c5'),
      color: isDisabled ? '#d0d0d0' : '#fff',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '5.5px',
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: '1px 0',
    lineHeight: 1,
  }),
}

export default forwardRef(
  (
    {
      show = true,
      id,
      label,
      placeholder,
      required,
      errMsg,
      options,
      value,
      fieldProps,
      ...props
    },
    ref,
  ) => {
    if (!show) return null

    const { formatMessage: message } = useIntl()
    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current && inputRef.current.focus(),
    }))

    const customFilter = ({ data }, searchText) => {
      return data.label.toLowerCase().includes(searchText.toLowerCase())
    }

    return (
      <FormField
        id={id}
        label={label}
        required={required}
        errMsg={errMsg}
        {...fieldProps}
      >
        <ReactSelect
          ref={inputRef}
          isClearable
          placeholder={placeholder ? message({ id: placeholder }) : ''}
          options={options}
          filterOption={customFilter}
          styles={styles}
          value={!value || Object.keys(value).length === 0 ? null : value}
          {...props}
        />
      </FormField>
    )
  },
)
