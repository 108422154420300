import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Flex } from 'reflexbox'
import { Modal, Divider, Image, Button } from 'components/core'
import { ImageThumb, DraggableBox } from 'components/file'
import { urlencode } from 'utilities/app'

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      file.preview = reader.result
      file.alt = ''
      resolve(file)
    }
  })
}

export default ({
  baseUrl,
  fallbackBaseUrl,
  value,
  thumb,
  onUpload,
  onDelete,
  onError,
  onDrag,
  maxSize = 1024000,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const files = await Promise.all(
        acceptedFiles.map((file) => readFile(file)),
      )
      await onUpload(files)
    },
    onDropRejected: (files) => {
      if (onError) {
        const errMsg = files.map(({ file, errors }) => {
          console.log(errors)
          const { code } = errors[0]
          const values = {
            filename: file.name,
            maxSize: Math.round(maxSize / 1000000),
          }
          return { id: `error.${code}`, values }
        })
        onError(errMsg)
      }
    },
    accept: 'image/*',
    maxSize,
    noDrag: true,
  })
  const onImageDrag = useCallback(
    (dragIndex, hoverIndex) => {
      const newImages = [...value]
      newImages.splice(dragIndex, 1)
      newImages.splice(hoverIndex, 0, value[dragIndex])
      onDrag(newImages)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  const renderItem = () =>
    value.map((item, index) => {
      if (thumb) return thumb(item, index)

      const src = item.preview ? item.preview : baseUrl + urlencode(item)
      const fallbackSrc = item.preview ? '' : fallbackBaseUrl + item
      return (
        <DraggableBox
          key={index}
          type="THUMB"
          index={index}
          previewImage={src}
          onDrag={onImageDrag}
        >
          <ImageThumb
            src={src}
            fallbackSrc={fallbackSrc}
            alt={item.alt}
            onView={() => {
              setPreviewOpen(true)
              setPreviewImage({ src, fallbackSrc, alt: item.alt })
            }}
            onDelete={() => onDelete(item, index)}
          />
        </DraggableBox>
      )
    })

  const renderEmpty = () => (
    <Flex justifyContent="center" alignItems="center" width={1} color="dark.0">
      Click to select files
    </Flex>
  )
  return (
    <>
      <Flex
        p={2}
        mt={3}
        minHeight="8rem"
        flexWrap="wrap"
        alignContent="center"
        sx={{
          borderColor: isDragActive ? 'accent.1' : 'light.3',
          borderWidth: '2px',
          borderRadius: '3px',
          borderStyle: 'dashed',
          outline: 'none',
          transition: 'border .24s ease-in-out',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {value.length > 0 ? renderItem() : renderEmpty()}
      </Flex>
      <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <Image {...previewImage} maxHeight="calc(100vh - 128px)" />
        <Divider bg="light.3" />
        <Flex justifyContent="flex-end" alignItems="center" mt={3}>
          <Button
            variant="secondary"
            text="btn.close"
            onClick={() => setPreviewOpen(false)}
          />
        </Flex>
      </Modal>
    </>
  )
}
