import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/salesDeptModal'
import { Flex } from 'reflexbox'
import { Modal, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value))
  const form = fields({ state, setState })
  const handler = handlers({ state, setState, app, session, action })

  useEffect(() => {
    setState(initialState(value))
  }, [value])

  return (
    <Modal
      title="salesDept.title.add"
      open={open}
      onClose={action.handleClose}
      width={300}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.name}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button type="submit" variant="primary" text="btn.submit" />
      </Flex>
    </Modal>
  )
}
