import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/reportStaffDrawer'
import { Drawer, Table } from 'components/core'
import { initialPage } from 'utilities/pagination'

export default ({ value, open, action }) => {
  const { showAmount = false, showQuantity = false } = value || {}
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad } = handlers({
    session,
    app,
    value,
    setState,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      width={[1, '460px']}
      title={`report.title.history`}
      open={open}
      onClose={action.handleClose}
    >
      <Table
        columns={columns({ showAmount, showQuantity })}
        rows={state}
        pagination={pagination}
        onChange={handleLoad}
      />
    </Drawer>
  )
}
