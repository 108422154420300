import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Button, Definition, Table } from 'components/core'
import { DateInput, NumberInput, Select, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
} from 'utilities/form'
import { MdDelete } from 'react-icons/md'
import { fetchBalance } from 'actions/inventory'
import { getProductOption, getProductOptions } from 'actions/product'
import { checkBatchItemQuantity, getAvailableBalance } from 'actions/ticket'

export const initialState = (value = {}, data) => ({
  id: value.id || '',
  index: value.index,
  productVariantName: value.productVariantName || '',
  balance: value.balance || 0,
  warehouses: data.warehouses,
  batchItems: value.extra?.batchItems || [],
  ...initializeState({
    productVariantId: getProductOption(data.products, value.productVariantId),
    quantity: value.quantity || 0,
  }),
})

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, data, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)
  const onBatchItemChange = (index, key) => (value) => {
    const batchItems = [...state.batchItems]
    const batchItem = batchItems[index]
    batchItem[key] = value
    batchItems.splice(index, 1, batchItem)
    setState({ ...state, batchItems })
  }

  return {
    productVariantId: state.id ? (
      <Definition
        direction="row"
        label="product.field.spu"
        value={state.productVariantName}
      />
    ) : (
      <Select
        id="productVariantId"
        label="product.field.spu"
        placeholder="product.field.spu"
        isClearable={false}
        options={getProductOptions(
          data.products,
          data.ticketItems,
          state.productVariantId?.value,
        )}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId', ({ value }) => {
          return getBalance(app, session, data, value)
        })}
        errMsg={state.__error__.productVariantId}
      />
    ),
    balance: (
      <Definition
        direction="row"
        label="inventory.field.balance"
        value={state.balance}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        max={getAvailableBalance({
          ticketItems: data.ticketItems,
          oldTicketItems: data.oldTicketItems,
          productVariantId: state.productVariantId?.value,
          balance: state.balance,
          index: state.index,
        })}
        label="transferOut.field.quantity"
        placeholder="transferOut.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    batchItems: (
      <Table
        columns={[
          {
            id: 'batchNo',
            label: 'transferOut.field.batchNo',
            render: ({ row, index }) => (
              <TextInput
                fieldProps={{ m: 0 }}
                value={row.batchNo}
                onChange={onBatchItemChange(index, 'batchNo')}
              />
            ),
          },
          {
            id: 'expireAt',
            label: 'transferOut.field.expireAt',
            render: ({ row, index }) => (
              <DateInput
                fieldProps={{ m: 0 }}
                value={row.expireAt}
                onChange={onBatchItemChange(index, 'expireAt')}
              />
            ),
          },
          {
            id: 'quantity',
            label: 'transferOut.field.quantity',
            render: ({ row, index }) => (
              <NumberInput
                fieldProps={{ m: 0 }}
                min="1"
                value={row.quantity}
                onChange={onBatchItemChange(index, 'quantity')}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const batchItems = [...state.batchItems]
                  batchItems.splice(index, 1)
                  setState({ ...state, batchItems })
                }}
              />
            ),
          },
        ]}
        rows={state.batchItems}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { productVariantId, quantity, batchItems } = state
    action.addItem({
      index: state.index,
      id: state.id,
      productVariantId: productVariantId.value,
      productVariantName: productVariantId.label,
      quantity,
      balance: state.balance,
      extra: { batchItems },
    })
    action.handleClose()
  },
  addBatch: () => {
    const batchItems = cloneDeep(state.batchItems)
    batchItems.push({ batchNo: '', expireAt: '', quantity: 1 })
    setState({ ...state, batchItems })
  },
  enableSubmit: () => {
    const { quantity, batchItems } = state
    const validBatchItems = !checkBatchItemQuantity(quantity, batchItems)
    const availableBalance = getAvailableBalance({
      ticketItems: data.ticketItems,
      oldTicketItems: data.oldTicketItems,
      productVariantId: state.productVariantId?.value,
      balance: state.balance,
      index: state.index,
    })
    return quantity > 0 && availableBalance >= quantity && validBatchItems
  },
})

async function getBalance(app, session, data, productVariantId) {
  const balance = await fetchBalance({
    app,
    session,
    locationType: 'WAREHOUSE',
    locationId: data.fromLocationId,
    productVariantId,
    oldTicketItems: data.oldTicketItems,
  })
  return { balance }
}
