import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'
import {
  initializeState,
  getSelectOption,
  getMultiSelectOption,
  getSelectOptions,
  getDate,
  validateForm,
  showDate,
  renderDefinition,
  renderDateInput,
  renderSelectInput,
  renderTextInput,
  renderAddressInput,
  renderTable,
  renderTextAreaInput,
  renderPhoneInput,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getLocation, parseRecipient, fetchLocations } from 'actions/location'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import {
  getAvailableBalance,
  getRecipients,
  handleDelete,
  setBalances,
} from 'actions/ticket'
import { Button, Link, StatusChip } from 'components/core'
import { Select, NumberInput } from 'components/form'
import { filterLocations } from 'utilities/permission'
import { getProductOptions } from 'actions/product'
import { getStatusColor } from 'constants/status'
import { MdDelete } from 'react-icons/md'

const moduleName = 'lend'

export const initialState = (value = {}, message) => {
  const recipients = getRecipients(value.recipients, message)
  const { ticketItems, returnItems } = groupTicketItems(value.ticketItems)
  const oldTicketItems = cloneDeep(ticketItems)

  return {
    isReady: value.isReady || false,
    id: value.id || '',
    locked: value.locked || false,
    status: value.status || 'ACTION_PENDING',
    bypass: value.bypass || false,
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: oldTicketItems || [],
    ticketItems: ticketItems || [],
    inputValues: getInitialInput(),
    editValues: {},
    returnItems: returnItems || [],
    dispatchTickets: value.dispatchTickets || [],
    sellTickets: value.sellTickets || [],
    dealers: value.dealers,
    dealerName: value.toLocationName || '',
    recipients,
    recipientName: value.extra?.recipientName || '',
    warehouses: value.warehouses,
    warehouseName: value.fromLocationName || '',
    shippings: value.shippings,
    shippingName: value.extra?.shippingName || '',
    sales: getSelectOptions(value.sales),
    salesName: value.extra?.salesName || [],
    products: value.products || [],
    allProducts: value.allProducts || [],
    ...initializeState({
      transDate: getDate(value.transDate),
      warehouseId: getSelectOption(
        value.warehouses,
        value.fromLocationId,
        'id',
        'name',
      ),
      dealerId: getSelectOption(
        value.dealers,
        value.toLocationId,
        'id',
        'name',
      ),
      recipientId: getSelectOption(
        recipients,
        value.extra?.recipientId,
        'id',
        'name',
        false,
      ),
      recipientContact: value.extra?.recipientContact || '',
      recipientAddress: value.extra?.recipientAddress || {},
      recipientPhone: value.extra?.recipientPhone || '',
      recipientCellphone: value.extra?.recipientCellphone || '',
      shippingId: getSelectOption(value.shippings, value.extra?.shippingId),
      salesId: getMultiSelectOption(
        value.sales,
        value.extra?.salesId,
        'id',
        'name',
      ),
      memo: value.extra?.memo || '',
    }),
  }
}

function getInitialInput() {
  return {
    productVariantId: '',
    productVariantName: '',
    balance: 0,
    quantity: 0,
  }
}

const validation = {
  dealerId: [{ type: 'required', message: 'error.required' }],
  warehouseId: [{ type: 'required', message: 'error.required' }],
  recipientContact: [{ type: 'required', message: 'error.required' }],
  recipientAddress: [
    { type: 'required', message: 'error.required' },
    { type: 'address', message: 'error.invalidAddress' },
  ],
  salesId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({
  profile,
  format,
  app,
  session,
  state,
  setState,
  message,
  bypass,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const phoneInput = (inputProps) =>
    renderPhoneInput({ ...commonProps, ...inputProps })
  const addressInput = (inputProps) =>
    renderAddressInput({ ...commonProps, ...inputProps, message })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    transDate: dateInput({ id: 'transDate', role: 'lockLendTicket' }),
    shipping: selectInput({
      id: 'shippingId',
      valKey: 'shippingName',
      isClearable: false,
      options: state.shippings,
    }),
    warehouse: selectInput({
      id: 'warehouseId',
      valKey: 'warehouseName',
      isClearable: false,
      options: getSelectOptions(state.warehouses),
      callback: ({ value }) => onWarehouseChange(state, app, session, value),
    }),
    dealer: selectInput({
      id: 'dealerId',
      valKey: 'dealerName',
      isClearable: false,
      options: getSelectOptions(state.dealers),
      callback: ({ value }) =>
        onDealerChange(state, app, session, message, value),
    }),
    recipient: selectInput({
      id: 'recipientId',
      valKey: 'recipientName',
      isClearable: false,
      options: getSelectOptions(state.recipients),
      callback: (item) => {
        const recipient = getLocation(state.recipients, item?.value)
        return parseRecipient(recipient, state)
      },
    }),
    recipientContact: textInput({ id: 'recipientContact' }),
    recipientAddress: addressInput({ id: 'recipientAddress' }),
    recipientPhone: phoneInput({ id: 'recipientPhone' }),
    recipientCellphone: phoneInput({
      id: 'recipientCellphone',
      type: 'cellphone',
    }),
    sales: selectInput({
      id: 'salesId',
      valKey: 'salesName',
      isClearable: false,
      isMulti: true,
      options: state.sales,
    }),
    memo: textAreaInput({ id: 'memo' }),
    product: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'lend.field.spu',
          renderInput: () => (
            <Select
              isClearable={false}
              options={getProductOptions(state.products)}
              onChange={async ({ value }) => {
                const balance = await fetchBalance({
                  app,
                  session,
                  locationType: 'WAREHOUSE',
                  locationId: state.warehouseId.value,
                  productVariantId: value,
                  oldTicketItems: state.oldTicketItems,
                })
                return { balance, quantity: 1 }
              }}
            />
          ),
          getValue: (row) =>
            getSelectOption(state.products, row.productVariantId, 'id', 'name'),
        },
        {
          id: 'sku',
          label: 'lend.field.sku',
          show: profile === 'view',
        },
        {
          id: 'balance',
          label: 'lend.field.balance',
          align: 'right',
          format: ['html'],
          show: profile !== 'view',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          width: profile === 'view' ? 'auto' : '96px',
          align: 'right',
          renderInput: ({ row, index }) => {
            const balance = getAvailableBalance({
              ticketItems: state.ticketItems,
              oldTicketItems: state.oldTicketItems,
              productVariantId: row.productVariantName.value,
              balance: row.balance,
              index,
            })
            const values = index === -1 ? state.inputValues : state.editValues
            const quantity = parseInt(values.quantity)
            return (
              <NumberInput
                min={0}
                max={bypass ? Infinity : balance}
                hasError={quantity < 0 || quantity > balance}
              />
            )
          },
        },
        {
          id: 'returnQuantity',
          label: 'lend.field.returnQuantity',
          align: 'right',
          show: profile === 'view',
          render: ({ row }) => Math.abs(row.extra?.returnQuantity || 0),
        },
        {
          id: 'sellQuantity',
          label: 'lend.field.sellQuantity',
          align: 'right',
          show: profile === 'view',
          render: ({ row }) => Math.abs(row.extra?.sellQuantity || 0),
        },
        {
          id: 'remainQuantity',
          label: 'lend.field.remainQuantity',
          align: 'right',
          show: profile === 'view',
          render: ({ row }) => row.balance,
        },
      ],
      showAddInput: profile !== 'view',
      showDeleteIcon: profile !== 'view',
      inputValues: state.inputValues,
      editValues: state.editValues,
      onInputChange: (value) => setState({ ...state, inputValues: value }),
      onEditChange: (value) => setState({ ...state, editValues: value }),
      onAdd: ({ row }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const availableBalance = getAvailableBalance({
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
          productVariantId: productVariantName.value,
          balance: row.balance,
        })
        if (quantity <= 0) return
        if (quantity > availableBalance && !bypass) return

        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.push({
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        })
        const inputValues = getInitialInput()
        setState({ ...state, ticketItems, inputValues })
      },
      onEdit: ({ row, index }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const availableBalance = getAvailableBalance({
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
          productVariantId: productVariantName.value,
          balance: row.balance,
          index,
        })
        if (quantity <= 0) return
        if (quantity > availableBalance && !bypass) return

        const ticketItems = cloneDeep(state.ticketItems)
        const ticketItem = {
          ...ticketItems[index],
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        }
        ticketItems.splice(index, 1, ticketItem)
        setState({ ...state, ticketItems })
        return true
      },
      onDelete: ({ index }) => {
        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.splice(index, 1)
        setState({ ...state, ticketItems })
      },
    }),
    returnItems: tableInput({
      id: 'returnItems',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'type',
          label: 'field.type',
          render: ({ row }) => message({ id: `lend.type.${row.extra?.type}` }),
        },
        {
          id: 'productVariantName',
          label: 'lend.field.spu',
        },
        {
          id: 'sku',
          label: 'lend.field.sku',
        },
        {
          id: 'toLocationName',
          label: 'field.warehouse',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          align: 'right',
          render: ({ row }) => Math.abs(row.quantity),
        },
        {
          id: 'actions',
          align: 'right',
          format: ['html'],
          show: ['view'].includes(profile),
          renderHtml: ({ row, index }) => (
            <Button
              variant="icon"
              icon={<MdDelete />}
              onClick={(event) => {
                const item = {
                  open: true,
                  title: { id: 'lend.title.deleteReturn' },
                  text: { id: 'lend.message.deleteReturn' },
                  onSubmit: async () => {
                    const [ok] = await editReturn({ session, app, state, row })
                    if (!ok) return false
                    const { id } = state
                    const data = await getData({ app, session, id, profile })
                    const initState = initialState(data, message)
                    setItemBalances(app, session, initState, profile)
                    setState(initState)
                    return true
                  },
                }
                session.dispatch({ type: CONFIRM_SET, item })
              }}
            />
          ),
        },
      ],
    }),
    dispatchTickets: tableInput({
      id: 'dispatchTickets',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'id',
          label: 'field.ticketId',
          renderHtml: ({ row }) => (
            <Link variant="primaryLink" href={`/dispatch/${row.id}/view`}>
              {row.id}
            </Link>
          ),
        },
        {
          id: 'status',
          label: 'field.status',
          render: ({ row }) => (
            <StatusChip
              label={`lend.status.${row.status}`}
              color={getStatusColor(row.status)}
            />
          ),
        },
      ],
    }),
    sellTickets: tableInput({
      id: 'sellTickets',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'id',
          label: 'field.ticketId',
          renderHtml: ({ row }) => (
            <Link variant="primaryLink" href={`/sell/${row.id}/view`}>
              {row.id}
            </Link>
          ),
        },
        {
          id: 'status',
          label: 'field.status',
          renderHtml: ({ row }) => (
            <StatusChip
              label={`lend.status.${row.status}`}
              color={getStatusColor(row.status)}
            />
          ),
          render: ({ row }) => message({ id: `status.${row.status}` }),
        },
      ],
    }),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  message,
  id,
  profile,
  bypass,
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    data.bypass = bypass
    const initState = initialState(data, message)
    setItemBalances(app, session, initState, profile)
    setState(initState)
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editLend(state, app, session)
      : await addLend(state, app, session)
    if (!ok) return

    if (!id) {
      id = data.addLendTicket
    }

    if (bypass) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'save.success' },
      })
      const data = await getData({ app, session, profile })
      data.bypass = bypass
      const initState = initialState(data, message)
      setItemBalances(app, session, initState, profile)
      setState(initState)
      return
    }

    history.push(`/lend/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('lend', { session, app, id, hash })
    if (!ok) return false

    history.push('/lend')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state, message })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ state, message }) {
  const title = { id: 'lend.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const form = fields({ profile: 'view', format: 'print', state, message })
  const field = Object.values(form).filter(
    ({ id }) =>
      ![
        'ticketItems',
        'returnItems',
        'sellTickets',
        'dispatchTickets',
      ].includes(id),
  )
  const content = [
    { type: 'field', value: field },
    { type: 'subTitle', value: 'lend.section.product' },
    { type: 'list', value: form.product },
    { type: 'subTitle', value: 'lend.section.return' },
    { type: 'list', value: form.returnItems },
    { type: 'subTitle', value: 'lend.section.sell' },
    { type: 'list', value: form.sellTickets },
  ]
  return { title, header, content }
}

async function getData({ app, session, id, profile }) {
  const locationInput = { type: ['WAREHOUSE', 'DEALER', 'COMPANY'] }
  const childTicketInput = { parentId: id }
  const productInput = { status: ['ACTIVE', 'INACTIVE'] }
  const variables = { id, locationInput, childTicketInput, productInput }
  const dispatchQuery = `
    dispatchTickets(input: $childTicketInput) {
      id
      transDate
      status
    }
  `
  const sellQuery = `
    sellTickets(input: $childTicketInput) {
      id
      transDate
      status
    }
  `
  const query = `
    query($id: ID, $locationInput: LocationQueryInput, $childTicketInput: TicketQueryInput, $productInput: ProductQueryInput) {
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      staffs {
        id
        name
      }
      productVariants(input: $productInput) {
        id
        name
        sku
        status
      }
      lendTicket(id: $id) {
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      lendTicketItems(ticketId: $id) {
        id
        toLocationId
        toLocationName
        productVariantId
        productVariantName
        sku
        quantity
        balance
        transDate
        extra
      }
      ${id ? dispatchQuery : ''}
      ${id ? sellQuery : ''}
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { staffs, productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.lendTicket || {}
  const extra = ticket.extra || {}
  const warehouses = []
  const dealers = []
  const shippings = []
  let recipients = []

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'WAREHOUSE':
        warehouses.push(item)
        break
      case 'DEALER':
        dealers.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  if (profile === 'edit' && ticket.fromLocationId) {
    const locationInput = { parentId: ticket.toLocationId, type: ['STORAGE'] }
    recipients = await fetchLocations(app, session, locationInput)
  }

  return {
    isReady: profile === 'edit',
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    locked: ticket.extra?.locked,
    transDate: ticket.transDate,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    // recipientId: extra.recipientId,
    // recipientName: extra.recipientName,
    // recipientContact: extra.recipientContact,
    // recipientAddress: extra.recipientAddress,
    // recipientPhone: extra.recipientPhone,
    // recipientCellphone: extra.recipientCellphone,
    // shippingId: extra.shippingId,
    // shippingName: extra.shippingName,
    // salesId: extra.salesId,
    // salesName: extra.salesName,
    // memo: extra.memo,
    status: ticket.status,
    hash: ticket.hash,
    // oldTicketItems,
    ticketItems: data.lendTicketItems,
    // returnItems,
    dispatchTickets: data.dispatchTickets,
    sellTickets: data.sellTickets,
    warehouses,
    dealers,
    recipients,
    shippings,
    sales: staffs,
    products: productVariants.filter((item) => item.status === 'ACTIVE'),
    allProducts: productVariants,
  }
}

async function setItemBalances(app, session, data, profile) {
  if (profile !== 'edit') return

  const { ticketItems, oldTicketItems } = data
  if (ticketItems.length === 0) return

  const balances = await getBalances(
    app,
    session,
    ticketItems,
    oldTicketItems,
    getLocation(data.warehouses, data.fromLocationId),
  )
  setBalances(ticketItems, balances)
}

function groupTicketItems(lendTicketItems) {
  const ticketItems = []
  const returnItems = []
  if (!lendTicketItems) return { ticketItems, returnItems }

  lendTicketItems.forEach((item) => {
    if (item.extra?.isReturn) {
      returnItems.push(item)
    } else {
      ticketItems.push(item)
    }
  })

  return { ticketItems, returnItems }
}

async function onDealerChange(state, app, session, message, dealerId) {
  const { dealers, shippings } = state
  const dealer = getLocation(dealers, dealerId)
  const locationInput = { parentId: dealerId, type: ['STORAGE'] }
  const recipients = await fetchLocations(app, session, locationInput)

  return {
    shippingId: getSelectOption(shippings, dealer?.extra.shippingId),
    recipients: getRecipients(recipients, message),
  }
}

async function onWarehouseChange(state, app, session, warehouseId) {
  const ticketItems = [...state.ticketItems]
  const warehouse = getLocation(state.warehouses, warehouseId)
  const balances = await getBalances(
    app,
    session,
    ticketItems,
    state.oldTicketItems,
    warehouse,
  )
  setBalances(ticketItems, balances)

  return { ticketItems, inputValues: getInitialInput() }
}

async function getBalances(
  app,
  session,
  ticketItems,
  oldTicketItems,
  warehouse,
) {
  if (ticketItems.length === 0) return []

  const productVariantId = ticketItems.map((item) => item.productVariantId)
  return fetchBalances({
    app,
    session,
    locationType: 'WAREHOUSE',
    locationId: warehouse.id,
    productVariantId,
    oldTicketItems,
  })
}

async function addLend(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addLendTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editLend(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editLendTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    id,
    hash,
    transDate: state.transDate,
    fromLocationId: state.warehouseId.value,
    toLocationId: state.dealerId.value,
    extra: {
      bypass: state.bypass === 'true',
      recipientId: state.recipientId.value,
      recipientName: state.recipientId.label,
      recipientContact: state.recipientContact,
      recipientAddress: state.recipientAddress,
      recipientPhone: state.recipientPhone,
      recipientCellphone: state.recipientCellphone,
      shippingId: state.shippingId.value,
      shippingName: state.shippingId.label,
      salesId: state.salesId.map((item) => item.value),
      salesName: state.salesId.map((item) => item.label),
      memo: state.memo,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function editReturn({ session, app, state, row }) {
  const input = {
    hash: state.hash,
    extra: { isReturn: true },
    itemsToDel: [row.id],
  }
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editLendTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
