export default {
  'report.title.history': '歷史紀錄',
  'repairReport.title.list': '滿意度報告',
  'repairReport.title.view': '滿意度紀錄',
  'repairReport.field.techId': '維修人員',
  'report.totalSellQuantity': '銷貨總量',
  'report.totalSellGiftQuantity': '銷貨總量（贈品）',
  'report.totalSellAmount': '銷貨總金額',
  'report.totalSellGiftAmount': '銷貨總金額（贈品）',
}
