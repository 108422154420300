import React from 'react'
import { Drawer, Center, Button, Image } from 'components/core'

const url = process.env.REACT_APP_STATIC_URL

export default ({ value, open, action }) => {
  const { productId, images = [] } = value
  return (
    <Drawer
      width={450}
      onClose={action.handleClose}
      title={`product.title.image`}
      open={open}
    >
      {images
        .filter((item) => !!item)
        .map((item, index) => (
          <Image key={index} src={`${url}/1/${productId}/${item}`} />
        ))}
      <Center mt={3}>
        <Button
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
      </Center>
    </Drawer>
  )
}
