export default {
  'error.dispatch.missingToLocationId': '經銷商為必填欄位',
  'error.dispatch.missingWarehouseId': '倉庫為必填欄位',
  'error.dispatch.invalidImportItem': '匯入商品與延出商品不符',
  'error.ticket.invalidQuantity': '數量有誤',
  'error.srcLocationId.required': '倉庫為必填欄位',
  'error.shippingId.required': '物流為必填欄位',
  'dispatch.error.phoneRequired': '電話或手機為必填欄位',
  'dispatch.error.deferItemLocked': '延出項目未鎖定',
  'dispatch.title.list': '出貨目錄',
  'dispatch.title.txList': '天心出貨目錄',
  'dispatch.title.add': '新增出貨單',
  'dispatch.title.edit': '修改出貨單',
  'dispatch.title.view': '出貨單詳情',
  'dispatch.title.print': '出貨單 - {ticketId}',
  'dispatch.title.addItem': '新增出貨商品',
  'dispatch.title.editItem': '修改出貨商品',
  'dispatch.title.listItems': '出貨商品詳情',
  'dispatch.title.import': '匯入出貨單',
  'dispatch.title.importSerial': '匯入機號',
  'dispatch.title.deleteImportItem': '刪除匯入品項',
  'dispatch.section.product': '商品',
  'dispatch.section.basic': '基礎信息',
  'dispatch.section.importDefault': '預設欄位',
  'dispatch.section.image': '相關圖示',
  'dispatch.message.template': '出貨單匯入範本',
  'dispatch.message.serialTemplate': '產品序號匯入範本',
  'dispatch.message.deleteImportItem': '確認刪除匯入品項？',
  'dispatch.btn.txExport': '天心匯出',
  'dispatch.btn.addBatch': '新增批號',
  'dispatch.btn.switchUser': '轉換用戶 - 將訂單商品轉至指定用戶下',
  'dispatch.btn.copyRefund': '複製退貨單',
  'dispatch.placeholder.import': '請將需匯入的文件拖入此框中...',
  'dispatch.field.id': '單號',
  'dispatch.field.parentId': '關聯單號',
  'dispatch.field.isUrgent': '急件',
  'dispatch.field.isCollect': '來回件',
  'dispatch.field.isDirect': '公司直出',
  'dispatch.field.isSell': '立即銷貨',
  'dispatch.field.skipConfirmation': '出貨免審核',
  'dispatch.field.ticketId': '訂單號',
  'dispatch.field.ticketNo': '廠商單號',
  'dispatch.field.transDate': '出貨日',
  'dispatch.field.arriveDate': '到貨日',
  'dispatch.field.arrivePeriod': '到貨時段',
  'dispatch.field.receiptNo': '發票號碼',
  'dispatch.field.salesId': '業務人員',
  'dispatch.field.backupId': '帳務人員',
  'dispatch.field.techId': '安裝人員',
  'dispatch.field.memo': '業務備註',
  'dispatch.field.dealerId': '經銷商',
  'dispatch.field.dealerCode': '廠商代碼',
  'dispatch.field.dealerEIN': '統一編號',
  'dispatch.field.dealerAddress': '公司地址',
  'dispatch.field.billingId': '歸帳',
  'dispatch.field.recipientId': '收貨處',
  'dispatch.field.recipientContact': '聯絡人',
  'dispatch.field.recipientAddress': '收件地址',
  'dispatch.field.recipientEmail': 'Email',
  'dispatch.field.recipientPhone': '電話',
  'dispatch.field.recipientCellphone': '手機',
  'dispatch.field.shippingId': '物流',
  'dispatch.field.trackingNo': '物流編號',
  'dispatch.field.warehouseId': '倉庫',
  'dispatch.field.warehouseGroupId': '倉庫群',
  'dispatch.field.warehouseMemo': '倉管備註',
  'dispatch.field.spu': '商品名稱',
  'dispatch.field.sku': '品號',
  'dispatch.field.dealerProductCode': '通路編碼',
  'dispatch.field.productCode': '原廠代碼',
  'dispatch.field.barcode': '產品條碼',
  'dispatch.field.serial': '產品序號',
  'dispatch.field.isGift': '贈品',
  'dispatch.field.isDemo': '展示/福利機',
  'dispatch.field.batchNo': '批號',
  'dispatch.field.expireAt': '效期',
  'dispatch.field.warehouseBalance': '庫存數量',
  'dispatch.field.balance': '可用數量',
  'dispatch.field.deptBalance': '業務數量',
  'dispatch.field.quantity': '數量',
  'dispatch.field.batchQuantity': '鎖批數量',
  'dispatch.field.price': '金額',
  'dispatch.field.status': '狀態',
  'dispatch.field.attach': '附件',
  'dispatch.field.itemMemo': '商品備註',
  'dispatch.field.importFile': '匯入檔案',
  'dispatch.field.breakCombo': '拆解組合',
  'dispatch.field.deferId': '延出單號',
  'dispatch.field.handleType': '載具種類',
  'dispatch.field.handleNo': '載具號碼',
  'dispatch.handleType.PHONE': '手機條碼(載具)',
  'dispatch.handleType.MEMBER_ID': '會員載具',
  'dispatch.handleType.CITIZEN_ID': '自然人條碼',
  'dispatch.arrivePeriod.ALL': '不指定',
  'dispatch.arrivePeriod.AM': '早上',
  'dispatch.arrivePeriod.PM': '下午',
  'dispatch.recipient.individual': '獨立客戶',
  'dispatch.tx.PS_ID': 'PS_ID', // 單據別(固定SA)
  'dispatch.tx.PS_NO': 'PS_NO', // 單號(天心單號)
  'dispatch.tx.PS_DD': 'PS_DD', // 單據日期
  'dispatch.tx.CUS_NOv': 'CUS_NO', // 客戶代號(CI22035)
  'dispatch.tx.REM_H': 'REM_H', // 表頭備註(官網)
  'dispatch.tx.REM': 'REM', // 表身摘要
  'dispatch.tx.ITM': 'ITM', // 項次
  'dispatch.tx.DEP': 'DEP', // 部門(固定0000)
  'dispatch.tx.TAX_ID': 'TAX_ID', // 扣稅類別
  'dispatch.tx.ZHANG_ID': 'ZHANG_ID', // 立帳方式(固定1)
  'dispatch.tx.SAL_NO': 'SAL_NO', // 業務員(402)
  'dispatch.tx.VOH_ID': 'VOH_ID', // 傳票模版
  'dispatch.tx.WH': 'WH', // 倉庫(固定0000)
  'dispatch.tx.PRD_NO': 'PRD_NO', // 品號(公司品號)
  'dispatch.tx.UNIT': 'UNIT', // 單位(固定1)
  'dispatch.tx.QTY': 'QTY', // 數量
  'dispatch.tx.UP': 'UP', // 單價
  'dispatch.tx.AMT': 'AMT', // 金額
  'dispatch.tx.AMTN_NET': 'AMTN_NET', // 未稅本位幣
  'dispatch.tx.TAX': 'TAX', // 稅額
  'dispatch.tx.CARRIER_KND': 'CARRIER_KND', // 載具種類
  'dispatch.tx.CARRIERID1': 'CARRIERID1', // 載具號碼
}
