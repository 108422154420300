import { request } from 'utilities/request'
import { textFilter, dateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'

export const filters = ({ data }) => [
  {
    id: 'warehouse',
    label: 'field.warehouseDealer',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
]

export const columns = ({ session, app, state, message, action }) => [
  {
    id: 'locationName',
    label: 'field.warehouseDealer',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    format: ['html', 'excel'],
    align: 'right',
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.warehouse'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: WarehouseReportInput) {
      warehouseReportCount(input: $input)
      warehouseReports(input: $input) {
        locationName
        productVariantName
        sku
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.warehouseReports,
    pagination: getPagination(page, countPerPage, data.warehouseReportCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: WarehouseReportInput) {
      warehouseReports(input: $input) {
        locationName
        productVariantName
        sku
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.warehouseReports
}

function getDataInput({ filterValues = [], pagination }) {
  const warehouse = filterValues.find(({ id }) => id === 'warehouse')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const input = {
    locationName: warehouse?.value,
    transDate: transDate?.value,
    productVariantName: productName?.value,
    orderBy: [{ key: 'locationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
