export default {
  'report.error.invalidTicketType': '{ticketId}: 單據類型有誤',
  'report.transDate.required': '請指定搜尋日期',
  'report.title.repairList': '維修紀錄',
  'report.title.refund': '退貨統計表',
  'report.title.refund2': '退貨統計表[新]',
  'report.title.update': '更新報表',
  'report.title.restockList': '商品補貨清單',
  'report.title.collectList': '商品允收檢核表',
  'report.btn.update': '更新報表',
  'report.message.update':
    '預設報表統計截至日期為昨日，更新報表會將今日新增及修改之訂單加入統計。更新數據所需時長依當日新增及修改之訂單筆數而異。一般完成更新需1至2分鐘。',
  'report.field.sellQuantity': '銷貨量',
  'report.field.partName': '零件名稱',
  'report.field.partSku': '零件代碼',
  'report.field.adjustQuantity': '調整量',
  'report.field.repairDate': '約排日期',
  'report.field.ticketDate': '訂單日期',
  'report.field.repairNo': '維修單號',
  'report.field.tech': '維修人員',
  'report.field.receiptNo': '發票號碼',
  'report.field.receiptDate': '發票日期',
  'report.field.phone': '電話',
  'report.field.cellphone': '手機',
  'report.field.address': '地址',
  'report.field.recipientContact': '客戶姓名',
  'report.field.symptom': '報修內容',
  'report.field.solution': '維修內容',
  'report.field.productName': '商品名稱',
  'report.field.productModel': '產品機號',
  'report.field.productSerialNo': 'ART',
  'report.field.productMemo': '產品備註',
  'report.field.dealer': '經銷商',
  'report.field.recieveTotal': '退貨量',
  'report.field.returnTotal': '轉倉量',
  'report.field.discardNoRecycleTotal': '報廢不回收',
  'report.field.discardTotal': '破包報廢',
  'report.field.discardExpiredTotal': '過期報廢',
  'report.field.discardOpenedTotal': '業務轉贈',
  'report.field.receiveLessTotal': '短收量',
  'report.field.refundAdjustQuantity': '溢收量',
  'report.title.daily': '當日單據',
  'report.field.restockBalance': '即時庫存',
  'report.field.restockSoldQuantity': '當月銷量',
  'report.field.restockMinQuantity': '安全庫存',
  'report.field.restockReplenish': '補貨',
  'report.field.restockQuantity': '須補貨量',
  'report.field.restockBalanceDate': '即時庫存日期',
  'report.field.restockSoldDate': '銷量日期',
  'report.field.warehouse': '倉庫',
  'report.field.expireDate': '到期日',
  'report.field.latestCollectDate': '最晚入庫日',
  'report.field.balance': '庫存數量',
}
