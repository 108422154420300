export default {
  'error.productComboCode.duplicate': '虛擬組合已存在',
  'error.productComboCode.invalidProduct': '組合商品有誤',
  'productComboCode.title': '虛擬組合',
  'productComboCode.title.list': '虛擬組合目錄',
  'productComboCode.title.add': '新增虛擬組合',
  'productComboCode.title.edit': '更改虛擬組合',
  'productComboCode.title.view': '虛擬組合詳情',
  'productComboCode.title.delete': '刪除虛擬組合',
  'productComboCode.field.code': '品號',
  'productComboCode.field.product': '商品',
  'productComboCode.field.isGift': '贈品',
  'productComboCode.field.dealer': '經銷商',
  'productComboCode.field.warehouseId': '出貨倉庫',
  'productComboCode.field.batchNo': '批號',
  'productComboCode.field.expireAt': '效期',
  'productComboCode.btn.addCombo': '新增組合',
  'productComboCode.message.delete': '確認刪除虛擬組合？',
  'productComboCode.vendor.pxmart': '全聯福利中心',
  'productComboCode.vendor.carrefour': '家樂福',
}
