import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  handleTextChange,
  validateForm,
  getSelectOption,
  handleSelectChange,
  getSelectOptions,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Flex } from 'reflexbox'
import { Select, Switch, TextInput } from 'components/form'
import { renderMessage } from 'utilities/app'
import { Button, Table } from 'components/core'
import { MdDelete } from 'react-icons/md'

export const initialState = (value = {}) => ({
  shippings: value.shippings || [],
  warehouseGroups: value.warehouseGroups || [],
  warehouses: value.warehouses || [],
  warehouseList: value.warehouseList || [],
  techs: value.techs || [],
  techList: value.techList || [],
  ...initializeState({
    host: value.host || '',
    username: value.username || '',
    password: value.password || '',
    dispatchSyncTime: value.dispatchSyncTime || '09:00',
    refundSyncTime: value.refundSyncTime || '09:00',
    warehouseGroupId: getSelectOption(
      value.warehouseGroups,
      value.warehouseGroupId,
    ),
    shippingId: getSelectOption(value.shippings, value.shippingId),
    isActive: value.isActive || false,
  }),
})

const validation = {
  host: [{ type: 'required', message: 'error.required' }],
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
  dispatchSyncTime: [{ type: 'required', message: 'error.required' }],
  refundSyncTime: [{ type: 'required', message: 'error.required' }],
  warehouseGroupId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    host: (
      <TextInput
        id="host"
        label="sy.field.host"
        placeholder="sy.field.host"
        value={state.host}
        onChange={onTextChange('host')}
        errMsg={state.__error__.host}
      />
    ),
    username: (
      <TextInput
        id="username"
        label="sy.field.username"
        placeholder="sy.field.username"
        value={state.username}
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        id="password"
        label="sy.field.password"
        placeholder="sy.field.password"
        value={state.password}
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    dispatchSyncTime: (
      <TextInput
        type="time"
        id="dispatchSyncTime"
        label="sy.field.dispatchSyncTime"
        placeholder="sy.field.dispatchSyncTime"
        value={state.dispatchSyncTime}
        onChange={onTextChange('dispatchSyncTime')}
        errMsg={state.__error__.dispatchSyncTime}
      />
    ),
    refundSyncTime: (
      <TextInput
        type="time"
        id="refundSyncTime"
        label="sy.field.refundSyncTime"
        placeholder="sy.field.refundSyncTime"
        value={state.refundSyncTime}
        onChange={onTextChange('refundSyncTime')}
        errMsg={state.__error__.refundSyncTime}
      />
    ),
    warehouseGroupId: (
      <Select
        label="sy.field.dispatchWarehouse"
        placeholder="sy.field.dispatchWarehouse"
        options={state.warehouseGroups}
        isClearable={false}
        value={state.warehouseGroupId}
        onChange={onSelectChange('warehouseGroupId')}
        errMsg={state.__error__.warehouseGroupId}
      />
    ),
    shippingId: (
      <Select
        label="sy.field.shipping"
        placeholder="sy.field.shipping"
        options={state.shippings}
        isClearable={false}
        value={state.shippingId}
        onChange={onSelectChange('shippingId')}
        errMsg={state.__error__.shippingId}
      />
    ),
    isActive: (
      <Flex alignItems="center">
        {renderMessage('plugin.field.isActive', { flex: 1 })}
        <Switch
          checked={state.isActive}
          onClick={() => setState({ ...state, isActive: !state.isActive })}
        />
      </Flex>
    ),
    warehouseList: (
      <Table
        columns={[
          {
            id: 'label',
            label: 'sy.field.syWarehouseId',
          },
          {
            id: 'locationName',
            label: 'plugin.field.warehouse',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const warehouseList = [...state.warehouseList]
                  warehouseList.splice(index, 1)
                  setState({ ...state, warehouseList })
                }}
              />
            ),
          },
        ]}
        rows={state.warehouseList}
      />
    ),
    techList: (
      <Table
        columns={[
          {
            id: 'label',
            label: 'sy.field.techId',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const techList = [...state.techList]
                  techList.splice(index, 1)
                  setState({ ...state, techList })
                }}
              />
            ),
          },
        ]}
        rows={state.techList}
      />
    ),
  }
}

export const handlers = ({ session, app, state, setState }) => ({
  handleLoad: async () => {
    const data = await getData({ app, session })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await updatePlugin({ session, app, state })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleDispatch: async () => {
    const [ok] = await uploadDispatch({ session, app, state })
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'sy.message.dispatchUploadSuccess' },
    })
    return true
  },
  handleRefund: async () => {
    const [ok] = await uploadRefund({ session, app, state })
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'sy.message.dispatchRefundSuccess' },
    })
    return true
  },
  handleTransport: async () => {
    const [ok] = await uploadTransport({ session, app, state })
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'sy.message.dispatchRefundSuccess' },
    })
    return true
  },
  addWarehouse: (value) => {
    const warehouseList = [...state.warehouseList, value]
    setState({ ...state, warehouseList })
  },
  addTech: (value) => {
    const techList = [...state.techList, value]
    setState({ ...state, techList })
  },
})

async function getData({ app, session }) {
  const variables = {
    staffInput: { type: ['TECHNICIAN'] },
    locationInput: { type: ['WAREHOUSE_GROUP', 'WAREHOUSE', 'COMPANY'] },
  }
  const query = `
    query($locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      merchant {
        extra
      }
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { merchant, locations, staffs } = data
  const { plugins } = merchant.extra
  const shippings = []
  const warehouseGroups = []
  const warehouses = []
  const warehouseList = []

  locations.forEach(({ id, name, type, extra }) => {
    const { sy } = extra

    switch (type) {
      case 'WAREHOUSE_GROUP':
        warehouseGroups.push({ value: id, label: name })
        break
      case 'WAREHOUSE':
        warehouses.push({ value: id, label: name })
        if (sy && sy.code) {
          warehouseList.push({
            locationId: id,
            locationName: name,
            code: sy.code,
            label: sy.label,
          })
        }
        break
      case 'COMPANY':
        if (extra.type === 'SHIPPING') {
          shippings.push({ value: id, label: name })
        }
        break
      default:
    }
  })

  const techList = staffs.filter((item) => item.extra?.isSyTech)
  return {
    ...plugins.find((item) => item.id === 'sy'),
    shippings,
    warehouseGroups,
    warehouses,
    warehouseList,
    techs: getSelectOptions(staffs),
    techList: getSelectOptions(techList),
  }
}

async function updatePlugin({ session, app, state }) {
  const locations = state.warehouseList.map((item) => ({
    locationId: item.locationId,
    code: item.code,
    label: item.label,
  }))
  const staffs = state.techList.map((item) => item.value)
  const input = {
    id: 'sy',
    host: state.host,
    username: state.username,
    password: state.password,
    dispatchSyncTime: state.dispatchSyncTime,
    refundSyncTime: state.refundSyncTime,
    warehouseGroupId: state.warehouseGroupId.value,
    shippingId: state.shippingId.value,
    notifyEmails: [
      'Asko_wh@sunyoung.idv.tw',
      'SY03WH006@sunyoung.idv.tw',
      'wanwan_li@sunyoung.idv.tw',
      'peteryeh@sunyoung.idv.tw',
      'sy03op@sunyoung.idv.tw',
      'suke.shou@gmail.com',
    ],
    locations,
    staffs,
    isActive: state.isActive,
  }
  const variables = { input }
  const query = `
    mutation($input: SyInput!) {
      editSyConfig(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function uploadDispatch({ session, app }) {
  const query = `
    mutation {
      uploadSyDispatch
    }
  `
  return request({ query }, { session, app })
}

async function uploadRefund({ session, app }) {
  const query = `
    mutation {
      uploadSyRefund
    }
  `
  return request({ query }, { session, app })
}

async function uploadTransport({ session, app }) {
  const query = `
    mutation {
      uploadSyTransport
    }
  `
  return request({ query }, { session, app })
}
