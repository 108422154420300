import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { setAddressOptions } from 'actions/address'
import { initialState, fields, handlers } from 'models/purchaseImportPage'
import { SessionContext, AppContext } from 'contexts'
import { Button, Center, Grid, Link, Message, Section } from 'components/core'
import Page from 'components/Page'
import PurchaseImportModal from 'views/PurchaseImportModal'
import { MdArrowBack } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { cityMap, districtMap } = app.state
  const [state, setState] = useState(initialState())
  const [open, setOpen] = useState(false)
  const form = fields({ session, state, setState })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    data: { cityMap, districtMap },
    action: { setOpen },
  })

  useEffect(() => {
    setAddressOptions({ app, session, cityMap, districtMap })
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page
        navId="purchaseList"
        title="purchase.title.import"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/purchase"
            />
            <Button ml={3} variant="primary" text="btn.submit" type="submit" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={handler.loadExcel}
      >
        <Center justifyContent="flex-start" px={3}>
          <Message id="purchase.message.template" />
          <Link
            variant="primaryLink"
            text="btn.download"
            onClick={handler.downloadTemplate}
          />
        </Center>
        <Grid mt={3} cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.warehouseId}
            {form.file}
          </Section>
        </Grid>
      </Page>
      <PurchaseImportModal
        open={open}
        value={{
          warehouseId: state.warehouseId?.value,
          ticketItems: state.ticketItems,
        }}
        data={{
          warehouses: state.warehouses,
          srcCompanies: state.srcCompanies,
          billCompanies: state.billCompanies,
          products: state.products,
        }}
        action={{ handleClose: () => setOpen(false) }}
      />
    </>
  )
}
