export default {
  'reverse.title.list': '歸還目錄',
  'reverse.title.add': '新增歸還單',
  'reverse.title.edit': '修改歸還單',
  'reverse.title.view': '歸還單詳情',
  'reverse.title.print': '歸還單 - {ticketId}',
  'reverse.section.product': '商品',
  'reverse.section.fromLocation': '取倉',
  'reverse.section.basic': '基礎信息',
  'reverse.field.id': '單號',
  'reverse.field.type': '類型',
  'reverse.field.fromLocationId': '取倉',
  'reverse.field.transDate': '日期',
  'reverse.field.spu': '商品名稱',
  'reverse.field.sku': '品號',
  'reverse.field.balance': '現有庫存',
  'reverse.field.quantity': '數量',
  'reverse.field.status': '狀態',
  'reverse.type.DEPT': '業務至倉庫',
  'reverse.type.DEALER_ORG': '經銷群至業務',
  'reverse.type.WAREHOUSE': '經銷群至倉庫',
}
