import React from 'react'
import { Definition } from 'components/core'

export const fields = ({ state, message }) => {
  const { staffName, staffType } = state.staff || {}

  return {
    name: <Definition label="account.field.name" value={staffName} />,
    type: (
      <Definition
        label="account.field.type"
        value={message({ id: `staff.type.${staffType}` })}
      />
    ),
    role: <Definition label="account.field.role" value={''} />,
  }
}
