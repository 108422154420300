import React, { useContext, useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import {
  initialState,
  fields,
  labels,
  handlers,
  getTotalPrice,
} from 'models/processPage'
import { Flex } from 'reflexbox'
import {
  Center,
  Grid,
  Button,
  Link,
  Section,
  StatusChip,
  Toolbar,
  Text,
  Definition,
  Modal,
} from 'components/core'
import Page from 'components/Page'
import ProcessSrcDrawer from 'views/ProcessSrcDrawer'
import ProcessDestDrawer from 'views/ProcessDestDrawer'
import ProcessDrawer from 'views/ProcessDrawer'
import ProcessMaterialDrawer from 'views/ProcessMaterialDrawer'
import {
  MdAdd,
  MdArrowBack,
  MdContentCopy,
  MdEdit,
  MdPrint,
  MdSort,
} from 'react-icons/md'
import {
  renderConfirmButton,
  renderDeleteLink,
  renderLockLink,
  renderMessage,
} from 'utilities/app'
import { handleLock, renderSyncLink } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { CONFIRM_SET } from 'constants/actionType'
import { hasAccess } from 'utilities/permission'
import SortableTable from 'components/core/SortableTable'

export default ({ match, profile }) => {
  const { id } = match.params
  const url = new URL(window.location.href)
  const copyId = url.searchParams.get('copyId')
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState(initialState({}, data, message))
  const [srcOpen, setSrcOpen] = useState(false)
  const [srcValue, setSrcValue] = useState()
  const [destOpen, setDestOpen] = useState(false)
  const [destValue, setDestValue] = useState()
  const [processOpen, setProcessOpen] = useState(false)
  const [processValue, setProcessValue] = useState()
  const [materialOpen, setMaterialOpen] = useState(false)
  const [materialValue, setMaterialValue] = useState()
  const [sortOpen, setSortOpen] = useState(false)
  const [sortValue, setSortValue] = useState([])
  const tableRef = useRef()

  const handleSrcOpen = (item) => {
    setSrcValue(item)
    setSrcOpen(true)
  }

  const handleSrcClose = () => {
    setSrcOpen(false)
    setSrcValue({})
  }

  const handleDestOpen = (item) => {
    setDestValue(item)
    setDestOpen(true)
  }

  const handleDestClose = () => {
    setDestOpen(false)
    setDestValue({})
  }

  const handleProcessOpen = (item) => {
    setProcessValue(item)
    setProcessOpen(true)
  }

  const handleProcessClose = () => {
    setProcessOpen(false)
    setProcessValue({})
  }

  const handleMaterialOpen = (item) => {
    setMaterialValue(item)
    setMaterialOpen(true)
  }

  const handleMaterialClose = () => {
    setMaterialOpen(false)
    setMaterialValue({})
  }

  const handleSortOpen = () => {
    const rows = state.processItems.map((item, index) => ({
      index,
      processName: item.processName,
    }))
    setSortValue(rows)
    setSortOpen(true)
  }

  const handleSortClose = () => {
    setSortOpen(false)
    setSortValue([])
  }

  const readonly = profile === 'view'
  const processType = state.processType.value
  const isPack = ['PACK_SINGLE', 'PACK_COMBO'].includes(processType)
  const form = readonly
    ? labels({
        app,
        state,
        message,
        profile,
      })
    : fields({
        app,
        session,
        state,
        setState,
        message,
        profile,
        action: {
          handleSrcOpen,
          handleDestOpen,
          handleProcessOpen,
          handleMaterialOpen,
        },
      })

  const handler = handlers({
    state,
    setState,
    data,
    setData,
    app,
    session,
    message,
    history,
    id,
    copyId,
    profile,
  })

  useEffect(() => {
    handler.handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile, copyId, data])

  const processAction = {
    handleOpen: handleProcessOpen,
    handleClose: handleProcessClose,
    handleLoad: handler.handleLoad,
    addProcessItem: handler.addProcessItem,
  }

  const materialAction = {
    handleOpen: handleMaterialOpen,
    handleClose: handleMaterialClose,
    handleLoad: handler.handleLoad,
    addMaterialItem: handler.addMaterialItem,
  }

  const renderFromTicketItems = () => (
    <Section
      mt={5}
      title={`process.section.from${isPack ? 'Pack' : 'Unpack'}Items`}
    >
      <Toolbar show={!readonly}>
        <Button
          variant="default"
          icon={<MdAdd />}
          text="btn.add"
          onClick={() =>
            isPack
              ? handleDestOpen({ index: -1, isFrom: true })
              : handleSrcOpen({ index: -1, isFrom: true })
          }
        />
      </Toolbar>
      {form.fromTicketItems}
    </Section>
  )

  const renderToTicketItems = () => (
    <Section
      mt={5}
      title={`process.section.to${isPack ? 'Pack' : 'Unpack'}Items`}
    >
      <Toolbar show={!readonly}>
        <Button
          variant="default"
          icon={<MdAdd />}
          text="btn.add"
          onClick={() =>
            isPack
              ? handleSrcOpen({ index: -1, isFrom: false })
              : handleDestOpen({ index: -1, isFrom: false })
          }
        />
      </Toolbar>
      {form.toTicketItems}
    </Section>
  )

  return (
    <>
      <Page
        profile={profile}
        navId="processList"
        title={`process.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`process.status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/process/${id}/view` : '/process'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdContentCopy />}
              tooltip="btn.copy"
              href={`/process/add?copyId=${id}`}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'process',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.locked || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/process/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handleLock({
                module: 'process',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            {renderSyncLink({
              readonly,
              sync: state.sync,
              app,
              session,
              id: state.id,
              onSubmit: (value) => setState({ ...state, sync: value }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
            {renderConfirmButton({
              module: 'process',
              session,
              profile,
              status: state.status,
              onSubmit: handler.handleConfirm,
            })}
            {renderCompleteButton({
              app,
              session,
              profile,
              status: state.status,
              onSubmit: handler.handleComplete,
            })}
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.transDate}
            {form.finishDate}
          </Section>
          <Section>
            {form.processType}
            {form.memo}
          </Section>
          <Section>
            {form.quantityInnerBox}
            {form.quantityOuterBox}
            {form.locationId}
          </Section>
        </Grid>
        {isPack ? (
          <>
            {renderToTicketItems()}
            {renderFromTicketItems()}
          </>
        ) : (
          <>
            {renderFromTicketItems()}
            {renderToTicketItems()}
          </>
        )}
        <Section mt={5} title="process.section.process">
          {form.processFilter}
          <Definition
            show={readonly && state.processConfigName}
            direction="row"
            label="process.field.processConfig"
            value={state.processConfigName}
          />
          <Toolbar show={!readonly}>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleProcessOpen({ index: -1 })}
            />
            <Button
              variant="default"
              icon={<MdSort />}
              text="btn.sort"
              onClick={() => handleSortOpen()}
            />
          </Toolbar>
          {form.processList}
          <Flex mt={2} justifyContent="flex-end" alignItems="center">
            {renderMessage('process.field.weightFactor', { color: 'dark.1' })}
            <Text ml={2} mr={3}>
              {readonly ? state.weightFactor : form.weightFactor}
            </Text>
            {renderMessage('process.field.totalPrice', { color: 'dark.1' })}
            <Text ml={2} mr={3}>
              {getTotalPrice(state)}
            </Text>
          </Flex>
        </Section>
        {(!readonly || state.processImages?.length > 0) && (
          <Section mt={5} title="process.section.image">
            {form.processImages}
          </Section>
        )}
        {(!readonly || state.printFiles?.length > 0) && (
          <Section mt={5} title="process.section.print">
            {form.printFiles}
          </Section>
        )}
        <Section mt={5} title="process.section.material">
          <Toolbar show={!readonly}>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleMaterialOpen({ index: -1 })}
            />
          </Toolbar>
          {form.materialList}
        </Section>
      </Page>
      <ProcessSrcDrawer
        open={srcOpen}
        value={srcValue}
        data={{
          id: state.id,
          ticketItems: isPack ? state.toTicketItems : state.fromTicketItems,
          oldTicketItems: state.oldFromItems,
          warehouses: state.warehouses,
          products: state.products,
          quantity: state.quantity,
          processType,
          isPack,
        }}
        action={{
          handleClose: handleSrcClose,
          handleSubmit: handler.editSrcItem,
        }}
      />
      <ProcessDestDrawer
        open={destOpen}
        value={destValue}
        data={{
          id: state.id,
          ticketItems: isPack ? state.fromTicketItems : state.toTicketItems,
          oldTicketItems: state.oldFromItems,
          warehouses: state.warehouses,
          products: state.products,
          quantity: state.quantity,
          processType,
          isPack,
        }}
        action={{
          handleClose: handleDestClose,
          handleSubmit: handler.editDestItem,
        }}
      />
      <ProcessDrawer
        open={processOpen}
        value={processValue}
        action={processAction}
      />
      <ProcessMaterialDrawer
        open={materialOpen}
        value={materialValue}
        data={{
          profile,
          products: state.products,
          warehouses: state.warehouses,
        }}
        action={materialAction}
      />
      <Modal
        title="processConfig.title.sort"
        open={sortOpen}
        onClose={handleSortClose}
        width={450}
        maxHeight="90%"
      >
        <SortableTable
          ref={tableRef}
          columns={[
            { id: 'processName', label: 'processConfig.field.processName' },
          ]}
          primaryKey="index"
          rows={sortValue}
        />
        <Center mt={4}>
          <Button
            mr={2}
            variant="secondary"
            onClick={handleSortClose}
            text="btn.cancel"
          />
          <Button
            variant="primary"
            onClick={handler.sortProducts(tableRef, handleSortClose)}
            text="btn.confirm"
          />
        </Center>
      </Modal>
    </>
  )
}

function renderCompleteButton({
  profile = 'view',
  status = 'PENDING',
  app,
  session,
  onSubmit,
}) {
  return (
    <Button
      ml={2}
      show={
        profile === 'view' &&
        (status === 'CALLBACK_PENDING' || status === 'ACTION_PENDING')
      }
      disabled={!hasAccess(app.state.staff, 'confirmProcessTicket')}
      variant="primary"
      text="process.btn.complete"
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: 'process.title.complete' },
          text: { id: 'process.message.complete' },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}
