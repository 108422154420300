import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { useIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'
import FormField from './FormField'

export const TextInput = ({
  placeholder,
  onChange,
  hasError,
  forwardRef,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const inputRef = useRef()

  useImperativeHandle(forwardRef, () => ({
    focus: () => inputRef.current && inputRef.current.focus(),
  }))

  return (
    <Flex sx={{ flex: 1 }}>
      <Box
        as="input"
        ref={inputRef}
        placeholder={getPlaceholder(formatMessage, placeholder)}
        autoComplete="off"
        width={1}
        minHeight="38px"
        py={2}
        px={2}
        m={0}
        fontSize={1}
        color="dark.2"
        sx={{
          boxSizing: 'border-box',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: hasError ? 'error.1' : 'light.3',
          borderRadius: '4px',
          transition: 'border-color 0.2s linear',
          willChange: 'border-color',
          '&:focus': {
            outline: 'none',
            borderColor: hasError ? 'error.1' : 'accent.1',
          },
        }}
        onChange={({ target }) => onChange(target.value)}
        onBlur={({ target }) => onChange(target.value?.trim())}
        {...props}
      />
    </Flex>
  )
}

export default forwardRef(
  ({ show = true, id, label, required, errMsg, fieldProps, ...props }, ref) => {
    if (!show) return null
    return (
      <FormField
        id={id}
        label={label}
        required={required}
        errMsg={errMsg}
        {...fieldProps}
      >
        <TextInput id={id} hasError={!!errMsg} {...props} forwardRef={ref} />
      </FormField>
    )
  },
)

function getPlaceholder(formatMessage, value) {
  if (!value) return ''
  if (typeof value === 'string') {
    return formatMessage({ id: value })
  }
  return formatMessage(...value)
}
