import { request } from 'utilities/request'

export const fetchBalances = async ({
  app,
  session,
  locationType,
  locationId,
  productVariantId,
  oldTicketItems,
  includePending = false,
  includeUnlockable = true,
}) => {
  if (!productVariantId || productVariantId.length === 0) return []
  if (locationType !== 'ALL') {
    if (!locationId) return []
    if (Array.isArray(locationId) && locationId.length === 0) return []
  }

  const method = getMethod(locationType)
  const input = { productVariantId, includePending, includeUnlockable }

  if (locationType === 'ALL') {
    input.locationId = locationId
    return fetchData(app, session, method, input)
  } else {
    if (!Array.isArray(locationId)) locationId = [locationId]

    const balances = await Promise.all(
      locationId.map((item) => {
        input.locationId = item
        return fetchData(app, session, method, input)
      }),
    )

    return balances.reduce((result, item) => {
      result = result.concat(item)
      return result
    }, [])
  }
  // if (oldTicketItems) {
  //   balances.forEach((balance) => {
  //     const matched = oldTicketItems.filter(
  //       (item) => item.productVariantId === balance.productVariantId,
  //     )
  //     if (matched.length > 0) {
  //       balance.quantity += matched.reduce((result, item) => {
  //         result += item.quantity
  //         return result
  //       }, 0)
  //     }
  //   })
  // }

  // return balances
}

async function fetchData(app, session, method, input) {
  const variables = { input }
  const query = `
    query($input: InventoryInput!) {
      ${method}(input: $input) {
        productVariantId
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data[method]
}

export const fetchBalance = async ({
  app,
  session,
  locationType,
  locationId,
  productVariantId,
  oldTicketItems,
  includePending,
  includeUnlockable,
}) => {
  const balances = await fetchBalances({
    app,
    session,
    locationType,
    locationId,
    productVariantId: [productVariantId],
    oldTicketItems,
    includePending,
    includeUnlockable,
  })
  return balances.reduce((result, item) => {
    result += item.quantity
    return result
  }, 0)
}

const getMethod = (locationType) => {
  switch (locationType) {
    case 'ALL':
      return 'inventoryAllBalances'
    default:
      return 'inventoryBalances'
  }
}
