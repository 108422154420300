export default {
  'mrWarehouse.title.edit': '新慎康倉管系統設定',
  'mrWarehouse.title.syncProduct': '同步商品',
  'mrWarehouse.title.syncRefund': '同步退貨單',
  'mrWarehouse.section.basic': '基礎信息',
  'mrWarehouse.section.autoSync': '自動同步',
  'mrWarehouse.section.manualSync': '手動同步',
  'mrWarehouse.field.apiToken': '介接金鑰 Token',
  'mrWarehouse.field.warehouseId': '退貨倉別',
  'mrWarehouse.field.fromDate': '起日',
  'mrWarehouse.btn.syncProduct': '同步商品',
  'mrWarehouse.btn.syncRefund': '同步未完成退貨單',
  'mrWarehouse.message.syncProduct': '確認同步商品？',
  'mrWarehouse.message.syncSuccess': '成功同步',
  'mrWarehouse.message.syncRefund': '確認同步未完成退貨單？',
}
