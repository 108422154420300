import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import {
  initialState,
  fields,
  labels,
  handlers,
  getTotalAmount,
} from 'models/sellPage'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Toolbar,
  Text,
} from 'components/core'
import Page from 'components/Page'
import { MdAdd, MdArrowBack, MdEdit, MdPrint } from 'react-icons/md'
import { renderDeleteLink, renderLockLink, renderMessage } from 'utilities/app'
import SellDrawer from './SellDrawer'
import { Box } from 'reflexbox'
import { handleLock } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match, profile }) => {
  const { id } = match.params
  const url = new URL(window.location.href)
  const parentId = url.searchParams.get('parentId')
  const parentType = url.searchParams.get('parentType')
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(
    initialState({ parentId, parentType }, message),
  )
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState()

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const readonly = profile === 'view'
  const form = readonly
    ? labels({ state, setState, message })
    : fields({
        app,
        session,
        state,
        setState,
        message,
        profile,
        action: { handleOpen },
      })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    history,
    id,
    parentId,
    parentType,
    profile,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <>
      <Page
        profile={profile}
        navId="sellList"
        title={`sell.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/sell/${id}/view` : '/sell'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'sell',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={
                state.locked || state.status === 'INACTIVE' || state.parentId
              }
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/sell/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handleLock({
                module: 'sell',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.parentId}
            {form.ticketNo}
            {form.transDate}
            {form.dealer}
            {form.recipient}
          </Section>
          <Section>
            {form.recipientContact}
            {form.recipientAddress}
            {form.recipientPhone}
            {form.recipientCellphone}
          </Section>
          <Section>
            {form.dealerCode}
            {form.billing}
            {form.sales}
            {form.backup}
            {form.memo}
          </Section>
        </Grid>
        <Section mt={5} title="sell.section.product">
          <Toolbar show={!readonly}>
            <Button
              disabled={!!parentId}
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
          {form.product}
          <Toolbar
            justifyContent={readonly ? 'flex-end' : 'space-between'}
            bottom
          >
            <Button
              show={!readonly}
              disabled={!!parentId}
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
            <Box py={2}>
              {renderMessage('field.total', { color: 'dark.1' })}
              <Text mx={2}>{getTotalAmount(state)}</Text>
            </Box>
          </Toolbar>
        </Section>
      </Page>
      <SellDrawer
        readonly={readonly}
        open={open}
        value={value}
        data={{
          profile,
          products: state.products,
          dealerId: state.dealerId?.value,
        }}
        action={{
          handleOpen,
          handleClose,
          addItem: handler.addItem,
        }}
      />
    </>
  )
}
