import React from 'react'
import { request } from 'utilities/request'
import { Link } from 'components/core'
import { selectFilter, textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { capitalize } from 'utilities/string'
import { getSelectOptions } from 'utilities/form'

export const filters = ({ profile, data }) => {
  const result = [
    {
      id: 'name',
      label: 'product.field.spu',
      input: textFilter,
    },
    {
      id: 'sku',
      label: 'product.field.sku',
      input: textFilter,
    },
    {
      id: 'option',
      label: 'inventory.filter.option',
      input: (props) =>
        selectFilter({
          ...props,
          options: [
            {
              value: 'SHOW_ZERO',
              label: props.message({ id: 'inventory.option.SHOW_ZERO' }),
            },
          ],
        }),
    },
  ]

  switch (profile) {
    case 'warehouse':
      result.push({
        id: 'locationId',
        label: `inventory.field.warehouse`,
        input: (props) =>
          selectFilter({
            ...props,
            isSearchable: true,
            options: getSelectOptions(data.warehouses),
          }),
      })
      result.push({
        id: 'locationParentId',
        label: `inventory.field.warehouseGroup`,
        input: (props) =>
          selectFilter({
            ...props,
            isSearchable: true,
            options: getSelectOptions(data.warehouseGroups),
          }),
      })
      break
    case 'org':
      result.push({
        id: 'locationId',
        label: `inventory.field.org`,
        input: (props) =>
          selectFilter({
            ...props,
            isSearchable: true,
            options: getSelectOptions(data.orgs),
          }),
      })
      break
    case 'dealer':
      result.push({
        id: 'orgId',
        label: `inventory.field.org`,
        input: (props) =>
          selectFilter({
            ...props,
            isSearchable: true,
            options: getSelectOptions(data.orgs),
          }),
      })
      result.push({
        id: 'locationId',
        label: `inventory.field.dealer`,
        input: textFilter,
      })
      break
    default:
  }

  return result
}

export const columns = ({ profile, action }) => [
  {
    id: 'locationName',
    label: `inventory.field.${profile}`,
  },
  {
    id: 'productVariantName',
    label: 'inventory.field.spu',
  },
  {
    id: 'sku',
    label: 'inventory.field.sku',
  },
  {
    id: 'barcode',
    label: 'product.field.barcode',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleOpen({
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.quantity}
      </Link>
    ),
  },
]

export const handlers = ({
  profile,
  session,
  app,
  setState,
  setFilterValues,
  setPagination,
  setData,
  message,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setData(resp)
  },
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({
      profile,
      session,
      app,
      filterValues,
      pagination,
    })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = `inventory.title.${profile}List`
    const cols = columns({ profile })
    const rows = await getExportData({ profile, session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getInit({ session, app }) {
  const variables = {
    input: { type: ['DEALER_ORG', 'WAREHOUSE', 'WAREHOUSE_GROUP'] },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        type
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const orgs = []
  const warehouses = []
  const warehouseGroups = []

  data.locations.forEach((item) => {
    switch (item.type) {
      case 'WAREHOUSE_GROUP':
        warehouseGroups.push(item)
        break
      case 'WAREHOUSE':
        warehouses.push(item)
        break
      case 'DEALER_ORG':
        orgs.push(item)
        break
      default:
    }
  })

  return { orgs, warehouses, warehouseGroups }
}

async function getData({ profile, session, app, filterValues, pagination }) {
  const variables = {
    input: getDataInput({ profile, filterValues, pagination }),
  }
  const type = `${capitalize(profile)}`
  const query = `
    query($input: InventoryQueryInput) {
      inventory${type}Count(input: $input)
      inventory${type}List(input: $input) {
        locationId
        locationName
        productVariantId
        quantity
        productVariantName
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data[`inventory${type}List`],
    pagination: getPagination(
      page,
      countPerPage,
      data[`inventory${type}Count`],
    ),
  }
}

async function getExportData({ profile, session, app, filterValues }) {
  const variables = { input: getDataInput({ profile, filterValues }) }
  const type = `${capitalize(profile)}`
  const query = `
    query($input: InventoryQueryInput) {
      inventory${type}Count(input: $input)
      inventory${type}List(input: $input) {
        locationId
        locationName
        productVariantId
        quantity
        productVariantName
        sku
        barcode
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data[`inventory${type}List`]
}

function getDataInput({ profile, filterValues, pagination }) {
  const locationType = getLocationType(profile)
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const locationId = filterValues.find(({ id }) => id === 'locationId')
  const locationParentId = filterValues.find(
    ({ id }) => id === 'locationParentId',
  )
  const orgId = filterValues.find(({ id }) => id === 'orgId')
  const option = filterValues.find(({ id }) => id === 'option')
  const input = {
    productVariantName: name?.value,
    sku: sku?.value,
    locationType,
    showZero: option?.value.value === 'SHOW_ZERO',
    orderBy: [{ key: 'locationName' }, { key: 'productVariantName' }],
  }
  switch (profile) {
    case 'warehouse':
      input.locationId = locationId?.value.value
      input.locationParentId = locationParentId?.value.value
      break
    case 'org':
      input.locationId = locationId?.value.value
      break
    case 'dealer':
      input.orgId = orgId?.value.value
      input.locationName = locationId?.value
      break
    default:
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

function getLocationType(profile) {
  switch (profile) {
    case 'org':
      return 'DEALER_ORG'
    case 'dealer':
      return 'DEALER'
    default:
      return 'WAREHOUSE'
  }
}
