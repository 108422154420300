import React from 'react'
import { PhoneInput, Address, Select, TextInput, Switch } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
  getMultiSelectOption,
} from 'utilities/form'
import { Definition } from 'components/core'

export const initialState = (value = {}, data) => ({
  index: value.index,
  id: value.id,
  ...initializeState({
    name: value.name || '',
    code: value.code || '',
    contact: value.contact || '',
    phone: value.phone || '',
    cellphone: value.cellphone || '',
    email: value.email || '',
    address: value.address || '',
    shippingId: getSelectOption(data.shippings, value.shippingId),
    salesId: getMultiSelectOption(data.sales, value.salesId),
    isActive: value?.status !== 'INACTIVE',
  }),
})

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    name: (
      <TextInput
        id="name"
        label="dealerStorage.field.name"
        placeholder="dealerStorage.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="dealerStorage.field.code"
        placeholder="dealerStorage.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    contact: (
      <TextInput
        id="contact"
        label="dealerStorage.field.contactName"
        placeholder="dealerStorage.field.contactName"
        value={state.contact}
        onChange={onTextChange('contact')}
        errMsg={state.__error__.contact}
      />
    ),
    phone: (
      <PhoneInput
        id="phone"
        label="dealerStorage.field.phone"
        value={state.phone}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    cellphone: (
      <PhoneInput
        id="cellphone"
        label="dealerStorage.field.cellphone"
        type="cellphone"
        value={state.cellphone}
        onChange={onTextChange('cellphone')}
        errMsg={state.__error__.cellphone}
      />
    ),
    email: (
      <TextInput
        id="email"
        label="dealerStorage.field.email"
        placeholder="dealerStorage.field.email"
        value={state.email}
        onChange={onTextChange('email')}
        errMsg={state.__error__.email}
      />
    ),
    address: (
      <Address
        id="address"
        label="field.address"
        placeholder="field.address"
        value={state.address}
        onChange={onTextChange('address')}
        errMsg={state.__error__.address}
      />
    ),
    shipping: (
      <Select
        label="dealer.field.shipping"
        placeholder="dealer.field.shipping"
        options={data.shippings}
        value={state.shippingId}
        onChange={onSelectChange('shippingId')}
        errMsg={state.__error__.shippingId}
      />
    ),
    sales: (
      <Select
        label="dealer.field.sales"
        placeholder="dealer.field.sales"
        isMulti
        options={data.sales}
        value={state.salesId}
        onChange={onSelectChange('salesId')}
        errMsg={state.__error__.salesId}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="dealer.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.addStorage({
      index: state.index,
      id: state.id,
      name: state.name,
      contact: state.contact,
      code: state.code,
      phone: state.phone,
      cellphone: state.cellphone,
      email: state.email,
      address: state.address,
      shippingId: state.shippingId?.value,
      shippingName: state.shippingId?.label,
      salesId: state.salesId?.map((item) => item.value),
      salesName: state.salesId?.map((item) => item.label),
      status: state.isActive ? 'ACTIVE' : 'INACTIVE',
    })
    action.handleClose()
  },
})
