import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { columns, filters, handlers } from 'models/repairFeedbackList'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { Table, Section } from 'components/core'
import Page from 'components/Page'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="repairFeedback.title.list" navId="repairFeedbackList">
      <Section>
        <Table
          columns={columns({ session, app, message, action: { handleLoad } })}
          rows={state}
          filters={filters}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
