export default {
  'vendor.sy': '生洋',
  'error.sy.api': '[生洋] {message}',
  'sy.title.edit': '更改生洋設定',
  'sy.btn.uploadDispatch': '上傳出貨單 / 調撥單',
  'sy.title.dispatchUpload': '上傳出貨單 / 調撥單',
  'sy.title.addTech': '新增安裝人員',
  'sy.message.dispatchUpload': '確認上傳出貨單 / 調撥單？',
  'sy.message.dispatchUploadSuccess': '成功上傳出貨單 / 調撥單',
  'sy.message.dispatchRefundSuccess': '成功上傳退貨單',
  'sy.field.host': 'SFTP Host',
  'sy.field.username': 'SFTP 帳號',
  'sy.field.password': 'SFTP 密碼',
  'sy.field.dispatchSyncTime': '出貨同步時間',
  'sy.field.refundSyncTime': '退貨同步時間',
  'sy.field.dispatchWarehouse': '倉庫群',
  'sy.field.shipping': '物流',
  'sy.field.syWarehouseId': '生洋儲位',
  'sy.field.techId': '安裝人員',
  'sy.section.autoSync': '自動同步',
  'sy.section.manualSync': '手動同步',
  'sy.section.warehouse': '儲位設定',
  'sy.section.tech': '安裝人員設定',
  'sy.btn.uploadRefund': '上傳退貨單',
  'sy.btn.uploadTransport': '上傳派工單',
  'sy.title.refundUpload': '上傳退貨單',
  'sy.message.refundUpload': '確認上傳退貨單？',
  'sy.message.refundUploadSuccess': '成功上傳退貨單',
  'sy.title.transportUpload': '上傳派工單',
  'sy.message.transportUpload': '確認上傳派工單？',
  'sy.message.transportUploadSuccess': '成功上傳派工單',
}
