import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { Button } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete } from 'react-icons/md'
import { textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Box } from 'reflexbox'

export const filters = [
  {
    id: 'code',
    label: 'productComboCode.field.code',
    input: textFilter,
  },
]

export const columns = ({ session, app, action, data, message }) => [
  {
    id: 'locationName',
    label: 'productComboCode.field.dealer',
    render: ({ row }) => {
      if (!data.dealerMap) return ''
      return data.dealerMap[row.locationId]
    },
  },
  {
    id: 'code',
    label: 'productComboCode.field.code',
  },
  {
    id: 'name',
    label: 'productComboCode.field.product',
    render: ({ row }) => {
      return row.products.map((item, index) => {
        let label = item.productVariantName
        if (item.isGift) {
          label += `[${message({ id: 'productComboCode.field.isGift' })}]`
        }
        label += ` x${item.quantity}`
        return <Box key={index}>{label}</Box>
      })
    },
  },
  {
    id: 'warehouse',
    label: 'productComboCode.field.warehouseId',
    render: ({ row }) =>
      row.products.map(({ warehouseName }, index) => (
        <Box key={index} color={warehouseName ? 'dark.1' : 'light.3'}>
          {warehouseName || '\u2014'}
        </Box>
      )),
  },
  {
    id: 'batchNo',
    label: 'productComboCode.field.batchNo',
    render: ({ row }) =>
      row.products.map(({ batchNo }, index) => (
        <Box key={index} color={batchNo ? 'dark.1' : 'light.3'}>
          {batchNo || '\u2014'}
        </Box>
      )),
  },
  {
    id: 'expireAt',
    label: 'productComboCode.field.expireAt',
    render: ({ row }) =>
      row.products.map(({ expireAt }, index) => (
        <Box key={index} color={expireAt ? 'dark.1' : 'light.3'}>
          {expireAt || '\u2014'}
        </Box>
      )),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'productComboCode.title.delete',
                text: 'productComboCode.message.delete',
                onSubmit: async () =>
                  handleDelete({
                    session,
                    app,
                    locationId: row.locationId,
                    code: row.code,
                    action,
                  }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  setState,
  setData,
  setFilterValues,
  setPagination,
}) => ({
  handleInit: async () => {
    const data = await getInit({ session, app })
    data.dealerMap = data.dealers.reduce((result, item) => {
      result[item.id] = item.name
      return result
    }, {})
    setData(data)
  },
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getInit({ session, app }) {
  const input = { status: 'ACTIVE' }
  const locationInput = { type: ['DEALER', 'WAREHOUSE'] }
  const variables = { locationInput, input }
  const query = `
    query($locationInput: LocationQueryInput, $input: ProductQueryInput) {
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      productVariants(input: $input) {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  const dealers = []
  const warehouses = []

  data.locations.forEach((item) => {
    switch (item.type) {
      case 'DEALER':
        dealers.push(item)
        break
      case 'WAREHOUSE':
        warehouses.push(item)
        break
      default:
    }
  })

  return { products: data.productVariants, dealers, warehouses }
}

async function getData({ session, app, filterValues, pagination }) {
  const input = getDataInput({ filterValues, pagination })
  const variables = { input }
  const query = `
    query($input: ProductComboCodeQueryInput) {
      productComboCodeCount(input: $input)
      productComboCodes(input: $input) {
        locationId
        code
        products
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.productComboCodes,
    pagination: getPagination(page, countPerPage, data.productComboCodeCount),
  }
}

async function handleDelete({ session, app, locationId, code, action }) {
  const variables = { locationId, code }
  const query = `
    mutation($locationId: ID!, $code: String!) {
      deleteProductComboCode(locationId: $locationId, code: $code)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok) action.handleLoad()
  return ok
}

function getDataInput({ filterValues, pagination }) {
  const code = filterValues.find(({ id }) => id === 'code')
  const input = {
    code: code ? code.value : null,
    orderBy: [{ key: 'code' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
