import React, { Suspense, lazy } from 'react'
import { convertDate, createExcel, readExcel } from 'utilities/excel'
import {
  getSelectOptions,
  handleTextChange,
  initializeState,
  validateForm,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Center, LoadingIcon } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'

const FileInput = lazy(() => import('components/file/FileInput'))

export const initialState = (value = {}) => {
  const srcCompanyMap = value.srcCompanies?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const billCompanyMap = value.billCompanies?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const warehouseMap = value.warehouses?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const productMap = value.products?.reduce((result, item) => {
    result[item.sku] = item.id
    return result
  }, {})

  return {
    srcCompanies: value.srcCompanies || [],
    srcCompanyMap,
    billCompanies: value.billCompanies || [],
    billCompanyMap,
    warehouses: value.warehouses || [],
    warehouseMap,
    products: getSelectOptions(value.products, 'id', 'sku'),
    productMap,
    ticketItems: value.ticketItems || [],
    ...initializeState({
      file: '',
    }),
  }
}

const columns = [
  { id: 'transDate', label: 'purchase.field.transDate' },
  { id: 'ticketNo', label: 'purchase.field.ticketNo' },
  { id: 'srcCompany', label: 'purchase.field.srcCompany' },
  { id: 'billCompany', label: 'purchase.field.billCompany' },
  { id: 'rackNo', label: 'purchase.field.rackNo' },
  { id: 'sealNo', label: 'purchase.field.sealNo' },
  { id: 'memo', label: 'purchase.field.memo' },
  { id: 'productVariantId', label: 'purchase.field.sku' },
  { id: 'toLocationId', label: 'purchase.field.warehouseId' },
  { id: 'expireAt', label: 'purchase.field.expireAt' },
  { id: 'batchNo', label: 'purchase.field.batchNo' },
  { id: 'invoicedQuantity', label: 'purchase.field.invoicedQuantity' },
  { id: 'purchasedQuantity', label: 'purchase.field.purchasedQuantity' },
]

const validation = {
  file: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    file: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <FileInput
          id="file"
          label="purchase.field.importFile"
          accept=".xlsx"
          value={state.file}
          onUpload={onTextChange('file')}
          onDelete={onTextChange('file')}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
          errMsg={state.__error__.file}
        />
      </Suspense>
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  message,
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session })
    setState(initialState(data))
  },
  downloadTemplate: () => {
    const title = 'purchase.message.template'
    createExcel({ message, title, timestamp: false, cols: columns, rows: [] })
  },
  loadExcel: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const excelFile = await readFile(state.file)
    const data = await readExcel(excelFile)
    const ticketItems = data.map((item) => {
      const result = {}
      columns.forEach(({ id, label }) => {
        let value = item[message({ id: label })]
        if (typeof value === 'string') value = value.trim()

        const srcCompanyId = state.srcCompanyMap[value]
        const billCompanyId = state.billCompanyMap[value]
        const toLocationId = state.warehouseMap[value]
        const productId = state.productMap[value]

        switch (id) {
          case 'srcCompany':
            value = srcCompanyId ? { value: srcCompanyId, label: value } : null
            break
          case 'billCompany':
            value = billCompanyId
              ? { value: billCompanyId, label: value }
              : null
            break
          case 'productVariantId':
            value = productId ? { value: productId, label: value } : null
            break
          case 'toLocationId':
            value = toLocationId ? { value: toLocationId, label: value } : null
            break
          case 'transDate':
          case 'expireAt':
            value = convertDate(value)
            break
          default:
        }
        result[id] = value
      })
      return result
    })

    setState({ ...state, ticketItems })
    action.setOpen(true)
  },
})

async function getData({ app, session }) {
  const locationInput = { type: ['WAREHOUSE', 'COMPANY'] }
  const variables = { locationInput }
  const query = `
    query($locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
      productVariants {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { locations, productVariants } = data
  const srcCompanies = []
  const billCompanies = []
  const warehouses = []

  locations.forEach(({ id, name, type, extra }) => {
    if (type === 'COMPANY' && extra.type === 'PURCHASE') {
      srcCompanies.push({ value: id, label: name })
    }
    if (type === 'COMPANY' && extra.type === 'BILLING') {
      billCompanies.push({ value: id, label: name })
    }
    if (type === 'WAREHOUSE') {
      warehouses.push({ value: id, label: name })
    }
  })

  return {
    warehouses,
    srcCompanies,
    billCompanies,
    products: productVariants,
  }
}

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}
