import React from 'react'
import { LinkButton } from 'components/core'
import { MdEdit } from 'react-icons/md'
import { hasAccess } from 'utilities/permission'

export const columns = ({ state, session, app, action }) => [
  {
    id: 'name',
    label: 'plugin.field.name',
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => {
      const { staff } = app.state
      return (
        <LinkButton
          disabled={!hasAccess(staff, row.permission)}
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          href={`/plugin/${row.id}/edit`}
        />
      )
    },
  },
]

export const handlers = ({ setState }) => ({
  handleLoad: () => {
    const plugins = [
      { id: 'sdj', name: '翔得捷物流', permission: 'editSdjConfig' },
      { id: 'sy', name: '生洋物流', permission: 'editSyConfig' },
      { id: 'best', name: '百事達物流', permission: 'editBestConfig' },
      { id: 'pxmart', name: '全聯福利中心', permission: 'editPxmartConfig' },
      { id: 'carrefour', name: '家樂福', permission: 'editCarrefourConfig' },
      {
        id: 'mrWarehouse',
        name: '新慎康倉儲系統',
        permission: 'editMrWarehouseConfig',
      },
      {
        id: 'vicInvoice',
        name: '慎康訂單系統',
        permission: 'editVicInvoiceConfig',
      },
    ]
    setState(plugins)
  },
})
