import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { columns, filters, handlers } from 'models/reportRefundList'
import { Center, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload } from 'react-icons/md'
import ReportRefundDrawer from './ReportRefundDrawer'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <>
      <Page
        title="report.title.refund"
        navId="refundReport"
        action={
          <Center>
            <Link
              mr={3}
              icon={<MdFileUpload />}
              text="btn.export"
              onClick={() => handleExport({ filterValues, createExcel })}
            />
          </Center>
        }
      >
        <Section>
          <Table
            columns={columns({ handleOpen, filterValues })}
            rows={state}
            filters={filters}
            filterValues={filterValues}
            pagination={pagination}
            onChange={handleLoad}
          />
        </Section>
      </Page>
      <ReportRefundDrawer value={value} open={open} action={{ handleClose }} />
    </>
  )
}
