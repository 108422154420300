import React from 'react'
import { Select, NumberInput, TextArea } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  getSelectOptions,
} from 'utilities/form'
import { getProductOption } from 'actions/product'

export const initialState = (value = {}, data = {}) => {
  return {
    index: value.index,
    id: value.id || '',
    productVariantName: value.productVariantName || '',
    products: data.products,
    ...initializeState({
      productVariantId: getProductOption(data.products, value.productVariantId),
      quantity: value.quantity || 0,
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState, data }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    productVariantId: (
      <Select
        id="productVariantId"
        label="product.field.spu"
        placeholder="product.field.spu"
        isClearable={false}
        options={getSelectOptions(data.products)}
        onChange={onTextChange('productVariantId')}
        value={state.productVariantId}
        errMsg={state.__error__.productVariantId}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        label="dispatch.field.quantity"
        placeholder="dispatch.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

export const handlers = ({ state, setState, data, action }) => ({
  handleLoad: async (value) => {
    setState(initialState(value, data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { productVariantId, quantity, memo } = state
    action.addItem({
      index: state.index,
      id: state.id,
      productVariantId: productVariantId.value,
      productVariantName: productVariantId.label,
      quantity,
      extra: { memo },
    })
    action.handleClose()
  },
})
