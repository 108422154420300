import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { createExcel } from 'utilities/excel'
import { SessionContext, AppContext } from 'contexts'
import { filters, columns, handlers } from 'models/reportCollectList'
import { Center, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [data, setData] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const handler = handlers({
    session,
    app,
    state,
    data,
    setState,
    setData,
    message,
    setFilterValues,
  })

  useEffect(() => {
    handler.handleInit()
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="report.title.collectList"
      navId="reportCollectList"
      action={
        <Center>
          <Link
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handler.handleExport({ filterValues, createExcel })}
          />
        </Center>
      }
    >
      <Section>
        <Table
          showFilterBar={false}
          columns={columns({ message })}
          rows={state}
          filters={filters({ data })}
          filterValues={filterValues}
          onChange={handler.handleLoad}
        />
      </Section>
    </Page>
  )
}
