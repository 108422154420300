export default {
  'company.title.list': '廠商目錄',
  'company.title.add': '新增廠商',
  'company.title.edit': '更改廠商',
  'company.title.delete': '刪除廠商',
  'company.message.delete': '確認刪除廠商?',
  'company.field.name': '名稱',
  'company.field.ein': '統一編號',
  'company.field.type': '類型',
  'company.type.BILLING': '歸帳',
  'company.type.PURCHASE': '進倉',
  'company.type.SHIPPING': '物流',
}
