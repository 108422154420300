import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/adjustImportDrawer'
import { Center, Drawer, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, data, open, action }) => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data))
  const form = fields({ app, session, state, setState, data, message })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    data,
    action,
  })

  useEffect(() => {
    setState(initialState(value, data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      title="adjust.title.editItem"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.transDate}
      {form.ticketNo}
      {form.toLocationId}
      {form.memo}
      {form.productVariantId}
      {form.quantity}
      <Center mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          disabled={state.quantity <= 0}
          type="submit"
          variant="primary"
          text="btn.confirm"
        />
      </Center>
    </Drawer>
  )
}
