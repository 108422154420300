import React from 'react'
import { CONFIRM_SET, FILTER_SET } from 'constants/actionType'
import { Button, Link } from 'components/core'
import { request } from 'utilities/request'
import { MdHome, MdDelete, MdEdit } from 'react-icons/md'

export const columns = ({ setState, session, app, action }) => [
  {
    id: 'name',
    label: 'category.field.name',
    render: ({ row }) => (
      <Link variant="primaryLink" href={`/category/${row.id}/product`}>
        {row.name}
      </Link>
    ),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <Button
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => action.handleOpen(row)}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'category.title.delete',
                text: 'category.message.delete',
                onSubmit: async () =>
                  handleDelete({
                    setState,
                    session,
                    app,
                    id: row.id,
                    action,
                  }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({ match, setState, session, app, action }) => ({
  handleLoad: async () => {
    const data = await getData({ match, session, app, action })
    setState(data)

    session.dispatch({
      type: FILTER_SET,
      key: 'productList',
      filterValues: null,
      pagination: null,
    })
  },
})

async function getData({ match, session, app }) {
  const input = { orderBy: [{ key: 'name', sort: 'ASC' }] }
  const variables = { input }
  const query = `
    query($input: CategoryQueryInput) {
      categories(input: $input) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  return data.categories
}

async function handleDelete({ session, app, id, action }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteCategory(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok) {
    action.handleLoad()
  }
  return ok
}

export function getBreadcrumb(category) {
  if (!category) return null

  const breadcrumb = [
    {
      url: `/category`,
      label: <MdHome size="18px" />,
    },
  ]

  if (!category.path) {
    breadcrumb.push({ label: category.name })
    return breadcrumb
  }

  category.path.forEach((item) => {
    breadcrumb.push({ url: `/category/${item.id}`, label: item.name })
  })

  breadcrumb.push({ label: category.name })
  return breadcrumb
}
