import { request } from 'utilities/request'

export const getLocation = (locations, locationId) => {
  if (!locations || !locationId) return {}
  return locations.find(({ id }) => id === locationId)
}

export const parseRecipient = (recipient, state = {}) => {
  const oldAddress = state.recipientAddress || {}
  const oldBackupId = state.backupId || []
  const oldShippingId = state.shippingId || []
  const extra = recipient.extra || {}
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))
  const shippingId = extra.shippingId
    ? { value: extra.shippingId, label: extra.shippingName }
    : null
  return {
    dealerCode: extra.code || state.dealerCode || '',
    recipientContact: recipient.contact || state.recipientContact || '',
    recipientAddress: extra.address || oldAddress,
    recipientEmail: extra.email || state.recipientEmail || '',
    recipientPhone: extra.phone || state.recipientPhone || '',
    recipientCellphone: extra.cellphone || state.recipientCellphone || '',
    backupId: backupId || oldBackupId,
    shippingId: shippingId || oldShippingId,
  }
}

export const fetchLocations = async (app, session, input) => {
  const variables = { input }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        name
        contact
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  return data.locations
}
