import React, { useState, useEffect } from 'react'
import { Center, Button, Drawer } from 'components/core'
import { initialState, fields, handlers } from 'models/deferDrawer'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ open, value, data, readonly, action }) => {
  const [state, setState] = useState(initialState(value, data))
  const handler = handlers({ state, setState, data, action })
  const form = fields({ state, setState, data })

  useEffect(() => {
    setState(initialState(value, data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data])

  return (
    <Drawer
      title="defer.title.addItem"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.transDate}
      {form.productVariant}
      {form.isDemo}
      {form.isGift}
      {form.isContract}
      {form.quantity}
      {form.unitPrice}
      {form.price}
      {form.memo}
      <Center mt={3}>
        <Button
          show={!readonly}
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          show={!readonly}
          variant="primary"
          text="btn.confirm"
          type="submit"
        />
      </Center>
    </Drawer>
  )
}
