import React from 'react'
import { Box } from 'reflexbox'
import { Text, Button } from 'components/core'
import { MdClose } from 'react-icons/md'

export default ({ text, onDelete, sx, ...props }) => {
  if (!text) {
    return null
  }

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      py={2}
      px={3}
      bg="light.2"
      color="dark.1"
      sx={{
        borderRadius: '15px',
        textAlign: 'center',
        whiteSpace: 'nowrap'
      }}
      {...props}
    >
      <Text flex={1}>{text}</Text>
      <Button ml={2} icon={<MdClose />} variant="icon" onClick={onDelete} />
    </Box>
  )
}
