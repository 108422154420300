import React from 'react'
import { Box } from 'reflexbox'
import { renderMessage } from 'utilities/app'

export default ({ show = true, label, color, ...props }) => {
  if (!show) return null
  return (
    <Box
      display="inline-flex"
      py={1}
      px={2}
      bg="light.2"
      color="dark.1"
      fontSize={1}
      sx={{
        alignItems: 'center',
        borderRadius: '15px',
        whiteSpace: 'nowrap',
      }}
      {...props}
    >
      <Box
        display="inline-block"
        height="0.8em"
        width="0.8em"
        mr={2}
        bg={color}
        sx={{
          borderRadius: '50%',
        }}
      />
      {renderMessage(label)}
    </Box>
  )
}
