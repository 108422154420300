import React, { useContext, useEffect, useState } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { Flex } from 'reflexbox'
import { initialState, fields, handlers } from 'models/passwordResetModal'
import { Modal, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ open, value, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value))
  const form = fields({ state, setState })
  const handler = handlers({ state, setState, app, session, action })

  useEffect(() => {
    setState(initialState(value))
  }, [value])

  return (
    <Modal
      title="staff.title.passwordReset"
      open={open}
      onClose={() => action.setOpen(false)}
      width={300}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.password}
      {form.newPassword}
      {form.newPasswordConfirm}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={() => action.setOpen(false)}
        />
        <Button type="submit" variant="primary" text="btn.save" />
      </Flex>
    </Modal>
  )
}
