import React from 'react'
import { Text } from 'components/core'
import { formatDate } from 'utilities/date'
import { selectFilter } from 'utilities/filter'
import { printHtml } from 'utilities/print'
import { request } from 'utilities/request'

const today = formatDate(new Date())

export const filters = ({ data }) => [
  {
    id: 'warehouseId',
    label: 'report.field.warehouse',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.warehouses,
      }),
  },
  {
    id: 'dealerId',
    label: 'report.field.dealer',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.dealers,
      }),
  },
  {
    id: 'sku',
    label: 'field.productName',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        isClearable: true,
        options: data.products,
      }),
  },
]

export const columns = ({ message }) => [
  {
    id: 'warehouseName',
    label: 'report.field.warehouse',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
  },
  {
    id: 'expireDate',
    label: 'report.field.expireDate',
    noWrap: true,
    format: ['html', 'excel'],
  },
  {
    id: 'collectDate',
    label: 'report.field.latestCollectDate',
    noWrap: true,
    format: ['html', 'excel'],
    renderHtml: ({ row }) => {
      if (row.collectDate >= today) return row.collectDate
      return <Text color="error.1">{row.collectDate}</Text>
    },
  },
  {
    id: 'balance',
    label: 'report.field.balance',
    align: 'right',
    format: ['html', 'excel'],
  },
]

export const handlers = ({
  session,
  app,
  state,
  data,
  setState,
  setData,
  message,
  setFilterValues,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setData(resp)
  },
  handleLoad: async ({ filterValues = [] } = {}) => {
    const warehouseId = filterValues.find(({ id }) => id === 'warehouseId')
    const resp = await getData({ data, session, app, filterValues })
    setFilterValues(filterValues)
    setState(formatRows(resp, warehouseId, data))
  },
  handlePrint: () => {
    const title = 'report.title.collectList'
    const list = { columns: columns({ message }), rows: state }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.collectList'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const warehouseId = filterValues.find(({ id }) => id === 'warehouseId')
    const resp = await getData({ session, app, filterValues })
    const rows = formatRows(resp, warehouseId, data)
    createExcel({ message, title, cols, rows, filterValues })
  },
})

function formatRows(rows, warehouseId, data) {
  return rows.map((item) => ({
    ...item,
    warehouseName: warehouseId?.value.label,
    productVariantName: data.productMap[item.sku],
  }))
}

async function getInit({ session, app }) {
  const productInput = { type: 'SINGLE', status: ['ACTIVE', 'INACTIVE'] }
  const locationInput = { type: ['DEALER', 'WAREHOUSE'] }
  const variables = {
    locationInput,
    productInput,
  }
  const query = `
    query($locationInput: LocationQueryInput, $productInput: ProductQueryInput) {
      locations(input: $locationInput) {
        id
        type
        name
        extra
      }
      productVariants(input: $productInput) {
        id
        name
        sku
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const warehouses = []
  const dealers = []

  data.locations.forEach((item) => {
    const { id, type, name, extra = {} } = item
    if (type === 'DEALER') {
      const collectPeriods = extra?.collectPeriods || []
      if (collectPeriods.length === 0) return

      dealers.push({ value: id, label: name })
      return
    }

    if (!extra?.sdj) return

    const value = extra.sdj.locationName
    warehouses.push({ value, label: name })
  })

  const products = []
  const productMap = {}

  data.productVariants.forEach((item) => {
    const { sku, name, extra = {} } = item
    const vendor = extra.vendor
    if (!vendor) return
    if (Array.isArray(vendor) && !vendor.includes('sdj')) return
    if (typeof vendor === 'string' && vendor !== 'sdj') return

    products.push({ value: sku, label: name })
    productMap[sku] = name
  })

  return { warehouses, dealers, products, productMap }
}

async function getData({ data, session, app, filterValues = [] }) {
  const input = getDataInput({ data, filterValues })
  if (!input.warehouseId || !input.dealerId) return []

  const variables = { input }
  const query = `
    query($input: ReportCollectInput) {
      reportCollectList(input: $input) {
        warehouseId
        dealerId
        sku
        expireDate
        collectDate
        balance
      }
    }
  `
  const [ok, body] = await request({ query, variables }, { app, session })
  if (!ok) return []

  return body.reportCollectList
}

function getDataInput({ filterValues = [] }) {
  const warehouseId = filterValues.find(({ id }) => id === 'warehouseId')
  const dealerId = filterValues.find(({ id }) => id === 'dealerId')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const input = {
    warehouseId: warehouseId?.value.value,
    dealerId: dealerId?.value.value,
    sku: sku?.value?.value,
  }
  return input
}
