import React from 'react'
import { Center, Message } from 'components/core'
import { DateInput, Select, TextInput } from 'components/form'
import { Box } from 'reflexbox'

export const textFilter = ({ ref, filterInput, setFilterInput }) => (
  <TextInput
    ref={ref}
    fieldProps={{ mx: 0, mb: 0, mt: [2, 0], flex: [1, 3], width: 1 }}
    value={filterInput || ''}
    onChange={setFilterInput}
  />
)

export const selectFilter = ({
  ref,
  filterInput,
  setFilterInput,
  isSearchable = false,
  isClearable = false,
  options,
}) => (
  <Select
    ref={ref}
    fieldProps={{ mx: 0, mb: 0, mt: [2, 0], flex: [1, 3], width: 1 }}
    isSearchable={isSearchable}
    isClearable={isClearable}
    options={options}
    value={filterInput}
    onChange={(item) => setFilterInput(item)}
  />
)

export const dateFilter = ({ ref, filterInput, setFilterInput }) => {
  const [fromDate, toDate] = filterInput || [null, null]
  return (
    <Label fieldProps={{ mt: [2, 0], flex: [1, 3] }}>
      <DateInput
        ref={ref}
        mr={2}
        fieldProps={{ m: 0, flex: 1, width: 1 }}
        min="1970-01-01"
        value={fromDate}
        onChange={(value) => setFilterInput([value, toDate])}
      />
      <DateInput
        fieldProps={{ m: 0, flex: 1, width: 1 }}
        min="1970-01-01"
        value={toDate}
        onChange={(value) => setFilterInput([fromDate, value])}
      />
    </Label>
  )
}

export const limitDateFilter =
  ({ min = '1970-01-01', max = '2100-01-01' }) =>
  ({ ref, filterInput, setFilterInput }) => {
    const [fromDate, toDate] = filterInput || [null, null]
    return (
      <Label fieldProps={{ mt: [2, 0], flex: [1, 3] }}>
        <DateInput
          ref={ref}
          mr={2}
          fieldProps={{ m: 0, flex: 1, width: 1 }}
          min={min}
          value={fromDate}
          onChange={(value) => setFilterInput([value, toDate])}
        />
        <DateInput
          fieldProps={{ m: 0, flex: 1, width: 1 }}
          min={min}
          max={max}
          value={toDate}
          onChange={(value) => setFilterInput([fromDate, value])}
        />
      </Label>
    )
  }

function Label({ id, label, children, fieldProps, ...props }) {
  return (
    <Box {...fieldProps} {...props}>
      {label && (
        <Message
          as="label"
          htmlFor={id}
          display="block"
          color="dark.0"
          mb={1}
          id={label}
        />
      )}
      <Center>{children}</Center>
    </Box>
  )
}

export const singleDateFilter = ({ ref, filterInput, setFilterInput }) => (
  <DateInput
    ref={ref}
    mr={2}
    fieldProps={{ m: 0, flex: 1, width: 1 }}
    value={filterInput}
    onChange={setFilterInput}
    min="1970-01-01"
  />
)
