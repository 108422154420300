import React from 'react'
import { request } from 'utilities/request'
import { textFilter, limitDateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'
import { filterLocations } from 'utilities/permission'
import { today, yesterday } from 'utilities/date'

export const filters = [
  {
    id: 'transDate',
    label: 'field.date',
    input: limitDateFilter({ max: yesterday() }),
  },
  {
    id: 'locationName',
    label: 'report.field.dealer',
    input: textFilter,
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
]

export const columns = ({ data, filterValues, handleOpen }) => [
  {
    id: 'fromLocationName',
    label: 'report.field.dealer',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.dealerMap) return ''
      return data.dealerMap[row.dealerId]?.name
    },
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.name
    },
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.sku
    },
  },
  {
    id: 'refundQuantity',
    label: 'report.field.recieveTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            productVariantId: row.productVariantId,
            ticket: { fromLocationId: row.dealerId },
            extra: { isReceived: true },
          })
        }}
      >
        {row.refundQuantity}
      </Link>
    ),
    render: ({ row }) => row.refundQuantity,
  },
  {
    id: 'returnQuantity',
    label: 'report.field.returnTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            productVariantId: row.productVariantId,
            ticket: { fromLocationId: row.dealerId },
            extra: { type: 'RETURN_WAREHOUSE' },
          })
        }}
      >
        {row.returnQuantity}
      </Link>
    ),
    render: ({ row }) => row.returnQuantity,
  },
  {
    id: 'discardNoRecycleTotal',
    label: 'report.field.discardNoRecycleTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            productVariantId: row.productVariantId,
            ticket: { fromLocationId: row.dealerId },
            extra: { type: 'DISCARD_NO_RECYCLE' },
          })
        }}
      >
        {row.discardNoRecycleQuantity}
      </Link>
    ),
    render: ({ row }) => row.discardNoRecycleQuantity,
  },
  {
    id: 'discardTotal',
    label: 'report.field.discardTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            productVariantId: row.productVariantId,
            ticket: { fromLocationId: row.dealerId },
            extra: { type: 'DISCARD' },
          })
        }}
      >
        {row.discardQuantity}
      </Link>
    ),
    render: ({ row }) => row.discardQuantity,
  },
  {
    id: 'discardExpiredTotal',
    label: 'report.field.discardExpiredTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            productVariantId: row.productVariantId,
            ticket: { fromLocationId: row.dealerId },
            extra: { type: 'DISCARD_EXPIRED' },
          })
        }}
      >
        {row.discardExpiredQuantity}
      </Link>
    ),
    render: ({ row }) => row.discardExpiredQuantity,
  },
  {
    id: 'discardOpenedTotal',
    label: 'report.field.discardOpenedTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            productVariantId: row.productVariantId,
            ticket: { fromLocationId: row.dealerId },
            extra: { type: 'DISCARD_OPENED' },
          })
        }}
      >
        {row.discardOpenedQuantity}
      </Link>
    ),
    render: ({ row }) => row.discardOpenedQuantity,
  },
  {
    id: 'receiveLessTotal',
    label: 'report.field.receiveLessTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            productVariantId: row.productVariantId,
            ticket: { fromLocationId: row.dealerId },
            extra: { type: 'RECEIVE_LESS' },
          })
        }}
      >
        {row.receiveLessQuantity}
      </Link>
    ),
    render: ({ row }) => row.receiveLessQuantity,
  },
  {
    id: 'adjustQuantity',
    label: 'report.field.refundAdjustQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            ticketType: ['ADJUST'],
            productVariantId: row.productVariantId,
            ticket: { ticketType: 'REFUND', fromLocationId: row.dealerId },
          })
        }}
      >
        {row.adjustQuantity}
      </Link>
    ),
    render: ({ row }) => row.adjustQuantity,
  },
]

export const handlers = ({
  setData,
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  data,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    resp.dealerMap = resp.dealers.reduce((result, item) => {
      result[item.id] = item
      return result
    }, {})
    resp.productMap = resp.productVariants.reduce((result, item) => {
      result[item.id] = item
      return result
    }, {})

    setData(resp)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.refund'
    const cols = columns({ data, filterValues }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
  handleUpdate: async ({ pagination, filterValues }) => {
    const ok = await updateReport({ session, app })
    if (!ok) return false

    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)

    return true
  },
})

async function getInit({ session, app }) {
  const variables = {
    input: { type: ['DEALER'] },
    productInput: { status: ['ACTIVE', 'INACTIVE'] },
    merchantId: app.state.staff.merchantId,
  }
  const query = `
    query($input: LocationQueryInput, $productInput: ProductQueryInput, $merchantId: ID) {
      locations(input: $input) {
        id
        name
      }
      productVariants(input: $productInput) {
        id
        name
        sku
      }
      reportLogs(merchantId: $merchantId) {
        id
        updatedAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const reportLog = data.reportLogs?.find((item) => item.id === 'refund')

  return {
    dealers: locations,
    productVariants: data.productVariants,
    updatedAt: reportLog?.updatedAt + ' UTC',
  }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: RefundReportInput) {
      refundReportCount2(input: $input)
      refundReports2(input: $input) {
        dealerId
        productVariantId
        refundQuantity
        returnQuantity
        discardNoRecycleQuantity
        discardQuantity
        discardExpiredQuantity
        discardOpenedQuantity
        receiveLessQuantity
        adjustQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.refundReports2,
    pagination: getPagination(page, countPerPage, data.refundReportCount2),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: RefundReportInput) {
      refundReports2(input: $input) {
        dealerId
        productVariantId
        refundQuantity
        returnQuantity
        discardNoRecycleQuantity
        discardQuantity
        discardExpiredQuantity
        discardOpenedQuantity
        receiveLessQuantity
        adjustQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.refundReports2
}

function getDataInput({ filterValues, pagination }) {
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const locationName = filterValues.find(({ id }) => id === 'locationName')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const input = {
    transDate: transDate?.value,
    locationName: locationName?.value,
    productVariantName: productName?.value,
    // orderBy: [{ key: 'fromLocationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function updateReport({ session, app }) {
  const fromDate = today() + ' 00:00:00'
  const toDate = today() + ' 23:59:59'
  const variables = { transDate: [fromDate, toDate] }
  const query = `
    mutation($transDate: [String]) {
      addRefundReport(transDate: $transDate) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.addRefundReport
}
