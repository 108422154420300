import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { StatusChip, Link, LinkButton } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { filterLocations } from 'utilities/permission'
import { getAdjustTypes, getLocationOptions } from './adjustPage'
import { FILTER_SET } from 'constants/actionType'
import { Flex } from 'reflexbox'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    input: textFilter,
  },
  {
    id: 'type',
    label: 'adjust.field.type',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getAdjustTypes(props.message),
      }),
  },
  {
    id: 'toLocation',
    label: 'adjust.field.toLocationId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getLocationOptions(data.locations, props.message),
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
    { value: 'PENDING', label: message({ id: 'status.PENDING' }) },
  ]
}

export const columns = ({ session, app, state, message, action }) => [
  {
    id: 'id',
    label: 'adjust.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/adjust/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    format: ['html', 'excel'],
  },
  {
    id: 'transDate',
    label: 'adjust.field.transDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'toLocationName',
    label: 'adjust.field.toLocationId',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'product.field.spu',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'adjust.field.quantity',
    format: ['excel'],
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'status',
    label: 'adjust.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    renderHtml: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/adjust/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'adjust',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('adjust', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setLocations,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'adjustList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    setLocations(resp.locations)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'adjust.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    locationInput: { type: ['WAREHOUSE', 'DEPT', 'DEALER_ORG', 'DEALER'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      adjustTicketCount(input: $input)
      adjustTickets(input: $input) {
        id
        ticketNo
        toLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const { page, countPerPage } = pagination
  return {
    data: data.adjustTickets,
    pagination: getPagination(page, countPerPage, data.adjustTicketCount),
    locations,
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      adjustTickets(input: $input) {
        id
        ticketNo
        toLocationName
        productVariantName
        sku
        quantity
        transDate
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.adjustTickets
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const ticketNo = filterValues.find(({ id }) => id === 'ticketNo')
  const type = filterValues.find(({ id }) => id === 'type')
  const toLocation = filterValues.find(({ id }) => id === 'toLocation')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    id: id?.value,
    ticketNo: ticketNo?.value,
    transDate: transDate?.value,
    toLocationId: toLocation?.value.value,
    status: status?.value.value,
    productVariantName: productName?.value,
    extra: {
      type: type?.value.value,
      locked: lockStatus?.value.value,
    },
    createdBy: createdBy?.value,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
