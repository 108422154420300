import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from 'reflexbox'
import FormField from './FormField'

const TextInput = ({ placeholder, onChange, hasError, ...props }) => {
  const { formatMessage } = useIntl()

  return (
    <Box
      as="textarea"
      placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
      width={1}
      height="64px"
      minHeight="64px"
      p={2}
      m={0}
      sx={{
        resize: 'vertical',
        boxSizing: 'border-box',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: hasError ? 'error.1' : 'light.3',
        borderRadius: '4px',
        transition: 'border-color 0.2s linear',
        willChange: 'border-color',
        '&:focus': {
          outline: 'none',
          borderColor: hasError ? 'error.1' : 'accent.1',
        },
      }}
      onChange={({ target }) => onChange(target.value)}
      onBlur={({ target }) => onChange(target.value?.trim())}
      {...props}
    />
  )
}

export default ({ id, label, required, errMsg, containerProps, ...props }) => (
  <FormField
    id={id}
    label={label}
    required={required}
    errMsg={errMsg}
    {...containerProps}
  >
    <TextInput id={id} hasError={!!errMsg} {...props} />
  </FormField>
)
