import React from 'react'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getDate,
  getSelectOption,
  showDate,
} from 'utilities/form'
import {
  Select,
  NumberInput,
  TextInput,
  TextArea,
  DateInput,
} from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = (value = {}, data, message) => {
  const productOptions = getProductOptions(data.ticketItems)
  return {
    productOptions,
    ...initializeState({
      transDate: getDate(value.transDate),
      paymentType: getSelectOption(
        getPaymentTypeOptions(message),
        value.paymentType,
        'value',
        'label',
        false,
      ),
      productVariantId: getSelectOption(productOptions, value.productVariantId),
      receiptType: getSelectOption(
        getReceiptTypeOptions(message),
        value.receiptType,
        'value',
        'label',
      ),
      receiptNo: value.receiptNo || '',
      memo: value.memo || '',
      price: value.price || 0,
    }),
  }
}

function getProductOptions(ticketItems) {
  if (!ticketItems) return []
  return ticketItems
    .filter(({ isGift }) => !isGift)
    .map((item, index) => ({
      value: `${item.id}::${item.productVariantId}`,
      label: `${index + 1}. ${item.productVariantName}`,
    }))
}

function getPaymentTypeOptions(message) {
  return [
    {
      value: 'DEPOSIT',
      label: message({ id: 'defer.paymentType.DEPOSIT' }),
    },
    {
      value: 'PARTIAL',
      label: message({ id: 'defer.paymentType.PARTIAL' }),
    },
    { value: 'FINAL', label: message({ id: 'defer.paymentType.FINAL' }) },
    { value: 'REFUND', label: message({ id: 'defer.paymentType.REFUND' }) },
    { value: 'REVERT', label: message({ id: 'defer.paymentType.REVERT' }) },
  ]
}

function getReceiptTypeOptions(message) {
  return [
    {
      value: 'DOUBLE',
      label: message({ id: 'defer.receiptType.DOUBLE' }),
    },
    {
      value: 'TRIPLE',
      label: message({ id: 'defer.receiptType.TRIPLE' }),
    },
  ]
}

const validation = {}

export const fields = ({ state, setState, message, data }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="defer.field.transDate"
        value={state.transDate}
        role="lockDeferTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    paymentType: (
      <Select
        label="field.type"
        isClearable={false}
        options={getPaymentTypeOptions(message)}
        value={state.paymentType}
        onChange={onSelectChange('paymentType')}
      />
    ),
    productVariantId: (
      <Select
        label="field.productName"
        isClearable={false}
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId', ({ value }) => {
          const [parentItemId] = value.split('::')
          const ticketItem = data.ticketItems.find(
            ({ id }) => parentItemId === id,
          )
          return { transDate: showDate(ticketItem.transDate) }
        })}
        errMsg={state.__error__.productVariantId}
      />
    ),
    receiptType: (
      <Select
        label="defer.field.receiptType"
        options={getReceiptTypeOptions(message)}
        value={state.receiptType}
        onChange={onSelectChange('receiptType')}
      />
    ),
    receiptNo: (
      <TextInput
        id="receiptNo"
        label="defer.field.receiptNo"
        value={state.receiptNo}
        onChange={onTextChange('receiptNo')}
        errMsg={state.__error__.receiptNo}
      />
    ),
    price: (
      <NumberInput
        id="price"
        type="decimal"
        min="0"
        label="field.price"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await addReceipt({ app, session, state, data })
    if (ok) {
      action.handleLoad()
      action.handleClose()
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'save.success' },
      })
    }
  },
})

async function addReceipt({ session, app, state, data }) {
  const paymentType = state.paymentType.value
  let price = parseFloat(state.price)
  if (['REFUND', 'REVERT'].includes(paymentType)) price *= -1

  const product = state.productVariantId.value
  if (!product) return

  const [parentItemId, productVariantId] = product.split('::')
  const payment = {
    parentItemId,
    transDate: state.transDate,
    paymentType,
    productVariantId,
    productVariantName: state.productVariantId.label,
    receiptType: state.receiptType.value,
    receiptNo: state.receiptNo,
    memo: state.memo,
    price,
  }
  const payments = { itemsToAdd: [payment] }
  const input = { hash: data.hash, extra: { payments } }
  const variables = { id: data.id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editDeferTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
