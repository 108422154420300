import React from 'react'
import { useIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'
import ReactTooltip from 'react-tooltip'
import { Button, Image } from 'components/core'
import { MdDelete, MdVisibility } from 'react-icons/md'

export default ({
  src,
  fallbackSrc,
  alt,
  onView,
  onEdit,
  onDelete,
  ...props
}) => {
  const { formatMessage: message } = useIntl()
  return (
    <Box
      m={1}
      width="100px"
      height="120px"
      sx={{
        position: 'relative',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'light.2',
      }}
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      <Image
        src={src}
        fallbackSrc={fallbackSrc}
        alt={alt}
        width={1}
        height="100%"
        sx={{
          objectFit: 'contain',
        }}
      />
      <Flex
        justifyContent="center"
        alignItems="flex-end"
        width={1}
        height="100%"
        bg="dark.3"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          opacity: 0,
          transition: '.3s ease',
          '&:hover': { opacity: 0.6 },
        }}
      >
        {onView && (
          <Button
            mb={2}
            px={1}
            variant="icon"
            icon={<MdVisibility />}
            data-tip={message({ id: 'btn.preview' })}
            onClick={onView}
          />
        )}
        {onDelete && (
          <Button
            mb={2}
            px={1}
            variant="icon"
            icon={<MdDelete />}
            data-tip={message({ id: 'btn.delete' })}
            onClick={onDelete}
          />
        )}
      </Flex>
      <ReactTooltip effect="solid" />
    </Box>
  )
}
