import React from 'react'
import { hasAccess } from 'utilities/permission'
import {
  MdDashboard,
  MdPeople,
  MdCardGiftcard,
  MdSettings,
  MdBuild,
  MdAssignment,
  MdReceipt,
} from 'react-icons/md'
import { FaCubes, FaWarehouse } from 'react-icons/fa'

export const navLinks = (staff) => [
  {
    id: 'dashboard',
    label: 'dashboard.title',
    icon: <MdDashboard />,
    items: [
      {
        id: 'sdjStockDiffList',
        label: 'sdj.title.stockDiffList',
        path: '/plugin/sdj/stock-diff',
        show: hasAccess(staff, 'sdjInventoryDiffs'),
      },
      {
        id: 'sdjPurchaseDiffList',
        label: 'sdj.title.purchaseDiff',
        path: '/plugin/sdj/purchase-diff',
        show: hasAccess(staff, 'sdjPurchaseDiffList'),
      },
      {
        id: 'sdjDispatchPendingList',
        label: 'sdj.title.dispatchPending',
        path: '/plugin/sdj/dispatch-pending',
        show: hasAccess(staff, 'ticketOutboxItems'),
      },
      {
        id: 'pxmartErrorList',
        label: 'pxmart.title.errorList',
        path: '/plugin/pxmart/error',
        show: hasAccess(staff, 'pxmartSyncErrors'),
      },
      {
        id: 'carrefourErrorList',
        label: 'carrefour.title.errorList',
        path: '/plugin/carrefour/error',
        show: hasAccess(staff, 'carrefourSyncErrors'),
      },
    ],
  },
  {
    id: 'ticket',
    label: 'ticket.title',
    icon: <MdReceipt />,
    items: [
      {
        id: 'purchaseList',
        label: 'purchase.title.list',
        path: '/purchase',
        show: hasAccess(staff, 'purchaseTickets'),
      },
      {
        id: 'lockList',
        label: 'lock.title.list',
        path: '/lock',
        show: hasAccess(staff, 'lockTickets'),
      },
      {
        id: 'reverseList',
        label: 'reverse.title.list',
        path: '/reverse',
        show: hasAccess(staff, 'reverseTickets'),
      },
      {
        id: 'dispatchList',
        label: 'dispatch.title.list',
        path: '/dispatch',
        show: hasAccess(staff, 'dispatchTickets'),
      },
      {
        id: 'sellList',
        label: 'sell.title.list',
        path: '/sell',
        show: hasAccess(staff, 'sellTickets'),
      },
      {
        id: 'refundList',
        label: 'refund.title.list',
        path: '/refund',
        show: hasAccess(staff, 'refundTickets'),
      },
      {
        id: 'processList',
        label: 'process.title.list',
        path: '/process',
        show: hasAccess(staff, 'processTickets'),
      },
      {
        id: 'adjustList',
        label: 'adjust.title.list',
        path: '/adjust',
        show: hasAccess(staff, 'adjustTickets'),
      },
      {
        id: 'moveList',
        label: 'move.title.list',
        path: '/move',
        show: hasAccess(staff, 'moveTickets'),
      },
      {
        id: 'deferList',
        label: 'defer.title.list',
        path: '/defer',
        show: hasAccess(staff, 'deferTickets'),
      },
      {
        id: 'lendList',
        label: 'lend.title.list',
        path: '/lend',
        show: hasAccess(staff, 'lendTickets'),
      },
      {
        id: 'transportList',
        label: 'transport.title.list',
        path: '/transport',
        show: hasAccess(staff, 'transportTickets'),
      },
    ],
  },
  {
    id: 'inventoryList',
    label: 'inventory.title',
    icon: <FaWarehouse />,
    items: [
      {
        id: 'inventoryAllList',
        label: 'inventory.title.allList',
        path: '/inventory/all',
        show: hasAccess(staff, 'inventoryAllList'),
      },
      {
        id: 'inventoryWarehouseList',
        label: 'inventory.title.warehouseList',
        path: '/inventory/warehouse',
        show: hasAccess(staff, 'inventoryWarehouseList'),
      },
      {
        id: 'inventoryDeptList',
        label: 'inventory.title.deptList',
        path: '/inventory/dept',
        show: hasAccess(staff, 'inventoryDeptList'),
      },
      {
        id: 'inventoryOrgList',
        label: 'inventory.title.orgList',
        path: '/inventory/org',
        show: hasAccess(staff, 'inventoryOrgList'),
      },
      {
        id: 'inventoryDealerList',
        label: 'inventory.title.dealerList',
        path: '/inventory/dealer',
        show: hasAccess(staff, 'inventoryDealerList'),
      },
    ],
  },
  {
    id: 'repair',
    label: 'repair.title',
    icon: <MdBuild />,
    items: [
      {
        id: 'repairList',
        label: 'repair.title.list',
        path: '/repair',
        show: hasAccess(staff, 'repairTickets'),
      },
      {
        id: 'repairTypeList',
        label: 'repair.title.typeList',
        path: '/repair/type',
        show: hasAccess(staff, 'repairTypes'),
      },
      {
        id: 'repairTagList',
        label: 'repair.title.tagList',
        path: '/repair/tag',
        show: hasAccess(staff, 'repairTags'),
      },
      {
        id: 'repairRemarkList',
        label: 'repair.title.remarkList',
        path: '/repair/remark',
        show: hasAccess(staff, 'repairRemarks'),
      },
    ],
  },
  {
    id: 'customer',
    label: 'customer.title',
    icon: <MdPeople />,
    items: [
      {
        id: 'customerList',
        label: 'customer.title.list',
        path: '/customer',
        show: hasAccess(staff, 'customers'),
      },
      {
        id: 'repairFeedbackList',
        label: 'repairFeedback.title.list',
        path: '/repair/feedback',
        show: hasAccess(staff, 'repairFeedbacks'),
      },
      {
        id: 'repairReportList',
        label: 'repairReport.title.list',
        path: '/repair/report',
        show: hasAccess(staff, 'repairReports'),
      },
    ],
  },
  {
    id: 'report',
    label: 'report.title',
    icon: <MdAssignment />,
    items: [
      {
        id: 'dailyReport',
        label: 'report.title.daily',
        path: '/report/daily',
        show: hasAccess(staff, 'dailyReports'),
      },
      {
        id: 'warehouseReport',
        label: 'report.title.warehouse',
        path: '/report/warehouse',
        show: hasAccess(staff, 'warehouseReports'),
      },
      {
        id: 'dealerStockReport',
        label: 'report.title.dealerStock',
        path: '/report/dealer/dispatch',
        show: hasAccess(staff, 'locationStockReports'),
      },
      {
        id: 'dealerSalesReport',
        label: 'report.title.dealerSales',
        path: '/report/dealer/sales',
        show: hasAccess(staff, 'locationSalesReports'),
      },
      {
        id: 'staffSalesReport',
        label: 'report.title.staffSales',
        path: '/report/staff/sales',
        show: hasAccess(staff, 'staffSalesReports'),
      },
      {
        id: 'repairReport',
        label: 'report.title.repair',
        path: '/report/repair',
        show: hasAccess(staff, 'repairReports'),
      },
      {
        id: 'refundReport',
        label: 'report.title.refund',
        path: '/report/refund',
        show: hasAccess(staff, 'refundReports'),
      },
      {
        id: 'reportRestockList',
        label: 'report.title.restockList',
        path: '/report/restock',
        show: hasAccess(staff, 'reportRestockList'),
      },
      {
        id: 'reportCollectList',
        label: 'report.title.collectList',
        path: '/report/collect',
        show: hasAccess(staff, 'reportCollectList'),
      },
    ],
  },
  // {
  //   id: 'report2',
  //   label: 'report.title2',
  //   icon: <MdAssignment />,
  //   items: [
  //     {
  //       id: 'dealerStockReport2',
  //       label: 'report.title.dealerStock',
  //       path: '/report/dealer/dispatch2',
  //       show: hasAccess(staff, 'locationStockReports'),
  //     },
  //     {
  //       id: 'dealerSalesReport2',
  //       label: 'report.title.dealerSales',
  //       path: '/report/dealer/sales2',
  //       show: hasAccess(staff, 'locationSalesReports'),
  //     },
  //     {
  //       id: 'staffSalesReport2',
  //       label: 'report.title.staffSales',
  //       path: '/report/staff/sales2',
  //       show: hasAccess(staff, 'staffSalesReports'),
  //     },
  //     {
  //       id: 'refundReport2',
  //       label: 'report.title.refund',
  //       path: '/report/refund2',
  //       show: hasAccess(staff, 'refundReports'),
  //     },
  //   ],
  // },
  {
    id: 'product',
    label: 'product.title',
    icon: <MdCardGiftcard />,
    items: [
      {
        id: 'product',
        label: 'category.title',
        path: '/category',
        show: hasAccess(staff, 'categories'),
      },
      {
        id: 'productComboCode',
        label: 'productComboCode.title',
        path: '/product/code',
        show: hasAccess(staff, 'productComboCodes'),
      },
    ],
  },
  {
    id: 'pluginList',
    label: 'plugin.title',
    icon: <FaCubes />,
    path: '/plugin',
    show: hasAccess(staff, 'pluginSidebar'),
  },
  {
    id: 'setting',
    label: 'setting.title',
    icon: <MdSettings />,
    items: [
      {
        id: 'roleList',
        label: 'role.title.list',
        path: '/role',
        show: hasAccess(staff, 'roles'),
      },
      {
        id: 'staffList',
        label: 'staff.title.list',
        path: '/staff',
        show: hasAccess(staff, 'staffs'),
      },
      {
        id: 'warehouseGroupList',
        label: 'warehouseGroup.title.list',
        path: '/warehouseGroup',
        show: true,
      },
      {
        id: 'warehouseList',
        label: 'warehouse.title.list',
        path: '/warehouse',
        show: true,
      },
      {
        id: 'salesDeptList',
        label: 'salesDept.title.list',
        path: '/sales-dept',
        show: true,
      },
      {
        id: 'dealerOrgList',
        label: 'dealerOrg.title.list',
        path: '/dealer/org',
        show: true,
      },
      {
        id: 'dealerList',
        label: 'dealer.title.list',
        path: '/dealer',
        show: true,
      },
      {
        id: 'companyList',
        label: 'company.title.list',
        path: '/company',
        show: true,
      },
      {
        id: 'processConfigList',
        label: 'processConfig.title.list',
        path: '/processConfig',
        show: hasAccess(staff, 'ticketConfigs'),
      },
    ],
  },
]
