import React, { useContext, useState } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { Flex } from 'reflexbox'
import { initialState, fields, handlers } from 'models/pxmartUploadModal'
import { Modal, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ data, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const form = fields({ state, setState })
  const handler = handlers({ app, session, state, setState, data, action })

  return (
    <Modal
      title="pxmart.title.upload"
      open={open}
      onClose={action.handleClose}
      width={300}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.file}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button type="submit" variant="primary" text="btn.confirm" />
      </Flex>
    </Modal>
  )
}
