export default {
  'error.category.duplicate': '商品類別已存在',
  'category.title': '商品類別',
  'category.title.list': '商品類別',
  'category.title.add': '新增商品類別',
  'category.title.edit': '更改商品類別',
  'category.title.delete': '刪除商品類別',
  'category.section.path': '商品類別路徑',
  'category.message.delete': '確認刪除商品類別?',
  'category.field.name': '類別名稱',
}
