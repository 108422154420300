import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { createExcel } from 'utilities/excel'
import { columns, filters, handlers } from 'models/inventoryAllList'
import { Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import InventoryDrawer from 'views/InventoryDrawer'
import { initialPage } from 'utilities/pagination'
import InventoryDeferDrawer from './InventoryDeferDrawer'
import { MdFileUpload, MdPrint } from 'react-icons/md'
import InventoryPendingDrawer from './InventoryPendingDrawer'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [deferOpen, setDeferOpen] = useState(false)
  const [deferValue, setDeferValue] = useState({})
  const [pendingOpen, setPendingOpen] = useState(false)
  const [pendingValue, setPendingValue] = useState({})
  const { handleLoad, handlePrint, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleDeferOpen = (item) => {
    setDeferValue(item)
    setDeferOpen(true)
  }

  const handleDeferClose = () => {
    setDeferOpen(false)
    setDeferValue({})
  }

  const handlePendingOpen = (item) => {
    setPendingValue(item)
    setPendingOpen(true)
  }

  const handlePendingClose = () => {
    setPendingOpen(false)
    setPendingValue({})
  }

  return (
    <Page
      fullWidth
      title={`inventory.title.allList`}
      navId={`inventoryAllList`}
      action={
        <>
          <Link
            mr={3}
            icon={<MdPrint />}
            text="btn.print"
            onClick={() => handlePrint({ filterValues })}
          />
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
        </>
      }
    >
      <Section>
        <Table
          columns={columns({
            action: { handleOpen, handleDeferOpen, handlePendingOpen },
          })}
          rows={state}
          filters={filters}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <InventoryDrawer value={value} open={open} action={{ handleClose }} />
      <InventoryDeferDrawer
        value={deferValue}
        open={deferOpen}
        action={{ handleClose: handleDeferClose }}
      />
      <InventoryPendingDrawer
        value={pendingValue}
        open={pendingOpen}
        action={{ handleClose: handlePendingClose }}
      />
    </Page>
  )
}
