import React from 'react'
import { initializeState, validateForm, handleTextChange } from 'utilities/form'
import { DateInput } from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = () =>
  initializeState({
    syncDate: '',
  })

const validation = {}

export const fields = ({ state, setState, data }) => {
  const { syncType } = data
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)

  return {
    syncDate: (
      <DateInput
        show={syncType !== 'syncProduct'}
        id="syncDate"
        label="mrWarehouse.field.fromDate"
        value={state.syncDate}
        onChange={onTextChange('syncDate')}
        errMsg={state.__error__.syncDate}
      />
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  data,
  action,
  setDisabled,
}) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    setDisabled(true)
    const { syncType } = data
    const [ok, resp] =
      syncType === 'syncProduct'
        ? await syncProducts({ app, session })
        : await syncRefunds({ app, session, state })
    setDisabled(false)

    if (!ok || !resp) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'mrWarehouse.message.syncSuccess' },
    })
    action.handleClose()
    setState(initialState())
  },
})

async function syncProducts({ session, app }) {
  const query = `
    mutation {
      syncMrWarehouseProducts
    }
  `
  return request({ query }, { session, app })
}

async function syncRefunds({ session, app, state }) {
  const query = `
    mutation {
      syncMrWarehouseRefunds
    }
  `
  const variables = { syncDate: state.syncDate }
  return request({ query, variables }, { session, app })
}
