import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/customerDrawer'
import { Center, Button, Drawer } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, open, action }) => {
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value))
  const form = fields({ state, setState })
  const handler = handlers({ app, session, state, setState, history, action })

  useEffect(() => {
    setState(initialState(value))
  }, [value])

  return (
    <Drawer
      title="customer.title.add"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.name}
      {form.phone}
      {form.cellphone}
      {form.address}
      <Center justifyContent="flex-end" mt={3}>
        <Button
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button type="submit" variant="primary" text="btn.submit" />
      </Center>
    </Drawer>
  )
}
