import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { CUSTOMER_LOOKUP_SET } from 'constants/actionType'
import { Button, Drawer, Table } from 'components/core'
import { Box, Flex } from 'reflexbox'
import { textFilter } from 'utilities/filter'
import { showAddress } from 'utilities/form'
import { request } from 'utilities/request'
import { getNumericValue } from 'utilities/string'
import { Checkbox } from 'components/form'

export default () => {
  const session = useContext(SessionContext)
  const { open, onSubmit } = session.state.customerLookup
  const app = useContext(AppContext)
  const { formatMessage: message } = useIntl()
  const [state, setState] = useState()
  const [selected, setSelected] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const handler = handlers({ session, app, setState, setFilterValues })

  const handleClose = () =>
    session.dispatch({
      type: CUSTOMER_LOOKUP_SET,
      item: { open: false, onSubmit: () => {} },
    })

  const handleSubmit = async () => {
    const ok = await onSubmit(selected)
    if (ok) {
      handleClose()
      setState()
      setSelected({})
      setFilterValues([])
    }
  }

  return (
    <Drawer
      title="customer.title.search"
      open={open}
      onClose={handleClose}
      width={[1, 750]}
    >
      <Table
        mt={3}
        showFilterDrawer={false}
        columns={columns({ selected, setSelected, message })}
        rows={state}
        filters={filters}
        filterValues={filterValues}
        onChange={handler.handleLoad}
      />
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={handleClose}
        />
        <Button variant="primary" text="btn.confirm" onClick={handleSubmit} />
      </Flex>
    </Drawer>
  )
}

const filters = [
  {
    id: 'name',
    label: 'customer.field.name',
    input: textFilter,
  },
  {
    id: 'phone',
    label: 'customer.field.phoneAndCellphone',
    input: textFilter,
  },
  {
    id: 'address',
    label: 'customer.field.address',
    input: textFilter,
  },
]

const columns = ({ selected, setSelected, message }) => [
  {
    id: 'name',
    label: 'customer.field.name',
    render: ({ row }) =>
      renderCellItem({
        key: 'name',
        row,
        label: row.name,
        value: row.name,
        selected,
        setSelected,
      }),
  },
  {
    id: 'phone',
    label: 'customer.field.phoneAndCellphone',
    render: ({ row }) => {
      const phones = row.phones || []
      const cellphones = row.cellphones || []
      return [
        ...phones
          ?.filter((item) => !!item)
          .map((item, index) => (
            <Box
              key={item}
              mt={index === 0 ? 0 : 1}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {renderCellItem({
                key: 'phone',
                row,
                label: item,
                value: item,
                selected,
                setSelected,
              })}
            </Box>
          )),
        ...cellphones
          ?.filter((item) => !!item)
          .map((item, index) => (
            <Box
              key={item}
              mt={index === 0 ? 0 : 1}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {renderCellItem({
                key: 'cellphone',
                row,
                label: item,
                value: item,
                selected,
                setSelected,
              })}
            </Box>
          )),
      ]
    },
  },
  {
    id: 'address',
    label: 'customer.field.address',
    render: ({ row }) =>
      row.addresses?.map((item, index) => {
        return (
          <Box key={index} mt={index === 0 ? 0 : 1}>
            {renderCellItem({
              key: 'address',
              row,
              label: showAddress(item, message),
              value: item,
              selected,
              setSelected,
            })}
          </Box>
        )
      }),
  },
]

function renderCellItem({ key, row, value, label, selected, setSelected }) {
  const contact = row.extra?.contact || {}
  const contactVal = contact[value] || ''

  return (
    <Flex alignItems="center">
      <Checkbox
        checked={row.id === selected.id && selected[key] === value}
        onChange={() => {
          if (key === 'name' && selected[key]) {
            setSelected({})
            return
          }

          let newSelected = { ...selected }
          if (row.id !== selected.id) {
            newSelected = { id: row.id, name: row.name }
          }

          if (key === 'phone') newSelected.phoneName = contactVal
          if (key === 'cellphone') newSelected.cellphoneName = contactVal

          newSelected[key] = selected[key] ? null : value
          setSelected(newSelected)
        }}
      />
      {label + (contactVal ? ` [${contactVal}]` : '')}
    </Flex>
  )
}

const handlers = ({ setState, session, app, setFilterValues }) => ({
  handleLoad: async ({ page, filterValues } = {}) => {
    const data = await getData({ session, app, page, filterValues })
    setFilterValues(filterValues)
    setState(data)
  },
})

async function getData({ session, app, page = 0, filterValues = [] }) {
  const input = getDataInput({ page, filterValues })
  if (!input.name && !input.phone && !input.address) return []

  const variables = { input }
  const query = `
    query($input: CustomerQueryInput) {
      customers(input: $input) {
        id
        name
        phones
        cellphones
        addresses {
          zipcode
          city
          district
          street
          hasLift
        }
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  return data.customers
}

function getDataInput({ page, filterValues }) {
  const count = 25
  const name = filterValues.find(({ id }) => id === 'name')
  const phone = filterValues.find(({ id }) => id === 'phone')
  const address = filterValues.find(({ id }) => id === 'address')
  const input = {
    name: name?.value,
    phone: getNumericValue(phone?.value),
    address: address?.value,
    orderBy: [{ key: 'name' }],
    limit: { offset: page * count, count },
  }
  return input
}
