import React from 'react'
import { CONFIRM_SET, FILTER_SET } from 'constants/actionType'
import { Button, Link, LinkButton, StatusChip } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete, MdEdit } from 'react-icons/md'
import { getStatusColor } from 'constants/status'
import { getLimit, getPagination } from 'utilities/pagination'
import { selectFilter, textFilter } from 'utilities/filter'
import { getTypes } from './staffDrawer'

export const filters = ({ data }) => [
  {
    id: 'name',
    label: 'staff.field.name',
    input: textFilter,
  },
  {
    id: 'username',
    label: 'staff.field.username',
    input: textFilter,
  },
  {
    id: 'type',
    label: 'staff.field.type',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getTypes(props.message),
      }),
  },
  {
    id: 'role',
    label: 'staff.field.role',
    input: (props) => selectFilter({ ...props, options: data.roles }),
  },
]

export const columns = ({ session, app, message, action }) => [
  {
    id: 'type',
    label: 'staff.field.type',
    render: ({ row }) => message({ id: `staff.type.${row.type}` }),
  },
  {
    id: 'name',
    label: 'staff.field.name',
    renderHtml: ({ row }) => (
      <Link variant="primaryLink" href={`/staff/${row.id}/role/edit`}>
        {row.name}
      </Link>
    ),
  },
  {
    id: 'username',
    label: 'staff.field.username',
  },
  {
    id: 'role',
    label: 'staff.field.role',
    render: ({ row }) => {
      if (!row.roles) return null
      return row.roles.join(', ')
    },
  },
  {
    id: 'status',
    label: 'dealer.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`staff.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={1}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => action.handleOpen(row)}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            const item = {
              open: true,
              title: 'staff.title.delete',
              text: 'staff.message.delete',
              onSubmit: handleDelete({
                session,
                app,
                id: row.id,
                action,
              }),
            }
            session.dispatch({ type: CONFIRM_SET, item })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  setData,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleInit: async () => {
    const data = await getOptions({ session, app })
    setData(data)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'staffList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setPagination(resp.pagination)
    setState(resp.data)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
})

async function getOptions({ session, app }) {
  const query = `
    query($input: StaffQueryInput) {
      roles {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return []

  return {
    roles: data.roles.map((item) => ({ value: item.id, label: item.name })),
  }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: StaffQueryInput) {
      staffCount(input: $input)
      staffs(input: $input) {
        id
        name
        username
        type
        roles
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.staffs,
    pagination: getPagination(page, countPerPage, data.staffCount),
  }
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const username = filterValues.find(({ id }) => id === 'username')
  const type = filterValues.find(({ id }) => id === 'type')
  const role = filterValues.find(({ id }) => id === 'role')
  const input = {
    joinRole: true,
    name: name?.value,
    username: username?.value,
    type: type?.value.value,
    role: role?.value.label,
    status: ['ACTIVE', 'INACTIVE'],
    orderBy: [
      { key: 'status', fields: ['ACTIVE', 'INACTIVE'] },
      { key: 'type' },
      { key: 'name' },
    ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

const handleDelete =
  ({ session, app, id, action }) =>
  async () => {
    const variables = { id }
    const query = `
    mutation($id: ID!) {
      deleteStaff(id: $id)
    }
  `
    const [ok] = await request({ query, variables }, { session, app })
    if (ok) action.handleLoad()

    return ok
  }
