import React from 'react'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
  getSelectOptions,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Select, Switch, TextInput } from 'components/form'
import { Definition } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = (value = {}) => {
  const dealers = getSelectOptions(value.dealers)
  return {
    dealers,
    ...initializeState({
      username: value.username || '',
      password: value.password || '',
      syncTime: value.syncTime || '09:00',
      dealerId: getSelectOption(dealers, value.dealerId),
      isActive: value.isActive || false,
    }),
  }
}

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
  syncTime: [{ type: 'required', message: 'error.required' }],
  dealerId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    username: (
      <TextInput
        id="username"
        label="best.field.username"
        placeholder="best.field.username"
        value={state.username}
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        id="password"
        label="best.field.password"
        placeholder="best.field.password"
        value={state.password}
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    syncTime: (
      <TextInput
        type="time"
        id="syncTime"
        label="best.field.syncTime"
        placeholder="best.field.syncTime"
        value={state.syncTime}
        onChange={onTextChange('syncTime')}
        errMsg={state.__error__.syncTime}
      />
    ),
    dealerId: (
      <Select
        isClearable={false}
        label="field.dealer"
        placeholder="field.dealer"
        options={state.dealers}
        value={state.dealerId}
        onChange={onSelectChange('dealerId')}
        errMsg={state.__error__.dealerId}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="plugin.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ session, app, state, setState }) => ({
  handleLoad: async () => {
    const resp = await getData({ app, session })
    setState(initialState(resp))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await updatePlugin({ session, app, state })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
})

async function updatePlugin({ session, app, state }) {
  const input = {
    id: 'best',
    username: state.username,
    password: state.password,
    syncTime: state.syncTime,
    dealerId: state.dealerId?.value,
    isActive: state.isActive,
  }
  const variables = { input }
  const query = `
    mutation($input: BestInput!) {
      editBestConfig(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getData({ app, session }) {
  const variables = { locationInput: { type: ['DEALER'] } }
  const query = `
    query($locationInput: LocationQueryInput) {
      merchant {
        extra
      }
      locations(input: $locationInput) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { plugins } = data.merchant.extra
  const plugin = plugins.find((item) => item.id === 'best')
  const dealers = data.locations

  return { ...plugin, dealers }
}
