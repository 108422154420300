import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { useHistory } from 'react-router-dom'
import { initialState, fields, handlers } from 'models/rolePage'
import { Box } from 'reflexbox'
import { Center, Button, Link, Section, Grid } from 'components/core'
import Page from 'components/Page'
import { MdArrowBack } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match, profile }) => {
  const roleId = match.params.id
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const form = fields({ state, setState, message })
  const handler = handlers({ state, setState, app, session, history, roleId })

  useEffect(() => {
    handler.handleLoad({ setState, app, session, roleId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId])

  return (
    <Page
      title={`role.title.${profile}`}
      navId="roleList"
      action={
        <Center>
          <Link
            variant="link"
            icon={<MdArrowBack />}
            text="btn.back"
            href="/role"
          />
          <Button type="submit" variant="primary" ml={3} text="btn.confirm" />
        </Center>
      }
      showFooterAction
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      <Grid cols={['1fr', '1fr 2fr']} gap={3}>
        <Box>
          <Section title="role.section.role">{form.name}</Section>
        </Box>
        <Section title="role.section.permission">
          <Box mt={2}>{form.permissions}</Box>
        </Section>
      </Grid>
    </Page>
  )
}
