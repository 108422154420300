export default {
  'vicInvoice.title.edit': '慎康訂單系統設定',
  'vicInvoice.title.syncDealer': '同步經銷商',
  'vicInvoice.title.syncStorage': '同步收貨處',
  'vicInvoice.title.syncBilling': '同步歸帳廠商',
  'vicInvoice.title.syncSales': '同步業務人員',
  'vicInvoice.title.syncProduct': '同步商品',
  'vicInvoice.title.syncStaff': '同步作業人員',
  'vicInvoice.title.syncPendingOrder': '同步待處理訂單',
  'vicInvoice.section.settingSync': '設定同步',
  'vicInvoice.section.orderSync': '訂單同步',
  'vicInvoice.message.syncDealer': '確認同步經銷商？',
  'vicInvoice.message.syncStorage': '確認同步收貨處？',
  'vicInvoice.message.syncBilling': '確認同步歸帳廠商？',
  'vicInvoice.message.syncSales': '確認同步業務人員？',
  'vicInvoice.message.syncStaff': '確認同步作業人員？',
  'vicInvoice.message.syncProduct': '確認同步商品？',
  'vicInvoice.message.syncPendingOrder': '確認同步待處理訂單？',
  'vicInvoice.message.syncSuccess': '成功同步',
}
