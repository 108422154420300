export default {
  'move.title.list': '調撥目錄',
  'move.title.add': '新增調撥單',
  'move.title.addItem': '新增調撥單商品',
  'move.title.edit': '修改調撥單',
  'move.title.view': '調撥單詳情',
  'move.title.confirmArrive': '確認到貨',
  'move.message.confirmArrive': '確認到貨?',
  'move.title.print': '調撥單 - {ticketId}',
  'move.btn.add': '新增調撥',
  'move.btn.addBatch': '新增批號',
  'move.btn.complete': '確認到貨',
  'move.section.product': '商品',
  'move.section.basic': '基礎信息',
  'move.field.id': '單號',
  'move.field.address': '倉庫地址',
  'move.field.phone': '倉庫電話',
  'move.field.contact': '倉庫聯絡人',
  'move.field.memo': '備註',
  'move.field.fromLocation': '取倉',
  'move.field.toLocation': '轉倉',
  'move.field.transDate': '出貨日',
  'move.field.arriveDate': '到貨日',
  'move.field.spu': '商品名稱',
  'move.field.sku': '品號',
  'move.field.batchNo': '批號',
  'move.field.expireAt': '效期',
  'move.field.balance': '現有庫存',
  'move.field.quantity': '數量',
  'move.field.status': '狀態',
  'move.field.type': '類型',
  'move.field.ticketType': '分類',
  'move.type.warehouse': '倉庫互調',
  'move.type.dept': '業務互調',
  'move.type.org': '經銷群互調',
  'move.type.dealer': '經銷商互調',
  'move.ticketType.crossSystem': '跨系統調撥',
  'move.ticketType.MOVE': '調撥',
  'move.ticketType.TRANSFER_IN': '跨系統調入',
  'move.ticketType.TRANSFER_OUT': '跨系統調出',
  'move.status.ACTIVE': '已完成',
  'move.status.INACTIVE': '已刪除',
  'move.status.PENDING': '待審核',
  'move.status.CALLBACK_PENDING': '待三方確認',
  'move.status.ACTION_PENDING': '待到貨',
}
