import React from 'react'
import { Button, Link, Message } from 'components/core'
import { CONFIRM_SET } from 'constants/actionType'
import { MdDelete, MdLock, MdLockOpen } from 'react-icons/md'
import { snakeToCamel } from './string'

export const renderMessage = (text, textProps = {}) => {
  if (typeof text === 'string') {
    return <Message id={text} {...textProps} />
  }
  return <Message {...text} {...textProps} />
}

export const getMessage = (formatter, message) => {
  if (!message) return ''
  if (typeof message === 'string') {
    return formatter({ id: message })
  }

  const { id, values, texts } = message
  let params = values || {}

  if (texts) {
    Object.entries(texts).forEach(([key, val]) => {
      params[key] = formatter({ id: val })
    })
  }

  return formatter({ id }, params)
}

export const renderConfirmButton = ({
  module,
  profile = 'view',
  disabledStatusList = [
    'INACTIVE',
    'ACTIVE',
    'CALLBACK_PENDING',
    'ACTION_PENDING',
    'TIME_PENDING',
  ],
  hideStatusList = null,
  status = 'PENDING',
  label = 'btn.approve',
  session,
  onSubmit,
  titleId = 'title.approve',
  textId = 'message.approve',
}) => {
  if (hideStatusList && hideStatusList.includes(status)) return null

  const name = `module.${module}`
  return (
    <Button
      ml={3}
      show={profile === 'view'}
      disabled={disabledStatusList.includes(status)}
      variant="primary"
      text={label}
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: titleId, texts: { name } },
          text: { id: textId, texts: { name } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}

export const renderCompleteButton = ({
  module,
  profile = 'view',
  status = 'PENDING',
  session,
  onSubmit,
}) => {
  const name = `module.${module}`
  return (
    <Button
      ml={3}
      show={profile === 'view'}
      disabled={['INACTIVE', 'ACTIVE', 'CALLBACK_PENDING'].includes(status)}
      variant="primary"
      text="btn.complete"
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: 'title.complete', texts: { name } },
          text: { id: 'message.complete', texts: { name } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}

export const renderDeleteButton = ({
  module,
  locked = false,
  status = 'PENDING',
  session,
  onSubmit,
}) => {
  const name = `module.${getModuleName(module)}`
  return (
    <Button
      disabled={locked || status === 'INACTIVE'}
      variant="icon"
      icon={<MdDelete />}
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: 'title.delete', texts: { name } },
          text: { id: 'message.delete', texts: { name } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}

function getModuleName(name) {
  let result = name.toLowerCase()

  if (!name.includes('_')) return result
  return snakeToCamel(result)
}

export const renderDeleteLink = ({
  module,
  readonly = false,
  locked = false,
  status = 'PENDING',
  session,
  onSubmit,
}) => {
  const name = `module.${module}`
  return (
    <Link
      ml={3}
      show={readonly}
      disabled={locked || ['INACTIVE'].includes(status)}
      variant="link"
      icon={<MdDelete />}
      tooltip="btn.delete"
      onClick={(event) => {
        const item = {
          open: true,
          title: { id: 'title.delete', texts: { name } },
          text: { id: 'message.delete', texts: { name } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}

export const renderLockLink = ({
  readonly = false,
  locked = false,
  status = 'PENDING',
  onSubmit,
}) => {
  return (
    <Link
      ml={3}
      show={readonly}
      disabled={!['INACTIVE', 'ACTIVE'].includes(status)}
      variant="link"
      icon={locked ? <MdLock /> : <MdLockOpen />}
      tooltip={locked ? 'btn.locked' : 'btn.unlocked'}
      onClick={onSubmit}
    />
  )
}

export function urlencode(input) {
  if (!input || typeof input !== 'string') return ''

  return input
    .split('/')
    .map((item) => encodeURIComponent(item).replace(/%20/g, '+'))
    .join('/')
}
