import React from 'react'
import {
  getSelectOption,
  getSelectOptions,
  handleSelectChange,
  handleTextChange,
  initializeState,
  validateForm,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Select, Switch, TextInput } from 'components/form'
import { Definition } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = (value = {}) => {
  const warehouses = getSelectOptions(value.warehouses)
  return {
    warehouses,
    ...initializeState({
      apiToken: value.apiToken || '',
      warehouseId: getSelectOption(warehouses, value.warehouseId),
      isActive: value.isActive || false,
    }),
  }
}

const validation = {
  apiToken: [{ type: 'required', message: 'error.required' }],
  // warehouseId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    apiToken: (
      <TextInput
        id="apiToken"
        label="mrWarehouse.field.apiToken"
        placeholder="mrWarehouse.field.apiToken"
        value={state.apiToken}
        onChange={onTextChange('apiToken')}
        errMsg={state.__error__.apiToken}
      />
    ),
    warehouseId: (
      <Select
        isClearable={false}
        label="mrWarehouse.field.warehouseId"
        placeholder="mrWarehouse.field.warehouseId"
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="plugin.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ session, app, state, setState }) => ({
  handleLoad: async () => {
    const resp = await getData({ app, session })
    setState(initialState(resp))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await updatePlugin({ session, app, state })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
})

async function updatePlugin({ session, app, state }) {
  const input = {
    apiToken: state.apiToken,
    warehouseId: state.warehouseId.value,
    isActive: state.isActive,
  }
  const variables = { input }
  const query = `
    mutation($input: MrWarehouseInput!) {
      editMrWarehouseConfig(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getData({ app, session }) {
  const variables = {
    input: { type: ['WAREHOUSE'] },
  }
  const query = `
    query($input: LocationQueryInput) {
      merchant {
        extra
      }
      locations(input: $input) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const { plugins } = data.merchant.extra
  const plugin = plugins.find((item) => item.id === 'mrWarehouse')

  return { ...plugin, warehouses: data.locations }
}
