import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { createExcel } from 'utilities/excel'
import { initialPage } from 'utilities/pagination'
import { filters, columns, handlers } from 'models/productList'
import { Center, Link, Section, SplitButton, Table } from 'components/core'
import Page from 'components/Page'
import { MdArrowBack, MdFileUpload } from 'react-icons/md'
import ProductDrawer from './ProductDrawer'
import ComboDrawer from './ComboDrawer'
import ProductImageDrawer from './ProductImageDrawer'

export default ({ match }) => {
  const categoryId = match.params.id
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { formatMessage: message } = useIntl()
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [comboOpen, setComboOpen] = useState(false)
  const [comboValue, setComboValue] = useState({})
  const [imageOpen, setImageOpen] = useState(false)
  const [images, setImages] = useState({})
  const { handleLoad, handleExport } = handlers({
    session,
    app,
    categoryId,
    setState,
    setFilterValues,
    setPagination,
    message,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleComboOpen = (item) => {
    setComboValue(item)
    setComboOpen(true)
  }

  const handleComboClose = () => {
    setComboOpen(false)
    setComboValue({})
  }

  const handleImageOpen = (item) => {
    setImages(item)
    setImageOpen(true)
  }

  const handleImageClose = () => {
    setImageOpen(false)
    setImages({})
  }

  return (
    <>
      <Page
        title="product.title.list"
        navId="productList"
        action={
          <Center>
            <Link
              mr={3}
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/category"
            />
            <Link
              mr={3}
              icon={<MdFileUpload />}
              text="btn.export"
              onClick={() => handleExport({ filterValues, createExcel })}
            />
            <SplitButton
              variant="secondary"
              text="product.btn.addSingle"
              onClick={() => handleOpen({ readonly: false })}
              popover={
                <Link
                  py={2}
                  variant="primaryLink"
                  text="product.btn.addCombo"
                  onClick={() => handleComboOpen({ readonly: false })}
                />
              }
            />
          </Center>
        }
      >
        <Section>
          <Table
            columns={columns({
              app,
              session,
              message,
              action: {
                handleLoad,
                handleOpen,
                handleComboOpen,
                handleImageOpen,
              },
            })}
            rows={state}
            filters={filters}
            filterValues={filterValues}
            pagination={pagination}
            onChange={handleLoad}
          />
        </Section>
      </Page>
      <ProductDrawer
        open={open}
        value={{ categoryId, ...value }}
        action={{ handleClose, handleLoad }}
      />
      <ComboDrawer
        open={comboOpen}
        value={{ categoryId, ...comboValue }}
        action={{ handleClose: handleComboClose, handleLoad }}
      />
      <ProductImageDrawer
        open={imageOpen}
        value={images}
        action={{ handleClose: handleImageClose }}
      />
    </>
  )
}
