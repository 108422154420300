import * as type from 'constants/actionType'
import { isMobile } from 'utilities/device'

export const initialState = {
  token: getLocalJson('token') || { accessToken: '', refreshToken: '' },
  navId: null,
  navOpen: !isMobile(),
  staff: getLocalJson('staff') || {},
  cityMap: getLocalJson('cityMap') || {},
  cityOptions: getLocalJson('cityOptions') || [],
  districtMap: getLocalJson('districtMap') || {},
  districtOptions: getLocalJson('districtOptions') || {},
}

export function reducer(state, action) {
  let token
  switch (action.type) {
    case type.NAV_SET:
      return { ...state, navId: action.id }
    case type.LOGOUT:
      removeLocalJson('token')
      removeLocalJson('cityMap')
      removeLocalJson('cityOptions')
      removeLocalJson('districtMap')
      removeLocalJson('districtOptions')
      return {
        ...state,
        token: { accessToken: '', refreshToken: '' },
        cityMap: {},
        cityOptions: [],
        districtMap: {},
        districtOptions: {},
      }
    case type.NAV_TOGGLE:
      return { ...state, navOpen: !state.navOpen }
    case type.TOKEN_SET:
      setLocalJson('token', action.token)
      return { ...state, token: action.token }
    case type.TOKEN_REMOVE:
      token = { accessToken: '', refreshToken: '' }
      setLocalJson('token', token)
      return { ...state, token }
    case type.STAFF_SET:
      setLocalJson('staff', action.staff)
      return { ...state, staff: action.staff }
    case type.ADDRESS_SET:
      return setAddress(state, action)
    default:
      return state
  }
}

function setAddress(state, action) {
  const { cities, districts } = action
  const cityMap = cities.reduce((result, item) => {
    result[item.name] = item
    return result
  }, {})
  setLocalJson('cityMap', cityMap)

  const cityOptions = cities.map(({ name }) => ({ value: name, label: name }))
  setLocalJson('cityOptions', cityOptions)

  const districtMap = districts.reduce((result, item) => {
    result[`${item.cityName}_${item.name}`] = item
    return result
  }, {})
  setLocalJson('districtMap', districtMap)

  const districtOptions = districts.reduce((result, item) => {
    const { name, cityName } = item
    const option = { value: name, label: name }
    const cityDistricts = result[cityName] || []
    if (cityDistricts.length === 0) result[cityName] = cityDistricts

    cityDistricts.push(option)
    return result
  }, {})
  setLocalJson('districtOptions', districtOptions)

  return { ...state, cityMap, cityOptions, districtMap, districtOptions }
}

function getLocalJson(key) {
  const val = localStorage.getItem(key)
  if (!val) {
    return null
  }
  return JSON.parse(val)
}

function setLocalJson(key, val) {
  localStorage.setItem(key, JSON.stringify(val))
}

function removeLocalJson(key) {
  localStorage.removeItem(key)
}
