import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { Button } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, action }) => [
  {
    id: 'name',
    label: 'repair.field.name',
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <Button
        variant="icon"
        icon={<MdDelete />}
        onClick={() => {
          const name = `module.repairRemark`
          const item = {
            open: true,
            title: { id: 'title.delete', texts: { name } },
            text: { id: 'message.delete', texts: { name } },
            onSubmit: handleDelete({
              session,
              app,
              id: row.id,
              action,
            }),
          }
          session.dispatch({ type: CONFIRM_SET, item })
        }}
      />
    ),
  },
]

export const handlers = ({ session, app, setState }) => ({
  handleLoad: async () => {
    const data = await getData({ session, app })
    setState(data)
  },
})

async function getData({ session, app }) {
  const query = `
    query {
      repairRemarks {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return

  return data.repairRemarks
}

const handleDelete = ({ session, app, id, action }) => async () => {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteRepairRemark(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok) {
    action.handleLoad()
    action.handleClose()
  }

  return ok
}
