import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/staffRolePage'
import { Box } from 'reflexbox'
import {
  Center,
  Button,
  Link,
  Section,
  Grid,
  DataPlaceholder,
  Definition,
  Message,
} from 'components/core'
import Page from 'components/Page'
import PasswordResetModal from 'views/PasswordResetModal'
import { MdArrowBack } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match }) => {
  const id = match.params.id
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [open, setOpen] = useState(false)
  const form = fields({ state, setState, message })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    id,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <Page
        title="staff.title.editRole"
        navId="staffList"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/staff"
            />
            <Button type="submit" variant="primary" ml={3} text="btn.save" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
      >
        <Grid cols={['1fr', '1fr 1fr']} gap={3}>
          <Box>
            <Section title="staff.section.basic">
              <Definition
                direction="row"
                label="staff.field.name"
                value={state.staff.name}
              />
              <Definition
                direction="row"
                label="staff.field.username"
                value={state.staff.username}
              />
              <Definition
                show={!!state.staff.type}
                direction="row"
                label="staff.field.type"
              >
                <Message ml={3} id={`staff.type.${state.staff.type}`} />
              </Definition>
            </Section>
            <Section mt={4} title="staff.section.role">
              {form.role}
            </Section>
            <Section mt={4} title="staff.section.permission">
              {form.permissions}
            </Section>
          </Box>
          <Box>
            <Section title="staff.section.warehouse">
              <Box mt={2}>
                {form.warehouses.length > 0 ? (
                  form.warehouses
                ) : (
                  <DataPlaceholder />
                )}
              </Box>
            </Section>
            <Section mt={5} title="staff.section.dealer">
              <Box mt={2}>
                {form.depts.length > 0 ? form.depts : <DataPlaceholder />}
              </Box>
            </Section>
          </Box>
        </Grid>
      </Page>
      <PasswordResetModal
        open={open}
        value={{ staffId: id }}
        action={{ setOpen }}
      />
    </>
  )
}
