import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { createExcel } from 'utilities/excel'
import { initialState, fields, handlers } from 'models/customerPage'
import { Box } from 'reflexbox'
import { Grid, Link, Section, Toolbar, Button, Center } from 'components/core'
import Page from 'components/Page'
import {
  MdAdd,
  MdArrowBack,
  MdDelete,
  MdEdit,
  MdFileUpload,
} from 'react-icons/md'
import CustomerBasicModal from 'views/CustomerBasicModal'
import CustomerPhoneModal from 'views/CustomerPhoneModal'
import CustomerAddressModal from 'views/CustomerAddressModal'
import CustomerProductDrawer from 'views/CustomerProductDrawer'
import { CONFIRM_SET } from 'constants/actionType'
import CustomerSwitchModal from './CustomerSwitchModal'
import CustomerMemoDrawer from './CustomerMemoDrawer'

export default ({ match, profile }) => {
  const id = match.params.id
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { formatMessage: message } = useIntl()
  const [state, setState] = useState(initialState())
  const [basicOpen, setBasicOpen] = useState(false)
  const [phoneOpen, setPhoneOpen] = useState(false)
  const [phoneType, setPhoneType] = useState('PHONE')
  const [phoneValue, setPhoneValue] = useState({})
  const [addressOpen, setAddressOpen] = useState(false)
  const [addressValue, setAddressValue] = useState({})
  const [memoOpen, setMemoOpen] = useState(false)
  const [memoValue, setMemoValue] = useState({})
  const [productOpen, setProductOpen] = useState(false)
  const [productValue, setProductValue] = useState({})
  const [switchOpen, setSwitchOpen] = useState(false)
  const [switchValue, setSwitchValue] = useState({})
  const [isExtra, setIsExtra] = useState(false)

  const handlePhoneOpen = (type, value = {}) => {
    setPhoneOpen(true)
    setPhoneType(type)
    setPhoneValue(value)
  }

  const handlePhoneClose = () => {
    setPhoneOpen(false)
    setPhoneValue('')
  }

  const handleAddressOpen = (value = {}) => {
    setAddressOpen(true)
    setAddressValue(value)
  }

  const handleAddressClose = () => {
    setAddressOpen(false)
    setAddressValue({})
  }

  const handleMemoOpen = (value) => {
    setMemoOpen(true)
    setMemoValue(value)
  }

  const handleMemoClose = () => {
    setMemoOpen(false)
    setMemoValue({})
  }

  const handleProductOpen = (value, isExtra = false) => {
    setProductOpen(true)
    setProductValue(value)
    setIsExtra(isExtra)
  }

  const handleProductClose = () => {
    setProductOpen(false)
    setProductValue({})
    setIsExtra(false)
  }

  const handleSwitchOpen = (val) => {
    setSwitchValue(val)
    setSwitchOpen(true)
  }

  const handleSwitchClose = () => {
    setSwitchOpen(false)
    setSwitchValue({})
  }

  const handler = handlers({ state, setState, app, session, history, id })
  const form = fields({
    app,
    session,
    state,
    message,
    id,
    action: {
      handlePhoneOpen,
      handleAddressOpen,
      handleLoad: handler.handleLoad,
      handleMemoOpen,
      handleProductOpen,
      handleSwitchOpen,
    },
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Page
      title={`customer.title.${profile}`}
      navId="customerList"
      action={
        <Center>
          <Link
            variant="link"
            icon={<MdArrowBack />}
            text="btn.back"
            href="/customer"
          />
          <Link
            ml={3}
            show={id}
            variant="link"
            icon={<MdDelete />}
            tooltip="btn.delete"
            onClick={() => {
              const item = {
                open: true,
                title: { id: 'customer.title.delete' },
                text: { id: 'customer.message.delete' },
                onSubmit: () => handler.handleDelete({ session, app, id }),
              }
              session.dispatch({ type: CONFIRM_SET, item })
            }}
          />
        </Center>
      }
    >
      <Grid cols={['1fr', '2fr 3fr']} gap={3}>
        <Section title="customer.section.basic">
          <Toolbar>
            <Button
              py={0}
              variant="default"
              icon={<MdEdit />}
              text="btn.edit"
              onClick={() => setBasicOpen(true)}
            />
          </Toolbar>
          {form.id}
          {form.name}
          {form.subscription}
          {form.bip}
          {form.isNotIndividual}
          {form.memo}
        </Section>
        <Box>
          <Section title="customer.section.phone">
            <Toolbar>
              <Button
                py={0}
                variant="default"
                icon={<MdAdd />}
                text="btn.add"
                onClick={() => handlePhoneOpen('PHONE')}
              />
            </Toolbar>
            {form.phones}
          </Section>
          <Section mt={4} title="customer.section.cellphone">
            <Toolbar>
              <Button
                py={0}
                variant="default"
                icon={<MdAdd />}
                text="btn.add"
                onClick={() => handlePhoneOpen('CELLPHONE')}
              />
            </Toolbar>
            {form.cellphones}
          </Section>
          <Section mt={4} title="customer.section.address">
            <Toolbar>
              <Button
                py={0}
                variant="default"
                icon={<MdAdd />}
                text="btn.add"
                onClick={handleAddressOpen}
              />
            </Toolbar>
            {form.addresses}
          </Section>
        </Box>
      </Grid>
      <Section mt={4} title="customer.section.memo">
        <Toolbar>
          <Button
            variant="default"
            icon={<MdAdd />}
            text="btn.add"
            onClick={() => handleMemoOpen({}, false)}
          />
        </Toolbar>
        {form.memos}
      </Section>
      <Section mt={4} title="customer.section.product">
        <Toolbar>
          <Button
            variant="default"
            icon={<MdAdd />}
            text="btn.add"
            onClick={() => handleProductOpen({}, false)}
          />
        </Toolbar>
        {form.products}
      </Section>
      <Section mt={4} title="customer.section.refund">
        {form.refunds}
      </Section>
      <Section mt={4} title="customer.section.repair">
        <Toolbar>
          <Link
            variant="primaryLink"
            sx={{ p: 2, fontSize: 1 }}
            icon={<MdAdd />}
            text="btn.add"
            href={`${process.env.REACT_APP_STOCK_URL}/repair/add?customerId=${state.id}`}
            target="_blank"
          />
          <Link
            variant="primaryLink"
            sx={{ p: 2, fontSize: 1 }}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handler.handleExport({ createExcel, message })}
          />
        </Toolbar>
        {form.repairs}
      </Section>
      <CustomerBasicModal
        open={basicOpen}
        value={state}
        action={{
          handleClose: () => setBasicOpen(false),
          handleLoad: handler.handleLoad,
        }}
      />
      <CustomerPhoneModal
        open={phoneOpen}
        value={{ id, phoneType, phoneValue }}
        action={{
          handleClose: handlePhoneClose,
          handleLoad: handler.handleLoad,
        }}
      />
      <CustomerAddressModal
        open={addressOpen}
        value={{ id, addressValue }}
        action={{
          handleClose: handleAddressClose,
          handleLoad: handler.handleLoad,
        }}
      />
      <CustomerMemoDrawer
        open={memoOpen}
        value={memoValue}
        data={{ id, staffs: state.staffs }}
        action={{
          handleOpen: handleMemoOpen,
          handleClose: handleMemoClose,
          handleLoad: handler.handleLoad,
        }}
      />
      <CustomerProductDrawer
        readonly={isExtra}
        open={productOpen}
        value={productValue}
        data={{
          id,
          dealers: state.dealerOptions,
          products: state.productOptions,
        }}
        action={{
          handleOpen: handleProductOpen,
          handleClose: handleProductClose,
          handleLoad: handler.handleLoad,
        }}
      />
      <CustomerSwitchModal
        value={switchValue}
        open={switchOpen}
        onClose={handleSwitchClose}
        onSubmit={() => {
          handler.handleLoad()
          handleSwitchClose()
        }}
      />
    </Page>
  )
}
