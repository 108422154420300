import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import {
  initialState,
  filters,
  columns,
  storageColumns,
  fields,
  handlers,
} from 'models/pxmartPage'
import { Box } from 'reflexbox'
import { initialPage } from 'utilities/pagination'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  Table,
  Toolbar,
} from 'components/core'
import Page from 'components/Page'
import { MdAdd, MdArrowBack } from 'react-icons/md'
import PxmartCodeModal from 'views/PxmartCodeModal'
import PxmartSyncModal from './PxmartSyncModal'
import PxmartStorageModal from './PxmartStorageModal'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import PxmartUploadModal from './PxmartUploadModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [openStorage, setOpenStorage] = useState(false)
  const [syncOpen, setSyncOpen] = useState(false)
  const [syncType, setSyncType] = useState('syncPxmartOrders')
  const [uploadOpen, setUploadOpen] = useState(false)

  const form = fields({ app, session, state, setState })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (type) => {
    setSyncOpen(true)
    setSyncType(type)
  }

  const handleClose = () => {
    setSyncOpen(false)
    setSyncType('syncPxmartOrders')
  }

  const handleCodeOpen = (item) => {
    setOpen(true)
    setValue(item)
  }

  const handleCodeClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <>
      <Page
        navId="pluginList"
        title="pxmart.title.edit"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/plugin"
            />
            <Button
              ml={3}
              variant="primary"
              text="btn.save"
              onClick={useCallbackOnce(handler.handleSubmit)}
            />
          </Center>
        }
        showFooterAction
      >
        <Grid cols={['1fr', '2fr 1fr']} gap={3}>
          <Section title="plugin.section.basic">
            <Grid cols={['1fr', '1fr 1fr']} gap={3}>
              {form.username}
              {form.password}
              {form.dealerId}
              {form.syncTime}
              {form.warehouseGroupId}
              {form.warehouseId}
              {form.refundWarehouseId}
              {form.shippingId}
            </Grid>
          </Section>
          <Box>
            <Section title="pxmart.section.autoSync">{form.isActive}</Section>
            <Section mt={4} title="pxmart.section.manualSync">
              <Box>
                <Button
                  variant="default"
                  text="pxmart.btn.syncDispatch"
                  onClick={() => handleOpen('syncPxmartOrders')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="pxmart.btn.syncRefund"
                  onClick={() => handleOpen('syncPxmartRefunds')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="pxmart.btn.uploadDispatch"
                  onClick={() => setUploadOpen(true)}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="pxmart.btn.downloadDispatch"
                  onClick={() => handleOpen('downloadPxmartOrders')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="pxmart.btn.downloadRefund"
                  onClick={() => handleOpen('downloadPxmartRefunds')}
                />
              </Box>
            </Section>
          </Box>
        </Grid>
        <Section mt={4} title="pxmart.section.refundStorage">
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => setOpenStorage(true)}
            />
          </Toolbar>
          <Table
            columns={storageColumns({ state, setState })}
            rows={state.refundStorages}
          />
        </Section>
        <Section mt={4} title="pxmart.section.code">
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => setOpen(true)}
            />
          </Toolbar>
          <Table
            mt={3}
            columns={columns({
              state,
              setState,
              action: { handleOpen: handleCodeOpen },
            })}
            rows={state.codes}
            filters={filters}
            filterValues={filterValues}
            pagination={pagination}
            onChange={handler.handleLoad}
          />
        </Section>
      </Page>
      <PxmartSyncModal
        open={syncOpen}
        data={{ syncType }}
        action={{ handleClose }}
      />
      <PxmartUploadModal
        open={uploadOpen}
        action={{ handleClose: () => setUploadOpen(false) }}
      />
      <PxmartStorageModal
        data={{
          storages: state.storages,
          refundStorages: state.refundStorages,
        }}
        open={openStorage}
        action={{
          handleClose: () => setOpenStorage(false),
          addStorage: handler.addStorage,
        }}
      />
      <PxmartCodeModal
        value={value}
        data={{ products: state.products }}
        open={open}
        action={{
          handleClose: handleCodeClose,
          addCode: handler.addCode,
        }}
      />
    </>
  )
}
