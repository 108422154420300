import { request } from 'utilities/request'
import { textFilter, dateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { showAddress, showDate } from 'utilities/form'

export const filters = [
  {
    id: 'transDate',
    label: 'report.field.ticketDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'repairDate',
    label: 'report.field.repairDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'partName',
    label: 'report.field.partName',
    input: textFilter,
  },
]

export const columns = ({ message }) => [
  {
    id: 'repairId',
    label: 'report.field.repairNo',
    format: ['html', 'excel'],
  },
  {
    id: 'transDate',
    label: 'report.field.ticketDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'repairDate',
    label: 'report.field.repairDate',
    format: ['html', 'excel'],
  },
  {
    id: 'techName',
    label: 'report.field.tech',
    format: ['html', 'excel'],
    render: ({ row }) => (row.techName ? row.techName.join(', ') : ''),
  },
  {
    id: 'repairType',
    label: 'repair.field.type',
    format: ['excel'],
  },
  {
    id: 'symptom',
    label: 'report.field.symptom',
    format: ['excel'],
  },
  {
    id: 'solution',
    label: 'report.field.solution',
    format: ['excel'],
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
  },
  {
    id: 'tag',
    label: 'repair.field.tagId',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.tagName) return null
      return row.tagName.join(', ')
    },
  },
  {
    id: 'commission',
    label: 'repair.field.commission',
    format: ['excel'],
    render: ({ row }) => row.commission,
  },
  {
    id: 'recipientContact',
    label: 'report.field.recipientContact',
    format: ['excel'],
  },
  {
    id: 'phone',
    label: 'report.field.phone',
    format: ['excel'],
  },
  {
    id: 'cellphone',
    label: 'report.field.cellphone',
    format: ['excel'],
  },
  {
    id: 'address',
    label: 'report.field.address',
    format: ['excel'],
    render: ({ row }) => (row.address ? showAddress(row.address, message) : ''),
  },
  {
    id: 'productName',
    label: 'report.field.productName',
    format: ['excel'],
  },
  {
    id: 'productModel',
    label: 'report.field.productModel',
    format: ['excel'],
  },
  {
    id: 'productSerialNo',
    label: 'report.field.productSerialNo',
    format: ['excel'],
  },
  {
    id: 'productMemo',
    label: 'report.field.productMemo',
    format: ['excel'],
  },
  {
    id: 'partName',
    label: 'report.field.partName',
    format: ['html', 'excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'price',
    label: 'field.subTotal',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'receiptNo',
    label: 'report.field.receiptNo',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.receipts) return null
      return row.receipts.map((item) => item.receiptNo).join(', ')
    },
  },
  {
    id: 'receiptDate',
    label: 'report.field.receiptDate',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.receipts) return null
      return row.receipts.map((item) => item.transDate).join(', ')
    },
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.repairList'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: RepairReportInput) {
      repairReportCount(input: $input)
      repairReports(input: $input) {
        repairDate
        transDate
        repairId
        receiptNo
        techName
        partName
        quantity
        price
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.repairReports,
    pagination: getPagination(page, countPerPage, data.repairReportCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const input = getDataInput({ filterValues })
  input.joinProduct = true
  const variables = { input }
  const query = `
    query($input: RepairReportInput) {
      repairReports(input: $input) {
        repairType
        repairDate
        transDate
        repairId
        receipts
        techName
        phone
        cellphone
        address
        partId
        partName
        productVariantName
        productModel
        productSerialNo
        productMemo
        quantity
        price
        memo
        recipientContact
        symptom
        solution
        tagName
        commission
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return formatData(data.repairReports)
}

function formatData(repairReports) {
  const result = []
  const keySet = new Set()
  const productMap = {}

  for (let item of repairReports) {
    const key = `${item.repairId}_${item.partId}`
    const product = {
      name: item.productVariantName,
      model: item.productModel,
      serialNo: item.productSerialNo,
      memo: item.productMemo,
    }
    const products = productMap[item.repairId] || []
    products.push(product)
    productMap[item.repairId] = products

    if (keySet.has(key)) continue

    result.push(item)
    keySet.add(key)
  }

  for (let item of result) {
    const product = productMap[item.repairId] || []
    item.productName = product.map((i) => i.name).join(', ')
    item.productModel = product.map((i) => i.model).join(', ')
    item.productSerialNo = product.map((i) => i.serialNo).join(', ')
    item.productMemo = product.map((i) => i.memo).join(', ')
  }

  return result
}

function getDataInput({ filterValues = [], pagination }) {
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const repairDate = filterValues.find(({ id }) => id === 'repairDate')
  const partName = filterValues.find(({ id }) => id === 'partName')
  const input = {
    transDate: transDate?.value,
    repairDate: repairDate?.value,
    partName: partName?.value,
    orderBy: [
      // { key: 'repairDate', sort: 'DESC' },
      { key: 'repairId', sort: 'DESC' },
    ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
