export default {
  'error.product.duplicate': '商品名稱已存在',
  'error.productVariant.duplicate': '品號已存在',
  'product.title': '商品管理',
  'product.title.list': '商品目錄',
  'product.title.add': '新增商品',
  'product.title.edit': '更改商品',
  'product.title.view': '商品詳情',
  'product.title.delete': '刪除商品',
  'product.title.inactivate': '停用商品',
  'product.title.image': '商品圖示',
  'product.btn.addSingle': '新增單品',
  'product.btn.addCombo': '新增組合',
  'product.message.delete': '確認刪除商品?',
  'product.message.inactivate': '商品尚有庫存，確認停用商品?',
  'product.section.basic': '基礎信息',
  'product.section.channel': '商品通路',
  'product.section.category': '商品類別',
  'product.section.tag': '商品標籤',
  'product.section.shipping': '物流信息',
  'product.section.image': '商品示圖',
  'product.section.price': '商品售價',
  'product.section.inventory': '商品庫存',
  'product.section.variant': '商品屬性',
  'product.placeholder.option.key': '尺寸/顏色/材料...',
  'product.field.image': '圖示',
  'product.field.category': '商品類別',
  'product.field.type': '類別',
  'product.field.vendor': '三方庫存同步',
  'product.field.spu': '商品名稱',
  'product.field.spuAndSku': '商品名稱 / 品號',
  'product.field.postedPrice': '定價',
  'product.field.price': '員購價',
  'product.field.spec': '規格',
  'product.field.desc': '備註',
  'product.field.sku': '品號',
  'product.field.barcode': '國際條碼',
  'product.field.code': '原廠代碼',
  'product.field.quantity': '數量',
  'product.field.option': '選項',
  'product.field.variant': '屬性',
  'product.field.boxUnit': '箱入數',
  'product.field.boxLength': '外箱長(cm)',
  'product.field.boxWidth': '外箱寬(cm)',
  'product.field.boxHeight': '外箱高(cm)',
  'product.field.boxWeight': '外箱重(kg)',
  'product.field.expireDate': '原始效期(天)',
  'product.field.isActive': '使用狀態',
  'product.vendor.sdj': '翔得捷',
  'product.vendor.mrWarehouse': '新慎康倉管',
  'product.message.variant': '此產品有多個選項，例如不同的尺寸或顏色',
  'product.image.title.alt': '編輯圖片替代文字',
  'product.image.field.alt': '圖片替代文字',
  'product.variant.title.add': '新增屬性',
  'product.variant.section.product': '商品信息',
  'product.variant.section.option': '商品選項',
  'product.variant.section.image': '商品示圖',
  'product.variant.section.basic': '庫存信息',
  'product.variant.btn.add': '新增屬性',
  'product.variant.option.btn.add': '新增選項',
  'product.variant.option.key': '選項名稱',
  'product.variant.option.val': '選項值',
  'product.status.ACTIVE': '啟用',
  'product.status.INACTIVE': '停用',
  'product.type.SINGLE': '單品',
  'product.type.COMBO': '組合',
}
