import React, { Suspense, lazy } from 'react'
import { convertDate, createExcel, readExcel } from 'utilities/excel'
import {
  getSelectOptions,
  handleTextChange,
  initializeState,
  validateForm,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Center, LoadingIcon } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'

const FileInput = lazy(() => import('components/file/FileInput'))

export const initialState = (value = {}) => {
  const locationMap = value.locations?.reduce((result, item) => {
    result[item.name] = item.id
    return result
  }, {})

  const productMap = value.products?.reduce((result, item) => {
    result[item.sku] = item.id
    return result
  }, {})

  return {
    locations: value.locations || [],
    locationMap,
    products: getSelectOptions(value.products, 'id', 'sku'),
    productMap,
    ticketItems: value.ticketItems || [],
    ...initializeState({
      file: '',
    }),
  }
}

const columns = [
  { id: 'ticketNo', label: 'adjust.field.ticketNo' },
  { id: 'transDate', label: 'adjust.field.transDate' },
  { id: 'toLocationId', label: 'adjust.field.toLocation' },
  { id: 'memo', label: 'adjust.field.memo' },
  { id: 'productVariantId', label: 'adjust.field.sku' },
  { id: 'quantity', label: 'field.quantity' },
]

const validation = {
  file: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    file: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <FileInput
          id="file"
          label="adjust.field.importFile"
          accept=".xlsx"
          value={state.file}
          onUpload={onTextChange('file')}
          onDelete={onTextChange('file')}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
          errMsg={state.__error__.file}
        />
      </Suspense>
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  message,
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session })
    setState(initialState(data))
  },
  downloadTemplate: () => {
    const title = 'adjust.message.template'
    createExcel({ message, title, timestamp: false, cols: columns, rows: [] })
  },
  loadExcel: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const excelFile = await readFile(state.file)
    const data = await readExcel(excelFile)

    const ticketItems = data.map((item) => {
      const result = {}
      columns.forEach(({ id, label }) => {
        let value = item[message({ id: label })]
        if (typeof value === 'string') value = value.trim()

        const toLocationId = state.locationMap[value]
        const productId = state.productMap[value]

        switch (id) {
          case 'toLocationId':
            value = toLocationId ? { value: toLocationId, label: value } : null
            break
          case 'productVariantId':
            value = productId ? { value: productId, label: value } : null
            break
          case 'transDate':
            value = convertDate(value)
            break
          default:
        }
        result[id] = value
      })
      return result
    })

    setState({ ...state, ticketItems })
    action.setOpen(true)
  },
})

async function getData({ app, session }) {
  const locationInput = { type: ['WAREHOUSE', 'DEPT', 'DEALER_ORG', 'DEALER'] }
  const variables = { locationInput }
  const query = `
    query($locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      productVariants {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { locations, productVariants } = data

  return {
    locations,
    products: productVariants,
  }
}

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}
