export default {
  'error.process.missingFromLocationId': '取倉為必填欄位',
  'error.process.missingToLocationId': '轉倉為必填欄位',
  'error.process.missingLocationId': '取倉/轉倉為必填欄位',
  'process.title.list': '加工目錄',
  'process.title.add': '新增加工單',
  'process.title.edit': '修改加工單',
  'process.title.view': '加工單詳情',
  'process.title.addPackFrom': '新增組合前商品',
  'process.title.editPackFrom': '修改組合前商品',
  'process.title.addPackTo': '新增組合後商品',
  'process.title.editPackTo': '修改組合後商品',
  'process.title.addUnpackFrom': '新增拆解前商品',
  'process.title.editUnpackFrom': '修改拆解前商品',
  'process.title.addUnpackTo': '新增拆解後商品',
  'process.title.editUnpackTo': '修改拆解後商品',
  'process.title.addMaterial': '加工包材',
  'process.title.complete': '強制完成加工單',
  'process.title.print': '加工單 - {ticketId}',
  'process.message.complete': '確認強制完成加工單',
  'process.section.fromPackItems': '組合前商品',
  'process.section.toPackItems': '組合後商品',
  'process.section.fromUnpackItems': '拆解前商品',
  'process.section.toUnpackItems': '拆解後商品',
  'process.section.process': '加工步驟',
  'process.section.image': '加工圖示',
  'process.section.print': '標籤機印製檔',
  'process.section.material': '加工包材',
  'process.section.basic': '基礎信息',
  'process.btn.complete': '完成',
  'process.field.id': '單號',
  'process.field.processFilter': '輸入搜尋加工模板名稱',
  'process.field.processConfig': '加工模板',
  'process.field.finishDate': '預計完工日',
  'process.field.transDate': '下單日期',
  'process.field.processType': '類型',
  'process.field.processName': '加工項目',
  'process.field.memo': '備註',
  'process.field.fromWarehouse': '取倉',
  'process.field.toWarehouse': '轉倉',
  'process.field.product': '產品名稱',
  'process.field.quantityPiece': '數量(PCS)',
  'process.field.quantityInnerBox': '內箱總數量',
  'process.field.quantityOuterBox': '外箱總數量',
  'process.field.locationId': '所屬業務 / 經銷群',
  'process.field.ticketNo': '進倉單號',
  'process.field.processItemPrice': '加工金額',
  // 'process.field.toExpireAt': '效期',
  // 'process.field.toBatchNo': '批號',
  'process.field.expireAt': '效期',
  'process.field.batchNo': '批號',
  'process.field.spu': '商品名稱',
  'process.field.sku': '品號',
  'process.field.unitQuantity': '單位數量',
  'process.field.unit': '單位',
  'process.field.price': '單價',
  'process.field.subPrice': '小計',
  'process.field.balance': '庫存數量',
  'process.field.materialMemo': '備註',
  'process.field.quantity': '數量',
  'process.field.status': '狀態',
  'process.field.weightFactor': '重物加成',
  'process.field.totalPrice': '總價',
  'process.field.materialTotalPrice': '包材總價',
  'process.type.PACK_SINGLE': '單品加工',
  'process.type.UNPACK_SINGLE': '單品拆解',
  'process.type.PACK_COMBO': '組合加工',
  'process.type.UNPACK_COMBO': '組合拆解',
  'process.status.ACTIVE': '已完成',
  'process.status.INACTIVE': '已刪除',
  'process.status.PENDING': '待審核',
  'process.status.CALLBACK_PENDING': '待三方確認',
  'process.status.ACTION_PENDING': '待完成',
}
