export default {
  'vendor.sdj': '翔得捷',
  'error.sdj.api': '[翔得捷] {message}',
  'error.sdj.invalidLocation': '[翔得捷] 選取倉別有誤！',
  'error.sdj.invalidWarehouse': '[翔得捷] 選取倉別有誤！',
  'error.sdj.invalidShipping': '[翔得捷] 選取物流有誤！',
  'error.sdj.invalidProductCode': '[翔得捷] 查無全聯商品代碼！',
  'error.sdj.productAdd': '[翔得捷] 新增商品失敗！',
  'error.sdj.purchaseAdd': '[翔得捷] 新增進倉單失敗！',
  'error.sdj.purchaseCancel': '[翔得捷] 更新進倉單失敗！',
  'error.sdj.dispatchAdd': '[翔得捷] 新增訂單失敗！',
  'error.sdj.moveAdd': '[翔得捷] 新增調撥單失敗！',
  'error.sdj.processAdd': '[翔得捷] 新增加工單失敗！',
  'error.sdj.refundAdd': '[翔得捷] 新增退貨單失敗！',
  'sdj.title.stockDiffList': '翔得捷庫存差異',
  'plugin.section.basic': '基礎信息',
  'plugin.section.warehouse': '倉別對應設定',
  'plugin.section.shipping': '物流對應設定',
  'sdj.btn.dispatchSync': '立即出貨',
  'sdj.title.dispatchSync': '立即出貨',
  'sdj.message.dispatchSync': '確認立即出貨？',
  'sdj.title.purchaseDiff': '翔得捷進倉差異',
  'sdj.field.createdAt': '回調日期',
  'sdj.field.errorKey': '單號',
  'sdj.field.message': '註釋',
  'sdj.title.dispatchPending': '翔得捷待出貨列表',
  'sdj.field.sendDate': '出貨日期',
  'sdj.status.PENDING': '待同步',
  'sdj.status.FAILED': '同步失敗',
}
