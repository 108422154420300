export default {
  'account.title.view': '帳戶管理',
  'account.title.passwordEdit': '修改密碼',
  'account.btn.editPassword': '修改密碼',
  'account.success.passwordEdit': '成功修改登錄密碼',
  'account.field.username': '帳號',
  'account.field.password': '密碼',
  'account.field.newPassword': '新密碼',
  'account.field.newPasswordConfirm': '確認新密碼',
  'account.field.name': '姓名',
  'account.field.type': '部門',
  'account.field.role': '權限角色',
}
