export default {
  'repairFeedback.title.list': '滿意度調查',
  'repairFeedback.title.edit': '新增滿意度',
  'repairFeedback.title.view': '滿意度詳情',
  'repairFeedback.section.customer': '客戶資料',
  'repairFeedback.section.repair': '維修資料',
  'repairFeedback.section.feedback': '滿意度調查',
  'repairFeedback.field.scoreOverall': '專業滿意度',
  'repairFeedback.field.scoreAttitude': '親切滿意度',
  'repairFeedback.field.scoreTechnical': '整體滿意度',
}
