import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { columns, filters, handlers } from 'models/repairList'
import { SessionContext, AppContext } from 'contexts'
import { createExcel } from 'utilities/excel'
import { initialPage } from 'utilities/pagination'
import { Link, Table, Section, Center } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload, MdPrint } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [repairTypes, setRepairTypes] = useState([])
  const [repairTags, setRepairTags] = useState([])
  const [dealers, setDealers] = useState([])
  const [techs, setTechs] = useState([])
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad, handlePrint, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
    setDealers,
    setTechs,
    setRepairTypes,
    setRepairTags,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="repair.title.list"
      navId="repairList"
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdPrint />}
            text="btn.print"
            onClick={() => handlePrint({ filterValues })}
          />
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
          {/* <LinkButton
            mr={2}
            variant="secondary"
            text="btn.add"
            href="/repair/add"
          /> */}
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({ session, app, message, action: { handleLoad } })}
          rows={state}
          filters={filters(
            { repairTypes, repairTags, dealers, techs },
            message,
          )}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
