import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { Flex } from 'reflexbox'
import { Button, Table } from 'components/core'
import { Switch, TextInput } from 'components/form'
import { MdDelete } from 'react-icons/md'
import { renderMessage } from 'utilities/app'

export const initialState = (value = {}) => ({
  name: value.name || '',
  sdjShippings: value.sdjShippings || [],
  warehouses: value.warehouses || [],
  shippings: value.shippings || [],
  warehouseList: value.warehouseList || [],
  shippingList: value.shippingList || [],
  ...initializeState({
    auth: value.auth || '',
    secret: value.secret || '',
    isActive: value.isActive || false,
  }),
})

const validation = {
  auth: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)

  return {
    auth: (
      <TextInput
        id="auth"
        label="plugin.field.auth"
        placeholder="plugin.field.auth"
        value={state.auth}
        onChange={onTextChange('auth', async (auth) => {
          const sdjLocation = await getSdjLoctions({ app, session, auth })
          return { sdjLocation }
        })}
        errMsg={state.__error__.auth}
      />
    ),
    secret: (
      <TextInput
        id="secret"
        label="plugin.field.secret"
        placeholder="plugin.field.secret"
        value={state.secret}
        onChange={onTextChange('secret')}
        errMsg={state.__error__.secret}
      />
    ),
    isActive: (
      <Flex alignItems="center">
        {renderMessage('plugin.field.isActive', { flex: 1 })}
        <Switch
          checked={state.isActive}
          onClick={() => setState({ ...state, isActive: !state.isActive })}
        />
      </Flex>
    ),
    warehouseList: (
      <Table
        columns={[
          {
            id: 'sdjLocationId',
            label: 'plugin.field.sdjWarehouse',
          },
          {
            id: 'locationName',
            label: 'plugin.field.warehouse',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const warehouseList = [...state.warehouseList]
                  warehouseList.splice(index, 1)
                  setState({ ...state, warehouseList })
                }}
              />
            ),
          },
        ]}
        rows={state.warehouseList}
      />
    ),
    shippingList: (
      <Table
        columns={[
          {
            id: 'sdjLocationName',
            label: 'plugin.field.sdjShipping',
          },
          {
            id: 'locationName',
            label: 'plugin.field.shipping',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const shippingList = [...state.shippingList]
                  shippingList.splice(index, 1)
                  setState({ ...state, shippingList })
                }}
              />
            ),
          },
        ]}
        rows={state.shippingList}
      />
    ),
  }
}

export const handlers = ({ session, app, state, setState, history }) => ({
  handleLoad: async () => {
    const data = await getData({ app, session })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await updatePlugin({ session, app, state })
    if (!ok) return

    history.push(`/plugin`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  addWarehouse: (value) => {
    const warehouseList = [...state.warehouseList, value]
    setState({ ...state, warehouseList })
  },
  addShipping: (value) => {
    const shippingList = [...state.shippingList, value]
    setState({ ...state, shippingList })
  },
})

async function updatePlugin({ session, app, state }) {
  const locations = [
    ...state.warehouseList.map((item) => ({
      locationId: item.locationId,
      code: item.sdjLocationId,
    })),
    ...state.shippingList.map((item) => ({
      locationId: item.locationId,
      code: item.sdjLocationId,
    })),
  ]
  const input = {
    id: 'sdj',
    name: state.name,
    auth: state.auth,
    secret: state.secret,
    locations,
    isActive: state.isActive,
  }
  const variables = { input }
  const query = `
    mutation($input: SdjInput!) {
      editSdjConfig(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getData({ app, session }) {
  const input = { type: ['WAREHOUSE', 'COMPANY'] }
  const variables = { input }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        type
        name
        extra
      }
      merchant {
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { locations, merchant } = data
  const { plugins } = merchant.extra
  const plugin = plugins.find((item) => item.id === 'sdj')
  const sdjLocations = await getSdjLoctions({ app, session, auth: plugin.auth })
  const sdjShippings = sdjLocations.map(({ code, name }) => ({
    value: code,
    label: name,
  }))
  const warehouses = []
  const shippings = []
  const warehouseList = []
  const shippingList = []

  locations.forEach(({ id, name, type, extra }) => {
    const { sdj } = extra

    if (type === 'WAREHOUSE') {
      warehouses.push({ value: id, label: name })
      if (sdj) {
        const { locationName } = sdj
        warehouseList.push({
          locationId: id,
          locationName: name,
          sdjLocationId: locationName,
        })
      }
    } else if (extra.type === 'SHIPPING') {
      shippings.push({ value: id, label: name })
      if (sdj) {
        const { locationName } = sdj
        const shipping = sdjShippings.find(
          ({ value }) => value === locationName,
        )
        if (shipping) {
          shippingList.push({
            locationId: id,
            locationName: name,
            sdjLocationId: shipping.value,
            sdjLocationName: shipping.label,
          })
        }
      }
    }
  })
  return {
    ...plugin,
    sdjShippings,
    warehouses,
    shippings,
    warehouseList,
    shippingList,
  }
}

async function getSdjLoctions({ app, session, auth }) {
  if (!auth || auth.length !== 64) return []
  const variables = { auth }
  const query = `
    query($auth: String) {
      sdjLocations(auth: $auth) {
        code
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.sdjLocations
}
