import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { filters, columns, handlers } from 'models/dealerList'
import { Button, Center, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { MdFileUpload } from 'react-icons/md'

export default () => {
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState()
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad, handleExport } = handlers({
    session,
    app,
    data,
    message,
    setState,
    setFilterValues,
    setPagination,
    setData,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="dealer.title.list"
      navId="dealerList"
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
          <Button
            variant="secondary"
            text="btn.add"
            onClick={() => history.push('/dealer/add')}
          />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({
            session,
            app,
            data,
            message,
            action: { handleLoad },
          })}
          rows={state}
          filters={filters({ data })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
