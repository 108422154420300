import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Select, Switch, TextInput } from 'components/form'
import { Button, Definition } from 'components/core'
import { MdDelete } from 'react-icons/md'
import { getLimit, getPagination } from 'utilities/pagination'
import { textFilter } from 'utilities/filter'
import { ALERT_ADD } from 'constants/actionType'
import { getDiff } from 'utilities/list'

export const initialState = (value = {}, data = {}) => {
  const dealers = data.dealers || []
  const warehouses = data.warehouses || []
  const shippings = data.shippings || []
  return {
    dealers,
    warehouses,
    shippings,
    storages: data.storages || [],
    storageMap: data.storageMap || {},
    oldLocationCodes: cloneDeep(value.locationCodes || []),
    locationCodes: value.locationCodes || [],
    ...initializeState({
      username: value.username || '',
      password: value.password || '',
      syncTime1: value.syncTime1 || '09:00',
      syncTime2: value.syncTime2 || '10:00',
      dealerId: getSelectOption(dealers, value.dealerId),
      warehouseId: getSelectOption(warehouses, value.warehouseId),
      shippingId: getSelectOption(shippings, value.shippingId),
      isActive: value.isActive || false,
    }),
  }
}

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
  syncTime1: [{ type: 'required', message: 'error.required' }],
  dealerId: [{ type: 'required', message: 'error.required' }],
  warehouseId: [{ type: 'required', message: 'error.required' }],
  shippingId: [{ type: 'required', message: 'error.required' }],
}

export const filters = [
  {
    id: 'locationName',
    label: 'carrefour.field.storageName',
    input: textFilter,
  },
  {
    id: 'code',
    label: 'carrefour.field.storageCode',
    input: textFilter,
  },
]

export const columns = ({ state, setState }) => [
  {
    id: 'locationName',
    label: 'carrefour.field.storageName',
  },
  {
    id: 'code',
    label: 'carrefour.field.storageCode',
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ index }) => (
      <Button
        variant="icon"
        icon={<MdDelete />}
        onClick={() => {
          const locationCodes = [...state.locationCodes]
          locationCodes.splice(index, 1)
          setState({ ...state, locationCodes })
        }}
      />
    ),
  },
]

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    username: (
      <TextInput
        id="username"
        label="carrefour.field.username"
        placeholder="carrefour.field.username"
        value={state.username}
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        id="password"
        label="carrefour.field.password"
        placeholder="carrefour.field.password"
        value={state.password}
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    syncTime1: (
      <TextInput
        type="time"
        id="syncTime1"
        label="carrefour.field.syncTime1"
        placeholder="carrefour.field.syncTime1"
        value={state.syncTime1}
        onChange={onTextChange('syncTime1')}
        errMsg={state.__error__.syncTime1}
      />
    ),
    syncTime2: (
      <TextInput
        type="time"
        id="syncTime2"
        label="carrefour.field.syncTime2"
        placeholder="carrefour.field.syncTime2"
        value={state.syncTime2}
        onChange={onTextChange('syncTime2')}
        errMsg={state.__error__.syncTime2}
      />
    ),
    dealerId: (
      <Select
        isClearable={false}
        label="field.dealer"
        placeholder="field.dealer"
        options={state.dealers}
        value={state.dealerId}
        onChange={onSelectChange('dealerId')}
        errMsg={state.__error__.dealerId}
      />
    ),
    warehouseId: (
      <Select
        label="carrefour.field.dispatchWarehouse"
        placeholder="carrefour.field.dispatchWarehouse"
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    shippingId: (
      <Select
        label="field.shipping"
        placeholder="field.shipping"
        options={state.shippings}
        value={state.shippingId}
        onChange={onSelectChange('shippingId')}
        errMsg={state.__error__.shippingId}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="plugin.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  data,
  setData,
  setFilterValues,
  setPagination,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ app, session })
    resp.storageMap = resp.storages.reduce((result, item) => {
      result[item.id] = item.name
      return result
    }, {})
    setData(resp)
  },
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ app, session, pagination, filterValues })
    setState(initialState(resp, data))
    setFilterValues(filterValues)
    setPagination(resp.pagination)
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await updatePlugin({ session, app, state })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  addStorage: (value) => {
    const locationCodes = [...state.locationCodes, value]
    setState({ ...state, locationCodes })
  },
})

async function updatePlugin({ session, app, state }) {
  const { oldLocationCodes, locationCodes } = state

  const isKeyEqual = (item, newItem) => {
    return item.locationId === newItem.locationId
  }
  const isValEqual = (item, newItem) => {
    if (item.code !== newItem.code) return false
    return true
  }
  const diff = getDiff(oldLocationCodes, locationCodes, isKeyEqual, isValEqual)
  const locationToAdd = diff.added.map((item) => ({
    locationId: item.locationId,
    locationName: item.locationName,
    code: item.code,
  }))
  const locationToEdit = diff.modified.map(({ after }) => ({
    locationId: after.locationId,
    locationName: after.locationName,
    code: after.code,
  }))

  const input = {
    id: 'carrefour',
    username: state.username,
    password: state.password,
    syncTime1: state.syncTime1,
    syncTime2: state.syncTime2,
    dealerId: state.dealerId?.value,
    warehouseId: state.warehouseId?.value,
    shippingId: state.shippingId?.value,
    isActive: state.isActive,
    locationToAdd,
    locationToEdit,
    locationToDel: diff.removed.map((item) => item.locationId),
  }

  const variables = { input }
  const query = `
    mutation($input: CarrefourInput!) {
      editCarrefourConfig(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getInit({ app, session }) {
  const variables = {
    input: { type: ['DEALER', 'WAREHOUSE', 'COMPANY', 'STORAGE'] },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        parentId
        name
        type
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const warehouses = []
  const dealers = []
  const storages = []
  const shippings = []

  data.locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'WAREHOUSE':
        warehouses.push(result)
        break
      case 'DEALER':
        dealers.push(result)
        break
      case 'STORAGE':
        storages.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  return { dealers, storages, warehouses, shippings }
}

async function getData({ app, session, pagination, filterValues }) {
  const variables = {
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: LocationCodeQueryInput) {
      merchant {
        extra
      }
      locationCodeCount(input: $input)
      locationCodes(input: $input) {
        locationId
        locationName
        code
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const { plugins } = data.merchant.extra
  const plugin = plugins.find((item) => item.id === 'carrefour')
  const { page, countPerPage } = pagination

  return {
    ...plugin,
    locationCodes: data.locationCodes,
    pagination: getPagination(page, countPerPage, data.locationCodeCount),
  }
}

function getDataInput({ filterValues, pagination }) {
  const locationName = filterValues.find(({ id }) => id === 'locationName')
  const code = filterValues.find(({ id }) => id === 'code')
  const input = {
    vendor: 'carrefour',
    locationName: locationName?.value || null,
    code: code?.value || null,
    orderBy: [{ key: 'locationName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
