import React from 'react'
import { request } from 'utilities/request'
import { Box } from 'reflexbox'
import { Button, Link } from 'components/core'
import { Checkbox } from 'components/form'
import { showAddress } from 'utilities/form'
import { ALERT_ADD, CONFIRM_SET, FILTER_SET } from 'constants/actionType'
import { textFilter } from 'utilities/filter'
import { getNumericValue } from 'utilities/string'
import { MdDelete } from 'react-icons/md'
import { handleDelete } from './customerPage'

export const filters = [
  {
    id: 'name',
    label: 'customer.field.name',
    input: textFilter,
  },
  {
    id: 'phone',
    label: 'customer.field.phoneAndCellphone',
    input: textFilter,
  },
  {
    id: 'address',
    label: 'customer.field.address',
    input: textFilter,
  },
  // {
  //   id: 'createdAt',
  //   label: 'customer.field.createdAt',
  //   op: (
  //     <Select
  //       containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
  //       isSearchable={false}
  //       isClearable={false}
  //       placeholder="filter.placeholder"
  //       options={[
  //         {
  //           value: 'EQ',
  //           label: message({ id: 'filter.op.eq' }),
  //         },
  //         {
  //           value: 'GE',
  //           label: message({ id: 'filter.op.ge' }),
  //         },
  //         {
  //           value: 'LE',
  //           label: message({ id: 'filter.op.le' }),
  //         },
  //       ]}
  //       value={filterOp}
  //       onChange={setFilterOp}
  //     />
  //   ),
  //   input: (
  //     <DateInput
  //       containerProps={{ mb: [2, 0], flex: [1, 2], width: 1 }}
  //       value={filterInput}
  //       onChange={setFilterInput}
  //     />
  //   ),
  //   getLabel: (item) => (
  //     <Flex alignItems="center" sx={{ lineHeight: 0 }}>
  //       {message({ id: 'customer.field.createdAt' })}
  //       <Text px={1}>
  //         {message({ id: `filter.op.${item.op.toLowerCase()}` })}
  //       </Text>
  //       {item.value}
  //     </Flex>
  //   ),
  // },
]

export const columns = ({
  session,
  app,
  message,
  selected,
  setSelected,
  action,
}) => [
  {
    id: 'ctrl',
    width: '32px',
    render: ({ row }) => (
      <Checkbox
        checked={selected.some((id) => id === row.id)}
        onClick={() => {
          const items = [...selected]
          const idx = items.findIndex((id) => id === row.id)
          if (idx === -1) {
            items.push(row.id)
          } else {
            items.splice(idx, 1)
          }
          setSelected(items)
        }}
      />
    ),
  },
  {
    id: 'name',
    label: 'customer.field.name',
    renderHtml: ({ row }) => (
      <Link variant="primaryLink" href={`/customer/${row.id}/view`}>
        {row.name}
      </Link>
    ),
  },
  {
    id: 'phone',
    label: 'customer.field.phoneAndCellphone',
    renderHtml: ({ row }) => {
      const phones = row.phones || []
      const cellphones = row.cellphones || []
      const contact = row.extra?.contact || {}
      return [...phones, ...cellphones]
        ?.filter((item) => !!item)
        .map((item, index) => {
          let name = contact[item]
          if (name) item = `${item} [${name}]`
          return (
            <Box
              key={item}
              mt={index === 0 ? 0 : 1}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {item}
            </Box>
          )
        })
    },
    render: ({ row }) => row.phones.join(', '),
  },
  {
    id: 'address',
    label: 'customer.field.address',
    renderHtml: ({ row }) =>
      row.addresses?.map((item, index) => (
        <Box key={index} mt={index === 0 ? 0 : 1}>
          {showAddress(item, message)}
        </Box>
      )),
    render: ({ row }) => row.addresses.join(', '),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <Button
        variant="icon"
        icon={<MdDelete />}
        onClick={() => {
          session.dispatch({
            type: CONFIRM_SET,
            item: {
              open: true,
              title: 'customer.title.delete',
              text: 'customer.message.delete',
              onSubmit: async () =>
                handleDelete({
                  session,
                  app,
                  id: row.id,
                  action,
                }),
            },
          })
        }}
      />
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  setSelected,
  setFilterValues,
}) => ({
  handleLoad: async ({ page, filterValues } = {}) => {
    const filterId = 'customerList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
    }
    const data = await getData({ session, app, page, filterValues })
    setFilterValues(filterValues)
    setState(data)
    session.dispatch({ type: FILTER_SET, key: filterId, filterValues })
  },
  handleMerge: async (customers, filterValues) => {
    const ok = await mergeCustomers({ session, app, customers })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })

    const data = await getData({ session, app, filterValues })
    setState(data)
    setSelected([])
  },
})

async function getData({ session, app, page = 0, filterValues = [] }) {
  const input = getDataInput({ page, filterValues })
  if (!input.name && !input.phone && !input.address) return []

  const variables = { input }
  const query = `
    query($input: CustomerQueryInput) {
      customers(input: $input) {
        id
        name
        phones
        cellphones
        addresses {
          zipcode
          city
          district
          street
          hasLift
        }
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  return data.customers
}

function getDataInput({ page, filterValues }) {
  const count = 25
  const name = filterValues.find(({ id }) => id === 'name')
  const phone = filterValues.find(({ id }) => id === 'phone')
  const address = filterValues.find(({ id }) => id === 'address')
  const input = {
    name: name?.value,
    phone: getNumericValue(phone?.value),
    address: address?.value,
    orderBy: [{ key: 'name' }],
    limit: { offset: page * count, count },
  }
  return input
}

async function mergeCustomers({ session, app, customers }) {
  const variables = { idList: customers }
  const query = `
    mutation($idList: [ID!]!) {
      mergeCustomers(idList: $idList)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  return data.mergeCustomers
}
