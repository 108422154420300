import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/bestPage'
import { Box } from 'reflexbox'
import { Grid, Section, Button, Link, Center } from 'components/core'
import Page from 'components/Page'
import BestSyncModal from 'views/BestSyncModal'
import { MdArrowBack } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}))
  const [syncOpen, setSyncOpen] = useState(false)
  const [syncType, setSyncType] = useState('syncBestOrders')

  const form = fields({ app, session, state, setState })
  const handler = handlers({ app, session, state, setState })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (type) => {
    setSyncType(type)
    setSyncOpen(true)
  }

  const handleClose = () => {
    setSyncOpen(false)
    setSyncType('syncBestOrders')
  }

  return (
    <>
      <Page
        navId="pluginList"
        title="best.title.edit"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/plugin"
            />
            <Button
              ml={3}
              variant="primary"
              text="btn.save"
              onClick={useCallbackOnce(handler.handleSubmit)}
            />
          </Center>
        }
        showFooterAction
      >
        <Grid cols={['1fr', '1fr 2fr']} gap={3}>
          <Box>
            <Section title="plugin.section.basic" mb={[3, 0]}>
              {form.username}
              {form.password}
              {form.dealerId}
              {form.syncTime}
            </Section>
            <Section mt={4} title="best.section.autoSync">
              {form.isActive}
            </Section>
            <Section mt={4} title="best.section.manualSync">
              <Box>
                <Button
                  variant="default"
                  text="best.btn.syncDispatch"
                  onClick={() => handleOpen('syncBestOrders')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="best.btn.uploadDispatch"
                  onClick={() => handleOpen('uploadBestOrders')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="best.btn.syncRefund"
                  onClick={() => handleOpen('syncBestRefunds')}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="best.btn.uploadRefund"
                  onClick={() => handleOpen('uploadBestRefunds')}
                />
              </Box>
            </Section>
          </Box>
        </Grid>
      </Page>
      <BestSyncModal
        open={syncOpen}
        data={{ syncType }}
        action={{ handleClose }}
      />
    </>
  )
}
