import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  handleTextChange,
  getSelectOptions,
} from 'utilities/form'
import { Select, TextInput } from 'components/form'

export const initialState = () =>
  initializeState({
    locationId: {},
    code: '',
  })

const validation = {
  locationId: [{ type: 'required', message: 'error.required' }],
  code: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    locationId: (
      <Select
        isClearable={false}
        label="carrefour.field.storageName"
        placeholder="carrefour.field.storageName"
        options={getSelectOptions(data.storages)}
        value={state.locationId}
        onChange={onSelectChange('locationId')}
        errMsg={state.__error__.locationId}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="carrefour.field.storageCode"
        placeholder="carrefour.field.storageCode"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
  }
}

export const handlers = ({ state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const locationId = state.locationId.value
    const storage = data.storages.find((item) => item.id === locationId)
    action.addStorage({
      locationId: storage.id,
      locationName: storage.name,
      code: state.code,
    })
    action.handleClose()
    setState(initialState())
  },
})
