import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { Address } from 'components/form'

export const initialState = (value) => {
  const { index, address } = value.addressValue || {}
  return {
    id: value.id,
    index,
    ...initializeState({
      address: address || {},
    }),
  }
}

const validation = {
  address: [
    { type: 'required', message: 'error.required' },
    { type: 'address', message: 'error.invalidAddress' },
  ],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    address: (
      <Address
        id="address"
        label="field.address"
        placeholder="field.address"
        value={state.address}
        onChange={onTextChange('address')}
        errMsg={state.__error__.address}
      />
    ),
  }
}

export const handlers = ({ session, app, state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await addAddress(state, app, session)
    if (!ok) return

    action.handleLoad()
    action.handleClose()
  },
})

async function addAddress(state, app, session) {
  const variables = {
    id: state.id,
    index: state.index,
    address: state.address,
  }
  const query = `
    mutation($id: ID!, $address: AddressInput!, $index: Int) {
      addCustomerAddress(id: $id, address: $address, index: $index)
    }
  `
  return request({ query, variables }, { session, app })
}
