import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { initialState, fields, handlers } from 'models/lockPage'
import { Box } from 'reflexbox'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
} from 'components/core'
import Page from 'components/Page'
import { MdArrowBack, MdEdit, MdPrint } from 'react-icons/md'
import {
  renderConfirmButton,
  renderDeleteLink,
  renderLockLink,
} from 'utilities/app'
import { handleLock } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match, profile }) => {
  const { id } = match.params
  const { formatMessage: message } = useIntl()
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))

  const readonly = profile === 'view'
  const form = fields({ profile, app, session, state, setState })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    history,
    id,
    profile,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <Page
      profile={profile}
      navId="lockList"
      title={`lock.title.${profile}`}
      titleExtra={
        <StatusChip
          show={readonly}
          ml={2}
          label={`status.${state.status}`}
          color={getStatusColor(state.status)}
          bg="light.1"
        />
      }
      action={
        <Center>
          <Link
            variant="link"
            icon={<MdArrowBack />}
            text="btn.back"
            href={profile === 'edit' ? `/lock/${id}/view` : '/lock'}
          />
          <Link
            ml={3}
            show={readonly}
            variant="link"
            icon={<MdPrint />}
            tooltip="btn.print"
            onClick={handler.handlePrint}
          />
          {renderDeleteLink({
            module: 'lock',
            session,
            readonly,
            locked: state.locked,
            status: state.status,
            onSubmit: handler.handleDelete,
          })}
          <Link
            ml={3}
            show={readonly}
            disabled={state.locked || state.status === 'INACTIVE'}
            variant="link"
            icon={<MdEdit />}
            tooltip="btn.edit"
            href={`/lock/${id}/edit`}
          />
          {renderLockLink({
            readonly,
            locked: state.locked,
            status: state.status,
            onSubmit: handleLock({
              module: 'lock',
              session,
              app,
              id,
              handleLoad: handler.handleLoad,
            }),
          })}
          <Button
            ml={3}
            show={!readonly}
            disabled={profile === 'edit' && !state.isReady}
            variant="primary"
            text="btn.save"
            type="submit"
          />
          {renderConfirmButton({
            module: 'lock',
            session,
            profile,
            status: state.status,
            onSubmit: handler.handleConfirm,
          })}
        </Center>
      }
      showFooterAction
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
      audit={{
        createdBy: state.createdBy,
        createdAt: state.createdAt,
        updatedBy: state.updatedBy,
        updatedAt: state.updatedAt,
      }}
    >
      <Grid cols={['1fr', '1fr 3fr']} gap={3}>
        <Box>
          <Section title="lock.section.basic" mb={[3, 0]}>
            {form.id}
            {form.transDate}
            {form.toLocation}
            {form.memo}
          </Section>
        </Box>
        <Box>
          <Section title="lock.section.product">{form.product}</Section>
        </Box>
      </Grid>
    </Page>
  )
}
