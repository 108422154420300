import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { LinkButton, StatusChip, Link } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { FILTER_SET } from 'constants/actionType'
import { Flex } from 'reflexbox'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'createdAt',
    label: 'purchase.field.createdAt',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'estimateDate',
    label: 'purchase.field.estimateDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'transDate',
    label: 'purchase.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    input: textFilter,
  },
  {
    id: 'warehouse',
    label: 'field.warehouse',
    input: (props) =>
      selectFilter({
        ...props,
        options: data.warehouses,
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'billing',
    label: 'field.billing',
    input: (props) =>
      selectFilter({
        ...props,
        options: data.billings,
      }),
  },
  {
    id: 'company',
    label: 'purchase.field.srcCompanyId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.companies,
      }),
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'PENDING', label: message({ id: 'status.PENDING' }) },
    {
      value: 'CALLBACK_PENDING',
      label: message({ id: 'status.CALLBACK_PENDING' }),
    },
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/purchase/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'createdAt',
    label: 'field.createdAt',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.createdAt),
  },
  {
    id: 'estimateDate',
    label: 'purchase.field.estimateDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.extra?.estimateDate),
  },
  {
    id: 'transDate',
    label: 'purchase.field.transDate',
    format: ['html', 'excel'],
    render: ({ row }) => {
      const date = showDate(row.transDate)
      if (date === '3000-01-01') return ''
      return date
    },
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    format: ['html', 'excel'],
  },
  {
    id: 'itemToLocationName',
    label: 'field.warehouse',
    format: ['excel'],
  },
  {
    id: 'billingName',
    label: 'field.billing',
    format: ['excel'],
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
  },
  {
    id: 'rackNo',
    label: 'purchase.field.rackNo',
    format: ['excel'],
    render: ({ row }) => row.extra?.rackNo,
  },
  {
    id: 'itemProductVariantName',
    label: 'field.productName',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'purchase.field.sku',
    format: ['excel'],
  },
  {
    id: 'productCode',
    label: 'product.field.code',
    format: ['excel'],
  },
  {
    id: 'invoicedQuantity',
    label: 'purchase.field.invoicedQuantity',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.invoicedQuantity,
  },
  {
    id: 'purchasedQuantity',
    label: 'purchase.field.purchasedQuantity',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.purchasedQuantity,
  },
  {
    id: 'quantity',
    label: 'purchase.field.quantity',
    format: ['excel'],
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/purchase/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'purchase',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('purchase', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  message,
  setState,
  setFilterValues,
  setPagination,
  setWarehouses,
  setBillings,
  setCompanies,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setWarehouses(resp.warehouses)
    setBillings(resp.billings)
    setCompanies(resp.companies)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'purchaseList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'purchase.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    input: getDataInput({ pagination, filterValues }),
  }
  const query = `
    query($input: TicketQueryInput) {
      purchaseTicketCount(input: $input)
      purchaseTickets(input: $input) {
        id
        ticketNo
        transDate
        createdAt
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.purchaseTickets,
    pagination: getPagination(page, countPerPage, data.purchaseTicketCount),
  }
}

async function getInit({ session, app }) {
  const variables = {
    locationInput: { type: ['WAREHOUSE', 'COMPANY'] },
  }
  const query = `
    query($locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const warehouses = []
  const billings = []
  const companies = []

  data.locations.forEach((item) => {
    const result = { value: item.id, label: item.name }
    if (item.type === 'WAREHOUSE') return warehouses.push(result)
    if (item.extra.type === 'BILLING') return billings.push(result)
    if (item.extra.type === 'PURCHASE') return companies.push(result)
  })

  return { warehouses, billings, companies }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      purchaseTickets(input: $input) {
        id
        ticketNo
        itemToLocationName
        itemProductVariantName
        sku
        productCode
        quantity
        extra
        itemExtra
        transDate
        createdAt
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.purchaseTickets.map((item) => {
    const { extra } = item
    item.billingName = extra.billCompany?.name
    item.memo = extra.memo
    return item
  })
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const createdAt = filterValues.find(({ id }) => id === 'createdAt')
  const estimateDate = filterValues.find(({ id }) => id === 'estimateDate')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const ticketNo = filterValues.find(({ id }) => id === 'ticketNo')
  const warehouse = filterValues.find(({ id }) => id === 'warehouse')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const billing = filterValues.find(({ id }) => id === 'billing')
  const company = filterValues.find(({ id }) => id === 'company')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    id: id?.value,
    createdAt: createdAt?.value,
    transDate: transDate?.value,
    ticketNo: ticketNo?.value,
    itemToLocationId: warehouse?.value.value,
    extra: {
      estimateDate: estimateDate?.value,
      billCompanyId: billing?.value.value,
      srcCompanyId: company?.value.value,
      locked: lockStatus?.value.value,
    },
    status: status?.value.value,
    productVariantName: productName?.value,
    createdBy: createdBy?.value,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
