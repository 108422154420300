import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SessionContext, AppContext } from 'contexts'
import { Button, Table, Section } from 'components/core'
import Page from 'components/Page'
import { columns, handlers } from 'models/roleList'

export default () => {
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const handler = handlers({ setState, session, app })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="role.title.list"
      navId="roleList"
      action={
        <Button
          variant="secondary"
          text="btn.add"
          onClick={() => history.push('/role/add')}
        />
      }
    >
      <Section>
        <Table columns={columns({ setState, session, app })} rows={state} />
      </Section>
    </Page>
  )
}
