import React from 'react'
import { TextInput } from 'components/form'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    parentId: value.parentId,
    name: value.name || '',
  })

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="category.field.name"
        placeholder="category.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = state.id
      ? await editCategory(state, app, session)
      : await addCategory(state, app, session)

    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  },
})

export async function addCategory(value, app, session) {
  const { name, parentId } = value
  const variables = { input: { name, parentId } }
  const query = `
    mutation($input: CategoryInput!) {
      addCategory(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editCategory(value, app, session) {
  const { id, name, parentId } = value
  const variables = { id, input: { name, parentId } }
  const query = `
    mutation($id: ID!, $input: CategoryInput!) {
      editCategory(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
