export const ALERT_ADD = 'ALERT_ADD'
export const ALERT_REMOVE = 'ALERT_REMOVE'
export const LOADING_SHOW = 'LOADING_SHOW'
export const LOADING_HIDE = 'LOADING_HIDE'
export const NAV_TOGGLE = 'NAV_TOGGLE'
export const NAV_SET = 'NAV_SET'
export const CONFIRM_SET = 'CONFIRM_SET'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const TOKEN_SET = 'TOKEN_SET'
export const TOKEN_REMOVE = 'TOKEN_REMOVE'
export const STAFF_SET = 'STAFF_SET'
export const ADDRESS_SET = 'ADDRESS_SET'
export const FILTER_SET = 'FILTER_SET'
export const CUSTOMER_LOOKUP_SET = 'CUSTOMER_LOOKUP_SET'
// export const CATEGORY_SET = 'CATEGORY_SET'
// export const PRODUCT_SET = 'PRODUCT_SET'
// export const PRODUCT_ADD = 'PRODUCT_ADD'
// export const PRODUCT_EDIT = 'PRODUCT_EDIT'
// export const PRODUCT_REMOVE = 'PRODUCT_REMOVE'
