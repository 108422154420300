import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { columns, filters, handlers } from 'models/reportDealerStockList2'
import { Center, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload, MdSync } from 'react-icons/md'
import ReportHistoryDrawer from './ReportHistoryDrawer'
import { CONFIRM_SET } from 'constants/actionType'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const { handleInit, handleLoad, handleExport, handleUpdate } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
    setData,
  })

  useEffect(() => {
    handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  if (Object.keys(data).length === 0) return null

  return (
    <Page
      title="report.title.dealerStock2"
      navId="dealerStockReport2"
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ data, filterValues, createExcel })}
          />
          <Link
            icon={<MdSync />}
            text="report.btn.update"
            onClick={() => {
              session.dispatch({
                type: CONFIRM_SET,
                item: {
                  open: true,
                  title: 'report.title.update',
                  text: 'report.message.update',
                  onSubmit: () => handleUpdate({ pagination, filterValues }),
                },
              })
            }}
          />
        </Center>
      }
      showAudit
      audit={{ updatedAt: data?.updatedAt }}
    >
      <Section>
        <Table
          columns={columns({
            data,
            filterValues,
            action: { handleLoad, handleOpen },
          })}
          rows={state}
          filters={filters({ data })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <ReportHistoryDrawer value={value} open={open} action={{ handleClose }} />
    </Page>
  )
}
