import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import InputMask from 'react-input-mask'
import FormField from './FormField'

export default ({
  id,
  label,
  required,
  errMsg,
  fieldProps,
  value,
  mask,
  onChange,
  ...props
}) => {
  const theme = useTheme()
  const { space, fontSizes, colors } = theme
  const [borderColor, setBorderColor] = useState(getBorderColor(false))

  function getBorderColor(isFocused) {
    if (!!errMsg) return colors.error[1]
    return isFocused ? colors.accent[1] : colors.light[3]
  }

  return (
    <FormField
      id={id}
      label={label}
      required={required}
      errMsg={errMsg}
      {...fieldProps}
    >
      <InputMask
        mask={mask}
        alwaysShowMask
        value={value}
        onChange={({ target }) => onChange(target.value)}
        onFocus={() => setBorderColor(getBorderColor(true))}
        onBlur={() => {
          setBorderColor(getBorderColor(false))
        }}
        style={{
          width: '100%',
          minHeight: '38px',
          padding: space[2],
          margin: space[0],
          fontSize: fontSizes[1],
          color: colors.dark[2],
          boxSizing: 'border-box',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor,
          borderRadius: '4px',
          transition: 'border-color 0.2s linear',
          willChange: 'border-color',
          outline: 'none',
        }}
        {...props}
      />
    </FormField>
  )
}
