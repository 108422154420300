export function getStatusColor(status) {
  switch (status) {
    case 'ACTIVE':
    case 'REVERTED':
      return 'success.1'
    case 'PENDING':
    case 'TIME_PENDING':
    case 'CALLBACK_PENDING':
    case 'ACTION_PENDING':
      return 'warning.1'
    case 'FAILED':
      return 'error.1'
    default:
      return 'dark.0'
  }
}

export function getLockStatusOptions(message) {
  return [
    { value: true, label: message({ id: 'type.true' }) },
    { value: false, label: message({ id: 'type.false' }) },
  ]
}
