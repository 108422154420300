import React, { useContext, useState } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { Flex } from 'reflexbox'
import { handlers } from 'models/vicInvoiceSyncModal'
import { Modal, Button, Message } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ data, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [disabled, setDisabled] = useState(false)
  const handler = handlers({
    app,
    session,
    data,
    action,
    setDisabled,
  })

  return (
    <Modal
      title={`vicInvoice.title.${data.syncType}`}
      open={open}
      onClose={action.handleClose}
      width={300}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      <Message id={`vicInvoice.message.${data.syncType}`} />
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          disabled={disabled}
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          disabled={disabled}
          type="submit"
          variant="primary"
          text="btn.confirm"
        />
      </Flex>
    </Modal>
  )
}
