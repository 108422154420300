import React from 'react'
import { request } from 'utilities/request'
import { getSelectOptions } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'

export const filters = ({ data }) => [
  {
    id: 'sales',
    label: 'field.sales',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.sales),
      }),
  },
  {
    id: 'dealer',
    label: 'field.dealer',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
]

export const columns = ({ filterValues, action }) => [
  {
    id: 'staffName',
    label: 'field.sales',
    format: ['html', 'excel'],
  },
  {
    id: 'locationName',
    label: 'field.dealer',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
  },
  {
    id: 'sellQuantity',
    label: 'field.sellQuantity',
    format: ['html', 'excel'],
    align: 'right',
    render: ({ row }) => row.sellQuantity || 0,
  },
  {
    id: 'sellAmount',
    label: 'field.sellAmount',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            distinct: true,
            showAmount: true,
            transDate: transDate?.value,
            staffId: row.staffId,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {round(row.sellAmount)}
      </Link>
    ),
    render: ({ row }) => round(row.sellAmount),
  },
]

function round(val) {
  if (!val) return 0
  return Math.round(val * 100) / 100
}

export const handlers = ({
  session,
  app,
  message,
  setState,
  setTotal,
  setFilterValues,
  setPagination,
  setSales,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setSales(resp.sales)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setTotal(resp.total)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.staffSales'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getInit({ session, app }) {
  const variables = { input: { type: ['SALES'] } }
  const query = `
    query($input: StaffQueryInput) {
      staffs(input: $input) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  return { sales: data.staffs }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: StaffSalesReportInput) {
      staffSalesReportCount(input: $input)
      staffSalesReports(input: $input) {
        staffId
        staffName
        locationId
        locationName
        productVariantId
        productVariantName
        sku
        sellQuantity
        sellAmount
      }
      staffSalesTotal(input: $input) {
        sellAmount
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    total: data.staffSalesTotal,
    data: data.staffSalesReports,
    pagination: getPagination(page, countPerPage, data.staffSalesReportCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: StaffSalesReportInput) {
      staffSalesReports(input: $input) {
        staffName
        locationName
        productVariantName
        sku
        sellQuantity
        sellAmount
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.staffSalesReports
}

function getDataInput({ filterValues, pagination }) {
  const sales = filterValues.find(({ id }) => id === 'sales')
  const dealer = filterValues.find(({ id }) => id === 'dealer')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const input = {
    staffId: sales?.value.value,
    locationName: dealer?.value,
    transDate: transDate?.value,
    productVariantName: productName?.value,
    orderBy: [
      { key: 'staffName' },
      { key: 'locationName' },
      { key: 'productVariantName' },
      { key: 'sku' },
    ],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
