import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { columns, filters, handlers } from 'models/refundList'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { Center, Link, LinkButton, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileDownload, MdFileUpload } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [dealers, setDealers] = useState([])
  const [sales, setSales] = useState([])
  const [staffs, setStaffs] = useState([])
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleInit, handleLoad, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
    setDealers,
    setSales,
    setStaffs,
  })

  useEffect(() => {
    handleInit()
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="refund.title.list"
      navId="refundList"
      action={
        <Center>
          <Link
            mr={2}
            icon={<MdFileDownload />}
            text="btn.import"
            href="/refund/import"
          />
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
          <LinkButton variant="secondary" text="btn.add" href="/refund/add" />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({ session, app, message, action: { handleLoad } })}
          rows={state}
          filters={filters({ message, data: { dealers, sales, staffs } })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
