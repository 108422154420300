import React, { useRef, useState, cloneElement, useEffect } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { useIntl, FormattedMessage } from 'react-intl'
import { Box, Flex } from 'reflexbox'
import {
  Center,
  Pagination,
  Text,
  Button,
  Chip,
  NoData,
  Link,
} from 'components/core'
import { Select } from 'components/form'
import {
  MdAdd,
  MdCheck,
  MdClose,
  MdDelete,
  MdEdit,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdMore,
  MdSearch,
} from 'react-icons/md'
import { COUNT_PER_PAGE } from 'utilities/pagination'
import TableDrawer from './TableDrawer'

const Table = ({ children, ...props }) => (
  <Box
    p={2}
    as="table"
    width={1}
    color="dark.2"
    sx={{ borderCollapse: 'collapse', position: 'relative' }}
    {...props}
  >
    {children}
  </Box>
)

const Row = ({ index, children, ...props }) => {
  const idx = props.parentIndex >= 0 ? props.parentIndex : index
  return (
    <Box as="tr" bg={idx % 2 === 0 ? 'light.1' : 'transparent'} {...props}>
      {children}
    </Box>
  )
}

const EmptyRow = ({ columnCount, show }) => {
  // if (!show) return null
  return (
    <Box as="tr" bg="light.1">
      <Cell
        colSpan={columnCount}
        sx={{
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'light.3',
        }}
      >
        <NoData flexDirection="row" iconSize="22px" />
      </Cell>
    </Box>
  )
}

const HeadCell = ({ column, ...props }) => {
  const { width, label, align, renderHead } = column
  return (
    <Box
      as="th"
      py={3}
      px={2}
      width={width || 'auto'}
      sx={{
        borderBottom: 'none',
        textAlign: align || 'left',
        fontWeight: 300,
        whiteSpace: 'nowrap',
      }}
      {...props}
      {...column.headProps}
    >
      {renderHead ? renderHead() : label && <FormattedMessage id={label} />}
    </Box>
  )
}

const Cell = ({ id, align, width, noWrap, children, isSubRow, ...props }) => (
  <Box
    as="td"
    py={2}
    px={2}
    width={width || 'auto'}
    color="dark.1"
    sx={{
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor:
        isSubRow && id === '__SUBROW__' ? 'transparent' : 'light.3',
      textAlign: align || 'left',
      whiteSpace: noWrap ? 'nowrap' : 'initial',
    }}
    {...props}
  >
    {children}
  </Box>
)

export default ({
  profile: pageProfile = 'view',
  show = true,
  showSeq = false,
  showAddInput = false,
  showAddIcon = false,
  showEditIcon = false,
  showDeleteIcon = false,
  showFilterBar = true,
  showFilterDrawer = true,
  filters,
  filterValues = [],
  inputValues = {},
  editValues = {},
  columns,
  rows: tableRows,
  footer,
  pagination,
  onChange,
  onInputChange,
  onEditChange,
  onAdd,
  onEdit,
  onDelete,
  headProps,
  bodyProps,
  rowProps,
  cellProps,
  ...props
}) => {
  const { formatMessage: message } = useIntl()
  const filterInputRef = useRef()
  const [filterOps, setFilterOps] = useState(getInitFilterOps(filters))
  const [filterInputs, setFilterInputs] = useState(getInitFilterInputs(filters))
  const [filterSelect, setFilterSelect] = useState(
    getInitFilterSelect(filters, message),
  )
  // const configs = getFilterConfigs(
  //   filters,
  //   message,
  //   filterOps,
  //   setFilterOps,
  //   filterInputs,
  //   setFilterInputs,
  //   filterInputRef,
  // )
  const [open, setOpen] = useState(false)
  const [editIdx, setEditIdx] = useState(-1)
  const [rows, setRows] = useState([])

  const cols = columns
    .filter(({ profile = ['add', 'edit', 'view'] }) =>
      profile.includes(pageProfile),
    )
    .filter(({ format = ['html'] }) => format.includes('html'))
    .filter(({ show = true }) => show)

  useEffect(() => {
    setRows(getRows(tableRows))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows])

  useEffect(() => {
    if (!editValues || Object.keys(editValues).length === 0) setEditIdx(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editValues])

  useEffect(() => {
    if (filterInputRef.current) filterInputRef.current.focus()
  }, [filterSelect])

  useEffect(() => {
    const { page, totalPage } = pagination || {}
    if (totalPage > 0 && page > totalPage) {
      pagination.page = totalPage
      onChange({ filterValues, pagination })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const hasSubRow = rows?.some((i) => !!i._subRows)
  if (hasSubRow) {
    cols.unshift({
      id: '__SUBROW__',
      width: '1px',
      render: ({ row }) => {
        if (!row._subRows) return ''
        return (
          <Button
            variant="icon"
            icon={
              row._childOpen ? (
                <MdKeyboardArrowDown />
              ) : (
                <MdKeyboardArrowRight />
              )
            }
            onClick={() => {
              const newRows = cloneDeep(rows)
              const chilOpen = !row._childOpen
              newRows.forEach((i) => {
                if (i._index === row._index) i._childOpen = chilOpen
                if (i._parentIndex === row._index) i._hidden = !chilOpen
              })
              setRows(newRows)
            }}
          />
        )
      },
    })
  }

  if (showSeq) {
    cols.unshift({
      id: '__SEQ__',
      label: 'field.seq',
      width: '1px',
      render: ({ index }) => {
        const { page = 1, countPerPage = COUNT_PER_PAGE } = pagination || {}
        const curPage = page || 1
        return (curPage - 1) * countPerPage + index + 1
      },
    })
  }

  if (showDeleteIcon) {
    cols.push({
      id: '__ACTION__',
      align: 'right',
      width: '1px',
      noWrap: true,
      renderHtml: (item) => {
        return (
          <>
            {onEdit && (
              <Button
                mr={1}
                variant="icon"
                icon={<MdEdit />}
                onClick={() => {
                  cols.forEach((col) => {
                    const { id, getValue } = col
                    editValues[id] = !!getValue
                      ? getValue(item.row)
                      : item.row[id]
                  })
                  onEditChange(editValues, item.index)
                  setEditIdx(item.index)
                }}
              />
            )}
            <Button
              variant="icon"
              icon={<MdDelete />}
              onClick={() => onDelete(item)}
            />
          </>
        )
      },
    })
  }

  const renderFilterSelector = () => (
    <Select
      fieldProps={{ mt: 0, mr: [0, 2], flex: 1, width: 1 }}
      isSearchable={false}
      isClearable={false}
      options={filters.map((item) => ({
        value: item.id,
        label: message({ id: item.label }),
      }))}
      value={filterSelect}
      onChange={(value) => {
        setFilterSelect(value)
      }}
    />
  )

  const renderFilterInput = () => {
    const filterId = filterSelect.value
    const filter = filters.find((item) => item.id === filterId)
    return filter.input({
      ref: filterInputRef,
      message,
      filterOp: filterOps[filter.id],
      setFilterOp: (val) => setFilterOps({ ...filterOps, [filter.id]: val }),
      filterInput: filterInputs[filter.id],
      setFilterInput: (val) =>
        setFilterInputs({ ...filterInputs, [filter.id]: val }),
    })
  }

  // const renderFilterOp = () =>
  //   configs.find((item) => item.id === filterSelect.value).op

  const submitFilter = () => {
    const filterInput = filterInputs[filterSelect.value]
    const filterOp = filterOps[filterSelect.value]
    if (!filterInput || filterInput.length === 0) return

    const filterVals = [...filterValues]
    const newFilter = {
      id: filterSelect.value,
      label: filterSelect.label,
      op: filterOp?.value,
      value: filterInput,
    }
    const index = filterVals.findIndex(({ id }) => filterSelect.value === id)
    if (index === -1) {
      filterVals.push(newFilter)
    } else {
      filterVals.splice(index, 1, newFilter)
    }

    onChange({ filterValues: filterVals })
    setFilterOps(getInitFilterOps(filters))
    setFilterInputs(getInitFilterInputs(filters))
  }

  const renderFilterButton = () => (
    <Button
      mt={[2, 0]}
      ml={[0, 2]}
      icon={<MdSearch size="24px" />}
      variant="icon"
      type="submit"
    />
  )

  const renderFilterDrawerButton = () => (
    <Button
      mt={[2, 0]}
      ml={[0, 2]}
      icon={<MdMore size="24px" />}
      variant="icon"
      style={{ marginLeft: showFilterBar ? null : 'auto' }}
      onClick={() => setOpen(true)}
    />
  )

  const getFilterLabel = ({ id, value }) => {
    const filter = filters.find((item) => item.id === id)
    if (filter.getLabel) return filter.getLabel(value)
    const getValue = () => {
      if (typeof value === 'string') return value
      if (Array.isArray(value)) return value.join(', ')
      if (value && value.label) return value.label
      return ''
    }
    return (
      <Center>
        <Text mr={1}>{message({ id: filter.label })}:</Text>
        {getValue(value)}
      </Center>
    )
  }

  const renderClearButton = () => (
    <Link
      mb={2}
      variant="primaryLink"
      text="filter.clear"
      fontSize={1}
      onClick={() => onChange({ filterValues: [], pagination })}
    />
  )

  const renderCell = (column, row, index) => {
    if (column.renderHtml) {
      return column.renderHtml({ row, index })
    }
    if (column.render) {
      return column.render({ row, index })
    }
    return <Text>{row[column.id]}</Text>
  }

  const renderRows = () => {
    if (!rows || rows.length === 0) {
      return <EmptyRow show={!showAddInput} columnCount={cols.length} />
    }

    return rows
      .filter((row) => !row._hidden)
      .map((row, index) => (
        <Row
          key={index}
          index={index}
          parentIndex={row._parentIndex}
          {...rowProps}
        >
          {cols.map((column) => (
            <Cell
              id={column.id}
              isSubRow={row._parentIndex >= 0}
              key={column.id}
              align={column.align}
              width={column.width}
              noWrap={column.noWrap}
              {...cellProps}
              {...column.cellProps}
            >
              {editIdx === index
                ? renderEditCell(column, index)
                : renderCell(column, row, index)}
            </Cell>
          ))}
        </Row>
      ))
  }

  const renderInputCell = (column) => {
    const { id, renderInput } = column
    const elementVal = inputValues[id]

    if (id === '__SEQ__') return ''
    if (id === '__ACTION__') {
      return (
        <Button
          variant="icon"
          icon={<MdAdd />}
          onClick={() => onAdd({ row: inputValues })}
        />
      )
    }

    if (!renderInput) return elementVal

    const input = renderInput({ row: inputValues, index: -1 })
    return cloneElement(input, {
      fieldProps: { m: 0 },
      value: elementVal,
      onChange: async (item) => {
        let rowResult = { [id]: item }
        if (input.props.onChange) {
          const result = await input.props.onChange(item)
          rowResult = { ...rowResult, ...result }
        }
        onInputChange({ ...inputValues, ...rowResult })
      },
    })
  }

  const renderEditCell = (column, index) => {
    const { id, renderInput } = column
    const elementVal = editValues[id]

    if (id === '__SEQ__') return index + 1
    if (id === '__ACTION__') {
      return (
        <>
          <Button
            mr={1}
            variant="icon"
            icon={<MdClose />}
            onClick={() => setEditIdx(null)}
          />
          <Button
            variant="icon"
            icon={<MdCheck />}
            onClick={() => {
              onEdit({ row: editValues, index: editIdx })
              setEditIdx(null)
            }}
          />
        </>
      )
    }

    if (!renderInput) return elementVal

    const input = renderInput({ row: editValues, index })
    return cloneElement(input, {
      fieldProps: { m: 0 },
      value: elementVal,
      onChange: async (item) => {
        let rowResult = { [id]: item }
        if (input.props.onChange) {
          const result = await input.props.onChange(item)
          rowResult = { ...rowResult, ...result }
        }
        onEditChange({ ...editValues, ...rowResult }, editIdx)
      },
    })
  }

  const renderAddRow = () => {
    if (!showAddInput) return null
    return (
      <Row index={1}>
        {cols.map((column) => (
          <Cell key={column.id} width={column.width} align={column.align}>
            {renderInputCell(column)}
          </Cell>
        ))}
      </Row>
    )
  }

  const renderFilterBar = () => {
    return (
      <>
        {renderFilterSelector()}
        <Flex flex={[1, 3]} width={1} alignItems="center">
          {/* {renderFilterOp()} */}
          {renderFilterInput()}
        </Flex>
        {renderFilterButton()}
      </>
    )
  }

  if (!show) return null
  return (
    <Box {...props}>
      {filters && filters.length > 0 && (
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          px={2}
          width={1}
          as="form"
          onSubmit={(event) => {
            event.preventDefault()
            submitFilter()
          }}
        >
          {showFilterBar && renderFilterBar()}
          {showFilterDrawer && renderFilterDrawerButton()}
        </Flex>
      )}
      {filterValues.length > 0 && (
        <Flex flexWrap="wrap" alignItems="center" p={2} width={1}>
          {filterValues.map((item, index) => (
            <Chip
              mr={2}
              mb={2}
              key={index}
              text={getFilterLabel(item)}
              onDelete={() => {
                const filterVals = [...filterValues]
                filterVals.splice(index, 1)
                onChange({ filterValues: filterVals, pagination })
              }}
            />
          ))}
          {renderClearButton()}
        </Flex>
      )}
      <Box
        height="100%"
        // sx={{
        //   overflowY: ['visible', 'initial'],
        //   overflowX: ['auto', 'initial'],
        // }}
        {...bodyProps}
      >
        <Table>
          <thead>
            <Row>
              {cols.map((column) => (
                <HeadCell key={column.id} column={column} {...headProps} />
              ))}
            </Row>
          </thead>
          <tbody>
            {renderRows(editIdx)}
            {renderAddRow()}
          </tbody>
          {footer && <tfoot>{footer}</tfoot>}
        </Table>
      </Box>
      {pagination && (
        <Box
          pt={3}
          px={2}
          width={1}
          sx={{
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: 'light.3',
          }}
        >
          <Pagination
            value={pagination}
            onClick={(page) => {
              pagination.page = page
              onChange({ pagination, filterValues })
            }}
          />
        </Box>
      )}
      <TableDrawer
        open={open}
        data={{ filters, filterOps, filterInputs, filterValues }}
        action={{
          setFilterOps,
          setFilterInputs,
          handleClose: () => setOpen(false),
          handleSubmit: async (filterVals) => {
            await onChange({ filterValues: filterVals })
            setFilterOps(getInitFilterOps(filters))
            setFilterInputs(getInitFilterInputs(filters))
            setOpen(false)
          },
        }}
      />
    </Box>
  )
}

function getInitFilterInputs(filters) {
  if (!filters) return {}

  return filters.reduce((result, config) => {
    result[config.id] = config.defaultValue || ''
    return result
  }, {})
}

function getInitFilterOps(filters) {
  if (!filters) return {}

  return filters.reduce((result, config) => {
    result[config.id] = config.defaultOp || ''
    return result
  }, {})
}

function getInitFilterSelect(filters, message) {
  if (!filters || filters.length === 0) return {}

  const defaultFilter = filters[0]
  return {
    value: defaultFilter.id,
    label: message({ id: defaultFilter.label }),
  }
}

function getRows(data) {
  if (!data) return []

  const result = cloneDeep(data).reduce((result, item, index) => {
    if (item._subRows) {
      item._childOpen = false
      item._index = index
    }
    result.push(item)

    if (item._subRows) {
      cloneDeep(item._subRows).forEach((i) => {
        result.push({ ...i, _parentIndex: index, _hidden: true })
      })
    }

    return result
  }, [])

  return result
}
