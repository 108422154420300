import React from 'react'
import {
  Select,
  TextInput,
  NumberInput,
  TextArea,
  DateInput,
  Address,
  PhoneInput,
} from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
} from 'utilities/form'

export const initialState = (value = {}, data) => ({
  index: value.index,
  ...initializeState({
    transDate: value.transDate || '',
    ticketNo: value.ticketNo || '',
    dealerId: value.dealerId || '',
    recipientId: value.recipientId || '',
    recipientContact: value.recipientContact || '',
    recipientAddress: value.recipientAddress || '',
    recipientPhone: value.recipientPhone || '',
    recipientCellphone: value.recipientCellphone || '',
    billngId: value.billngId || '',
    salesId: value.salesId || '',
    memo: value.memo || '',
    productVariantId: value.productVariantId || {},
    quantity: value.quantity || 0,
  }),
})

const validation = {
  ticketNo: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
  dealerId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="refund.field.transDate"
        placeholder="refund.field.transDate"
        value={state.transDate}
        role="lockRefundTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="refund.field.ticketNo"
        placeholder="refund.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
        errMsg={state.__error__.ticketNo}
      />
    ),
    dealerId: (
      <Select
        label="refund.field.dealerId"
        isClearable={false}
        options={data.locations}
        value={state.dealerId}
        onChange={onSelectChange('dealerId')}
        errMsg={state.__error__.dealerId}
      />
    ),
    recipientId: (
      <Select
        label="refund.field.recipientId"
        isClearable={false}
        options={data.recipients}
        value={state.recipientId}
        onChange={onSelectChange('recipientId')}
        errMsg={state.__error__.recipientId}
      />
    ),
    recipientContact: (
      <TextInput
        id="recipientContact"
        label="refund.field.recipientContact"
        placeholder="refund.field.recipientContact"
        value={state.recipientContact}
        onChange={onTextChange('recipientContact')}
        errMsg={state.__error__.recipientContact}
      />
    ),
    recipientAddress: (
      <Address
        id="recipientAddress"
        label="refund.field.recipientAddress"
        placeholder="refund.field.recipientAddress"
        value={state.recipientAddress}
        onChange={onTextChange('recipientAddress')}
        errMsg={state.__error__.recipientAddress}
      />
    ),
    recipientPhone: (
      <PhoneInput
        id="recipientPhone"
        label="refund.field.recipientPhone"
        value={state.recipientPhone}
        onChange={onTextChange('recipientPhone')}
        errMsg={state.__error__.recipientPhone}
      />
    ),
    recipientCellphone: (
      <PhoneInput
        id="recipientCellphone"
        label="refund.field.recipientCellphone"
        type="cellphone"
        value={state.recipientCellphone}
        onChange={onTextChange('recipientCellphone')}
        errMsg={state.__error__.recipientCellphone}
      />
    ),
    billingId: (
      <Select
        label="refund.field.billingId"
        isClearable={false}
        options={data.billings}
        value={state.billingId}
        onChange={onSelectChange('billingId')}
        errMsg={state.__error__.billingId}
      />
    ),
    salesId: (
      <Select
        label="refund.field.salesId"
        isClearable={false}
        isMulti
        options={data.sales}
        value={state.salesId}
        onChange={onSelectChange('salesId')}
        errMsg={state.__error__.salesId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="refund.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    productVariantId: (
      <Select
        id="productVariantId"
        label="product.field.sku"
        placeholder="product.field.sku"
        isClearable={false}
        options={data.products}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        label="field.quantity"
        placeholder="field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.editTicketItem(state)
    action.handleClose()
  },
})
