export default {
  'combo.title': '組合組合商品',
  'combo.title.list': '組合商品目錄',
  'combo.title.add': '新增組合商品',
  'combo.title.edit': '更改組合商品',
  'combo.title.view': '組合商品詳情',
  'combo.title.delete': '刪除組合商品',
  'combo.message.delete': '確認刪除組合商品?',
  'combo.section.basic': '基礎信息',
  'combo.section.variant': '組合商品屬性',
  'combo.field.productFilter': '輸入搜尋商品名稱',
  'combo.field.spu': '商品名稱',
  'combo.field.sku': '品號',
  'combo.field.barcode': '條碼',
  'combo.field.quantity': '數量',
  'combo.field.variant': '屬性',
}
