import React from 'react'
import { request } from 'utilities/request'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
} from 'utilities/form'
import { Definition } from 'components/core'
import { Switch, Select, TextInput } from 'components/form'

export const initialState = (value = {}, message) => ({
  id: value.id,
  ...initializeState({
    type: getType(value.type, message),
    name: value.name || '',
    username: value.username || '',
    password: '',
    email: value.extra?.email || '',
    isActive: value.status !== 'INACTIVE',
  }),
})

export function getTypes(message) {
  return [
    { value: 'ADMIN', label: message({ id: `staff.type.ADMIN` }) },
    {
      value: 'TECHNICIAN',
      label: message({ id: `staff.type.TECHNICIAN` }),
    },
    {
      value: 'CUSTOMER_SUPPORT',
      label: message({ id: `staff.type.CUSTOMER_SUPPORT` }),
    },
    { value: 'SALES', label: message({ id: `staff.type.SALES` }) },
    { value: 'WAREHOUSE', label: message({ id: `staff.type.WAREHOUSE` }) },
    { value: 'DEALER', label: message({ id: `staff.type.DEALER` }) },
  ]
}

function getType(value, message) {
  if (!value) value = 'ADMIN'
  return { value, label: message({ id: `staff.type.${value}` }) }
}

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id) =>
    handleSelectChange(id, state, setState, validation)

  return {
    type: (
      <Select
        label="staff.field.type"
        isSearchable={false}
        isClearable={false}
        options={getTypes(message)}
        value={state.type}
        onChange={onSelectChange('type')}
      />
    ),
    name: (
      <TextInput
        id="name"
        label="staff.field.name"
        placeholder="staff.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    username: (
      <TextInput
        id="username"
        label="staff.field.username"
        placeholder="staff.field.username"
        value={state.username}
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        id="password"
        type="password"
        label="staff.field.password"
        placeholder="staff.field.password"
        value={state.password}
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    email: (
      <TextInput
        id="email"
        label="staff.field.email"
        placeholder="staff.field.email"
        value={state.email}
        onChange={onTextChange('email')}
        errMsg={state.__error__.email}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="staff.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({
  state,
  setState,
  app,
  session,
  message,
  id,
  action,
}) => ({
  handleLoad: async () => {
    if (!id) return

    const resp = await getData({ app, session, id })
    setState(initialState(resp, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = state.id
      ? await editStaff({ state, app, session })
      : await addStaff({ state, app, session })
    if (!ok) return

    action.handleLoad()
    action.handleClose()
  },
})

async function getData({ app, session, id }) {
  const variables = { id }
  const query = `
    query($id: ID) {
      staff(id: $id) {
        id
        type
        name
        username
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  return data.staff
}

async function addStaff({ state, app, session }) {
  const variables = {
    input: {
      name: state.name,
      type: state.type.value,
      username: state.username,
      password: state.password,
      email: state.email,
      status: state.isActive ? 'ACTIVE' : 'INACTIVE',
    },
  }
  const query = `
    mutation($input: StaffInput!) {
      addStaff(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editStaff({ state, app, session }) {
  const variables = {
    id: state.id,
    input: {
      name: state.name,
      username: state.username,
      type: state.type.value,
      email: state.email,
      status: state.isActive ? 'ACTIVE' : 'INACTIVE',
    },
  }
  const query = `
    mutation($id: ID!, $input: StaffInput!) {
      editStaff(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
