import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { Button, Icon, StatusChip } from 'components/core'
import { request } from 'utilities/request'
import { MdCheck, MdDelete, MdEdit } from 'react-icons/md'
import { getStatusColor } from 'constants/status'

export const columns = ({ data, setState, session, app, action, message }) => [
  {
    id: 'name',
    label: 'warehouse.field.name',
  },
  {
    id: 'parentName',
    label: 'warehouseGroup.field.name',
    render: ({ row }) => {
      const { parentId } = row
      const warehouseGroup = data.groupMap[parentId]
      return warehouseGroup?.name
    },
  },
  {
    id: 'isStock',
    label: 'warehouse.field.isStock',
    renderHtml: ({ row }) => (
      <Icon show={!!row.isStock} color="success.1" value={<MdCheck />} />
    ),
    render: ({ row }) => (row.isStock ? '&check;' : ''),
  },
  {
    id: 'isLockable',
    label: 'warehouse.field.isLockable',
    renderHtml: ({ row }) => (
      <Icon
        show={!!row.extra?.isLockable}
        color="success.1"
        value={<MdCheck />}
      />
    ),
    render: ({ row }) => (row.extra?.isLockable ? '&check;' : ''),
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`warehouse.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <Button
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => action.handleOpen(row)}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'warehouse.title.delete',
                text: 'warehouse.message.delete',
                onSubmit: handleDelete({
                  setState,
                  session,
                  app,
                  id: row.id,
                  action,
                }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({ setData, setState, session, app }) => ({
  handleLoad: async () => {
    const { warehouses, ...data } = await getData({ session, app })
    setState(warehouses)
    setData(data)
  },
})

async function getData({ session, app }) {
  const variables = {
    input: {
      type: ['WAREHOUSE', 'WAREHOUSE_GROUP'],
      status: ['ACTIVE', 'INACTIVE'],
    },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        parentId
        type
        name
        isStock
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const warehouses = []
  const warehouseGroups = []

  data.locations.forEach((item) => {
    switch (item.type) {
      case 'WAREHOUSE':
        warehouses.push(item)
        break
      case 'WAREHOUSE_GROUP':
        if (item.status === 'ACTIVE') warehouseGroups.push(item)
        break
      default:
    }
  })

  const groupMap = warehouseGroups.reduce((result, item) => {
    result[item.id] = item
    return result
  }, {})

  return { warehouses, warehouseGroups, groupMap }
}

const handleDelete =
  ({ session, app, id, action }) =>
  async () => {
    const variables = { id }
    const query = `
    mutation($id: ID!) {
      deleteWarehouse(id: $id)
    }
  `
    const [ok] = await request({ query, variables }, { session, app })
    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  }
