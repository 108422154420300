import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { initialState, fields, handlers } from 'models/adjustPage'
import {
  Center,
  Button,
  Link,
  Section,
  StatusChip,
  Grid,
} from 'components/core'
import Page from 'components/Page'
import { MdArrowBack, MdEdit, MdPrint } from 'react-icons/md'
import {
  renderConfirmButton,
  renderDeleteLink,
  renderLockLink,
} from 'utilities/app'
import { handleLock } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match, profile }) => {
  const { id } = match.params
  const { formatMessage: message } = useIntl()
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))

  const readonly = profile === 'view'
  const form = fields({ profile, state, setState, app, session })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    history,
    profile,
    id,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <>
      <Page
        profile={profile}
        navId="adjustList"
        title={`adjust.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/adjust/${id}/view` : '/adjust'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'adjust',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.locked || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/adjust/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handleLock({
                module: 'adjust',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
            {renderConfirmButton({
              module: 'adjust',
              session,
              profile,
              status: state.status,
              onSubmit: handler.handleConfirm,
            })}
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.toLocation}
            {form.type}
          </Section>
          <Section>
            {form.transDate}
            {form.ticketNo}
          </Section>
          <Section>{form.memo}</Section>
        </Grid>
        <Section mt={5} title="adjust.section.product">
          {form.product}
        </Section>
        <Section mt={5} title="adjust.section.images">
          {form.images}
        </Section>
      </Page>
    </>
  )
}
