import React from 'react'
import { DateInput, NumberInput, Select, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
} from 'utilities/form'
import {
  getProduct,
  getProductOption,
  getProductOptions,
} from 'actions/product'
import { fetchBalance } from 'actions/inventory'
import { Definition } from 'components/core'

export const initialState = (value = {}, data) => ({
  id: value.id || '',
  index: value.index,
  productVariantName: value.productVariantName || '',
  oldBalance: value.oldBalance || 0,
  balance: value.balance || 0,
  products: data.products,
  productOptions: getProductOptions(data.products),
  unitPrice: value.unitPrice,
  totalPrice: value.totalPrice,
  ...initializeState({
    fromLocationId: getSelectOption(data.warehouses, value.fromLocationId),
    productVariantId: getProductOption(data.products, value.productVariantId),
    memo: value.memo || '',
    quantity: value.quantity || 0,
    batchNo: value.batchNo || '',
    expireAt: value.expireAt || '',
  }),
})

const validation = {
  fromLocationId: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, data, state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    productVariantId:
      data.profile === 'edit' && !!state.id ? (
        <Definition
          direction="row"
          label="product.field.spu"
          value={state.productVariantName}
        />
      ) : (
        <Select
          id="productVariantId"
          label="product.field.spu"
          placeholder="product.field.spu"
          isClearable={false}
          options={state.productOptions}
          value={state.productVariantId}
          onChange={onSelectChange('productVariantId', async ({ value }) => {
            const product = state.products.find((i) => i.id === value)
            const unitPrice = product.unitPrice || 0
            const balance = await getBalance(
              app,
              session,
              state.fromLocationId.value,
              value,
            )
            return {
              unitPrice,
              totalPrice: Math.round(unitPrice * state.quantity * 100) / 100,
              balance,
            }
          })}
          errMsg={state.__error__.productVariantId}
        />
      ),
    fromLocationId: (
      <Select
        label="process.field.fromWarehouse"
        isClearable={false}
        options={data.warehouses}
        value={state.fromLocationId}
        onChange={onSelectChange('fromLocationId', async ({ value }) => {
          const balance = await getBalance(
            app,
            session,
            value,
            state.productVariantId?.value,
          )
          return { balance }
        })}
        errMsg={state.__error__.fromLocationId}
      />
    ),
    materialMemo: (
      <TextInput
        id="materialMemo"
        label="process.field.materialMemo"
        placeholder="process.field.materialMemo"
        value={state.memo}
        onChange={onTextChange('memo')}
        errMsg={state.__error__.memo}
      />
    ),
    balance: (
      <Definition
        direction="row"
        label="inventory.field.balance"
        value={state.balance}
      />
    ),
    materialQuantity: (
      <NumberInput
        id="materialQuantity"
        min="0"
        max={state.balance}
        label="process.field.quantity"
        placeholder="process.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity', (value) => {
          const productVariantId = state.productVariantId.value
          const product = state.products.find((i) => i.id === productVariantId)
          const unitPrice = product.postedPrice || 0
          const totalPrice = Math.round(unitPrice * value * 100) / 100
          return { unitPrice, totalPrice }
        })}
        errMsg={state.__error__.quantity}
      />
    ),
    batchNo: (
      <TextInput
        id="batchNo"
        label="process.field.batchNo"
        placeholder="process.field.batchNo"
        value={state.batchNo}
        onChange={onTextChange('batchNo')}
        errMsg={state.__error__.batchNo}
      />
    ),
    expireAt: (
      <DateInput
        id="expireAt"
        label="process.field.expireAt"
        placeholder="process.field.expireAt"
        value={state.expireAt}
        onChange={onTextChange('expireAt')}
        errMsg={state.__error__.expireAt}
      />
    ),
    unitPrice: (
      <Definition
        direction="row"
        label="field.unitPrice"
        value={state.unitPrice}
      />
    ),
    totalPrice: (
      <Definition
        direction="row"
        label="field.totalPrice"
        value={state.totalPrice}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleLoad: async (value) => {
    if (!value) return

    const quantity = parseInt(value.oldMaterialItem?.quantity || 0)
    const balance = parseInt(value.balance || 0)
    value.oldBalance = balance
    value.balance = balance + quantity
    setState(initialState(value, data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const isAdd = !state.id
    const productVariantId = state.productVariantId.value
    const product = getProduct(state.products, productVariantId)
    action.addMaterialItem({
      id: state.id,
      index: state.index,
      productVariantId,
      productVariantName: product.name,
      sku: product.sku,
      memo: state.memo,
      fromLocationId: state.fromLocationId.value,
      fromLocationName: state.fromLocationId.label,
      balance: isAdd ? state.balance : state.oldBalance,
      quantity: state.quantity,
      unitPrice: state.unitPrice,
      totalPrice: state.totalPrice,
      batchNo: state.batchNo,
      expireAt: state.expireAt,
    })
    action.handleClose()
  },
})

async function getBalance(app, session, fromLocationId, productVariantId) {
  if (!fromLocationId) return { balance: 0 }

  const balance = await fetchBalance({
    app,
    session,
    locationType: 'WAREHOUSE',
    locationId: fromLocationId,
    productVariantId,
  })

  return balance
}
