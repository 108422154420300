export default {
  'error.adjust.missingToLocationId': '目的地為必填欄位',
  'adjust.title.list': '調整目錄',
  'adjust.title.add': '新增調整單',
  'adjust.title.edit': '修改調整單',
  'adjust.title.view': '調整單詳情',
  'adjust.title.editItem': '修改調整商品',
  'adjust.title.import': '匯入調整單',
  'adjust.title.print': '調整單 - {ticketId}',
  'adjust.section.product': '商品',
  'adjust.section.images': '相關圖示',
  'adjust.section.basic': '基礎信息',
  'adjust.message.template': '調整單匯入範本',
  'adjust.field.id': '單號',
  'adjust.field.ticketNo': '廠商單號',
  'adjust.field.toLocationId': '目的地',
  'adjust.field.transDate': '日期',
  'adjust.field.memo': '備註',
  'adjust.field.spu': '商品名稱',
  'adjust.field.sku': '品號',
  'adjust.field.balance': '現有庫存',
  'adjust.field.quantity': '調整數量',
  'adjust.field.status': '狀態',
  'adjust.field.importFile': '匯入檔案',
  'adjust.field.type': '類型',
  'adjust.type.NAME': '品名調整',
  'adjust.type.QUANTITY': '盤點差異',
  'adjust.type.COMBO': '組合調整',
  'adjust.type.OTHER': '其他',
}
