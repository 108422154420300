import React, { useEffect, useState } from 'react'
import { Box } from 'reflexbox'
import { Center } from 'components/core'
import { MdPhoto } from 'react-icons/md'

export default ({ src, fallbackSrc, alt, sx, ...props }) => {
  const [state, setState] = useState(src)
  
  useEffect(() => {
    setState(src)
  }, [src])

  if (!src) {
    return (
      <Center
        height="100%"
        bg="light.0"
        color="light.3"
        fontSize={6}
        {...props}
      >
        <MdPhoto />
      </Center>
    )
  }
  return (
    // <Preload
    //   loadingIndicator={
    //     <Center height="100%">
    //       <LoadingIcon />
    //     </Center>
    //   }
    //   images={[src]}
    //   autoResolveDelay={3000}
    //   resolveOnError
    //   mountChildren
    // >
    <Box
      as="img"
      src={state}
      alt={alt}
      width={1}
      height="100%"
      onError={() => {
        setState(fallbackSrc)
      }}
      sx={{
        objectFit: 'cover',
        ...sx,
      }}
      {...props}
    />
    // </Preload>
  )
}
