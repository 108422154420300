import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/syPage'
import { Box } from 'reflexbox'
import { Grid, Section, Button, Link, Center, Toolbar } from 'components/core'
import Page from 'components/Page'
import { MdAdd, MdArrowBack } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { CONFIRM_SET } from 'constants/actionType'
import SyWarehouseModal from './SyWarehouseModal'
import SyTechModal from './SyTechModal '

export default () => {
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}))
  const [warehouseOpen, setWarehouseOpen] = useState(false)
  const [techOpen, setTechOpen] = useState(false)

  const form = fields({ app, session, state, setState })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    history,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page
        navId="pluginList"
        title={`sy.title.edit`}
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/plugin"
            />
            <Button ml={3} variant="primary" text="btn.save" type="submit" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
      >
        <Grid cols={['1fr', '2fr 1fr']} gap={3}>
          <Box>
            <Section title="plugin.section.basic">
              <Grid cols={['1fr', '1fr 1fr']} gap={3}>
                <Box>
                  {form.host}
                  {form.username}
                  {form.password}
                </Box>
                <Box>
                  {form.dispatchSyncTime}
                  {/* {form.refundSyncTime} */}
                  {form.warehouseGroupId}
                  {form.shippingId}
                </Box>
              </Grid>
            </Section>
            <Section title="sy.section.warehouse" mt={4}>
              <Toolbar>
                <Button
                  variant="default"
                  icon={<MdAdd />}
                  text="btn.add"
                  onClick={() => setWarehouseOpen(true)}
                />
              </Toolbar>
              {form.warehouseList}
            </Section>
            <Section title="sy.section.tech" mt={4}>
              <Toolbar>
                <Button
                  variant="default"
                  icon={<MdAdd />}
                  text="btn.add"
                  onClick={() => setTechOpen(true)}
                />
              </Toolbar>
              {form.techList}
            </Section>
          </Box>
          <Box>
            <Section title="sy.section.autoSync">{form.isActive}</Section>
            <Section mt={4} title="sy.section.manualSync">
              <Box>
                <Button
                  variant="default"
                  text="sy.btn.uploadDispatch"
                  onClick={() => {
                    session.dispatch({
                      type: CONFIRM_SET,
                      item: {
                        open: true,
                        title: 'sy.title.dispatchUpload',
                        text: 'sy.message.dispatchUpload',
                        onSubmit: handler.handleDispatch,
                      },
                    })
                  }}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="sy.btn.uploadRefund"
                  onClick={() => {
                    session.dispatch({
                      type: CONFIRM_SET,
                      item: {
                        open: true,
                        title: 'sy.title.refundUpload',
                        text: 'sy.message.refundUpload',
                        onSubmit: handler.handleRefund,
                      },
                    })
                  }}
                />
              </Box>
              <Box>
                <Button
                  variant="default"
                  text="sy.btn.uploadTransport"
                  onClick={() => {
                    session.dispatch({
                      type: CONFIRM_SET,
                      item: {
                        open: true,
                        title: 'sy.title.transportUpload',
                        text: 'sy.message.transportUpload',
                        onSubmit: handler.handleTransport,
                      },
                    })
                  }}
                />
              </Box>
            </Section>
          </Box>
        </Grid>
      </Page>
      <SyWarehouseModal
        data={{
          warehouses: state.warehouses,
          warehouseList: state.warehouseList,
        }}
        open={warehouseOpen}
        action={{
          handleClose: () => setWarehouseOpen(false),
          addWarehouse: handler.addWarehouse,
        }}
      />
      <SyTechModal
        data={{ techs: state.techs }}
        open={techOpen}
        action={{
          handleClose: () => setTechOpen(false),
          addTech: handler.addTech,
        }}
      />
    </>
  )
}
