import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { Link } from 'components/core'

export const columns = ({ showAmount, showQuantity }) => [
  {
    id: 'ticketId',
    label: `field.ticketNo`,
    render: ({ row: { ticketType, ticketId } }) => {
      const type = ticketType.toLowerCase().replace('_', '-')
      return (
        <Link
          variant="primaryLink"
          href={`${process.env.REACT_APP_STOCK_URL}/${type}/${ticketId}/view`}
          target="_blank"
        >
          {ticketId}
        </Link>
      )
    },
  },
  {
    id: 'transDate',
    label: `field.date`,
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'amount',
    label: 'field.amount',
    align: 'right',
    show: showAmount,
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
    show: showQuantity,
    render: ({ row }) => row.quantity * -1,
  },
]

export const handlers = ({ setState, session, app, value, setPagination }) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    if (!value.productVariantId) return

    const resp = await getData({ session, app, value, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, value, pagination }) {
  const variables = { input: getDataInput({ pagination, value }) }
  const query = `
    query($input: SalesQueryInput!) {
      salesLinkCount(input: $input)
      salesLinks(input: $input) {
        ticketId
        ticketType
        transDate
        amount
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.salesLinks,
    pagination: getPagination(page, countPerPage, data.salesLinkCount),
  }
}

function getDataInput({ pagination, value }) {
  const input = {
    productVariantId: value.productVariantId,
    orderBy: [{ key: 'transDate', sort: 'DESC' }],
  }
  if (value.transDate) input.transDate = value.transDate
  if (value.ticketType) input.ticketType = value.ticketType
  if (value.staffId) input.staffId = value.staffId
  if (value.locationId) input.locationId = value.locationId
  if (value.distinct) input.distinct = value.distinct
  if (value.showQuantity) input.showQuantity = value.showQuantity
  if (value.showAmount) input.showAmount = value.showAmount
  if (value.distinct) input.distinct = value.distinct
  if (value.isGift) input.isGift = value.isGift
  if (pagination) input.limit = getLimit(pagination)
  return input
}
