export default {
  'error.customer.duplicate': '客戶已存在！',
  'customer.title': '客戶管理',
  'customer.title.list': '客戶目錄',
  'customer.title.search': '搜尋客戶',
  'customer.title.add': '新增客戶',
  'customer.title.addPhone': '新增聯絡電話',
  'customer.title.addCellphone': '新增聯絡手機',
  'customer.title.addAddress': '新增收貨地址',
  'customer.title.edit': '修改客戶基礎信息',
  'customer.title.view': '客戶詳情',
  'customer.title.delete': '刪除客戶',
  'customer.title.deletePhone': '刪除聯絡電話',
  'customer.title.deleteAddress': '刪除收貨地址',
  'customer.title.editPhone': '修改聯絡電話',
  'customer.title.editAddress': '修改收貨地址',
  'customer.title.memoAdd': '新增客服紀錄',
  'customer.title.memoEdit': '修改客服紀錄',
  'customer.title.memoView': '客服紀錄詳情',
  'customer.title.productAdd': '新增購買商品',
  'customer.title.productEdit': '修改購買商品',
  'customer.title.productView': '購買商品詳情',
  'customer.title.deleteProduct': '刪除商品',
  'customer.title.switch': '轉換用戶',
  'customer.title.addDuplicate': '客戶資料已存在',
  'customer.message.addDuplicate': '聯絡人{newName}與既有客戶{name}存在相同聯絡資料。確認繼續將新增客戶{newName}。確認繼續？',
  'customer.title.metaNotFound': '客戶資料異動',
  'customer.message.metaNotFound': '聯絡人資料有異於系統之客戶資料。確認修改將更新電話、手機及地址至客戶資料。確認修改？',
  'customer.btn.merge': '合併',
  'customer.tooltip.switchUser': '轉換用戶',
  'customer.message.delete': '確認刪除客戶?',
  'customer.message.deletePhone': '確認刪除聯絡電話?',
  'customer.message.deleteAddress': '確認刪除收貨地址?',
  'customer.message.deleteProduct': '確認刪除商品?',
  'customer.success.switch': '成功轉換用戶',
  'customer.section.basic': '基礎信息',
  'customer.section.phone': '聯絡電話',
  'customer.section.cellphone': '聯絡手機',
  'customer.section.address': '收貨地址',
  'customer.section.memo': '客服列表',
  'customer.section.product': '產品列表',
  'customer.section.refund': '退貨列表',
  'customer.section.repair': '維修紀錄',
  'customer.field.id': '用戶編號',
  'customer.field.name': '姓名',
  'customer.field.subscription': '訂閱DM',
  'customer.field.bip': 'BIP',
  'customer.field.isNotIndividual': '非獨立客戶',
  'customer.field.phone': '電話',
  'customer.field.cellphone': '手機',
  'customer.field.phoneAndCellphone': '電話 / 手機',
  'customer.field.address': '地址',
  'customer.field.ticketId': '訂單編號',
  'customer.field.createdAt': '加入日期',
  'customer.field.transDate': '交易日期',
  'customer.field.price': '金額',
  'customer.field.customerId': '用戶編號',
  'customer.field.staffId': '客服人員',
  'customer.field.date': '日期',
  'customer.field.content': '內容',
  'customer.status.DEFER': '延出',
  'customer.status.DISPATCH_PENDING': '待出貨',
  'customer.status.DISPATCH_ACTIVE': '已出貨',
  'customer.status.DISPATCH_INACTIVE': '已取消',
  'customer.status.REFUND_PENDING': '待退貨',
  'customer.status.REFUND_ACTIVE': '已退貨',
  'customer.status.REFUND_INACTIVE': '已取消',
}
