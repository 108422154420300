import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/sdjDispatchPendingList'
import { Section, Table } from 'components/core'
import Page from 'components/Page'
import { initialPage } from 'utilities/pagination'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad } = handlers({ session, app, setState, setPagination })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="sdj.title.dispatchPending" navId="sdjDispatchPendingList">
      <Section>
        <Table
          columns={columns({ app, session, action: { handleLoad } })}
          rows={state}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
