import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { Button, Table, Section } from 'components/core'
import Page from 'components/Page'
import { columns, filters, handlers } from 'models/staffList'
import { initialPage } from 'utilities/pagination'
import StaffDrawer from './StaffDrawer'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const { handleInit, handleLoad } = handlers({
    session,
    app,
    setData,
    setState,
    setFilterValues,
    setPagination,
    action: { handleOpen },
  })

  useEffect(() => {
    handleInit()
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page
        title="staff.title.list"
        navId="staffList"
        action={
          <Button
            variant="secondary"
            text="btn.add"
            onClick={() => handleOpen({})}
          />
        }
      >
        <Section>
          <Table
            columns={columns({
              session,
              app,
              message,
              action: { handleOpen, handleLoad },
            })}
            rows={state}
            filters={filters({ data })}
            filterValues={filterValues}
            pagination={pagination}
            onChange={handleLoad}
          />
        </Section>
      </Page>
      <StaffDrawer
        open={open}
        value={value}
        action={{ handleLoad, handleClose }}
      />
    </>
  )
}
