import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { initialState, columns, handlers } from 'models/refundImportModal'
import { SessionContext, AppContext } from 'contexts'
import { Box, Flex } from 'reflexbox'
import { Button, Table } from 'components/core'
import RefundImportDrawer from 'views/RefundImportDrawer'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ open, value, data, action }) => {
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value))
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerValue, setDrawerValue] = useState({})

  const handleOpen = (item) => {
    setDrawerValue(item)
    setDrawerOpen(true)
  }

  const handleClose = () => {
    setDrawerOpen(false)
    setDrawerValue({})
  }

  const handler = handlers({ app, session, state, setState, history })

  useEffect(() => {
    setState(initialState(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.toLocationId, value.ticketItems])

  return (
    <Box
      height="100vh"
      width="100vw"
      bg="light.0"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1001,
        transform: open ? 'translateY(0px)' : 'translateY(110vh)',
        opacity: open ? 1 : 0,
        transition: `transform .3s ease-out, opacity 0.15s ease-out`,
      }}
    >
      <Box width={1} height="calc(100% - 65px)" sx={{ overflow: 'auto' }}>
        <Table
          flex={1}
          width={1}
          headProps={{
            bg: 'light.0',
            sx: {
              position: 'sticky',
              top: 0,
              borderBottom: 'none',
              fontWeight: 300,
              whiteSpace: 'nowrap',
              textAlign: 'left',
            },
          }}
          bodyProps={{ sx: { overflow: 'visible' } }}
          columns={columns({
            state,
            setState,
            message,
            action: { handleOpen },
          })}
          rows={state.ticketItems}
        />
      </Box>
      <Flex
        p={3}
        height="65px"
        justifyContent="flex-end"
        alignItems="center"
        bg="light.0"
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          borderTopStyle: 'solid',
          borderTopColor: 'light.3',
          borderTopWidth: '1px',
        }}
      >
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          variant="primary"
          text="btn.submit"
          onClick={useCallbackOnce(handler.handleSubmit)}
        />
      </Flex>
      <RefundImportDrawer
        open={drawerOpen}
        value={drawerValue}
        data={data}
        action={{ handleClose, editTicketItem: handler.editTicketItem }}
      />
    </Box>
  )
}
