import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { Switch, TextArea, TextInput } from 'components/form'
import { Definition } from 'components/core'

export const initialState = (value) => ({
  id: value.id,
  ...initializeState({
    name: value.name || '',
    subscription: value.extra?.subscription || true,
    memo: value.extra?.memo || '',
    isNotIndividual: value.extra?.isNotIndividual || false,
    bip: value.extra?.bip || false,
  }),
})

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    name: (
      <TextInput
        id="name"
        label="customer.field.name"
        placeholder="customer.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    subscription: (
      <Definition
        direction="row"
        label="customer.field.subscription"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={!!state.subscription}
          onClick={() => {
            setState({ ...state, subscription: !state.subscription })
          }}
        />
      </Definition>
    ),
    bip: (
      <Definition
        direction="row"
        label="customer.field.bip"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={!!state.bip}
          onClick={() => {
            setState({ ...state, bip: !state.bip })
          }}
        />
      </Definition>
    ),
    isNotIndividual: (
      <Definition
        direction="row"
        label="customer.field.isNotIndividual"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={!!state.isNotIndividual}
          onClick={() => {
            setState({ ...state, isNotIndividual: !state.isNotIndividual })
          }}
        />
      </Definition>
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

export const handlers = ({ session, app, state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await editBasic(state, app, session)
    if (!ok) return

    action.handleLoad()
    action.handleClose()
  },
})

async function editBasic(state, app, session) {
  const input = {
    name: state.name,
    extra: {
      subscription: state.subscription,
      bip: state.bip,
      isNotIndividual: state.isNotIndividual,
      memo: state.memo,
    },
  }
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: CustomerInput!) {
      editCustomer(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
