import React from 'react'
import { Select, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
} from 'utilities/form'

export const initialState = (value = {}, data) => ({
  index: value.index,
  ...initializeState({
    ticketId: value.ticketId || '',
    productVariantId: value.productVariantId || {},
    serial: value.serial || '',
  }),
})

const validation = {
  ticketId: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    ticketId: (
      <TextInput
        id="ticketId"
        label="dispatch.field.ticketId"
        placeholder="dispatch.field.ticketId"
        value={state.ticketId}
        onChange={onTextChange('ticketId')}
        errMsg={state.__error__.ticketId}
      />
    ),
    productVariantId: (
      <Select
        id="productVariantId"
        label="field.productName"
        placeholder="field.productName"
        isClearable={false}
        options={data.products}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    serial: (
      <TextInput
        id="serial"
        label="dispatch.field.serial"
        placeholder="dispatch.field.serial"
        value={state.serial}
        onChange={onTextChange('serial')}
        errMsg={state.__error__.serial}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return
    action.editTicketItem(state)
    action.handleClose()
  },
})
