import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  handleTextChange,
  getSelectOptions,
  getSelectOption,
} from 'utilities/form'
import { Select, Switch, TextInput } from 'components/form'
import { Definition } from 'components/core'

export const initialState = (value = {}, data = {}) => {
  const productOptions = getSelectOptions(data.products)
  return {
    index: value.index,
    productOptions,
    ...initializeState({
      productVariantId: getSelectOption(productOptions, value.productVariantId),
      code: value.code || '',
      isImportActive: value.importStatus === 'ACTIVE',
      isExportActive: value.exportStatus === 'ACTIVE',
    }),
  }
}

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
  code: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    productVariantId: (
      <Select
        isClearable={false}
        label="field.productName"
        placeholder="field.productName"
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="pxmart.field.code"
        placeholder="pxmart.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    isImportActive: (
      <Definition
        direction="row"
        label="pxmart.field.importStatus"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isImportActive}
          onClick={() => {
            setState({ ...state, isImportActive: !state.isImportActive })
          }}
        />
      </Definition>
    ),
    isExportActive: (
      <Definition
        direction="row"
        label="pxmart.field.exportStatus"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isExportActive}
          onClick={() => {
            setState({ ...state, isExportActive: !state.isExportActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const variantId = state.productVariantId.value
    const variant = data.products.find((item) => item.id === variantId)
    action.addCode({
      index: state.index,
      productVariantId: variant.id,
      productVariantName: variant.name,
      code: state.code,
      importStatus: state.isImportActive ? 'ACTIVE' : 'INACTIVE',
      exportStatus: state.isExportActive ? 'ACTIVE' : 'INACTIVE',
    })
    action.handleClose()
  },
})
