import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { getDiff } from 'utilities/list'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getDate,
  getSelectOption,
  getSelectOptions,
  showDate,
  renderImageDropzone,
  renderFileDropzone,
} from 'utilities/form'
import { request } from 'utilities/request'
import { printHtml } from 'utilities/print'
import { fetchBalance } from 'actions/inventory'
import { getDisplayQuantity, handleDelete, setBalances } from 'actions/ticket'
import { Definition, Button, Table, Center } from 'components/core'
import { TextArea, Select, NumberInput, DateInput } from 'components/form'
import { MdEdit, MdDelete } from 'react-icons/md'
import { filterLocations } from 'utilities/permission'
import { getProduct } from 'actions/product'
import { updateImages } from 'actions/image'
import { uploadFile, urlToFile } from 'utilities/file'

const url = process.env.REACT_APP_STATIC_URL

export const initialState = (value = {}, data = {}, message) => {
  const processType = value.extra?.processType
  const locations = groupLocations(data.staff, data.locations)
  const warehouses = getSelectOptions(locations.warehouses)
  const depts = locations.depts || []
  const orgs = locations.orgs || []
  const { fromTicketItems, toTicketItems, materialItems } = groupTicketItems(
    value.ticketItems,
  )

  const isPack = ['PACK_SINGLE', 'PACK_COMBO'].includes(processType)
  const balance = isPack ? 0 : fromTicketItems[0]?.balance || 0
  const oldFromItems = cloneDeep(fromTicketItems)
  const oldToItems = cloneDeep(toTicketItems)
  const oldMaterialItems = cloneDeep(materialItems)

  return {
    isReady: value.isReady || false,
    id: value.id,
    sync: value.sync || false,
    locked: value.locked || false,
    transDate: getDate(value.transDate),
    status: value.status || 'PENDING',
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldFromItems,
    oldToItems,
    fromTicketItems,
    toTicketItems,
    processConfigId: value.extra?.processConfigId,
    processConfigName: value.extra?.processConfigName,
    processItems: value.extra?.processItems || [],
    processImages: value.extra?.processImages || [],
    imagesToAdd: value.imagesToAdd || [],
    imagesToDel: [],
    printFiles: value.extra?.printFiles || [],
    filesToAdd: value.filesToAdd || [],
    filesToDel: [],
    materialItems,
    oldMaterialItems,
    warehouses,
    fromWarehouseName: value.fromWarehouseName || '',
    toWarehouseName: value.toWarehouseName || '',
    processes: data.processes,
    products: data.products || [],
    // productVariantName: value.productVariantName || '',
    balance,
    locations: [
      { label: message({ id: 'location.type.DEPT' }), options: depts },
      { label: message({ id: 'location.type.DEALER_ORG' }), options: orgs },
    ],
    ...initializeState({
      processType: getProcessType(processType, message),
      fromWarehouseId: getSelectOption(warehouses, value.fromWarehouseId),
      toWarehouseId: getSelectOption(warehouses, value.toWarehouseId),
      // productVariantId: getSelectOption(
      //   value.products,
      //   value.productVariantId,
      //   'id',
      //   'name',
      // ),
      quantity: value.quantity || 1,
      quantityInnerBox: value.extra?.quantityInnerBox || 0,
      quantityOuterBox: value.extra?.quantityOuterBox || 0,
      // batchNo: value.batchNo || '',
      // expireAt: showDate(value.expireAt) || '',
      finishDate: getDate(value.extra?.finishDate),
      memo: value.extra?.memo || '',
      locationId: getSelectOption([...depts, ...orgs], value.extra?.locationId),
      weightFactor: value.extra?.weightFactor || 1,
    }),
  }
}

function getProcessType(value, message) {
  if (!value) value = 'PACK_SINGLE'
  return { value, label: message({ id: `process.type.${value}` }) }
}

const validation = {}

const defs = (state) => ({
  id: state.id,
  finishDate: state.finishDate,
  transDate: state.transDate,
  processType: state.processType.label,
  // fromWarehouse: state.fromWarehouseName,
  // toWarehouse: state.toWarehouseName,
  // product: state.productVariantName,
  // quantity: state.quantity,
  quantityInnerBox: state.quantityInnerBox,
  quantityOuterBox: state.quantityOuterBox,
  locationId: state.locationId.label,
  // batchNo: state.batchNo,
  // expireAt: showDate(state.expireAt),
  memo: state.memo,
})

const productColumns = ({ profile, isFrom, state, setState, action }) => {
  const isPack = ['PACK_SINGLE', 'PACK_COMBO'].includes(state.processType.value)
  const isSrc = (isPack && !isFrom) || (!isPack && isFrom)
  return [
    {
      id: 'productVariantName',
      label: 'process.field.spu',
    },
    {
      id: 'fromLocationName',
      label: 'process.field.fromWarehouse',
      show: isFrom,
    },
    {
      id: 'toLocationName',
      label: 'process.field.toWarehouse',
      show: !isFrom,
    },
    {
      id: 'ticketNo',
      label: 'process.field.ticketNo',
      show: !isSrc,
    },
    {
      id: 'batchNo',
      label: 'process.field.batchNo',
      render: ({ row }) => row.batchNo,
    },
    {
      id: 'expireAt',
      label: 'process.field.expireAt',
      render: ({ row }) => row.expireAt,
    },
    {
      id: 'unit',
      label: 'process.field.unit',
      align: 'right',
      show: !isSrc,
    },
    {
      id: 'balance',
      label: 'process.field.balance',
      align: 'right',
      show: isFrom && profile !== 'view',
    },
    {
      id: 'quantity',
      label: 'process.field.quantity',
      align: 'right',
      renderHtml: ({ row }) => {
        if (!isFrom) return row.quantity
        return getDisplayQuantity(row.quantity, row.balance)
      },
    },
    {
      id: 'actions',
      align: 'right',
      noWrap: true,
      format: ['html'],
      show: profile !== 'view',
      renderHtml: ({ row, index }) => (
        <Center justifyContent="flex-end">
          <Button
            variant="icon"
            icon={<MdEdit />}
            onClick={() => {
              if (isSrc) {
                action.handleSrcOpen({
                  ...row,
                  oldTicketItem: state.oldFromItems[index],
                  index,
                  isFrom,
                })
              } else {
                action.handleDestOpen({
                  ...row,
                  oldTicketItem: state.oldFromItems[index],
                  index,
                  isFrom,
                })
              }
            }}
          />
          <Button
            variant="icon"
            icon={<MdDelete />}
            onClick={() => {
              const key = isFrom ? 'fromTicketItems' : 'toTicketItems'
              const ticketItems = [...state[key]]
              ticketItems.splice(index, 1)
              setState({ ...state, [key]: ticketItems })
            }}
          />
        </Center>
      ),
    },
  ]
}

const processColumns = (state, message) => [
  {
    id: 'processName',
    label: 'process.field.processName',
  },
  {
    id: 'code',
    label: 'processConfig.field.code',
  },
  {
    id: 'memo',
    label: 'process.field.memo',
  },
  {
    id: 'quantity',
    label: 'process.field.quantity',
    align: 'right',
  },
  {
    id: 'unit',
    label: 'process.field.unit',
    align: 'right',
    render: ({ row }) => message({ id: `processConfig.unit.${row.unit}` }),
  },
  {
    id: 'price',
    label: 'process.field.price',
    align: 'right',
  },
  {
    id: 'subPrice',
    label: 'process.field.subPrice',
    align: 'right',
    render: ({ row }) =>
      getSubPrice(
        row,
        state.quantity,
        state.quantityInnerBox,
        state.quantityOuterBox,
      ),
  },
]

const materialColumns = [
  {
    id: 'productVariantName',
    label: 'product.field.spu',
  },
  {
    id: 'sku',
    label: 'process.field.sku',
  },
  {
    id: 'memo',
    label: 'process.field.memo',
  },
  {
    id: 'fromLocationName',
    label: 'process.field.fromWarehouse',
  },
  {
    id: 'quantity',
    label: 'process.field.quantity',
    align: 'right',
  },
  {
    id: 'unitPrice',
    label: 'field.unitPrice',
    align: 'right',
  },
  {
    id: 'totalPrice',
    label: 'field.totalPrice',
    align: 'right',
  },
]

export const labels = ({ app, state, message, profile, action }) => {
  const processType = state.processType.value
  const isPack = ['PACK_SINGLE', 'PACK_COMBO'].includes(processType)
  const content = Object.entries(defs(state)).reduce((result, [id, value]) => {
    if (isPack && id === 'fromWarehouse') return result
    if (!isPack && id === 'toWarehouse') return result

    const label = `process.field.${id}`
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})
  content.fromTicketItems = (
    <Table
      columns={productColumns({ profile, state, isFrom: true })}
      rows={state.fromTicketItems}
    />
  )
  content.toTicketItems = (
    <Table
      columns={productColumns({ profile, state, isFrom: false })}
      rows={state.toTicketItems}
    />
  )
  content.processList = (
    <Table
      showSeq
      columns={processColumns(state, message)}
      rows={state.processItems}
    />
  )

  if (state.processImages.length > 0) {
    // content.processImages = (
    //   <Flex flexWrap="wrap" alignContent="center">
    //     {state.processImages.map((item) => {
    //       const src = `${url}/${app.state.staff.merchantId}/${item}`
    //       return (
    //         <ImageThumb
    //           key={item}
    //           src={src}
    //           onView={() => {
    //             action.setPreviewOpen(true)
    //             action.setPreviewImage({ src, alt: item })
    //           }}
    //         />
    //       )
    //     })}
    //   </Flex>
    // )
    content.processImages = renderImageDropzone({
      profile,
      state,
      imagePath: `${app.state.staff.merchantId}`,
      imageKey: 'processImages',
    })
  }

  if (state.printFiles.length > 0) {
    content.printFiles = renderFileDropzone({
      profile,
      state,
      filePath: `${app.state.staff.merchantId}`,
      fileKey: 'printFiles',
    })
  }

  content.materialList = (
    <Table showSeq columns={materialColumns} rows={state.materialItems} />
  )
  return content
}

export const fields = ({
  format,
  session,
  app,
  state,
  setState,
  message,
  profile,
  action,
}) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    id: (
      <Definition show={!!state.id} label="field.ticketId" value={state.id} />
    ),
    transDate: (
      <DateInput
        id="transDate"
        label="field.createdAt"
        value={state.transDate}
        role="lockProcessTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    finishDate: (
      <DateInput
        id="finishDate"
        label="process.field.finishDate"
        value={state.finishDate}
        role="lockProcessTicket"
        onChange={onTextChange('finishDate')}
        errMsg={state.__error__.finishDate}
      />
    ),
    processType: (
      <Select
        label="process.field.processType"
        isSearchable={false}
        isClearable={false}
        options={[
          {
            value: 'PACK_SINGLE',
            label: message({ id: 'process.type.PACK_SINGLE' }),
          },
          {
            value: 'UNPACK_SINGLE',
            label: message({ id: 'process.type.UNPACK_SINGLE' }),
          },
          {
            value: 'PACK_COMBO',
            label: message({ id: 'process.type.PACK_COMBO' }),
          },
          {
            value: 'UNPACK_COMBO',
            label: message({ id: 'process.type.UNPACK_COMBO' }),
          },
        ]}
        value={state.processType}
        onChange={onSelectChange('processType', () => {
          return {
            productVariantId: {},
            fromTicketItems: [],
            toTicketItems: [],
          }
        })}
      />
    ),
    quantityInnerBox: (
      <NumberInput
        label="process.field.quantityInnerBox"
        min="0"
        value={state.quantityInnerBox}
        onChange={onTextChange('quantityInnerBox')}
        errMsg={state.__error__.quantityInnerBox}
      />
    ),
    quantityOuterBox: (
      <NumberInput
        label="process.field.quantityOuterBox"
        min="0"
        value={state.quantityOuterBox}
        onChange={onTextChange('quantityOuterBox')}
        errMsg={state.__error__.quantityOuterBox}
      />
    ),
    locationId: (
      <Select
        label="process.field.locationId"
        options={state.locations}
        value={state.locationId}
        onChange={onSelectChange('locationId')}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="process.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    weightFactor: (
      <NumberInput
        fieldProps={{ my: 0, width: 85 }}
        type="decimal"
        min="0"
        value={state.weightFactor}
        onChange={onTextChange('weightFactor')}
        errMsg={state.__error__.weightFactor}
      />
    ),
    processFilter: (
      <Select
        id="processFilter"
        placeholder="process.field.processFilter"
        options={getSelectOptions(state.processes)}
        value=""
        onChange={(item) => {
          if (!item) return
          const { processes } = state
          const process = processes.find(({ id }) => id === `${item.value}`)
          const { processItems } = process.content
          const { images = [] } = process.extra || {}
          setState({
            ...state,
            processConfigId: item.value,
            processConfigName: item.label,
            processItems,
            processImages: images,
          })
        }}
      />
    ),
    fromTicketItems: (
      <Table
        columns={productColumns({
          profile,
          state,
          setState,
          action,
          isFrom: true,
        })}
        rows={state.fromTicketItems}
      />
    ),
    toTicketItems: (
      <Table
        columns={productColumns({
          profile,
          state,
          setState,
          action,
          isFrom: false,
        })}
        rows={state.toTicketItems}
      />
    ),
    processList: (
      <Table
        showSeq
        columns={[
          {
            id: 'processName',
            label: 'process.field.processName',
          },
          {
            id: 'code',
            label: 'processConfig.field.code',
          },
          {
            id: 'memo',
            label: 'process.field.memo',
          },
          {
            id: 'quantity',
            label: 'process.field.unitQuantity',
            align: 'right',
          },
          {
            id: 'unit',
            label: 'process.field.unit',
            align: 'right',
            render: ({ row }) =>
              message({ id: `processConfig.unit.${row.unit}` }),
          },
          {
            id: 'price',
            label: 'process.field.price',
            align: 'right',
          },
          {
            id: 'subPrice',
            label: 'process.field.subPrice',
            align: 'right',
            render: ({ row }) =>
              getSubPrice(
                row,
                state.quantity,
                state.quantityInnerBox,
                state.quantityOuterBox,
              ),
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <Center justifyContent="flex-end">
                <Button
                  mr={1}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() => action.handleProcessOpen({ ...row, index })}
                />
                <Button
                  icon={<MdDelete />}
                  variant="icon"
                  onClick={() => {
                    const processItems = [...state.processItems]
                    processItems.splice(index, 1)
                    setState({ ...state, processItems })
                  }}
                />
              </Center>
            ),
          },
        ]}
        rows={state.processItems}
      />
    ),
    processImages: renderImageDropzone({
      format,
      profile,
      session,
      state,
      setState,
      action,
      imagePath: `${app.state.staff.merchantId}`,
      imageKey: 'processImages',
    }),
    printFiles: renderFileDropzone({
      format,
      profile,
      session,
      state,
      setState,
      action,
      accept: ['.btw'],
      placeholder: 'Upload .btw files',
      filePath: `${app.state.staff.merchantId}`,
      fileKey: 'printFiles',
    }),
    materialList: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'product.field.spu',
          },
          {
            id: 'sku',
            label: 'process.field.sku',
          },
          {
            id: 'memo',
            label: 'process.field.materialMemo',
          },
          {
            id: 'fromLocationName',
            label: 'process.field.fromWarehouse',
          },
          // {
          //   id: 'balance',
          //   label: 'inventory.field.balance',
          //   align: 'right',
          // },
          {
            id: 'quantity',
            label: 'process.field.quantity',
            align: 'right',
          },
          {
            id: 'unitPrice',
            label: 'field.unitPrice',
            align: 'right',
          },
          {
            id: 'totalPrice',
            label: 'field.totalPrice',
            align: 'right',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <Center justifyContent="flex-end">
                <Button
                  mr={1}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() => {
                    action.handleMaterialOpen({
                      ...row,
                      oldMaterialItem: state.oldMaterialItems[index],
                      index,
                    })
                  }}
                />
                <Button
                  icon={<MdDelete />}
                  variant="icon"
                  onClick={() => {
                    const materialItems = [...state.materialItems]
                    materialItems.splice(index, 1)
                    setState({ ...state, materialItems })
                  }}
                />
              </Center>
            ),
          },
        ]}
        rows={state.materialItems}
      />
    ),
  }
}

function getSubPrice(row, quantityPiece, quantityInnerBox, quantityOuterBox) {
  const unitQuantity = parseInt(row.quantity)
  const unitPrice = parseFloat(row.price)
  let quantity = 0
  switch (row.unit) {
    case 'PCS':
      // quantity = parseInt(quantityPiece)
      quantity = parseInt(1)
      break
    case 'INNER_BOX':
      quantity = parseInt(quantityInnerBox)
      break
    case 'OUTER_BOX':
      quantity = parseInt(quantityOuterBox)
      break
    default:
      quantity = 0
  }
  return Math.round(unitQuantity * unitPrice * quantity * 100) / 100
}

export function getTotalPrice({
  processItems = [],
  quantity,
  quantityInnerBox,
  quantityOuterBox,
  weightFactor,
}) {
  let total = 0
  processItems.forEach((item) => {
    total += getSubPrice(item, quantity, quantityInnerBox, quantityOuterBox)
  })
  return Math.round(total * parseFloat(weightFactor) * 100) / 100
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  data,
  setData,
  message,
  history,
  id,
  copyId,
  profile,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ app, session })
    setData(resp)
  },
  handleLoad: async () => {
    if (copyId) id = copyId
    const resp = await getData({ app, session, id, profile })
    if (copyId) {
      resp.id = null
      resp.status = null
      resp.createdBy = null
      resp.updatedAt = null
      resp.processItems = resp.processItems.map((item) => ({
        ...item,
        id: null,
      }))
      resp.imagesToAdd = await fetchImages(
        resp.processImages,
        app.state.staff.merchantId,
      )
      resp.processImages = resp.imagesToAdd
      resp.oldFromItems = []
      resp.oldToItems = []
      resp.oldMaterialItems = []
    }
    const initState = initialState(resp, data, message)
    await setItemBalances(app, session, initState, profile)
    setState(initState)
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editProcess(state, app, session)
      : await addProcess(state, app, session, copyId)
    if (!ok) return

    if (!id) id = data.addProcessTicket

    const imageOk = await updateImages(app, session, state, id)
    if (!imageOk) {
      if (!id) history.push(`/process/${id}/edit`)
      return
    }

    const fileOk = await updatePrintFiles(app, session, state, id)
    if (!fileOk) {
      if (!id) history.push(`/process/${id}/edit`)
      return
    }

    history.push(`/process/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmProcess({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    const initState = initialState(resp, data, message)
    await setItemBalances(app, session, initState, profile)
    setState(initState)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })
    return true
  },
  handleComplete: async () => {
    const { hash } = state
    const [ok] = await completeProcess({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    const initState = initialState(resp, data, message)
    await setItemBalances(app, session, initState, profile)
    setState(initState)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })
    return true
  },
  addProductItem: (value) => {
    updateItemQuantities(state, value)
  },
  editSrcItem: (value) => {
    const { processType } = state
    const isPack = ['PACK_SINGLE', 'PACK_COMBO'].includes(processType.value)
    const srcKey = isPack ? 'toTicketItems' : 'fromTicketItems'
    const destKey = isPack ? 'fromTicketItems' : 'toTicketItems'
    const srcTicketItems = setSrcItems(state, srcKey, value, value.index)
    const destTicketItems = setDestItems(state, destKey, value)

    if (state.id) {
      setState({ ...state, [srcKey]: srcTicketItems })
    } else {
      setState({
        ...state,
        [srcKey]: srcTicketItems,
        [destKey]: destTicketItems,
      })
    }
  },
  editDestItem: (value) => {
    const { index, ...rest } = value
    const { processType } = state
    const isPack = ['PACK_SINGLE', 'PACK_COMBO'].includes(processType.value)
    const key = isPack ? 'fromTicketItems' : 'toTicketItems'
    const ticketItems = [...state[key]]
    if (index === -1) {
      ticketItems.push(rest)
    } else {
      ticketItems.splice(index, 1, rest)
    }
    setState({ ...state, [key]: ticketItems })
  },
  addProcessItem: (value) => {
    const processItems = [...state.processItems]
    const { index } = value
    if (index === -1) {
      processItems.push(value)
    } else {
      processItems.splice(index, 1, value)
    }
    setState({ ...state, processItems })
  },
  addMaterialItem: (value) => {
    const materialItems = [...state.materialItems]
    const { index } = value
    if (index === -1) {
      materialItems.push(value)
    } else {
      materialItems.splice(index, 1, value)
    }
    setState({ ...state, materialItems })
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('process', { session, app, id, hash })
    if (!ok) return false

    history.push('/process')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state, profile, message })
    printHtml({ title, header, content, message })
  },
  sortProducts: (ref, onClose) => () => {
    const indexList = ref.current.getRows().map((item) => item.index)
    const processItems = indexList.reduce((result, item) => {
      result.push(state.processItems[item])
      return result
    }, [])

    setState({ ...state, processItems })
    onClose()
  },
})

export function getPrintData({ state, profile, message }) {
  const title = { id: 'process.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const processType = state.processType.value
  const isPack = ['PACK_SINGLE', 'PACK_COMBO'].includes(processType)
  const field = Object.entries(defs(state))
    .filter(([id]) => {
      if (isPack && id === 'fromWarehouse') return false
      if (!isPack && id === 'toWarehouse') return false
      return true
    })
    .map(([id, value]) => ({ label: `process.field.${id}`, value }))
  const fromTicketItems = {
    columns: productColumns({ profile, state, isFrom: true }),
    rows: state.fromTicketItems,
  }
  const toTicketItems = {
    columns: productColumns({ profile, state, isFrom: false }),
    rows: state.toTicketItems,
  }
  const processList = {
    columns: processColumns(state, message),
    rows: state.processItems,
  }
  const materialList = { columns: materialColumns, rows: state.materialItems }
  const content = [
    { type: 'field', value: field },
    { type: 'subTitle', value: 'process.section.fromPackItems' },
    { type: 'list', value: fromTicketItems },
    { type: 'subTitle', value: 'process.section.toPackItems' },
    { type: 'list', value: toTicketItems },
    { type: 'subTitle', value: 'process.section.process' },
    { type: 'list', value: processList },
    { type: 'subTitle', value: 'process.section.material' },
    { type: 'list', value: materialList },
  ]
  return { title, header, content }
}

async function fetchImages(urls, merchantId) {
  return Promise.all(
    urls.map((item) => urlToFile(`${url}/${merchantId}/${item}`)),
  )
}

async function getInit({ app, session }) {
  const locationInput = { type: ['WAREHOUSE', 'DEPT', 'DEALER_ORG'] }
  const configInput = { type: 'PROCESS' }
  const variables = { locationInput, configInput }
  const query = `
    query($locationInput: LocationQueryInput, $configInput: TicketConfigQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra {
          type
        }
      }
      productVariants {
        id
        type
        name
        sku
        childVariants {
          id
          parentId
          name
          sku
          quantity
        }
        postedPrice
      }
      ticketConfigs(input: $configInput) {
        id
        name
        content
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { locations, productVariants, ticketConfigs } = data
  return {
    locations,
    processes: ticketConfigs,
    products: productVariants,
    staff: app.state.staff,
  }
}

function groupLocations(staff, locations) {
  const depts = []
  const orgs = []
  const warehouses = []

  if (!staff || !locations) return { depts, orgs, warehouses }

  locations.forEach((item) => {
    const result = { label: item.name, value: item.id }
    switch (item.type) {
      case 'DEPT':
        depts.push(result)
        break
      case 'DEALER_ORG':
        orgs.push(result)
        break
      case 'WAREHOUSE':
        warehouses.push(item)
        break
      default:
    }
  })

  return {
    warehouses: filterLocations(staff, warehouses),
    depts,
    orgs,
  }
}

async function getData({ app, session, id, profile }) {
  const { merchantId } = app.state.staff
  const variables = { id, merchantId, vendor: 'sdj' }
  const query = `
    query($id: ID, $merchantId: ID!, $vendor: String!) {
      plugin(merchantId: $merchantId, vendor: $vendor) {
        isActive
      }
      processTicket(id: $id) {
        ticketNo
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      processTicketItems(ticketId: $id) {
        id
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        productVariantId
        productVariantName
        sku
        quantity
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.processTicket || {}
  const extra = ticket.extra || {}
  const quantity = extra.quantity

  return {
    isReady: profile === 'edit',
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    sync: extra.sync === undefined ? data.plugin.isActive : extra.sync,
    locked: extra.locked,
    ticketNo: ticket.ticketNo,
    transDate: ticket.transDate,
    // finishDate: extra.finishDate,
    fromWarehouseId: ticket.fromLocationId,
    fromWarehouseName: ticket.fromLocationName,
    toWarehouseId: ticket.toLocationId,
    toWarehouseName: ticket.toLocationName,
    // productVariantId: extra.productVariantId,
    // productVariantName: extra.productVariantName,
    quantity,
    // quantityInnerBox: extra.quantityInnerBox,
    // quantityOuterBox: extra.quantityOuterBox,
    // locationId: extra.locationId,
    // batchNo: extra.batchNo,
    // expireAt: extra.expireAt,
    // memo: extra.memo,
    status: ticket.status,
    hash: ticket.hash,
    // weightFactor: extra.weightFactor,
    ticketItems: data.processTicketItems,
  }
}

function groupTicketItems(processTicketItems) {
  if (!processTicketItems) {
    return { fromTicketItems: [], toTicketItems: [], materialItems: [] }
  }

  const fromTicketItems = processTicketItems
    .filter((item) => {
      if (item.extra?.isMaterial) return false
      return !!item.fromLocationId
    })
    .map((item) => ({
      id: item.id,
      ticketNo: item.extra?.ticketNo,
      productVariantId: item.productVariantId,
      productVariantName: item.productVariantName,
      fromLocationId: item.fromLocationId,
      fromLocationName: item.fromLocationName,
      batchNo: item.extra?.batchNo,
      expireAt: showDate(item.extra?.expireAt),
      unit: item.extra?.unit,
      quantity: item.quantity,
    }))
  const toTicketItems = processTicketItems
    .filter((item) => {
      if (item.extra?.isMaterial) return false
      return !!item.toLocationId
    })
    .map((item) => ({
      id: item.id,
      ticketNo: item.extra?.ticketNo,
      productVariantId: item.productVariantId,
      productVariantName: item.productVariantName,
      toLocationId: item.toLocationId,
      toLocationName: item.toLocationName,
      batchNo: item.extra?.batchNo,
      expireAt: showDate(item.extra?.expireAt),
      unit: item.extra?.unit,
      quantity: item.quantity,
    }))
  const materialItems = processTicketItems
    .filter((item) => item.extra?.isMaterial)
    .map((item) => ({
      id: item.id,
      productVariantId: item.productVariantId,
      productVariantName: item.productVariantName,
      fromLocationId: item.fromLocationId,
      fromLocationName: item.fromLocationName,
      sku: item.sku,
      memo: item.extra?.memo,
      quantity: item.quantity,
      unitPrice: item.extra?.unitPrice,
      totalPrice: Math.round(item.extra?.totalPrice * 100) / 100,
      batchNo: item.extra?.batchNo,
      expireAt: showDate(item.extra?.expireAt),
    }))

  return {
    fromTicketItems: fromTicketItems || [],
    toTicketItems: toTicketItems || [],
    materialItems: materialItems || [],
  }
}

async function setItemBalances(app, session, data, profile) {
  if (['add', 'view'].includes(profile)) return
  const { fromTicketItems, oldFromItems, materialItems } = data

  if (fromTicketItems.length > 0) {
    const balances = await Promise.all(
      fromTicketItems.map(async (ticketItem) => {
        const { productVariantId, fromLocationId } = ticketItem
        const balance = await fetchBalance({
          app,
          session,
          locationType: 'WAREHOUSE',
          locationId: fromLocationId,
          productVariantId,
          oldFromItems,
        })
        return { productVariantId, quantity: balance }
      }),
    )
    setBalances(fromTicketItems, balances)
  }

  if (materialItems.length > 0) {
    const balances = await Promise.all(
      materialItems.map(async ({ productVariantId, fromLocationId }) => {
        const quantity = await fetchBalance({
          app,
          session,
          locationType: 'WAREHOUSE',
          locationId: fromLocationId,
          productVariantId,
        })
        return { productVariantId, quantity }
      }),
    )
    setBalances(materialItems, balances)
  }
}

function updateItemQuantities(isPack, state, quantity) {
  const { fromTicketItems, toTicketItems } = state
  const ticketItems = isPack ? fromTicketItems : toTicketItems
  ticketItems.forEach((item) => {
    item.quantity = Math.round(item.unit * quantity)
  })
  return isPack
    ? { fromTicketItems: ticketItems }
    : { toTicketItems: ticketItems }
}

async function addProcess(state, app, session, copyId) {
  const variables = { input: getSubmitInput(state, copyId) }
  const query = `
    mutation($input: TicketInput!) {
      addProcessTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editProcess(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editProcessTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function confirmProcess({ session, app, id, hash }) {
  const input = { ticketType: 'PROCESS', status: 'ACTIVE', hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmProcessTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function completeProcess({ session, app, id, hash }) {
  const input = { ticketType: 'PROCESS', hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      completeProcessTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state, copyId) {
  const { hash, fromTicketItems, toTicketItems, materialItems } = state
  const oldFromItems = copyId ? [] : state.oldFromItems
  const oldToItems = copyId ? [] : state.oldToItems
  const oldMaterialItems = copyId ? [] : state.oldMaterialItems
  const fromItemDiff = getFromItemDiff(oldFromItems, fromTicketItems)
  const toItemDiff = getToItemDiff(oldToItems, toTicketItems)
  const itemsToAdd = [...fromItemDiff.added, ...toItemDiff.added]
  const itemsToEdit = [
    ...fromItemDiff.modified.map((item) => item.after),
    ...toItemDiff.modified.map((item) => item.after),
  ]
  const itemsToDel = [
    ...fromItemDiff.removed.map((item) => item.id),
    ...toItemDiff.removed.map((item) => item.id),
  ]
  // const ticketItems = [...fromTicketItems, ...toTicketItems]
  // const itemsToAdd = ticketItems.map((item) => ({
  //   id: item.id,
  //   productVariantId: item.productVariantId,
  //   fromLocationId: item.fromLocationId,
  //   toLocationId: item.toLocationId,
  //   quantity: parseInt(item.quantity),
  //   extra: {
  //     ticketNo: item.ticketNo,
  //     batchNo: item.batchNo,
  //     expireAt: item.expireAt,
  //     unit: item.unit,
  //   },
  // }))
  const processItems = state.processItems.map((item) => ({
    processName: item.processName,
    code: item.code,
    memo: item.memo,
    quantity: item.quantity,
    unit: item.unit,
    price: item.price,
  }))
  const materialDiff = getMaterialDiff(oldMaterialItems, materialItems)
  // const materialItems = state.materialItems.map((item) => ({
  //   productVariantId: item.productVariantId,
  //   fromLocationId: item.fromLocationId,
  //   memo: item.memo,
  //   quantity: item.quantity,
  //   batchNo: item.batchNo,
  //   expireAt: item.expireAt,
  // }))

  return {
    hash,
    transDate: state.transDate,
    extra: {
      processType: state.processType.value,
      processConfigId: state.processConfigId,
      processConfigName: state.processConfigName,
      quantityInnerBox: state.quantityInnerBox,
      quantityOuterBox: state.quantityOuterBox,
      locationId: state.locationId?.value,
      finishDate: state.finishDate,
      memo: state.memo,
      processItems,
      processImages: state.processImages.map((item) => {
        return item.path ? `{id}/${item.path}` : item
      }),
      printFiles: state.printFiles.map((item) => item.path || item),
      materialsToAdd: materialDiff.added,
      materialsToEdit: materialDiff.modified.map((item) => item.after),
      materialsToDel: materialDiff.removed.map((item) => item.id),
      sync: state.sync,
      weightFactor: parseFloat(state.weightFactor),
    },
    itemsToAdd,
    itemsToEdit,
    itemsToDel,
  }
}

function getFromItemDiff(oldFromItems, ticketItems) {
  const newItems = ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    fromLocationId: item.fromLocationId,
    quantity: parseInt(item.quantity),
    extra: {
      ticketNo: item.ticketNo,
      batchNo: item.batchNo,
      expireAt: item.expireAt,
      unit: item.unit,
    },
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.id === newItem.id &&
      item.productVariantId === newItem.productVariantId
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.fromLocationId !== newItem.fromLocationId) return false
    if (item.quantity !== newItem.quantity) return false
    if (item.batchNo !== newItem.extra.batchNo) return false
    if (item.expireAt !== newItem.extra.expireAt) return false
    if (item.unit !== newItem.extra.unit) return false
    if (item.ticketNo !== newItem.extra.ticketNo) return false
    return true
  }
  return getDiff(oldFromItems, newItems, isKeyEqual, isValEqual)
}

function getToItemDiff(oldToItems, ticketItems) {
  const newItems = ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    toLocationId: item.toLocationId,
    quantity: parseInt(item.quantity),
    extra: {
      batchNo: item.batchNo,
      expireAt: item.expireAt,
    },
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.id === newItem.id &&
      item.productVariantId === newItem.productVariantId
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.toLocationId !== newItem.toLocationId) return false
    if (item.quantity !== newItem.quantity) return false
    if (item.batchNo !== newItem.extra.batchNo) return false
    if (item.expireAt !== newItem.extra.expireAt) return false
    if (item.memo !== newItem.extra.memo) return false
    return true
  }
  return getDiff(oldToItems, newItems, isKeyEqual, isValEqual)
}

function getMaterialDiff(oldMaterialItems, materialItems) {
  const newItems = materialItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    fromLocationId: item.fromLocationId,
    quantity: parseInt(item.quantity),
    extra: {
      batchNo: item.batchNo,
      expireAt: item.expireAt,
      memo: item.memo,
      unitPrice: item.unitPrice,
      totalPrice: item.totalPrice,
    },
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.id === newItem.id &&
      item.productVariantId === newItem.productVariantId
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.fromLocationId !== newItem.fromLocationId) return false
    if (item.quantity !== newItem.quantity) return false
    if (item.unitPrice !== newItem.unitPrice) return false
    if (item.totalPrice !== newItem.totalPrice) return false
    if (item.batchNo !== newItem.extra.batchNo) return false
    if (item.expireAt !== newItem.extra.expireAt) return false
    if (item.memo !== newItem.extra.memo) return false
    return true
  }
  return getDiff(oldMaterialItems, newItems, isKeyEqual, isValEqual)
}

function setSrcItems(state, key, value, index) {
  const ticketItems = [...state[key]]

  if (index === -1) {
    ticketItems.push(value)
  } else {
    ticketItems.splice(value.index, 1, value)
  }
  return ticketItems
}

function setDestItems(state, key, value) {
  if (state.id) return state[key]

  const { productVariantId } = value
  const { products } = state
  const product = getProduct(products, productVariantId)
  if (!product.id) return []

  // const isCombo = ['PACK_COMBO', 'UNPACK_COMBO'].includes(processType.value)
  // const variants = isCombo ? product.childVariants : [product]
  const variants = product.childVariants || [product]
  const ticketItems = variants.map((item) => {
    const unit = parseFloat(item.quantity || 1)
    const quantity = parseInt(value.quantity || 0)
    return {
      ticketNo: item.ticketNo || '',
      productVariantId: item.id,
      productVariantName: item.name,
      unit,
      balance: 0,
      quantity: quantity * unit,
      srcQuantity: quantity,
    }
  })

  return ticketItems
}

async function updatePrintFiles(app, session, state, id) {
  const { filesToAdd = [], filesToDel = [] } = state
  const result = await Promise.all([
    ...filesToDel.map((file) => deleteFile(app, session, id, file)),
    ...filesToAdd.map((file) => addFile(app, session, id, file)),
  ])
  return !result.some((ok) => !ok)
}

async function addFile(app, session, id, file) {
  const variables = { id, filename: file.name, contentType: file.type }
  const query = `
    mutation($id: ID!, $filename: String!, $contentType: String!) {
      addFile(id: $id, filename: $filename, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addFile
  uploadFile({ url, fields, file })
  return ok
}

async function deleteFile(app, session, id, file) {
  const variables = { id, filename: file }
  const query = `
    mutation($id: ID!, $filename: String!) {
      deleteFile(id: $id, filename: $filename) 
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
