import React from 'react'
import { Select, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOptions,
  getSelectOption,
} from 'utilities/form'
import { request } from 'utilities/request'

export const initialState = (value = {}, data = {}) => {
  const remarks = getSelectOptions(data.remarks)
  return {
    id: value.id,
    remarks,
    ...initializeState({
      id: value.id || '',
      name: value.name || '',
      remarkId: getSelectOption(remarks, value.extra?.remarkId),
    }),
  }
}

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
  remarkId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    name: (
      <TextInput
        id="name"
        label="repair.field.type"
        placeholder="repair.field.type"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    remark: (
      <Select
        label="repair.field.remark"
        isClearable={false}
        options={state.remarks}
        value={state.remarkId}
        onChange={onSelectChange('remarkId')}
        errMsg={state.__error__.remarkId}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editRepairType(state, app, session)
      : await addRepairType(state, app, session)

    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  },
})

async function addRepairType(value, app, session) {
  const { name, remarkId } = value
  const extra = { remarkId: remarkId.value, remarkName: remarkId.label }
  const variables = { input: { name, extra } }
  const query = `
    mutation($input: TicketConfigInput!) {
      addRepairType(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editRepairType(state, app, session) {
  const { name, remarkId } = state
  const extra = { remarkId: remarkId.value, remarkName: remarkId.label }
  const variables = { id: state.id, input: { name, extra } }
  const query = `
    mutation($id: ID, $input: TicketConfigInput!) {
      editRepairType(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
