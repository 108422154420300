import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import {
  initialState,
  fields,
  labels,
  handlers,
} from 'models/repairFeedbackPage'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Popover,
} from 'components/core'
import Page from 'components/Page'
import { MdArrowBack, MdPrint } from 'react-icons/md'
import { renderDeleteLink } from 'utilities/app'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match, profile }) => {
  const { id } = match.params
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))

  const readonly = profile === 'view'
  const form = readonly
    ? labels({ state, setState, message })
    : fields({ state, setState, message })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    history,
    id,
    profile,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <Page
      navId="repairFeedbackList"
      title={`repairFeedback.title.${profile}`}
      titleExtra={
        <StatusChip
          show={readonly}
          ml={2}
          label={`status.${state.status}`}
          color={getStatusColor(state.status)}
          bg="light.1"
        />
      }
      action={
        <Center>
          <Link
            variant="link"
            icon={<MdArrowBack />}
            text="btn.back"
            href="/repair/feedback"
          />
          <Link
            ml={3}
            show={readonly}
            variant="link"
            icon={<MdPrint />}
            tooltip="btn.print"
            onClick={handler.handlePrint}
          />
          <Button
            ml={3}
            show={!readonly}
            variant="primary"
            text="btn.save"
            type="submit"
          />
          <Popover show={readonly}>
            <Link
              py={2}
              disabled={state.status === 'INACTIVE'}
              variant="primaryLink"
              href={`/repair/feedback/${id}/edit`}
              text="btn.edit"
            />
            {renderDeleteLink({
              module: 'repairFeedback',
              session,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
          </Popover>
        </Center>
      }
      showFooterAction
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
        <Section title="repairFeedback.section.customer">
          {form.customerName}
          {form.customerPhone}
          {form.customerCellphone}
        </Section>
        <Section title="repairFeedback.section.repair">
          {form.transDate}
          {form.techId}
          {form.product}
        </Section>
        <Section title="repairFeedback.section.feedback">
          {form.techName}
          {form.scoreOverall}
          {form.scoreAttitude}
          {form.scoreTechnical}
        </Section>
      </Grid>
    </Page>
  )
}
