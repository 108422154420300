import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { columns, filters, handlers } from 'models/reportRefundList2'
import { Center, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload, MdSync } from 'react-icons/md'
import ReportHistoryDrawer from './ReportHistoryDrawer'
import { CONFIRM_SET } from 'constants/actionType'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleInit, handleLoad, handleExport, handleUpdate } = handlers({
    session,
    app,
    message,
    setData,
    setState,
    setFilterValues,
    setPagination,
    data,
  })

  useEffect(() => {
    handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <>
      <Page
        fullWidth
        title="report.title.refund2"
        navId="refundReport2"
        action={
          <Center>
            <Link
              mr={3}
              icon={<MdFileUpload />}
              text="btn.export"
              onClick={() => handleExport({ filterValues, createExcel })}
            />
            <Link
              icon={<MdSync />}
              text="report.btn.update"
              onClick={() => {
                session.dispatch({
                  type: CONFIRM_SET,
                  item: {
                    open: true,
                    title: 'report.title.update',
                    text: 'report.message.update',
                    onSubmit: () => handleUpdate({ pagination, filterValues }),
                  },
                })
              }}
            />
          </Center>
        }
        showAudit
        audit={{ updatedAt: data?.updatedAt }}
      >
        <Section>
          <Table
            columns={columns({ data, handleOpen, filterValues })}
            rows={state}
            filters={filters}
            filterValues={filterValues}
            pagination={pagination}
            onChange={handleLoad}
          />
        </Section>
      </Page>
      <ReportHistoryDrawer value={value} open={open} action={{ handleClose }} />
    </>
  )
}
