import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'
import { TextInput } from 'components/form'

export const initialState = () =>
  initializeState({
    customerId: '',
  })

const validation = {
  customerId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    customerId: (
      <TextInput
        placeholder="customer.field.customerId"
        value={state.customerId}
        onChange={onTextChange('customerId')}
        errMsg={state.__error__.customerId}
      />
    ),
  }
}

export const handlers = ({ value, state, setState, session, app }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { customerId } = state
    const variables = { customerId, ticketId: value.id, product: value }
    const query = `
      mutation($customerId: ID!, $ticketId: ID!, $product: JSON) {
        switchCustomer(customerId: $customerId, ticketId: $ticketId, product: $product)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'customer.success.switch' },
    })

    return true
  },
})
