export default {
  'repair.title.list': '維修目錄',
  'repair.title.typeList': '維修類型設定',
  'repair.title.tagList': '維修註記設定',
  'repair.title.remarkList': '附加內容設定',
  'repair.title.add': '新增維修單',
  'repair.title.addType': '新增維修類型',
  'repair.title.addTag': '新增維修註記',
  'repair.title.addRemark': '新增附加內容',
  'repair.title.edit': '修改維修單',
  'repair.title.deleteType': '刪除維修類型',
  'repair.title.deleteTag': '刪除維修註記',
  'repair.title.view': '維修單詳情',
  'repair.title.addItem': '新增維修商品',
  'repair.title.addReceipt': '新增發票',
  'repair.title.print': '維修單 - {ticketId}',
  'repair.section.product': '商品',
  'repair.section.image': '商品圖示',
  'repair.section.part': '零件銷貨',
  'repair.section.receipt': '發票資訊',
  'repair.field.id': '單號',
  'repair.field.type': '維修類型',
  'repair.field.name': '名稱',
  'repair.field.repairDate': '約排日期',
  'repair.field.repairTime': '約排時間',
  'repair.field.transDate': '訂單日期',
  'repair.field.installDate': '安裝/送貨日期',
  'repair.field.receiptDate': '發票日期',
  'repair.field.receiptType': '發票類型',
  'repair.field.receiptTitle': '抬頭',
  'repair.field.receiptNo': '發票號碼',
  'repair.field.ein': '統編',
  'repair.field.techId': '維修人員',
  'repair.field.supportId': '客服人員',
  'repair.field.dealerId': '經銷商',
  'repair.field.recipientContact': '聯絡人',
  'repair.field.recipientAddress': '地址',
  'repair.field.recipientEmail': 'Email',
  'repair.field.recipientPhone': '電話',
  'repair.field.recipientCellphone': '手機',
  'repair.field.symptom': '報修內容',
  'repair.field.solution': '維修內容',
  'repair.field.tagId': '註記',
  'repair.field.remark': '附加內容',
  'repair.field.commission': '件數',
  'repair.field.collectAmount': '需代收款項金額',
  'repair.field.spu': '商品名稱',
  'repair.field.model': '產品機號',
  'repair.field.serialNo': 'ART',
  'repair.field.price': '發票金額',
  'repair.field.memo': '備註',
  'repair.field.productMemo': '商品備註',
  'repair.field.shippingMemo': '送貨資訊',
  'repair.field.partName': '零件名稱',
  'repair.field.partSku': '零件代碼',
  'repair.field.partTransDate': '零件銷貨日期',
  'repair.field.partQuantity': '零件銷貨數量',
  'repair.field.partPrice': '零件銷貨金額',
  'repair.field.receiptAddress': '發票地址',
  'repair.field.receiptTransDate': '發票日期',
  'repair.field.receiptMemo': '發票備註',
  'repair.field.receiptPrice': '發票金額',
  'repair.field.phoneOrCell': '電話 / 手機',
  'repair.field.priceItem': '項目',
  'repair.receiptType.INDIVIDUAL': '二聯',
  'repair.receiptType.BUSINESS': '三聯',
  'repair.status.ACTIVE': '已完成',
  'repair.status.INACTIVE': '已刪除',
  'repair.status.PENDING': '待約排',
}
