import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import { request } from 'utilities/request'
import {
  getMultiSelectOption,
  initializeState,
  validateForm,
} from 'utilities/form'
import { Flex, Box } from 'reflexbox'
import { Link, Collapsible, Message } from 'components/core'
import { Select, Checkbox } from 'components/form'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

export const initialState = (value = {}) => ({
  staff: value.staff || {},
  warehouseGroups: value.warehouseGroups || [],
  depts: value.depts || [],
  permissions: value.permissions || [],
  rolePermissions: value.rolePermissions || {},
  roles: value.roles || [],
  ...initializeState({
    role: getMultiSelectOption(value.roles, value.role),
  }),
})

const validation = {}

export const fields = ({ state, setState }) => {
  return {
    role: (
      <Select
        label="staff.field.role"
        placeholder="staff.field.role"
        isMulti
        options={state.roles}
        value={state.role}
        onChange={(role) => {
          setPermissions({ state, setState, role })
        }}
      />
    ),
    warehouses: state.warehouseGroups.map((warehouseGroup) => (
      <Flex
        key={warehouseGroup.id}
        flexDirection="column"
        sx={{
          borderBottomColor: 'light.2',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }}
      >
        <Link
          py={2}
          icon={
            <Checkbox
              checked={warehouseGroup.active}
              isPartial={warehouseGroup.isPartial}
              onChange={(event) =>
                toggleWarehouseGroupActive(
                  event,
                  state,
                  setState,
                  warehouseGroup.id,
                )
              }
            />
          }
          rightIcon={getExpandIcon(warehouseGroup.items, warehouseGroup.open)}
          onClick={() =>
            toggleWarehouseGroupOpen(state, setState, warehouseGroup.id)
          }
        >
          <Flex
            flex={1}
            color={warehouseGroup.status === 'ACTIVE' ? 'dark.2' : 'dark.0'}
          >
            {warehouseGroup.name}
            {warehouseGroup.status === 'INACTIVE' && (
              <Flex ml={1}>
                [<Message id="warehouse.status.INACTIVE" />]
              </Flex>
            )}
          </Flex>
        </Link>
        <Box sx={{ display: warehouseGroup.open ? 'block' : 'none' }}>
          {warehouseGroup.items.map((warehouse) => (
            <Flex
              ml={4}
              key={warehouse.id}
              flexDirection="column"
              sx={{
                borderBottomColor: 'light.2',
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
              }}
            >
              <Link
                py={2}
                icon={
                  <Checkbox
                    checked={warehouse.active}
                    onChange={(event) =>
                      toggleWarehouseActive(
                        event,
                        state,
                        setState,
                        warehouseGroup.id,
                        warehouse.id,
                      )
                    }
                  />
                }
              >
                <Flex
                  flex={1}
                  color={warehouse.status === 'ACTIVE' ? 'dark.2' : 'dark.0'}
                >
                  {warehouse.name}
                  {warehouse.status === 'INACTIVE' && (
                    <Flex ml={1}>
                      [<Message id="warehouse.status.INACTIVE" />]
                    </Flex>
                  )}
                </Flex>
              </Link>
            </Flex>
          ))}
        </Box>
      </Flex>
    )),
    depts: state.depts.map((dept) => (
      <Flex
        key={dept.id}
        flexDirection="column"
        sx={{
          borderBottomColor: 'light.2',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }}
      >
        <Link
          py={2}
          icon={
            <Checkbox
              checked={dept.active || dept.isPartial}
              isPartial={dept.isPartial}
              onChange={(event) =>
                toggleDeptActive(event, state, setState, dept.id)
              }
            />
          }
          rightIcon={getExpandIcon(dept.items, dept.open)}
          onClick={() => toggleDeptOpen(state, setState, dept.id)}
        >
          <Flex flex={1} color={dept.status === 'ACTIVE' ? 'dark.2' : 'dark.0'}>
            {dept.name}
            {dept.status === 'INACTIVE' && (
              <Flex ml={1}>
                [
                <Message id="warehouse.status.INACTIVE" />]
              </Flex>
            )}
          </Flex>
        </Link>
        <Box sx={{ display: dept.open ? 'block' : 'none' }}>
          {dept.items.map((org) => (
            <Flex
              ml={4}
              key={org.id}
              flexDirection="column"
              sx={{
                borderBottomColor: 'light.2',
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
              }}
            >
              <Link
                py={2}
                icon={
                  <Checkbox
                    checked={org.active || org.isPartial}
                    isPartial={org.isPartial}
                    onChange={(event) =>
                      toggleOrgActive(event, state, setState, dept.id, org.id)
                    }
                  />
                }
                rightIcon={getExpandIcon(org.items, org.open)}
                onClick={() => toggleOrgOpen(state, setState, dept.id, org.id)}
              >
                <Flex
                  flex={1}
                  color={org.status === 'ACTIVE' ? 'dark.2' : 'dark.0'}
                >
                  {org.name}
                  {org.status === 'INACTIVE' && (
                    <Flex ml={1}>
                      [
                      <Message id="warehouse.status.INACTIVE" />]
                    </Flex>
                  )}
                </Flex>
              </Link>
              <Box sx={{ display: org.open ? 'block' : 'none' }}>
                {org.items.map((dealer) => (
                  <Link
                    key={dealer.id}
                    display="flex"
                    py={2}
                    ml={4}
                    icon={<Checkbox checked={dealer.active} />}
                    onClick={(event) =>
                      toggleDealerActive(
                        event,
                        state,
                        setState,
                        dept.id,
                        org.id,
                        dealer.id,
                      )
                    }
                  >
                    <Flex
                      flex={1}
                      color={dealer.status === 'ACTIVE' ? 'dark.2' : 'dark.0'}
                    >
                      {dealer.name}
                      {dealer.status === 'INACTIVE' && (
                        <Flex ml={1}>
                          [
                          <Message id="warehouse.status.INACTIVE" />]
                        </Flex>
                      )}
                    </Flex>
                  </Link>
                ))}
              </Box>
            </Flex>
          ))}
        </Box>
      </Flex>
    )),
    permissions: Object.entries(state.permissions).map(([key, val]) => (
      <Flex
        key={key}
        flexDirection="column"
        sx={{
          borderBottomColor: 'light.2',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }}
      >
        <Link
          py={2}
          icon={
            <Checkbox
              checked={val.active}
              isPartial={val.isPartial}
              onChange={(event) =>
                toggleModuleActive(event, state, setState, key)
              }
            />
          }
          text={`module.${key}`}
          textProps={{ flex: 1 }}
          rightIcon={val.open ? <MdArrowDropUp /> : <MdArrowDropDown />}
          onClick={() => toggleModuleOpen(state, setState, key)}
        />
        <Collapsible open={val.open}>
          {val.items.map((item, index) => (
            <Link
              py={2}
              key={index}
              display="flex"
              ml={4}
              icon={<Checkbox checked={item.active} />}
              text={`${key}.${item.action}`}
              textProps={{ flex: 1 }}
              onClick={(event) =>
                toggleCodeActive(event, state, setState, key, index)
              }
            />
          ))}
        </Collapsible>
      </Flex>
    )),
  }
}

function resetPermissions(permissions) {
  return Object.entries(permissions).reduce((result, [name, group]) => {
    result[name] = {
      ...group,
      active: false,
      items: group.items.map((item) => ({ ...item, active: false })),
    }
    return result
  }, {})
}

function setPermissions({ state, setState, role }) {
  const permissions = resetPermissions(state.permissions)
  if (!role) {
    setState({ ...state, role, permissions })
    return
  }

  role.forEach(({ value }) => {
    const modules = state.rolePermissions[value] || {}
    Object.entries(modules).forEach(([name, group]) => {
      const permission = permissions[name]
      let count = 0
      permission.items.forEach((item) => {
        if (group.has(item.action)) {
          item.active = true
          count++
        }
      })
      if (count === permission.items.length) {
        permission.active = true
        permission.isPartial = false
      }
    })
  })
  setState({ ...state, role, permissions })
}

function getExpandIcon(items, open) {
  if (items.length === 0) return null
  return open ? <MdArrowDropUp /> : <MdArrowDropDown />
}

function toggleModuleOpen(state, setState, key) {
  const permissions = { ...state.permissions }
  permissions[key].open = !permissions[key].open
  setState({ ...state, permissions })
}

function toggleModuleActive(event, state, setState, key) {
  event.stopPropagation()
  const permissions = { ...state.permissions }
  const permission = permissions[key]
  const items = [...permission.items]
  items.forEach((item) => {
    item.active = !permission.active
  })
  permission.active = !permission.active
  permission.isPartial = !permission.active
  permission.items = items

  setState({ ...state, permissions })
}

function toggleCodeActive(event, state, setState, key, index) {
  event.stopPropagation()
  const permissions = { ...state.permissions }
  const items = [...permissions[key].items]
  items[index].active = !items[index].active
  const count = items.filter((item) => item.active).length
  permissions[key].active = count > 0
  permissions[key].isPartial = count < items.length
  setState({ ...state, permissions })
}

function toggleWarehouseGroupOpen(state, setState, id) {
  const warehouseGroups = [...state.warehouseGroups]
  const warehouseGroup = warehouseGroups.find((item) => item.id === id)
  warehouseGroup.open = !warehouseGroup.open
  setState({ ...state, warehouseGroups })
}

function toggleWarehouseGroupActive(event, state, setState, id) {
  event.stopPropagation()
  const warehouseGroups = [...state.warehouseGroups]
  const warehouseGroup = warehouseGroups.find((item) => item.id === id)
  const active = !warehouseGroup.active
  warehouseGroup.active = active
  warehouseGroup.isPartial = false
  warehouseGroup.items.forEach((warehouse) => {
    warehouse.active = active
  })

  setState({ ...state, warehouseGroups })
}

function toggleWarehouseActive(
  event,
  state,
  setState,
  warehouseGroupId,
  warehouseId,
) {
  event.stopPropagation()
  const warehouseGroups = [...state.warehouseGroups]
  const warehouseGroup = warehouseGroups.find(
    (item) => item.id === warehouseGroupId,
  )
  const warehouse = warehouseGroup.items.find((item) => item.id === warehouseId)
  warehouse.active = !warehouse.active
  if (warehouse.active) {
    warehouseGroup.active = true
  } else {
    warehouseGroup.active = warehouseGroup.items.some((i) => i.active)
  }
  warehouseGroup.isPartial = !warehouseGroup.items.every((i) => i.active)
  setState({ ...state, warehouseGroups })
}

function toggleDeptOpen(state, setState, id) {
  const depts = [...state.depts]
  const dept = depts.find((item) => item.id === id)
  dept.open = !dept.open
  setState({ ...state, depts })
}

function toggleDeptActive(event, state, setState, id) {
  event.stopPropagation()
  const depts = [...state.depts]
  const dept = depts.find((item) => item.id === id)
  const active = !dept.active
  dept.active = active
  dept.isPartial = false
  dept.items.forEach((org) => {
    org.active = active
    org.isPartial = false
    org.items.forEach((dealer) => {
      dealer.active = active
    })
  })

  setState({ ...state, depts })
}

function toggleOrgOpen(state, setState, deptId, orgId) {
  const depts = [...state.depts]
  const dept = depts.find((item) => item.id === deptId)
  const org = dept.items.find((item) => item.id === orgId)
  org.open = !org.open
  setState({ ...state, depts })
}

function toggleOrgActive(event, state, setState, deptId, orgId) {
  event.stopPropagation()
  const depts = [...state.depts]
  const dept = depts.find((item) => item.id === deptId)
  const org = dept.items.find((item) => item.id === orgId)
  const active = !org.active
  org.active = active
  org.isPartial = false
  org.items.forEach((item) => {
    item.active = active
  })
  dept.isPartial = dept.items.some((item) => item.active || item.isPartial)

  setState({ ...state, depts })
}

function toggleDealerActive(event, state, setState, deptId, orgId, dealerId) {
  event.stopPropagation()
  const depts = [...state.depts]
  const dept = depts.find((item) => item.id === deptId)
  const org = dept.items.find((item) => item.id === orgId)
  const dealer = org.items.find((item) => item.id === dealerId)
  dealer.active = !dealer.active
  org.isPartial = org.items.some((item) => item.active || item.isPartial)
  dept.isPartial = dept.items.some((item) => item.active || item.isPartial)

  setState({ ...state, depts })
}

export const handlers = ({ state, setState, app, session, message, id }) => ({
  handleLoad: async () => {
    const resp = await getData({ app, session, id })
    setState(initialState(resp, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await editRole({ app, session, id, state })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
})

async function getData({ app, session, id }) {
  const variables = {
    id,
    roleId: null,
    input: {
      type: ['WAREHOUSE', 'WAREHOUSE_GROUP', 'DEPT', 'DEALER', 'DEALER_ORG'],
      status: ['ACTIVE', 'INACTIVE'],
    },
  }
  const query = `
    query($id: ID, $input: LocationQueryInput) {
      staff(id: $id) {
        type
        name
        username
      }
      locations(input: $input) {
        id
        parentId
        type
        name
        status
      }
      permissions
      roles {
        id
        name
      }
      rolePermissions {
        roleId
        code
      }
      staffRoles(staffId: $id) {
        roleId
      }
      staffPermissions(staffId: $id) {
        code
      }
      staffLocations(staffId: $id) {
        locationId
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  const permissions = getPermissions(data.permissions, data.staffPermissions)
  return {
    ...getLocations(data.locations, data.staffLocations),
    staff: data.staff,
    permissions,
    roles: data.roles.map((item) => ({ value: item.id, label: item.name })),
    rolePermissions: getRolePermissions(data.rolePermissions),
    role: data.staffRoles ? data.staffRoles.map(({ roleId }) => roleId) : [],
  }
}

function getLocations(locations, staffLocations) {
  const staffLocationSet = new Set()
  if (staffLocations) {
    staffLocations.forEach(({ locationId }) => {
      staffLocationSet.add(locationId)
    })
  }

  const warehouseGroups = []
  const warehouses = []
  const depts = []
  const orgs = []
  const dealers = []

  locations.forEach((item) => {
    item.active = staffLocationSet.has(item.id)
    switch (item.type) {
      case 'WAREHOUSE_GROUP':
        item.open = false
        item.isPartial = false
        warehouseGroups.push(item)
        break
      case 'WAREHOUSE':
        warehouses.push(item)
        break
      case 'DEPT':
        item.open = false
        item.isPartial = false
        depts.push(item)
        break
      case 'DEALER_ORG':
        item.open = false
        item.isPartial = false
        orgs.push(item)
        break
      case 'DEALER':
        dealers.push(item)
        break
      default:
    }
  })

  warehouseGroups.forEach((group) => {
    group.items = warehouses.reduce((result, item) => {
      if (item.parentId === group.id) result.push(item)
      return result
    }, [])

    if (!group.active) {
      const isPartial = group.items.some((i) => i.active)
      group.isPartial = isPartial
      group.active = isPartial
    }
  })

  orgs.forEach((org) => {
    org.items = dealers.reduce((result, item) => {
      if (item.parentId === org.id) result.push(item)
      return result
    }, [])

    if (!org.active) {
      const isPartial = org.items.some((i) => i.active)
      org.isPartial = isPartial
    }
  })

  depts.forEach((dept) => {
    dept.items = orgs.reduce((result, item) => {
      if (item.parentId === dept.id) result.push(item)
      return result
    }, [])

    if (!dept.active) {
      const isPartial = dept.items.some((i) => i.active || i.isPartial)
      dept.isPartial = isPartial
    }
  })

  return { warehouseGroups, depts }
}

function getPermissions(permissions, staffPermissions) {
  const staffPermissionSet = new Set()
  if (staffPermissions) {
    staffPermissions.forEach(({ code }) => staffPermissionSet.add(code))
  }

  return Object.entries(permissions).reduce((result, [module, codes]) => {
    let count = 0
    const items = Object.entries(codes).map(([action]) => {
      const active = staffPermissionSet.has(`${module}::${action}`)
      count += active ? 1 : 0
      return { action, active }
    })
    result[module] = {
      items,
      open: false,
      active: count > 0,
      isPartial: count < Object.keys(codes).length,
    }
    return result
  }, {})
}

function getRolePermissions(permissions) {
  return permissions.reduce((result, { roleId, code }) => {
    const [moduleName, actionGroup] = code.split('::')
    const module = result[roleId] || {}
    const group = module[moduleName] || new Set()
    group.add(actionGroup)
    module[moduleName] = group
    result[roleId] = module
    return result
  }, {})
}

async function editRole({ app, session, id, state }) {
  const variables = {
    id,
    input: {
      locations: getActiveLocations(state),
      roles: state.role?.map((item) => item.value),
      permissions: getActiveCodes(state.permissions),
    },
  }
  const query = `
    mutation($id: ID!, $input: StaffInput!) {
      editStaffRole(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getActiveLocations({ warehouseGroups, depts }) {
  const result = []

  warehouseGroups.forEach((warehouseGroup) => {
    let count = 0

    warehouseGroup.items.forEach((warehouse) => {
      if (warehouse.active) {
        result.push(warehouse.id)
        count++
      }
    })

    if (warehouseGroup.active && count === warehouseGroup.items.length) {
      result.push(warehouseGroup.id)
    }
  })

  depts.forEach((dept) => {
    if (dept.active) result.push(dept.id)

    dept.items.forEach((org) => {
      if (org.active) result.push(org.id)

      org.items.forEach((dealer) => {
        if (dealer.active) result.push(dealer.id)
      })
    })
  })

  return result
}

function getActiveCodes(permissions) {
  return Object.entries(permissions).reduce((result, [key, value]) => {
    value.items.forEach((item) => {
      if (item.active) {
        result.push(`${key}::${item.action}`)
      }
    })
    return result
  }, [])
}
