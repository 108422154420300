import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { Center, Button, Drawer } from 'components/core'
import { initialState, fields, handlers } from 'models/deferRevertDrawer'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ open, value, data, readonly, action }) => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data, message))
  const [disabled, setDisabled] = useState(false)
  action.setDisabled = setDisabled
  const handler = handlers({ app, session, state, setState, data, action })
  const form = fields({ state, setState, data })

  useEffect(() => {
    setState(initialState(value, data, message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      title="defer.title.addRevert"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.transDate}
      {form.productVariantId}
      {form.quantity}
      {form.memo}
      <Center mt={3}>
        <Button
          disabled={disabled}
          show={!readonly}
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          disabled={disabled}
          show={!readonly}
          variant="primary"
          text="btn.save"
          type="submit"
        />
      </Center>
    </Drawer>
  )
}
