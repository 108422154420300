import { uploadFile } from 'utilities/file'
import { request } from 'utilities/request'

export async function updateImages(app, session, state, id) {
  const { imagesToAdd, imagesToDel } = state
  const result = await Promise.all([
    ...imagesToDel.map(async (image) => deleteImage(app, session, id, image)),
    ...imagesToAdd.map(async (image) => addImage(app, session, id, image)),
  ])
  return !result.some((ok) => !ok)
}

async function addImage(app, session, id, file) {
  const variables = { id, filename: file.name, contentType: file.type }
  const query = `
    mutation($id: ID!, $filename: String!, $contentType: String!) {
      addFile(id: $id, filename: $filename, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addFile
  uploadFile({ url, fields, file })
  return ok
}

async function deleteImage(app, session, id, image) {
  const variables = { id, filename: image }
  const query = `
      mutation($id: ID!, $filename: String!) {
        deleteFile(id: $id, filename: $filename) 
      }
    `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
