import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  getSelectOption,
  getDate,
  validateForm,
  showDate,
  renderDefinition,
  renderTextAreaInput,
  renderSelectInput,
  renderTable,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getLocation } from 'actions/location'
import { fetchBalance, fetchBalances } from 'actions/inventory'
import { getAvailableBalance, handleDelete, setBalances } from 'actions/ticket'
import { Select, NumberInput } from 'components/form'
import { filterLocations } from 'utilities/permission'
import { getProductOptions } from 'actions/product'

const moduleName = 'lock'

export const initialState = (value = {}, message) => ({
  isReady: value.isReady || false,
  id: value.id || '',
  locked: value.extra?.locked || false,
  transDate: getDate(value.transDate),
  toLocationName: value.toLocationName || '',
  createdAt: showDate(value.createdAt),
  createdBy: value.createdBy,
  updatedAt: showDate(value.updatedAt),
  updatedBy: value.updatedBy,
  status: value.status || 'ACTIVE',
  hash: value.hash,
  oldTicketItems: value.oldTicketItems || [],
  ticketItems: value.ticketItems || [],
  inputValues: getInitialInput(),
  editValues: {},
  locations: value.locations || [],
  locationOptions: getLocationOptions(value.locations, message),
  products: value.products || [],
  ...initializeState({
    toLocationId: getSelectOption(
      value.locations,
      value.toLocationId,
      'id',
      'name',
    ),
    memo: value.extra?.memo || '',
  }),
})

function getInitialInput() {
  return {
    productVariantId: '',
    productVariantName: '',
    balance: 0,
    quantity: 0,
  }
}

export function getLocationOptions(locations, message) {
  if (!locations) return []
  const depts = []
  const orgs = []
  locations.forEach(({ type, id, name }) => {
    if (type === 'DEPT') depts.push({ label: name, value: id })
    if (type === 'DEALER_ORG') orgs.push({ label: name, value: id })
  })
  return [
    { label: message({ id: 'location.type.DEPT' }), options: depts },
    { label: message({ id: 'location.type.DEALER_ORG' }), options: orgs },
  ]
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  toLocationId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ profile, format, app, session, state, setState }) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    transDate: definition({ id: 'transDate' }),
    toLocation: selectInput({
      id: 'toLocationId',
      valKey: 'toLocationName',
      isClearable: false,
      options: state.locationOptions,
      callback: ({ value }) => onLocationChange(state, app, session, value),
    }),
    memo: textAreaInput({ id: 'memo' }),
    product: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'lock.field.spu',
          renderInput: () => (
            <Select
              isClearable={false}
              options={getProductOptions(state.products)}
              onChange={async ({ value }) => {
                const location = getLocation(
                  state.locations,
                  state.toLocationId.value,
                )
                const balance = await fetchBalance({
                  app,
                  session,
                  locationType:
                    location.type === 'DEPT' ? 'ALL' : location.type,
                  locationId: location.parentId,
                  productVariantId: value,
                  oldTicketItems: state.oldTicketItems,
                  includePending: true,
                  includeUnlockable: false,
                })
                return { balance, quantity: 1 }
                // return { balance, quantity: 1 }
              }}
            />
          ),
          getValue: (row) =>
            getSelectOption(state.products, row.productVariantId, 'id', 'name'),
        },
        {
          id: 'balance',
          label: 'lock.field.balance',
          align: 'right',
          format: ['html'],
          show: profile !== 'view',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          width: '96px',
          align: 'right',
          renderInput: ({ row, index }) => {
            const balance = getAvailableBalance({
              ticketItems: state.ticketItems,
              oldTicketItems: state.oldTicketItems,
              productVariantId: row.productVariantName.value,
              balance: row.balance,
              index,
            })
            const min = Math.min(1, balance)
            const values = index === -1 ? state.inputValues : state.editValues
            const quantity = parseInt(values.quantity)
            return (
              <NumberInput
                min={min}
                max={balance}
                hasError={quantity < min || quantity > balance}
              />
            )
          },
        },
      ],
      showAddInput: profile !== 'view',
      showDeleteIcon: profile !== 'view',
      inputValues: state.inputValues,
      editValues: state.editValues,
      onInputChange: (value) => setState({ ...state, inputValues: value }),
      onEditChange: (value) => {
        setState({ ...state, editValues: value })
      },
      onAdd: ({ row }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const availableBalance = getAvailableBalance({
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
          productVariantId: productVariantName.value,
          balance: row.balance,
        })
        if (quantity > availableBalance) return

        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.push({
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        })
        const inputValues = getInitialInput()
        setState({ ...state, ticketItems, inputValues })
      },
      onEdit: ({ row, index }) => {
        const { productVariantName, quantity, balance } = row
        if (!productVariantName.value) return

        const availableBalance = getAvailableBalance({
          ticketItems: state.ticketItems,
          oldTicketItems: state.oldTicketItems,
          productVariantId: productVariantName.value,
          balance: row.balance,
          index,
        })
        if (quantity > availableBalance) return

        const ticketItems = cloneDeep(state.ticketItems)
        const ticketItem = {
          ...ticketItems[index],
          productVariantId: productVariantName.value,
          productVariantName: productVariantName.label,
          balance: parseInt(balance),
          quantity: parseInt(quantity),
        }
        ticketItems.splice(index, 1, ticketItem)
        setState({ ...state, ticketItems })
        return true
      },
      onDelete: ({ index }) => {
        const ticketItems = cloneDeep(state.ticketItems)
        ticketItems.splice(index, 1)
        setState({ ...state, ticketItems })
      },
    }),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  message,
  history,
  id,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editLock(state, app, session)
      : await addLock(state, app, session)
    if (!ok) return

    if (!id) {
      id = data.addLockTicket
    }

    history.push(`/lock/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmLock({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })

    return true
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('lock', { session, app, id, hash })
    if (!ok) return false

    history.push('/lock')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ state }) {
  const title = { id: 'lock.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const form = fields({ profile: 'view', format: 'print', state })
  const field = Object.values(form).filter(({ id }) => id !== 'ticketItems')
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: form.product },
  ]
  return { title, header, content }
}

async function getData({ app, session, id, profile }) {
  const locationInput = { type: ['DEPT', 'DEALER_ORG'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        parentId
        name
        type
      }
      productVariants {
        id
        name
        sku
      }
      lockTicket(id: $id) {
        toLocationId
        toLocationType
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      lockTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.lockTicket || {}
  const ticketItems = data.lockTicketItems || []
  const oldTicketItems = cloneDeep(ticketItems)

  if (profile === 'edit' && ticketItems.length > 0) {
    const { toLocationId, toLocationType } = ticket
    const location = locations.find((item) => item.id === toLocationId)
    const balances = await fetchBalances({
      app,
      session,
      locationType: toLocationType === 'DEPT' ? 'ALL' : 'DEPT',
      locationId: toLocationType === 'DEPT' ? null : location.parentId,
      productVariantId: ticketItems.map((item) => item.productVariantId),
      oldTicketItems,
      includePending: true,
      includeUnlockable: false,
    })
    setBalances(ticketItems, balances)
  }

  return {
    isReady: profile === 'edit',
    id,
    extra: ticket.extra || {},
    // locked: ticket.extra?.locked,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    transDate: ticket.transDate,
    // memo: ticket.extra?.memo,
    status: ticket.status,
    hash: ticket.hash,
    createdAt: ticket.createdAt,
    createdBy: ticket.createdBy,
    updatedAt: ticket.updatedAt,
    updatedBy: ticket.updatedBy,
    ticketItems,
    oldTicketItems,
    locations,
    products: productVariants,
  }
}

async function addLock(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addLockTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editLock(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editLockTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function confirmLock({ session, app, id, hash }) {
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmLockTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    return item.quantity === newItem.quantity
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    ticketType: 'LOCK',
    toLocationId: state.toLocationId.value,
    extra: {
      memo: state.memo,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function onLocationChange(state, app, session, locationId) {
  const { locations, oldTicketItems } = state
  const ticketItems = [...state.ticketItems]
  if (ticketItems.length === 0) return { inputValues: getInitialInput() }

  const location = getLocation(locations, locationId)
  const productVariantId = ticketItems.map((item) => item.productVariantId)
  const balances = await fetchBalances({
    app,
    session,
    locationType: location.type === 'DEPT' ? 'ALL' : 'DEPT',
    locationId: location.type === 'DEPT' ? null : location.parentId,
    productVariantId,
    oldTicketItems,
    includePending: true,
    includeUnlockable: false,
  })
  setBalances(ticketItems, balances)

  return { ticketItems, inputValues: getInitialInput() }
}
