import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/categoryList'
import { Center, Section, Table, Button } from 'components/core'
import Page from 'components/Page'
import CategoryModal from 'views/CategoryModal'

export default ({ match }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState()
  const [value, setValue] = useState()
  const { handleLoad } = handlers({
    match,
    setState,
    session,
    app,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const action = { handleOpen, handleClose, handleLoad }

  return (
    <Page
      title="category.title"
      navId="categoryList"
      action={
        <Center>
          <Button
            ml={3}
            variant="secondary"
            text="btn.add"
            onClick={handleOpen}
          />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({ setState, session, app, action })}
          rows={state}
        />
      </Section>
      <CategoryModal open={open} value={value} action={action} />
    </Page>
  )
}
