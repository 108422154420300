import React from 'react'
import {
  DateInput,
  NumberInput,
  Select,
  TextArea,
  TextInput,
} from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getDate,
  getSelectOption,
} from 'utilities/form'
import { ALERT_ADD } from 'constants/actionType'
import { request } from 'utilities/request'
import { Definition } from 'components/core'

export const initialState = (value = {}, data) => {
  const { dealers, products } = data
  return {
    hasTicket: value.hasTicket,
    index: value.index,
    id: value.id,
    itemId: value.itemId,
    ticketId: value.extra?.ticketId,
    ticketType: value.ticketType,
    hash: value.hash,
    dealers,
    products,
    ...initializeState({
      transDate: getDate(value.transDate),
      dealerId: getSelectOption(dealers, value.dealerId),
      productVariantId: getSelectOption(products, value.productVariantId),
      quantity: value.quantity || 0,
      price: value.price || 0,
      model: value.extra?.model || '',
      serialNo: value.extra?.serialNo || '',
      serial: value.extra?.serial || '',
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
  quantity: [{ type: 'required', message: 'error.required' }],
  price: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ readonly, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  if (readonly) {
    return {
      model: <Definition label="repair.field.model" value={state.model} />,
      serialNo: (
        <Definition label="repair.field.serialNo" value={state.serialNo} />
      ),
      serial: (
        <Definition label="dispatch.field.serial" value={state.serial} />
      ),
      memo: <Definition label="field.memo" value={state.memo} />,
    }
  }

  if (state.hasTicket) {
    return {
      model: (
        <TextInput
          id="model"
          label="repair.field.model"
          placeholder="repair.field.model"
          value={state.model}
          onChange={onTextChange('model')}
          errMsg={state.__error__.model}
        />
      ),
      serialNo: (
        <TextInput
          id="serialNo"
          label="repair.field.serialNo"
          placeholder="repair.field.serialNo"
          value={state.serialNo}
          onChange={onTextChange('serialNo')}
          errMsg={state.__error__.serialNo}
        />
      ),
      serial: (
        <TextInput
          id="serial"
          label="dispatch.field.serial"
          placeholder="dispatch.field.serial"
          value={state.serial}
          onChange={onTextChange('serial')}
          errMsg={state.__error__.serial}
        />
      ),
      memo: (
        <TextArea
          id="memo"
          label="field.memo"
          value={state.memo}
          onChange={onTextChange('memo')}
        />
      ),
    }
  }

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="field.date"
        min="1970-01-01"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    dealerId: (
      <Select
        id="dealerId"
        label="field.dealer"
        placeholder="field.dealer"
        options={state.dealers}
        value={state.dealerId}
        onChange={onSelectChange('dealerId')}
        errMsg={state.__error__.dealerId}
      />
    ),
    productVariantId: (
      <Select
        id="productVariantId"
        label="field.productName"
        placeholder="product.field.spu"
        isClearable={false}
        options={state.products}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="1"
        label="field.quantity"
        placeholder="field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    price: (
      <NumberInput
        id="price"
        type="decimal"
        min="0"
        label="field.price"
        placeholder="field.price"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.quantity}
      />
    ),
    model: (
      <TextInput
        id="model"
        label="repair.field.model"
        placeholder="repair.field.model"
        value={state.model}
        onChange={onTextChange('model')}
        errMsg={state.__error__.model}
      />
    ),
    serialNo: (
      <TextInput
        id="serialNo"
        label="repair.field.serialNo"
        placeholder="repair.field.serialNo"
        value={state.serialNo}
        onChange={onTextChange('serialNo')}
        errMsg={state.__error__.serialNo}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, id, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    const { hasTicket } = state
    if (!hasTicket && !validateForm({ state, setState, validation })) return

    const [ok] = hasTicket
      ? await editTicket({ app, session, state })
      : state.id
      ? await editProduct({ app, session, state })
      : await addProduct({ app, session, state, id })

    if (ok) {
      action.handleLoad()
      action.handleClose()
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'save.success' },
      })
    }
  },
})

async function addProduct({ session, app, state, id }) {
  const input = getSubmitInput(state)
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: CustomerProductInput!) {
      addCustomerProduct(id: $id, product: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editProduct({ session, app, state }) {
  const input = getSubmitInput(state)
  const variables = { productId: state.id, input }
  const query = `
    mutation($productId: ID!, $input: CustomerProductInput!) {
      editCustomerProduct(productId: $productId, product: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editTicket({ session, app, state }) {
  const { itemId, model, serialNo, serial, memo } = state
  const input = { extra: { model, serialNo, serial, memo } }
  const variables = { ticketItemId: itemId, input }
  const query = `
    mutation($ticketItemId: ID!, $input: CustomerProductInput!) {
      editTicketProduct(ticketItemId: $ticketItemId, product: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { productVariantId, dealerId, memo, ticketId } = state
  return {
    transDate: state.transDate,
    productVariantId: productVariantId.value,
    productVariantName: productVariantId.label,
    quantity: parseInt(state.quantity),
    price: parseFloat(state.price),
    extra: {
      ticketId,
      dealerId: dealerId.value,
      dealerName: dealerId.label,
      model: state.model,
      serialNo: state.serialNo,
      serial: state.serial,
      memo,
    },
  }
}
