import React from 'react'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getSelectOption,
  getSelectOptions,
} from 'utilities/form'
import { getProductOption } from 'actions/product'
import { Select, NumberInput } from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = (value = {}, data) => {
  return {
    index: value.index,
    ticketId: value.ticketId,
    hash: value.hash,
    id: value.id || '',
    productOptions: getSelectOptions(data.products),
    locations: data.locations,
    ...initializeState({
      productVariantId: getProductOption(data.products, value.productVariantId),
      toLocationId: getSelectOption(data.locations, value.toLocationId),
      quantity: value.quantity || 1,
    }),
  }
}

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
  toLocationId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ data, state, setState, message }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    productVariant: (
      <Select
        label="product.field.sku"
        isClearable={false}
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    location: (
      <Select
        label="refund.field.location"
        isClearable={false}
        options={state.locations}
        value={state.toLocationId}
        onChange={onSelectChange('toLocationId')}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="1"
        label="refund.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, action }) => ({
  handleConfirm: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return false

    const [ok] = state.id
      ? await editRefund({ app, session, state })
      : await addRefund({ app, session, state })
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
    await action.handleSubmit()
    return true
  },
})

async function addRefund({ app, session, state }) {
  const { ticketId, hash } = state
  const variables = { ticketId, hash, input: getSubmitInput(state) }
  const query = `
    mutation($ticketId: ID!, $hash: String!, $input: TicketItemInput!) {
      addRefundAdjust(ticketId: $ticketId, hash: $hash, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editRefund({ app, session, state }) {
  const { ticketId, hash } = state
  const variables = { ticketId, hash, input: getSubmitInput(state) }
  const query = `
    mutation($ticketId: ID!, $hash: String!, $input: TicketItemInput!) {
      editRefundAdjust(ticketId: $ticketId, hash: $hash, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { productVariantId } = state
  return {
    id: state.id,
    productVariantId: productVariantId.value,
    toLocationId: state.toLocationId?.value,
    quantity: parseInt(state.quantity),
  }
}
