export default {
  'vendor.best': '百事達物流',
  'error.best.invalidLocation': '[百事達] 選取倉別有誤！',
  'error.best.notEnabled': '請先儲存設定',
  'best.title.edit': '百事達物流設定',
  'best.title.syncBestOrders': '上傳出貨單',
  'best.title.uploadBestOrders': '上傳出貨單',
  'best.title.syncBestRefunds': '上傳退貨單',
  'best.title.uploadBestRefunds': '上傳退貨單',
  'best.section.basic': '基礎信息',
  'best.section.autoSync': '自動同步',
  'best.section.manualSync': '手動同步',
  'best.btn.syncDispatch': '上傳出貨單 EXCEL',
  'best.btn.uploadDispatch': '上傳出貨單 PDF',
  'best.btn.syncRefund': '上傳退貨單 EXCEL',
  'best.btn.uploadRefund': '上傳退貨單 PDF',
  'best.field.username': 'FTP帳號',
  'best.field.password': 'FTP密碼',
  'best.field.syncTime': '定時同步時間',
  'best.field.syncDate': '同步日期',
  'best.field.filename': '檔案名',
  'best.field.message': '錯誤信息',
  'best.message.syncSuccess': '同步成功',
}
