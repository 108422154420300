import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import {
  Grid,
  Button,
  Link,
  Section,
  Center,
  Toolbar,
  Modal,
} from 'components/core'
import Page from 'components/Page'
import { initialState, fields, handlers } from 'models/processConfigPage'
import ProcessConfigModal from 'views/ProcessConfigModal'
import { MdAdd, MdArrowBack, MdSort } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import SortableTable from 'components/core/SortableTable'

export default ({ match, profile }) => {
  const { id } = match.params
  const { formatMessage: message } = useIntl()
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState()
  const [sortOpen, setSortOpen] = useState(false)
  const [sortValue, setSortValue] = useState([])
  const tableRef = useRef()

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleSortOpen = () => {
    const rows = state.processItems.map((item, index) => ({
      index,
      processName: item.processName,
    }))
    setSortValue(rows)
    setSortOpen(true)
  }

  const handleSortClose = () => {
    setSortOpen(false)
    setSortValue([])
  }

  const form = fields({
    app,
    session,
    state,
    setState,
    message,
    id,
    action: { handleOpen },
  })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    history,
    id,
  })

  useEffect(() => {
    handler.handleLoad({ setState, app, session })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const action = {
    handleOpen,
    handleClose,
    handleLoad: handler.handleLoad,
    addConfigItem: handler.addConfigItem,
  }

  return (
    <>
      <Page
        title={`processConfig.title.${profile}`}
        navId="processConfigList"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/processConfig"
            />
            <Button type="submit" variant="primary" ml={3} text="btn.save" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Section mb={[3, 0]}>
          <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
            {form.name}
          </Grid>
        </Section>
        <Section mt={5} title="processConfig.section.product">
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
            <Button
              variant="default"
              icon={<MdSort />}
              text="btn.sort"
              onClick={() => handleSortOpen()}
            />
          </Toolbar>
          {form.processItems}
        </Section>
        <Section mt={5} title="processConfig.section.image">
          {form.images}
        </Section>
      </Page>
      <ProcessConfigModal open={open} value={value} action={action} />
      <Modal
        title="processConfig.title.sort"
        open={sortOpen}
        onClose={handleSortClose}
        width={450}
        maxHeight="90%"
      >
        <SortableTable
          ref={tableRef}
          columns={[
            { id: 'processName', label: 'processConfig.field.processName' },
          ]}
          primaryKey="index"
          rows={sortValue}
        />
        <Center mt={4}>
          <Button
            mr={2}
            variant="secondary"
            onClick={handleSortClose}
            text="btn.cancel"
          />
          <Button
            variant="primary"
            onClick={handler.sortProducts(tableRef, handleSortClose)}
            text="btn.confirm"
          />
        </Center>
      </Modal>
    </>
  )
}
