import React from 'react'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getDate,
  getSelectOption,
} from 'utilities/form'
import {
  Select,
  NumberInput,
  TextArea,
  Switch,
  DateInput,
} from 'components/form'
import { Definition } from 'components/core'

export const initialState = (value = {}, data) => ({
  id: value.id || '',
  index: value.index,
  ...initializeState({
    transDate: getDate(value.transDate),
    productVariantId: getSelectOption(
      data.productOptions,
      value.productVariantId,
    ),
    isContract: value.extra?.isContract || false,
    isDemo: value.extra?.isDemo || false,
    isGift: value.isGift || false,
    quantity: value.quantity || 0,
    price: value.price || 0,
    unitPrice: value.extra?.unitPrice || 0,
    memo: value.extra?.memo || '',
  }),
})

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
  quantity: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="defer.field.transDate"
        value={state.transDate}
        role="lockDeferTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    productVariant: (
      <Select
        label="product.field.spu"
        isClearable={false}
        options={data.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    isDemo: (
      <Definition
        label="defer.field.isDemo"
        direction="row"
        justifyContent="space-between"
      >
        <Switch
          checked={state.isDemo}
          onClick={() => {
            setState({ ...state, isDemo: !state.isDemo })
          }}
        />
      </Definition>
    ),
    isGift: (
      <Definition
        label="defer.field.isGift"
        direction="row"
        justifyContent="space-between"
      >
        <Switch
          checked={state.isGift}
          onClick={() => {
            setState({ ...state, isGift: !state.isGift })
          }}
        />
      </Definition>
    ),
    isContract: (
      <Definition
        label="defer.field.isContract"
        direction="row"
        justifyContent="space-between"
      >
        <Switch
          checked={state.isContract}
          onClick={() => {
            setState({ ...state, isContract: !state.isContract })
          }}
        />
      </Definition>
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        label="field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity', (value) => {
          const quantity = parseInt(value)
          const unitPrice = parseFloat(state.unitPrice)
          const price = Math.round(quantity * unitPrice * 100) / 100
          return { price }
        })}
        errMsg={state.__error__.quantity}
      />
    ),
    unitPrice: (
      <NumberInput
        id="unitPrice"
        type="decimal"
        label="field.unitPrice"
        value={state.unitPrice}
        onChange={onTextChange('unitPrice', (value) => {
          const quantity = parseInt(state.quantity)
          const unitPrice = parseFloat(value)
          const price = Math.round(quantity * unitPrice * 100) / 100
          return { price }
        })}
        errMsg={state.__error__.unitPrice}
      />
    ),
    price: (
      <NumberInput
        id="price"
        type="decimal"
        label="field.price"
        value={state.price}
        onChange={onTextChange('price', (value) => {
          const quantity = parseInt(state.quantity)
          if (quantity === 0) return {}

          const price = parseFloat(value)
          const unitPrice = Math.round((price / quantity) * 100) / 100
          return { unitPrice }
        })}
        errMsg={state.__error__.price}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
  }
}

export const handlers = ({ state, setState, data, action }) => ({
  handleSubmit: (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { index, id, transDate, productVariantId } = state
    action.addItem({
      index,
      id,
      transDate,
      productVariantId: productVariantId.value,
      productVariantName: productVariantId.label,
      quantity: parseInt(state.quantity),
      price: parseFloat(state.price),
      isGift: state.isGift,
      extra: {
        unitPrice: parseFloat(state.unitPrice),
        memo: state.memo,
        isDemo: state.isDemo,
        isContract: state.isContract,
      },
    })
    action.handleClose()
  },
})
