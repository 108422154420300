import React from 'react'
import { Select, Switch, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  getSelectOptions,
  getSelectOption,
  handleSelectChange,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Definition } from 'components/core'

export const initialState = (value = {}, data = {}) => {
  const warehouseGroups = getSelectOptions(data.warehouseGroups)

  return {
    warehouseGroups,
    ...initializeState({
      id: value.id || '',
      name: value.name || '',
      parentId: getSelectOption(warehouseGroups, value.parentId),
      isStock: value.isStock,
      isLockable: value.extra?.isLockable,
      status: value.status === 'ACTIVE',
    }),
  }
}

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
  parentId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    name: (
      <TextInput
        id="name"
        label="warehouse.field.name"
        placeholder="warehouse.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    parentId: (
      <Select
        label="warehouseGroup.field.name"
        isClearable={false}
        options={state.warehouseGroups}
        value={state.parentId}
        onChange={onSelectChange('parentId')}
        errMsg={state.__error__.parentId}
      />
    ),
    isStock: (
      <Definition
        direction="row"
        label="warehouse.field.isStock"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isStock}
          onClick={() => {
            const isStock = !state.isStock
            const isLockable = !isStock ? false : state.isLockable
            setState({ ...state, isLockable, isStock })
          }}
        />
      </Definition>
    ),
    isLockable: (
      <Definition
        direction="row"
        label="warehouse.field.isLockable"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isLockable}
          onClick={() => {
            setState({ ...state, isLockable: !state.isLockable })
          }}
        />
      </Definition>
    ),
    status: (
      <Definition
        direction="row"
        label="warehouse.field.status"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.status}
          onClick={() => {
            setState({ ...state, status: !state.status })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editWarehouse(state, app, session)
      : await addWarehouse(state, app, session)

    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  },
})

async function addWarehouse(value, app, session) {
  const { name, parentId, isStock } = value
  const variables = {
    input: {
      parentId: parentId.value,
      name,
      isStock,
      type: 'WAREHOUSE',
      status: value.status ? 'ACTIVE' : 'INACTIVE',
    },
  }
  const query = `
    mutation($input: LocationInput!) {
      addWarehouse(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editWarehouse(value, app, session) {
  const { id, name, parentId, isStock, isLockable } = value
  const status = value.status ? 'ACTIVE' : 'INACTIVE'
  const variables = {
    id,
    input: {
      parentId: parentId.value,
      name,
      isStock,
      extra: { isLockable },
      status,
    },
  }
  const query = `
    mutation($id: ID!, $input: LocationInput!) {
      editWarehouse(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
