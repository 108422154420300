import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/processDestDrawer'
import { Center, Button, Drawer } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, data, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data))
  const form = fields({ app, session, data, state, setState })
  const handler = handlers({ state, setState, app, session, data, action })

  useEffect(() => {
    handler.handleLoad(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const { index = -1, isFrom = true } = value || {}
  let title = index === -1 ? 'add' : 'edit'
  title += data.isPack ? 'Pack' : 'Unpack'
  title += isFrom ? 'From' : 'To'
  return (
    <Drawer
      title={`process.title.${title}`}
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.productVariantId}
      {form.fromLocationId}
      {form.toLocationId}
      {form.ticketNo}
      {form.batchNo}
      {form.expireAt}
      {form.balance}
      {form.unit}
      {form.quantity}
      <Center mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          disabled={!handler.enableSubmit()}
          type="submit"
          variant="primary"
          text="btn.confirm"
        />
      </Center>
    </Drawer>
  )
}
