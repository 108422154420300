import React, { useState, useContext, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/repairTypeList'
import { Button, Section, Table } from 'components/core'
import Page from 'components/Page'
import RepairTypeModal from 'views/RepairTypeModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [remarks, setRemarks] = useState()
  const [state, setState] = useState()
  const [value, setValue] = useState()
  const { handleLoad } = handlers({ session, app, setState, setRemarks })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const action = { handleOpen, handleClose, handleLoad }

  return (
    <Page
      title="repair.title.typeList"
      navId="repairTypeList"
      action={
        <Button
          variant="secondary"
          text="btn.add"
          onClick={() => handleOpen({})}
        />
      }
    >
      <Section>
        <Table columns={columns({ session, app, action })} rows={state} />
      </Section>
      <RepairTypeModal
        open={open}
        value={value}
        data={{ remarks }}
        action={action}
      />
    </Page>
  )
}
