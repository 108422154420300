import React from 'react'
import { Button } from 'components/core'
import { MdDelete } from 'react-icons/md'
import { Box } from 'reflexbox'
import { getMessage } from 'utilities/app'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'

// export const parseColumns = ({ session, app, state, setState }) => [
//   {
//     id: 'syncDate',
//     label: 'carrefour.field.syncDate',
//   },
//   {
//     id: 'filename',
//     label: 'carrefour.field.filename',
//   },
//   {
//     id: 'message',
//     label: 'carrefour.field.message',
//   },
//   {
//     id: 'actions',
//     align: 'right',
//     render: ({ row, index }) => (
//       <Button
//         variant="icon"
//         icon={<MdDelete />}
//         onClick={async () => {
//           const [ok] = await handleDelete({ session, app, id: row.id })
//           if (!ok) return

//           const parseErrors = [...state.parseErrors]
//           parseErrors.splice(index, 1)
//           setState({ ...state, parseErrors })
//         }}
//       />
//     ),
//   },
// ]

export const importColumns = ({ session, app, state, setState, message }) => [
  {
    id: 'syncDate',
    label: 'carrefour.field.syncDate',
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'key',
    label: 'carrefour.field.errorKey',
  },
  {
    id: 'message',
    label: 'carrefour.field.message',
    renderHtml: ({ row }) => {
      if (!Array.isArray(row.content)) {
        return getMessage(message, row.content.message)
      }
      return (
        <>
          {row.content.map((item, index) => (
            <Box key={index}>{getMessage(message, item.message)}</Box>
          ))}
        </>
      )
    },
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row, index }) => (
      <Button
        variant="icon"
        icon={<MdDelete />}
        onClick={async () => {
          const { transDate, key } = row
          const [ok] = await handleDelete({ session, app, transDate, key })
          if (!ok) return

          const importErrors = [...state]
          importErrors.splice(index, 1)
          setState(importErrors)
        }}
      />
    ),
  },
]

export const handlers = ({ session, app, setState, setPagination }) => ({
  handleLoad: async ({ pagination } = {}) => {
    const resp = await getData({ session, app, pagination })
    setPagination(resp.pagination)
    setState(resp.data)
  },
})

async function getData({ session, app, pagination = {} }) {
  const input = { orderBy: [{ key: 'createdAt', sort: 'DESC' }] }
  input.limit = getLimit(pagination)
  const variables = { input }
  const query = `
    query($input: CarrefourErrorInput) {
      carrefourSyncErrorCount
      carrefourSyncErrors(input: $input) {
        transDate
        key
        type
        content
        createdAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.carrefourSyncErrors,
    pagination: getPagination(page, countPerPage, data.carrefourSyncErrorCount),
  }
}

async function handleDelete({ session, app, transDate, key }) {
  const variables = { transDate, key }
  const query = `
    mutation($transDate: String!, $key: String) {
      deleteCarrefourErrors(transDate: $transDate, key: $key)
    }
  `
  return request({ query, variables }, { session, app })
}
