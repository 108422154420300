export const hasAccess = (staff, action) => {
  const { staffType, permissions = [] } = staff
  if (staffType === 'OWNER') return true

  if (Array.isArray(action)) {
    action.forEach((item) => {
      if (permissions.includes(item)) return true
    })
    return false
  }

  return permissions.includes(action)
}

export const filterLocations = (staff, locations) => {
  const { staffType } = staff
  if (staffType === 'OWNER') return locations

  const allowdLocations = staff.locations
  return locations.filter(({ id, type }) => {
    if (type === 'COMPANY') return true
    if (type === 'STORAGE') return true
    return allowdLocations.includes(id)
  })
}
