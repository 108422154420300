import React from 'react'
import {
  Address,
  DateInput,
  NumberInput,
  TextArea,
  TextInput,
} from 'components/form'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'

export const initialState = (value = {}) => {
  return {
    id: value.id,
    index: value.index,
    ...initializeState({
      transDate: value.transDate || '',
      receiptNo: value.extra?.receiptNo || '',
      receiptAddress: value.extra?.receiptAddress || '',
      price: value.extra?.price || 0,
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="repair.field.transDate"
        value={state.transDate}
        role="lockDeferTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    receiptNo: (
      <TextInput
        id="receiptNo"
        label="repair.field.receiptNo"
        value={state.receiptNo}
        onChange={onTextChange('receiptNo')}
        errMsg={state.__error__.receiptNo}
      />
    ),
    receiptAddress: (
      <Address
        id="receiptAddress"
        label="repair.field.receiptAddress"
        placeholder="repair.field.receiptAddress"
        value={state.receiptAddress}
        onChange={onTextChange('receiptAddress')}
        errMsg={state.__error__.receiptAddress}
      />
    ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="repair.field.price"
        placeholder="repair.field.price"
        autoComplete="off"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="repair.field.memo"
        placeholder="repair.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
        errMsg={state.__error__.memo}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await addReceipt({ app, session, state, data })
    if (!ok) return

    action.handleLoad()
    action.handleClose()
  },
})

async function addReceipt({ session, app, state, data }) {
  const receipt = {
    transDate: state.transDate,
    receiptNo: state.receiptNo,
    receiptAddress: state.receiptAddress,
    price: parseFloat(state.price),
    memo: state.memo,
  }
  const receipts = { itemsToAdd: [receipt] }
  const input = { hash: data.hash, extra: { receipts } }
  const variables = { id: data.id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editRepairTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
