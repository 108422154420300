import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Button, Definition, Table } from 'components/core'
import { DateInput, NumberInput, Select, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
} from 'utilities/form'
import { getProductOption, getProductOptions } from 'actions/product'
import { MdDelete } from 'react-icons/md'
import { fetchBalance } from 'actions/inventory'
import { checkBatchItemQuantity, getAvailableBalance } from 'actions/ticket'

export const initialState = (value = {}, data) => ({
  id: value.id || '',
  index: value.index,
  productVariantName: value.productVariantName || '',
  balance: value.balance || 0,
  warehouses: data.warehouses,
  products: data.products,
  batchItems: value.extra?.batchItems || [],
  ...initializeState({
    productVariantId: getProductOption(data.products, value.productVariantId),
    warehouseId: getSelectOption(data.warehouses, value.toLocationId),
    purchasedQuantity: value.extra?.purchasedQuantity || 0,
    arrivedQuantity: value.extra?.arrivedQuantity || 0,
  }),
})

// export function getQuantity({ status, quantity }) {
//   console.log(status, quantity)
//   if (status === 'PENDING') return 0
//   return quantity || 0
// }

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
  warehouseId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, data, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)
  const onBatchItemChange = (index, key) => (value) => {
    const batchItems = [...state.batchItems]
    const batchItem = batchItems[index]
    batchItem[key] = value
    batchItems.splice(index, 1, batchItem)
    setState({ ...state, batchItems })
  }
  return {
    productVariantId: (
      <Select
        id="productVariantId"
        label="product.field.spu"
        placeholder="product.field.spu"
        isClearable={false}
        options={getProductOptions(
          data.products,
          data.ticketItems,
          state.productVariantId?.value,
        )}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId', async ({ value }) => {
          const balance = await fetchBalance({
            app,
            session,
            locationType: 'WAREHOUSE',
            locationId: data.fromLocationId,
            productVariantId: value,
            oldTicketItems: data.oldTicketItems,
          })
          return { balance }
        })}
        errMsg={state.__error__.productVariantId}
      />
    ),
    warehouseId: (
      <Select
        id="warehouseId"
        label="transferOut.field.warehouseId"
        placeholder="transferOut.field.warehouseId"
        isClearable={false}
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    balance: (
      <Definition
        direction="row"
        label="inventory.field.balance"
        value={state.balance}
      />
    ),
    purchasedQuantity: (
      <NumberInput
        id="purchasedQuantity"
        min="0"
        max={getAvailableBalance({
          ticketItems: data.ticketItems,
          oldTicketItems: data.oldTicketItems,
          productVariantId: state.productVariantId?.value,
          balance: state.balance,
          index: state.index,
        })}
        label="transferOut.field.purchasedQuantity"
        placeholder="transferOut.field.purchasedQuantity"
        value={state.purchasedQuantity}
        onChange={onTextChange('purchasedQuantity')}
        errMsg={state.__error__.purchasedQuantity}
      />
    ),
    quantity: (
      <NumberInput
        id="arrivedQuantity"
        min="0"
        max={getAvailableBalance({
          ticketItems: data.ticketItems,
          oldTicketItems: data.oldTicketItems,
          productVariantId: state.productVariantId?.value,
          balance: state.balance,
          index: state.index,
        })}
        label="transferOut.field.quantity"
        placeholder="transferOut.field.quantity"
        value={state.arrivedQuantity}
        onChange={onTextChange('arrivedQuantity')}
        errMsg={state.__error__.arrivedQuantity}
      />
    ),
    batchItems: (
      <Table
        columns={[
          {
            id: 'batchNo',
            label: 'transferOut.field.batchNo',
            render: ({ row, index }) => (
              <TextInput
                fieldProps={{ m: 0 }}
                value={row.batchNo || ''}
                onChange={onBatchItemChange(index, 'batchNo')}
              />
            ),
          },
          {
            id: 'expireAt',
            label: 'transferOut.field.expireAt',
            render: ({ row, index }) => (
              <DateInput
                fieldProps={{ m: 0 }}
                value={row.expireAt}
                onChange={onBatchItemChange(index, 'expireAt')}
              />
            ),
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            render: ({ row, index }) => (
              <NumberInput
                fieldProps={{ m: 0 }}
                min="1"
                value={row.quantity}
                onChange={onBatchItemChange(index, 'quantity')}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const batchItems = [...state.batchItems]
                  batchItems.splice(index, 1)
                  setState({ ...state, batchItems })
                }}
              />
            ),
          },
        ]}
        rows={state.batchItems}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, data, action }) => ({
  handleLoad: async (value) => {
    if (!value) return
    setState(initialState(value, data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { productVariantId, warehouseId } = state
    action.addItem({
      index: state.index,
      id: state.id,
      productVariantId: productVariantId.value,
      productVariantName: productVariantId.label,
      toLocationId: warehouseId.value,
      toLocationName: warehouseId.label,
      balance: state.balance,
      // quantity: parseInt(state.quantity),
      extra: {
        purchasedQuantity: state.purchasedQuantity,
        arrivedQuantity: state.arrivedQuantity,
        batchItems: state.batchItems,
      },
    })
    action.handleClose()
  },
  addBatch: () => {
    const batchItems = cloneDeep(state.batchItems)
    batchItems.push({ batchNo: '', expireAt: '', quantity: 1 })
    setState({ ...state, batchItems })
  },
  enableSubmit: () => {
    const { purchasedQuantity, batchItems } = state
    const quantity = state.quantity || purchasedQuantity
    const validBatchItems = !checkBatchItemQuantity(quantity, batchItems)
    const availableBalance = getAvailableBalance({
      ticketItems: data.ticketItems,
      oldTicketItems: data.oldTicketItems,
      productVariantId: state.productVariantId?.value,
      balance: state.balance,
      index: state.index,
    })
    return quantity > 0 && availableBalance >= quantity && validBatchItems
  },
})
