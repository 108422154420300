import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/customerAddressModal'
import { Center, Modal, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value))
  const form = fields({ state, setState })
  const handler = handlers({ app, session, state, setState, action })

  useEffect(() => {
    setState(initialState(value))
  }, [value])

  return (
    <Modal
      title="customer.title.addAddress"
      open={open}
      onClose={action.handleClose}
      width={300}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.address}
      <Center justifyContent="flex-end" mt={3}>
        <Button
          mr={2}
          variant="secondary"
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button type="submit" variant="primary" text="btn.submit" />
      </Center>
    </Modal>
  )
}
