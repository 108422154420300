import React from 'react'
import { Flex } from 'reflexbox'
import { Button, Text } from 'components/core'
import { FormField } from 'components/form'
import { useDropzone } from 'react-dropzone'
import { MdFileUpload, MdClose } from 'react-icons/md'

export default ({
  id,
  label,
  errMsg,
  fieldProps,
  value,
  accept,
  onUpload,
  onDelete,
  onError,
  maxSize = 1024000,
}) => {
  const hasError = !!errMsg
  const isFile = value instanceof File
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      await onUpload(acceptedFiles[0])
    },
    onDropRejected: (files) => {
      if (onError) {
        const errMsg = files.map(({ file, errors }) => {
          console.log(errors)
          const { code } = errors[0]
          const values = {
            filename: file.name,
            maxSize: Math.round(maxSize / 1000000),
          }
          return { id: `error.${code}`, values }
        })
        onError(errMsg)
      }
    },
    accept,
    maxSize,
    multiple: false,
    noDrag: true,
  })

  return (
    <FormField id={id} label={label} errMsg={errMsg} {...fieldProps}>
      <Flex
        justifyContent="space-between"
        minHeight="38px"
        py={2}
        px={2}
        m={0}
        fontSize={1}
        color="dark.2"
        sx={{
          boxSizing: 'border-box',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: hasError ? 'error.1' : 'light.3',
          borderRadius: '4px',
          transition: 'border-color 0.2s linear',
          willChange: 'border-color',
          '&:focus': {
            outline: 'none',
            borderColor: hasError ? 'error.1' : 'accent.1',
          },
        }}
      >
        <Text
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {isFile ? value.name : value}
        </Text>
        <Flex flexShrink={0}>
          <Flex
            mr={2}
            variant="icon"
            alignItems="center"
            fontSize={3}
            color="dark.1"
            sx={{
              cursor: 'pointer',
              outline: 'none',
              '&:hover': {
                color: `secondary.1`,
              },
            }}
            {...getRootProps()}
          >
            <MdFileUpload />
            <input {...getInputProps()} />
          </Flex>
          <Button
            disabled={!value}
            variant="icon"
            icon={<MdClose />}
            onClick={() => {
              onDelete(value)
            }}
          />
        </Flex>
      </Flex>
    </FormField>
  )
}
