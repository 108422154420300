import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { Link } from 'components/core'

export const columns = [
  {
    id: 'ticketId',
    label: `field.ticketNo`,
    render: ({ row: { ticketId } }) => (
      <Link
        variant="primaryLink"
        href={`${process.env.REACT_APP_STOCK_URL}/refund/${ticketId}/view`}
        target="_blank"
      >
        {ticketId}
      </Link>
    ),
  },
  {
    id: 'transDate',
    label: `refund.field.transDate`,
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'quantity',
    label: 'refund.field.quantity',
    align: 'right',
  },
]

export const handlers = ({ setState, session, app, value, setPagination }) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    if (!value.productVariantId) return

    const resp = await getData({ session, app, value, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, value, pagination }) {
  const variables = { input: getDataInput({ pagination, value }) }
  const query = `
    query($input: RefundReportInput) {
      refundReportItemCount(input: $input)
      refundReportItems(input: $input) {
        ticketId
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.refundReportItems,
    pagination: getPagination(page, countPerPage, data.refundReportItemCount),
  }
}

function getDataInput({ pagination, value }) {
  const input = {
    productVariantId: value.productVariantId,
    locationId: value.locationId,
  }
  if (value.transDate) input.transDate = value.transDate
  if (value.refundType) input.refundType = value.refundType
  if (pagination) input.limit = getLimit(pagination)
  return input
}
