import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { columns, filters, handlers } from 'models/inventoryList'
import { createExcel } from 'utilities/excel'
import { Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import InventoryDrawer from 'views/InventoryDrawer'
import { initialPage } from 'utilities/pagination'
import { MdFileUpload } from 'react-icons/md'

export default ({ profile }) => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState()
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const { handleInit, handleLoad, handleExport } = handlers({
    profile,
    session,
    app,
    setData,
    setState,
    setFilterValues,
    setPagination,
    message,
  })

  useEffect(() => {
    handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <Page
      title={`inventory.title.${profile}List`}
      navId={`inventory${capitalize(profile)}List`}
      action={
        <Link
          mr={3}
          icon={<MdFileUpload />}
          text="btn.export"
          onClick={() => handleExport({ filterValues, createExcel })}
        />
      }
    >
      <Section>
        <Table
          columns={columns({ profile, action: { handleOpen } })}
          rows={state}
          filters={filters({ profile, data })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <InventoryDrawer value={value} open={open} action={{ handleClose }} />
    </Page>
  )
}

function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
