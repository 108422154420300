import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { getSelectOptions, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { StatusChip, Link, LinkButton, Center } from 'components/core'
import { MdEdit, MdLock, MdPrint } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { filterLocations } from 'utilities/permission'
import { FILTER_SET } from 'constants/actionType'
import { getData as getPageData, getPrintData } from 'models/sellPage'
import { Flex } from 'reflexbox'
import { printHtml } from 'utilities/print'

export const filters = ({ data }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'sell.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    input: textFilter,
  },
  {
    id: 'parentId',
    label: 'field.parentId',
    input: textFilter,
  },
  {
    id: 'org',
    label: 'field.org',
    input: (props) =>
      selectFilter({
        ...props,
        options: data.orgs,
      }),
  },
  {
    id: 'fromLocation',
    label: 'field.dealer',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.dealers,
      }),
  },
  {
    id: 'recipientName',
    label: 'sell.field.recipient',
    input: textFilter,
  },
  {
    id: 'recipientContact',
    label: 'sell.field.recipientContact',
    input: textFilter,
  },
  {
    id: 'sales',
    label: 'sell.field.sales',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.sales),
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'sell.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/sell/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'parentId',
    label: 'field.parentId',
    noWrap: true,
    format: ['excel'],
  },
  {
    id: 'transDate',
    label: 'sell.field.transDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'ticketNo',
    label: 'sell.field.ticketNo',
    format: ['html', 'excel'],
  },
  {
    id: 'fromLocationName',
    label: 'sell.field.dealer',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'product.field.spu',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'sell.field.quantity',
    format: ['excel'],
  },
  {
    id: 'isGift',
    label: 'sell.field.isGift',
    format: ['excel'],
    render: ({ row }) => (row.isGift ? 'Y' : 'N'),
  },
  {
    id: 'billing',
    label: 'sell.field.billing',
    format: ['excel'],
    render: ({ row }) => row.extra?.billingName,
  },
  {
    id: 'sales',
    label: 'sell.field.sales',
    format: ['excel'],
    render: ({ row }) => row.extra?.salesName?.join(', '),
  },
  {
    id: 'backup',
    label: 'sell.field.backup',
    format: ['excel'],
    render: ({ row }) => row.extra?.backupName?.join(', '),
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'itemMemo',
    label: 'sell.field.productMemo',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.memo,
  },
  {
    id: 'recipient',
    label: 'sell.field.recipient',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientName,
  },
  {
    id: 'dealerCode',
    label: 'sell.field.dealerCode',
    format: ['excel'],
    render: ({ row }) => row.extra?.dealerCode,
  },
  {
    id: 'recipientContact',
    label: 'sell.field.recipientContact',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientContact,
  },
  {
    id: 'unitPrice',
    label: 'sell.field.unitPrice',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.unitPrice,
  },
  {
    id: 'price',
    label: 'sell.field.price',
    format: ['excel'],
    render: ({ row }) => row.price,
  },
  {
    id: 'locked',
    label: 'field.locked',
    format: ['excel'],
    render: ({ row }) => (row.extra?.locked ? 'Y' : 'N'),
  },
  {
    id: 'status',
    label: 'sell.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    render: ({ row }) => (
      <Center justifyContent="flex-end">
        <LinkButton
          mr={1}
          variant="icon"
          icon={<MdPrint />}
          onClick={() => handlePrint({ app, session, id: row.id, message })}
        />
        <LinkButton
          mr={1}
          disabled={
            row.extra.locked || row.status === 'INACTIVE' || row.parentId
          }
          variant="icon"
          icon={<MdEdit />}
          href={`/sell/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'sell',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('sell', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </Center>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setOrgs,
  setDealers,
  setSales,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'sellList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    setOrgs(resp.orgs)
    setDealers(resp.dealers)
    setSales(resp.sales)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'sell.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    staffInput: { type: ['SALES'] },
    locationInput: { type: ['DEALER', 'DEALER_ORG'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      staffs(input: $staffInput) {
        id
        name
      }
      sellTicketCount(input: $input)
      sellTickets(input: $input) {
        id
        parentId
        ticketNo
        fromLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const orgs = []
  const dealers = []
  const locations = filterLocations(app.state.staff, data.locations)
  locations.forEach((item) => {
    const result = { value: item.id, label: item.name }
    switch (item.type) {
      case 'DEALER_ORG':
        orgs.push(result)
        break
      default:
        dealers.push(result)
    }
  })
  const { page, countPerPage } = pagination
  return {
    data: data.sellTickets,
    pagination: getPagination(page, countPerPage, data.sellTicketCount),
    orgs,
    dealers,
    sales: data.staffs,
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      sellTickets(input: $input) {
        id
        parentId
        ticketNo
        fromLocationName
        productVariantName
        sku
        quantity
        price
        transDate
        isGift
        extra
        itemExtra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.sellTickets
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const ticketNo = filterValues.find(({ id }) => id === 'ticketNo')
  const parentId = filterValues.find(({ id }) => id === 'parentId')
  const org = filterValues.find(({ id }) => id === 'org')
  const fromLocation = filterValues.find(({ id }) => id === 'fromLocation')
  const recipientName = filterValues.find(({ id }) => id === 'recipientName')
  const recipientContact = filterValues.find(
    ({ id }) => id === 'recipientContact',
  )
  const sales = filterValues.find(({ id }) => id === 'sales')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    id: id?.value,
    parentId: parentId?.value,
    ticketNo: ticketNo?.value,
    transDate: transDate?.value,
    fromLocationId: fromLocation?.value.value,
    fromLocationParentId: org?.value.value,
    extra: {
      recipientName: recipientName?.value,
      recipientContact: recipientContact?.value,
      salesId: sales?.value.value,
      locked: lockStatus?.value.value,
    },
    status: status?.value.value,
    productVariantName: productName?.value,
    createdBy: createdBy?.value,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function handlePrint({ app, session, id, message }) {
  const data = await getPageData({ app, session, id })
  const { title, header, content } = getPrintData({ state: data, message })
  printHtml({ title, header, content, message })
}
