import React, { useContext } from 'react'
import { SessionContext } from 'contexts'
import { Flex } from 'reflexbox'
import FormField from './FormField'
import { TextInput } from './TextInput'
import { Button } from 'components/core'
import { MdSearch } from 'react-icons/md'
import { CUSTOMER_LOOKUP_SET } from 'constants/actionType'

export default ({
  id,
  label,
  required,
  errMsg,
  containerProps,
  onSubmit,
  disabled = false,
  ...props
}) => {
  const session = useContext(SessionContext)

  const handleOpen = () =>
    session.dispatch({
      type: CUSTOMER_LOOKUP_SET,
      item: { open: true, onSubmit },
    })

  return (
    <FormField
      id={id}
      label={label}
      required={required}
      errMsg={errMsg}
      {...containerProps}
    >
      <Flex>
        <TextInput disabled={disabled} id={id} hasError={!!errMsg} {...props} />
        <Button
          disabled={disabled}
          ml={2}
          icon={<MdSearch size="24px" />}
          variant="icon"
          onClick={handleOpen}
        />
      </Flex>
    </FormField>
  )
}
