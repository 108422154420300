export default {
  'error.inventoryBalance.insufficientQuantity': '庫存數量不足：{val0}',
  'error.inventoryBalance.nonzero': '商品尚有庫存',
  'inventory.title': '庫存目錄',
  'inventory.title.allList': '總倉',
  'inventory.title.warehouseList': '倉庫',
  'inventory.title.deptList': '業務',
  'inventory.title.orgList': '經銷群',
  'inventory.title.dealerList': '經銷商',
  'inventory.title.lockList': '鎖貨列表',
  'inventory.title.history': '存量歷史紀錄',
  'inventory.field.warehouseGroup': '倉庫群',
  'inventory.field.warehouse': '倉庫',
  'inventory.field.dept': '業務',
  'inventory.field.org': '群組',
  'inventory.field.dealer': '經銷商',
  'inventory.field.spu': '商品名稱',
  'inventory.field.sku': '品號',
  'inventory.field.options': '屬性',
  'inventory.field.quantity': '數量',
  'inventory.field.amount': '金額',
  'inventory.field.balance': '庫存數量',
  'inventory.field.deptBalance': '業務數量',
  'inventory.field.pendingProcessQuantity': '處理中',
  'inventory.field.lockQuantity': '鎖貨量',
  'inventory.field.deferQuantity': '延出量',
  'inventory.field.lockableQuantity': '可鎖量',
  'inventory.field.unlockableQuantity': '非可鎖量',
  'inventory.field.availableQuantity': '可用量',
  'inventory.field.unavailableQuantity': '非可用量',
  'inventory.field.lentQuantity': '借出量',
  'inventory.field.totalQuantity': '總量',
  'inventory.field.ticketId': '單號',
  'inventory.field.ticketType': '類型',
  'inventory.field.transDate': '日期',
  'inventoryDiff.title.list': '三方系統庫存差異',
  'inventoryDiff.field.vendor': '三方系統',
  'inventoryDiff.field.quantity': '數量',
  'inventoryDiff.field.vendorQuantity': '三方數量',
  'inventory.filter.option': '庫存狀態',
  'inventory.option.OVER_DEFERRED': '延出數量 > 可用數量',
  'inventory.option.SHOW_ZERO': '所有商品（包含庫存為零之商品）',
}
