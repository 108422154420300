import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Select, Switch, TextInput } from 'components/form'
import { Button, Definition, StatusChip } from 'components/core'
import { MdDelete, MdEdit } from 'react-icons/md'
import { getLimit, getPagination } from 'utilities/pagination'
import { textFilter } from 'utilities/filter'
import { getDiff } from 'utilities/list'
import { ALERT_ADD } from 'constants/actionType'
import { getStatusColor } from 'constants/status'

export const initialState = (value = {}) => ({
  dealers: value.dealers || [],
  storages: value.storages || [],
  warehouseGroups: value.warehouseGroups || [],
  allWarehouses: value.warehouses || [],
  warehouses: value.warehouses || [],
  shippings: value.shippings || [],
  products: value.products || [],
  oldCodes: value.oldCodes || [],
  codes: value.codes || [],
  refundStorages: value.refundStorages || [],
  ...initializeState({
    username: value.username || '',
    password: value.password || '',
    syncTime: value.syncTime || '09:00',
    dealerId: getSelectOption(value.dealers, value.dealerId),
    warehouseGroupId: getSelectOption(
      value.warehouseGroups,
      value.warehouseGroupId,
    ),
    warehouseId: getSelectOption(value.warehouses, value.warehouseId),
    refundWarehouseId: getSelectOption(
      value.warehouses,
      value.refundWarehouseId,
    ),
    shippingId: getSelectOption(value.shippings, value.shippingId),
    isActive: value.isActive || false,
  }),
})

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
  syncTime: [{ type: 'required', message: 'error.required' }],
  dealerId: [{ type: 'required', message: 'error.required' }],
}

export const filters = [
  {
    id: 'code',
    label: 'pxmart.field.code',
    input: textFilter,
  },
  {
    id: 'name',
    label: 'field.productName',
    input: textFilter,
  },
]

export const columns = ({ state, setState, action }) => [
  {
    id: 'code',
    label: 'pxmart.field.code',
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
  },
  {
    id: 'importStatus',
    label: 'pxmart.field.importStatus',
    render: ({ row }) => (
      <StatusChip
        label={`pxmart.status.${row.importStatus}`}
        color={getStatusColor(row.importStatus)}
      />
    ),
  },
  {
    id: 'exportStatus',
    label: 'pxmart.field.exportStatus',
    render: ({ row }) => (
      <StatusChip
        label={`pxmart.status.${row.exportStatus}`}
        color={getStatusColor(row.exportStatus)}
      />
    ),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row, index }) => (
      <>
        <Button
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => {
            row.index = index
            action.handleOpen(row)
          }}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            const codes = [...state.codes]
            codes.splice(index, 1)
            setState({ ...state, codes })
          }}
        />
      </>
    ),
  },
]

export const storageColumns = ({ state, setState }) => [
  {
    id: 'name',
    label: 'field.storage',
  },
  {
    id: 'code',
    label: 'pxmart.field.locationCode',
    render: ({ row }) => row.extra.code,
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ index }) => (
      <Button
        variant="icon"
        icon={<MdDelete />}
        onClick={() => {
          const refundStorages = [...state.refundStorages]
          refundStorages.splice(index, 1)
          setState({ ...state, refundStorages })
        }}
      />
    ),
  },
]

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    username: (
      <TextInput
        id="username"
        label="pxmart.field.username"
        placeholder="pxmart.field.username"
        value={state.username}
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        id="password"
        label="pxmart.field.password"
        placeholder="pxmart.field.password"
        value={state.password}
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    syncTime: (
      <TextInput
        type="time"
        id="syncTime"
        label="pxmart.field.syncTime"
        placeholder="pxmart.field.syncTime"
        value={state.syncTime}
        onChange={onTextChange('syncTime')}
        errMsg={state.__error__.syncTime}
      />
    ),
    dealerId: (
      <Select
        isClearable={false}
        label="field.dealer"
        placeholder="field.dealer"
        options={state.dealers}
        value={state.dealerId}
        onChange={onSelectChange('dealerId')}
        errMsg={state.__error__.dealerId}
      />
    ),
    warehouseGroupId: (
      <Select
        label="pxmart.field.dispatchWarehouse"
        placeholder="pxmart.field.dispatchWarehouse"
        options={state.warehouseGroups}
        isClearable={false}
        value={state.warehouseGroupId}
        onChange={onSelectChange('warehouseGroupId', ({ value }) => {
          console.log(value)
          const warehouses = state.allWarehouses.filter(
            (item) => item.parentId === value,
          )
          return { warehouses, warehouseId: null }
        })}
        errMsg={state.__error__.warehouseGroupId}
      />
    ),
    warehouseId: (
      <Select
        label="pxmart.field.dispatchWarehouse"
        placeholder="pxmart.field.dispatchWarehouse"
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    refundWarehouseId: (
      <Select
        label="pxmart.field.refundWarehouse"
        placeholder="pxmart.field.refundWarehouse"
        options={state.warehouses}
        value={state.refundWarehouseId}
        onChange={onSelectChange('refundWarehouseId')}
        errMsg={state.__error__.refundWarehouseId}
      />
    ),
    shippingId: (
      <Select
        label="field.shipping"
        placeholder="field.shipping"
        options={state.shippings}
        value={state.shippingId}
        onChange={onSelectChange('shippingId')}
        errMsg={state.__error__.shippingId}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="plugin.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ app, session, pagination, filterValues })
    setState(initialState(resp))
    setFilterValues(filterValues)
    setPagination(resp.pagination)
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await updatePlugin({ session, app, state })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  addCode: ({ index, ...value }) => {
    const codes = [...state.codes]
    const { code, importStatus, exportStatus } = value
    if (importStatus === 'ACTIVE') {
      codes
        .filter((item) => item.code === code)
        .forEach((item) => (item.importStatus = 'INACTIVE'))
    }

    if (exportStatus === 'ACTIVE') {
      codes
        .filter((item) => item.code === code)
        .forEach((item) => (item.exportStatus = 'INACTIVE'))
    }

    if (index >= 0) {
      codes.splice(index, 1, value)
    } else {
      codes.push(value)
    }
    setState({ ...state, codes })
  },
  addStorage: (value) => {
    const refundStorages = [...state.refundStorages, value]
    setState({ ...state, refundStorages })
  },
})

async function updatePlugin({ session, app, state }) {
  const { oldCodes, codes } = state
  const refundStorages = state.refundStorages.map((item) => ({
    id: item.id,
    code: item.code,
  }))

  const isKeyEqual = (item, newItem) => {
    return item.productVariantId === newItem.productVariantId
  }
  const isValEqual = (item, newItem) => {
    if (item.code !== newItem.code) return false
    if (item.importStatus !== newItem.importStatus) return false
    if (item.exportStatus !== newItem.exportStatus) return false
    return true
  }
  const diff = getDiff(oldCodes, codes, isKeyEqual, isValEqual)
  const itemsToAdd = diff.added.map((item) => ({
    productVariantId: item.productVariantId,
    code: item.code,
    importStatus: item.importStatus,
    exportStatus: item.exportStatus,
  }))
  const itemsToEdit = diff.modified.map(({ after }) => ({
    productVariantId: after.productVariantId,
    code: after.code,
    importStatus: after.importStatus,
    exportStatus: after.exportStatus,
  }))

  const input = {
    id: 'pxmart',
    username: state.username,
    password: state.password,
    syncTime: state.syncTime,
    dealerId: state.dealerId?.value,
    warehouseGroupId: state.warehouseGroupId?.value,
    warehouseId: state.warehouseId?.value,
    refundWarehouseId: state.refundWarehouseId?.value,
    refundStorages,
    shippingId: state.shippingId?.value,
    isActive: state.isActive,
    itemsToAdd,
    itemsToEdit,
    itemsToDel: diff.removed.map((item) => item.productVariantId),
  }
  const variables = { input }
  const query = `
    mutation($input: PxmartInput!) {
      editPxmartConfig(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getData({ app, session, pagination, filterValues }) {
  const variables = {
    input: getDataInput({ filterValues, pagination }),
    locationInput: {
      type: ['DEALER', 'WAREHOUSE_GROUP', 'WAREHOUSE', 'COMPANY', 'STORAGE'],
    },
  }
  const query = `
    query($input: ProductCodeQueryInput, $locationInput: LocationQueryInput) {
      merchant {
        extra
      }
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      productVariants {
        id
        name
      }
      productCodeCount(input: $input)
      productCodes(input: $input) {
        productVariantId
        productVariantName
        code
        importStatus
        exportStatus
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const { plugins } = data.merchant.extra
  const plugin = plugins.find((item) => item.id === 'pxmart')
  const { dealerId } = plugin || {}
  const { page, countPerPage } = pagination
  const warehouseGroups = []
  const warehouses = []
  const dealers = []
  const storages = []
  const refundStorages = []
  const shippings = []

  data.locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'WAREHOUSE_GROUP':
        warehouseGroups.push(result)
        break
      case 'WAREHOUSE':
        result.parentId = item.parentId
        warehouses.push(result)
        break
      case 'DEALER':
        dealers.push(result)
        break
      case 'STORAGE':
        if (dealerId !== item.parentId) break
        if (extra.pxmartRefund) refundStorages.push(item)
        storages.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  return {
    ...plugin,
    dealers,
    storages,
    warehouseGroups,
    warehouses,
    shippings,
    products: data.productVariants,
    oldCodes: cloneDeep(data.productCodes),
    codes: data.productCodes,
    refundStorages,
    pagination: getPagination(page, countPerPage, data.productCodeCount),
  }
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const code = filterValues.find(({ id }) => id === 'code')
  const input = {
    vendor: 'pxmart',
    productVariantName: name ? name.value : null,
    code: code ? code.value : null,
    orderBy: [{ key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
