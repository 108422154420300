import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  initializeState,
  validateForm,
  showDate,
  getDate,
  renderDefinition,
  renderSelectInput,
  renderDateInput,
  renderTextInput,
  renderAddressInput,
  renderPhoneInput,
  renderTextAreaInput,
  renderCustomerLookup,
  getSelectOption,
  renderTable,
  renderNumberInput,
} from 'utilities/form'
import { request } from 'utilities/request'
import { printHtml } from 'utilities/print'
import { getRecipients, handleDelete } from 'actions/ticket'
import { getSelectOptions } from 'utilities/form'
import { Button, Center } from 'components/core'
import { MdDelete, MdEdit } from 'react-icons/md'
import { getDiff } from 'utilities/list'
import { ALERT_ADD } from 'constants/actionType'
import { fetchLocations, getLocation } from 'actions/location'

export const initialState = (value = {}, data = {}, message) => {
  const extra = value.extra || {}
  const pickupRecipients = getRecipients(
    value.pickupRecipients?.length > 0
      ? value.pickupRecipients
      : data.recipients,
    message,
  )
  const deliverRecipients = getRecipients(
    value.deliverRecipients?.length > 0
      ? value.deliverRecipients
      : data.recipients,
    message,
  )
  return {
    id: value.id || '',
    status: value.status || 'PENDING',
    hash: value.hash,
    locked: extra.locked || false,
    shippingName: extra.shippingName || '',
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    shippings: data.shippings,
    products: data.products || [],
    pickupContactId: extra.pickupContactId,
    deliverContactId: extra.deliverContactId,
    ticketItems: value.ticketItems || [],
    oldTicketItems: cloneDeep(value.ticketItems || []),
    dealers: data.dealers,
    pickupRecipients,
    deliverRecipients,
    pickupDealerName: extra.pickupDealerName,
    pickupRecipientName: extra.pickupRecipientName,
    deliverDealerName: extra.deliverDealerName,
    deliverRecipientName: extra.deliverRecipientName,
    ...initializeState({
      parentId: value.parentId || '',
      shippingId: getSelectOption(data.shippings, extra.shippingId),
      ticketDate: getDate(extra.ticketDate),
      transDate: getDate(value.transDate) || '',
      pickupDealerId: getSelectOption(
        data.dealers,
        extra.pickupDealerId,
        'id',
        'name',
      ),
      pickupRecipientId: getSelectOption(
        pickupRecipients,
        extra.pickupRecipientId,
        'id',
        'name',
      ),
      pickupContact: extra.pickupContact || '',
      pickupPhone: extra.pickupPhone || '',
      pickupCellphone: extra.pickupCellphone || '',
      pickupAddress: extra.pickupAddress || '',
      pickupMemo: extra.pickupMemo || '',
      deliverDealerId: getSelectOption(
        data.dealers,
        extra.deliverDealerId,
        'id',
        'name',
      ),
      deliverRecipientId: getSelectOption(
        deliverRecipients,
        extra.deliverRecipientId,
        'id',
        'name',
      ),
      deliverDate: showDate(extra.deliverDate) || '',
      deliverContact: extra.deliverContact || '',
      deliverPhone: extra.deliverPhone || '',
      deliverCellphone: extra.deliverCellphone || '',
      deliverAddress: extra.deliverAddress || '',
      deliverMemo: extra.deliverMemo || '',
      fee: extra.fee || 0,
    }),
  }
}

const validation = {
  shippingId: [{ type: 'required', message: 'error.required' }],
  ticketDate: [{ type: 'required', message: 'error.required' }],
  transDate: [{ type: 'required', message: 'error.required' }],
  deliverDate: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({
  app,
  session,
  profile,
  format,
  state,
  setState,
  message,
  action,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName: 'transport',
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const addressInput = (inputProps) =>
    renderAddressInput({ ...commonProps, ...inputProps, message })
  const phoneInput = (inputProps) =>
    renderPhoneInput({ ...commonProps, ...inputProps, message })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const customerLookup = (inputProps) =>
    renderCustomerLookup({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })
  const numberInput = (inputProps) =>
    renderNumberInput({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    parentId: textInput({ id: 'parentId' }),
    shipping: selectInput({
      id: 'shippingId',
      valKey: 'shippingName',
      options: state.shippings,
    }),
    ticketDate: dateInput({ id: 'ticketDate', min: '1970-01-01' }),
    transDate: dateInput({ id: 'transDate', min: '1970-01-01' }),
    pickupDealer: selectInput({
      id: 'pickupDealerId',
      valKey: 'pickupDealerName',
      isClearable: false,
      options: getSelectOptions(state.dealers),
      callback: ({ value }) =>
        onDealerChange('pickup', state, app, session, message, value),
    }),
    pickupRecipient: selectInput({
      id: 'pickupRecipientId',
      valKey: 'pickupRecipientName',
      isClearable: false,
      options: getSelectOptions(state.pickupRecipients),
      callback: (item) => {
        const pickupRecipient = getLocation(state.pickupRecipients, item?.value)
        return parseRecipient('pickup', pickupRecipient, state)
      },
    }),
    pickupContact: customerLookup({
      id: 'pickupContact',
      href: state.pickupContactId
        ? `${process.env.REACT_APP_STOCK_URL}/customer/${state.pickupContactId}/view`
        : null,
      target: '_blank',
      callback: (value) => {
        setState({
          ...state,
          pickupContactId: value.id || '',
          pickupContact: value.name || '',
          pickupPhone: value.phone || '',
          pickupCellphone: value.cellphone || '',
          pickupAddress: value.address || {},
        })
        return true
      },
    }),
    pickupAddress: addressInput({ id: 'pickupAddress' }),
    pickupPhone: phoneInput({ id: 'pickupPhone' }),
    pickupCellphone: phoneInput({
      id: 'pickupCellphone',
      type: 'cellphone',
    }),
    pickupMemo: textAreaInput({ id: 'pickupMemo' }),
    deliverDealer: selectInput({
      id: 'deliverDealerId',
      valKey: 'deliverDealerName',
      isClearable: false,
      options: getSelectOptions(state.dealers),
      callback: ({ value }) =>
        onDealerChange('deliver', state, app, session, message, value),
    }),
    deliverRecipient: selectInput({
      id: 'deliverRecipientId',
      valKey: 'deliverRecipientName',
      isClearable: false,
      options: getSelectOptions(state.deliverRecipients),
      callback: (item) => {
        const deliverRecipient = getLocation(
          state.deliverRecipients,
          item?.value,
        )
        return parseRecipient('deliver', deliverRecipient, state)
      },
    }),
    deliverDate: dateInput({ id: 'deliverDate', min: '1970-01-01' }),
    deliverContact: customerLookup({
      id: 'deliverContact',
      href: state.deliverContactId
        ? `${process.env.REACT_APP_STOCK_URL}/customer/${state.deliverContactId}/view`
        : null,
      target: '_blank',
      callback: (value) => {
        setState({
          ...state,
          deliverContactId: value.id || '',
          deliverContact: value.name || '',
          deliverPhone: value.phone || '',
          deliverCellphone: value.cellphone || '',
          deliverAddress: value.address || {},
        })
        return true
      },
    }),
    deliverAddress: addressInput({ id: 'deliverAddress' }),
    deliverPhone: phoneInput({ id: 'deliverPhone' }),
    deliverCellphone: phoneInput({
      id: 'deliverCellphone',
      type: 'cellphone',
    }),
    deliverMemo: textAreaInput({ id: 'deliverMemo' }),
    fee: numberInput({ id: 'fee' }),
    product: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'productVariantName',
          label: 'field.productName',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          width: '96px',
          align: 'right',
        },
        {
          id: 'memo',
          label: 'field.memo',
          render: ({ row }) => row.extra?.memo,
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          profile: ['add', 'edit'],
          render: ({ row, index }) => (
            <Center justifyContent="flex-end">
              <Button
                mr={2}
                variant="icon"
                icon={<MdEdit />}
                onClick={() => action.handleOpen({ ...row, index })}
              />
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const ticketItems = cloneDeep(state.ticketItems)
                  ticketItems.splice(index, 1)
                  setState({ ...state, ticketItems })
                }}
              />
            </Center>
          ),
        },
      ],
    }),
  }
}

async function onDealerChange(type, state, app, session, message, dealerId) {
  const locationInput = { parentId: dealerId, type: ['STORAGE'] }
  const recipients = await fetchLocations(app, session, locationInput)
  return {
    [`${type}Recipients`]: getRecipients(recipients, message),
  }
}

function parseRecipient(type, recipient, state = {}) {
  const oldAddress = state.recipientAddress || {}
  const extra = recipient.extra || {}
  return {
    [`${type}Contact`]: recipient.contact || state.recipientContact || '',
    [`${type}Address`]: extra.address || oldAddress,
    [`${type}Email`]: extra.email || state.recipientEmail || '',
    [`${type}Phone`]: extra.phone || state.recipientPhone || '',
    [`${type}Cellphone`]: extra.cellphone || state.recipientCellphone || '',
  }
}

export const handlers = ({
  state,
  setState,
  setData,
  session,
  app,
  history,
  message,
  id,
  profile,
  data,
}) => ({
  handleInit: async () => {
    const data = await getInit({ app, session })
    setData(data)
  },
  handleLoad: async () => {
    const resp = await getData({ app, session, id, profile })
    setState(initialState(resp, data, message))
  },
  addItem: (value) => {
    const ticketItems = [...state.ticketItems]
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    const isValid = validateForm({ session, state, setState, validation })
    if (!isValid) return

    const [ok, data] = id
      ? await editTransport(state, app, session, history)
      : await addTransport(state, app, session, history)
    if (!ok) return
    if (!id) id = data.addTransportTicket

    history.push(`/transport/${id}/view`)
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmTransport({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp, data, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })

    return true
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('transport', { session, app, id, hash })
    if (!ok) return false

    history.push('/transport')
    return true
  },
  handlePrint: () => {
    const { title, header, footer, content } = getPrintData({
      app,
      state,
      message,
    })
    printHtml({ title, header, footer, content, message })
  },
})

export function getPrintData({ app, state, message }) {
  const title = {
    id: 'transport.title.print',
    values: { ticketId: state.id },
  }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const form = fields({ profile: 'view', format: 'print', state, message })
  const hidden = ['product', 'ticketItems']
  const field = Object.values(form).filter(({ id }) => !hidden.includes(id))
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: form.product },
  ]
  return { title, header, content }
}

async function getInit({ app, session }) {
  const variables = {
    locationInput: { type: ['DEALER', 'COMPANY'] },
    productInput: { status: ['ACTIVE', 'INACTIVE'] },
  }
  const query = `
    query($locationInput: LocationQueryInput, $productInput: ProductQueryInput) {
      locations(input: $locationInput) {
        id
        type
        name
        extra
      }
      productVariants(input: $productInput) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const dealers = []
  const shippings = []

  data.locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'DEALER':
        dealers.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  return { dealers, shippings, products: data.productVariants }
}

async function getData({ app, session, id, profile }) {
  if (!id) return {}

  const variables = { id }
  const query = `
    query($id: ID) {
      transportTicket(id: $id) {
        id
        parentId
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      transportTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        quantity
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.transportTicket || {}
  const extra = ticket.extra || {}
  let pickupRecipients = []
  let deliverRecipients = []

  if (profile === 'edit' && extra.pickupDealerId) {
    const locationInput = { parentId: extra.pickupDealerId, type: ['STORAGE'] }
    pickupRecipients = await fetchLocations(app, session, locationInput)
  }

  if (profile === 'edit' && extra.deliverDealerId) {
    const locationInput = { parentId: extra.deliverDealerId, type: ['STORAGE'] }
    deliverRecipients = await fetchLocations(app, session, locationInput)
  }

  return {
    ...ticket,
    ticketItems: data.transportTicketItems,
    pickupRecipients,
    deliverRecipients,
  }
}

async function addTransport(state, app, session, history) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addTransportTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editTransport(state, app, session, history) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editTransportTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, hash, shippingId, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => {
    const { extra } = item
    return {
      id: item.id,
      productVariantId: item.productVariantId,
      quantity: parseInt(item.quantity),
      extra,
    }
  })
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    id,
    hash,
    parentId: state.parentId || null,
    transDate: state.transDate,
    extra: {
      ticketDate: state.ticketDate,
      shippingId: shippingId.value,
      shippingName: shippingId.label,
      pickupDealerId: state.pickupDealerId?.value,
      pickupDealerName: state.pickupDealerId?.label,
      pickupRecipientId: state.pickupRecipientId?.value,
      pickupRecipientName: state.pickupRecipientId?.label,
      pickupContactId: state.pickupContactId,
      pickupContact: state.pickupContact,
      pickupAddress: state.pickupAddress,
      pickupPhone: state.pickupPhone,
      pickupCellphone: state.pickupCellphone,
      pickupMemo: state.pickupMemo,
      deliverDealerId: state.deliverDealerId?.value,
      deliverDealerName: state.deliverDealerId?.label,
      deliverRecipientId: state.deliverRecipientId?.value,
      deliverRecipientName: state.deliverRecipientId?.label,
      deliverDate: state.deliverDate,
      deliverContactId: state.deliverContactId,
      deliverContact: state.deliverContact,
      deliverAddress: state.deliverAddress,
      deliverPhone: state.deliverPhone,
      deliverCellphone: state.deliverCellphone,
      deliverMemo: state.deliverMemo,
      fee: state.fee,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function confirmTransport({ session, app, id, hash }) {
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmTransportTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
