import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/inventoryLockDrawer'
import { Drawer, Table } from 'components/core'

export default ({ value, data, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const { handleLoad } = handlers({ session, app, value, data, setState })
  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      title={`inventory.title.lockList`}
      open={open}
      onClose={action.handleClose}
    >
      <Table columns={columns} rows={state} />
    </Drawer>
  )
}
