export default {
  'error.location.ticketExists': '經銷商已被使用，登錄用戶無更改類型權限',
  'dealer.title.list': '經銷商目錄',
  'dealer.title.add': '新增經銷商',
  'dealer.title.addStorage': '新增收貨處',
  'dealer.title.edit': '更改經銷商',
  'dealer.title.delete': '刪除經銷商',
  'dealer.section.info': '經銷商詳情',
  'dealer.section.address': '收件通訊錄',
  'dealer.section.collectPeriods': '允收效期',
  'dealer.message.delete': '確認刪除經銷商?',
  'dealer.field.dealer': '經銷商',
  'dealer.field.dept': '業務部門',
  'dealer.field.org': '經銷群',
  'dealer.field.name': '名稱',
  'dealer.field.code': '代碼',
  'dealer.field.ein': '統一編號',
  'dealer.field.type': '類型',
  'dealer.field.billing': '歸帳',
  'dealer.field.skipDispatchConfirm': '出貨免審核',
  'dealer.field.skipDispatchWarehouse': '出貨免審核倉別',
  'dealer.field.shipping': '預設物流',
  'dealer.field.sales': '預設帳務人員',
  'dealer.field.contact': '聯繫方式',
  'dealer.field.phone': '電話',
  'dealer.field.address': '地址',
  'dealer.field.memo': '備註',
  'dealer.field.contractFromDate': '切口合約起始日',
  'dealer.field.contractToDate': '切口合約終止日',
  'dealer.field.contractSignDate': '展示合約簽訂日',
  'dealer.field.contractAmount': '合約金額',
  'dealer.field.issueReceipt': '是否開票',
  'dealer.field.isActive': '開啟',
  'dealer.field.status': '狀態',
  'dealer.field.lowRange': '效期範圍（低）',
  'dealer.field.highRange': '效期範圍（高）',
  'dealer.field.shelfPercent': '允收效期百分比',
  'dealer.field.shelfDay': '允收效期天數',
  'dealer.type.SELF_STORAGE': '寄倉',
  'dealer.type.DEALER_STORAGE': '賣斷',
  'dealer.status.ACTIVE': '使用中',
  'dealer.status.INACTIVE': '已停用',
  'dealer.message.invalidFilter': '請指定搜尋經銷商與日期',
  'dealerStorage.status.ACTIVE': '開啟',
  'dealerStorage.status.INACTIVE': '停用',
  'dealerStorage.field.name': '收貨處',
  'dealerStorage.field.code': '廠商代碼',
  'dealerStorage.field.contact': '聯繫方式',
  'dealerStorage.field.contactName': '收貨處聯絡人',
  'dealerStorage.field.phone': '收貨處電話',
  'dealerStorage.field.cellphone': '收貨處手機',
  'dealerStorage.field.email': 'EMAIL',
  'dealerStorage.field.address': '收貨處地址',
  'dealerStorage.field.status': '狀態',
}
