import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/customerProductDrawer'
import { Center, Drawer, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ readonly, value, open, data, action }) => {
  const { id } = data
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data))
  const form = fields({ readonly, state, setState })
  const handler = handlers({ state, setState, app, session, id, action })
  const profile = readonly ? 'View' : id ? 'Edit' : 'Add'

  useEffect(() => {
    setState(initialState(value, data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      title={`customer.title.product${profile}`}
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.transDate}
      {form.dealerId}
      {form.productVariantId}
      {form.quantity}
      {form.price}
      {form.serial}
      {form.model}
      {form.serialNo}
      {form.memo}
      <Center mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          show={!readonly}
          type="submit"
          variant="primary"
          text="btn.confirm"
        />
      </Center>
    </Drawer>
  )
}
