import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/processDrawer'
import { Center, Button, Drawer } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, open, action }) => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, message))
  const form = fields({ state, setState, message })
  const handler = handlers({ state, setState, app, session, action })

  useEffect(() => {
    setState(initialState(value, message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Drawer
      title="processConfig.title.add"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.processName}
      {form.code}
      {form.memo}
      {form.quantity}
      {form.unit}
      {form.price}
      <Center mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button type="submit" variant="primary" text="btn.submit" />
      </Center>
    </Drawer>
  )
}
