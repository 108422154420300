export default {
  'sell.title.list': '銷貨目錄',
  'sell.title.add': '新增銷貨單',
  'sell.title.edit': '修改銷貨單',
  'sell.title.view': '銷貨單詳情',
  'sell.title.addItem': '修改銷貨商品',
  'sell.title.listItems': '銷貨商品詳情',
  'sell.title.print': '銷貨單 - {ticketId}',
  'sell.section.product': '商品',
  'sell.section.basic': '基礎信息',
  'sell.field.id': '單號',
  'sell.field.parentId': '關聯單號',
  'sell.field.ticketNo': '廠商單號',
  'sell.field.transDate': '銷貨日期',
  'sell.field.sales': '業務人員',
  'sell.field.backup': '帳務人員',
  'sell.field.memo': '業務備註',
  'sell.field.dealer': '經銷層',
  'sell.field.dealerCode': '廠商代碼',
  'sell.field.dealerEIN': '統一編號',
  'sell.field.dealerAddress': '公司地址',
  'sell.field.billing': '歸帳',
  'sell.field.recipient': '收貨處',
  'sell.field.recipientContact': '聯絡人',
  'sell.field.recipientAddress': '收件地址',
  'sell.field.recipientEmail': 'Email',
  'sell.field.recipientPhone': '電話',
  'sell.field.recipientCellphone': '手機',
  'sell.field.spu': '商品名稱',
  'sell.field.barcode': '產品條碼',
  'sell.field.isGift': '贈品',
  'sell.field.batchNo': '批號',
  'sell.field.balance': '可用數量',
  'sell.field.quantity': '數量',
  'sell.field.unitPrice': '單價',
  'sell.field.price': '小計',
  'sell.field.productMemo': '商品備註',
  'sell.field.status': '狀態',
}
