import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Select, NumberInput, DateInput } from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'
import { Button, Definition, Table } from 'components/core'
import { MdDelete } from 'react-icons/md'
import { getDate } from 'utilities/form'
import { getDiff } from 'utilities/list'

export const initialState = (value = {}, message) => ({
  ticketId: value.ticketId,
  hash: value.hash,
  id: value.id || '',
  productVariantId: value.productVariantId,
  productVariantName: value.productVariantName,
  quantity: value.quantity,
  oldResultItems: cloneDeep(value.resultItems) || [],
  resultItems: getResultItems(value.resultItems, message),
  isComboSingle: !!value.comboParentId,
  comboQuantity: value.comboQuantity,
})

function getResultItems(data, message) {
  if (!data) return []

  return data.map((item) => {
    const { toLocationId, toLocationName, extra } = item
    const type = extra.type
    item.type = typeof type === 'string' ? getType(type, message) : type
    item.locationId = { value: toLocationId, label: toLocationName }
    return item
  })
}

export function getTypes(message) {
  return [
    {
      value: 'RETURN_WAREHOUSE',
      label: message({ id: `refund.type.RETURN_WAREHOUSE` }),
    },
    {
      value: 'DISCARD_NO_RECYCLE',
      label: message({ id: `refund.type.DISCARD_NO_RECYCLE` }),
    },
    {
      value: 'DISCARD',
      label: message({ id: `refund.type.DISCARD` }),
    },
    {
      value: 'DISCARD_EXPIRED',
      label: message({ id: `refund.type.DISCARD_EXPIRED` }),
    },
    {
      value: 'DISCARD_OPENED',
      label: message({ id: `refund.type.DISCARD_OPENED` }),
    },
    {
      value: 'RECEIVE_LESS',
      label: message({ id: `refund.type.RECEIVE_LESS` }),
    },
    {
      value: 'LOST',
      label: message({ id: `refund.type.LOST` }),
    },
  ]
}

function getType(value, message) {
  if (!value) value = 'RETURN_WAREHOUSE'
  return { value, label: message({ id: `refund.type.${value}` }) }
}

export const fields = ({ state, setState, data, message }) => {
  const onItemChange = (index, key) => (value) => {
    const resultItems = [...state.resultItems]
    const resultItem = resultItems[index]
    resultItem[key] = value
    resultItems.splice(index, 1, resultItem)
    setState({ ...state, resultItems })
  }

  return {
    productVariant: (
      <Definition label="product.field.spu" value={state.productVariantName} />
    ),
    quantity: (
      <Definition
        show={!state.isComboSingle}
        label="refund.field.appliedQuantity"
        value={state.quantity}
      />
    ),
    comboQuantity: (
      <Definition
        show={state.isComboSingle}
        label="refund.field.comboQuantity"
        value={state.comboQuantity}
      />
    ),
    maxSingleQuantity: (
      <Definition
        show={state.isComboSingle}
        label="refund.field.maxSingleQuantity"
        // value={state.quantity * state.comboQuantity}
        value={state.quantity}
      />
    ),
    resultItems: (
      <Table
        columns={[
          {
            id: 'transDate',
            label: 'field.date',
            render: ({ row, index }) => (
              <DateInput
                fieldProps={{ m: 0 }}
                value={row.transDate}
                onChange={onItemChange(index, 'transDate')}
              />
            ),
          },
          {
            id: 'type',
            label: 'refund.field.type',
            width: '160px',
            render: ({ row, index }) => (
              <Select
                fieldProps={{ m: 0 }}
                isSearchable={false}
                isClearable={false}
                options={getTypes(message)}
                value={row.type}
                onChange={onItemChange(index, 'type')}
              />
            ),
          },
          {
            id: 'locationId',
            label: 'refund.field.warehouseId',
            width: '35%',
            render: ({ row, index }) => (
              <Select
                fieldProps={{ m: 0 }}
                show={row.type?.value === 'RETURN_WAREHOUSE'}
                isClearable={false}
                options={data.locations}
                value={row.locationId}
                onChange={onItemChange(index, 'locationId')}
              />
            ),
          },
          {
            id: 'quantity',
            label: 'refund.field.quantity',
            render: ({ row, index }) => (
              <NumberInput
                width="80px"
                fieldProps={{ m: 0 }}
                min="1"
                value={row.quantity}
                onChange={onItemChange(index, 'quantity')}
              />
            ),
          },
          {
            id: 'actions',
            width: '30px',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const resultItems = [...state.resultItems]
                  resultItems.splice(index, 1)
                  setState({ ...state, resultItems })
                }}
              />
            ),
          },
        ]}
        rows={state.resultItems}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, action }) => ({
  handleConfirm: async (event) => {
    event.preventDefault()

    const [ok] = await editRefund({ app, session, state })
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
    await action.handleSubmit()
    return true
  },
  addResult: () => {
    const resultItems = [...state.resultItems]
    resultItems.push({
      transDate: getDate(),
      type: {},
      locationId: {},
      quantity: 1,
    })
    setState({ ...state, resultItems })
  },
})

async function editRefund({ app, session, state }) {
  const variables = { id: state.ticketId, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editRefundTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, productVariantId, oldResultItems } = state
  const resultItems = state.resultItems.map((item) => {
    const type = item.type.value
    const isReturn = type === 'RETURN_WAREHOUSE'
    return {
      id: item.id,
      transDate: item.transDate,
      productVariantId,
      toLocationId: isReturn ? item.locationId?.value : '',
      quantity: parseInt(item.quantity),
      extra: { parentItemId: state.id, type },
    }
  })
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.transDate !== newItem.transDate) return false
    if (item.toLocationId !== newItem.toLocationId) return false
    if (item.quantity !== newItem.quantity) return false
    if (item.extra.type !== newItem.extra.type) return false
    return true
  }
  const diff = getDiff(oldResultItems, resultItems, isKeyEqual, isValEqual)

  return {
    hash,
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}
