import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, labels, fields, handlers } from 'models/dispatchDrawer'
import { Toolbar, Center, Drawer, Button } from 'components/core'
import { MdAdd } from 'react-icons/md'
import { checkBatchItemQuantity } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, data, readonly, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data))
  const form = readonly
    ? labels({ state })
    : fields({ app, session, state, setState, data })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    readonly,
    data,
    action,
  })

  useEffect(() => {
    if (open) handler.handleLoad(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Drawer
      width={[1, '460px']}
      title={readonly ? 'dispatch.title.listItems' : 'dispatch.title.addItem'}
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.productVariantId}
      {form.warehouseId}
      {form.warehouseBalance}
      {form.balance}
      {form.deptBalance}
      {form.quantity}
      {form.price}
      {form.isDemo}
      {form.isGift}
      {form.serial}
      {form.memo}
      <Toolbar show={!readonly}>
        <Button
          variant="default"
          icon={<MdAdd />}
          text="dispatch.btn.addBatch"
          onClick={handler.addBatch}
        />
      </Toolbar>
      {form.batchItems}
      <Center mt={3}>
        <Button
          show={!readonly}
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          show={!readonly}
          disabled={checkBatchItemQuantity(state.quantity, state.batchItems)}
          type="submit"
          variant="primary"
          text="btn.confirm"
        />
      </Center>
    </Drawer>
  )
}
