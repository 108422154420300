export const getProduct = (variants, variantId) => {
  if (!variants || !variantId) return {}
  const product = variants.find(({ id }) => id === variantId)
  return product || {}
}

export const getProductOption = (variants, variantId) => {
  const product = getProduct(variants, variantId)
  if (Object.keys(product).length === 0) return null
  return { value: product.id, label: product.name }
}

export const getProductOptions = (variants, ticketItems, productVariantId) => {
  if (!ticketItems) {
    return variants.map(({ id, name }) => ({ value: id, label: name }))
  }

  const productSet = new Set(ticketItems.map((item) => item.productVariantId))
  return variants.reduce((result, item) => {
    if (productSet.has(item.id) && item.id !== productVariantId) {
      return result
    }
    result.push({ value: item.id, label: item.name })
    return result
  }, [])
}

export const getProductMap = (products) => {
  if (!products) return {}

  return products.reduce((result, item) => {
    result[item.id] = item
    return result
  }, {})
}
