import React, { useState, useContext, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Button, Link, Section, Grid, Center, Toolbar } from 'components/core'
import Page from 'components/Page'
import { initialState, fields, handlers } from 'models/dealerPage'
import DealerDrawer from 'views/DealerDrawer'
import DealerCollectDrawer from 'views/DealerCollectDrawer'
import { MdAdd, MdArrowBack } from 'react-icons/md'
import { Box } from 'reflexbox'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ match, profile }) => {
  const { id } = match.params
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState()
  const [shelfOpen, setShelfOpen] = useState(false)
  const [shelfValue, setShelfValue] = useState()

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleShelfOpen = (item) => {
    setShelfValue(item)
    setShelfOpen(true)
  }

  const handleShelfClose = () => {
    setShelfOpen(false)
    setShelfValue({})
  }

  const form = fields({ state, setState, message, action: { handleOpen, handleShelfOpen } })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    history,
    id,
  })

  useEffect(() => {
    handler.handleLoad({ setState, app, session })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <Page
        title={`dealer.title.${profile}`}
        navId="dealerList"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/dealer"
            />
            <Button type="submit" variant="primary" ml={3} text="btn.save" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.dept}
            {form.org}
            {form.name}
            {form.ein}
            {form.code}
            {form.sales}
          </Section>
          <Section>
            {form.phone}
            {form.address}
            {form.contractFromDate}
            {form.contractToDate}
            {form.contractSignDate}
            {form.contractAmount}
            {form.issueReceipt}
          </Section>
          <Box>
            <Section>{form.isActive}</Section>
            <Section mt={3}>
              {form.type}
              {form.billing}
            </Section>
            <Section mt={3}>
              {form.skipDispatchConfirm}
              {form.skipDispatchWarehouse}
            </Section>
          </Box>
        </Grid>
        <Section title="dealer.section.address" mt={5}>
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
          {form.storages}
        </Section>
        <Section title="dealer.section.collectPeriods" mt={5}>
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleShelfOpen({ index: -1 })}
            />
          </Toolbar>
          {form.collectPeriods}
        </Section>
      </Page>
      <DealerDrawer
        open={open}
        value={value}
        data={{ shippings: state.shippings, sales: state.sales }}
        action={{ handleClose, addStorage: handler.addStorage }}
      />
      <DealerCollectDrawer
        open={shelfOpen}
        value={shelfValue}
        action={{ handleClose: handleShelfClose, addShelfPeriod: handler.addShelfPeriod }}
      />
    </>
  )
}
