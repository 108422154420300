import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { setAddressOptions } from 'actions/address'
import { initialState, fields, handlers } from 'models/dispatchImportPage'
import { SessionContext, AppContext } from 'contexts'
import { Button, Center, Grid, Link, Message, Section } from 'components/core'
import Page from 'components/Page'
import DispatchImportModal from 'views/DispatchImportModal'
import { MdArrowBack } from 'react-icons/md'
import { Box } from 'reflexbox'

export default () => {
  const url = new URL(window.location.href)
  const deferId = url.searchParams.get('deferId')
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { cityMap, districtMap } = app.state
  const [state, setState] = useState(initialState())
  const [open, setOpen] = useState(false)
  const form = fields({ app, session, state, setState })
  const handler = handlers({
    deferId,
    app,
    session,
    state,
    setState,
    message,
    data: { cityMap, districtMap },
    action: { setOpen },
  })

  useEffect(() => {
    setAddressOptions({ app, session, cityMap, districtMap })
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page
        navId="dispatchList"
        title="dispatch.title.import"
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/dispatch"
            />
            <Button ml={3} variant="primary" text="btn.submit" type="submit" />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={handler.loadExcel}
      >
        <Center justifyContent="flex-start" px={3}>
          <Message id="dispatch.message.template" />
          <Link
            variant="primaryLink"
            text="btn.download"
            onClick={handler.downloadTemplate}
          />
        </Center>
        <Grid mt={3} cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.deferId}
            {form.warehouseGroupId}
            {form.warehouseId}
            {form.dealerId}
          </Section>
          <Section>
            {form.recipientId}
            {form.shippingId}
            {form.sales}
          </Section>
          <Box>
            <Section>
              {form.isDirect}
              {/* {form.breakCombo} */}
              {form.skipConfirmation}
            </Section>
            <Section mt={3}>{form.file}</Section>
          </Box>
        </Grid>
      </Page>
      <DispatchImportModal
        open={open}
        value={{
          deferId: state.deferId,
          dealers: state.dealerLocations,
          recipients: state.recipientLocations,
          warehouseGroupId: state.warehouseGroupId,
          warehouseId: state.warehouseId?.value,
          ticketItems: state.ticketItems,
        }}
        data={{
          warehouseGroups: state.warehouseGroups,
          allWarehouses: state.allWarehouses,
          warehouses: state.warehouses,
          dealers: state.dealers,
          dealerLocations: state.dealerLocations,
          recipients: state.recipients,
          recipientLocations: state.recipientLocations,
          billings: state.billings,
          shippings: state.shippings,
          products: state.products,
          sales: state.sales,
        }}
        action={{ handleClose: () => setOpen(false) }}
      />
    </>
  )
}
