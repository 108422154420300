import React from 'react'
// import InputMask from 'react-input-mask'
import { TextInput } from 'components/form'

export default ({ value, ...props }) => {
  // const handleChange = (value) => {
  //   if (typeof value !== 'string') return
  //   if (!value) return

  //   const time = value.split(':')
  //   const hour = parseInt(time[0])
  //   const minute = parseInt(time[1])
  //   onChange(`${hour}:${minute}`)
  // }
  return <TextInput placeholder="placeholder.time" {...props} />
  // return (
  //   <InputMask
  //     mask="99:99"
  //     maskPlaceholder="hh:mm"
  //     alwaysShowMask
  //     value={value}
  //     onChange={handleChange}
  //   >
  //     <TextInput {...props} />
  //   </InputMask>
  // )
}
