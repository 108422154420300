import React from 'react'
import { Button, Message, StatusChip } from 'components/core'
import { showDate } from 'utilities/form'
import { getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { getStatusColor } from 'constants/status'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'

export const columns = ({ app, session, action }) => [
  {
    id: 'ticketId',
    label: 'field.ticketId',
  },
  {
    id: 'sendDate',
    label: 'sdj.field.sendDate',
    render: ({ row }) => showDate(row.sendDate),
  },
  {
    id: 'status',
    label: 'field.status',
    render: ({ row }) => (
      <StatusChip
        label={`sdj.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <Button
        variant="icon"
        onClick={() => {
          session.dispatch({
            type: CONFIRM_SET,
            item: {
              open: true,
              title: 'sdj.title.dispatchSync',
              text: 'sdj.message.dispatchSync',
              onSubmit: async () => {
                const { ticketId } = row
                const [ok] = await syncDispatch({ session, app, ticketId })
                if (!ok) return false

                action.handleLoad()
                session.dispatch({
                  type: ALERT_ADD,
                  item: { type: 'success', message: 'submit.success' },
                })
                return true
              },
            },
          })
        }}
      >
        <Message id="sdj.btn.dispatchSync" />
      </Button>
    ),
  },
]

export const handlers = ({ session, app, setState, setPagination }) => ({
  handleLoad: async ({ pagination } = {}) => {
    const resp = await getData({ session, app, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, pagination = {} }) {
  const query = `
    query {
      ticketOutboxItemCount
      ticketOutboxItems {
        ticketId
        sendDate
        status
      }
    }
  `
  const [ok, data] = await request({ query }, { app, session })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.ticketOutboxItems,
    pagination: getPagination(page, countPerPage, data.ticketOutboxItemCount),
  }
}

async function syncDispatch({ session, app, ticketId }) {
  const variables = { ticketId }
  const query = `
    mutation($ticketId: ID!) {
      sdjSendOutboxTicket(ticketId: $ticketId)
    }
  `
  return request({ query, variables }, { session, app })
}
