import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getSelectOption,
  showAddress,
  getMultiSelectOption,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Box, Flex } from 'reflexbox'
import { Table, Button, Text, Definition, StatusChip } from 'components/core'
import {
  PhoneInput,
  TextInput,
  Select,
  Switch,
  DateInput,
  NumberInput,
} from 'components/form'
import { MdDelete, MdEdit } from 'react-icons/md'
import { getDiff } from 'utilities/list'
import { getStatusColor } from 'constants/status'

export const initialState = (value = {}, message) => ({
  id: value.id || '',
  warehouses: value.warehouses || [],
  skipDispatchWarehouseName: value.skipDispatchWarehouseName || [],
  depts: value.depts || [],
  orgs: value.orgs || [],
  dealerOrgs: value.dealerOrgs || [],
  billings: value.billings || [],
  shippings: value.shippings || [],
  oldStorages: value.oldStorages || [],
  storages: value.storages || [],
  collectPeriods: value.collectPeriods || [],
  sales: value.sales || [],
  salesName: value.salesName || [],
  ...initializeState({
    deptId: getSelectOption(value.depts, value.deptId),
    dealerOrgId: getSelectOption(value.orgs, value.parentId),
    name: value.name || '',
    code: value.code || '',
    ein: value.ein || '',
    type: getType(value.type, message),
    billingId: getSelectOption(value.billings, value.billingId),
    skipDispatchConfirm: value.skipDispatchConfirm || false,
    skipDispatchWarehouseId: getMultiSelectOption(
      value.warehouses,
      value.skipDispatchWarehouseId,
    ),
    phone: value.phone || '',
    address: value.address || '',
    salesId: getMultiSelectOption(value.sales, value.salesId),
    contractFromDate: value.contractFromDate || '',
    contractToDate: value.contractToDate || '',
    contractSignDate: value.contractSignDate || '',
    contractAmount: value.contractAmount || 0,
    issueReceipt: value.issueReceipt || false,
    isActive: value.status !== 'INACTIVE',
  }),
})

function getDealerOrgs(orgs, deptId) {
  if (!orgs || !deptId) return []
  return orgs.filter((item) => item.parentId === deptId)
}

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
  dealerOrgId: [{ type: 'required', message: 'error.required' }],
  billingId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, message, action }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)
  return {
    dept: (
      <Select
        label="dealer.field.dept"
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isClearable={false}
        options={state.depts}
        value={state.deptId}
        onChange={onSelectChange('deptId', ({ value }) => {
          const dealerOrgs = getDealerOrgs(state.orgs, value)
          return { dealerOrgs }
        })}
      />
    ),
    org: (
      <Select
        label="dealer.field.org"
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isClearable={false}
        options={state.dealerOrgs}
        value={state.dealerOrgId}
        onChange={onSelectChange('dealerOrgId')}
        errMsg={state.__error__.dealerOrgId}
      />
    ),
    name: (
      <TextInput
        id="name"
        label="dealer.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="dealer.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    ein: (
      <TextInput
        id="ein"
        label="dealer.field.ein"
        value={state.ein}
        onChange={onTextChange('ein')}
        errMsg={state.__error__.ein}
      />
    ),
    type: state.id ? (
      <Definition label="dealer.field.type" value={state.type.label} />
    ) : (
      <Select
        label="dealer.field.type"
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isSearchable={false}
        isClearable={false}
        options={getTypes(message)}
        value={state.type}
        onChange={handleSelectChange('type', state, setState, validation)}
      />
    ),
    billing: (
      <Select
        label="dealer.field.billing"
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isSearchable={false}
        isClearable={false}
        options={state.billings}
        value={state.billingId}
        onChange={handleSelectChange('billingId', state, setState, validation)}
        errMsg={state.__error__.billingId}
      />
    ),
    skipDispatchConfirm: (
      <Definition
        direction="row"
        label="dealer.field.skipDispatchConfirm"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.skipDispatchConfirm}
          onClick={() => {
            setState({
              ...state,
              skipDispatchConfirm: !state.skipDispatchConfirm,
            })
          }}
        />
      </Definition>
    ),
    skipDispatchWarehouse: (
      <Select
        label="dealer.field.skipDispatchWarehouse"
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isSearchable={false}
        isClearable
        isMulti
        options={state.warehouses}
        value={state.skipDispatchWarehouseId}
        onChange={handleSelectChange(
          'skipDispatchWarehouseId',
          state,
          setState,
          validation,
        )}
        errMsg={state.__error__.skipDispatchWarehouseId}
      />
    ),
    phone: (
      <PhoneInput
        id="phone"
        label="dealer.field.phone"
        value={state.phone}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    address: (
      <TextInput
        id="address"
        label="dealer.field.address"
        value={state.address}
        onChange={onTextChange('address')}
        errMsg={state.__error__.address}
      />
    ),
    sales: (
      <Select
        label="dealer.field.sales"
        placeholder="dealer.field.sales"
        isMulti
        options={state.sales}
        value={state.salesId}
        onChange={onSelectChange('salesId')}
        errMsg={state.__error__.salesId}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="dealer.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
    contractFromDate: (
      <DateInput
        id="contractFromDate"
        label="dealer.field.contractFromDate"
        placeholder="dealer.field.contractFromDate"
        value={state.contractFromDate}
        min="1970-01-01"
        onChange={onTextChange('contractFromDate')}
        errMsg={state.__error__.contractFromDate}
      />
    ),
    contractToDate: (
      <DateInput
        id="contractToDate"
        label="dealer.field.contractToDate"
        placeholder="dealer.field.contractToDate"
        value={state.contractToDate}
        min="1970-01-01"
        onChange={onTextChange('contractToDate')}
        errMsg={state.__error__.contractToDate}
      />
    ),
    contractSignDate: (
      <DateInput
        id="contractSignDate"
        label="dealer.field.contractSignDate"
        placeholder="dealer.field.contractSignDate"
        value={state.contractSignDate}
        min="1970-01-01"
        onChange={onTextChange('contractSignDate')}
        errMsg={state.__error__.contractSignDate}
      />
    ),
    contractAmount: (
      <NumberInput
        label="dealer.field.contractAmount"
        min="0"
        value={state.contractAmount}
        onChange={onTextChange('contractAmount')}
        errMsg={state.__error__.contractAmount}
      />
    ),
    issueReceipt: (
      <Definition
        direction="row"
        label="dealer.field.issueReceipt"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.issueReceipt}
          onClick={() => {
            setState({
              ...state,
              issueReceipt: !state.issueReceipt,
            })
          }}
        />
      </Definition>
    ),
    storages: (
      <Table
        columns={[
          {
            id: 'name',
            label: 'dealerStorage.field.name',
          },
          {
            id: 'code',
            label: 'dealerStorage.field.code',
          },
          {
            id: 'storageContact',
            label: 'dealerStorage.field.contact',
            render: ({ row }) => (
              <Flex flexDirection="column">
                <Text>{row.contact}</Text>
                <Text>{row.phone}</Text>
                <Text>{row.cellphone}</Text>
                <Text>{row.email}</Text>
                <Text>{showAddress(row.address, message)}</Text>
              </Flex>
            ),
          },
          {
            id: 'shippingName',
            label: 'dealer.field.shipping',
          },
          {
            id: 'salesName',
            label: 'dealer.field.sales',
            render: ({ row }) =>
              row.salesName?.map((item, index) => (
                <Box key={index} pt={index === 0 ? 0 : 1}>
                  {item}
                </Box>
              )),
          },
          {
            id: 'status',
            label: 'field.status',
            renderHtml: ({ row }) => (
              <StatusChip
                label={`dealerStorage.status.${row.status}`}
                color={getStatusColor(row.status)}
              />
            ),
            render: ({ row }) => message({ id: `status.${row.status}` }),
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <>
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() => action.handleOpen({ ...row, index })}
                />
                {/* <Button
                  mr={2}
                  variant="icon"
                  icon={<MdDelete />}
                  onClick={() => {
                    const storages = [...state.storages]
                    storages.splice(index, 1)
                    setState({ ...state, storages })
                  }}
                /> */}
              </>
            ),
          },
        ]}
        rows={state.storages}
      />
    ),
    collectPeriods: (
      <Table
        columns={[
          {
            id: 'lowRange',
            label: 'dealer.field.lowRange',
          },
          {
            id: 'highRange',
            label: 'dealer.field.highRange',
          },
          {
            id: 'shelfPercent',
            label: 'dealer.field.shelfPercent',
          },
          {
            id: 'shelfDay',
            label: 'dealer.field.shelfDay',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <>
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() => action.handleShelfOpen({ ...row, index })}
                />
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdDelete />}
                  onClick={() => {
                    const collectPeriods = [...state.collectPeriods]
                    collectPeriods.splice(index, 1)
                    setState({ ...state, collectPeriods })
                  }}
                />
              </>
            ),
          },
        ]}
        rows={state.collectPeriods}
      />)
  }
}

function getTypes(message) {
  return [
    {
      value: 'SELF_STORAGE',
      label: message({ id: 'dealer.type.SELF_STORAGE' }),
    },
    {
      value: 'DEALER_STORAGE',
      label: message({ id: 'dealer.type.DEALER_STORAGE' }),
    },
  ]
}

function getType(type, message) {
  if (!type) return getTypes(message)[0]
  return {
    value: type,
    label: message({ id: `dealer.type.${type}` }),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  message,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = state.id
      ? await editDealer(state, app, session)
      : await addDealer(state, app, session)
    if (!ok) return

    history.push(`/dealer`)
  },
  addStorage: ({ index, ...storage }) => {
    const storages = [...state.storages]
    if (index === -1) {
      storages.push(storage)
    } else {
      storages.splice(index, 1, storage)
    }
    setState({ ...state, storages })
  },
  addShelfPeriod: ({ index, ...collectPeriod }) => {
    const collectPeriods = [...state.collectPeriods]
    if (index === -1) {
      collectPeriods.push(collectPeriod)
    } else {
      collectPeriods.splice(index, 1, collectPeriod)
    }
    setState({ ...state, collectPeriods })
  },
})

async function getData({ app, session, id }) {
  const input = { type: ['DEPT', 'DEALER_ORG', 'COMPANY', 'WAREHOUSE'] }
  const storageInput = { parentId: id, type: ['STORAGE'] }
  const staffInput = { type: ['SALES'] }
  const variables = { id, input, storageInput, staffInput }
  const query = `
    query($id: ID, $input: LocationQueryInput, $storageInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      staffs(input: $staffInput) {
        id
        name
      }
      locations(input: $input) {
        id
        parentId
        type
        name
        extra
        status
      }
      location(id: $id) {
        id
        parentId
        name
        extra
        status
      }
      childLocations(input: $storageInput) {
        id
        name
        contact
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const { location, locations, childLocations, staffs } = data
  const depts = []
  const orgs = []
  const billings = []
  const shippings = []
  const warehouses = []
  locations.forEach(({ id, parentId, name, type, extra }) => {
    const result = { value: id, label: name }
    switch (type) {
      case 'DEPT':
        depts.push(result)
        break
      case 'DEALER_ORG':
        result.parentId = parentId
        orgs.push(result)
        break
      case 'WAREHOUSE':
        warehouses.push(result)
        break
      default:
        if (extra.type === 'BILLING') billings.push(result)
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
    }
  })
  const extra = location?.extra || {}
  const storages = childLocations.map((item) => ({
    id: item.id,
    name: item.name,
    contact: item.contact,
    code: item.extra.code,
    phone: item.extra.phone,
    cellphone: item.extra.cellphone,
    email: item.extra.email,
    address: item.extra.address,
    shippingId: item.extra.shippingId,
    shippingName: item.extra.shippingName,
    salesId: item.extra.salesId,
    salesName: item.extra.salesName,
    status: item.status,
  }))
  return {
    id: location?.id,
    parentId: location?.parentId,
    name: location?.name,
    code: extra.code,
    ein: extra.ein,
    type: extra.type,
    billingId: extra.billingId,
    phone: extra.phone,
    address: extra.address,
    salesId: extra.salesId,
    salesName: extra.salesName,
    deptId: extra.deptId,
    skipDispatchConfirm: extra.skipDispatchConfirm,
    skipDispatchWarehouseId: extra.skipDispatchWarehouseId,
    warehouses,
    depts,
    orgs,
    dealerOrgs: getDealerOrgs(orgs, extra.deptId),
    billings,
    shippings,
    oldStorages: cloneDeep(storages),
    storages,
    collectPeriods: extra.collectPeriods,
    sales: staffs.map(({ id, name }) => ({ value: id, label: name })),
    contractFromDate: extra.contractFromDate,
    contractToDate: extra.contractToDate,
    contractSignDate: extra.contractSignDate,
    contractAmount: extra.contractAmount,
    issueReceipt: extra.issueReceipt,
    status: location?.status,
  }
}

async function addDealer(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: LocationInput!) {
      addDealer(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editDealer(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: LocationInput!) {
      editDealer(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { oldStorages } = state
  const storages = state.storages.map((item) => ({
    id: item.id,
    type: 'STORAGE',
    name: item.name,
    contact: item.contact,
    extra: {
      code: item.code,
      phone: item.phone,
      cellphone: item.cellphone,
      email: item.email,
      address: item.address,
      shippingId: item.shippingId,
      shippingName: item.shippingName,
      salesId: item.salesId,
      salesName: item.salesName,
    },
    status: item.status,
  }))
  const isKeyEqual = (item, newItem) => {
    const itemKey = `${item.name}::${item.contact}`
    const newItemKey = `${newItem.name}::${newItem.contact}`
    return itemKey === newItemKey
  }
  const isValEqual = (item, newItem) => {
    const { extra } = newItem
    if (item.code !== extra.code) return false
    if (item.phone !== extra.phone) return false
    if (item.cellphone !== extra.cellphone) return false
    if (item.cellphone !== extra.cellphone) return false
    if (item.email !== extra.email) return false
    if (item.shippingId !== extra.shippingId) return false
    if (item.shippingName !== extra.shippingName) return false
    if (!isEqual(item.address, extra.address)) return false
    if (!isEqual(item.salesId, extra.salesId)) return false
    if (!isEqual(item.salesName, extra.salesName)) return false
    if (item.status !== extra.status) return false
    return true
  }
  const diff = getDiff(oldStorages, storages, isKeyEqual, isValEqual)

  return {
    type: 'DEALER',
    parentId: state.dealerOrgId.value,
    name: state.name,
    contact: state.contact,
    extra: {
      code: state.code,
      ein: state.ein,
      type: state.type.value,
      phone: state.phone,
      address: state.address,
      billingId: state.billingId.value,
      billingName: state.billingId.label,
      salesId: state.salesId?.map((item) => item.value),
      salesName: state.salesId?.map((item) => item.label),
      deptId: state.deptId.value,
      skipDispatchConfirm: state.skipDispatchConfirm,
      skipDispatchWarehouseId: state.skipDispatchWarehouseId?.map(
        (item) => item.value,
      ),
      skipDispatchWarehouseName: state.skipDispatchWarehouseId?.map(
        (item) => item.label,
      ),
      contractFromDate: state.contractFromDate,
      contractToDate: state.contractToDate,
      contractSignDate: state.contractSignDate,
      contractAmount: state.contractAmount,
      issueReceipt: state.issueReceipt,
      collectPeriods: state.collectPeriods,
    },
    status: state.isActive ? 'ACTIVE' : 'INACTIVE',
    locationsToAdd: diff.added,
    locationsToEdit: diff.modified.map((item) => item.after),
    locationsToDel: diff.removed.map((item) => item.id),
  }
}
