import React from 'react'
import { TextInput, CreatableSelect } from 'components/form'
import {
  initializeState,
  handleSelectChange,
  handleTextChange,
  validateForm,
  getSelectOption,
} from 'utilities/form'
import { request } from 'utilities/request'
import { addDept } from 'models/salesDeptModal'

export const initialState = (value = {}, data = {}) =>
  initializeState({
    id: value.id || '',
    name: value.name || '',
    salesDepts: data.salesDepts || [],
    salesDeptId: getSelectOption(data.salesDepts, value.parentId, 'id', 'name'),
  })

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
  salesDeptId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="dealerOrg.field.name"
        placeholder="dealerOrg.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    salesDeptId: (
      <CreatableSelect
        id="salesDeptId"
        isClearable={false}
        isSearchable={false}
        label="dealerOrg.field.salesDeptId"
        placeholder="dealerOrg.field.salesDeptId"
        options={state.salesDepts.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        value={state.salesDeptId}
        onChange={async (value) => {
          handleSelectChange('salesDeptId', state, setState, validation)(value)

          if (value && value.__isNew__) {
            value.value = await addDept(value, app, session)
            setState({
              ...state,
              salesDepts: [...state.salesDepts, value],
              salesDeptId: value,
            })
          }
        }}
        errMsg={state.__error__.salesDeptId}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editDealerOrg(state, app, session)
      : await addDealerOrg(state, app, session)

    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  },
})

async function addDealerOrg(value, app, session) {
  const { name, salesDeptId } = value
  const parentId = salesDeptId.value
  const variables = { input: { name, type: 'DEALER_ORG', parentId } }
  const query = `
    mutation($input: LocationInput!) {
      addOrg(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editDealerOrg(value, app, session) {
  const { id, name, salesDeptId } = value
  const parentId = salesDeptId.value
  const variables = { id, input: { name, parentId } }
  const query = `
    mutation($id: ID!, $input: LocationInput!) {
      editOrg(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
