import { request } from './request'

export const fetchAsBlob = (url) => fetch(url).then((resp) => resp.blob())

export async function urlToFile(url) {
  const filename = url.substring(url.lastIndexOf('/') + 1)
  const resp = await fetch(url)
  const blob = await resp.blob()
  const contentType = resp.headers.get('content-type')
  const file = new File([blob], filename, { contentType })
  file.preview = await imageBlobToDataUrl(blob)
  file.path = filename
  return file
}

export async function imageBlobToDataUrl(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => resolve(reader.result)
  })
}

export function dataUrlToFile(dataUrl, filename) {
  const [mime, data] = dataUrl.split(',')
  const type = mime.match(/:(.*?);/)[1]
  const bstr = Buffer.from(data, 'base64').toString('ascii')
  const u8arr = new Uint8Array(bstr.length)
  for (let i = 0; i < bstr.length; i++) u8arr[i] = bstr.charCodeAt(i)
  return new File([u8arr], filename, { type })
}

export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

export const uploadFile = async ({ url, fields, file }) => {
  try {
    const form = new FormData()
    Object.entries(fields).forEach(([key, val]) => {
      form.append(key, val)
    })

    form.append('file', file)
    await fetch(url, { method: 'POST', body: form })
  } catch (e) {
    // TODO: mark upload failed
  }
}

export async function updateImages(app, session, state, id) {
  const { imagesToAdd, imagesToDel } = state

  const result = await Promise.all([
    ...imagesToDel.map(async (image) => deleteImage(app, session, id, image)),
    ...imagesToAdd.map(async (image) => addImage(app, session, id, image)),
  ])
  return !result.some((ok) => !ok)
}

export async function addImage(app, session, id, file) {
  const variables = { id, filename: file.name, contentType: file.type }
  const query = `
    mutation($id: ID!, $filename: String!, $contentType: String!) {
      addFile(id: $id, filename: $filename, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addFile
  uploadFile({ url, fields, file })
  return ok
}

export async function deleteImage(app, session, id, image) {
  const variables = { id, filename: image }
  const query = `
    mutation($id: ID!, $filename: String!) {
      deleteFile(id: $id, filename: $filename) 
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}
