import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { columns, handlers } from 'models/sdjPurchaseDiffList'
import { Button, Section, Table, Toolbar } from 'components/core'
import Page from 'components/Page'
import { MdPrint } from 'react-icons/md'
import { initialPage } from 'utilities/pagination'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const handler = handlers({
    session,
    app,
    state,
    setState,
    message,
    setPagination,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="sdj.title.purchaseDiff" navId="sdjPurchaseDiffList">
      <Section>
        <Toolbar>
          <Button
            variant="default"
            text="btn.print"
            icon={<MdPrint />}
            onClick={handler.handlePrint}
          />
        </Toolbar>
        <Table
          columns={columns({ session, app, state, setState })}
          rows={state}
          pagination={pagination}
        />
      </Section>
    </Page>
  )
}
