import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { Link } from 'components/core'

export const columns = [
  {
    id: 'ticketId',
    label: `field.ticketId`,
    renderHtml: ({ row: { ticketId, ticketType } }) => {
      const type = ticketType.toLowerCase().replace('_', '-')
      return (
        <Link
          variant="primaryLink"
          href={`${process.env.REACT_APP_STOCK_URL}/${type}/${ticketId}/view`}
          target="_blank"
        >
          {ticketId}
        </Link>
      )
    },
  },
  {
    id: 'transDate',
    label: `inventory.field.transDate`,
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
  },
]

export const handlers = ({ session, app, value, setState, setPagination }) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    if (!value.productVariantId) return

    const resp = await getData({ session, app, value, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, value, pagination }) {
  const variables = {
    input: getDataInput({ pagination, value }),
  }
  const query = `
    query($input: TicketQueryInput) {
      inventoryPendingLinkCount(input: $input)
      inventoryPendingLinks(input: $input) {
        ticketId
        ticketType
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryPendingLinks,
    pagination: getPagination(
      page,
      countPerPage,
      data.inventoryPendingLinkCount,
    ),
  }
}

function getDataInput({ pagination, value }) {
  const input = {
    productVariantId: value.productVariantId,
    orderBy: [{ key: 'transDate', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
