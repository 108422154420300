import React from 'react'
import { Center, Text } from 'components/core'
import { FaInbox } from 'react-icons/fa'

export default (props) => (
  <Center
    p={3}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width={1}
    height="100%"
    bg="light.1"
    color="light.3"
    {...props}
  >
    <FaInbox fontSize={48} />
    <Text>No Data</Text>
  </Center>
)
