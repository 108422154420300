import React from 'react'
import { Select, TextArea, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
  getSelectOptions,
} from 'utilities/form'
import { getProduct } from 'actions/product'
import { getAvailableBalance } from 'actions/ticket'
import { fetchBalance } from 'actions/inventory'

export const initialState = (value = {}, data) => {
  const productOptions = getSelectOptions(data.products)
  return {
    id: value.id || '',
    index: value.index,
    products: data.products,
    productOptions,
    models: data.models,
    serialNos: data.serialNos,
    repairDates: data.repairDates,
    ...initializeState({
      productVariantId: getSelectOption(productOptions, value.productVariantId),
      model: value.extra?.model || '',
      serialNo: value.extra?.serialNo || '',
      repairDate: value.extra?.repairDate || '',
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  const { productVariantId, models, serialNos, repairDates } = state
  const modelOptions = getModelOptions(productVariantId, models)
  const serialNoOptions = getSerialNoOptions(productVariantId, serialNos)
  const repairDateOptions = getRepairDateOptions(productVariantId, repairDates)

  return {
    productVariantId: (
      <Select
        id="productVariantId"
        label="field.productName"
        placeholder="field.productName"
        isClearable={false}
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    model:
      modelOptions.length > 0 ? (
        <Select
          id="model"
          label="repair.field.model"
          placeholder="repair.field.model"
          options={modelOptions}
          value={state.model}
          onChange={onSelectChange('model')}
          errMsg={state.__error__.model}
        />
      ) : (
        <TextInput
          id="model"
          label="repair.field.model"
          placeholder="repair.field.model"
          value={state.model}
          onChange={onTextChange('model')}
          errMsg={state.__error__.model}
        />
      ),
    serialNo:
      serialNoOptions.length > 0 ? (
        <Select
          id="serialNo"
          label="repair.field.serialNo"
          placeholder="repair.field.serialNo"
          options={serialNoOptions}
          value={state.serialNo}
          onChange={onSelectChange('serialNo')}
          errMsg={state.__error__.serialNo}
        />
      ) : (
        <TextInput
          id="serialNo"
          label="repair.field.serialNo"
          placeholder="repair.field.serialNo"
          value={state.serialNo}
          onChange={onTextChange('serialNo')}
          errMsg={state.__error__.serialNo}
        />
      ),
    repairDate:
      repairDateOptions.length > 0 ? (
        <Select
          id="repairDate"
          label="repair.field.installDate"
          placeholder="repair.field.installDate"
          options={repairDateOptions}
          value={state.repairDate}
          onChange={onSelectChange('repairDate')}
          errMsg={state.__error__.repairDate}
        />
      ) : (
        <TextInput
          id="repairDate"
          label="repair.field.installDate"
          placeholder="repair.field.installDate"
          value={state.repairDate}
          onChange={onTextChange('repairDate')}
          errMsg={state.__error__.repairDate}
        />
      ),
    memo: (
      <TextArea
        id="memo"
        label="repair.field.memo"
        placeholder="repair.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
        errMsg={state.__error__.memo}
      />
    ),
  }
}

function getModelOptions(productId, models) {
  if (!productId || !models) return []
  const result = models.filter(({ id }) => id === productId.value)
  return result.map(({ model }) => ({ value: model, label: model }))
}

function getSerialNoOptions(productId, products) {
  if (!productId || !products) return []
  const result = products.filter(({ id }) => id === productId.value)
  return result.map(({ serialNo }) => ({ value: serialNo, label: serialNo }))
}

function getRepairDateOptions(productId, products) {
  if (!productId || !products) return []
  const result = products.filter(({ id }) => id === productId.value)
  return result.map(({ repairDate }) => ({
    value: repairDate,
    label: repairDate,
  }))
}

export const handlers = ({ app, session, data, state, setState, action }) => ({
  handleLoad: async (value) => {
    const balance = await getBalance(
      app,
      session,
      data,
      value.productVariantId,
      value.srcLocationId,
      value.index,
    )
    value.balance = balance
    setState(initialState(value, data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { productVariantId, models, serialNos, repairDates } = state
    const product = getProduct(state.products, productVariantId.value)
    const modelOptions = getModelOptions(productVariantId, models)
    const serialNoOptions = getSerialNoOptions(productVariantId, serialNos)
    const repairDateOptions = getRepairDateOptions(
      productVariantId,
      repairDates,
    )
    action.addItem({
      index: state.index,
      id: state.id,
      productVariantId: productVariantId.value,
      productVariantName: product.name,
      extra: {
        model: modelOptions.length > 0 ? state.model.value : state.model,
        serialNo:
          serialNoOptions.length > 0 ? state.serialNo.value : state.serialNo,
        repairDate:
          repairDateOptions.length > 0
            ? state.repairDate.value
            : state.repairDate,
        memo: state.memo,
      },
    })
    action.handleClose()
  },
})

async function getBalance(
  app,
  session,
  data,
  productVariantId,
  warehouseId,
  index,
) {
  const balance = await fetchBalance({
    app,
    session,
    locationType: 'ALL',
    productVariantId,
    oldTicketItems: data.oldTicketItems,
  })

  const availableBalance = getAvailableBalance({
    ticketItems: data.ticketItems,
    oldTicketItems: data.oldTicketItems,
    productVariantId,
    balance,
    index,
    locationKey: 'srcLocationId',
    locationId: warehouseId,
  })

  return availableBalance < 0 ? 0 : availableBalance
}
