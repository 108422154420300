import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
} from 'utilities/form'
import { Select } from 'components/form'

export const initialState = () =>
  initializeState({
    syWarehouseId: {},
    warehouseId: {},
  })

const validation = {
  syWarehouseId: [{ type: 'required', message: 'error.required' }],
  warehouseId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    syWarehouseId: (
      <Select
        isClearable={false}
        label="sy.field.syWarehouseId"
        options={getSyWarehouses()}
        value={state.syWarehouseId}
        onChange={onSelectChange('syWarehouseId')}
        errMsg={state.__error__.syWarehouseId}
      />
    ),
    warehouseId: (
      <Select
        isClearable={false}
        label="plugin.field.warehouse"
        options={data.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
  }
}

function getSyWarehouses() {
  return [
    { value: 'OK', label: '良品倉(進貨-良品)' },
    { value: 'F', label: '福利品倉' },
    { value: 'RET', label: '待處理倉' },
    { value: 'O', label: '客訂倉' },
    { value: 'S', label: '展示倉' },
    { value: 'X', label: '報廢倉' },
  ]
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { warehouseId, syWarehouseId } = state
    action.addWarehouse({
      locationId: warehouseId.value,
      locationName: warehouseId.label,
      code: syWarehouseId.value,
      label: syWarehouseId.label,
    })
    action.handleClose()
    setState(initialState())
  },
})
