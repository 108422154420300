export default {
  'error.transferIn.missingToLocationId': '經銷商為必填欄位',
  'error.ticket.invalidQuantity': '數量有誤',
  'transferIn.title.list': '跨系統調入目錄',
  'transferIn.title.add': '新增跨系統調入單',
  'transferIn.title.edit': '修改跨系統調入單',
  'transferIn.title.view': '跨系統調入單詳情',
  'transferIn.title.addItem': '新增跨系統調入商品',
  'transferIn.title.editItem': '修改跨系統調入商品',
  'transferIn.title.listItems': '跨系統調入商品詳情',
  'transferIn.title.import': '匯入跨系統調入單',
  'transferIn.title.print': '跨系統調入單 - {ticketId}',
  'transferIn.section.product': '商品',
  'transferIn.section.basic': '基礎信息',
  'transferIn.section.importDefault': '預設欄位',
  'transferIn.message.template': '跨系統調入單匯入範本',
  'transferIn.btn.add': '新增跨系統調入',
  'transferIn.btn.addBatch': '新增批號',
  'transferIn.placeholder.import': '請將需匯入的文件拖入此框中...',
  'transferIn.field.id': '單號',
  'transferIn.field.ticketNo': '廠商單號',
  'transferIn.field.transDate': '到貨日',
  'transferIn.field.estimateDate': '跨系統調入日期',
  'transferIn.field.arrivePeriod': '到貨時段',
  'transferIn.field.sales': '業務人員',
  'transferIn.field.memo': '備註',
  'transferIn.field.fromLocation': '取倉',
  'transferIn.field.recipientContact': '聯絡人',
  'transferIn.field.recipientAddress': '收件地址',
  'transferIn.field.recipientEmail': 'Email',
  'transferIn.field.recipientPhone': '電話',
  'transferIn.field.recipientCellphone': '手機',
  'transferIn.field.shipping': '物流',
  'transferIn.field.trackingNo': '物流編號',
  'transferIn.field.toLocation': '轉倉',
  'transferIn.field.spu': '商品名稱',
  'transferIn.field.sku': '品號',
  'transferIn.field.productCode': '通路編碼',
  'transferIn.field.barcode': '產品條碼',
  'transferIn.field.isGift': '贈品',
  'transferIn.field.batchNo': '批號',
  'transferIn.field.expireAt': '效期',
  'transferIn.field.balance': '庫存數量',
  'transferIn.field.deptBalance': '業務數量',
  'transferIn.field.quantity': '數量',
  'transferIn.field.batchQuantity': '鎖批數量',
  'transferIn.field.price': '金額',
  'transferIn.field.status': '狀態',
  'transferIn.arrivePeriod.AM': '早上',
  'transferIn.arrivePeriod.PM': '下午',
  'transferIn.status.ACTIVE': '已完成',
  'transferIn.status.INACTIVE': '已刪除',
  'transferIn.status.PENDING': '待審核',
  'transferIn.status.ACTION_PENDING': '待到貨',
}
