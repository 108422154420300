import React from 'react'
import { Flex } from 'reflexbox'

export default ({ show = true, bottom = false, children, ...props }) => {
  if (!show) return null

  return (
    <Flex
      py={2}
      justifyContent="flex-start"
      alignItems="center"
      sx={getBorderStyle(bottom)}
      {...props}
    >
      {children}
    </Flex>
  )
}

function getBorderStyle(bottom) {
  if (bottom) {
    return {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'light.3',
    }
  }

  return {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: 'light.3',
  }
}
