import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { Link, Text } from 'components/core'
import { selectFilter } from 'utilities/filter'

export const filters = ({ message }) => [
  {
    id: 'transDate',
    label: 'field.date',
    input: (props) =>
      selectFilter({
        ...props,
        options: [{ value: true, label: message({ id: 'field.empty' }) }],
      }),
  },
]

export const columns = (message) => [
  {
    id: 'ticketId',
    label: `field.ticketNo`,
    render: ({ row: { ticketType, ticketId } }) => {
      const type = ticketType.toLowerCase().replace('_', '-')
      return (
        <Link
          variant="primaryLink"
          href={`${process.env.REACT_APP_STOCK_URL}/${type}/${ticketId}/view`}
          target="_blank"
        >
          {ticketId}
        </Link>
      )
    },
  },
  {
    id: 'ticketType',
    label: `field.type`,
    render: ({ row: { ticketType } }) => {
      return message({ id: `ticketType.${ticketType}` })
    },
  },
  {
    id: 'transDate',
    label: `inventory.field.transDate`,
    render: ({ row }) => {
      const data = showDate(row.transDate)
      return data === '3000-01-01' ? '' : data
    },
  },
  // {
  //   id: 'locationName',
  //   label: `inventory.field.warehouse`,
  // },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right',
    render: ({ row: { quantity } }) =>
      quantity >= 0 ? quantity : <Text color="error.1">{quantity}</Text>,
  },
]

export const handlers = ({
  setState,
  session,
  app,
  value,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ filterValues, pagination = {} } = {}) => {
    if (!value.productVariantId) return

    const resp = await getData({
      session,
      app,
      value,
      pagination,
      filterValues,
    })
    setState(resp.data)
    setPagination(resp.pagination)
    setFilterValues(filterValues)
  },
})

async function getData({ session, app, value, pagination, filterValues }) {
  const variables = { input: getDataInput({ filterValues, pagination, value }) }
  const query = `
    query($input: InventoryQueryInput!) {
      inventoryLinkCount(input: $input)
      inventoryLinks(input: $input) {
        ticketId
        ticketType
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryLinks,
    pagination: getPagination(page, countPerPage, data.inventoryLinkCount),
  }
}

function getDataInput({ filterValues = [], pagination, value }) {
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const input = {
    locationId: value.locationId,
    productVariantId: value.productVariantId,
    orderBy: [{ key: 'createdAt', sort: 'DESC' }],
  }
  if (value.locationType) input.locationType = value.locationType
  if (transDate?.value.value === true) {
    input.transDate = ['3000-01-01', '3000-01-01']
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
