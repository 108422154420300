import React from 'react'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'
import { Button, Center, Text, Icon } from 'components/core'
import { MdDelete, MdEdit, MdErrorOutline } from 'react-icons/md'
import { request } from 'utilities/request'
import { validateRows } from 'utilities/form'
import { getTag } from 'actions/token'

export const initialState = (value) => ({
  ticketItems: value.ticketItems || [],
  errorItems: [],
})

const validation = {
  ticketId: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'dispatch.field.ticketId' },
      },
    },
  ],
  productVariantId: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'dispatch.field.spu' },
      },
    },
  ],
}

export const columns = ({ session, state, setState, message, action }) => {
  const renderText = (id, label, align = 'left') => ({
    id,
    label,
    align,
    cellProps: { py: 2 },
    render: ({ row }) => <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]}</Text>,
  })

  const renderSelect = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]?.label}</Text>
    ),
  })

  return [
    renderText('ticketId', 'dispatch.field.ticketId'),
    renderSelect('productVariantId', 'dispatch.field.spu'),
    renderText('serial', 'dispatch.field.serial'),
    {
      id: 'actions',
      align: 'right',
      headProps: {
        width: '100px',
        height: '56px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          top: 0,
          right: 0,
          zIndex: 1,
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      cellProps: {
        py: 2,
        width: '100px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          right: 0,
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'light.3',
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      render: ({ row, index }) => {
        const err = state.errorItems.find(
          ({ ticketId }) => ticketId === row.ticketId,
        )
        return (
          <Center>
            <Icon
              color={err ? 'error.1' : 'light.3'}
              px={1}
              tooltip={err?.message}
              tooltipProps={{ place: 'bottom', type: 'error' }}
              value={<MdErrorOutline />}
            />
            <Button
              px={1}
              variant="icon"
              icon={<MdEdit />}
              onClick={() => action.handleOpen({ ...row, index })}
            />
            <Button
              variant="icon"
              icon={<MdDelete />}
              onClick={() => {
                const item = {
                  open: true,
                  title: { id: 'dispatch.title.deleteImportItem' },
                  text: { id: 'dispatch.message.deleteImportItem' },
                  onSubmit: () => {
                    const ticketItems = [...state.ticketItems]
                    ticketItems.splice(index, 1)
                    setState({ ...state, ticketItems })
                    return true
                  },
                }
                session.dispatch({ type: CONFIRM_SET, item })
              }}
            />
          </Center>
        )
      },
    },
  ]
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  history,
  setDisabled,
  setProgress,
}) => ({
  handleLoad: async () => {},
  handleSubmit: async (event) => {
    event.preventDefault()

    setDisabled(true)

    const { ticketItems } = state
    const inputErrors = validateRows(ticketItems, validation)
    if (inputErrors.length > 0) {
      setState({ ...state, errorItems: inputErrors })
      setDisabled(false)
      return
    }

    const tickets = getSubmitInput(state)
    const ticketCount = tickets.length
    const errorItems = []
    let successCount = 0

    try {
      for (let i = 0; i < ticketCount; i++) {
        const ticket = tickets[i]
        const { ticketId, input } = ticket
        const tag = await getTag({ session, app })
        const [ok, err] = await editDispatchSerial(
          app,
          ticketId,
          input,
          tag,
        )

        if (ok) {
          successCount++
        } else {
          errorItems.push({ ticketId, message: err[0].message })
        }

        setProgress(Math.floor((i / ticketCount) * 100))
      }

      if (successCount > 0) {
        session.dispatch({
          type: ALERT_ADD,
          item: {
            type: 'success',
            message: {
              id: 'submit.batch.success',
              values: { count: successCount },
              texts: { name: 'module.dispatch' },
            },
          },
        })
      }

      if (errorItems.length === 0) {
        history.push('/dispatch')
        return
      }

      const idSet = new Set(errorItems.map(({ ticketId }) => ticketId))
      setState({
        ...state,
        ticketItems: ticketItems.filter(({ ticketId }) => idSet.has(ticketId)),
        errorItems,
      })
    } finally {
      setDisabled(false)
      setProgress(null)
    }
  },
  editTicketItem: ({ index, ...value }) => {
    const ticketItems = [...state.ticketItems]
    ticketItems.splice(index, 1, value)

    const errorItems = state.errorItems.filter(
      ({ ticketId }) => ticketId !== value.ticketId,
    )

    setState({ ...state, ticketItems, errorItems })
  },
})

async function editDispatchSerial(app, ticketId, input, tag) {
  const variables = { id: ticketId, input }
  const query = `
    mutation($id: ID!, $input: [TicketSerialInput]!) {
      editDispatchSerial(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { app, tag })
}

function getSubmitInput(state) {
  const { ticketItems } = state
  return ticketItems.reduce((result, item) => {
    let ticket = result.find(({ ticketId }) => ticketId === item.ticketId)
    if (!ticket) {
      ticket = {
        ticketId: `${item.ticketId || ''}`,
        input: [],
      }
      result.push(ticket)
    }
    editTicketItem(ticket, item)
    return result
  }, [])
}

function editTicketItem(ticket, item) {
  ticket.input.push({
    productVariantId: item.productVariantId.value,
    serial: item.serial,
  })
}
