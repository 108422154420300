import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/transportDrawer'
import { Center, Drawer, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, data, readonly, open, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data))
  const form = fields({ app, session, state, setState, data })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    readonly,
    data,
    action,
  })

  useEffect(() => {
    if (open) handler.handleLoad(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Drawer
      width={[1, '460px']}
      title={readonly ? 'transport.title.listItems' : 'transport.title.addItem'}
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.productVariantId}
      {form.quantity}
      {form.memo}
      <Center mt={3}>
        <Button
          show={!readonly}
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button
          show={!readonly}
          type="submit"
          variant="primary"
          text="btn.confirm"
        />
      </Center>
    </Drawer>
  )
}
