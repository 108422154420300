import * as type from 'constants/actionType'

export const initialState = {
  alerts: [],
  loading: false,
  confirmModal: { open: false, title: '', text: '', onSubmit: () => {} },
  customerLookup: { open: false, onSubmit: () => {} },
  filterMap: {},
  paginationMap: {},
}

export function reducer(state, action) {
  switch (action.type) {
    case type.ALERT_ADD:
      return { ...state, alerts: addItem(state.alerts, action.item) }
    case type.ALERT_REMOVE:
      return { ...state, alerts: removeItem(state.alerts, action.idx) }
    case type.LOADING_SHOW:
      return { ...state, loading: true }
    case type.LOADING_HIDE:
      return { ...state, loading: false }
    case type.CONFIRM_SET:
      return { ...state, confirmModal: action.item }
    case type.FILTER_SET:
      return {
        ...state,
        filterMap: setMap(state.filterMap, action.key, action.filterValues),
        paginationMap: setMap(
          state.paginationMap,
          action.key,
          action.pagination,
        ),
      }
    case type.CUSTOMER_LOOKUP_SET:
      return { ...state, customerLookup: action.item }
    default:
      return state
  }
}

function addItem(list, data) {
  return [...list, data]
}

function removeItem(list, idx) {
  const result = [...list]
  result.splice(idx, 1)
  return result
}

function setMap(map, key, value) {
  const result = { ...map }
  result[key] = value
  return result
}
