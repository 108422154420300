import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'

export const handlers = ({ app, session, data, action, setDisabled }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()

    setDisabled(true)
    const { syncType } = data
    const [ok] = await sync(app, session, syncType)
    setDisabled(false)

    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'vicInvoice.message.syncSuccess' },
    })
    action.handleClose()
  },
})

async function sync(app, session, syncType) {
  switch (syncType) {
    case 'syncProduct':
      return syncProducts({ app, session })
    case 'syncDealer':
      return syncDealers({ app, session })
    case 'syncStorage':
      return syncStorages({ app, session })
    case 'syncBilling':
      return syncBillings({ app, session })
    case 'syncSales':
      return syncSales({ app, session })
    case 'syncStaff':
      return syncStaffs({ app, session })
    case 'syncPendingOrder':
      return syncPendingOrders({ app, session })
    default:
  }
}

async function syncDealers({ session, app }) {
  const query = `
    mutation {
      syncVicInvoiceDealers
    }
  `
  return request({ query }, { session, app })
}

async function syncStorages({ session, app }) {
  const query = `
    mutation {
      syncVicInvoiceStorages
    }
  `
  return request({ query }, { session, app })
}

async function syncBillings({ session, app }) {
  const query = `
    mutation {
      syncVicInvoiceBillings
    }
  `
  return request({ query }, { session, app })
}

async function syncSales({ session, app }) {
  const query = `
    mutation {
      syncVicInvoiceSales
    }
  `
  return request({ query }, { session, app })
}

async function syncStaffs({ session, app }) {
  const query = `
    mutation {
      syncVicInvoiceStaffs
    }
  `
  return request({ query }, { session, app })
}

async function syncProducts({ session, app }) {
  const query = `
    mutation {
      syncVicInvoiceProducts
    }
  `
  return request({ query }, { session, app })
}

async function syncPendingOrders({ session, app }) {
  const query = `
    mutation {
      syncVicInvoiceOrders
    }
  `
  return request({ query }, { session, app })
}
