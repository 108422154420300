import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/staffDrawer'
import { Center, Button, Link, Toolbar, Drawer } from 'components/core'
import PasswordResetModal from 'views/PasswordResetModal'
import { MdArrowBack, MdEdit } from 'react-icons/md'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ open, value, action }) => {
  const id = value.id
  const profile = id ? 'edit' : 'add'
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [passwordOpen, setPasswordOpen] = useState(false)
  const form = fields({ state, setState, message })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    id,
    action,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <Drawer
        title={`staff.title.${profile}`}
        open={open}
        onClose={action.handleClose}
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/staff"
            />
            <Button type="submit" variant="primary" ml={3} text="btn.save" />
          </Center>
        }
      >
        <Toolbar show={!!id}>
          <Button
            variant="default"
            icon={<MdEdit />}
            text="staff.btn.resetPassword"
            onClick={() => setPasswordOpen(true)}
          />
        </Toolbar>
        {form.name}
        {form.username}
        {!id && form.password}
        {form.type}
        {form.email}
        {form.isActive}
        <Center mt={3}>
          <Button
            variant="secondary"
            mr={2}
            text="btn.close"
            onClick={action.handleClose}
          />
          <Button type="submit" variant="primary" text="btn.save" />
        </Center>
      </Drawer>
      <PasswordResetModal
        open={passwordOpen}
        value={{ staffId: id }}
        action={{ setOpen: setPasswordOpen }}
      />
    </>
  )
}
