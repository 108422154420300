import React from 'react'
import { NumberInput, Select, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
} from 'utilities/form'
import { fetchBalance } from 'actions/inventory'
import { Definition } from 'components/core'
import { getAvailableBalance } from 'actions/ticket'
import MaskInput from 'components/form/MaskInput'

export const initialState = (value = {}, data) => {
  const productOptions = getProductOptions(data.processType, data.products)
  const unit = parseFloat(value.unit || 1)
  const srcQuantity = parseInt(value.srcQuantity || 0)
  const quantity = value.srcQuantity
    ? Math.round(srcQuantity * unit)
    : parseInt(value.quantity || 1)
  return {
    index: value.index,
    isFrom: value.isFrom,
    id: value.id,
    balance: value.balance || 0,
    srcQuantity,
    quantity,
    productOptions,
    ...initializeState({
      productVariantId: getSelectOption(productOptions, value.productVariantId),
      fromLocationId: getSelectOption(data.warehouses, value.fromLocationId),
      toLocationId: getSelectOption(data.warehouses, value.toLocationId),
      unit,
      ticketNo: value.ticketNo || '',
      batchNo: value.batchNo || '',
      expireAt: value.expireAt || '',
    }),
  }
}

const validation = (isPack) => {
  const result = {
    productVariantId: [{ type: 'required', message: 'error.required' }],
  }

  if (isPack) {
    result.fromLocationId = [{ type: 'required', message: 'error.required' }]
  } else {
    result.toLocationId = [{ type: 'required', message: 'error.required' }]
  }

  return result
}

export const fields = ({ app, session, data, state, setState }) => {
  const { isPack } = data
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation(isPack), callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation(isPack), callback)

  return {
    productVariantId: (
      <Select
        label="process.field.product"
        isClearable={false}
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId', async ({ value }) => {
          if (isPack) return {}

          const fromLocationId = state.fromLocationId?.value
          if (!fromLocationId || !value) return { balance: 0 }

          const balance = await fetchBalance({
            app,
            session,
            locationType: 'WAREHOUSE',
            locationId: fromLocationId,
            productVariantId: value,
            oldTicketItems: data.oldTicketItems,
          })

          return { balance }
        })}
        errMsg={state.__error__.productVariantId}
      />
    ),
    fromLocationId: (
      <Select
        show={isPack}
        label="process.field.fromWarehouse"
        isClearable={false}
        options={data.warehouses}
        value={state.fromLocationId}
        onChange={onSelectChange('fromLocationId', async ({ value }) => {
          const productVariantId = state.productVariantId?.value
          if (!productVariantId || !value) return { balance: 0 }

          const balance = await fetchBalance({
            app,
            session,
            locationType: 'WAREHOUSE',
            locationId: value,
            productVariantId,
            oldTicketItems: state.oldTicketItems,
          })
          return { balance }
        })}
        errMsg={state.__error__.fromLocationId}
      />
    ),
    toLocationId: (
      <Select
        id="toLocationId"
        show={!isPack}
        label="process.field.toWarehouse"
        isClearable={false}
        options={data.warehouses}
        value={state.toLocationId}
        onChange={onSelectChange('toLocationId')}
        errMsg={state.__error__.toLocationId}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="process.field.ticketNo"
        placeholder="process.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
      />
    ),
    batchNo: (
      <TextInput
        id="batchNo"
        label="process.field.batchNo"
        placeholder="process.field.batchNo"
        value={state.batchNo}
        onChange={onTextChange('batchNo')}
      />
    ),
    expireAt: (
      // <DateInput
      //   id="expireAt"
      //   label="process.field.expireAt"
      //   value={state.expireAt}
      //   onChange={onTextChange('expireAt')}
      // />
      <MaskInput
        label="process.field.expireAt"
        mask="9999/99/99"
        value={state.expireAt}
        onChange={onTextChange('expireAt')}
      />
    ),
    balance: (
      <Definition
        show={isPack}
        direction="row"
        label="inventory.field.balance"
        value={state.balance}
      />
    ),
    unit: (
      <NumberInput
        id="unit"
        min={0.000001}
        label="process.field.unit"
        step={0.000001}
        value={state.unit}
        onChange={onTextChange('unit', (value) => {
          const unit = parseFloat(value || 0)
          const quantity = Math.round(state.srcQuantity * unit)
          return { quantity }
        })}
        errMsg={state.__error__.unit}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min={1}
        max={getMaxQuantity(state, data)}
        label="process.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
  }
}

function getProductOptions(processType, products) {
  if (!products) return []

  return (
    products
      // .filter(({ type }) => {
      //   if (processType === 'PACK_SINGLE') return true
      //   if (processType === 'PACK_COMBO') return type === 'SINGLE'
      //   if (processType === 'UNPACK_SINGLE') return type === 'SINGLE'
      //   return type === 'COMBO'
      // })
      .map(({ id, name }) => ({ value: id, label: name }))
  )
}

export const handlers = ({ state, setState, data, action }) => ({
  handleLoad: async (value) => {
    if (!value) return
    setState(initialState(value, data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    const isValid = validateForm({
      state,
      setState,
      validation: validation(data.isPack),
    })
    if (!isValid) return

    action.handleSubmit({
      id: state.id,
      index: state.index,
      productVariantId: state.productVariantId?.value,
      productVariantName: state.productVariantId?.label,
      fromLocationId: state.fromLocationId?.value,
      fromLocationName: state.fromLocationId?.label,
      toLocationId: state.toLocationId?.value,
      toLocationName: state.toLocationId?.label,
      ticketNo: state.ticketNo,
      balance: state.balance,
      unit: state.unit,
      quantity: state.quantity,
      srcQuantity: state.srcQuantity,
      batchNo: state.batchNo,
      expireAt: state.expireAt,
    })
    action.handleClose()
  },
  enableSubmit: () => {
    const { quantity } = state
    if (!data.isPack) return quantity > 0

    const availableBalance = getMaxQuantity(state, data)
    return quantity > 0 && availableBalance >= quantity
  },
})

function getMaxQuantity(state, data) {
  if (!data.isPack) return Number.POSITIVE_INFINITY

  const productVariantId = state.productVariantId?.value
  if (!productVariantId) return 0

  return getAvailableBalance({
    ticketItems: data.ticketItems,
    oldTicketItems: data.oldTicketItems,
    productVariantId,
    balance: state.balance,
    index: state.index,
  })
}
