import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { PhoneInput, TextInput } from 'components/form'

export const initialState = (value) => {
  const { index, phone, cellphone, contact } = value.phoneValue || {}
  const isPhone = value.phoneType === 'PHONE'
  return {
    id: value.id,
    index,
    type: value.phoneType,
    ...initializeState({
      phone: (isPhone ? phone : cellphone) || '',
      name: contact || '',
    }),
  }
}

const validation = {
  phone: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const phoneType = state.type.toLowerCase()
  return {
    phone: (
      <PhoneInput
        id="phone"
        type={phoneType}
        label={`customer.field.${phoneType}`}
        value={state.phone}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    name: (
      <TextInput
        id="name"
        label="customer.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
  }
}

export const handlers = ({ session, app, state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await addPhone(state, app, session)
    if (!ok) return

    action.handleLoad()
    action.handleClose()
  },
})

async function addPhone(state, app, session) {
  const variables = {
    id: state.id,
    index: state.index,
    type: state.type,
    phone: state.phone,
    name: state.name,
  }
  const query = `
    mutation($id: ID!, $phone: String!, $type: PhoneType!, $name: String, $index: Int) {
      addCustomerPhone(id: $id, phone: $phone, type: $type, name: $name, index: $index)
    }
  `
  return request({ query, variables }, { session, app })
}
