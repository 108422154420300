import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getSelectOption,
  getDate,
  showDate,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { filterLocations } from 'utilities/permission'
import { Flex } from 'reflexbox'
import { Text, Definition, Button, Table } from 'components/core'
import { TextInput, TextArea, Select, DateInput } from 'components/form'
import { MdEdit, MdDelete } from 'react-icons/md'
import { printHtml } from 'utilities/print'
import { getLocation } from 'actions/location'
import { fetchBalances } from 'actions/inventory'
import { getDisplayQuantity, handleDelete, setBalances } from 'actions/ticket'

export const initialState = (value = {}) => ({
  isReady: value.isReady || false,
  id: value.id,
  locked: value.locked || false,
  status: value.status || 'PENDING',
  hash: value.hash,
  createdBy: value.createdBy,
  createdAt: value.createdAt,
  updatedBy: value.updatedBy,
  updatedAt: value.updatedAt,
  fromLocationName: value.fromLocationName || '',
  oldTicketItems: value.oldTicketItems || [],
  ticketItems: value.ticketItems || [],
  srcCompanies: value.srcCompanies,
  billCompanies: value.billCompanies,
  warehouses: value.warehouses,
  products: value.products || [],
  ...initializeState({
    ticketNo: value.ticketNo || '',
    transDate: getDate(value.transDate),
    fromLocationId: getSelectOption(value.warehouses, value.fromLocationId),
    srcCompanyId: getSelectOption(
      value.srcCompanies,
      value.extra?.srcCompanyId,
    ),
    srcCompanyName: value.extra?.srcCompany?.name || '',
    billCompanyId: getSelectOption(
      value.billCompanies,
      value.extra?.billCompanyId,
    ),
    billCompanyName: value.extra?.billCompany?.name || '',
    rackNo: value.extra?.rackNo || '',
    sealNo: value.extra?.sealNo || '',
    memo: value.extra?.memo || '',
  }),
})

const validation = {
  fromLocationId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

const defs = (state) => ({
  id: state.id,
  ticketNo: state.ticketNo,
  transDate: state.transDate,
  fromLocation: state.fromLocationName,
  srcCompany: state.srcCompanyName,
  billCompany: state.billCompanyName,
  rackNo: state.rackNo,
  sealNo: state.sealNo,
  memo: state.memo,
})

const columns = [
  {
    id: 'productVariantName',
    label: 'transferOut.field.spu',
  },
  {
    id: 'toLocationName',
    label: 'transferOut.field.warehouseId',
  },
  {
    id: 'purchasedQuantity',
    label: 'transferOut.field.purchasedQuantity',
    align: 'right',
    render: ({ row }) => row.extra?.purchasedQuantity,
  },
  {
    id: 'arrivedQuantity',
    label: 'transferOut.field.quantity',
    align: 'right',
    // render: ({ row }) => getQuantity(row),
    render: ({ row }) => row.extra?.arrivedQuantity,
  },
  {
    id: 'batchNo',
    label: 'transferOut.field.batchNo',
    renderHtml: ({ row }) => (
      <Flex flexDirection="column">
        {row.extra.batchItems?.map(({ batchNo }, index) =>
          renderBatchItem(batchNo, index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra.batchItems
        ?.map(({ batchNo }) => batchNo || '\u2014')
        .join('<br/>'),
  },
  {
    id: 'expireAt',
    label: 'transferOut.field.expireAt',
    noWrap: true,
    renderHtml: ({ row }) => (
      <Flex flexDirection="column">
        {row.extra.batchItems?.map(({ expireAt }, index) =>
          renderBatchItem(showDate(expireAt), index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra.batchItems
        ?.map(({ expireAt }) => showDate(expireAt) || '\u2014')
        .join('<br/>'),
  },
  {
    id: 'lockQuantity',
    label: 'transferOut.field.lockQuantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Flex flexDirection="column" justifyContent="flex-end">
        {row.extra.batchItems?.map(({ quantity }, index) =>
          renderBatchItem(quantity, index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra.batchItems?.map(({ quantity }) => quantity).join('<br/>'),
  },
]

export const labels = ({ state }) => {
  const content = Object.entries(defs(state)).reduce((result, [id, value]) => {
    const label = `transferOut.field.${id}`
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.product = <Table showSeq columns={columns} rows={state.ticketItems} />
  return content
}

export const fields = ({ app, session, state, setState, profile, action }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="transferOut.field.transDate"
        value={state.transDate}
        role="lockTransferOutTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="transferOut.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
      />
    ),
    fromLocation: (
      <Select
        label="transferOut.field.fromLocation"
        isClearable={false}
        options={state.warehouses}
        value={state.fromLocationId}
        onChange={onSelectChange('fromLocationId', ({ value }) =>
          onFromLocationChange(state, app, session, value),
        )}
        errMsg={state.__error__.fromLocationId}
      />
    ),
    srcCompany: (
      <Select
        label="transferOut.field.srcCompany"
        isSearchable={false}
        isClearable={false}
        options={state.srcCompanies}
        value={state.srcCompanyId}
        onChange={onSelectChange('srcCompanyId')}
      />
    ),
    billCompany: (
      <Select
        label="transferOut.field.billCompany"
        isSearchable={false}
        isClearable={false}
        options={state.billCompanies}
        value={state.billCompanyId}
        onChange={onSelectChange('billCompanyId')}
      />
    ),
    rackNo: (
      <TextInput
        id="rackNo"
        label="transferOut.field.rackNo"
        value={state.rackNo}
        onChange={onTextChange('rackNo')}
      />
    ),
    sealNo: (
      <TextInput
        id="sealNo"
        label="transferOut.field.sealNo"
        value={state.sealNo}
        onChange={onTextChange('sealNo')}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="transferOut.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    product: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'transferOut.field.spu',
          },
          {
            id: 'toLocationName',
            label: 'transferOut.field.warehouseId',
          },
          {
            id: 'balance',
            label: 'inventory.field.balance',
            align: 'right',
          },
          {
            id: 'purchasedQuantity',
            label: 'transferOut.field.purchasedQuantity',
            align: 'right',
            render: ({ row }) =>
              getDisplayQuantity(row.extra?.purchasedQuantity, row.balance),
          },
          {
            id: 'batchNo',
            label: 'transferOut.field.batchNo',
            render: ({ row }) => (
              <Flex flexDirection="column">
                {row.extra.batchItems?.map(({ batchNo }, index) =>
                  renderBatchItem(batchNo, index),
                )}
              </Flex>
            ),
          },
          {
            id: 'expireAt',
            label: 'transferOut.field.expireAt',
            noWrap: true,
            render: ({ row }) => (
              <Flex flexDirection="column">
                {row.extra.batchItems?.map(({ expireAt }, index) =>
                  renderBatchItem(showDate(expireAt), index),
                )}
              </Flex>
            ),
          },
          {
            id: 'lockQuantity',
            label: 'transferOut.field.lockQuantity',
            align: 'right',
            render: ({ row }) => (
              <Flex flexDirection="column" justifyContent="flex-end">
                {row.extra.batchItems?.map(({ quantity }, index) =>
                  renderBatchItem(quantity, index),
                )}
              </Flex>
            ),
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <>
                <Button
                  mr={2}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() =>
                    action.handleOpen({
                      ...row,
                      oldTicketItem: state.oldTicketItems[index],
                      index,
                    })
                  }
                />
                <Button
                  variant="icon"
                  icon={<MdDelete />}
                  onClick={() => {
                    const ticketItems = [...state.ticketItems]
                    ticketItems.splice(index, 1)
                    setState({ ...state, ticketItems })
                  }}
                />
              </>
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

function renderBatchItem(value, index) {
  return (
    <Text
      key={index}
      mt={index === 0 ? 0 : 2}
      color={value ? 'dark.1' : 'light.3'}
    >
      {value ? value : '\u2014'}
    </Text>
  )
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  message,
  history,
  profile,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, profile, id })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editTransfer(state, app, session)
      : await addTransfer(state, app, session)
    if (!ok) return

    if (!id) {
      id = data.addTransferOutTicket
    }

    history.push(`/transfer-out/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmTransfer({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })
    return true
  },
  addItem: (value) => {
    const ticketItems = cloneDeep(state.ticketItems)
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('transfer_out', { session, app, id, hash })
    if (!ok) return false

    history.push('/move')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ state }) {
  const title = {
    id: 'transferOut.title.print',
    values: { ticketId: state.id },
  }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const field = Object.entries(defs(state)).map(([id, value]) => ({
    label: `transferOut.field.${id}`,
    value,
  }))
  const list = { columns, rows: state.ticketItems }
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: list },
  ]
  return { title, header, content }
}

async function getData({ app, session, profile, id }) {
  const locationInput = { type: ['WAREHOUSE', 'COMPANY'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra {
          type
        }
      }
      productVariants {
        id
        name
        sku
      }
      transferOutTicket(id: $id) {
        ticketNo
        fromLocationId
        fromLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      transferOutTicketItems(ticketId: $id) {
        id
        toLocationId
        toLocationName
        productVariantId
        productVariantName
        sku
        quantity
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.transferOutTicket || {}
  const ticketItems = data.transferOutTicketItems || []
  const oldTicketItems = cloneDeep(ticketItems)
  const { extra = {} } = ticket
  const srcCompanies = []
  const billCompanies = []
  const warehouses = []

  locations.forEach(({ id, name, type, extra }) => {
    if (type === 'COMPANY' && extra.type === 'PURCHASE') {
      srcCompanies.push({ value: id, label: name })
    }
    if (type === 'COMPANY' && extra.type === 'BILLING') {
      billCompanies.push({ value: id, label: name })
    }
    if (type === 'WAREHOUSE') {
      warehouses.push({ value: id, label: name })
    }
  })

  if (profile === 'edit' && ticketItems.length > 0) {
    const productVariantId = ticketItems.map((item) => item.productVariantId)
    const balances = await fetchBalances({
      app,
      session,
      locationType: 'WAREHOUSE',
      locationId: ticket.fromLocationId,
      productVariantId,
      oldTicketItems,
    })
    setBalances(ticketItems, balances)
  }

  return {
    isReady: profile === 'edit',
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    locked: ticket.extra?.locked,
    ticketNo: ticket.ticketNo,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    // srcCompanyId: extra.srcCompany?.id,
    // srcCompanyName: extra.srcCompany?.name,
    // billCompanyId: extra.billCompany?.id,
    // billCompanyName: extra.billCompany?.name,
    transDate: ticket.transDate,
    // rackNo: extra.rackNo,
    // sealNo: extra.sealNo,
    // memo: extra.memo,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems,
    oldTicketItems,
    srcCompanies,
    billCompanies,
    warehouses,
    products: productVariants,
  }
}

async function addTransfer(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addTransferOutTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editTransfer(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editTransferOutTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function confirmTransfer({ session, app, id, hash }) {
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmTransferOutTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    toLocationId: item.toLocationId,
    productVariantId: item.productVariantId,
    quantity: item.quantity,
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.toLocationId !== newItem.toLocationId) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    transDate: state.transDate,
    ticketNo: state.ticketNo,
    fromLocationId: state.fromLocationId.value,
    extra: {
      isExternal: true,
      srcCompany: {
        id: state.srcCompanyId.value,
        name: state.srcCompanyId.label,
      },
      billCompany: {
        id: state.billCompanyId.value,
        name: state.billCompanyId.label,
      },
      rackNo: state.rackNo,
      sealNo: state.sealNo,
      memo: state.memo,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function onFromLocationChange(state, app, session, locationId) {
  const { locations } = state
  const ticketItems = [...state.ticketItems]
  if (ticketItems.length === 0) return []

  const location = getLocation(locations, locationId)
  const productVariantId = ticketItems.map((item) => item.productVariantId)
  const balances = await fetchBalances({
    app,
    session,
    locationType: location.type,
    locationId: location.id,
    productVariantId,
    oldTicketItems: state.oldTicketItems,
  })
  setBalances(ticketItems, balances)

  return { ticketItems }
}
