export default {
  'warehouseGroup.title.list': '倉庫群目錄',
  'warehouseGroup.title.add': '新增倉庫群',
  'warehouseGroup.title.edit': '更改倉庫群',
  'warehouseGroup.title.delete': '刪除倉庫群',
  'warehouseGroup.message.delete': '確認刪除倉庫群?',
  'warehouseGroup.field.name': '倉別群名稱',
  'warehouse.title.list': '倉庫目錄',
  'warehouse.title.add': '新增倉庫',
  'warehouse.title.edit': '更改倉庫',
  'warehouse.title.delete': '刪除倉庫',
  'warehouse.message.delete': '確認刪除倉庫?',
  'warehouse.field.name': '倉別名稱',
  'warehouse.field.isStock': '可用',
  'warehouse.field.isLockable': '可鎖',
  'warehouse.field.status': '啟用',
  'warehouse.status.ACTIVE': '使用中',
  'warehouse.status.INACTIVE': '停用',
}
