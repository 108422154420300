import React from 'react'
import { Link } from 'components/core'
import { getDate, showDate } from 'utilities/form'
import { request } from 'utilities/request'
import {
  initialState as getPurchaseState,
  getPrintData as getPurchasePrintData,
} from 'models/purchasePage'
import {
  initialState as getLockState,
  getPrintData as getLockPrintData,
} from 'models/lockPage'
import {
  initialState as getReverseState,
  getPrintData as getReversePrintData,
} from 'models/reversePage'
import {
  initialState as getDispatchState,
  getPrintData as getDispatchPrintData,
} from 'models/dispatchPage'
import {
  initialState as getSellState,
  getPrintData as getSellPrintData,
} from 'models/sellPage'
import {
  initialState as getRefundState,
  getPrintData as getRefundPrintData,
} from 'models/refundPage'
import {
  initialState as getProcessState,
  getPrintData as getProcessPrintData,
} from 'models/processPage'
import {
  initialState as getAdjustState,
  getPrintData as getAdjustPrintData,
} from 'models/adjustPage'
import {
  initialState as getMoveState,
  getPrintData as getMovePrintData,
} from 'models/movePage'
import {
  initialState as getTransferOutState,
  getPrintData as getTransferOutPrintData,
} from 'models/transferOutPage'
import {
  initialState as getTransferInState,
  getPrintData as getTransferInPrintData,
} from 'models/transferInPage'
import {
  initialState as getDeferState,
  getPrintData as getDeferPrintData,
} from 'models/deferPage'
import {
  initialState as getLendState,
  getPrintData as getLendPrintData,
} from 'models/lendPage'
import {
  initialState as getRepairState,
  getPrintData as getRepairPrintData,
} from 'models/repairPage'
import { ALERT_ADD } from 'constants/actionType'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { printHtml } from 'utilities/print'
import { formatDate, yesterday } from 'utilities/date'

export const columns = (message) => [
  {
    id: 'id',
    label: 'field.ticketId',
    format: ['html', 'excel'],
    render: ({ row: { ticketType, id } }) => {
      const type = ticketType.toLowerCase().replace('_', '-')
      return (
        <Link
          variant="primaryLink"
          href={`${process.env.REACT_APP_STOCK_URL}/${type}/${id}/view`}
          target="_blank"
        >
          {id}
        </Link>
      )
    },
  },
  {
    id: 'ticketType',
    label: `field.type`,
    format: ['html', 'excel'],
    render: ({ row: { ticketType } }) => {
      return message({ id: `ticketType.${ticketType}` })
    },
  },
  {
    id: 'createdAt',
    label: 'ticket.createdAt',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.createdAt),
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    format: ['html', 'excel'],
  },
  {
    id: 'updatedAt',
    label: 'ticket.updatedAt',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.updatedAt),
  },
  {
    id: 'updatedBy',
    label: 'ticket.updatedBy',
    format: ['html', 'excel'],
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  fromDate,
  toDate,
  priorDay,
}) => ({
  handleLoad: async () => {
    const data = await getData({ session, app, fromDate, toDate, priorDay })
    setState(data)
  },
  handleExport: async () => {
    const staff = app.state.staff
    const zip = new JSZip()
    const {
      rows = [],
      productVariants,
      locations,
      repairTypes,
      repairRemarks,
    } = await getExportData({ session, app, fromDate, toDate, priorDay })

    const refIdList = getRefIdList(rows)
    const { rows: refRows = [] } = await getExportData({
      session,
      app,
      parentId: refIdList,
      fromDate,
      toDate,
      priorDay,
    })
    const allRows = mergeExportData(rows, refRows)

    for (const row of allRows) {
      let data = null

      switch (row.ticketType) {
        case 'PURCHASE':
          data = getPurchasePrintData({
            app,
            message,
            state: getPurchaseState(row),
          })
          break
        case 'LOCK':
          data = getLockPrintData({
            app,
            message,
            state: getLockState(row, message),
          })
          break
        case 'REVERSE':
          data = getReversePrintData({
            app,
            message,
            state: getReverseState(row, message),
          })
          break
        case 'DISPATCH':
          data = getDispatchPrintData({
            app,
            message,
            state: getDispatchState(row, {}, message),
          })
          break
        case 'SELL':
          data = getSellPrintData({
            app,
            message,
            state: getSellState(row, message),
          })
          break
        case 'REFUND':
          data = getRefundPrintData({
            app,
            message,
            state: getRefundState(row, { productVariants }, message),
          })
          break
        case 'PROCESS':
          data = getProcessPrintData({
            profile: 'view',
            message,
            state: getProcessState(row, { staff, locations }, message),
          })
          break
        case 'ADJUST':
          data = getAdjustPrintData({
            app,
            message,
            state: getAdjustState(row, message),
          })
          break
        case 'MOVE':
          data = getMovePrintData({
            app,
            message,
            state: getMoveState(row, app.state.staff, message),
          })
          break
        case 'TRANSFER_OUT':
          data = getTransferOutPrintData({
            state: getTransferOutState(row),
          })
          break
        case 'TRANSFER_IN':
          data = getTransferInPrintData({
            message,
            state: getTransferInState(row, message),
          })
          break
        case 'DEFER':
          data = getDeferPrintData({
            app,
            message,
            state: getDeferState(row, message),
          })
          break
        case 'LEND':
          data = getLendPrintData({
            message,
            state: getLendState(row, message),
          })
          break
        case 'REPAIR':
          data = getRepairPrintData({
            app,
            message,
            state: getRepairState(
              { ...row, repairTypes, repairRemarks },
              message,
            ),
          })
          break
        default:
          session.dispatch({
            type: ALERT_ADD,
            item: {
              type: 'error',
              message: {
                id: 'report.error.invalidTicketType',
                values: { ticketId: row.id },
              },
            },
          })
          return
      }

      const blob = printHtml({ ...data, message, onload: '' })
      zip.file(row.id + '.html', blob)
    }

    const title = message({ id: 'report.title.daily' })
    let date = getDate()
    if (priorDay) date = yesterday()
    if (toDate) date = formatDate(new Date(toDate))
    if (fromDate) date = formatDate(new Date(fromDate))

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${title}_${date}.zip`)
    })
  },
})

async function getData({ session, app, fromDate, toDate, priorDay }) {
  const variables = { input: { date: [fromDate, toDate], priorDay } }
  const query = `
    query($input: DailyReportInput) {
      dailyReports(input: $input) {
        id
        ticketType
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  return data.dailyReports
}

async function getExportData({
  session,
  app,
  getConfigs = true,
  parentId = [],
  fromDate,
  toDate,
  priorDay,
}) {
  const locationInput = { type: ['WAREHOUSE', 'DEPT', 'DEALER_ORG'] }
  const productInput = { status: ['ACTIVE', 'INACTIVE'] }
  const input = { joinItem: true, parentId, priorDay, date: [fromDate, toDate] }
  const variables = { input, productInput, locationInput }
  let query = `
    query($input: DailyReportInput, $locationInput: LocationQueryInput, $productInput: ProductQueryInput) {
      ${getConfigs && getConfigQuery()}
      ${getExportDataQuery()}
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const {
    dailyReports,
    productVariants,
    locations,
    repairTypes,
    repairRemarks,
  } = data
  const rows = dailyReports.reduce((result, item) => {
    const ticketItem = {
      id: item.itemId,
      ticketType: item.itemTicketType,
      transDate: showDate(item.itemTransDate),
      productVariantName: item.productVariantName,
      sku: item.sku,
      barcode: item.barcode,
      srcLocationId: item.itemSrcLocationId,
      srcLocationName: item.itemSrcLocationName,
      fromLocationId: item.itemFromLocationId,
      fromLocationName: item.itemFromLocationName,
      toLocationId: item.itemToLocationId,
      toLocationName: item.itemToLocationName,
      quantity: item.quantity,
      price: item.price,
      balance: item.balance,
      isGift: item.isGift,
      extra: item.itemExtra || {},
      status: item.itemStatus,
    }
    const ticket = result.find((i) => i.id === item.id)
    if (ticket) {
      ticket.ticketItems.push(ticketItem)
    } else {
      result.push({
        id: item.id,
        parentId: item.parentId,
        ticketType: item.ticketType,
        ticketNo: item.ticketNo,
        transDate: item.transDate,
        srcLocationName: item.srcLocationName,
        fromLocationName: item.fromLocationName,
        toLocationName: item.toLocationName,
        extra: item.extra || {},
        hash: item.hash,
        status: item.status,
        createdAt: item.createdAt,
        createdBy: item.createdBy,
        updatedAt: item.updatedAt,
        updatedBy: item.updatedBy,
        ticketItems: [ticketItem],
      })
    }
    return result
  }, [])

  return { rows, productVariants, locations, repairTypes, repairRemarks }
}

function getConfigQuery() {
  return `
    locations(input: $locationInput) {
      id
      name
      type
      extra {
        type
      }
    }
    productVariants(input: $productInput) {
      id
      name
      sku
      barcode
      type
      childVariants {
        id
        name
        quantity
      }
    }
    repairTypes {
      id
      name
      extra
    }
    repairRemarks {
      id
      content
    }
  `
}

function getExportDataQuery() {
  return `
    dailyReports(input: $input) {
      id
      parentId
      ticketType
      ticketNo
      transDate
      srcLocationName
      fromLocationName
      toLocationName
      extra
      hash
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      itemId
      itemTicketType
      itemTransDate
      productVariantName
      sku
      barcode
      productCode
      itemSrcLocationId
      itemSrcLocationName
      itemFromLocationId
      itemFromLocationName
      itemToLocationId
      itemToLocationName
      price
      quantity
      balance
      isGift
      itemExtra
      itemStatus
    }
  `
}

function getRefIdList(rows) {
  return rows
    .filter((item) => ['DEFER', 'LEND'].includes(item.ticketType))
    .map((item) => item.id)
}

function mergeExportData(rows, refRows) {
  const refRowsMap = refRows.reduce((result, item) => {
    const tickets = result[item.parentId] || []
    tickets.push(item)
    result[item.parentId] = tickets
    return result
  }, {})

  return rows.map((row) => {
    const refRows = refRowsMap[row.id] || []
    switch (row.ticketType) {
      case 'DEFER':
        row.dispatchTickets = refRows.filter((i) => i.ticketType === 'DISPATCH')
        row.refundTickets = refRows.filter((i) => i.ticketType === 'REFUND')
        break
      case 'LEND':
        // row.dispatchTickets = refRows.filter((i) => i.ticketType === 'DISPATCH')
        row.sellTickets = refRows.filter((i) => i.ticketType === 'SELL')
        break
      default:
    }
    return row
  })
}
