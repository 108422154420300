import { ALERT_ADD } from 'constants/actionType'
import { printHtml } from 'utilities/print'
import { request } from 'utilities/request'

export const columns = ({ message }) => [
  {
    id: 'vendor',
    label: 'inventoryDiff.field.vendor',
    render: () => message({ id: `vendor.sdj` }),
  },
  {
    id: 'productVariantName',
    label: 'product.field.spu',
  },
  {
    id: 'locationName',
    label: 'warehouse.field.name',
  },
  {
    id: 'quantity',
    label: 'inventoryDiff.field.quantity',
    align: 'right',
  },
  {
    id: 'vendorQuantity',
    label: 'inventoryDiff.field.vendorQuantity',
    align: 'right',
  },
]

export const handlers = ({ session, app, state, setState, message }) => ({
  handleLoad: async () => {
    const data = await getData({ session, app })
    setState(data)
  },
  syncInventoryDiff: async () => {
    const ok = await syncInventoryDiff({ session, app })
    if (!ok) return false

    const data = await getData({ session, app })
    setState(data)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'dashboard.sync.success' },
    })
    return true
  },
  handlePrint: () => {
    const title = 'inventoryDiff.title.list'
    const list = { columns: columns({ message }), rows: state }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
})

async function getData({ session, app }) {
  const query = `
    query {
      sdjInventoryDiffs {
        quantity
        vendorQuantity
        locationName
        productVariantName
      }
    }
  `
  const [ok, data] = await request({ query }, { app, session })
  if (!ok) return {}

  return data.sdjInventoryDiffs
}

async function syncInventoryDiff({ session, app }) {
  const query = `
    mutation {
      sdjCompareBalances
    }
  `
  const [ok, data] = await request({ query }, { app, session })
  if (!ok) return false

  return data.sdjCompareBalances
}
