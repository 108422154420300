import React from 'react'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
  getSelectOption,
  getDate,
  getSelectOptions,
} from 'utilities/form'
import { getProductOption } from 'actions/product'
import { Select, NumberInput, DateInput, Switch } from 'components/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'
import { Button, Definition, LinkButton, Table, Toolbar } from 'components/core'
import { MdAdd, MdDelete } from 'react-icons/md'

export const initialState = (value = {}, data, message) => {
  let disableCombo = true
  let childProductOptions = []

  if (value.productVariantId) {
    const product = getProduct(data.products, value.productVariantId)
    disableCombo = product.type !== 'COMBO'
    childProductOptions = getSelectOptions(product.childVariants)
  }

  const productOptionMap = data.rcvdItems.reduce((result, item) => {
    const value = item.productVariantId
    result[value] = { value, label: item.productVariantName }
    return result
  }, {})

  return {
    index: value.index,
    ticketId: value.ticketId,
    hash: value.hash,
    id: value.id || '',
    parentId: value.parentId || '',
    products: data.products,
    productOptions: Object.values(productOptionMap),
    childProductOptions,
    locations: data.locations,
    showCombo: false,
    disableCombo,
    childProducts: [],
    ...initializeState({
      transDate: getDate(value.transDate),
      productVariantId: getProductOption(data.products, value.productVariantId),
      type: getType(value.extra?.type, message),
      locationId: getSelectOption(data.locations, value.toLocationId),
      quantity: value.quantity || 1,
    }),
  }
}

function getProduct(products, id) {
  if (!id) return {}
  return products.find((item) => item.id === id)
}

export function getTypes(message) {
  return [
    {
      value: 'RETURN_WAREHOUSE',
      label: message({ id: `refund.type.RETURN_WAREHOUSE` }),
    },
    {
      value: 'DISCARD_NO_RECYCLE',
      label: message({ id: `refund.type.DISCARD_NO_RECYCLE` }),
    },
    {
      value: 'DISCARD',
      label: message({ id: `refund.type.DISCARD` }),
    },
    {
      value: 'DISCARD_EXPIRED',
      label: message({ id: `refund.type.DISCARD_EXPIRED` }),
    },
    {
      value: 'DISCARD_OPENED',
      label: message({ id: `refund.type.DISCARD_OPENED` }),
    },
    {
      value: 'RECEIVE_LESS',
      label: message({ id: `refund.type.RECEIVE_LESS` }),
    },
  ]
}

function getType(value, message) {
  if (!value) value = 'RETURN_WAREHOUSE'
  return { value, label: message({ id: `refund.type.${value}` }) }
}

const getValidation = (showCombo) => {
  if (showCombo) {
    return {
      productVariantId: [{ type: 'required', message: 'error.required' }],
      childProducts: [{ type: 'required', message: 'error.required' }],
    }
  }

  return {
    productVariantId: [{ type: 'required', message: 'error.required' }],
    type: [{ type: 'required', message: 'error.required' }],
  }
}

const columns = ({ message, state, setState }) => [
  {
    id: 'name',
    label: 'product.field.sku',
    width: '40%',
    render: ({ row, index }) => (
      <Select
        fieldProps={{ m: 0 }}
        isSearchable={false}
        isClearable={false}
        options={state.childProductOptions}
        value={row.productId}
        onChange={(value) => {
          const childProducts = [...state.childProducts]
          childProducts.splice(index, 1, {
            ...childProducts[index],
            productId: value,
          })
          setState({ ...state, childProducts })
        }}
      />
    ),
  },
  {
    id: 'type',
    label: 'refund.field.type',
    width: '130px',
    render: ({ row, index }) => (
      <Select
        fieldProps={{ m: 0 }}
        isSearchable={false}
        isClearable={false}
        options={getTypes(message)}
        value={row.type}
        onChange={(value) => {
          const childProducts = [...state.childProducts]
          childProducts.splice(index, 1, {
            ...childProducts[index],
            type: value,
          })
          setState({ ...state, childProducts })
        }}
      />
    ),
  },
  {
    id: 'location',
    label: 'refund.field.location',
    render: ({ row, index }) => (
      <Select
        fieldProps={{ m: 0 }}
        show={row.type?.value === 'RETURN_WAREHOUSE'}
        isClearable={false}
        options={state.locations}
        value={row.locationId}
        onChange={(value) => {
          const childProducts = [...state.childProducts]
          childProducts.splice(index, 1, {
            ...childProducts[index],
            locationId: value,
          })
          setState({ ...state, childProducts })
        }}
      />
    ),
  },
  {
    id: 'quantity',
    label: 'refund.field.quantity',
    width: '120px',
    render: ({ row, index }) => (
      <NumberInput
        fieldProps={{ m: 0 }}
        id="quantity"
        min="1"
        value={row.quantity}
        onChange={(value) => {
          const childProducts = [...state.childProducts]
          childProducts.splice(index, 1, {
            ...childProducts[index],
            quantity: value,
          })
          setState({ ...state, childProducts })
        }}
      />
    ),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    width: '30px',
    render: ({ index }) => (
      <LinkButton
        variant="icon"
        icon={<MdDelete />}
        onClick={() => {
          const childProducts = [...state.childProducts]
          childProducts.splice(index, 1)
          setState({ ...state, childProducts })
        }}
      />
    ),
  },
]

export const fields = ({ data, state, setState, message }) => {
  const validation = getValidation(state.showCombo.value)
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="field.date"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    productVariant: (
      <Select
        label="product.field.sku"
        isClearable={false}
        options={state.productOptions}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId', (value) => {
          const product = getProduct(data.products, value.value)
          return { disableCombo: product.type !== 'COMBO' }
        })}
        errMsg={state.__error__.productVariantId}
      />
    ),
    showCombo: (
      <Definition
        direction="row"
        label="refund.field.showCombo"
        labelProps={{ flex: 1 }}
      >
        <Switch
          disabled={state.disableCombo}
          checked={state.showCombo}
          onClick={() => {
            const productVariantId = state.productVariantId?.value
            if (!productVariantId) return

            const product = getProduct(data.products, productVariantId)
            const { childVariants } = product
            const childProductOptions = getSelectOptions(childVariants)
            setState({
              ...state,
              showCombo: !state.showCombo,
              childProductOptions,
              childProducts: childVariants.map((item) =>
                addChildProduct(
                  item,
                  childProductOptions,
                  state.quantity,
                  message,
                ),
              ),
            })
          }}
        />
      </Definition>
    ),
    type: (
      <Select
        show={!state.showCombo}
        label="refund.field.type"
        isSearchable={false}
        isClearable={false}
        options={getTypes(message)}
        value={state.type}
        onChange={onSelectChange('type')}
      />
    ),
    location: (
      <Select
        show={!state.showCombo && state.type?.value === 'RETURN_WAREHOUSE'}
        label="refund.field.location"
        isClearable={false}
        options={state.locations}
        value={state.locationId}
        onChange={onSelectChange('locationId')}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="1"
        label="refund.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity', (value) => {
          if (!value) return {}
          if (!state.showCombo) return {}

          const product = getProduct(
            data.products,
            state.productVariantId.value,
          )

          const childVariantMap = product.childVariants.reduce(
            (result, item) => {
              const total = result[item.id] || 0
              result[item.id] = total + parseInt(item.quantity) * value
              return result
            },
            {},
          )

          const childProducts = state.childProducts.map((item) => ({
            ...item,
            quantity: childVariantMap[item.productId.value],
          }))

          return { childProducts }
        })}
        errMsg={state.__error__.quantity}
      />
    ),
    childProducts: (
      <div style={{ display: state.showCombo ? 'block' : 'none' }}>
        <Toolbar>
          <Button
            variant="default"
            icon={<MdAdd />}
            text="refund.btn.addChildProduct"
            onClick={() => {
              const { childProducts } = state
              childProducts.push(
                addChildProduct(
                  {},
                  state.childProductOptions,
                  state.quantity,
                  message,
                ),
              )
              setState({ ...state, childProducts })
            }}
          />
        </Toolbar>
        <Table
          columns={columns({ message, state, setState })}
          rows={state.childProducts}
        />
      </div>
    ),
  }
}

export const handlers = ({ app, session, data, state, setState, action }) => ({
  handleConfirm: async (event) => {
    event.preventDefault()

    const validation = getValidation(state.showCombo.value)
    if (!validateForm({ state, setState, validation })) return false

    if (!validateChildProduct({ session, state, data })) return false

    if (action.handleItemSubmit) {
      const { itemsToAdd = [], itemsToEdit = [] } = getSubmitInput(state)
      const ticketItems = [...itemsToAdd, ...itemsToEdit]
      action.handleItemSubmit(state.index, ticketItems)
      return true
    }

    const [ok] = await editRefund({ app, session, state })
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
    await action.handleSubmit()
    return true
  },
})

async function editRefund({ app, session, state }) {
  const variables = { id: state.ticketId, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editRefundTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, productVariantId } = state
  const ticketItems = []

  if (state.showCombo) {
    for (const item of state.childProducts) {
      const isReturn = state.type?.value === 'RETURN_WAREHOUSE'
      ticketItems.push({
        transDate: state.transDate,
        productVariantId: item.productId.value,
        productVariantName: item.productId.label,
        toLocationId: isReturn ? item.locationId?.value : '',
        toLocationName: isReturn ? item.locationId?.label : '',
        quantity: parseInt(item.quantity),
        extra: { type: item.type?.value },
      })
    }
    const input = { hash, itemsToAdd: ticketItems }
    if (state.id) input.itemsToDel = [state.id]
    return input
  }

  const isReturn = state.type?.value === 'RETURN_WAREHOUSE'
  ticketItems.push({
    id: state.id,
    transDate: state.transDate,
    productVariantId: productVariantId.value,
    productVariantName: productVariantId.label,
    toLocationId: isReturn ? state.locationId?.value : '',
    toLocationName: isReturn ? state.locationId?.label : '',
    quantity: parseInt(state.quantity),
    extra: { parentItemId: state.parentItemId, type: state.type?.value },
  })

  return { hash, [state.id ? 'itemsToEdit' : 'itemsToAdd']: ticketItems }
}

function addChildProduct(value, childProductOptions, quantity, message) {
  let productId = getSelectOption(childProductOptions, value.id)
  if (!productId) productId = childProductOptions[0]

  const refundQuantity = parseInt(quantity || 0)
  const unitQuantity = parseInt(value.quantity || 0)
  return {
    productId,
    type: getType(null, message),
    locationId: null,
    quantity: refundQuantity * unitQuantity,
  }
}

function validateChildProduct({ session, state, data }) {
  const { showCombo, childProducts } = state

  for (const childProduct of childProducts) {
    if (!childProduct.type.value) {
      session.dispatch({
        type: ALERT_ADD,
        item: {
          type: 'error',
          message: 'refund.childProduct.typeRequired',
        },
      })
      return false
    }

    if (childProduct.type.value !== 'RETURN_WAREHOUSE') continue

    if (!childProduct.locationId?.value) {
      session.dispatch({
        type: ALERT_ADD,
        item: {
          type: 'error',
          message: 'refund.childProduct.locationRequired',
        },
      })
      return false
    }
  }

  if (!showCombo) return true

  const quantity = parseInt(state.quantity)
  const productVariantId = state.productVariantId?.value
  const product = getProduct(data.products, productVariantId)
  const { childVariants } = product
  const childVariantMap = childVariants.reduce((result, item) => {
    const total = result[item.id] || 0
    result[item.id] = total + parseInt(item.quantity) * quantity
    return result
  }, {})

  const childProductMap = childProducts.reduce((result, item) => {
    const productVariantId = item.productId.value
    const total = result[productVariantId] || 0
    result[productVariantId] = total + parseInt(item.quantity)
    return result
  }, {})

  const keys = Object.keys(childVariantMap)
  if (keys.length !== Object.keys(childProductMap).length) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message: 'refund.childProduct.quantityInvalid' },
    })
    return false
  }

  for (const key of keys) {
    const variant = childVariantMap[key]
    const product = childProductMap[key]

    if (variant !== product) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'refund.childProduct.quantityInvalid' },
      })
      return false
    }
  }

  return true
}
