import React from 'react'
import { NumberInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/form'

export const initialState = (value = {}) => ({
  index: value.index,
  id: value.id,
  ...initializeState({
    lowRange: value.lowRange || '',
    highRange: value.highRange || '',
    shelfPercent: value.shelfPercent || '',
    shelfDay: value.shelfDay || '',
  }),
})

const validation = {
  lowRange: [{ type: 'required', message: 'error.required' }],
  highRange: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    lowRange: (
      <NumberInput
        id="lowRange"
        placeholder="dealer.field.lowRange"
        value={state.lowRange}
        min={0}
        onChange={onTextChange('lowRange')}
        errMsg={state.__error__.lowRange}
      />
    ),
    highRange: (
      <NumberInput
        id="highRange"
        placeholder="dealer.field.highRange"
        value={state.highRange}
        min={0}
        onChange={onTextChange('highRange')}
        errMsg={state.__error__.highRange}
      />
    ),
    shelfPercent: (
      <NumberInput
        id="shelfPercent"
        placeholder="dealer.field.shelfPercent"
        value={state.shelfPercent}
        min={0}
        onChange={onTextChange('shelfPercent')}
        errMsg={state.__error__.shelfPercent}
      />
    ),
    shelfDay: (
      <NumberInput
        id="shelfDay"
        placeholder="dealer.field.shelfDay"
        value={state.shelfDay}
        min={0}
        onChange={onTextChange('shelfDay')}
        errMsg={state.__error__.shelfDay}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    action.addShelfPeriod({
      index: state.index,
      id: state.id,
      lowRange: state.lowRange,
      highRange: state.highRange,
      shelfPercent: state.shelfPercent,
      shelfDay: state.shelfDay,
    })
    action.handleClose()
  },
})
