import React from 'react'
import { Button, Table } from 'components/core'
import { NumberInput, Select, TextInput } from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
  getSelectOption,
} from 'utilities/form'
import {
  getProduct,
  getProductOption,
  getProductOptions,
} from 'actions/product'
import { MdDelete } from 'react-icons/md'
import MaskInput from 'components/form/MaskInput'

export const initialState = (value = {}, data) => ({
  id: value.id || '',
  index: value.index,
  warehouses: data.warehouses,
  products: data.products,
  batchItems: value.extra?.batchItems || [],
  ...initializeState({
    productVariantId: getProductOption(data.products, value.productVariantId),
    warehouseId: getSelectOption(data.warehouses, value.toLocationId),
    invoicedQuantity: value.extra?.invoicedQuantity || 0,
    purchasedQuantity: value.extra?.purchasedQuantity || 0,
    quantity: value.quantity || 0,
  }),
})

const validation = {
  productVariantId: [{ type: 'required', message: 'error.required' }],
  warehouseId: [{ type: 'required', message: 'error.required' }],
  purchasedQuantity: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)
  const onBatchItemChange = (index, key) => (value) => {
    const batchItems = [...state.batchItems]
    const batchItem = batchItems[index]
    batchItem[key] = value
    batchItems.splice(index, 1, batchItem)
    setState({ ...state, batchItems })
  }

  return {
    productVariantId: (
      <Select
        id="productVariantId"
        label="product.field.spu"
        placeholder="product.field.spu"
        isClearable={false}
        options={getProductOptions(
          data.products,
          data.ticketItems,
          state.productVariantId?.value,
        )}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    warehouseId: (
      <Select
        id="warehouseId"
        label="purchase.field.warehouseId"
        placeholder="purchase.field.warehouseId"
        isClearable={false}
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
        errMsg={state.__error__.warehouseId}
      />
    ),
    invoicedQuantity: (
      <NumberInput
        id="invoicedQuantity"
        min="0"
        label="purchase.field.invoicedQuantity"
        placeholder="purchase.field.invoicedQuantity"
        value={state.invoicedQuantity}
        onChange={onTextChange('invoicedQuantity')}
        errMsg={state.__error__.invoicedQuantity}
      />
    ),
    purchasedQuantity: (
      <NumberInput
        id="purchasedQuantity"
        min="0"
        label="purchase.field.purchasedQuantity"
        placeholder="purchase.field.purchasedQuantity"
        value={state.purchasedQuantity}
        onChange={onTextChange('purchasedQuantity')}
        errMsg={state.__error__.purchasedQuantity}
      />
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        label="purchase.field.quantity"
        placeholder="purchase.field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    batchItems: (
      <Table
        columns={[
          {
            id: 'batchNo',
            label: 'purchase.field.batchNo',
            render: ({ row, index }) => (
              <TextInput
                fieldProps={{ m: 0 }}
                value={row.batchNo}
                onChange={onBatchItemChange(index, 'batchNo')}
              />
            ),
          },
          {
            id: 'expireAt',
            label: 'purchase.field.expireAt',
            render: ({ row, index }) => (
              // <DateInput
              //   fieldProps={{ m: 0 }}
              //   min={getDate()}
              //   value={row.expireAt}
              //   onChange={onBatchItemChange(index, 'expireAt')}
              // />
              <MaskInput
                fieldProps={{ m: 0 }}
                mask="9999/99/99"
                value={row.expireAt}
                onChange={onBatchItemChange(index, 'expireAt')}
              />
            ),
          },
          {
            id: 'quantity',
            label: 'purchase.field.quantity',
            render: ({ row, index }) => (
              <NumberInput
                fieldProps={{ m: 0 }}
                min="1"
                value={row.quantity}
                onChange={onBatchItemChange(index, 'quantity')}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const batchItems = [...state.batchItems]
                  batchItems.splice(index, 1)
                  setState({ ...state, batchItems })
                }}
              />
            ),
          },
        ]}
        rows={state.batchItems}
      />
    ),
  }
}

export const handlers = ({ app, session, state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { warehouseId, batchItems } = state
    const productVariantId = state.productVariantId.value
    const product = getProduct(state.products, productVariantId)
    action.addItem({
      index: state.index,
      id: state.id,
      productVariantId,
      productVariantName: product.name,
      toLocationId: warehouseId.value,
      toLocationName: warehouseId.label,
      sku: product.sku,
      quantity: parseInt(state.quantity),
      extra: {
        barcode: product.barcode,
        batchItems,
        invoicedQuantity: parseInt(state.invoicedQuantity),
        purchasedQuantity: parseInt(state.purchasedQuantity),
      },
    })
    action.handleClose()
  },
  addBatch: () => {
    const batchItems = [...state.batchItems]
    batchItems.push({ batchNo: '', expireAt: '', quantity: 1 })
    setState({ ...state, batchItems })
  },
})
