import React from 'react'
import { Link, LinkButton, StatusChip } from 'components/core'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { MdEdit, MdLock } from 'react-icons/md'
import { showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { FILTER_SET } from 'constants/actionType'
import { getTotalPrice } from './processPage'
import { Flex } from 'reflexbox'

export const filters = ({ depts, orgs }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'lock.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'finishDate',
    label: 'process.field.finishDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'location',
    label: 'process.field.locationId',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          {
            label: props.message({ id: 'location.type.DEPT' }),
            options: depts,
          },
          {
            label: props.message({ id: 'location.type.DEALER_ORG' }),
            options: orgs,
          },
        ],
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'PENDING', label: message({ id: 'status.PENDING' }) },
    {
      value: 'CALLBACK_PENDING',
      label: message({ id: 'status.CALLBACK_PENDING' }),
    },
    {
      value: 'CALLBACK_PENDING',
      label: message({ id: 'process.status.ACTION_PENDING' }),
    },
    { value: 'ACTIVE', label: message({ id: 'status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'status.INACTIVE' }) },
  ]
}

export const columns = ({ session, app, locationMap, message, action }) => [
  {
    id: 'id',
    label: 'process.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/process/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'processType',
    label: 'process.field.processType',
    format: ['excel'],
    render: ({ row }) =>
      message({ id: `process.type.${row.extra.processType}` }),
  },
  {
    id: 'transDate',
    label: 'field.createdAt',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'finishDate',
    label: 'process.field.finishDate',
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.extra?.finishDate),
  },
  {
    id: 'itemToLocationName',
    label: 'process.field.toWarehouse',
    format: ['excel'],
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    format: ['excel'],
  },
  {
    id: 'locationId',
    label: 'process.field.locationId',
    format: ['excel'],
    render: ({ row }) => {
      const locationId = row.extra?.locationId
      if (!locationMap || !locationId) return ''
      return locationMap[locationId]
    },
  },
  {
    id: 'processItemPrice',
    label: 'process.field.processItemPrice',
    format: ['excel'],
    render: ({ row }) => getTotalPrice(row.extra),
  },
  {
    id: 'status',
    label: 'process.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => {
      return (
        <StatusChip
          label={`process.status.${row.status}`}
          color={getStatusColor(row.status)}
        />
      )
    },
    render: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'materialPrice',
    label: 'process.field.materialTotalPrice',
    format: ['excel'],
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.extra.locked || row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/process/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'process',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('process', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  message,
  app,
  setFilterValues,
  setPagination,
  setDepts,
  setOrgs,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'processList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setDepts(resp.depts)
    setOrgs(resp.orgs)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ depts, orgs, filterValues, createExcel }) => {
    const locationMap = depts.concat(orgs).reduce((result, item) => {
      result[item.value] = item.label
      return result
    }, {})

    const title = 'process.title.list'
    const cols = columns({ locationMap, message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    locationInput: { type: ['DEPT', 'DEALER_ORG'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      processTicketCount(input: $input)
      processTickets(input: $input) {
        id
        transDate
        extra
        createdAt
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const depts = []
  const orgs = []

  data.locations.forEach((item) => {
    const result = { label: item.name, value: item.id }
    switch (item.type) {
      case 'DEPT':
        depts.push(result)
        break
      case 'DEALER_ORG':
        orgs.push(result)
        break
      default:
    }
  })

  const { page, countPerPage } = pagination
  return {
    data: data.processTickets,
    pagination: getPagination(page, countPerPage, data.processTicketCount),
    depts,
    orgs,
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      processTickets(input: $input) {
        id
        itemToLocationName
        productVariantName
        sku
        quantity
        transDate
        extra
        itemExtra
        createdAt
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const materialPriceMap = data.processTickets.reduce((result, item) => {
    if (!item.itemExtra?.isMaterial) return result

    const total = result[item.id] || 0
    result[item.id] = total + parseFloat(item.itemExtra?.totalPrice || 0)
    return result
  }, {})

  return data.processTickets
    .filter((item) => !!item.itemToLocationName)
    .map((item) => ({ ...item, materialPrice: materialPriceMap[item.id] }))
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const finishDate = filterValues.find(({ id }) => id === 'finishDate')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const location = filterValues.find(({ id }) => id === 'location')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    id: id?.value,
    transDate: transDate?.value,
    status: status?.value?.value,
    productVariantName: productName?.value,
    createdBy: createdBy?.value,
    extra: {
      locationId: location?.value.value,
      finishDate: finishDate?.value,
      locked: lockStatus?.value.value,
    },
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
