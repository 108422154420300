import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { initialState, fields, handlers } from 'models/dealerDrawer'
import { Center, Drawer, Button } from 'components/core'
import { useCallbackOnce } from 'hooks/useCallbackOnce'

export default ({ value, open, data, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState(value, data))
  const form = fields({ state, setState, data })
  const handler = handlers({ state, setState, app, session, action })

  useEffect(() => {
    setState(initialState(value, data))
  }, [value, data])

  return (
    <Drawer
      title="dealer.title.addStorage"
      open={open}
      onClose={action.handleClose}
      as="form"
      onSubmit={useCallbackOnce(handler.handleSubmit)}
    >
      {form.name}
      {form.code}
      {form.contact}
      {form.phone}
      {form.cellphone}
      {form.email}
      {form.address}
      {form.shipping}
      {form.sales}
      {form.isActive}
      <Center mt={3}>
        <Button
          variant="secondary"
          mr={2}
          text="btn.close"
          onClick={action.handleClose}
        />
        <Button type="submit" variant="primary" text="btn.confirm" />
      </Center>
    </Drawer>
  )
}
