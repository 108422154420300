import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { ALERT_ADD } from 'constants/actionType'
import { TextInput } from 'components/form'

export const initialState = (value = {}) => ({
  staffId: value.staffId,
  ...initializeState({
    newPassword: '',
    newPasswordConfirm: '',
  }),
})

const validation = {
  newPassword: [
    { type: 'required', message: 'error.required' },
    {
      type: 'minLength',
      val: 6,
      message: { id: 'error.minLength', values: { val: 6 } },
    },
  ],
  newPasswordConfirm: [
    { type: 'required', message: 'error.required' },
    {
      type: 'minLength',
      val: 6,
      message: { id: 'error.minLength', values: { val: 6 } },
    },
    {
      type: 'fieldEqual',
      name: 'newPassword',
      message: 'error.passNotMatch',
    },
  ],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    newPassword: (
      <TextInput
        type="password"
        placeholder="staff.field.newPassword"
        value={state.newPassword}
        onChange={onTextChange('newPassword')}
        errMsg={state.__error__.newPassword}
      />
    ),
    newPasswordConfirm: (
      <TextInput
        type="password"
        placeholder="staff.field.newPasswordConfirm"
        value={state.newPasswordConfirm}
        onChange={onTextChange('newPasswordConfirm')}
        errMsg={state.__error__.newPasswordConfirm}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const { staffId, newPassword } = state
    const variables = { staffId, newPassword }
    const query = `
      mutation($staffId: ID!, $newPassword: String!) {
        resetStaffPass(staffId: $staffId, newPassword: $newPassword)
      }
    `
    const [ok, data] = await request({ query, variables }, { session, app })
    if (!ok || !data.resetStaffPass) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'staff.success.passwordReset' },
    })
    action.setOpen(false)
  },
})
