import React from 'react'
import { request } from 'utilities/request'
import { textFilter, selectFilter, limitDateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'
import { filterLocations } from 'utilities/permission'
import { today, yesterday } from 'utilities/date'

export const filters = ({ data }) => [
  {
    id: 'dept',
    label: 'field.dept',
    input: (props) =>
      selectFilter({ ...props, isSearchable: true, options: data.depts }),
  },
  {
    id: 'org',
    label: 'field.org',
    input: (props) =>
      selectFilter({ ...props, isSearchable: true, options: data.orgs }),
  },
  {
    id: 'dealer',
    label: 'field.dealer',
    input: textFilter,
  },
  {
    id: 'storage',
    label: 'field.storage',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: limitDateFilter({ max: yesterday() }),
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'isGift',
    label: 'dispatch.field.isGift',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          { value: true, label: props.message({ id: 'type.true' }) },
          { value: false, label: props.message({ id: 'type.false' }) },
        ],
      }),
  },
]

export const columns = ({ data, filterValues, action }) => [
  {
    id: 'locationName',
    label: 'field.dealer',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.dealerMap) return ''
      return data.dealerMap[row.dealerId]?.name
    },
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.name
    },
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
    render: ({ row }) => {
      if (!data.productMap) return ''
      return data.productMap[row.productVariantId]?.sku
    },
  },
  {
    id: 'dispatchQuantity',
    label: 'field.dispatchQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['DISPATCH'],
            isGift: false,
            locationId: row.dealerId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.dispatchQuantity}
      </Link>
    ),
    render: ({ row }) => row.dispatchQuantity,
  },
  {
    id: 'dispatchGiftQuantity',
    label: 'field.dispatchGiftQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['DISPATCH'],
            isGift: true,
            locationId: row.dealerId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.dispatchGiftQuantity}
      </Link>
    ),
    render: ({ row }) => row.dispatchGiftQuantity,
  },
  {
    id: 'sellQuantity',
    label: 'field.sellQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            isGift: false,
            locationId: row.dealerId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.sellQuantity}
      </Link>
    ),
    render: ({ row }) => row.sellQuantity,
  },
  {
    id: 'sellGiftQuantity',
    label: 'field.sellGiftQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            isGift: true,
            locationId: row.dealerId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.sellGiftQuantity}
      </Link>
    ),
    render: ({ row }) => row.sellGiftQuantity,
  },
  {
    id: 'refundQuantity',
    label: 'field.refundQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            locationId: row.dealerId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.refundQuantity}
      </Link>
    ),
    render: ({ row }) => row.refundQuantity,
  },
  {
    id: 'adjustQuantity',
    label: 'field.adjustQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['ADJUST'],
            locationId: row.dealerId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.adjustQuantity}
      </Link>
    ),
    render: ({ row }) => row.adjustQuantity,
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setData,
}) => ({
  handleInit: async () => {
    const data = await getInit({ session, app })
    data.dealerMap = data.dealers.reduce((result, item) => {
      result[item.id] = item
      return result
    }, {})
    data.productMap = data.productVariants.reduce((result, item) => {
      result[item.id] = item
      return result
    }, {})

    setData(data)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ data, filterValues, createExcel }) => {
    const title = 'report.title.dealerStock'
    const cols = columns({ data, message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
  handleUpdate: async ({ pagination, filterValues }) => {
    const ok = await updateReport({ session, app })
    if (!ok) return false

    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)

    return true
  },
})

async function getInit({ session, app }) {
  const variables = {
    input: { type: ['DEALER', 'DEALER_ORG', 'DEPT'] },
    productInput: { status: ['ACTIVE', 'INACTIVE'] },
    merchantId: app.state.staff.merchantId,
  }
  const query = `
    query($input: LocationQueryInput, $productInput: ProductQueryInput, $merchantId: ID) {
      locations(input: $input) {
        id
        name
        type
      }
      productVariants(input: $productInput) {
        id
        name
        sku
      }
      reportLogs(merchantId: $merchantId) {
        id
        updatedAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const depts = []
  const orgs = []
  const dealers = []

  locations.forEach(({ id, name, type }) => {
    const result = { value: id, label: name }
    switch (type) {
      case 'DEALER':
        dealers.push({ id, name })
        break
      case 'DEALER_ORG':
        orgs.push(result)
        break
      case 'DEPT':
        depts.push(result)
        break
      default:
    }
  })

  const reportLog = data.reportLogs?.find((item) => item.id === 'stock')
  return {
    depts,
    orgs,
    dealers,
    productVariants: data.productVariants,
    updatedAt: reportLog?.updatedAt + ' UTC',
  }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: LocationStockReportInput) {
      locationStockReportCount2(input: $input)
      locationStockReports2(input: $input) {
        dealerId
        productVariantId
        dispatchQuantity
        dispatchGiftQuantity
        sellQuantity
        sellGiftQuantity
        refundQuantity
        adjustQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.locationStockReports2,
    pagination: getPagination(
      page,
      countPerPage,
      data.locationStockReportCount2,
    ),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: LocationStockReportInput) {
      locationStockReports2(input: $input) {
        dealerId
        productVariantId
        dispatchQuantity
        dispatchGiftQuantity
        sellQuantity
        sellGiftQuantity
        refundQuantity
        adjustQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.locationStockReports2
}

function getDataInput({ filterValues, pagination }) {
  const dept = filterValues.find(({ id }) => id === 'dept')
  const org = filterValues.find(({ id }) => id === 'org')
  const dealer = filterValues.find(({ id }) => id === 'dealer')
  const storage = filterValues.find(({ id }) => id === 'storage')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const isGift = filterValues.find(({ id }) => id === 'isGift')
  const input = {
    deptId: dept?.value.value,
    orgId: org?.value.value,
    storageName: storage?.value,
    locationName: dealer?.value,
    transDate: transDate?.value,
    productVariantName: productName?.value,
    isGift: isGift?.value.value,
    // orderBy: [{ key: 'locationName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function updateReport({ session, app }) {
  const fromDate = today() + ' 00:00:00'
  const toDate = today() + ' 23:59:59'
  const variables = { transDate: [fromDate, toDate] }
  const query = `
    mutation($transDate: [String]) {
      addLocationStocks(transDate: $transDate) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.addLocationStocks
}