import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { Link } from 'components/core'

export const columns = [
  {
    id: 'ticketId',
    label: `field.ticketNo`,
    render: ({ row: { ticketType, ticketId } }) => {
      const type = ticketType.toLowerCase().replace('_', '-')
      return (
        <Link
          variant="primaryLink"
          href={`${process.env.REACT_APP_STOCK_URL}/${type}/${ticketId}/view`}
          target="_blank"
        >
          {ticketId}
        </Link>
      )
    },
  },
  {
    id: 'transDate',
    label: `inventory.field.transDate`,
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right',
    // render: ({ row }) => {
    //   if (['SELL', 'DISPATCH'].includes(row.ticketType)) {
    //     return row.quantity * -1
    //   }
    //   return row.quantity
    // },
  },
]

export const handlers = ({ setState, session, app, value, setPagination }) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    if (!value.productVariantId) return

    let resp = null

    if (value.isSell) {
      resp = await getSellData({ session, app, value, pagination })
    } else if (value.isDispatch) {
      resp = await getDispatchData({ session, app, value, pagination })
    } else {
      resp = await getData({ session, app, value, pagination })
    }
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getSellData({ session, app, value, pagination }) {
  const variables = { input: getSellDataInput({ pagination, value }) }
  const query = `
    query($input: InventoryQueryInput!) {
      inventorySellLinkCount(input: $input)
      inventorySellLinks(input: $input) {
        ticketId
        ticketType
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventorySellLinks,
    pagination: getPagination(page, countPerPage, data.inventorySellLinkCount),
  }
}

async function getDispatchData({ session, app, value, pagination }) {
  const variables = { input: getSellDataInput({ pagination, value }) }
  const query = `
    query($input: InventoryQueryInput!) {
      inventoryDispatchLinkCount(input: $input)
      inventoryDispatchLinks(input: $input) {
        ticketId
        ticketType
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryDispatchLinks,
    pagination: getPagination(
      page,
      countPerPage,
      data.inventoryDispatchLinkCount,
    ),
  }
}

async function getData({ session, app, value, pagination }) {
  const variables = { input: getDataInput({ pagination, value }) }
  const query = `
    query($input: InventoryQueryInput!) {
      inventoryLinkCount(input: $input)
      inventoryLinks(input: $input) {
        ticketId
        ticketType
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryLinks,
    pagination: getPagination(page, countPerPage, data.inventoryLinkCount),
  }
}

function getDataInput({ pagination, value }) {
  const input = {
    productVariantId: value.productVariantId,
    isGift: value.isGift,
    orderBy: [{ key: 'createdAt', sort: 'DESC' }],
  }
  if (value.transDate) input.transDate = value.transDate
  if (value.ticketType) input.ticketType = value.ticketType
  if (value.locationId) input.locationId = value.locationId
  if (value.locationType) input.locationType = value.locationType
  if (pagination) input.limit = getLimit(pagination)
  return input
}

function getSellDataInput({ pagination, value }) {
  const input = {
    productVariantId: value.productVariantId,
    isGift: value.isGift,
    orderBy: [{ key: 'transDate', sort: 'DESC' }],
  }
  if (value.transDate) input.transDate = value.transDate
  if (value.ticketType) input.ticketType = value.ticketType
  if (value.locationId) input.locationId = value.locationId
  if (value.locationType) input.locationType = value.locationType
  if (pagination) input.limit = getLimit(pagination)
  return input
}
