import React from 'react'
import { Link, Popover, Text } from 'components/core'
import { request } from 'utilities/request'
import { snakeToCamel } from 'utilities/string'
import { MdSync, MdSyncDisabled, MdSyncProblem } from 'react-icons/md'
import { ALERT_ADD } from 'constants/actionType'

export const setBalance = ({
  ticketItems,
  oldTicketItems,
  productVariantId,
  balance,
  itemIndex = -1,
}) => {
  const itemTotal = ticketItems.reduce((result, item) => {
    if (productVariantId !== item.productVariantId) return result
    result += item.quantity || 0
    return result
  }, 0)

  let value = balance - itemTotal

  if (itemIndex !== -1 && oldTicketItems) {
    const oldItemTotal = oldTicketItems.reduce((result, item) => {
      if (productVariantId !== item.productVariantId) return result
      result += item.quantity || 0
      return result
    }, 0)

    value += oldItemTotal
  }

  return value
}

export const setBalances = (ticketItems, balances, key = 'balance') => {
  ticketItems.forEach((ticketItem) => {
    const { productVariantId } = ticketItem
    const balance = balances.find(
      (i) => i.productVariantId === productVariantId,
    )
    ticketItem[key] = balance ? balance.quantity : 0
  })
}

export const getAvailableBalance = ({
  ticketItems,
  oldTicketItems,
  productVariantId,
  balance = 0,
  index = -1,
  locationKey = null,
  locationId = null,
}) => {
  if (!productVariantId) return 0

  let itemBalance = balance

  if (oldTicketItems) {
    const oldItemTotal = oldTicketItems.reduce((result, item) => {
      if (item.productVariantId !== productVariantId) return result
      result += parseInt(item.quantity || 0)
      return result
    }, 0)
    itemBalance += oldItemTotal
  }

  const restItemTotal = ticketItems.reduce((result, item, itemIdx) => {
    if (index === itemIdx) return result
    if (item.productVariantId !== productVariantId) return result
    if (locationKey && item[locationKey] !== locationId) return result

    result += parseInt(item.quantity || 0)
    return result
  }, 0)
  itemBalance -= restItemTotal

  return itemBalance
}

export const getDisplayBalance = (profile, oldQuantity, balance) => {
  const quantity = parseInt(oldQuantity || 0)
  const newBalance = getAvailableBalance(profile, oldQuantity, balance)
  if (newBalance > 0 && newBalance >= quantity) return newBalance
  return <Text color="error.1">{newBalance}</Text>
}

export const getDisplayQuantity = (val, maxVal, minVal = 1) => {
  const value = parseInt(val || 0)
  if (value >= minVal && value <= maxVal) return value
  return <Text color="error.1">{value}</Text>
}

export const getRecipients = (value = [], message) => {
  return [
    {
      id: '__INDIVIDUAL__',
      name: message({ id: 'dispatch.recipient.individual' }),
    },
    ...value,
  ]
}

export async function handleDelete(module, { session, app, id, hash, action }) {
  const name = getModuleName(module)
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      cancel${name}Ticket(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok && action) action.handleLoad()
  return ok
}

function getModuleName(name) {
  let result = name.toLowerCase()

  if (name.includes('_')) {
    result = snakeToCamel(result)
  }

  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function checkBatchItemQuantity(itemQuantity, batchItems) {
  const quantity = parseInt(itemQuantity || 0)
  if (quantity <= 0) return true
  if (batchItems.length === 0) return false

  const lockQuantity = batchItems.reduce((result, item) => {
    result += parseInt(item.quantity)
    return result
  }, 0)
  if (quantity !== lockQuantity) return true

  return false
}

export const handleLock =
  ({ module, session, app, id, handleLoad }) =>
  async () => {
    const name = getModuleName(module)
    const variables = { id }
    const query = `
    mutation($id: ID!) {
      lock${name}Ticket(id: $id)
    }
  `
    const [ok] = await request({ query, variables }, { session, app })
    if (ok && handleLoad) handleLoad()
    return ok
  }

export const renderSyncLink = ({
  readonly = false,
  sync = false,
  app,
  session,
  id,
  onSubmit,
}) => {
  return (
    <Popover
      show={readonly}
      icon={sync ? <MdSync /> : <MdSyncDisabled />}
      linkProps={{ ml: 3, mr: 0, color: sync ? 'success.1' : 'warning.1' }}
    >
      <Link
        disabled={sync}
        py={2}
        variant="primaryLink"
        icon={<MdSync />}
        text="btn.openSync"
        onClick={async () => {
          const ok = await enableSync(app, session, id)
          if (ok) onSubmit(true)
        }}
      />
      <Link
        disabled={!sync}
        py={2}
        variant="primaryLink"
        icon={<MdSyncDisabled />}
        text="btn.closeSync"
        onClick={async () => {
          const ok = await disableSync(app, session, id)
          if (ok) onSubmit(false)
        }}
      />
      <Link
        py={2}
        variant="primaryLink"
        icon={<MdSyncProblem />}
        text="btn.sync"
        onClick={async () => {
          const [ok] = await syncTicket(app, session, id)
          if (ok) {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'success', message: 'sync.success' },
            })
          }
        }}
      />
    </Popover>
  )
}

async function enableSync(app, session, id) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      enableTicketSync(id: $id)
    }
  `
  return request({ query, variables }, { session, app })
}

async function disableSync(app, session, id) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      disableTicketSync(id: $id)
    }
  `
  return request({ query, variables }, { session, app })
}

async function syncTicket(app, session, id) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      syncTicket(id: $id)
    }
  `
  return request({ query, variables }, { session, app })
}
