import React from 'react'
import { request } from 'utilities/request'
import { textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'
import { FILTER_SET } from 'constants/actionType'

export const filters = [
  {
    id: 'staffName',
    label: 'repair.field.techId',
    input: textFilter,
  },
]

export const columns = [
  {
    id: 'techId',
    label: 'repair.field.techId',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Link variant="primaryLink" href={`/repair/report/${row.staffId}`}>
        {row.staffName}
      </Link>
    ),
  },
  {
    id: 'scoreOverall',
    label: 'repairFeedback.field.scoreOverall',
    render: ({ row }) => getAvgScore(row.scoreOverall, row.count),
  },
  {
    id: 'scoreAttitude',
    label: 'repairFeedback.field.scoreAttitude',
    render: ({ row }) => getAvgScore(row.scoreAttitude, row.count),
  },
  {
    id: 'scoreTechnical',
    label: 'repairFeedback.field.scoreTechnical',
    render: ({ row }) => getAvgScore(row.scoreTechnical, row.count),
  },
]

function getAvgScore(score, count) {
  if (count === 0) return 0
  return Math.round((score / count) * 100) / 100
}

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'repairReportList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'repairReport.title.list'
    const cols = columns.filter(({ format = [] }) => format.includes('excel'))
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: FeedbackQueryInput) {
      feedbackSummaryCount(input: $input)
      feedbackSummaries(input: $input) {
        staffId
        staffName
        scoreOverall
        scoreAttitude
        scoreTechnical
        count
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.feedbackSummaries,
    pagination: getPagination(page, countPerPage, data.feedbackSummaryCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: FeedbackQueryInput) {
      feedbackSummaries(input: $input) {
        staffId
        staffName
        scoreOverall
        scoreAttitude
        scoreTechnical
        count
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.feedbackSummaries
}

function getDataInput({ filterValues, pagination }) {
  const staffName = filterValues.find(({ id }) => id === 'staffName')
  const input = {
    staffName: staffName ? staffName.value : null,
    orderBy: [{ key: 'staffName', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
