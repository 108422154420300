import React from 'react'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  handleTextChange,
  getSelectOptions,
} from 'utilities/form'
import {
  DateInput,
  NumberInput,
  Select,
  Switch,
  TextInput,
} from 'components/form'
import { request } from 'utilities/request'
import { Button, Table } from 'components/core'
import { MdDelete } from 'react-icons/md'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = () => ({
  products: [],
  ...initializeState({
    dealerId: '',
    code: '',
  }),
})

const validation = {
  dealerId: [{ type: 'required', message: 'error.required' }],
  code: [{ type: 'required', message: 'error.required' }],
  products: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data }) => {
  const onTextChange = (id, callback) =>
    handleTextChange(id, state, setState, validation, callback)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    dealer: (
      <Select
        isClearable={false}
        label="productComboCode.field.dealer"
        placeholder="productComboCode.field.dealer"
        options={getSelectOptions(data.dealers)}
        value={state.dealerId}
        onChange={onSelectChange('dealerId')}
        errMsg={state.__error__.dealerId}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="productComboCode.field.code"
        placeholder="productComboCode.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    products: (
      <Table
        columns={[
          {
            id: 'productVariantId',
            label: 'productComboCode.field.product',
            render: ({ row, index }) => (
              <Select
                fieldProps={{ m: 0 }}
                isClearable={false}
                placeholder="field.productName"
                options={getSelectOptions(data.products)}
                value={row.productVariantId}
                onChange={(value) => {
                  const products = [...state.products]
                  const product = { ...row, productVariantId: value }
                  products.splice(index, 1, product)
                  setState({ ...state, products })
                }}
              />
            ),
          },
          {
            id: 'isGift',
            label: 'productComboCode.field.isGift',
            width: '90px',
            render: ({ row, index }) => (
              <Switch
                checked={row.isGift}
                onClick={() => {
                  const products = [...state.products]
                  const product = { ...row, isGift: !row.isGift }
                  products.splice(index, 1, product)
                  setState({ ...state, products })
                }}
              />
            ),
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            width: '90px',
            render: ({ row, index }) => (
              <NumberInput
                fieldProps={{ m: 0 }}
                min="1"
                value={row.quantity}
                onChange={(value) => {
                  const products = [...state.products]
                  const product = { ...row, quantity: value }
                  products.splice(index, 1, product)
                  setState({ ...state, products })
                }}
              />
            ),
          },
          {
            id: 'warehouseId',
            label: 'productComboCode.field.warehouseId',
            render: ({ row, index }) => (
              <Select
                fieldProps={{ m: 0 }}
                options={getSelectOptions(data.warehouses)}
                value={row.warehouseId}
                onChange={(value) => {
                  const products = [...state.products]
                  const product = { ...row, warehouseId: value }
                  products.splice(index, 1, product)
                  setState({ ...state, products })
                }}
              />
            ),
          },
          {
            id: 'batchNo',
            label: 'productComboCode.field.batchNo',
            width: '90px',
            render: ({ row, index }) => (
              <TextInput
                fieldProps={{ m: 0 }}
                value={row.batchNo || ''}
                onChange={(value) => {
                  const products = [...state.products]
                  const product = { ...row, batchNo: value }
                  products.splice(index, 1, product)
                  setState({ ...state, products })
                }}
              />
            ),
          },
          {
            id: 'expireAt',
            label: 'productComboCode.field.expireAt',
            width: '90px',
            render: ({ row, index }) => (
              <DateInput
                fieldProps={{ m: 0 }}
                value={row.expireAt}
                onChange={(value) => {
                  const products = [...state.products]
                  const product = { ...row, expireAt: value }
                  products.splice(index, 1, product)
                  setState({ ...state, products })
                }}
              />
            ),
          },
          {
            id: 'actions',
            width: '30px',
            align: 'right',
            noWrap: true,
            render: ({ index }) => (
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const products = [...state.products]
                  products.splice(index, 1)
                  setState({ ...state, products })
                }}
              />
            ),
          },
        ]}
        rows={state.products}
      />
    ),
  }
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  data,
  onSubmit,
}) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return
    if (!validateProducts(state.products)) {
      session.dispatch({
        type: ALERT_ADD,
        item: {
          type: 'error',
          message: 'error.productComboCode.invalidProduct',
        },
      })
      return
    }

    const [ok] = await addProductCode({ session, app, state, data })
    if (!ok) return

    onSubmit()
  },
  addCombo: () => {
    const products = [...state.products]
    products.push({ productVariantId: {}, quantity: 1 })
    setState({ ...state, products })
  },
})

async function addProductCode({ session, app, state }) {
  const input = {
    locationId: state.dealerId.value,
    code: state.code,
    products: state.products.map((item) => ({
      productVariantId: item.productVariantId.value,
      productVariantName: item.productVariantId.label,
      isGift: item.isGift,
      quantity: item.quantity,
      warehouseId: item.warehouseId?.value,
      warehouseName: item.warehouseId?.label,
      batchNo: item.batchNo,
      expireAt: item.expireAt,
    })),
  }
  const variables = { input }
  const query = `
    mutation($input: ProductComboCodeInput!) {
      addProductComboCode(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function validateProducts(products) {
  for (const product of products) {
    if (!product.productVariantId.value) return false
    if (product.quantity <= 0) return false
  }
  return true
}
