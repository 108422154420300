import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { LinkButton, Button } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete, MdEdit } from 'react-icons/md'

export const columns = ({ setState, session, app, action }) => [
  {
    id: 'name',
    label: 'processConfig.field.name',
  },
  // {
  //   id: 'status',
  //   label: 'processConfig.field.status',
  // },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          href={`/processConfig/${row.id}/edit`}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'processConfig.title.delete',
                text: 'processConfig.message.delete',
                onSubmit: async () =>
                  handleDelete({ session, app, action, id: row.id }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({ setState, session, app }) => ({
  handleLoad: async () => {
    const data = await getData({ session, app })
    setState(data)
  },
})

async function getData({ session, app }) {
  const variables = {
    input: { type: ['PROCESS'] },
  }
  const query = `
    query($input: TicketConfigQueryInput) {
      ticketConfigs(input: $input) {
        id
        locationName
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.ticketConfigs
}

async function handleDelete({ session, app, action, id }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteTicketConfig(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok) {
    action.handleLoad()
  }

  return ok
}
