import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { columns, filters, handlers } from 'models/reportDealerSalesList'
import { Center, Definition, Grid, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload } from 'react-icons/md'
// import ReportLocationDrawer from './ReportLocationDrawer'
import ReportStaffDrawer from './ReportStaffDrawer'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [depts, setDepts] = useState([])
  const [orgs, setOrgs] = useState([])
  const [dealers, setDealers] = useState([])
  const [state, setState] = useState([])
  const [total, setTotal] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  // const [open, setOpen] = useState(false)
  // const [value, setValue] = useState({})
  const [salesOpen, setSalesOpen] = useState(false)
  const [salesValue, setSalesValue] = useState({})
  const { handleInit, handleLoad, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setTotal,
    setFilterValues,
    setPagination,
    setDepts,
    setOrgs,
    setDealers,
  })

  useEffect(() => {
    handleInit()
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleOpen = (item) => {
  //   setValue(item)
  //   setOpen(true)
  // }

  // const handleClose = () => {
  //   setOpen(false)
  //   setValue({})
  // }

  const handleSalesOpen = (item) => {
    item.distinct = true
    setSalesValue(item)
    setSalesOpen(true)
  }

  const handleSalesClose = () => {
    setSalesOpen(false)
    setSalesValue({})
  }

  return (
    <Page
      title="report.title.dealerSales"
      navId="dealerSalesReport"
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
        </Center>
      }
    >
      <Section>
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Definition
            label="report.totalSellQuantity"
            value={total?.sellQuantity ? parseInt(total.sellQuantity) : 0}
          />
          <Definition
            label="report.totalSellGiftQuantity"
            value={
              total?.sellGiftQuantity ? parseInt(total.sellGiftQuantity) : 0
            }
          />
          <Definition
            label="report.totalSellAmount"
            value={total?.sellAmount || 0}
          />
        </Grid>
      </Section>
      <Section mt={4}>
        <Table
          columns={columns({
            filterValues,
            action: { handleLoad, handleSalesOpen },
          })}
          rows={state}
          filters={filters({ message, data: { depts, orgs, dealers } })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      {/* <ReportLocationDrawer
        value={value}
        open={open}
        action={{ handleClose }}
      /> */}
      <ReportStaffDrawer
        value={salesValue}
        open={salesOpen}
        action={{ handleClose: handleSalesClose }}
      />
    </Page>
  )
}
