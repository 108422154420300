import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { createExcel } from 'utilities/excel'
import { columns, handlers } from 'models/reportDailyList'
import { Center, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload } from 'react-icons/md'

export default () => {
  const url = new URL(window.location.href)
  const fromDate = url.searchParams.get('fromDate')
  const toDate = url.searchParams.get('toDate')
  const priorDay = JSON.parse(url.searchParams.get('priorDay'))
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const { handleLoad, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    fromDate,
    toDate,
    priorDay,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="report.title.daily"
      navId="dailyReport"
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ createExcel })}
          />
        </Center>
      }
    >
      <Section>
        <Table columns={columns(message)} rows={state} showSeq />
      </Section>
    </Page>
  )
}
