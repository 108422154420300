import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { columns, filters, handlers } from 'models/dispatchList'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { Center, Link, Table, LinkButton, Section } from 'components/core'
import Page from 'components/Page'
import { MdFileDownload, MdFileUpload } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [orgs, setOrgs] = useState([])
  const [dealers, setDealers] = useState([])
  const [warehouseGroups, setWarehouseGroups] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [shippings, setShippings] = useState([])
  const [sales, setSales] = useState([])
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleInit, handleLoad, handleExport, handleTxExport } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
    setOrgs,
    setDealers,
    setWarehouseGroups,
    setWarehouses,
    setShippings,
    setSales,
  })

  useEffect(() => {
    handleInit()
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="dispatch.title.list"
      navId="dispatchList"
      action={
        <Center>
          <Link
            mr={2}
            icon={<MdFileDownload />}
            text="btn.import"
            href="/dispatch/import"
          />
          <Link
            mr={2}
            icon={<MdFileDownload />}
            text="dispatch.title.importSerial"
            href="/dispatch/importSerial"
          />
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="dispatch.btn.txExport"
            onClick={() => handleTxExport({ filterValues, createExcel })}
          />
          <LinkButton
            mr={2}
            variant="secondary"
            text="btn.add"
            href="/dispatch/add"
          />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({ session, app, message, action: { handleLoad } })}
          rows={state}
          filters={filters({
            message,
            data: {
              shippings,
              warehouseGroups,
              warehouses,
              orgs,
              dealers,
              sales,
            },
          })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
