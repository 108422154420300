import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { columns, filters, handlers } from 'models/reportStaffSalesList'
import { Center, Definition, Link, Section, Table } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload } from 'react-icons/md'
import ReportStaffDrawer from './ReportStaffDrawer'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [sales, setSales] = useState([])
  const [state, setState] = useState([])
  const [total, setTotal] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const { handleInit, handleLoad, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setTotal,
    setFilterValues,
    setPagination,
    setSales,
  })

  useEffect(() => {
    handleInit()
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <Page
      title="report.title.staffSales"
      navId="staffSalesReport"
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
        </Center>
      }
    >
      <Section>
        <Definition
          label="report.totalSellAmount"
          value={total?.sellAmount || 0}
        />
      </Section>
      <Section mt={4}>
        <Table
          columns={columns({
            filterValues,
            action: { handleLoad, handleOpen },
          })}
          rows={state}
          filters={filters({ message, data: { sales } })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <ReportStaffDrawer value={value} open={open} action={{ handleClose }} />
    </Page>
  )
}
