import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { columns, filters, handlers } from 'models/moveList'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { createExcel } from 'utilities/excel'
import { Center, Link, Table, Section, SplitButton } from 'components/core'
import Page from 'components/Page'
import { MdFileUpload } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [locations, setLocations] = useState([])
  const [state, setState] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const { handleLoad, handleExport } = handlers({
    session,
    app,
    message,
    setState,
    setFilterValues,
    setPagination,
    setLocations,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="move.title.list"
      navId="moveList"
      action={
        <Center>
          <Link
            mr={3}
            icon={<MdFileUpload />}
            text="btn.export"
            onClick={() => handleExport({ filterValues, createExcel })}
          />
          <SplitButton
            variant="secondary"
            text="move.btn.add"
            href="/move/add"
            popover={
              <>
                <Link
                  py={2}
                  variant="primaryLink"
                  text="transferOut.btn.add"
                  href="/transfer-out/add"
                />
                <Link
                  py={2}
                  variant="primaryLink"
                  text="transferIn.btn.add"
                  href="/transfer-in/add"
                />
              </>
            }
          />
        </Center>
      }
    >
      <Section>
        <Table
          columns={columns({ session, app, message, action: { handleLoad } })}
          rows={state}
          filters={filters({
            message,
            staff: app.state.staff,
            data: { locations },
          })}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
    </Page>
  )
}
