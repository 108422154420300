import React from 'react'
import { Icon } from 'components/core'
import {
  singleDateFilter,
  selectFilter,
  dateFilter,
  textFilter,
} from 'utilities/filter'
import { getSelectOptions } from 'utilities/form'
import { printHtml } from 'utilities/print'
import { request } from 'utilities/request'
import { MdCheck } from 'react-icons/md'

export const filters = ({ data }) => [
  {
    id: 'dealerId',
    label: 'report.field.dealer',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.dealers,
      }),
  },
  {
    id: 'balanceDate',
    label: 'report.field.restockBalanceDate',
    input: singleDateFilter,
  },
  {
    id: 'soldDate',
    label: 'report.field.restockSoldDate',
    input: dateFilter,
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'field.sku',
    input: textFilter,
  },
]

export const columns = () => [
  {
    id: 'dealerName',
    label: 'report.field.dealer',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
  },
  {
    id: 'balance',
    label: 'report.field.restockBalance',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'soldQuantity',
    label: 'report.field.restockSoldQuantity',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'minQuantity',
    label: 'report.field.restockMinQuantity',
    align: 'right',
    format: ['html', 'excel'],
  },
  {
    id: 'replenish',
    label: 'report.field.restockReplenish',
    format: ['html', 'excel'],
    render: ({ row }) => (row.minQuantity > row.balance ? 'Y' : 'N'),
    renderHtml: ({ row }) => (
      <Icon
        show={row.minQuantity > row.balance}
        color="success.1"
        value={<MdCheck />}
      />
    ),
  },
  {
    id: 'restockQuantity',
    label: 'report.field.restockQuantity',
    align: 'right',
    format: ['html', 'excel'],
    render: ({ row }) => {
      const quantity = row.minQuantity - row.balance
      return quantity > 0 ? quantity : ''
    },
  },
]

export const handlers = ({
  session,
  app,
  state,
  setState,
  setData,
  data,
  message,
  setFilterValues,
}) => ({
  handleInit: async () => {
    const data = await getInit({ session, app })
    setData(data)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    if (!data.dealerMap || !data.productMap) return

    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(formatRows(resp, data))
  },
  handlePrint: () => {
    const title = 'report.title.restockList'
    const list = { columns: columns({ message }), rows: state }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.restockList'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const resp = await getData({ session, app, filterValues })
    const rows = formatRows(resp, data)
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getInit({ session, app }) {
  const productInput = { status: ['ACTIVE', 'INACTIVE'] }
  const variables = {
    input: { type: ['DEALER'] },
    productInput,
  }
  const query = `
    query($input: LocationQueryInput, $productInput: ProductQueryInput) {
      locations(input: $input) {
        id
        name
      }
      productVariants(input: $productInput) {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const dealers = getSelectOptions(data.locations)
  const dealerMap = dealers.reduce((result, item) => {
    result[item.value] = item.label
    return result
  }, {})
  const productMap = data.productVariants.reduce((result, item) => {
    result[item.id] = item
    return result
  }, {})
  return { dealers, dealerMap, productMap }
}

async function getData({ session, app, filterValues = [] }) {
  const input = getDataInput({ filterValues })
  if (!input.balanceDate || !input.dealerId) return {}
  if (!input.soldDate || input.soldDate.length === 0) return {}

  const variables = { input }
  const query = `
    query($input: ReportRestockInput) {
      reportRestockList(input: $input) {
        dealerId
        productVariantId
        balance
        soldQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  return data.reportRestockList.map((item) => {
    const minQuantity = Math.max(0, item.soldQuantity * 2)
    return { ...item, minQuantity }
  })
}

function formatRows(rows, data) {
  return rows.map((item) => {
    item.dealerName = data.dealerMap[item.dealerId]
    const product = data.productMap[item.productVariantId]
    if (product) {
      item.productVariantName = product.name
      item.sku = product.sku
    }
    item.replenish = parseInt(item.balance) < parseInt(item.soldQuantity * 2)
    return item
  })
}

function getDataInput({ filterValues = [] }) {
  const dealerId = filterValues.find(({ id }) => id === 'dealerId')
  const balanceDate = filterValues.find(({ id }) => id === 'balanceDate')
  const soldDate = filterValues.find(({ id }) => id === 'soldDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const input = {
    balanceDate: balanceDate?.value,
    soldDate: soldDate?.value,
    dealerId: dealerId?.value.value,
    productName: productName?.value,
    sku: sku?.value,
  }
  return input
}
